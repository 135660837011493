import styled from 'styled-components';
import { Color, FontSize } from '../../../../Styles/Styles';

export const DivContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const ErrorStyled = styled.span`
  height: 12px;
  width: 100%;
  font-size: ${FontSize.Xsmall};
  margin: 0.5rem;
  margin-top: 1rem;
  color: ${Color.Red1};
`;
