import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import logoGS3 from '../Images/logoGS3SemLegenda.svg';
import {
  AdminOption,
  AdminOptionsDiv
} from '../Screens/AdminScreen/AdminScreenStyle';
import { HeaderDiv, LogoGS3 } from '../Styles/HeaderMenuStyle';
import { Color } from '../Styles/Styles';
import StructureSelect from './StructureSelect';
import UserBadge from './UserBadge';
import ViewModal from './V2/Molecules/ViewModal/ViewModal';
import Notification from './V2/Organisms/Notification/Notification';

function HeaderMenu() {
  const navigate = useNavigate();
  const { structureId } = useParams();
  const { t } = useTranslation();
  const urlPath = location.pathname.split('/')[1];
  const urlPathAdmin = location.pathname.split('/')[2];
  const [searchParam, setSearchParam] = useSearchParams();

  const handleNavigateHome = () => {
    if (structureId) {
      navigate(`/${structureId}`);
      return;
    }

    navigate('/');
  };

  return (
    <>
      {urlPath !== 'admin' ? (
        <HeaderDiv>
          <LogoGS3 onClick={handleNavigateHome} src={logoGS3} alt="" />
          <StructureSelect />
          <UserBadge />
        </HeaderDiv>
      ) : (
        <HeaderDiv>
          <LogoGS3 onClick={handleNavigateHome} src={logoGS3} alt="" />
          <AdminOptionsDiv>
            <AdminOption
              onClick={() => navigate('/admin/clients')}
              style={{ color: urlPathAdmin === 'clients' ? Color.Brown1 : '' }}
            >
              {t('Clients')}
            </AdminOption>
            <AdminOption
              onClick={() => navigate('/admin/users')}
              style={{ color: urlPathAdmin === 'users' ? Color.Brown1 : '' }}
            >
              {t('Users')}
            </AdminOption>
            <AdminOption
              onClick={() => navigate('/admin/structures')}
              style={{
                color: urlPathAdmin === 'structures' ? Color.Brown1 : ''
              }}
            >
              {t('Structures')}
            </AdminOption>
            <AdminOption
              onClick={() => navigate('/admin/permissions')}
              style={{
                color: urlPathAdmin === 'permissions' ? Color.Brown1 : ''
              }}
            >
              {t('Permissions')}
            </AdminOption>
            <AdminOption
              onClick={() => navigate('/admin/notifications')}
              style={{
                color: urlPathAdmin === 'notifications' ? Color.Brown1 : ''
              }}
            >
              {t('Notifications')}
            </AdminOption>
          </AdminOptionsDiv>
        </HeaderDiv>
      )}

      <ViewModal
        showModal={!!searchParam.get('notification')}
        title={`${t('Notifications')} ${t('of')} ${t(searchParam.get('module'))}`}
        onClose={() => {
          const searchParams = new URLSearchParams(searchParam);
          searchParams.delete('notification');
          searchParams.delete('module');
          setSearchParam(searchParams);
        }}
        component={<Notification />}
        height="70%"
      />
    </>
  );
}

export default HeaderMenu;
