import { zodResolver } from '@hookform/resolvers/zod';
import { t } from 'i18next';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import * as z from 'zod';
import {
  ActionPlanPriorityEnum,
  ActionPlanSituationEnum,
  ActionPlanTypeEnum
} from '../../../../data/graphql/base-schema';
import Button from '../../Atoms/Button/Button';
import DatepickerInput from '../../Molecules/DatepickerInput/DatepickerInput';
import InputSelectSearch from '../../Molecules/InputSelectSearch/InputSelectSearch';
import { OptionType } from '../../Molecules/InputSelectSearch/InputSelectSearch.interfaces';
import InputText from '../../Molecules/InputText/InputText';
import {
  DivContainerButtons,
  DivContainerForm,
  DivInput
} from './ActionPlanFilters.styles';

const ActionPLanFilterComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { t: translate } = useTranslation();
  const FilterFormType = z.object({
    code: z.string().optional(),
    situation: z
      .object({
        value: z.string(),
        label: z.string()
      })
      .nullable()
      .optional(),
    type: z
      .object({
        value: z.string(),
        label: z.string()
      })
      .nullable()
      .optional(),
    responsibleName: z.string().optional(),
    priority: z
      .object({
        value: z.string(),
        label: z.string()
      })
      .nullable()
      .optional(),
    timeFramePeriod: z.date().nullable().optional()
  });

  type FilterFormValues = z.infer<typeof FilterFormType>;
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue
  } = useForm<FilterFormValues>({
    resolver: zodResolver(FilterFormType),
    defaultValues: {
      timeFramePeriod: searchParams.get('timeFramePeriod')
        ? new Date(searchParams.get('timeFramePeriod')!)
        : null,
      responsibleName: searchParams.get('responsibleName') || '',
      code: searchParams.get('code') || '',
      situation: searchParams.get('situation')
        ? {
            value: searchParams.get('situation')!,
            label: searchParams.get('situation')!
          }
        : undefined,
      type: searchParams.get('type')
        ? {
            value: searchParams.get('type')!,
            label: searchParams.get('type')!
          }
        : undefined,
      priority: searchParams.get('priority')
        ? {
            value: searchParams.get('priority')!,
            label: searchParams.get('priority')!
          }
        : undefined
    }
  });

  const priorityOptions: OptionType[] = Object.values(
    ActionPlanPriorityEnum
  ).map((value) => ({
    label: translate(value),
    value: value.toString()
  }));

  const situationOptions: OptionType[] = Object.values(
    ActionPlanSituationEnum
  ).map((value) => ({
    label: translate(value),
    value: value.toString()
  }));

  const typeOptions: OptionType[] = Object.values(ActionPlanTypeEnum).map(
    (value) => ({
      label: translate(value),
      value: value.toString()
    })
  );

  const handleFilterSubmit = (data: FilterFormValues) => {
    const {
      timeFramePeriod,
      responsibleName,
      code,
      situation,
      type,
      priority
    } = data;

    const newSearchParams = new URLSearchParams(searchParams.toString());

    const filterParams: Record<string, string | undefined> = {
      timeFramePeriod: timeFramePeriod?.toISOString(),
      responsibleName: responsibleName || undefined,
      code: code || undefined,
      situation: situation?.value,
      type: type?.value,
      priority: priority?.value
    };

    Object.entries(filterParams).forEach(([key, value]) => {
      if (value) {
        newSearchParams.set(key, value);
      } else {
        newSearchParams.delete(key);
      }
    });

    newSearchParams.delete('filter');

    navigate({
      pathname: location.pathname,
      search: newSearchParams.toString()
    });
  };

  const handleClearFilters = () => {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.delete('timeFramePeriod');
    newSearchParams.delete('responsibleName');
    newSearchParams.delete('code');
    newSearchParams.delete('situation');
    newSearchParams.delete('type');
    newSearchParams.delete('priority');
    navigate({
      pathname: location.pathname,
      search: newSearchParams.toString()
    });
    setValue('timeFramePeriod', null);
    setValue('responsibleName', '');
    setValue('code', '');
    setValue('situation', null);
    setValue('type', null);
    setValue('priority', null);
  };

  return (
    <>
      <DivContainerForm>
        <InputSelectSearch
          errorMessage={errors.priority?.message}
          label={translate('priorities')}
          name="priority"
          options={priorityOptions}
          width="300px"
          control={control}
          placeholder={translate('selectPriority')}
          error={!!errors.priority}
        />
        <InputSelectSearch
          errorMessage={errors.situation?.message}
          label={translate('situation')}
          name="situation"
          options={situationOptions}
          width="300px"
          control={control}
          placeholder={translate('selectSituation')}
          error={!!errors.situation}
        />
        <InputSelectSearch
          errorMessage={errors.type?.message}
          label={translate('Type')}
          name="type"
          options={typeOptions}
          width="300px"
          control={control}
          placeholder={translate('selectType')}
          error={!!errors.type}
        />
        <DatepickerInput
          label={t('timeFrame')}
          control={control}
          name="timeFramePeriod"
          placeholder={t('selectDate')}
          time={false}
          error={!!errors.timeFramePeriod}
          errorMessage={errors?.timeFramePeriod?.message}
        />
        <DivInput>
          <InputText
            label={t('responsible')}
            type="text"
            control={control}
            name="responsibleName"
            error={!!errors.responsibleName}
            errorMessage={errors?.responsibleName?.message}
          />
        </DivInput>
        <DivInput>
          <InputText
            label={t('code')}
            type="text"
            control={control}
            name="code"
            error={!!errors.code}
            errorMessage={errors?.code?.message}
          />
        </DivInput>
      </DivContainerForm>
      <DivContainerButtons>
        <Button
          variant="secondary"
          size="small"
          text={t('clean')}
          onClick={handleClearFilters}
        />
        <Button
          variant="primary"
          size="small"
          text={t('Filtrar')}
          onClick={handleSubmit(handleFilterSubmit)}
        />
      </DivContainerButtons>
    </>
  );
};

export default ActionPLanFilterComponent;
