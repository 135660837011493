import { API_URL } from '../../../../utils/const';

export async function postFile(
  token: string,
  sectionId: string,
  formData: FormData
) {
  const fullURL = `${API_URL}/section/${sectionId}/upload`;

  const response = await fetch(fullURL, {
    method: 'POST',
    body: formData,
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: token
    }
  });

  if (response.status !== 200) {
    throw new Error('Erro');
  }
}
