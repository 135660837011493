import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type SetAtoCanceledMutationVariables = Types.Exact<{
  setAtoCanceledId: Types.Scalars['String']['input'];
}>;


export type SetAtoCanceledMutation = { __typename?: 'Mutation', setAtoCanceled: string };


export const SetAtoCanceledDocument = gql`
    mutation SetAtoCanceled($setAtoCanceledId: String!) {
  setAtoCanceled(id: $setAtoCanceledId)
}
    `;
export type SetAtoCanceledMutationFn = Apollo.MutationFunction<SetAtoCanceledMutation, SetAtoCanceledMutationVariables>;
export type SetAtoCanceledMutationResult = Apollo.MutationResult<SetAtoCanceledMutation>;
export type SetAtoCanceledMutationOptions = Apollo.BaseMutationOptions<SetAtoCanceledMutation, SetAtoCanceledMutationVariables>;