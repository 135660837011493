import { zodResolver } from '@hookform/resolvers/zod';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import * as z from 'zod';
import { ProjectContext } from '../../../../Context/ContextAPI';
import { client } from '../../../../data/apollo.client';
import { FindGeneralInfoDocument } from '../../../../data/graphql/query/generated/findAtoGeneralInfo.query';
import { FindDataByRecordDocument } from '../../../../data/graphql/query/generated/findDataByRecord';
import { sendVideoFile } from '../../../../data/services/ATOService';
import ToastifyModel from '../../../../Models/ToastifyModel';
import { UserData } from '../../../Map/types';
import { toastfyError } from '../../../Toastify';
import Button from '../../Atoms/Button/Button';
import { FileType } from '../../Molecules/FileViewer/FileViewer.interfaces';
import InputTextarea from '../../Molecules/InputTextarea/InputTextarea';
import InputFileWithList from '../InputFileWithList/InputFileWithList';
import { AddVideoAtoFormProps } from './AddVideoAtoForm.interface';
import {
  DivButtonArea,
  DivInputsArea,
  HolderForm
} from './AddVideoAtoForm.styles';

const oneMegaByte = 1048576;

const addVideoAtoFormSchema = z.object({
  description: z
    .string()
    .min(1, {
      message: 'fieldIsRequired'
    })
    .refine((val) => val !== '', {
      message: 'fieldIsRequired'
    }),
  files: z
    .array(z.instanceof(File), { message: 'fieldIsRequired' })
    .min(1, { message: 'fieldIsRequired' })
    .max(1, { message: 'tooManyFiles' })
    .refine((val) => val.every((file) => file.size <= 100 * oneMegaByte), {
      message: 'veryBigFile'
    })
});

type AddVideoAtoFormSchemaType = z.infer<typeof addVideoAtoFormSchema>;

const AddVideoAtoForm = ({
  setShowModal,
  recordId,
  editVideo
}: AddVideoAtoFormProps) => {
  const { t } = useTranslation();
  const { atoId } = useParams();
  const [description, setDescription] = useState<string>(
    editVideo?.description || ''
  );
  const [files, setFiles] = useState<FileType[]>([]);
  const { userData } = useContext(ProjectContext) as {
    userData: UserData;
  };

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors }
  } = useForm<AddVideoAtoFormSchemaType>({
    resolver: zodResolver(addVideoAtoFormSchema),
    defaultValues: {
      description: editVideo?.description || ''
    }
  });

  const urlVideo = '/ato/record/video/';

  useEffect(() => {
    if (editVideo) {
      setFiles([
        {
          id: editVideo?.id,
          name: editVideo?.name,
          url: editVideo?.url
        }
      ]);
    }
  }, []);

  const handleSubmitAddVideoAtoForm = async () => {
    const isInvalid = files.length === 0;
    if (isInvalid) {
      setValue('files', []);
      return;
    }

    if (description.length !== 0 && files.length !== 0) {
      const formData = new FormData();
      const videoExtensions = ['.mkv', '.avi', '.mp4', '.wmv'];

      files.forEach((file) => {
        formData.append('video', file.file as Blob);
      });

      formData.append('observation', description);

      const allVideosEndWithExtension = files.every((file) => {
        return videoExtensions.some((videoExtensions) =>
          file.name.endsWith(videoExtensions)
        );
      });

      if (allVideosEndWithExtension) {
        const res = await sendVideoFile(
          formData,
          urlVideo,
          recordId,
          userData.token
        );

        if (res === 201) {
          await client.refetchQueries({
            include: [
              {
                query: FindDataByRecordDocument,
                variables: {
                  recordId: recordId
                },
                awaitRefetchQueries: true
              },
              {
                query: FindGeneralInfoDocument,
                variables: {
                  atoId: atoId
                },
                awaitRefetchQueries: true
              }
            ]
          });

          setShowModal(false);
        } else {
          toastfyError(t(ToastifyModel().toastifyMessage.error));
        }
      }
    }
  };

  const handleFileChange = (selectedFiles: File[]) => {
    const fileSize = selectedFiles[0].size;
    const newselectedFiles: FileType[] = Array.from(selectedFiles).map(
      (file) => ({
        id: '',
        name: file.name,
        url: URL.createObjectURL(file),
        file: file
      })
    );

    const storedStateFiles = files
      .map((file) => file.file)
      .concat(newselectedFiles.map((file) => file.file)) as File[];

    setValue('files', storedStateFiles);
    if (fileSize <= 100 * oneMegaByte) {
      setFiles((prev) => [...prev, ...newselectedFiles]);
    }
  };

  const handleDeleteImage = (fileSelect: FileType) => {
    const updatedFiles: FileType[] = files.filter(
      (file) => file.file !== fileSelect.file
    );
    setFiles(updatedFiles);

    setValue(
      'files',
      updatedFiles.map((file) => file.file!)
    );
  };

  return (
    <HolderForm>
      <DivInputsArea>
        <div>
          <InputTextarea
            errorMessage={errors.description?.message}
            error={!!errors.description}
            height="100px"
            label={t('description')}
            name="description"
            onChange={(event) => setDescription(event.target.value)}
            register={register}
            value={description}
            width="300px"
          />
        </div>
        <InputFileWithList
          register={register}
          name="files"
          errorMessage={errors.files?.message}
          error={!!errors.files}
          accept=".avi, .mp4, .mkv, .wmv"
          label=""
          multiple={true}
          files={files}
          onChange={(event) => handleFileChange(event.target.files as any)}
          onDelete={(file: FileType) => handleDeleteImage(file)}
        />
      </DivInputsArea>
      <DivButtonArea>
        <Button
          text={t('Cancel')}
          variant={'secondary'}
          size="small"
          onClick={() => {
            setShowModal(false);
          }}
        />
        <Button
          text={t('Save')}
          variant={'primary'}
          size="small"
          onClick={handleSubmit(handleSubmitAddVideoAtoForm)}
        />
      </DivButtonArea>
    </HolderForm>
  );
};

export default AddVideoAtoForm;
