import { IoIosClose } from 'react-icons/io';
import Icon from '../../Atoms/Icon/Icon';
import Text from '../../Atoms/Text/Text';
import { ViewModalProps } from './ViewModal.interface';
import {
  DivContainerViewModalBody,
  DivModalContainer,
  DivViewModal,
  DivViewModalContent,
  DivViewModalHeader
} from './ViewModal.styles';
import { useEffect, useState } from 'react';

const ViewModal = ({
  width,
  height,
  title,
  onClose,
  style,
  component,
  showModal
}: ViewModalProps) => {
  const [isMounted, setIsMounted] = useState(showModal);

  useEffect(() => {
    if (showModal) {
      setIsMounted(true);
    } else {
      const timer = setTimeout(() => setIsMounted(false), 300);
      return () => clearTimeout(timer);
    }
  }, [showModal]);

  return (
    <DivModalContainer showModal={showModal}>
      <DivViewModal
        style={style}
        width={width}
        height={height}
        showModal={showModal}
      >
        <DivViewModalContent>
          <DivViewModalHeader>
            <Text type="h4" size="medium">
              {title}
            </Text>
            <Icon
              Icon={IoIosClose}
              variant="large"
              onClick={() => {
                onClose();
              }}
            />
          </DivViewModalHeader>
          <DivContainerViewModalBody showModal={showModal}>
            {isMounted && component}
          </DivContainerViewModalBody>
        </DivViewModalContent>
      </DivViewModal>
    </DivModalContainer>
  );
};

export default ViewModal;
