export const DEFAULT_CENTER_BRAZIL = {
  lat: -14.235004,
  lng: -51.92528
};

export const DEFAULT_MAP_ZOOM = 3;

export const WITH_CENTER_MAP_ZOOM = 16;

export const DEFAULT_MAP_ID_FOR_API_CONTEXT = 'bf51a910020fa25a';
