import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ModalStep from '../../../../Components/V2/Molecules/ModalStep/ModalStep';
import { toastfyError, toastfySuccess } from '../../../../Components/Toastify';
import ToastifyModel from '../../../../Models/ToastifyModel';
import { useAddStructureDamTailingVolume } from '../../../../data/hooks/add-structure-dam-tailing-volume';
import { useCreateStructureDamRaising } from '../../../../data/hooks/admin-structure-structures/use-create-structure-dam-raising.mutation';
import { useCreateStructureGeometry } from '../../../../data/hooks/admin-structure-structures/use-create-structure-geometry.mutation';
import { useCreateStructureHydrology } from '../../../../data/hooks/admin-structure-structures/use-create-structure-hidrology.mutation';
import { useCreateStructureReservoirHandling } from '../../../../data/hooks/admin-structure-structures/use-create-structure-reservoir-holding.mutation';
import { useCreateStructureRiskAssessment } from '../../../../data/hooks/admin-structure-structures/use-create-structure-risk-assesment.mutation';
import { useCreateStructureSummary } from '../../../../data/hooks/admin-structure-structures/use-create-structure-summary.mutation';
import { useCreateStructure } from '../../../../data/hooks/admin-structure-structures/use-create-structures.mutation';
import { useEditStructure } from '../../../../data/hooks/admin-structure-structures/use-edit-structure';
import { useEditStructureDamRaising } from '../../../../data/hooks/admin-structure-structures/use-edit-structure-dam-raising';
import { useEditStructureDamTailing } from '../../../../data/hooks/admin-structure-structures/use-edit-structure-dam-tailing';
import { useEditStructureGeometry } from '../../../../data/hooks/admin-structure-structures/use-edit-structure-geometry';
import { useEditStructureHydrology } from '../../../../data/hooks/admin-structure-structures/use-edit-structure-hydrology';
import { useEditStructureReservoirHandling } from '../../../../data/hooks/admin-structure-structures/use-edit-structure-reservoir-handling';
import { useEditStructureRiskAssessment } from '../../../../data/hooks/admin-structure-structures/use-edit-structure-risk-assessment';
import { useEditStructureSummary } from '../../../../data/hooks/admin-structure-structures/use-edit-structure-summary';
import { useStructureSummary } from '../../../../data/hooks/use-structure-summary.query';
import { StructureStepFive } from './Steps/StructureStepFive';
import { StructureStepFour } from './Steps/StructureStepFour';
import { StructureStepOne } from './Steps/StructureStepOne';
import { StructureStepSeven } from './Steps/StructureStepSeven';
import { StructureStepSix } from './Steps/StructureStepSix';
import { StructureStepThree } from './Steps/StructureStepThree';
import { StructureStepTwo } from './Steps/StructureStepTwo';
import {
  StepFive,
  StepFour,
  StepOne,
  StepSeven,
  StepSix,
  StepThree,
  StepTwo,
  __initialStepFive,
  __initialStepFour,
  __initialStepOne,
  __initialStepSeven,
  __initialStepSix,
  __initialStepThree,
  __initialStepTwo
} from './StructuresInterface';

export function StructureModal({
  edit,
  setEdit,
  setShowModal
}: {
  edit: { status: boolean; data: any };
  setEdit: Dispatch<SetStateAction<{ status: boolean; data: any }>>;
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
}) {
  const [structureStepOneData, setStructureStepOneData] =
    useState<StepOne>(__initialStepOne);
  const [structureStepTwoData, setStructureStepTwoData] =
    useState<StepTwo>(__initialStepTwo);
  const [structureStepThreeData, setStructureStepThreeData] =
    useState<StepThree>(__initialStepThree);
  const [structureStepFourData, setStructureStepFourData] =
    useState<StepFour>(__initialStepFour);
  const [structureStepFiveData, setStructureStepFiveData] =
    useState<StepFive>(__initialStepFive);
  const [structureStepSixData, setStructureStepSixData] =
    useState<StepSix>(__initialStepSix);
  const [structureStepSevenData, setStructureStepSevenData] =
    useState<StepSeven>(__initialStepSeven);
  const [error, setError] = useState(false);
  const { t } = useTranslation();
  const [structureId, setStructureId] = useState(edit.data.id ?? '');
  const { createStructure } = useCreateStructure();
  const { editStructure } = useEditStructure();
  const { data: structureSummary } = useStructureSummary('', structureId);
  const { createStructureSummary } = useCreateStructureSummary();
  const { createStructureDamRaising } = useCreateStructureDamRaising();
  const { editStructure: editStructureSummary } = useEditStructureSummary();
  const { editDamRaising } = useEditStructureDamRaising();
  const { createStructureRiskAssessment } = useCreateStructureRiskAssessment();
  const { editRiskAssessment } = useEditStructureRiskAssessment();
  const { createStructureGeometry } = useCreateStructureGeometry();
  const { editGeometry } = useEditStructureGeometry();
  const { createStructureHydrology } = useCreateStructureHydrology();
  const { editHydrology } = useEditStructureHydrology();
  const { createStructureReservoirHandling } =
    useCreateStructureReservoirHandling();
  const { editReservoirHandling } = useEditStructureReservoirHandling();
  const { editDamTailing } = useEditStructureDamTailing();
  const { addStructureDamTailingVolume } = useAddStructureDamTailingVolume();

  useEffect(() => {
    if (structureSummary) {
      setStructureStepOneData({
        name: structureSummary.getStructureSummary.name ?? '',
        companyId: structureSummary.getStructureSummary.company?.id ?? '',
        responsibleId:
          structureSummary.getStructureSummary.responsible?.id ?? '',
        type: structureSummary.getStructureSummary.type,
        complex: {
          address: structureSummary.getStructureSummary.complex?.address ?? '',
          city: structureSummary.getStructureSummary.complex?.city ?? '',
          country: structureSummary.getStructureSummary.complex?.country ?? '',
          name: structureSummary.getStructureSummary.complex?.name ?? '',
          neighborhood:
            structureSummary.getStructureSummary.complex?.neighborhood ?? '',
          phone: structureSummary.getStructureSummary.complex?.phone ?? '',
          state: structureSummary.getStructureSummary.complex?.state ?? '',
          zipCode: structureSummary.getStructureSummary.complex?.zipCode ?? ''
        }
      });
      setStructureStepTwoData({
        ANMClassification:
          structureSummary.getStructureSummary.summary?.ANMClassification ?? '',
        coordinateE:
          structureSummary.getStructureSummary.summary?.coordinateE ?? 0,
        coordinateN:
          structureSummary.getStructureSummary.summary?.coordinateN ?? 0,
        datumName:
          structureSummary.getStructureSummary.summary?.datumName ?? '',
        maxHeight: structureSummary.getStructureSummary.summary?.maxHeight ?? 0,
        operationEnd:
          structureSummary.getStructureSummary.summary?.operationEnd ?? '',
        operationStart:
          structureSummary.getStructureSummary.summary?.operationStart ?? '',
        operationalStatus:
          structureSummary.getStructureSummary.summary?.operationalStatus ?? '',
        potentialDamage:
          structureSummary.getStructureSummary.summary?.potentialDamage ?? '',
        riskClassification:
          structureSummary.getStructureSummary.summary?.riskClassification ??
          '',
        utmZone: structureSummary.getStructureSummary.summary?.utmZone ?? '',
        structureId: structureId,
        isUTM: structureSummary.getStructureSummary.summary?.isUTM ?? false
      });
      setStructureStepThreeData({
        data:
          structureSummary.getStructureSummary.damRaisingHistory.map(
            (damRaising) => {
              return {
                date: damRaising.date,
                elevation: damRaising.elevation,
                method: damRaising.method,
                stage: damRaising.stage,
                id: damRaising.id,
                structureId: structureId
              };
            }
          ) ?? []
      });
      setStructureStepFourData({
        downstreamEnvironmentalInterest:
          structureSummary.getStructureSummary.riskAssessment
            ?.downstreamEnvironmentalInterest ?? '',
        downstreamHumanOccupation:
          structureSummary.getStructureSummary.riskAssessment
            ?.downstreamHumanOccupation ?? '',
        downstreamInstallations:
          structureSummary.getStructureSummary.riskAssessment
            ?.downstreamInstallations ?? '',
        wasteType:
          structureSummary.getStructureSummary.riskAssessment?.wasteType ?? ''
      });
      setStructureStepFiveData({
        bermLength:
          structureSummary.getStructureSummary.geometry?.bermLength ?? 0,
        crestElevation:
          structureSummary.getStructureSummary.geometry?.crestElevation ?? 0,
        crestLength:
          structureSummary.getStructureSummary.geometry?.crestLength ?? 0,
        crestWidth:
          structureSummary.getStructureSummary.geometry?.crestWidth ?? 0,
        globalSlopeDownstream:
          structureSummary.getStructureSummary.geometry
            ?.globalSlopeDownstream ?? '',
        slopeBetweenBerms:
          structureSummary.getStructureSummary.geometry?.slopeBetweenBerms ??
          '',
        upstreamSlopeInclination:
          structureSummary.getStructureSummary.geometry
            ?.upstreamSlopeInclination ?? ''
      });
      setStructureStepSixData({
        criticalDuration:
          structureSummary.getStructureSummary.hydrologyInfos
            ?.criticalDuration ?? 0,
        inflowRate:
          structureSummary.getStructureSummary.hydrologyInfos?.inflowRate ?? 0,
        NAMaximoMaximorum:
          structureSummary.getStructureSummary.hydrologyInfos
            ?.NAMaximoMaximorum ?? 0,
        outflowRate:
          structureSummary.getStructureSummary.hydrologyInfos?.outflowRate ?? 0,
        projectRainfall:
          structureSummary.getStructureSummary.hydrologyInfos
            ?.projectRainfall ?? 0,
        reservoirContributionArea:
          structureSummary.getStructureSummary.hydrologyInfos
            ?.reservoirContributionArea ?? 0,
        returnPeriod:
          structureSummary.getStructureSummary.hydrologyInfos?.returnPeriod ??
          0,
        spillwaySill:
          structureSummary.getStructureSummary.hydrologyInfos?.spillwaySill ??
          0,
        spillwayType:
          structureSummary.getStructureSummary.hydrologyInfos?.spillwayType ??
          '',
        volumeTotalElevationCrest:
          structureSummary.getStructureSummary.hydrologyInfos
            ?.volumeTotalElevationCrest ?? 0
      });
      setStructureStepSevenData({
        waterVolume:
          structureSummary.getStructureSummary.reservoirHandling?.waterVolume ??
          0,
        tailingVolumeHistory:
          structureSummary.getStructureSummary.reservoirHandling
            ?.tailingVolumeHistory ?? [],
        reservoirOccupiedArea:
          structureSummary.getStructureSummary.reservoirHandling
            ?.reservoirOccupiedArea ?? 0,
        reservoirOccupiedVolume:
          structureSummary.getStructureSummary.reservoirHandling
            ?.reservoirOccupiedVolume ?? 0,
        reservoirTotalVolume:
          structureSummary.getStructureSummary.reservoirHandling
            ?.reservoirTotalVolume ?? 0
      });
    }
  }, [structureSummary]);

  const handleCreateOrUpdateStepOne = async (idField?: string) => {
    const validate = hanldeValidation(structureStepOneData);
    if (!validate) return false;
    if (!idField) {
      const response = await createStructure({
        variables: { data: structureStepOneData }
      });
      if (response.data) {
        setStructureId(response.data.createStructure);
      }

      return handleResponse(response, false);
    } else {
      const response = await editStructure({
        variables: {
          data: {
            id: idField,
            name: structureStepOneData.name,
            type: structureStepOneData.type,
            complex: {
              address: structureStepOneData.complex.address,
              city: structureStepOneData.complex.city,
              country: structureStepOneData.complex.country,
              name: structureStepOneData.complex.name,
              neighborhood: structureStepOneData.complex.neighborhood,
              phone: structureStepOneData.complex.phone,
              state: structureStepOneData.complex.state,
              zipCode: structureStepOneData.complex.zipCode
            }
          }
        }
      });
      return handleResponse(response, true);
    }
  };

  function replaceEmptyOrZeroWithNull(value: any) {
    if (value === '' || value === 0) {
      return null;
    }

    return value;
  }

  const handleCreateOrUpdate = async (
    stepData: any,
    createFn: any,
    editFn: any,
    idField?: string
  ) => {
    const newData = Object.values(stepData).map(replaceEmptyOrZeroWithNull);

    const newStepData = {};
    Object.keys(stepData).forEach((key, index) => {
      newStepData[key] = newData[index];
    });

    if (!idField) {
      const response = await createFn({
        variables: { data: newStepData, structureId: structureId }
      });
      return handleResponse(response, false);
    } else {
      const data = { ...newStepData, id: idField };
      const response = await editFn({
        variables: { data }
      });
      return handleResponse(response, true);
    }
  };

  const handleCreateOrUpdateStepFour = async (
    stepData: any,
    createFn: any,
    editFn: any,
    idField?: string
  ) => {
    const newData = Object.values(stepData).map(replaceEmptyOrZeroWithNull);

    const newStepData = {};
    Object.keys(stepData).forEach((key, index) => {
      newStepData[key] = newData[index];
    });

    const validate = hanldeValidation(stepData);
    if (!validate) return false;
    if (!idField) {
      const response = await createFn({
        variables: { data: newStepData, structureId }
      });
      return handleResponse(response, false);
    } else {
      const { companyId, responsibleId, ...rest } = newStepData;
      const data = { ...rest, id: idField, structureId };
      const response = await editFn({
        variables: { data }
      });
      return handleResponse(response, true);
    }
  };

  const handleCreateOrUpdateStepFiveSix = async (
    stepData: any,
    createFn: any,
    editFn: any,
    idField?: string
  ) => {
    const newData = Object.values(stepData).map(replaceEmptyOrZeroWithNull);

    const newStepData = {};
    Object.keys(stepData).forEach((key, index) => {
      newStepData[key] = newData[index];
    });

    if (!idField) {
      const response = await createFn({
        variables: { data: newStepData, structureId }
      });
      return handleResponse(response, false);
    } else {
      const { companyId, responsibleId, ...rest } = newStepData;
      const data = { ...rest, id: idField, structureId };
      const response = await editFn({
        variables: { data }
      });
      return handleResponse(response, true);
    }
  };

  const handleResponse = (response: any, edit: boolean) => {
    if (response.data) {
      toastfySuccess(
        edit ? `${t('editedSuccessfully')}!` : `${t('registeredSuccessfully')}!`
      );
      setError(false);
      return true;
    } else if (response.errors) {
      toastfyError(
        response.errors[0].message || t(ToastifyModel().toastifyMessage.error)
      );
      setError(true);
      return false;
    }

    setError(true);
    return false;
  };

  const hanldeValidation = (stepData: any) => {
    const { coordinateN, coordinateE, isUTM, maxHeight, ...data } = stepData;

    if (!isUTM) {
      if (
        coordinateE < -90 ||
        coordinateE > 90 ||
        coordinateN < -180 ||
        coordinateN > 180
      ) {
        toastfyError(t('ValidationCoordinate'));
        setError(true);
        return false;
      }
    }

    const response = Object.values(data).some((value) => !value);
    if (response) {
      toastfyError(t(ToastifyModel().toastifyMessage.fillRequiredFields));
      setError(true);
      return false;
    }

    return true;
  };

  const hanldeCreateStepThree = async () => {
    if (structureStepThreeData.data.length <= 0) {
      toastfyError(t(ToastifyModel().toastifyMessage.fillRequiredFields));
      setError(true);
      return false;
    }

    const response = await createStructureDamRaising({
      variables: {
        structureId: structureId,
        data: structureStepThreeData.data
      }
    });
    return handleResponse(response, false);
  };

  const handleEditStepThree = async () => {
    if (structureStepThreeData.data.length <= 0) {
      toastfyError(t(ToastifyModel().toastifyMessage.fillRequiredFields));
      setError(true);
      return false;
    }

    const msg: string[] = [];
    structureStepThreeData.data.map(async (damRaising) => {
      if (damRaising.id) {
        const response = await editDamRaising({
          variables: {
            data: {
              date: damRaising.date,
              elevation: damRaising.elevation,
              method: damRaising.method,
              stage: damRaising.stage,
              id: damRaising.id,
              structureId: structureId
            }
          }
        });
        if (response.errors) {
          msg.push(response.errors[0].message);
        }
      } else {
        const response = await createStructureDamRaising({
          variables: {
            structureId: structureId,
            data: damRaising
          }
        });
        if (response.errors) {
          msg.push(response.errors[0].message);
        }
      }

      if (msg.length > 0) {
        toastfyError(msg.join(', '));
        setError(true);
        return false;
      }

      setError(false);
      return true;
    });
    setError(false);
    return true;
  };

  const handleStepThree = async () => {
    if (structureSummary?.getStructureSummary?.damRaisingHistory.length > 0) {
      return await handleEditStepThree();
    } else {
      return await hanldeCreateStepThree();
    }
  };

  const handleEditStepSeven = async () => {
    const msg: string[] = [];
    const response = await editReservoirHandling({
      variables: {
        data: {
          id: structureSummary?.getStructureSummary.reservoirHandling?.id ?? '',
          structureId: structureId,
          reservoirOccupiedArea: structureStepSevenData.reservoirOccupiedArea,
          reservoirOccupiedVolume:
            structureStepSevenData.reservoirOccupiedVolume,
          reservoirTotalVolume: structureStepSevenData.reservoirTotalVolume,
          waterVolume: structureStepSevenData.waterVolume
        }
      }
    });
    structureStepSevenData.tailingVolumeHistory.map(async (tailing) => {
      if (tailing.id) {
        const response2 = await editDamTailing({
          variables: {
            data: {
              date: tailing.date,
              volume: tailing.volume,
              id: tailing.id,
              structureId: structureId
            }
          }
        });
        if (response2.data) {
          toastfySuccess(
            response2.data.editStructureDamTailingVolume ||
              `${t('editedSuccessfully')}!`
          );
        }

        if (response2.errors) {
          msg.push(response2.errors[0].message);
        }
      } else {
        const response2 = await addStructureDamTailingVolume({
          variables: {
            structureId: structureId,
            data: {
              reservoirHandlingId:
                structureSummary?.getStructureSummary.reservoirHandling?.id ??
                '',
              date: tailing.date,
              volume: tailing.volume
            }
          }
        });
        if (response2.data) {
          toastfySuccess(
            response2.data.addStructureDamTailingVolume ||
              `${t('registeredSuccessfully')}!`
          );
        }

        if (response2.errors) {
          msg.push(
            response2.errors[0].message ||
              t(ToastifyModel().toastifyMessage.error)
          );
        }
      }
    });
    if (response.data) {
      toastfySuccess(
        response.data.editStructureReservoirHandling ||
          `${t('editedSuccessfully')}!`
      );
      setError(false);
      window.location.reload();
      return true;
    }

    if (response.errors) {
      msg.push(response.errors[0].message);
    }

    if (msg.length > 0) {
      toastfyError(msg.join(', '));
      setError(true);
      return false;
    }

    setError(true);
    return false;
  };

  const handleStepSeven = async () => {
    if (structureSummary?.getStructureSummary.reservoirHandling?.id) {
      return await handleEditStepSeven();
    } else {
      return await handleCreateOrUpdate(
        structureStepSevenData,
        createStructureReservoirHandling,
        editReservoirHandling,
        structureSummary?.getStructureSummary?.reservoirHandling?.id
      );
    }
  };

  return (
    <ModalStep
      title={`${edit.data.id ? t('Edit') : t('Register')} ${t('Structure')}`}
      onClose={() => {
        if (setShowModal) setShowModal(false);
        setEdit({ status: false, data: {} });
      }}
      editing={edit.data.id ? true : false}
      steps={[
        {
          name: 'CompanyData',
          component: (
            <StructureStepOne
              setStructureStepOneData={setStructureStepOneData}
              structureStepOneData={structureStepOneData}
              error={error}
            />
          ),
          onNext: () =>
            handleCreateOrUpdateStepOne(
              structureSummary?.getStructureSummary?.id
            )
        },
        {
          name: 'GeneralData',
          component: (
            <StructureStepTwo
              setStructureStepTwoData={setStructureStepTwoData}
              structureStepTwoData={structureStepTwoData}
              error={error}
            />
          ),
          onNext: () =>
            handleCreateOrUpdate(
              structureStepTwoData,
              createStructureSummary,
              editStructureSummary,
              structureSummary?.getStructureSummary?.summary?.id
            )
        },
        {
          name: 'ChangeHistory',
          component: (
            <StructureStepThree
              setStructureStepThreeData={setStructureStepThreeData}
              structureStepThreeData={structureStepThreeData}
            />
          ),
          onNext: () => handleStepThree()
        },
        {
          name: 'RiskMapping',
          component: (
            <StructureStepFour
              setStructureStepFourData={setStructureStepFourData}
              structureStepFourData={structureStepFourData}
              error={error}
            />
          ),
          onNext: () =>
            handleCreateOrUpdateStepFour(
              structureStepFourData,
              createStructureRiskAssessment,
              editRiskAssessment,
              structureSummary?.getStructureSummary?.riskAssessment?.id
            )
        },
        {
          name: 'MassiveGeometry',
          component: (
            <StructureStepFive
              setStructureStepFiveData={setStructureStepFiveData}
              structureStepFiveData={structureStepFiveData}
              error={error}
            />
          ),
          onNext: () =>
            handleCreateOrUpdateStepFiveSix(
              structureStepFiveData,
              createStructureGeometry,
              editGeometry,
              structureSummary?.getStructureSummary?.geometry?.id
            )
        },
        {
          name: 'Hydrology',
          component: (
            <StructureStepSix
              setStructureStepSixData={setStructureStepSixData}
              structureStepSixData={structureStepSixData}
              error={error}
            />
          ),
          onNext: () =>
            handleCreateOrUpdateStepFiveSix(
              structureStepSixData,
              createStructureHydrology,
              editHydrology,
              structureSummary?.getStructureSummary?.hydrologyInfos?.id
            )
        },
        {
          name: 'ReservoirManagement',
          component: (
            <StructureStepSeven
              setStructureStepSevenData={setStructureStepSevenData}
              structureStepSevenData={structureStepSevenData}
              structureId={structureId}
              error={error}
            />
          ),
          onNext: () => handleStepSeven()
        }
      ]}
    />
  );
}
