import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../Atoms/Button/Button';
import Text from '../../Atoms/Text/Text';
import ModalStep from '../../Molecules/ModalStep/ModalStep';
import ViewModal from '../../Molecules/ViewModal/ViewModal';
import GISTMList from '../../Organisms/GISTMList/GISTMList';
import GISTMListFiles from '../../Organisms/GISTMListFiles/GISTMListFiles';
import { GISTMListVolumes } from '../../Organisms/GISTMListVolumes/GISTMListVolumes';
import SimpleContentTemplate from '../../Templates/SimpleContentTemplate/SimpleContentTemplate';
import { sendGISTMFile } from '../../../../data/services/GISTMService';
import { toastfyError, toastfySuccess } from '../../../Toastify';
import { Comment } from '../../Organisms/VolumeCheckbox/VolumeCheckbox.interface';
import { useNavigate, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import {
  SaveGistmCommentsDocument,
  SaveGistmCommentsMutation,
  SaveGistmCommentsMutationVariables
} from '../../../../data/graphql/generated/saveGistmComments.mutation';
import { FindGistmCommentsDocument } from '../../../../data/graphql/query/generated/findGistmComments.query';

const GISTMScreen = () => {
  const { t: translate } = useTranslation();
  const nagivate = useNavigate();
  const { structureId, gistmId } = useParams();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [comments, setComments] = useState<Comment[]>([]);
  const [files, setFiles] = useState<any[]>([]);
  const [datas, setData] = useState<any[]>([]);
  const [error, setError] = useState(false);

  const [saveGistmComments] = useMutation<
    SaveGistmCommentsMutation,
    SaveGistmCommentsMutationVariables
  >(SaveGistmCommentsDocument);

  const handleSubmit = async (): Promise<boolean> => {
    const isComments = comments.every((item) => item.comment !== '');
    if (comments.length !== 0 && files.length !== 0 && isComments) {
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append('files', files[i].file);
      }

      formData.append(
        'gistmDataIds',
        comments.map((item) => item.id.toString()) as any
      );
      const gistmMap = comments.map((item) => ({
        gistmId: item.id,
        comment: item.comment
      }));
      await saveGistmComments({
        variables: {
          structureId: structureId!,
          comments: gistmMap
        },
        refetchQueries: [FindGistmCommentsDocument],
        awaitRefetchQueries: true,
        onCompleted: () => {
          toastfySuccess(translate('deletedSuccessfully'));
        },
        onError: () => {
          toastfyError(translate('errorDelete'));
        }
      });
      const res = await sendGISTMFile(formData, structureId!);
      if (res === 200) {
        handleCloseModalAdd();
      }

      return true;
    } else {
      if (files.length === 0) {
        setError(true);
      }

      toastfyError(translate('fillRequiredFields'));
      return false;
    }
  };

  const handleCloseModalAdd = () => {
    setShowModal(false);
    setComments([]);
    setFiles([]);
    setData([]);
    setError(false);
  };

  const handleClose = () => {
    nagivate(`/${structureId}/gistm`);
  };

  return (
    <>
      <SimpleContentTemplate
        loading={false}
        title={<Text type="h1">{translate('GISTM')}</Text>}
        button={
          <Button
            onClick={() => setShowModal(true)}
            text={`${translate('Add')} ${translate('File')}`}
            size="medium"
            variant="primary"
          />
        }
        content={<GISTMList />}
      />
      {showModal && (
        <ModalStep
          title={translate('Add')}
          onClose={() => handleCloseModalAdd()}
          steps={[
            {
              name: '',
              component: (
                <GISTMListVolumes
                  setShowModal={setShowModal}
                  comments={comments}
                  setComments={setComments}
                  files={files}
                  setFiles={setFiles}
                  data={datas}
                  setData={setData}
                  error={error}
                  setError={setError}
                  structureSelectedId={structureId || ''}
                />
              ),
              onNext: () => handleSubmit()
            }
          ]}
        />
      )}

      <ViewModal
        showModal={gistmId ? true : false}
        title={`${translate('View')} ${translate('File')}`}
        onClose={() => handleClose()}
        component={<GISTMListFiles />}
        width="768px"
        height="600px"
      />
    </>
  );
};

export default GISTMScreen;
