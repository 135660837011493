import React from 'react';
import { Color } from '../../../Styles/Styles';
interface PiezomererIconProps {
  size?: number;
  color?: string;
}
const PiezomererIcon = ({
  size = 66,
  color = Color.Brown1
}: PiezomererIconProps) => {
  return (
    <svg
      width={size}
      height={(size * 76) / 66}
      viewBox="0 0 66 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M45.5 50.5V45H53V50.5L50.5 52C50.6231 54.1955 50.5 54.5 50.5 56.5C50 61 46.5 64.5 39.5 65.5L24.5 67V65L39.5 63.5C44 62.5 47 62 48.5 56.5V52L45.5 50.5Z"
        fill={color}
      />
      <rect x="11" width="3" height="76" fill={color} />
      <path
        d="M17 14V19.5H19C20.1616 16.6841 21.9549 15.7509 25 14H17Z"
        fill={color}
      />
      <path
        d="M17 22.5V28H19C20.1616 25.1841 21.9549 24.2509 25 22.5H17Z"
        fill={color}
      />
      <path
        d="M17 31V36.5H19C20.1616 33.6841 21.9549 32.7509 25 31H17Z"
        fill={color}
      />
      <path
        d="M17 39.5V45H19C20.1616 42.1841 21.9549 41.2509 25 39.5H17Z"
        fill={color}
      />
      <path
        d="M17 48V53.5H19C20.1616 50.6841 21.9549 49.7509 25 48H17Z"
        fill={color}
      />
      <path
        d="M17 56.5V62H19C20.1616 59.1841 21.9549 58.2509 25 56.5H17Z"
        fill={color}
      />
      <path
        d="M17 6V11.5H19C20.1616 8.68405 21.9549 7.75095 25 6H17Z"
        fill={color}
      />
      <path
        d="M17 6L17 0L19 1.74846e-07C20.1616 3.07194 21.9549 4.08988 25 6L17 6Z"
        fill={color}
      />
      <path
        d="M17 65V75H19C20.1616 69.8801 21 68 24.5 67V65H17Z"
        fill={color}
      />
      <path
        d="M8 14V19.5H6C4.83835 16.6841 3.04514 15.7509 0 14H8Z"
        fill={color}
      />
      <path
        d="M8 22.5V28H6C4.83835 25.1841 3.04514 24.2509 0 22.5H8Z"
        fill={color}
      />
      <path
        d="M8 31V36.5H6C4.83835 33.6841 3.04514 32.7509 0 31H8Z"
        fill={color}
      />
      <path
        d="M8 39.5V45H6C4.83835 42.1841 3.04514 41.2509 0 39.5H8Z"
        fill={color}
      />
      <path
        d="M8 48V53.5H6C4.83835 50.6841 3.04514 49.7509 0 48H8Z"
        fill={color}
      />
      <path
        d="M8 56.5V62H6C4.83835 59.1841 3.04514 58.2509 0 56.5H8Z"
        fill={color}
      />
      <path
        d="M8 6V11.5H6C4.83835 8.68405 3.04514 7.75095 0 6H8Z"
        fill={color}
      />
      <path
        d="M8 6L8 0L6 1.74846e-07C4.83835 3.07194 3.04514 4.08988 0 6L8 6Z"
        fill={color}
      />
      <path d="M8 65V75H6C4.83835 69.8801 4 68 0.5 67V65H8Z" fill={color} />
      <circle cx="49" cy="30" r="15.5" stroke={color} strokeWidth="3" />
      <rect
        x="48"
        y="29.0713"
        width="10"
        height="2.78009"
        transform="rotate(-45 48 29.0713)"
        fill={color}
      />
      <circle cx="49" cy="30" r="2" fill={color} />
    </svg>
  );
};

export default PiezomererIcon;
