import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type CreateSectionMutationVariables = Types.Exact<{
  data: Types.CreateSectionInput;
}>;


export type CreateSectionMutation = { __typename?: 'Mutation', createSection: string };


export const CreateSectionDocument = gql`
    mutation CreateSection($data: CreateSectionInput!) {
  createSection(data: $data)
}
    `;
export type CreateSectionMutationFn = Apollo.MutationFunction<CreateSectionMutation, CreateSectionMutationVariables>;
export type CreateSectionMutationResult = Apollo.MutationResult<CreateSectionMutation>;
export type CreateSectionMutationOptions = Apollo.BaseMutationOptions<CreateSectionMutation, CreateSectionMutationVariables>;