import { useMutation, useQuery } from '@apollo/client';
import axios from 'axios';
import { t } from 'i18next';
import {
  toastfyDimiss,
  toastfyError,
  toastfySuccess,
  toastifyLoading
} from '../../Components/Toastify';
import ToastifyModel from '../../Models/ToastifyModel';
import { API_URL } from '../../utils/const';
import { AtoFilterInput, PageInput, SortInput } from '../graphql/base-schema';
import {
  RecordApprovalFlowDocument,
  RecordApprovalFlowMutation,
  RecordApprovalFlowMutationVariables
} from '../graphql/generated/recordApprovalFlow';
import {
  FindAllRecordsPagDocument,
  FindAllRecordsPagQuery,
  FindAllRecordsPagQueryVariables
} from '../graphql/query/generated/findAllAtoRecordsPag';
import {
  FindAtoApproversDocument,
  FindAtoApproversQuery,
  FindAtoApproversQueryVariables
} from '../graphql/query/generated/findAtoApprovers';
import {
  FindGeneralInfoDocument,
  FindGeneralInfoQuery,
  FindGeneralInfoQueryVariables
} from '../graphql/query/generated/findGeneralInfo';

export const useGetAllAtoRecords = (
  atoId: string,
  pageInfo?: PageInput,
  filters?: AtoFilterInput,
  sortInfo?: [SortInput],
  onCompleted?: () => void,
  onError?: () => void
) => {
  const { data, loading, error, refetch } = useQuery<
    FindAllRecordsPagQuery,
    FindAllRecordsPagQueryVariables
  >(FindAllRecordsPagDocument, {
    variables: {
      atoId,
      pageInfo: pageInfo || {},
      filters: filters || {},
      sortInfo
    },
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    onCompleted,
    onError
  });

  return {
    data,
    loading,
    error,
    refetch
  };
};

export const useFindAtoApprovers = (
  structureId: string,
  clientId: string,
  onCompleted?: () => void,
  onError?: () => void
) => {
  const { data, loading, error, refetch } = useQuery<
    FindAtoApproversQuery,
    FindAtoApproversQueryVariables
  >(FindAtoApproversDocument, {
    variables: {
      structureId,
      clientId
    },
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    data,
    loading,
    error,
    refetch
  };
};

export const sendActivityFile = async (
  formData: FormData,
  url: string,
  recordId: string,
  token: string
) => {
  try {
    if (!formData) {
      toastfyError(t(ToastifyModel().toastifyMessage.fillRequiredFields));
      return;
    }

    toastifyLoading(`${t('registering')}...`);
    const response = await fetch(`${API_URL}${url}${recordId}`, {
      method: 'POST',
      body: formData,
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: token
      }
    });
    if (response.status === 200) {
      toastfyDimiss('toastLoading');
      toastfySuccess(`${t('registeredSuccessfully')}!`);
    }

    return response.status;
  } catch (error) {
    console.error(error);
    toastfyDimiss('toastLoading');
    toastfyError(t(ToastifyModel().toastifyMessage.error));
  }
};

export const sendPhotoFile = async (
  formData: FormData,
  url: string,
  recordId: string,
  token: string
) => {
  try {
    if (!formData) {
      toastfyError(t(ToastifyModel().toastifyMessage.fillRequiredFields));
      return;
    }

    toastifyLoading(`${t('registering')}...`);
    const response = await axios.post(`${API_URL}${url}${recordId}`, formData, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: token
      }
    });
    if (response.status === 200) {
      toastfyDimiss('toastLoading');
      toastfySuccess(`${t('registeredSuccessfully')}!`);
    }

    return response;
  } catch (error) {
    console.error(error);
    toastfyDimiss('toastLoading');
    toastfyError(t(ToastifyModel().toastifyMessage.error));
  }
};

export const sendVideoFile = async (
  formData: FormData,
  url: string,
  recordId: string,
  token: string
) => {
  try {
    if (!formData) {
      toastfyError(t(ToastifyModel().toastifyMessage.fillRequiredFields));
      return;
    }

    toastifyLoading(`${t('registering')}...`);
    const response = await fetch(`${API_URL}${url}${recordId}`, {
      method: 'POST',
      body: formData,
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: token
      }
    });
    if (response.status === 201) {
      toastfyDimiss('toastLoading');
      toastfySuccess(`${t('registeredSuccessfully')}!`);
    }

    return response.status;
  } catch (error) {
    console.error(error);
    toastfyDimiss('toastLoading');
    toastfyError(t(ToastifyModel().toastifyMessage.error));
  }
};

export function useFindGeneralInfo(
  atoId: string,
  onCompleted?: () => void,
  onError?: () => void
) {
  const { data, loading, error } = useQuery<
    FindGeneralInfoQuery,
    FindGeneralInfoQueryVariables
  >(FindGeneralInfoDocument, {
    variables: {
      atoId: atoId
    },
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    data,
    loading,
    error
  };
}

export const useRecordApprovalFlow = (
  onCompleted?: () => void,
  onError?: () => void
) => {
  const [recordApprovalFlow, { data, error, loading }] = useMutation<
    RecordApprovalFlowMutation,
    RecordApprovalFlowMutationVariables
  >(RecordApprovalFlowDocument, {
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    recordApprovalFlow,
    data,
    error,
    loading
  };
};
