import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type EditStructureSummaryMutationVariables = Types.Exact<{
  data: Types.EditStructureSummaryInput;
}>;


export type EditStructureSummaryMutation = { __typename?: 'Mutation', editStructureSummary: string };


export const EditStructureSummaryDocument = gql`
    mutation EditStructureSummary($data: EditStructureSummaryInput!) {
  editStructureSummary(data: $data)
}
    `;
export type EditStructureSummaryMutationFn = Apollo.MutationFunction<EditStructureSummaryMutation, EditStructureSummaryMutationVariables>;
export type EditStructureSummaryMutationResult = Apollo.MutationResult<EditStructureSummaryMutation>;
export type EditStructureSummaryMutationOptions = Apollo.BaseMutationOptions<EditStructureSummaryMutation, EditStructureSummaryMutationVariables>;