import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PiezometerListPage from '../Monitoring/PiezometerListPage';
import PluviometerListPage from '../Monitoring/PluviometerListPage';
import SurfaceMarkersListPage from '../Monitoring/SurfaceMarkerListPage';
import WaterLevelListPage from '../Monitoring/WaterLevelListPage';
import DrawerMenuTemplate from '../../Templates/DrawerMenuTemplate/DrawerMenuTemplate';
import { MenuItemType } from '../../Molecules/RecursiveMenu/RecursiveMenu.interface';
import { MdOutlineWaterDrop } from 'react-icons/md';
import PiezomererIcon from '../../icons/piezometerIcon';
import InaIcon from '../../icons/inaIcon';
import { FaWater } from 'react-icons/fa6';
import SurfaceMarkerIcon from '../../icons/surfaceMarkerIcon';
import { IconType } from 'react-icons';
import { InstrumentType } from './InstrumentDataListPage.interface';
import RecursiveMenu from '../../Molecules/RecursiveMenu/RecursiveMenu';

const InstrumentDataListPage = () => {
  const { structureId, instrumentType } = useParams();

  const navigate = useNavigate();

  const instrumentListPageMap: {
    [key in InstrumentType]: React.ComponentType;
  } = {
    piezometer: PiezometerListPage,
    ina: PiezometerListPage,
    pluviometer: PluviometerListPage,
    waterlevel: WaterLevelListPage,
    surfacemarker: SurfaceMarkersListPage
  };

  const InstrumentComponent =
    instrumentListPageMap[instrumentType as InstrumentType] || null;

  const instrumentTypes = [
    { type: 'piezometer', icon: PiezomererIcon as unknown as IconType },
    { type: 'ina', icon: InaIcon as unknown as IconType },
    { type: 'pluviometer', icon: MdOutlineWaterDrop },
    { type: 'waterlevel', icon: FaWater },
    { type: 'surfacemarker', icon: SurfaceMarkerIcon as unknown as IconType }
  ];

  const menuItems: MenuItemType[] = instrumentTypes.map(
    (instrument, index) => ({
      id: index.toString(),
      icon: instrument.icon,
      name: instrument.type,
      identifier: instrument.type,
      children: []
    })
  );

  const handleItemClick = (item: MenuItemType[]) => {
    const selectedInstrument = item[0].name;
    navigate(`/${structureId}/monitoring/instrument/${selectedInstrument}`);
  };

  return (
    <DrawerMenuTemplate
      title=""
      titleMenu="Instruments"
      width={250}
      menu={
        <RecursiveMenu
          items={menuItems}
          onClick={(item) => handleItemClick(item)}
          checkbox={false}
        />
      }
      content={InstrumentComponent && <InstrumentComponent />}
    />
  );
};

export default InstrumentDataListPage;
