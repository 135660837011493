import { useState } from 'react';

import {
  useDragTableHookProps,
  useDragTableResult
} from './useDragTable.interface';
import { saveLocalStorageTablePreferences } from '../../Components/V2/localStorage/localStorageFunctions';

export const useDragTable = ({
  columns,
  setColumns,
  userId,
  tableId
}: useDragTableHookProps): useDragTableResult => {
  const [columnDragging, setColumnDragging] = useState<string>('');
  const [columnOver, setColumnOver] = useState<string>('');

  const handleDragStart = (column: string) => {
    setColumnDragging(column);
  };

  const handleDragOver = (column: string) => {
    setColumnOver(column);
  };

  const handleDragEnd = () => {
    const newColumns = [...columns];
    const fromIndex = newColumns.findIndex((obj) => obj.key === columnDragging);
    const toIndex = newColumns.findIndex((obj) => obj.key === columnOver);

    if (fromIndex === -1 || toIndex === -1) {
      return;
    }

    const [movedObject] = newColumns.splice(fromIndex, 1);

    newColumns.splice(toIndex, 0, movedObject);
    saveLocalStorageTablePreferences(
      userId,
      tableId,
      'orderColumns',
      newColumns
    );
    setColumns!(newColumns);
    setColumnOver!('');
    setColumnDragging('');
  };

  const handleDragLeave = () => {
    setColumnOver('');
  };

  return {
    columnDragging,
    columnOver,
    handleDragStart,
    handleDragOver,
    handleDragEnd,
    handleDragLeave
  };
};
