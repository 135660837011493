import { ApolloClient, HttpLink, InMemoryCache, gql } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { createContext, useContext, useEffect, useState } from 'react';
import { BsExclamation } from 'react-icons/bs';
import { io } from 'socket.io-client';
import { NotificationListType, UserData } from '../@Types/types';
import { ProjectContext } from '../Context/ContextAPI';
import {
  ExclamationStyle,
  UserCardStyle,
  UserDiv
} from '../Styles/UserBadgeStyle';
import { API_URL } from '../utils/const';
import UserCard from './UserCard';
import GeneralNotification from './V2/Organisms/GeneralNotification/GeneralNotification';

export const NotificationContext = createContext({});

function UserBadge() {
  const { userData } = useContext(ProjectContext) as {
    userData: UserData;
  };

  const [showUserCard, setShowUserCard] = useState<boolean>(false);
  const [showNotificationsCard, setShowNotificationsCard] =
    useState<boolean>(false);
  const [lastNotificationItemsList, setLastNotificationItemsList] = useState<
    NotificationListType[]
  >([]);
  const [allNotificationItemsList, setAllNotificationItemsList] = useState<
    NotificationListType[]
  >([]);
  const [hasNotification, setHasNotification] = useState<boolean | null>(null);
  const [showModalSupport, setShowModalSupport] = useState<boolean>(false);

  useEffect(() => {
    const socket = io(API_URL as string);

    const handleSocketOpen = () => {
      socket.on(userData.id, (data) => {
        if (data.length > 0) {
          setHasNotification(!hasNotification);
        }
      });
    };

    socket.on('connect', handleSocketOpen);

    return () => {
      socket.disconnect();
      socket.off('instrument-notification');
    };
  }, [hasNotification, userData.id]);

  const fetchLastNotificationItens = async () => {
    const httpLink = new HttpLink({
      uri: `${API_URL}/graphql`
    });
    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          Authorization: userData.token
        }
      };
    });

    const client = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache()
    });

    const response = await client.query({
      query: gql`
        query ListLatestUserNotification {
          listLatestUserNotification {
            latestNotifications {
              alertLevel
              installLocation
              instrumentNotificationId
              readingDate
              sentAt
              structureName
              unread
            }
            unreadCount
          }
        }
      `
    });
    if (response.data) {
      setLastNotificationItemsList(
        response.data.listLatestUserNotification.latestNotifications
      );
    }
  };

  const getFirstAndLastLetters = (str: string) => {
    if (!str.trim()) {
      return '';
    }

    const words = str.trim().split(' ');
    const firstLetter = words[0][0];
    const lastLetter = words[words.length - 1][0];
    if (words.length === 1) {
      return `${firstLetter}`.toUpperCase();
    }

    return `${firstLetter}${lastLetter}`.toUpperCase();
  };

  return (
    <NotificationContext.Provider
      value={{
        lastNotificationItemsList,
        allNotificationItemsList,
        setAllNotificationItemsList
      }}
    >
      <UserDiv
        onMouseEnter={() => setShowUserCard(true)}
        onMouseLeave={() => setShowUserCard(false)}
        style={showUserCard || showModalSupport ? UserCardStyle : {}}
      >
        {showUserCard || showModalSupport ? (
          <UserCard
            setShowUserCard={setShowUserCard}
            fetchLastNotificationItens={fetchLastNotificationItens}
            setShowNotificationsCard={setShowNotificationsCard}
            hasNotification={hasNotification}
            setHasNotification={setHasNotification}
            getFirstAndLastLetters={getFirstAndLastLetters}
            showModalSupport={showModalSupport}
            setShowModalSupport={setShowModalSupport}
          />
        ) : (
          <>
            {getFirstAndLastLetters(userData.name)}
            {hasNotification !== null && (
              <ExclamationStyle style={{ top: 'unset', bottom: 0, right: -1 }}>
                <BsExclamation style={{ color: 'white' }} />
              </ExclamationStyle>
            )}
          </>
        )}
        {showNotificationsCard && (
          <GeneralNotification
            setShowNotificationsCard={setShowNotificationsCard}
          />
        )}
      </UserDiv>
    </NotificationContext.Provider>
  );
}

export default UserBadge;
