import React from 'react';
import { Color } from '../../../Styles/Styles';
interface InaIconProps {
  size?: number;
  color?: string;
}
const InaIcon = ({ size = 124, color = Color.Brown1 }: InaIconProps) => {
  return (
    <svg
      width={size}
      height={(size * 84) / 74}
      viewBox="0 0 74 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 58.9951C3.5 58.9951 3.5 64.4951 9 64.4951C14.5 64.4951 14.5 58 18 57.9951C19.5 57.993 21 60.5 22.5 60.5"
        stroke={color}
        strokeWidth="4"
      />
      <path
        d="M1 75.9946C3.5 75.9946 3.5 81.4946 9 81.4946C14.5 81.4946 14.5 74.9995 18 74.9946C22 74.9891 22.5 81.492 27 81.4949C31.5 81.4977 32.7898 75.9946 37 75.9946C41 75.9946 40.5 81.4946 45 81.4977C49 81.4946 51 74.9944 56.5 74.9946"
        stroke={color}
        strokeWidth="4"
      />
      <path
        d="M73.5 58.9231C71 58.9231 71 64 65.5 64C60 64 60 58.0045 56.5 58C55 57.9981 53.5 60.5 51.5 60.5"
        stroke={color}
        strokeWidth="4"
      />
      <path
        d="M73.5 75.928C71 75.928 71 81.0049 65.5 81.0049C60 81.0049 60 75.0094 56.5 75.0049"
        stroke={color}
        strokeWidth="4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.5 11L39.4926 6.69657C39.4975 6.63241 39.5 6.56689 39.5 6.5C39.5 5.11929 38.3807 4 37 4C35.6193 4 34.5 5.11929 34.5 6.5C34.5 6.56689 34.5025 6.63242 34.5074 6.69659L34.5 11V50.0663L32.5829 51.2357C30.1259 52.7344 28.5 55.4291 28.5 58.5C28.5 63.1944 32.3056 67 37 67C41.6944 67 45.5 63.1944 45.5 58.5C45.5 55.4291 43.8741 52.7344 41.4171 51.2357L39.5 50.0663V11ZM43.5 47.8208C47.098 50.0154 49.5 53.9772 49.5 58.5C49.5 65.4036 43.9036 71 37 71C30.0964 71 24.5 65.4036 24.5 58.5C24.5 53.9772 26.902 50.0154 30.5 47.8208V7H30.5189C30.5064 6.83498 30.5 6.66824 30.5 6.5C30.5 6.33176 30.5064 6.16502 30.5189 6C30.7743 2.64378 33.5784 0 37 0C40.4216 0 43.2257 2.64377 43.4811 5.99999C43.4936 6.16501 43.5 6.33176 43.5 6.5C43.5 6.66824 43.4936 6.83498 43.4811 7H43.5V47.8208Z"
        fill={color}
      />
      <rect x="40" y="31" width="8" height="4" rx="2" fill={color} />
      <rect x="40" y="21" width="8" height="4" rx="2" fill={color} />
      <rect x="40" y="11" width="8" height="4" rx="2" fill={color} />
    </svg>
  );
};

export default InaIcon;
