import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type FindAllAtoImagesQueryVariables = Types.Exact<{
  atoId: Types.Scalars['String']['input'];
}>;


export type FindAllAtoImagesQuery = { __typename?: 'Query', findAllAtoImages: Array<{ __typename?: 'AtoImagesType', recordNumber: string, images: Array<{ __typename?: 'AtoImageType', image: string }> }> };


export const FindAllAtoImagesDocument = gql`
    query FindAllAtoImages($atoId: String!) {
  findAllAtoImages(atoId: $atoId) {
    recordNumber
    images {
      image
    }
  }
}
    `;
export type FindAllAtoImagesQueryResult = Apollo.QueryResult<FindAllAtoImagesQuery, FindAllAtoImagesQueryVariables>;