import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ExportPiezometerInaReadingsByStructureQueryVariables = Types.Exact<{
  instrumentType: Types.InstrumentTypeEnum;
  structureId: Types.Scalars['String']['input'];
  selectedFields:
    | Array<Types.Scalars['String']['input']>
    | Types.Scalars['String']['input'];
  filters?: Types.InputMaybe<Types.ExportPiezometerInaReadingFiltersInput>;
}>;

export type ExportPiezometerInaReadingsByStructureQuery = {
  __typename?: 'Query';
  exportPiezometerInaReadingsByStructure: string;
};

export const ExportPiezometerInaReadingsByStructureDocument = gql`
  query ExportPiezometerInaReadingsByStructure(
    $instrumentType: InstrumentTypeEnum!
    $structureId: String!
    $selectedFields: [String!]!
    $filters: ExportPiezometerInaReadingFiltersInput
  ) {
    exportPiezometerInaReadingsByStructure(
      instrumentType: $instrumentType
      structureId: $structureId
      selectedFields: $selectedFields
      filters: $filters
    )
  }
`;

export type ExportPiezometerInaReadingsByStructureQueryResult =
  Apollo.QueryResult<
    ExportPiezometerInaReadingsByStructureQuery,
    ExportPiezometerInaReadingsByStructureQueryVariables
  >;
