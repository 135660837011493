import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type CreateStructureGeometryMutationVariables = Types.Exact<{
  data: Types.CreateStructureGeometryInput;
  structureId: Types.Scalars['String']['input'];
}>;


export type CreateStructureGeometryMutation = { __typename?: 'Mutation', createStructureGeometry: string };


export const CreateStructureGeometryDocument = gql`
    mutation CreateStructureGeometry($data: CreateStructureGeometryInput!, $structureId: String!) {
  createStructureGeometry(data: $data, structureId: $structureId)
}
    `;
export type CreateStructureGeometryMutationFn = Apollo.MutationFunction<CreateStructureGeometryMutation, CreateStructureGeometryMutationVariables>;
export type CreateStructureGeometryMutationResult = Apollo.MutationResult<CreateStructureGeometryMutation>;
export type CreateStructureGeometryMutationOptions = Apollo.BaseMutationOptions<CreateStructureGeometryMutation, CreateStructureGeometryMutationVariables>;