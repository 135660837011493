import { useLazyQuery, useQuery } from '@apollo/client';
import {
  ListCompaniesDocument,
  ListCompaniesQuery,
  ListCompaniesQueryVariables
} from '../../graphql/query/generated/listCompanies.query';

export function useListCompany(
  dataCompanies: boolean,
  onCompleted?: () => void,
  onError?: () => void
) {
  const { data, loading, error } = useQuery<
    ListCompaniesQuery,
    ListCompaniesQueryVariables
  >(ListCompaniesDocument, {
    variables: {
      data: {
        listAllCompanies: dataCompanies
      }
    },
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    data,
    loading,
    error
  };
}

export function useListCompanyLazy(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [listCompanyLazy, { data, loading, error }] = useLazyQuery<
    ListCompaniesQuery,
    ListCompaniesQueryVariables
  >(ListCompaniesDocument, {
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    listCompanyLazy,
    data,
    loading,
    error
  };
}
