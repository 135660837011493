import Text from '../../Atoms/Text/Text';
import ActionMenu from '../../Molecules/ActionMenu/ActionMenu';
import IconProfile from '../../Molecules/IconProfile/IconProfile';
import { CommentProps } from './Comment.interface';
import {
  DivComment,
  DivContainerComment,
  DivContainerTiltle,
  DivTitle,
  DivAction
} from './Comment.styles';

const Comment = ({ acronym, date, user, comment, actions }: CommentProps) => {
  return (
    <DivContainerComment>
      <IconProfile acronym={acronym} />
      <DivComment>
        <DivContainerTiltle>
          <DivTitle>
            <Text type={'p'} size="small">
              {user}
            </Text>
            <Text type={'p'} size="small" color="black">
              {date}
            </Text>
          </DivTitle>
          {actions && (
            <DivAction
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <ActionMenu
                availableContextActions={actions}
                dataDrivenPermissions={{ delete: true }}
              />
            </DivAction>
          )}
        </DivContainerTiltle>
        <Text type={'p'} color="black">
          {comment}
        </Text>
      </DivComment>
    </DivContainerComment>
  );
};

export default Comment;
