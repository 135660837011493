import { useMutation } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { z } from 'zod';
import {
  ActionPlanReprovalFlowDocument,
  ActionPlanReprovalFlowMutation,
  ActionPlanReprovalFlowMutationVariables
} from '../../../../data/graphql/generated/actionPlanReprovalFlow.mutation';
import {
  SetActionPlanCanceledDocument,
  SetActionPlanCanceledMutation,
  SetActionPlanCanceledMutationVariables
} from '../../../../data/graphql/generated/setActionPlanCanceled.mutation';
import { FindActionPlanAllInfoDocument } from '../../../../data/services/ActionPlanService';
import ErrorsTreatments from '../../../../utils/errorTreatment';
import { toastfyError, toastfySuccess } from '../../../Toastify';
import Button from '../../Atoms/Button/Button';
import InputTextarea from '../../Molecules/InputTextarea/InputTextarea';
import ViewModal from '../../Molecules/ViewModal/ViewModal';
import { ActionPlanReprovalFlowProps } from './ActionPlanReprovalFlow.interface';
import {
  DivButtonReproveFlow,
  DivReprovalFlow
} from './ActionPlanReprovalFlow.styles';
import { FindActionPlanDataDocument } from '../../../../data/graphql/query/generated/findActionPlanData.query';

const ActionPlanReprovalFlow = ({
  setIsReproval,
  situation
}: ActionPlanReprovalFlowProps) => {
  const { t } = useTranslation();
  const [commentReproval, setCommentReproval] = useState('');
  const { actionPlanId } = useParams();

  const [actionPlanReprovalFlow] = useMutation<
    ActionPlanReprovalFlowMutation,
    ActionPlanReprovalFlowMutationVariables
  >(ActionPlanReprovalFlowDocument);

  const [setActionPlanCanceled] = useMutation<
    SetActionPlanCanceledMutation,
    SetActionPlanCanceledMutationVariables
  >(SetActionPlanCanceledDocument);

  const commentReprovalSchema = z.object({
    commentReproval: z
      .string()
      .refine((val) => val.length > 0, { message: t('fieldIsRequired') })
  });

  type commentReprovalSchemaType = z.infer<typeof commentReprovalSchema>;

  const {
    register: registerCommentReproval,
    handleSubmit: handleSubmitCommentReproval,
    formState: { errors: errorsCommentReproval }
  } = useForm<commentReprovalSchemaType>({
    resolver: zodResolver(commentReprovalSchema),
    values: {
      commentReproval: ''
    }
  });

  const handleReproveFlow = (data: commentReprovalSchemaType) => {
    if (situation !== 'Opened') {
      actionPlanReprovalFlow({
        variables: {
          actionPlanId: actionPlanId ?? '',
          comment: data.commentReproval
        },
        onCompleted: () => {
          toastfySuccess(t('actionPlanReproved'));
          setCommentReproval('');
          setIsReproval(false);
        },
        onError: ({ graphQLErrors }) => {
          const errorMessage = ErrorsTreatments(graphQLErrors[0].message, t);
          toastfyError(errorMessage);
        },
        refetchQueries: [
          FindActionPlanDataDocument,
          FindActionPlanAllInfoDocument
        ]
      });
    } else {
      setActionPlanCanceled({
        variables: {
          comment: data.commentReproval,
          actionPlanId: actionPlanId ?? ''
        },
        onCompleted: () => {
          toastfySuccess(t('actionPlanReproved'));
          setCommentReproval('');
          setIsReproval(false);
        },
        onError: ({ graphQLErrors }) => {
          const errorMessage = ErrorsTreatments(graphQLErrors[0].message, t);
          toastfyError(errorMessage);
        },
        refetchQueries: [
          FindActionPlanDataDocument,
          FindActionPlanAllInfoDocument
        ]
      });
    }
  };

  return (
    <ViewModal
      showModal={true}
      width={30}
      height={50}
      title={t('desiredReproval')}
      onClose={() => setIsReproval(false)}
      component={
        <DivReprovalFlow>
          <InputTextarea
            name="commentReproval"
            label={t('reproveComment')}
            width="100%"
            register={registerCommentReproval}
            error={!!errorsCommentReproval.commentReproval}
            errorMessage={errorsCommentReproval.commentReproval?.message}
            onChange={(event) => setCommentReproval(event.target.value)}
            value={commentReproval}
          />
          <DivButtonReproveFlow>
            <Button
              variant={'secondary'}
              text="Cancel"
              size="small"
              onClick={() => setIsReproval(false)}
            />
            <Button
              variant={'primary'}
              text="reprove"
              size="small"
              onClick={handleSubmitCommentReproval(handleReproveFlow)}
            />
          </DivButtonReproveFlow>
        </DivReprovalFlow>
      }
    />
  );
};

export default ActionPlanReprovalFlow;
