import InputMask from 'react-input-mask';
import styled from 'styled-components';
import { Color, FontSize } from '../../../../Styles/Styles';

interface InputStyledProps {
  hasValue: boolean;
}

interface InputMaskProps {
  error?: boolean | undefined;
  width?: string;
}

export const InputContainer = styled.div``;

export const InputStyled = styled.div<InputStyledProps>`
  width: 100%;
  position: relative;
  label {
    position: absolute;
    top: ${({ hasValue }) => (hasValue ? '-15px' : '10px')};
    left: 5px;
    pointer-events: none;
    transition: all 0.2s ease;
  }
  input:focus ~ label {
    top: -15px;
  }
`;

export const ErrorStyled = styled.span`
  position: absolute;
  left: 5px;
  font-size: ${FontSize.Xsmall};
  color: ${Color.Red1};
`;

export const InputMaskStyle = styled(InputMask)<InputMaskProps>`
  width: 220px;
  display: block;
  width: ${({ width }) => (width ? `min(${width}, 100%)` : 'min(300px, 100%)')};
  padding: 10px;
  border: none;
  background: transparent;
  font-size: ${FontSize.Small};
  border-bottom: 1px solid ${({ error }) => (error ? Color.Red1 : Color.Brown1)};
  color: ${({ error }) => (error ? Color.Red1 : Color.Brown1)};
`;
