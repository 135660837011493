import { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';
import InstrumentsModel from '../../Models/InstrumentsModel';
import Toggles from '../Buttons/Toggles/Toggles';
import {
  ContainerToggleMenu,
  MapMenuStyle,
  MenuTitle,
  ToggleMenu,
  checkBoxStyle,
  disabledCheckBoxStyle
} from './MapStyle';

export const MapMenu = ({
  setSelectedInstruments,
  selectedInstruments,
  instruments,
  instrumentsType,
  setSelectedSection,
  selectedSection,
  status,
  setStatus,
  icons,
  setIcons
}: {
  setSelectedInstruments: Dispatch<string[]>;
  selectedInstruments: string[];
  instruments?: string[];
  instrumentsType?: any;
  setSelectedSection: Dispatch<string[]>;
  selectedSection: string[];
  status: boolean;
  setStatus: Dispatch<boolean>;
  icons: boolean;
  setIcons: Dispatch<boolean>;
}) => {
  const { t } = useTranslation();

  const handleInstrumentClick = (instrumentId: string) => {
    const isSelected = selectedInstruments.includes(instrumentId);

    if (isSelected) {
      const updatedList = selectedInstruments.filter(
        (id) => id !== instrumentId
      );
      setSelectedInstruments(updatedList);
    } else {
      setSelectedInstruments([...selectedInstruments, instrumentId]);
    }
  };

  const handleSectionClick = (sectionId: string) => {
    const isSelected = selectedSection?.includes(sectionId);

    if (isSelected) {
      const updatedList = selectedSection?.filter((id) => id !== sectionId);
      setSelectedSection(updatedList);
    } else {
      setSelectedSection([...selectedSection, sectionId]);
    }
  };

  const getTotal = (type: string) => {
    const features = instrumentsType.typesList.find(
      (x: any) => x.type.toLocaleLowerCase() === type.toLocaleLowerCase()
    );

    if (features) return features.totalActive || 0;
    return 0;
  };

  return (
    <>
      <MapMenuStyle>
        <section>
          <div>
            <MenuTitle>{t('Instruments')}</MenuTitle>
            {selectedSection.length === 0
              ? InstrumentsModel()
                  .types.filter((f) =>
                    instruments?.includes(f.id.toLocaleUpperCase())
                  )
                  .map((v, i) => (
                    <span
                      key={i}
                      id={v.id}
                      onClick={() => handleInstrumentClick(v.id)}
                    >
                      {v.label} ({getTotal(v.id)}){' '}
                      {selectedInstruments.includes(v.id) ? (
                        <MdCheckBox style={checkBoxStyle} />
                      ) : (
                        <MdCheckBoxOutlineBlank style={checkBoxStyle} />
                      )}
                    </span>
                  ))
              : InstrumentsModel()
                  .types.filter((f) =>
                    instruments?.includes(f.id.toLocaleUpperCase())
                  )
                  .map((v, i) => (
                    <span key={i} id={v.id}>
                      {v.label} ({getTotal(v.id)}){' '}
                      <MdCheckBox style={disabledCheckBoxStyle} />
                    </span>
                  ))}
          </div>
          <div>
            <MenuTitle>{t('Sections')}</MenuTitle>
            {instrumentsType?.sectionsList.map((v: any, i: number) => (
              <span key={i} id={v.id} onClick={() => handleSectionClick(v.id)}>
                {v.name}{' '}
                {selectedSection?.includes(v.id) ? (
                  <MdCheckBox style={checkBoxStyle} />
                ) : (
                  <MdCheckBoxOutlineBlank style={checkBoxStyle} />
                )}
              </span>
            ))}
          </div>
          <ContainerToggleMenu>
            <ToggleMenu>
              {Toggles.ToggleStatus({
                check: status,
                setCheck: setStatus,
                key: 'status',
                text: `${t('active')}`
              })}
            </ToggleMenu>
            <ToggleMenu>
              {Toggles.ToggleStatus({
                check: icons,
                setCheck: setIcons,
                key: 'icons',
                text: `${t('Icons')}`
              })}
            </ToggleMenu>
          </ContainerToggleMenu>
        </section>
      </MapMenuStyle>
    </>
  );
};
