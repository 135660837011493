import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ListReadingByWaterLevelPagQueryVariables = Types.Exact<{
  structureInfo: Types.StructureInfoInput;
  pageInfo?: Types.InputMaybe<Types.PageInput>;
  sortInfo?: Types.InputMaybe<Array<Types.SortInput> | Types.SortInput>;
  filters?: Types.InputMaybe<Types.FilterInput>;
}>;


export type ListReadingByWaterLevelPagQuery = { __typename?: 'Query', listReadingByWaterLevelPag: { __typename?: 'PagWaterLevelReadingType', count: number, nodes: Array<{ __typename?: 'PaginatedWaterLevelReadingType', structureType?: string | null, readingType?: string | null, date: Date, observation?: string | null, value: number, coordinateE: number, coordinateN: number, name: string, operationalStatus: Types.InstrumentStatusEnum, instrumentId: string, readingId: string }>, pageInfo: { __typename?: 'PageInfo', page: number, offset: number, limit: number, totalPages: number, hasNextPage: boolean, hasPreviousPage: boolean } } };


export const ListReadingByWaterLevelPagDocument = gql`
    query ListReadingByWaterLevelPag($structureInfo: StructureInfoInput!, $pageInfo: PageInput, $sortInfo: [SortInput!], $filters: FilterInput) {
  listReadingByWaterLevelPag(
    structureInfo: $structureInfo
    pageInfo: $pageInfo
    sortInfo: $sortInfo
    filters: $filters
  ) {
    nodes {
      structureType
      readingType
      date
      observation
      value
      coordinateE
      coordinateN
      name
      operationalStatus
      instrumentId
      readingId
    }
    count
    pageInfo {
      page
      offset
      limit
      totalPages
      hasNextPage
      hasPreviousPage
    }
  }
}
    `;
export type ListReadingByWaterLevelPagQueryResult = Apollo.QueryResult<ListReadingByWaterLevelPagQuery, ListReadingByWaterLevelPagQueryVariables>;