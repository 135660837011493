import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type CreateStructureReservoirHandlingMutationVariables = Types.Exact<{
  data: Types.CreateReservoirHandlingInput;
  structureId: Types.Scalars['String']['input'];
}>;


export type CreateStructureReservoirHandlingMutation = { __typename?: 'Mutation', createStructureReservoirHandling: string };


export const CreateStructureReservoirHandlingDocument = gql`
    mutation CreateStructureReservoirHandling($data: CreateReservoirHandlingInput!, $structureId: String!) {
  createStructureReservoirHandling(data: $data, structureId: $structureId)
}
    `;
export type CreateStructureReservoirHandlingMutationFn = Apollo.MutationFunction<CreateStructureReservoirHandlingMutation, CreateStructureReservoirHandlingMutationVariables>;
export type CreateStructureReservoirHandlingMutationResult = Apollo.MutationResult<CreateStructureReservoirHandlingMutation>;
export type CreateStructureReservoirHandlingMutationOptions = Apollo.BaseMutationOptions<CreateStructureReservoirHandlingMutation, CreateStructureReservoirHandlingMutationVariables>;