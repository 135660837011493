/* eslint-disable @typescript-eslint/no-explicit-any */
// CustomControlLevelTable
import React from 'react';
import { TableComponentStyle, TableArea } from '../TablesStyle';
import { useTranslation } from 'react-i18next';
import { MdDelete } from 'react-icons/md';
import { Color } from '../../../Styles/Styles';

export const CustomControlLevelTable = ({ controlLevels, onDelete }: any) => {
  const { t } = useTranslation();

  return (
    <TableArea>
      <TableComponentStyle>
        <thead>
          <tr>
            <th>{t('name')}</th>
            <th>{t('value')}</th>
            <th>{t('Actions')}</th>
          </tr>
        </thead>
        <tbody>
          {controlLevels.map((level: any, index: number) => (
            <tr key={index}>
              <td>{level.name}</td>
              <td>{level.value}</td>
              <td>
                <div
                  style={{
                    cursor: 'pointer',
                    height: '100%',
                    width: '100%'
                  }}
                >
                  <MdDelete
                    color={Color.Brown1}
                    onClick={() => onDelete(index)}
                  >
                    {t('Delete')}
                  </MdDelete>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </TableComponentStyle>
    </TableArea>
  );
};
