import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type EditStructureGeometryMutationVariables = Types.Exact<{
  data: Types.EditGeometryInput;
}>;


export type EditStructureGeometryMutation = { __typename?: 'Mutation', editStructureGeometry: string };


export const EditStructureGeometryDocument = gql`
    mutation EditStructureGeometry($data: EditGeometryInput!) {
  editStructureGeometry(data: $data)
}
    `;
export type EditStructureGeometryMutationFn = Apollo.MutationFunction<EditStructureGeometryMutation, EditStructureGeometryMutationVariables>;
export type EditStructureGeometryMutationResult = Apollo.MutationResult<EditStructureGeometryMutation>;
export type EditStructureGeometryMutationOptions = Apollo.BaseMutationOptions<EditStructureGeometryMutation, EditStructureGeometryMutationVariables>;