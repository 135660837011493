import styled from 'styled-components';

export const Ul = styled.ul`
  list-style-type: none;
  padding: 5px;
  box-sizing: border-box;
  width: 100%;
`;

export const Li = styled.li`
  list-style: none;
  padding: 0 5px;
  box-sizing: border-box;
  width: 100%;
`;

export const DrawerItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  padding: 5px 8px;
  gap: 5px;
  width: 100%;
  cursor: pointer;
  transition:
    background 0.2s,
    transform 0.3s ease-in-out,
    opacity 0.3s ease-in-out;

  &:hover {
    background: #ddd;
    transform: translateX(5px);
  }
`;
