import React from 'react';
import { ImageProps } from './Image.interfaces';
import { StyledImage } from './Image.styles';

const Image: React.FC<ImageProps> = ({
  src,
  alt,
  width,
  height,
  maxHeight,
  borderRadius,
  onClick,
  objectFit,
  ...props
}) => {
  return (
    <StyledImage
      src={src}
      alt={alt}
      width={width}
      height={height}
      borderRadius={borderRadius}
      hover={!!onClick}
      onClick={onClick}
      objectFit={objectFit}
      maxHeight={maxHeight}
      {...props}
    />
  );
};

export default Image;
