import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type SaveDataToSectionChartMutationVariables = Types.Exact<{
  parameters: Types.ParametersToChartInput;
  sectionId: Types.Scalars['String']['input'];
}>;


export type SaveDataToSectionChartMutation = { __typename?: 'Mutation', saveDataToSectionChart: string };


export const SaveDataToSectionChartDocument = gql`
    mutation SaveDataToSectionChart($parameters: ParametersToChartInput!, $sectionId: String!) {
  saveDataToSectionChart(parameters: $parameters, sectionId: $sectionId)
}
    `;
export type SaveDataToSectionChartMutationFn = Apollo.MutationFunction<SaveDataToSectionChartMutation, SaveDataToSectionChartMutationVariables>;
export type SaveDataToSectionChartMutationResult = Apollo.MutationResult<SaveDataToSectionChartMutation>;
export type SaveDataToSectionChartMutationOptions = Apollo.BaseMutationOptions<SaveDataToSectionChartMutation, SaveDataToSectionChartMutationVariables>;