import { Map, Marker } from '@vis.gl/react-google-maps';
import { useStructureSummary } from '../../data/hooks/use-structure-summary.query';
import { useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import {
  DEFAULT_CENTER_BRAZIL,
  DEFAULT_MAP_ZOOM,
  WITH_CENTER_MAP_ZOOM
} from './mapGoogle.constants';

const MapGoogle = () => {
  const { structureId } = useParams();
  const { data: structure, loading } = useStructureSummary(
    '',
    structureId || ''
  );

  const { latitude, longitude } = structure?.getStructureSummary?.summary || {};

  const shouldRenderCenterPosition = latitude && longitude;
  const defaultZoom = shouldRenderCenterPosition
    ? WITH_CENTER_MAP_ZOOM
    : DEFAULT_MAP_ZOOM;
  const defaultCenter = shouldRenderCenterPosition
    ? { lat: latitude!, lng: longitude! }
    : DEFAULT_CENTER_BRAZIL;

  return (
    <>
      {loading ? (
        <Skeleton />
      ) : (
        <Map
          defaultZoom={defaultZoom}
          defaultCenter={defaultCenter}
          mapTypeId={window.google?.maps?.MapTypeId?.SATELLITE}
          streetViewControl={false}
        >
          <Marker
            position={{ lat: latitude!, lng: longitude! }}
            title={structure?.getStructureSummary?.name}
          />
        </Map>
      )}
    </>
  );
};

export default MapGoogle;
