import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type EditWaterLevelMutationVariables = Types.Exact<{
  structureInfo: Types.StructureInfoInput;
  data: Types.EditWaterLevelInput;
}>;


export type EditWaterLevelMutation = { __typename?: 'Mutation', editWaterLevel: string };


export const EditWaterLevelDocument = gql`
    mutation EditWaterLevel($structureInfo: StructureInfoInput!, $data: EditWaterLevelInput!) {
  editWaterLevel(structureInfo: $structureInfo, data: $data)
}
    `;
export type EditWaterLevelMutationFn = Apollo.MutationFunction<EditWaterLevelMutation, EditWaterLevelMutationVariables>;
export type EditWaterLevelMutationResult = Apollo.MutationResult<EditWaterLevelMutation>;
export type EditWaterLevelMutationOptions = Apollo.BaseMutationOptions<EditWaterLevelMutation, EditWaterLevelMutationVariables>;