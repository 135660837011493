import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type FindAtoApproversQueryVariables = Types.Exact<{
  clientId: Types.Scalars['String']['input'];
  structureId: Types.Scalars['String']['input'];
}>;


export type FindAtoApproversQuery = { __typename?: 'Query', findAtoApprovers: Array<{ __typename?: 'VerifierApproverType', name: string, id: string, email: string }> };


export const FindAtoApproversDocument = gql`
    query FindAtoApprovers($clientId: String!, $structureId: String!) {
  findAtoApprovers(clientId: $clientId, structureId: $structureId) {
    name
    id
    email
  }
}
    `;
export type FindAtoApproversQueryResult = Apollo.QueryResult<FindAtoApproversQuery, FindAtoApproversQueryVariables>;