export const getRandomColor = (colorList: string) => {
  if (colorList === 'primary') {
    const colors = [
      '#808080',
      '#800000',
      '#800080',
      '#FF00FF',
      '#008000',
      '#00FF00',
      '#6B8E23',
      '#0000FF',
      '#000080',
      '#008080',
      '#00FFFF',
      '#2F4F4F',
      '#B87333',
      '#D8BFD8',
      '#F0E68C',
      '#F4C430',
      '#87CEFA',
      '#8470FF'
    ];
    const randomIndex = Math.floor(Math.random() * colorList.length);
    return colors[randomIndex];
  } else if (colorList === 'random') {
    let r = 0;
    let g = 0;
    let b = 0;

    r = Math.floor(Math.random() * 256);
    g = Math.floor(Math.random() * 256);
    b = Math.floor(Math.random() * 256);

    const color = `rgba(${r},${g},${b},${0.99})`;
    return color;
  }
};
