import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ListCompaniesQueryVariables = Types.Exact<{
  data: Types.ListCompaniesInput;
}>;


export type ListCompaniesQuery = { __typename?: 'Query', listCompanies: Array<{ __typename?: 'CompanyType', id: string, status: boolean, name: string, logo?: string | null, cnpj: string, address: { __typename?: 'CompanyAddressType', country: string, state: string, city: string, zipCode?: string | null, addressLine?: string | null, number?: number | null } }> };


export const ListCompaniesDocument = gql`
    query ListCompanies($data: ListCompaniesInput!) {
  listCompanies(data: $data) {
    id
    status
    name
    logo
    cnpj
    address {
      country
      state
      city
      zipCode
      addressLine
      number
    }
  }
}
    `;
export type ListCompaniesQueryResult = Apollo.QueryResult<ListCompaniesQuery, ListCompaniesQueryVariables>;