import { useQuery } from '@apollo/client';
import {
  ListModulesQueryVariables,
  ListModulesQuery,
  ListModulesDocument
} from '../graphql/query/generated/listModules';

export function useListModules(onCompleted?: () => void, onError?: () => void) {
  const { data, loading, error } = useQuery<
    ListModulesQuery,
    ListModulesQueryVariables
  >(ListModulesDocument, {
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    data,
    loading,
    error
  };
}
