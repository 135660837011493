import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type EditStructureMutationVariables = Types.Exact<{
  data: Types.EditStructureInput;
}>;


export type EditStructureMutation = { __typename?: 'Mutation', editStructure: string };


export const EditStructureDocument = gql`
    mutation EditStructure($data: EditStructureInput!) {
  editStructure(data: $data)
}
    `;
export type EditStructureMutationFn = Apollo.MutationFunction<EditStructureMutation, EditStructureMutationVariables>;
export type EditStructureMutationResult = Apollo.MutationResult<EditStructureMutation>;
export type EditStructureMutationOptions = Apollo.BaseMutationOptions<EditStructureMutation, EditStructureMutationVariables>;