import { useLazyQuery, useQuery } from '@apollo/client';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import ExportData from '../../../../Controllers/Export/ExportData';
import {
  ActionPlanFilterInput,
  ActionPlanPriorityEnum,
  ActionPlanSituationEnum,
  ActionPlanTypeEnum,
  SortDirection
} from '../../../../data/graphql/base-schema';
import {
  FindActionPlanAllInfoDocument,
  FindActionPlanAllInfoQuery,
  FindActionPlanAllInfoVariables
} from '../../../../data/services/ActionPlanService';
import { Color } from '../../../../Styles/Styles';
import ErrorsTreatments from '../../../../utils/errorTreatment';
import { toastfyError } from '../../../Toastify';
import Button from '../../Atoms/Button/Button';
import Text from '../../Atoms/Text/Text';
import SquareCard from '../../Molecules/SquareCard/SquareCard';
import TableComponent from '../../Molecules/TableComponent/TableComponent';
import ViewModal from '../../Molecules/ViewModal/ViewModal';
import ActionPLanFilterComponent from '../../Organisms/ActionPlanFilters/ActionPlanFilters';
import CreateActionPlan from '../../Organisms/CreateActionPlan/CreateActionPlan';
import CardTableTemplate from '../../Templates/CardTableTemplate/CardTableTemplate';
import { ProjectContext } from '../../../../Context/ContextAPI';
import {
  FindAllActionPlansDocument,
  FindAllActionPlansQuery,
  FindAllActionPlansQueryVariables
} from '../../../../data/graphql/query/generated/findAllActionPlans.query';
import Enums from '../../../../utils/enumns';

const ActionPlan = () => {
  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const { structureId } = useParams();
  const { ExportActionPlanList } = ExportData();
  const { UserRoles } = Enums();
  const [searchParams] = useSearchParams();
  const generalSearchString = searchParams.get('generalSearchString') || '';
  const { getUserRoleByModule } = useContext(ProjectContext) as {
    getUserRoleByModule: (moduleName: string) => string;
  };
  const userRoleInModule = getUserRoleByModule('ActionPlan');

  const [isModalOpen, setIsModalOpen] = useState(false);

  const filters: ActionPlanFilterInput = {
    code: searchParams.get('code') || null,
    priority: (searchParams.get('priority') as ActionPlanPriorityEnum) || null,
    responsibleName: searchParams.get('responsibleName') || null,
    situation:
      (searchParams.get('situation') as ActionPlanSituationEnum) || null,
    timeFramePeriod: searchParams.get('timeFramePeriod')
      ? [new Date(searchParams.get('timeFramePeriod') as string)]
      : null,
    type: (searchParams.get('type') as ActionPlanTypeEnum) || null
  };

  const { data: ActionPlanData, loading } = useQuery<
    FindActionPlanAllInfoQuery,
    FindActionPlanAllInfoVariables
  >(FindActionPlanAllInfoDocument, {
    variables: {
      pageInfo: {
        limit: parseInt(searchParams.get('totalForPage') || '10'),
        page: parseInt(searchParams.get('currentPage') || '1')
      },
      structureId: structureId ?? '',
      filters: filters,
      sortInfo:
        searchParams.get('sortDirection') && searchParams.get('sortKey')
          ? [
              {
                direction:
                  searchParams.get('sortDirection') === 'ascending'
                    ? SortDirection.ASC
                    : SortDirection.DESC,
                field: searchParams.get('sortKey') || ''
              }
            ]
          : []
    },
    onError: ({ graphQLErrors }) => {
      const errorMessage = ErrorsTreatments(
        graphQLErrors[0].message,
        translate
      );
      toastfyError(errorMessage);
    }
  });
  const totalPages = ActionPlanData?.findActionPlanPag.pageInfo.totalPages || 0;

  const [getAllActionPlansExport] = useLazyQuery<
    FindAllActionPlansQuery,
    FindAllActionPlansQueryVariables
  >(FindAllActionPlansDocument);

  const exportActionPlanColumns = [
    { key: 'code', label: translate('code') },
    { key: 'type', label: translate('type') },
    { key: 'responsibleName', label: translate('responsible') },
    { key: 'situation', label: translate('situation') },
    { key: 'status', label: translate('status') },
    { key: 'timeFrame', label: translate('timeFrame') },
    { key: 'priority', label: translate('priority') },
    { key: 'teamMembersName', label: translate('team') }
  ];

  const handleNavigate = (actionPlanId: string) => {
    navigate(`/${structureId}/actionplan/${actionPlanId}`);
  };

  const tableActionPlanColumns: Array<{
    key: string;
    label: string;
    sortType?: 'number' | 'text';
  }> = [
    { key: 'name', label: translate('name'), sortType: 'text' },
    { key: 'responsible', label: translate('responsible'), sortType: 'text' },
    { key: 'status', label: translate('status'), sortType: 'text' },
    { key: 'timeFrame', label: translate('timeFrame'), sortType: 'text' },
    { key: 'priority', label: translate('priorities'), sortType: 'text' },
    { key: 'createdAt', label: translate('createdAt') }
  ];

  const tableActionPlanData = ActionPlanData?.findActionPlanPag.nodes.map(
    (actionPlan) => ({
      ...actionPlan,
      name: translate(actionPlan.name),
      responsible: translate(actionPlan.responsible),
      status: translate(actionPlan.status?.toLocaleLowerCase()),
      timeFrame: actionPlan.timeFrame
        ? new Date(actionPlan.timeFrame).toLocaleDateString()
        : '-',
      priority: translate(actionPlan.priority),
      color:
        actionPlan.status === 'Concluded'
          ? Color.Green1
          : actionPlan.status === 'Late'
            ? Color.Red1
            : actionPlan.status === 'Opened'
              ? Color.Golden2
              : '',
      createdAt: new Date(actionPlan.createdAt).toLocaleDateString()
    })
  );

  const filteredAtoData = !generalSearchString
    ? tableActionPlanData
    : tableActionPlanData?.filter((row) =>
        Object.values(row).some((value) =>
          value
            .toString()
            .toLowerCase()
            .includes(generalSearchString.toLowerCase())
        )
      );

  const handleExportActionPlan = async () => {
    await getAllActionPlansExport({
      variables: {
        structureId: structureId!
      },
      onCompleted: (data) => {
        ExportActionPlanList(data.findAllActionPlans, exportActionPlanColumns);
      },
      onError: ({ graphQLErrors }) => {
        const errorMessage = ErrorsTreatments(
          graphQLErrors[0].message,
          translate
        );
        toastfyError(errorMessage);
      }
    });
  };

  return (
    <>
      <CardTableTemplate
        loading={loading}
        title={<Text type="h1">{translate('Action plan')}</Text>}
        button={
          userRoleInModule !== UserRoles.Viewer ? (
            <Button
              onClick={() => setIsModalOpen(true)}
              text={`${translate('create')} ${translate('Action plan')}`}
              size="large"
              variant="primary"
            />
          ) : (
            <> </>
          )
        }
        cards={
          <>
            <SquareCard
              label={translate('total')}
              value={
                ActionPlanData?.findCalculatedInfos.totalPlans.toString() ?? ''
              }
            />
            <SquareCard
              label={translate('completed')}
              value={
                ActionPlanData?.findCalculatedInfos.totalPlansDone.toString() ??
                ''
              }
            />
            <SquareCard
              label={translate('highPriority')}
              value={
                ActionPlanData?.findCalculatedInfos.totalPlansHighPriority.toString() ??
                ''
              }
            />
            <SquareCard
              label={translate('inProgress')}
              value={
                ActionPlanData?.findCalculatedInfos.totalPlansInProgress.toString() ??
                ''
              }
            />
            <SquareCard
              label={translate('overdue')}
              value={
                ActionPlanData?.findCalculatedInfos.totalPlansLate.toString() ??
                ''
              }
            />
            <SquareCard
              label={translate('opened')}
              value={
                ActionPlanData?.findCalculatedInfos.totalPlansOpened.toString() ??
                ''
              }
            />
          </>
        }
        content={
          <TableComponent
            columns={tableActionPlanColumns}
            filterComponent={<ActionPLanFilterComponent />}
            onRow={(actionPlan) => handleNavigate(actionPlan.id)}
            tableData={filteredAtoData ?? []}
            paginator
            totalPages={totalPages}
            onSearch={() => {}}
            showInUrl={true}
            exportTable={() => handleExportActionPlan()}
            exportTableColumns={exportActionPlanColumns}
          />
        }
      />
      <ViewModal
        showModal={isModalOpen}
        title={`${translate('create')} ${translate('Action plan')}`}
        onClose={() => setIsModalOpen(false)}
        width="auto"
        height="auto"
        component={<CreateActionPlan onClose={() => setIsModalOpen(false)} />}
      />
    </>
  );
};

export default ActionPlan;
