import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type SaveConservationStateMutationVariables = Types.Exact<{
  inspectionId: Types.Scalars['String']['input'];
  conservationState: Array<Types.SaveConservationStateInput> | Types.SaveConservationStateInput;
}>;


export type SaveConservationStateMutation = { __typename?: 'Mutation', saveConservationState: string };


export const SaveConservationStateDocument = gql`
    mutation SaveConservationState($inspectionId: String!, $conservationState: [SaveConservationStateInput!]!) {
  saveConservationState(
    inspectionId: $inspectionId
    conservationState: $conservationState
  )
}
    `;
export type SaveConservationStateMutationFn = Apollo.MutationFunction<SaveConservationStateMutation, SaveConservationStateMutationVariables>;
export type SaveConservationStateMutationResult = Apollo.MutationResult<SaveConservationStateMutation>;
export type SaveConservationStateMutationOptions = Apollo.BaseMutationOptions<SaveConservationStateMutation, SaveConservationStateMutationVariables>;