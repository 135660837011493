import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type SaveActionPlanCommentMutationVariables = Types.Exact<{
  data: Types.CreateActionPlanCommentInput;
}>;

export type SaveActionPlanCommentMutation = {
  __typename?: 'Mutation';
  saveActionPlanComment: {
    __typename?: 'SavedActionPlanCommentType';
    id: string;
    comment: string;
    files: Array<{
      __typename?: 'ActionPlanCommentFilesType';
      id: string;
      fileName: string;
      url: string;
    }>;
  };
};

export const SaveActionPlanCommentDocument = gql`
  mutation SaveActionPlanComment($data: CreateActionPlanCommentInput!) {
    saveActionPlanComment(data: $data) {
      id
      comment
      files {
        id
        fileName
        url
      }
    }
  }
`;

export type SaveActionPlanCommentMutationFn = Apollo.MutationFunction<
  SaveActionPlanCommentMutation,
  SaveActionPlanCommentMutationVariables
>;

export type SaveActionPlanCommentMutationResult =
  Apollo.MutationResult<SaveActionPlanCommentMutation>;

export type SaveActionPlanCommentMutationOptions = Apollo.BaseMutationOptions<
  SaveActionPlanCommentMutation,
  SaveActionPlanCommentMutationVariables
>;
