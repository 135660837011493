import styled from 'styled-components';
import { Color } from '../../../../Styles/Styles';

interface ModalProps {
  width?: number;
  height?: number;
}

export const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
`;

export const Modal = styled.div<ModalProps>`
  position: fixed;
  background-color: ${Color.White};
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: ${(props) => props.width || 80}%;
  height: ${(props) => props.height || 80}%;
  max-width: 90%;
  max-height: 90%;
  z-index: 3;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;

  h2 {
    margin: 0;
    font-size: 1.5rem;
    color: ${Color.Brown1};
  }

  button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: ${Color.Brown1};
  }
`;

export const TabHeader = styled.div`
  display: flex;
  margin-bottom: 20px;
  border-bottom: none;
  border-top: 1px solid ${Color.Brown3};
  background-color: #dbc8ad;
  width: 100%;

  button {
    cursor: pointer;
    padding: 10px;
    background: none;
    border: none;
    font-size: 1rem;
    color: ${Color.Brown1};
    white-space: nowrap;
    width: 100%;
    text-wrap: wrap;
    font-weight: bold;

    &.active {
      color: ${Color.Brown1};
      font-weight: bold;
      background-color: #ffffff;
    }

    &:disabled {
      cursor: not-allowed;
      color: ${Color.Brown2};
      font-weight: normal;
    }
  }
`;

export const ModalBody = styled.div`
  flex: 1;
  margin-bottom: 20px;
  width: 100%;
  overflow-x: hidden;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: end;
  gap: 10px;
  padding: 10px;
`;
