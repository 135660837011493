import { DragEvent, PropsWithChildren } from 'react';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa6';

import { TableHeaderCellProps } from './TableHeaderCell.interface';
import {
  TableHeaderCellContent,
  TableHeaderCellContentFixedLeft,
  TableHeaderCellContentFixedRight
} from './TableHeaderCell.style';

const mapVariant = {
  default: TableHeaderCellContent,
  leftFixed: TableHeaderCellContentFixedLeft,
  rightFixed: TableHeaderCellContentFixedRight
};

export const TableHeaderCell = ({
  accessKey,
  children,
  onClick,
  isSorted = false,
  isSortedDesc = false,
  sortType,
  minWidth = '120px',
  variant = 'default',
  draggable = true,
  columnOver,
  columnDragging,
  handleDragStart,
  handleDragOver,
  handleDragEnd,
  handleDragLeave
}: PropsWithChildren<TableHeaderCellProps>) => {
  const TableHeaderCell = mapVariant[variant];
  const isHover = columnOver === accessKey;
  const isDragging = columnDragging === accessKey;

  const handleSortClick = () => {
    if (onClick) {
      onClick();
    }
  };

  const dragprops = draggable
    ? {
        draggable: true,
        onDragStart: (event: DragEvent<HTMLTableHeaderCellElement>) => {
          handleDragStart!(event.currentTarget.accessKey);
        },

        onDragEnd: () => {
          handleDragEnd!();
        },

        onDragOver: (event: DragEvent<HTMLTableHeaderCellElement>) => {
          event.preventDefault();
          handleDragOver!(event.currentTarget.accessKey);
        },

        handleDragLeave: (event: DragEvent<HTMLTableHeaderCellElement>) => {
          event.preventDefault();
          handleDragLeave!();
        },
        isDragging: isDragging,
        isHover: isHover
      }
    : { draggable: false };

  if (sortType === 'number' || sortType === 'text') {
    return (
      <TableHeaderCell accessKey={accessKey} minWidth={minWidth} {...dragprops}>
        <div>
          {children}
          {isSorted ? (
            isSortedDesc ? (
              <FaArrowDown onClick={handleSortClick} />
            ) : (
              <FaArrowUp onClick={handleSortClick} />
            )
          ) : (
            <FaArrowDown onClick={handleSortClick} />
          )}
        </div>
      </TableHeaderCell>
    );
  }

  return (
    <TableHeaderCell accessKey={accessKey} minWidth={minWidth} {...dragprops}>
      <div>{children}</div>
    </TableHeaderCell>
  );
};
