import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ListUsersPermissionsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ListUsersPermissionsQuery = { __typename?: 'Query', listUsersPermissions: Array<{ __typename?: 'ListUserPermissionType', structureUserPermissionId: string, moduleAssignmentId: string, companyName: string, structureName: string, userName: string, userEmail: string, moduleName: Types.ModuleEnum, moduleRoleName: Types.ModuleRoleEnum }> };


export const ListUsersPermissionsDocument = gql`
    query ListUsersPermissions {
  listUsersPermissions {
    structureUserPermissionId
    moduleAssignmentId
    companyName
    structureName
    userName
    userEmail
    moduleName
    moduleRoleName
  }
}
    `;
export type ListUsersPermissionsQueryResult = Apollo.QueryResult<ListUsersPermissionsQuery, ListUsersPermissionsQueryVariables>;