import { useLazyQuery, useQuery } from '@apollo/client';
import {
  ListCompanyRelatedUsersAndStructuresDocument,
  ListCompanyRelatedUsersAndStructuresQuery,
  ListCompanyRelatedUsersAndStructuresQueryVariables
} from '../../graphql/query/generated/listCompanyRelatedUsersAndStructures.query';

export function useListCompanyRelatedUsersAndStructures(
  id: string,
  onCompleted?: () => void,
  onError?: () => void
) {
  const { data, loading, error } = useQuery<
    ListCompanyRelatedUsersAndStructuresQuery,
    ListCompanyRelatedUsersAndStructuresQueryVariables
  >(ListCompanyRelatedUsersAndStructuresDocument, {
    variables: {
      data: {
        id
      }
    },
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    data,
    loading,
    error
  };
}

export function useListCompanyRelatedUsersAndStructuresLazy(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [listCompanyRelatedUsersAndStructures, { data, loading, error }] =
    useLazyQuery<
      ListCompanyRelatedUsersAndStructuresQuery,
      ListCompanyRelatedUsersAndStructuresQueryVariables
    >(ListCompanyRelatedUsersAndStructuresDocument, {
      errorPolicy: 'all',
      onCompleted: onCompleted,
      onError: onError
    });

  return {
    listCompanyRelatedUsersAndStructures,
    data,
    loading,
    error
  };
}
