import {
  DivContainerGroupText,
  DivContainerSpanCard
} from './GroupText.styles';
import Text from '../../Atoms/Text/Text';
import { GroupTextProps } from './GroupText.interfaces';
import { Tooltip } from 'react-tooltip';

export const GroupText = ({ title, value, maxLength }: GroupTextProps) => {
  return (
    <DivContainerGroupText>
      <Text type="label">{title}</Text>
      <DivContainerSpanCard>
        <Text
          data-tooltip-id={maxLength ? value : undefined}
          data-tooltip-content={value}
          type="span"
          color="black"
        >
          {maxLength && value?.length > maxLength
            ? `${value?.slice(0, maxLength)}...`
            : value}
        </Text>
      </DivContainerSpanCard>
      <Tooltip id={maxLength && value.length > maxLength ? value : undefined} />
    </DivContainerGroupText>
  );
};
