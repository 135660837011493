import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type DeleteRecordActivitiesMutationVariables = Types.Exact<{
  deleteRecordActivitiesId: Types.Scalars['String']['input'];
}>;


export type DeleteRecordActivitiesMutation = { __typename?: 'Mutation', deleteRecordActivities: string };


export const DeleteRecordActivitiesDocument = gql`
    mutation DeleteRecordActivities($deleteRecordActivitiesId: String!) {
  deleteRecordActivities(id: $deleteRecordActivitiesId)
}
    `;
export type DeleteRecordActivitiesMutationFn = Apollo.MutationFunction<DeleteRecordActivitiesMutation, DeleteRecordActivitiesMutationVariables>;
export type DeleteRecordActivitiesMutationResult = Apollo.MutationResult<DeleteRecordActivitiesMutation>;
export type DeleteRecordActivitiesMutationOptions = Apollo.BaseMutationOptions<DeleteRecordActivitiesMutation, DeleteRecordActivitiesMutationVariables>;