import styled from 'styled-components';
import { Color } from '../../Styles/Styles';

export const TableArea = styled.div`
  position: relative;
  max-height: 300px;
  width: 90%;
  overflow-y: scroll;
`;

export const TableComponentStyle = styled.table`
  width: 100%;
  height: 100%;
  text-align: center;
  border-collapse: collapse;
  position: relative;
  thead {
    tr {
      padding: 10px 15px;
      background-color: ${Color.Brown3};
      border-bottom: 1px solid ${Color.Brown1};
      color: ${Color.Brown1};
      th {
        padding: 2px 12px;
        font-size: 12px;
      }
    }
  }
  tbody {
    td {
      padding: 10px 10px;
      min-width: 100px;
      font-size: 12px;
      color: ${Color.Disabled};
    }
  }
`;

export const TableComponentInspectionStyle = styled.table`
  width: 100%;
  height: 100%;
  text-align: center;
  border-collapse: collapse;
  position: relative;
  thead {
    display: table;
    width: 100%;
    table-layout: fixed;
    tr {
      padding: 10px 15px;
      background-color: ${Color.Brown3};
      border-bottom: 1px solid ${Color.Brown1};
      color: ${Color.Brown1};
      th {
        padding: 5px 15px;
      }
    }
  }
  tbody {
    display: block;
    height: 500px;
    overflow-y: auto;
    tr {
      display: table;
      width: 100%;
      table-layout: fixed;
      td {
        padding: 10px 10px;
        min-width: 100px;
        /* max-height:10px; */
        font-size: 14px;
        color: ${Color.Disabled};
      }
    }
  }
`;

export const TabeFilterArea = styled.div`
  position: relative;
  color: ${Color.Brown1};
  display: flex;
`;

export const TableHolderFilterAndTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 20px;
  padding-top: 25px;
`;

export const TableHolderFilter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 5%;
  margin-bottom: 25px;
`;

export const SelectFilter = styled.select`
  background-color: transparent;
  width: 220px;
  padding: 10px 10px 10px 0px;
  border: none;
  outline: none;
  text-align: start;
  border-bottom: 2px solid #967442;
  column-gap: 10px;
  color: #967442;
  option:checked {
    background-color: ${Color.Golden2};
  }
`;

export const OptionFilter = styled.option``;

export const FilterTitle = styled.a`
  color: #967442;
  font-size: 20px;
  font-weight: 700;
`;

export const TableDivStyle = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: scroll;
  width: 100%;
`;
