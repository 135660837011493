import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ListInstrumentTypesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ListInstrumentTypesQuery = { __typename?: 'Query', listInstrumentTypes: Array<{ __typename?: 'InstrumentType', id: string, instrumentType: string }> };


export const ListInstrumentTypesDocument = gql`
    query ListInstrumentTypes {
  listInstrumentTypes {
    id
    instrumentType
  }
}
    `;
export type ListInstrumentTypesQueryResult = Apollo.QueryResult<ListInstrumentTypesQuery, ListInstrumentTypesQueryVariables>;