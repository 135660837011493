import styled from 'styled-components';

type VideoAtomProps = {
  src: string;
  width?: string;
  height?: string;
  controls?: boolean;
  autoPlay?: boolean;
  loop?: boolean;
  muted?: boolean;
  poster?: string;
};

export const StyledVideo = styled.video<VideoAtomProps>`
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height || 'auto'};
  border-radius: 5px;
  background-color: black;
`;
