import { useLazyQuery, useQuery } from '@apollo/client';
import {
  ListReadingByPiezometerDocument,
  ListReadingByPiezometerQuery,
  ListReadingByPiezometerQueryVariables
} from '../../graphql/query/generated/listReadingByPiezometer';

export function useListReadingByPiezometer(
  endDate: Date,
  startDate: Date,
  instrumentIds: string,
  onCompleted?: () => void,
  onError?: () => void
) {
  const { data, loading, error } = useQuery<
    ListReadingByPiezometerQuery,
    ListReadingByPiezometerQueryVariables
  >(ListReadingByPiezometerDocument, {
    variables: {
      endDate,
      startDate,
      instrumentIds
    },
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    data,
    loading,
    error
  };
}

export function useListReadingByPiezometerLazy(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [listReadingByPiezometer, { data, loading, error }] = useLazyQuery<
    ListReadingByPiezometerQuery,
    ListReadingByPiezometerQueryVariables
  >(ListReadingByPiezometerDocument, {
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    listReadingByPiezometer,
    data,
    loading,
    error
  };
}
