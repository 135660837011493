import styled, { css } from 'styled-components';
import { Color } from '../../../../../../Styles/Styles';

interface TableHeaderCell {
  isDragging?: boolean;
  isHover?: boolean;
  minWidth?: string;
}

export const TableHeaderCellContent = styled.th<TableHeaderCell>`
  border-bottom: 1px solid ${Color.Brown3};
  min-width: ${(props) => props.minWidth || 'auto'};
  width: auto;
  transition: scale 0.3s ease-in-out;
  div {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  svg {
    color: ${Color.Brown3};
    font-size: 15px;
  }

  svg:hover {
    color: ${Color.Brown1};
    cursor: pointer;
    transition: ease-in-out 0.3s;
  }

  &:hover {
    cursor: grab;
  }

  ${(props) =>
    props.isDragging &&
    css`
      border: 1px solid ${Color.Brown1};
      margin: 5px;
      cursor: grab;
      z-index: 10;
      transition: scale 0.6s ease-in-out;
      background-color: white;
    `}

  ${(props) =>
    props.isHover &&
    css`
      border: 1px solid;
      margin: 5px;
      cursor: grab;
      z-index: 10;
      transition: scale 0.6s ease-in-out;
      background-color: white;
    `}
`;

export const TableHeaderCellContentFixedLeft = styled.th<TableHeaderCell>`
  border-bottom: 1px solid ${Color.Brown3};
  min-width: ${(props) => props.minWidth || 'auto'};
  position: sticky;
  left: 0;
  background-color: #f5f5f5;
  text-align: center;

  div {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  svg {
    color: ${Color.Brown3};
    font-size: 15px;
  }

  svg:hover {
    color: ${Color.Brown1};
    cursor: pointer;
    transition: ease-in-out 0.3s;
  }
`;

export const TableHeaderCellContentFixedRight = styled.th<TableHeaderCell>`
  border-bottom: 1px solid ${Color.Brown3};
  border-right: 1px solid ${Color.Brown3};
  min-width: ${(props) => props.minWidth || 'auto'};
  position: sticky;
  right: 0;
  background-color: #f5f5f5;

  div {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  svg {
    color: ${Color.Brown3};
    font-size: 15px;
  }

  svg:hover {
    color: ${Color.Brown1};
    cursor: pointer;
    transition: ease-in-out 0.3s;
  }
`;
