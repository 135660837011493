import { PropsWithChildren } from 'react';

import { TableCellProps } from './TableCell.interface';
import {
  TableCellContent,
  TableCellContentFixedLeft,
  TableCellContentFixedRight
} from './TableCell.styles';

const mapVariant = {
  default: TableCellContent,
  leftFixed: TableCellContentFixedLeft,
  rightFixed: TableCellContentFixedRight
};

export const TableCell = ({
  children,
  color,
  variant = 'default'
}: PropsWithChildren<TableCellProps>) => {
  const TableCellContent = mapVariant[variant];

  return <TableCellContent color={color}>{children}</TableCellContent>;
};
