import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type EditSurfaceMarkerAlertLevelMutationVariables = Types.Exact<{
  structureInfo: Types.StructureInfoInput;
  data: Types.EditSurfaceMarkerAlertLevelInput;
}>;


export type EditSurfaceMarkerAlertLevelMutation = { __typename?: 'Mutation', editSurfaceMarkerAlertLevel: string };


export const EditSurfaceMarkerAlertLevelDocument = gql`
    mutation EditSurfaceMarkerAlertLevel($structureInfo: StructureInfoInput!, $data: EditSurfaceMarkerAlertLevelInput!) {
  editSurfaceMarkerAlertLevel(structureInfo: $structureInfo, data: $data)
}
    `;
export type EditSurfaceMarkerAlertLevelMutationFn = Apollo.MutationFunction<EditSurfaceMarkerAlertLevelMutation, EditSurfaceMarkerAlertLevelMutationVariables>;
export type EditSurfaceMarkerAlertLevelMutationResult = Apollo.MutationResult<EditSurfaceMarkerAlertLevelMutation>;
export type EditSurfaceMarkerAlertLevelMutationOptions = Apollo.BaseMutationOptions<EditSurfaceMarkerAlertLevelMutation, EditSurfaceMarkerAlertLevelMutationVariables>;