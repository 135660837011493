import React, {
  createContext,
  useCallback,
  useMemo,
  useReducer,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  LanguageOptions,
  ModuleData,
  StructureSelectType,
  UserData
} from '../@Types/types';
import brazilFlag from '../Images/Brasil (BRA).svg';
import usFlag from '../Images/United States of America (US).svg';
import esFlag from '../Images/Spain (ESP).svg';
import zhFlag from '../Images/China (CHN).svg';

const ProjectContext = createContext({});

interface CompanyData {
  name: string;
  id: string;
  address: string;
}

export type Action =
  | { type: 'SET_USER_DATA'; payload: UserData }
  | { type: 'CLEAR_DATA' };

const Provider = ({ children }: { children: React.ReactNode }) => {
  const { i18n } = useTranslation();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const languageOptions: LanguageOptions[] = [
    {
      id: 1,
      label: 'Português',
      value: 1,
      language: 'pt-BR',
      flag: brazilFlag
    },
    { id: 2, label: 'English', value: 2, language: 'en-US', flag: usFlag },
    { id: 3, label: 'Español', value: 3, language: 'es', flag: esFlag },
    { id: 4, label: '普通', value: 4, language: 'zh', flag: zhFlag }
  ];
  const [selectedLanguage, setSelectedLanguage] = useState<LanguageOptions>(
    languageOptions.find((ln) => ln.language === i18n.language) ||
      languageOptions.find((ln) => ln.language === i18n.language) ||
      languageOptions[0]
  );
  const [structures, setStructures] = useState<StructureSelectType[] | []>([]);
  const [modulesByStructure, setModulesByStructure] = useState<ModuleData[]>(
    (localStorage.getItem('userModules')
      ? JSON.parse(localStorage.getItem('userModules') || '[]').map(
          (item: { name: string; role: string }) => {
            return {
              moduleName: item.name,
              role: item.role
            };
          }
        )
      : []) || []
  );
  const [adminOptionSelected, setAdminOptionSelected] = useState<string>();

  const handleLanguage = useCallback(
    (selected: LanguageOptions) => {
      setSelectedLanguage(selected);
      const languageType = {
        'pt-BR': 'pt-BR',
        'en-US': 'en-US',
        es: 'es',
        zh: 'zh'
      };
      i18n.changeLanguage(
        languageType[selected.language as keyof typeof languageType]
      );
    },
    [i18n]
  );

  function useUserDataReducer(state: UserData, action: Action) {
    switch (action.type) {
      case 'SET_USER_DATA':
        setIsAuthenticated(true);
        return {
          name: action.payload.name,
          id: action.payload.id,
          token: action.payload.token,
          role: action.payload.role,
          isLoggedWithTemporaryPassword:
            action.payload.isLoggedWithTemporaryPassword,
          hasAcceptedTerms: action.payload.hasAcceptedTerms
        };
      case 'CLEAR_DATA':
        setIsAuthenticated(false);
        return {
          name: '',
          id: '',
          token: '',
          role: '',
          isLoggedWithTemporaryPassword: null,
          hasAcceptedTerms: false
        };
      default:
        return state;
    }
  }

  const [userData, dispatch] = useReducer(useUserDataReducer, {
    name: '',
    id: '',
    role: '',
    token: '',
    isLoggedWithTemporaryPassword: null,
    hasAcceptedTerms: false
  });
  const [companyData, setCompanyData] = useState<CompanyData>({
    name: '',
    id: '',
    address: ''
  });

  useMemo(() => {
    if (!userData.name && !userData.token) {
      const userStored = localStorage.getItem('user');
      if (userStored) {
        setIsAuthenticated(true);
        dispatch({
          type: 'SET_USER_DATA',
          payload: {
            name: JSON.parse(userStored).name,
            id: JSON.parse(userStored).id,
            token: JSON.parse(userStored).token,
            role: JSON.parse(userStored).role,
            isLoggedWithTemporaryPassword:
              JSON.parse(userStored).isLoggedWithTemporaryPassword,
            hasAcceptedTerms: JSON.parse(userStored).hasAcceptedTerms
          }
        });
      }
    }
  }, [userData]);

  useMemo(() => {
    if (modulesByStructure.length === 0) {
      const modulesByStructureStorad = localStorage.getItem('userModules');
      if (modulesByStructureStorad) {
        setModulesByStructure(JSON.parse(modulesByStructureStorad));
      }
    }
  }, [modulesByStructure]);

  const getUserRoleByModule = useCallback(
    (moduleName: string) => {
      const userModuloRole = modulesByStructure.find(
        (module) =>
          module.moduleName.toLocaleLowerCase() ===
          moduleName.toLocaleLowerCase()
      )?.role;
      return userModuloRole;
    },
    [modulesByStructure]
  );

  return (
    <ProjectContext.Provider
      value={{
        selectedLanguage,
        handleLanguage,
        languageOptions,
        userData,
        dispatch,
        companyData,
        setCompanyData,
        isAuthenticated,
        structures,
        setStructures,
        modulesByStructure,
        setModulesByStructure,
        adminOptionSelected,
        setAdminOptionSelected,
        getUserRoleByModule
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
};

export { ProjectContext, Provider };
