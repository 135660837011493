import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type EditPluviometerMutationVariables = Types.Exact<{
  structureInfo: Types.StructureInfoInput;
  data: Types.EditPluviometerInput;
}>;


export type EditPluviometerMutation = { __typename?: 'Mutation', editPluviometer: string };


export const EditPluviometerDocument = gql`
    mutation EditPluviometer($structureInfo: StructureInfoInput!, $data: EditPluviometerInput!) {
  editPluviometer(structureInfo: $structureInfo, data: $data)
}
    `;
export type EditPluviometerMutationFn = Apollo.MutationFunction<EditPluviometerMutation, EditPluviometerMutationVariables>;
export type EditPluviometerMutationResult = Apollo.MutationResult<EditPluviometerMutation>;
export type EditPluviometerMutationOptions = Apollo.BaseMutationOptions<EditPluviometerMutation, EditPluviometerMutationVariables>;