import { useMutation, useQuery } from '@apollo/client';
import { t } from 'i18next';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoCheckmarkDone, IoSyncSharp } from 'react-icons/io5';
import { MdEdit } from 'react-icons/md';
import { TiDelete } from 'react-icons/ti';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  SetAtoCanceledDocument,
  SetAtoCanceledMutation,
  SetAtoCanceledMutationVariables
} from '../../../../data/graphql/generated/canceledAto';
import {
  SetAtoDoneDocument,
  SetAtoDoneMutation,
  SetAtoDoneMutationVariables
} from '../../../../data/graphql/generated/doneAto';
import {
  ReactivateAtoDocument,
  ReactivateAtoMutation,
  ReactivateAtoMutationVariables
} from '../../../../data/graphql/generated/reactivateAto';
import {
  FindAllAtoDocument,
  FindAllAtoQuery,
  FindAllAtoQueryVariables
} from '../../../../data/graphql/query/generated/findAllAto.query';
import ErrorsTreatments from '../../../../utils/errorTreatment';
import { toastfyError, toastfySuccess } from '../../../Toastify';
import Button from '../../Atoms/Button/Button';
import Text from '../../Atoms/Text/Text';
import { AvailableContextActionsProps } from '../../Molecules/ActionMenu/ActionMenu.interface';
import TableComponent from '../../Molecules/TableComponent/TableComponent';
import ViewModal from '../../Molecules/ViewModal/ViewModal';
import AtoForm from '../../Organisms/AtoForm/AtoForm';
import { AtoFormType } from '../../Organisms/AtoForm/AtoForm.interfaces';
import SimpleContentTemplate from '../../Templates/SimpleContentTemplate/SimpleContentTemplate';
import { lisAtoDataProps } from './AtoAdminPage.interfaces';
import { DivContentTable, DivTable } from './AtoAdminPageStyles';
const AtoAdminPage = () => {
  const navigate = useNavigate();
  const { structureId } = useParams();
  const [searchParams] = useSearchParams();
  const { t: translate } = useTranslation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [atoToEdit, setAtoToEdit] = useState<AtoFormType | null>(null);
  const generalSearchString = searchParams.get('generalSearchString') || '';
  const [cancelAto] = useMutation<
    SetAtoCanceledMutation,
    SetAtoCanceledMutationVariables
  >(SetAtoCanceledDocument);
  const [atoDone] = useMutation<
    SetAtoDoneMutation,
    SetAtoDoneMutationVariables
  >(SetAtoDoneDocument);
  const [atoReactivate] = useMutation<
    ReactivateAtoMutation,
    ReactivateAtoMutationVariables
  >(ReactivateAtoDocument);
  const { data: listAtos, loading } = useQuery<
    FindAllAtoQuery,
    FindAllAtoQueryVariables
  >(FindAllAtoDocument, {
    variables: {
      structureId: structureId ? structureId : ''
    },
    onError: ({ graphQLErrors }) => {
      const errorMessage = ErrorsTreatments(
        graphQLErrors[0].message,
        translate
      );
      toastfyError(errorMessage);
    }
  });
  const columnsTable = [
    {
      key: 'title',
      label: `${translate('title')}`
    },
    {
      key: 'status',
      label: 'Status'
    },
    {
      key: 'contract',
      label: `${translate('contractNumber')}`
    },
    {
      key: 'responsibleCompanyName',
      label: `${translate('responsibleCompany')}`
    },
    {
      key: 'initialDate',
      label: `${translate('startDate')}`
    },
    {
      key: 'finalDate',
      label: `${translate('finalDate')}`
    }
  ];

  const actionsTable: AvailableContextActionsProps<lisAtoDataProps>[] = [
    {
      name: `${translate('Edit')}`,
      icon: <MdEdit />,
      onClick: (ato: lisAtoDataProps) => handleAtoEdit(ato),
      canShow: 'canEdit'
    },
    {
      name: `${translate('conclude')}`,
      icon: <IoCheckmarkDone />,
      onClick: (ato: lisAtoDataProps) => handleDoneAto(ato.id),
      canShow: 'canFinish'
    },
    {
      name: `${translate('Cancel')}`,
      icon: <TiDelete />,
      onClick: (ato: lisAtoDataProps) => handleCancelAto(ato.id),
      canShow: 'canCancel'
    },
    {
      name: `${translate('reactivate')}`,
      icon: <IoSyncSharp />,
      onClick: (ato: lisAtoDataProps) => handleReactivateAto(ato.id),
      canShow: 'canReactivate'
    }
  ];

  const lisAtoData = listAtos?.findAllAto.map((ato) => ({
    title: ato.title,
    contract: ato.contract,
    responsibleCompanyName: ato.responsibleCompany.name,
    initialDate: new Date(ato.initialDate).toLocaleDateString(),
    finalDate: new Date(ato.finalDate).toLocaleDateString(),
    status: translate(ato.situation.toLowerCase()),
    id: ato.id,
    canFinish: ato.canFinish,
    canReactivate: ato.situation === 'Canceled'
  }));

  const filteredAtoData = !generalSearchString
    ? lisAtoData
    : lisAtoData?.filter((row) =>
        Object.values(row).some((value) =>
          value
            .toString()
            .toLowerCase()
            .includes(generalSearchString.toLowerCase())
        )
      );

  const handlePushAtoIdToUrl = (atoId: string) => {
    navigate(`/${structureId}/ato/${atoId}`);
  };

  const handleReactivateAto = (atoId: string) => {
    atoReactivate({
      variables: { reactivateAtoId: atoId },
      onCompleted: () => {
        toastfySuccess(t('reactivatedSuccessfully'));
      },
      update: (cache, { data }) => {
        if (!data) return;
        const existingData = cache.readQuery<FindAllAtoQuery>({
          query: FindAllAtoDocument,
          variables: { structureId: structureId ? structureId : '' }
        });
        if (existingData?.findAllAto) {
          const updatedAtos = existingData.findAllAto.map((ato) =>
            ato.id === atoId ? { ...ato, situation: 'Reactivated' } : ato
          );
          cache.writeQuery({
            query: FindAllAtoDocument,
            data: { findAllAto: updatedAtos },
            variables: { structureId: structureId ? structureId : '' }
          });
        }
      },
      onError: ({ graphQLErrors }) => {
        const errorMessage = ErrorsTreatments(
          graphQLErrors[0].message,
          translate
        );
        toastfyError(errorMessage);
      }
    });
  };

  const handleDoneAto = (atoId: string) => {
    const selectedAto = listAtos?.findAllAto.find((ato) => ato.id === atoId);

    if (selectedAto?.canFinish) {
      atoDone({
        variables: { setAtoDoneId: atoId },
        onCompleted: () => {
          toastfySuccess(t('doneSuccessfully'));
        },
        update: (cache, { data }) => {
          if (!data) return;
          const existingData = cache.readQuery<FindAllAtoQuery>({
            query: FindAllAtoDocument,
            variables: { structureId: structureId ? structureId : '' }
          });
          if (existingData?.findAllAto) {
            const updatedAtos = existingData.findAllAto.map((ato) =>
              ato.id === atoId ? { ...ato, situation: 'Done' } : ato
            );
            cache.writeQuery({
              query: FindAllAtoDocument,
              data: { findAllAto: updatedAtos },
              variables: { structureId: structureId ? structureId : '' }
            });
          }
        },
        onError: ({ graphQLErrors }) => {
          const errorMessage = ErrorsTreatments(
            graphQLErrors[0].message,
            translate
          );
          toastfyError(errorMessage);
        }
      });
    } else {
      toastfyError(t('toCompleteAtoIsNecessary'));
    }
  };

  const handleCancelAto = (atoId: string) => {
    cancelAto({
      variables: { setAtoCanceledId: atoId },
      onCompleted: () => {
        toastfySuccess(t('deletedSuccessfully'));
      },
      update: (cache, { data }) => {
        if (!data) return;
        const existingData = cache.readQuery<FindAllAtoQuery>({
          query: FindAllAtoDocument,
          variables: { structureId: structureId ? structureId : '' }
        });
        if (existingData?.findAllAto) {
          const updatedAtos = existingData.findAllAto.map((ato) =>
            ato.id === atoId ? { ...ato, situation: 'Canceled' } : ato
          );
          cache.writeQuery({
            query: FindAllAtoDocument,
            data: { findAllAto: updatedAtos },
            variables: { structureId: structureId ? structureId : '' }
          });
        }
      },
      onError: ({ graphQLErrors }) => {
        const errorMessage = ErrorsTreatments(
          graphQLErrors[0].message,
          translate
        );
        toastfyError(errorMessage);
      }
    });
  };

  const handleAtoEdit = (atoRole: lisAtoDataProps) => {
    const ato = listAtos?.findAllAto.find((ato) => ato.id === atoRole.id);
    if (!ato) return;
    const newAto = {
      atoId: ato.id,
      contract: ato.contract,
      title: ato.title,
      finalDate: ato.finalDate,
      startDate: ato.initialDate,
      verifier: { id: ato.verifier.id, name: ato.verifier.name },
      clientApprover: {
        id: ato.clientApprover?.id || '',
        name: ato.clientApprover?.name || ''
      },
      responsibleCompany: {
        id: ato.responsibleCompany.id,
        name: ato.responsibleCompany.name
      },
      docs: ato.docs,
      client: { id: ato.client.id, name: ato.client.name }
    };
    setAtoToEdit(newAto);
    setShowModal(true);
  };

  return (
    <>
      <SimpleContentTemplate
        title={<Text type="h1">ATO</Text>}
        button={
          <Button
            size="small"
            variant="primary"
            text={t('createAto')}
            onClick={() => {
              setAtoToEdit(null);
              setShowModal(true);
            }}
          />
        }
        content={
          <DivTable>
            <TableComponent
              tableData={filteredAtoData || []}
              columns={columnsTable}
              actions={actionsTable}
              onSearch={() => {}}
              onRow={(ato) => handlePushAtoIdToUrl(ato.id)}
            />
          </DivTable>
        }
        loading={loading}
      />
      <ViewModal
        showModal={showModal}
        title={
          atoToEdit ? `${translate('editAto')}` : `${translate('createAto')}`
        }
        onClose={() => {
          setShowModal(false);
          setAtoToEdit(null);
        }}
        component={<AtoForm ato={atoToEdit} setShowModal={setShowModal} />}
        width="768px"
        height="700px"
      />
      {/* TODO: finalizar logica de cancelamento */}
      {/* 
        <ModalConfirm
          showModal={showAtoCanceled}
          title={t('AtoCancel')}
          text={t('ConfirmDelete')}
          onClose={() => setShowAtoCanceled(false)}
          onConfirm={(ato) => handleCancelAto(ato.id || '')}
        />
     */}
    </>
  );
};

export default AtoAdminPage;
