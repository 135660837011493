import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type FindAllRecordsPagQueryVariables = Types.Exact<{
  pageInfo: Types.PageInput;
  atoId: Types.Scalars['String']['input'];
  sortInfo?: Types.InputMaybe<Array<Types.SortInput> | Types.SortInput>;
  filters?: Types.InputMaybe<Types.AtoFilterInput>;
}>;


export type FindAllRecordsPagQuery = { __typename?: 'Query', findAllRecordsPag: { __typename?: 'PaginatedRecordsType', count: number, pageInfo: { __typename?: 'PageInfo', totalPages: number, page: number, offset: number, limit: number, hasPreviousPage: boolean, hasNextPage: boolean }, nodes: Array<{ __typename?: 'FindAllRecordsType', situation: Types.AtoSituationEnum, recordVideosCount: number, recordNumber: string, recordImagesCount: number, id: string, date: Date }> } };


export const FindAllRecordsPagDocument = gql`
    query FindAllRecordsPag($pageInfo: PageInput!, $atoId: String!, $sortInfo: [SortInput!], $filters: AtoFilterInput) {
  findAllRecordsPag(
    pageInfo: $pageInfo
    atoId: $atoId
    sortInfo: $sortInfo
    filters: $filters
  ) {
    pageInfo {
      totalPages
      page
      offset
      limit
      hasPreviousPage
      hasNextPage
    }
    nodes {
      situation
      recordVideosCount
      recordNumber
      recordImagesCount
      id
      date
    }
    count
  }
}
    `;
export type FindAllRecordsPagQueryResult = Apollo.QueryResult<FindAllRecordsPagQuery, FindAllRecordsPagQueryVariables>;