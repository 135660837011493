import { jwtDecode } from 'jwt-decode';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom';
import { ModuleData, UserData } from '../@Types/types';
import { toastfyError } from '../Components/Toastify';
import { ProjectContext } from '../Context/ContextAPI';

const PrivateRoute = () => {
  const { isAuthenticated, userData, modulesByStructure } = useContext(
    ProjectContext
  ) as {
    isAuthenticated: boolean;
    userData: UserData;
    modulesByStructure: ModuleData[];
  };
  const { t: translate } = useTranslation();
  const location = useLocation();
  const {
    structureId,
    atoId,
    recordId,
    actionPlanId,
    instrumentType,
    instrumentId
  } = useParams();

  const routePermissions: { [key: string]: string[] } = {
    viewer: [
      '/',
      `/${structureId}`,
      `/${structureId}/general`,
      `/${structureId}/monitoring`,
      `/${structureId}/monitoring/instrument/${instrumentType}`,
      `/${structureId}/monitoring/instrument/${instrumentType}/view/${instrumentId}`,
      `/${structureId}/monitoring/readings`,
      `/${structureId}/monitoring/graphs`,
      `/${structureId}/monitoring/sectionGraphs`,
      `/${structureId}/inspections`,
      `/${structureId}/actionplan`,
      `/${structureId}/actionplan/${actionPlanId}`,
      `/${structureId}/actionplan/${actionPlanId}/images`,
      `/${structureId}/actionplan/${actionPlanId}/history`,
      `/${structureId}/ato`,
      `/${structureId}/ato/${atoId}`,
      `/${structureId}/ato/${atoId}/records`,
      `/${structureId}/ato/${atoId}/videos`,
      `/${structureId}/ato/${atoId}/fotos`,
      `/${structureId}/ato/${atoId}/view/${recordId}`,
      `/${structureId}/psb`,
      `/${structureId}/gistm`
    ],
    editor: [
      '/',
      `/${structureId}`,
      `/${structureId}/general`,
      `/${structureId}/monitoring`,
      `/${structureId}/monitoring/instrument/${instrumentType}`,
      `/${structureId}/monitoring/instrument/${instrumentType}/edit/${instrumentId}`,
      `/${structureId}/monitoring/readings`,
      `/${structureId}/monitoring/graphs`,
      `/${structureId}/monitoring/sectionGraphs`,
      `/${structureId}/inspections`,
      `/${structureId}/actionplan`,
      `/${structureId}/actionplan/${actionPlanId}`,
      `/${structureId}/actionplan/${actionPlanId}/images`,
      `/${structureId}/actionplan/${actionPlanId}/history`,
      `/${structureId}/actionplan/${actionPlanId}/team`,
      `/${structureId}/ato`,
      `/${structureId}/ato/${atoId}`,
      `/${structureId}/ato/${atoId}/records`,
      `/${structureId}/ato/${atoId}/videos`,
      `/${structureId}/ato/${atoId}/fotos`,
      `/${structureId}/ato/${atoId}/view/${recordId}`,
      `/${structureId}/ato/${atoId}/edit/${recordId}`,
      `/${structureId}/psb`,
      `/${structureId}/gistm`
    ]
  };

  const userSession = localStorage.user;
  const token = userSession ? JSON.parse(userSession).token : null;
  const decoded = token ? jwtDecode(token) : null;

  if (decoded && decoded.exp && new Date() > new Date(decoded.exp * 1000)) {
    localStorage.clear();
    localStorage.setItem('urlPath', location.pathname);
    return <Navigate to="/login" />;
  }

  if (userData.isLoggedWithTemporaryPassword === true) {
    return <Navigate to="/login" />;
  }

  const userHasPermission = (path: string) => {
    const role = userData.role;
    if (role === 'owner') return true;

    const currentModule = location.pathname.split('/')[2];
    if (!currentModule) return true;

    const roleModule = modulesByStructure
      .find(
        (module) =>
          module.moduleName.toLocaleLowerCase() ===
          currentModule?.toLocaleLowerCase()
      )
      ?.role.toLocaleLowerCase();

    if (!roleModule) return false;

    if (roleModule === 'admin') return true;

    const allowedRoutes = routePermissions[roleModule] || [];

    return allowedRoutes.includes(path);
  };

  if (isAuthenticated) {
    const currentPath = location.pathname;
    if (userHasPermission(currentPath)) {
      return <Outlet />;
    } else {
      toastfyError(translate('userNotHasPermission'));
      if (structureId) return <Navigate to={`/${structureId}`} />;

      return <Navigate to="/" />;
    }
  }

  localStorage.setItem('urlPath', location.pathname);
  return <Navigate to="/login" />;
};

export default PrivateRoute;
