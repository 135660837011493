import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ListLatestWaterLevelReadingQueryVariables = Types.Exact<{
  instrumentId: Types.Scalars['String']['input'];
}>;


export type ListLatestWaterLevelReadingQuery = { __typename?: 'Query', listLatestWaterLevelReading: { __typename?: 'ListLatestWaterLevelReadingType', alertLevels?: string | null, date: Date, id: string, name: string, operationalStatus: string, type: string, value: number, observation?: string | null } };


export const ListLatestWaterLevelReadingDocument = gql`
    query ListLatestWaterLevelReading($instrumentId: String!) {
  listLatestWaterLevelReading(instrumentId: $instrumentId) {
    alertLevels
    date
    id
    name
    operationalStatus
    type
    value
    observation
  }
}
    `;
export type ListLatestWaterLevelReadingQueryResult = Apollo.QueryResult<ListLatestWaterLevelReadingQuery, ListLatestWaterLevelReadingQueryVariables>;