import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type CreateInspectionMutationVariables = Types.Exact<{
  data: Types.CreateInspectionInput;
}>;


export type CreateInspectionMutation = { __typename?: 'Mutation', createInspection: string };


export const CreateInspectionDocument = gql`
    mutation CreateInspection($data: CreateInspectionInput!) {
  createInspection(data: $data)
}
    `;
export type CreateInspectionMutationFn = Apollo.MutationFunction<CreateInspectionMutation, CreateInspectionMutationVariables>;
export type CreateInspectionMutationResult = Apollo.MutationResult<CreateInspectionMutation>;
export type CreateInspectionMutationOptions = Apollo.BaseMutationOptions<CreateInspectionMutation, CreateInspectionMutationVariables>;