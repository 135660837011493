import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type DeleteInstrumentAlertLevelMutationVariables = Types.Exact<{
  structureId: Types.Scalars['String']['input'];
  data: Types.DeleteInstrumentAlertLevelInput;
}>;


export type DeleteInstrumentAlertLevelMutation = { __typename?: 'Mutation', deleteInstrumentAlertLevel: string };


export const DeleteInstrumentAlertLevelDocument = gql`
    mutation DeleteInstrumentAlertLevel($structureId: String!, $data: DeleteInstrumentAlertLevelInput!) {
  deleteInstrumentAlertLevel(structureId: $structureId, data: $data)
}
    `;
export type DeleteInstrumentAlertLevelMutationFn = Apollo.MutationFunction<DeleteInstrumentAlertLevelMutation, DeleteInstrumentAlertLevelMutationVariables>;
export type DeleteInstrumentAlertLevelMutationResult = Apollo.MutationResult<DeleteInstrumentAlertLevelMutation>;
export type DeleteInstrumentAlertLevelMutationOptions = Apollo.BaseMutationOptions<DeleteInstrumentAlertLevelMutation, DeleteInstrumentAlertLevelMutationVariables>;