import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type EditPiezometerMutationVariables = Types.Exact<{
  structureInfo: Types.StructureInfoInput;
  data: Types.EditPiezometerInaInput;
}>;


export type EditPiezometerMutation = { __typename?: 'Mutation', editPiezometer: string };


export const EditPiezometerDocument = gql`
    mutation EditPiezometer($structureInfo: StructureInfoInput!, $data: EditPiezometerInaInput!) {
  editPiezometer(structureInfo: $structureInfo, data: $data)
}
    `;
export type EditPiezometerMutationFn = Apollo.MutationFunction<EditPiezometerMutation, EditPiezometerMutationVariables>;
export type EditPiezometerMutationResult = Apollo.MutationResult<EditPiezometerMutation>;
export type EditPiezometerMutationOptions = Apollo.BaseMutationOptions<EditPiezometerMutation, EditPiezometerMutationVariables>;