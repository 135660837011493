import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ActionPlanApprovalFlowMutationVariables = Types.Exact<{
  actionPlanId: Types.Scalars['String']['input'];
}>;


export type ActionPlanApprovalFlowMutation = { __typename?: 'Mutation', actionPlanApprovalFlow: string };


export const ActionPlanApprovalFlowDocument = gql`
    mutation ActionPlanApprovalFlow($actionPlanId: String!) {
  actionPlanApprovalFlow(actionPlanId: $actionPlanId)
}
    `;
export type ActionPlanApprovalFlowMutationFn = Apollo.MutationFunction<ActionPlanApprovalFlowMutation, ActionPlanApprovalFlowMutationVariables>;
export type ActionPlanApprovalFlowMutationResult = Apollo.MutationResult<ActionPlanApprovalFlowMutation>;
export type ActionPlanApprovalFlowMutationOptions = Apollo.BaseMutationOptions<ActionPlanApprovalFlowMutation, ActionPlanApprovalFlowMutationVariables>;