import React, { useEffect, useState } from 'react';
import {
  CheckboxContainer,
  CheckboxInput,
  CheckboxLabel,
  ErrorStyled
} from './InputCheckbox.styles';
import { InputCheckboxProps } from './InputCheckbox.interfaces';
import { useTranslation } from 'react-i18next';

const InputCheckbox = ({
  label,
  name,
  register,
  setValue: setFormValue,
  onChange,
  value: propValue,
  error,
  errorMessage
}: InputCheckboxProps) => {
  const { t: translate } = useTranslation();
  const [checked, setChecked] = useState<boolean>(propValue || false);

  useEffect(() => {
    setChecked(propValue || false);
  }, [propValue]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    setChecked(newValue);
    if (setFormValue) {
      setFormValue(name, newValue);
    }

    if (onChange) {
      onChange(newValue);
    }
  };

  return (
    <CheckboxContainer>
      <CheckboxLabel error={error}>
        <CheckboxInput
          type="checkbox"
          name={name}
          error={error}
          checked={checked}
          {...(register && register(name))}
          onChange={handleChange}
        />
        {label}
      </CheckboxLabel>
      {error ? (
        <ErrorStyled>{translate(errorMessage)}</ErrorStyled>
      ) : (
        <ErrorStyled />
      )}
    </CheckboxContainer>
  );
};

export default InputCheckbox;
