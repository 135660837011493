import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { z, ZodIssueCode } from 'zod';
import ErrorsTreatments from '../../../../utils/errorTreatment';
import { toastfyError, toastfySuccess } from '../../../Toastify';
import Text from '../../Atoms/Text/Text';
import InputText from '../../Molecules/InputText/InputText';
import { MdEdit } from 'react-icons/md';
import { TiDelete } from 'react-icons/ti';
import Button from '../../Atoms/Button/Button';
import TableComponent from '../../Molecules/TableComponent/TableComponent';
import DatepickerInput from '../../Molecules/DatepickerInput/DatepickerInput';
import InputSelectSearch from '../../Molecules/InputSelectSearch/InputSelectSearch';
import { AvailableContextActionsProps } from '../../Molecules/ActionMenu/ActionMenu.interface';
import {
  InstrumentReadingTypeEnum,
  InstrumentStatusEnum,
  ListSurfaceMarkerAlertLevelType,
  ListSurfaceMarkersReferenceType
} from '../../../../data/graphql/base-schema';
import Enums from '../../../../utils/enumns';
import InputCheckbox from '../../Molecules/InputCheckbox/InputCheckbox';
import {
  ListInstrumentByIdQuery,
  ListInstrumentByIdQueryVariables,
  ListInstrumentByIdDocument
} from '../../../../data/graphql/query/generated/listInstrumentsById';
import { useState } from 'react';
import ViewModal from '../../Molecules/ViewModal/ViewModal';
import {
  DeleteInstrumentAlertLevelMutation,
  DeleteInstrumentAlertLevelMutationVariables,
  DeleteInstrumentAlertLevelDocument
} from '../../../../data/graphql/generated/deleteInstrumentAlertLevel.mutation';
import { ListSurfaceMarkersByStructureDocument } from '../../../../data/graphql/query/generated/listSurfaceMarkersByStructure.query';
import {
  DivButton,
  DivInputs
} from '../EditPiezometerAndInaPage/EditPiezometerAndInaPage.styles';
import {
  BorderContainer,
  BorderContainerSpecificInfos,
  DivFile,
  Footer,
  HolderAlertLevel,
  HolderCommonInfos,
  HolderContainerTitle,
  HolderReference,
  HolderSpecificsInfo,
  HolderTitle,
  MainContainer
} from './EditSurfaceMarker.styles';
import {
  EditSurfaceMarkerMutation,
  EditSurfaceMarkerMutationVariables,
  EditSurfaceMarkerDocument
} from '../../../../data/graphql/generated/editSurfaceMarker.mutation';
import {
  ListAlertsLevelBySurfaceMarkerPagQuery,
  ListAlertsLevelBySurfaceMarkerPagQueryVariables,
  ListAlertsLevelBySurfaceMarkerPagDocument
} from '../../../../data/graphql/query/generated/listSurfaceMarkerAlertLevels';
import {
  ListReferencesBySurfaceMarkerPagQuery,
  ListReferencesBySurfaceMarkerPagQueryVariables,
  ListReferencesBySurfaceMarkerPagDocument
} from '../../../../data/graphql/query/generated/listSurfaceMarkerReferences';
import {
  ListAlertLevelsDataProps,
  ListReferencesDataProps
} from './EditSurfaceMarker.interface';
import AlertLevelSurfaceMarkerForm from '../../Organisms/AlertLevelSurfaceMarkerForm/AlertLevelSurfaceMarkerForm';
import SurfaceMarkerReferenceForm from '../../Organisms/SurfaceMarkerReferenceForm/SurfaceMarkerReference';
import ModalConfirm from '../../Molecules/ModalConfirm/ModalConfirm';
import { GroupText } from '../../Molecules/GroupText/GroupText';
import { FileType } from '../../Molecules/FileViewer/FileViewer.interfaces';
import {
  SaveInstrumentDocsDocument,
  SaveInstrumentDocsMutation,
  SaveInstrumentDocsMutationVariables
} from '../../../../data/graphql/generated/saveInstrumentDocs.mutation';
import {
  DeleteInstrumentDocByIdDocument,
  DeleteInstrumentDocByIdMutation,
  DeleteInstrumentDocByIdMutationVariables
} from '../../../../data/graphql/generated/deleteInstrumentDocById.mutation';
import { API_URL } from '../../../../utils/const';
import InputFileWithList from '../../Organisms/InputFileWithList/InputFileWithList';
import FileList from '../../Molecules/FileList/FileList';

const EditSurfaceMarker = () => {
  const { t: translate } = useTranslation();
  const { instrumentId, structureId } = useParams();
  const { OperationalStatus, TypeOfReading, TypeOfInstallation } = Enums();

  const [pageAlerts, setPageAlerts] = useState<number>(1);
  const [pageReferences, setPageReferences] = useState<number>(1);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalReference, setShowModalReference] = useState<boolean>(false);
  const [alertLevelEdit, setAlertLevelEdit] =
    useState<ListAlertLevelsDataProps | null>(null);
  const [referenceEdit, setReferenceEdit] =
    useState<ListReferencesDataProps | null>(null);
  const [deleteAlertLevel, setDeleteAlertLevel] = useState<string | null>(null);
  const [files, setFiles] = useState<FileType[]>([]);

  const canEdit = location.pathname.includes('edit');

  const [getInstumentData] = useLazyQuery<
    ListInstrumentByIdQuery,
    ListInstrumentByIdQueryVariables
  >(ListInstrumentByIdDocument);

  const { data: surfaceMarkerAlertLevels } = useQuery<
    ListAlertsLevelBySurfaceMarkerPagQuery,
    ListAlertsLevelBySurfaceMarkerPagQueryVariables
  >(ListAlertsLevelBySurfaceMarkerPagDocument, {
    variables: {
      instrumentId: instrumentId!,
      pageInfo: {
        page: pageAlerts,
        limit: 3
      }
    },
    onError: ({ graphQLErrors }) => {
      const errorMessage = ErrorsTreatments(
        graphQLErrors[0].message,
        translate
      );
      toastfyError(errorMessage);
    }
  });

  const { data: surfaceMarkerReferences } = useQuery<
    ListReferencesBySurfaceMarkerPagQuery,
    ListReferencesBySurfaceMarkerPagQueryVariables
  >(ListReferencesBySurfaceMarkerPagDocument, {
    variables: {
      instrumentId: instrumentId!,
      pageInfo: {
        page: pageReferences,
        limit: 3
      }
    },
    onError: ({ graphQLErrors }) => {
      const errorMessage = ErrorsTreatments(
        graphQLErrors[0].message,
        translate
      );
      toastfyError(errorMessage);
    }
  });

  const [editSurfaceMarker] = useMutation<
    EditSurfaceMarkerMutation,
    EditSurfaceMarkerMutationVariables
  >(EditSurfaceMarkerDocument);

  const [deleteInstrumentAlertLevel] = useMutation<
    DeleteInstrumentAlertLevelMutation,
    DeleteInstrumentAlertLevelMutationVariables
  >(DeleteInstrumentAlertLevelDocument);

  const [saveInstrumentDocs] = useMutation<
    SaveInstrumentDocsMutation,
    SaveInstrumentDocsMutationVariables
  >(SaveInstrumentDocsDocument);

  const [deleteInstrumentDocById] = useMutation<
    DeleteInstrumentDocByIdMutation,
    DeleteInstrumentDocByIdMutationVariables
  >(DeleteInstrumentDocByIdDocument);

  const basicInfoInstrumentSchema = z
    .object({
      name: z.string().min(1, translate('fieldIsRequired')),
      coordinateE: z.string(),
      coordinateN: z.string(),
      installationDate: z.date().nullable(),
      activationDate: z.date().nullable(),
      operationalStatus: z
        .object({
          value: z.string(),
          label: z.string()
        })
        .nullable()
        .refine((val) => val !== null, {
          message: translate('fieldIsRequired')
        }),
      deactivationDate: z.date().nullable().optional(),
      installLocation: z.object({
        value: z.string(),
        label: z.string()
      }),
      readingType: z
        .object({
          value: z.string(),
          label: z.string()
        })
        .nullable()
        .refine((val) => val !== null, {
          message: translate('fieldIsRequired')
        }),
      isUTM: z.boolean()
    })
    .superRefine((data, ctx) => {
      const {
        installationDate,
        activationDate,
        coordinateE,
        coordinateN,
        isUTM,
        operationalStatus,
        deactivationDate
      } = data;

      if (!installationDate && activationDate) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          path: ['installationDate'],
          message: translate('fieldIsRequired')
        });
      }

      if (
        activationDate &&
        installationDate &&
        activationDate < installationDate
      ) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          path: ['activationDate'],
          message: translate('dateActivationIsLessThanInstallationDate')
        });
      }

      if (
        !isUTM &&
        (parseInt(coordinateE) > 90 || parseInt(coordinateE) < -90)
      ) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          path: ['coordinateE'],
          message: translate('ValidationCoordinateE')
        });
      }

      if (
        !isUTM &&
        (parseInt(coordinateN) > 180 || parseInt(coordinateN) < -180)
      ) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          path: ['coordinateN'],
          message: translate('ValidationCoordinateN')
        });
      }

      if (isUTM && parseInt(coordinateE) < 0) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          path: ['coordinateE'],
          message: translate('coordinateUtmCannottNegative')
        });
      }

      if (isUTM && parseInt(coordinateN) < 0) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          path: ['coordinateN'],
          message: translate('coordinateUtmCannottNegative')
        });
      }

      if (operationalStatus.value === 'Inactive' && !deactivationDate) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          path: ['deactivationDate'],
          message: translate('fieldIsRequired')
        });
      }

      if (
        operationalStatus.value === 'Inactive' &&
        deactivationDate &&
        activationDate &&
        deactivationDate < activationDate
      ) {
        ctx.addIssue({
          code: ZodIssueCode.custom,
          path: ['deactivationDate'],
          message: translate('dateDeactivationNeedToBeGreaterThanActivation')
        });
      }
    });

  type instrumentCommonInfosType = z.infer<typeof basicInfoInstrumentSchema>;

  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    watch
  } = useForm<instrumentCommonInfosType>({
    resolver: zodResolver(basicInfoInstrumentSchema),
    defaultValues: async () => {
      try {
        const { data: instrumentData } = await getInstumentData({
          variables: { instrumentId: instrumentId! },
          onError: ({ graphQLErrors }) => {
            const errorMessage = ErrorsTreatments(
              graphQLErrors[0].message,
              translate
            );
            toastfyError(errorMessage);
          }
        });
        if (instrumentData?.listInstrumentById) {
          const instrument = instrumentData.listInstrumentById;
          setFiles(
            instrument.docs.map((file) => {
              return {
                id: file.id,
                name: file.fileName,
                url: `${API_URL}/${file.url}`
              };
            })
          );

          return {
            name: instrument.name,
            coordinateE: instrument.coordinateE.toString() || '',
            coordinateN: instrument.coordinateN.toString() || '',
            isUTM: instrument.isUTM,
            activationDate: instrument.activationDate
              ? new Date(instrument.activationDate)
              : new Date(),
            installationDate: instrument.installationDate
              ? new Date(instrument.installationDate)
              : new Date(),
            operationalStatus: {
              value: instrument.operationalStatus || '',
              label: instrument.operationalStatus || ''
            },
            deactivationDate: instrument.deactivationDate
              ? new Date(instrument.deactivationDate)
              : null,
            installLocation: {
              value: instrument.installLocation || '',
              label: instrument.installLocation || ''
            },
            readingType: {
              value: instrument.readingType || '',
              label: instrument.readingType || ''
            }
          };
        } else {
          return {
            name: '',
            coordinateE: '',
            coordinateN: '',
            isUTM: false,
            activationDate: null,
            installationDate: null,
            operationalStatus: {
              value: '',
              label: ''
            },
            deactivationDate: null,
            installLocation: {
              value: '',
              label: ''
            },
            readingType: {
              value: '',
              label: ''
            }
          };
        }
      } catch (error) {
        return {
          name: '',
          coordinateE: '',
          coordinateN: '',
          isUTM: false,
          activationDate: null,
          installationDate: null,
          operationalStatus: {
            value: '',
            label: ''
          },
          deactivationDate: null,
          installLocation: {
            value: '',
            label: ''
          },
          readingType: {
            value: '',
            label: ''
          }
        };
      }
    }
  });

  const listAlertLevels =
    surfaceMarkerAlertLevels?.listAlertsLevelBySurfaceMarkerPag.nodes.map(
      (alertLevel: ListSurfaceMarkerAlertLevelType) => ({
        id: alertLevel.id,
        name: alertLevel.name,
        elevation: alertLevel.elevation,
        date: new Date(alertLevel?.date ?? '').toLocaleDateString(),
        coordinateE: alertLevel.coordinateE,
        coordinateN: alertLevel.coordinateN
      })
    );

  const listReferences =
    surfaceMarkerReferences?.listReferencesBySurfaceMarkerPag.nodes.map(
      (reference: ListSurfaceMarkersReferenceType) => ({
        id: reference.id,
        elevation: reference.elevation,
        date: new Date(reference.date ?? '').toLocaleDateString(),
        coordinateE: reference.coordinateE,
        coordinateN: reference.coordinateN
      })
    );

  const totalPagesAlertLevels =
    surfaceMarkerAlertLevels?.listAlertsLevelBySurfaceMarkerPag.pageInfo
      .totalPages;

  const totalPagesReferences =
    surfaceMarkerReferences?.listReferencesBySurfaceMarkerPag.pageInfo
      .totalPages;

  const handleEditSurfaceMarker = (dataFromForm: instrumentCommonInfosType) => {
    const surfaceMarker = {
      instrumentId: instrumentId!,
      name: dataFromForm.name,
      coordinateE: Number(dataFromForm.coordinateE),
      coordinateN: Number(dataFromForm.coordinateN),
      isUTM: dataFromForm.isUTM,
      operationalStatus: dataFromForm.operationalStatus
        .value as InstrumentStatusEnum,
      readingType: dataFromForm.readingType.value as InstrumentReadingTypeEnum,
      activationDate: dataFromForm.activationDate,
      deactivationDate:
        dataFromForm.operationalStatus.value === 'Active'
          ? null
          : dataFromForm.deactivationDate,
      installationDate: dataFromForm.installationDate,
      installLocation: dataFromForm.installLocation.value,
      sectionsId: []
    };
    editSurfaceMarker({
      variables: {
        structureInfo: {
          structureId: structureId || '',
          associatedStructureId: ''
        },
        data: {
          generalInfo: surfaceMarker
        }
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: ListSurfaceMarkersByStructureDocument,
          variables: {
            structureInfo: {
              structureId: structureId!,
              associatedStructureId: null
            }
          }
        },
        {
          query: ListInstrumentByIdDocument,
          variables: {
            instrumentId: instrumentId!
          }
        }
      ],
      onCompleted: () => {
        toastfySuccess(translate('updatedSuccessfully'));
        setDeleteAlertLevel(null);
      },
      onError: ({ graphQLErrors }) => {
        const errorMessage = ErrorsTreatments(
          graphQLErrors[0].message,
          translate
        );
        toastfyError(errorMessage);
        setDeleteAlertLevel(null);
      }
    });
  };

  const handleDeleteAlertLevel = async (id: string) => {
    await deleteInstrumentAlertLevel({
      variables: {
        structureId: structureId!,
        data: {
          instrumentId: instrumentId!,
          alertId: id
        }
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: ListInstrumentByIdDocument,
          variables: { instrumentId: instrumentId! }
        },
        {
          query: ListAlertsLevelBySurfaceMarkerPagDocument,
          variables: {
            instrumentId: instrumentId!,
            pageInfo: {
              page: pageAlerts,
              limit: 3
            }
          }
        }
      ],
      onError: ({ graphQLErrors }) => {
        const errorMessage = ErrorsTreatments(
          graphQLErrors[0].message,
          translate
        );
        toastfyError(errorMessage);
      },
      onCompleted: () => {
        toastfySuccess(translate('deletedSuccessfully'));
        setDeleteAlertLevel(null);
      }
    });
  };

  const columnsTableAlertLevels = [
    {
      key: 'name',
      label: `${translate('name')}`
    },
    {
      key: 'elevation',
      label: `${translate('elevation')}`
    },
    {
      key: 'coordinateE',
      label: `${translate('coordinateE')}`
    },
    {
      key: 'coordinateN',
      label: `${translate('coordinateN')}`
    },
    {
      key: 'date',
      label: `${translate('date')}`
    }
  ];

  const actionsTableAlertLevels: AvailableContextActionsProps<ListAlertLevelsDataProps>[] =
    [
      {
        name: `${translate('Edit')}`,
        icon: <MdEdit />,

        onClick: (alertlevel: ListAlertLevelsDataProps) =>
          handleEditAlertlevel(alertlevel),
        canShow: 'canEdit'
      },

      {
        name: `${translate('Delete')}`,
        icon: <TiDelete />,
        onClick: (alertLevel: ListAlertLevelsDataProps) => {
          setDeleteAlertLevel(alertLevel.id);
        },
        canShow: 'canCancel'
      }
    ];

  const columnsTableReferences = [
    {
      key: 'elevation',
      label: `${translate('elevation')}`
    },
    {
      key: 'coordinateE',
      label: `${translate('coordinateE')}`
    },
    {
      key: 'coordinateN',
      label: `${translate('coordinateN')}`
    },
    {
      key: 'date',
      label: `${translate('date')}`
    }
  ];

  const actionsTableReferences: AvailableContextActionsProps<ListReferencesDataProps>[] =
    [
      {
        name: `${translate('Edit')}`,
        icon: <MdEdit />,
        onClick: (reference: ListReferencesDataProps) => {
          handleEditReference(reference);
        },
        canShow: 'canEdit'
      }
    ];

  const handleEditAlertlevel = (alertLevelRole: ListAlertLevelsDataProps) => {
    const alertLevel =
      surfaceMarkerAlertLevels?.listAlertsLevelBySurfaceMarkerPag.nodes.find(
        (item) => item.id === alertLevelRole.id
      );
    if (!alertLevel) return;
    const newAlertLevel = {
      id: alertLevel.id,
      name: alertLevel.name || '',
      elevation: Number(alertLevel.elevation),
      date: new Date(alertLevel?.date || new Date()) || null,
      coordinateE: Number(alertLevel.coordinateE),
      coordinateN: Number(alertLevel.coordinateN)
    };
    setAlertLevelEdit(newAlertLevel);
    setShowModal(true);
  };

  const handleEditReference = (referenceRole: ListReferencesDataProps) => {
    const reference =
      surfaceMarkerReferences?.listReferencesBySurfaceMarkerPag.nodes.find(
        (item) => item.id === referenceRole.id
      );
    if (!reference) return;
    const newReference = {
      id: reference.id,
      elevation: Number(reference.elevation),
      date: new Date(reference?.date || new Date()) || null,
      coordinateE: Number(reference.coordinateE),
      coordinateN: Number(reference.coordinateN)
    };
    setReferenceEdit(newReference);
    setShowModalReference(true);
  };

  const handleFileChange = async (newFiles: FileList) => {
    saveInstrumentDocs({
      variables: {
        data: {
          instrumentId: instrumentId!,
          docs: Array.from(newFiles)
        }
      },
      update: (cache, { data }) => {
        const cacheData = cache.readQuery<ListInstrumentByIdQuery>({
          query: ListInstrumentByIdDocument,
          variables: { instrumentId: instrumentId! }
        });
        if (cacheData) {
          const updatedData =
            data?.saveInstrumentDocs?.map((file) => ({
              id: file.id,
              fileName: file.fileName,
              url: file.url
            })) || [];
          cache.writeQuery<ListInstrumentByIdQuery>({
            query: ListInstrumentByIdDocument,
            variables: { instrumentId: instrumentId! },
            data: {
              listInstrumentById: {
                ...cacheData.listInstrumentById,
                docs: [...cacheData.listInstrumentById.docs, ...updatedData]
              }
            }
          });

          setFiles((prev) => [
            ...prev,
            ...updatedData.map((file) => ({
              id: file.id,
              name: file.fileName,
              url: `${API_URL}/${file.url}`
            }))
          ]);
        }
      },
      awaitRefetchQueries: true,
      onCompleted: () => {
        toastfySuccess(translate('registeredSuccessfully'));
      },
      onError: ({ graphQLErrors }) => {
        const errorMessage = ErrorsTreatments(
          graphQLErrors[0].message,
          translate
        );
        toastfyError(errorMessage);
      }
    });
  };

  const handleDeleteFile = async (fileToDelete: FileType) => {
    deleteInstrumentDocById({
      variables: {
        deleteInstrumentDocByIdId: fileToDelete.id!
      },
      update: (cache) => {
        const cacheData = cache.readQuery<ListInstrumentByIdQuery>({
          query: ListInstrumentByIdDocument,
          variables: { instrumentId: instrumentId! }
        });
        if (cacheData) {
          const updatedData = cacheData.listInstrumentById.docs.filter(
            (file) => file.id !== fileToDelete.id
          );
          cache.writeQuery<ListInstrumentByIdQuery>({
            query: ListInstrumentByIdDocument,
            variables: { instrumentId: instrumentId! },
            data: {
              listInstrumentById: {
                ...cacheData.listInstrumentById,
                docs: updatedData
              }
            }
          });
          setFiles((prev) =>
            prev.filter((file) => file.id !== fileToDelete.id)
          );
        }
      },
      awaitRefetchQueries: true,
      onCompleted: () => {
        toastfySuccess(translate('deletedSuccessfully'));
      },
      onError: ({ graphQLErrors }) => {
        const errorMessage = ErrorsTreatments(
          graphQLErrors[0].message,
          translate
        );
        toastfyError(errorMessage);
      }
    });
  };

  return (
    <>
      <MainContainer>
        <HolderCommonInfos>
          <HolderContainerTitle>
            <Text color="black" type="span" weight="bold">
              {translate('basicInfos')}
            </Text>
          </HolderContainerTitle>
          {canEdit ? (
            <BorderContainer>
              <DivInputs>
                <InputText
                  width="300px"
                  control={control}
                  label={translate('name')}
                  name={'name'}
                  type={'text'}
                  error={!!errors.name}
                  errorMessage={errors?.name?.message}
                />
              </DivInputs>
              <DivInputs>
                <InputText
                  width="300px"
                  control={control}
                  label={translate('coordinateE')}
                  name={'coordinateE'}
                  type={'number'}
                  error={!!errors.coordinateE}
                  errorMessage={errors?.coordinateE?.message}
                />
              </DivInputs>
              <DivInputs>
                <InputText
                  width="300px"
                  control={control}
                  label={translate('coordinateN')}
                  name={'coordinateN'}
                  type={'number'}
                  error={!!errors.coordinateN}
                  errorMessage={errors?.coordinateN?.message}
                />
              </DivInputs>
              <DivInputs>
                <InputCheckbox
                  register={register}
                  label={translate('coordinateUTM')}
                  name={'isUTM'}
                  value={watch('isUTM')}
                />
              </DivInputs>
              <DivInputs>
                <DatepickerInput
                  control={control}
                  label={translate('installationDate')}
                  name="installationDate"
                  placeholder={translate('NoInformated')}
                  time={false}
                  error={!!errors.installationDate}
                  errorMessage={errors?.installationDate?.message}
                />
              </DivInputs>
              <DivInputs>
                <DatepickerInput
                  control={control}
                  label={translate('activationDate')}
                  name="activationDate"
                  placeholder={translate('NoInformated')}
                  time={false}
                  error={!!errors.activationDate}
                  errorMessage={errors?.activationDate?.message}
                />
              </DivInputs>
              <DivInputs>
                <InputSelectSearch
                  control={control}
                  placeholder={translate('operationalStatus')}
                  width="300px"
                  label={translate('operationalStatus')}
                  options={OperationalStatus}
                  name="operationalStatus"
                  error={!!errors.operationalStatus}
                  errorMessage={errors?.operationalStatus?.message}
                />
              </DivInputs>
              <DivInputs>
                <DatepickerInput
                  control={control}
                  label={translate('deactivationDate')}
                  name="deactivationDate"
                  placeholder={translate('NoInformated')}
                  time={false}
                  error={!!errors.deactivationDate}
                  errorMessage={errors?.deactivationDate?.message}
                  disabled={watch('operationalStatus')?.value === 'Active'}
                />
              </DivInputs>
              <DivInputs>
                <InputSelectSearch
                  control={control}
                  placeholder={translate('installLocation')}
                  width="300px"
                  label={translate('installLocation')}
                  options={TypeOfInstallation}
                  name="installLocation"
                  error={!!errors.installLocation}
                  errorMessage={errors?.installLocation?.message}
                />
              </DivInputs>
              <DivInputs>
                <InputSelectSearch
                  control={control}
                  placeholder={translate('typeOfReading')}
                  width="300px"
                  label={translate('typeOfReading')}
                  options={TypeOfReading}
                  name="readingType"
                  error={!!errors.readingType}
                  errorMessage={errors?.readingType?.message}
                />
              </DivInputs>
              <InputFileWithList
                name="file"
                accept=".xlsx,.pdf,.docx,.xls,.xlsx,.doc"
                label={translate('File')}
                files={files}
                onChange={(event) => {
                  if (event.target.files) {
                    handleFileChange(event.target.files);
                  }
                }}
                canDelete={canEdit}
                onDelete={(file) => handleDeleteFile(file)}
              />
              <DivButton>
                {canEdit && (
                  <Button
                    variant="primary"
                    onClick={handleSubmit(handleEditSurfaceMarker)}
                    text={translate('saveGeneralData')}
                  />
                )}
              </DivButton>
            </BorderContainer>
          ) : (
            <BorderContainer>
              <DivInputs>
                <GroupText title={translate('name')} value={watch('name')} />
              </DivInputs>
              <DivInputs>
                <GroupText
                  title={translate('coordinateE')}
                  value={watch('coordinateE')}
                />
              </DivInputs>
              <DivInputs>
                <GroupText
                  title={translate('coordinateN')}
                  value={watch('coordinateN')}
                />
              </DivInputs>
              <DivInputs>
                <GroupText
                  title={translate('coordinateUTM')}
                  value={watch('isUTM') ? translate('Yes') : translate('No')}
                />
              </DivInputs>
              <DivInputs>
                <GroupText
                  title={translate('installationDate')}
                  value={
                    watch('installationDate')
                      ? new Date(
                          watch('installationDate') || ''
                        ).toLocaleDateString()
                      : '-'
                  }
                />
              </DivInputs>
              <DivInputs>
                <GroupText
                  title={translate('activationDate')}
                  value={
                    watch('activationDate')
                      ? new Date(
                          watch('activationDate') || ''
                        ).toLocaleDateString()
                      : '-'
                  }
                />
              </DivInputs>
              <DivInputs>
                <GroupText
                  title={translate('operationalStatus')}
                  value={translate(
                    watch('operationalStatus')
                      ? watch('operationalStatus').label
                      : '-'
                  )}
                />
              </DivInputs>
              <DivInputs>
                <GroupText
                  title={translate('deactivationDate')}
                  value={
                    watch('deactivationDate')
                      ? new Date(
                          watch('deactivationDate') || ''
                        ).toLocaleDateString()
                      : '-'
                  }
                />
              </DivInputs>
              <DivInputs>
                <GroupText
                  title={translate('installLocation')}
                  value={translate(
                    watch('installLocation')?.value
                      ? watch('installLocation').value
                      : '-'
                  )}
                />
              </DivInputs>
              <DivInputs>
                <GroupText
                  title={translate('typeOfReading')}
                  value={translate(
                    watch('readingType') ? watch('readingType').value : '-'
                  )}
                />
              </DivInputs>
              <DivFile>
                <Text type="label" weight="bold">
                  {translate('File')}
                </Text>
                {files.length > 0 ? (
                  <FileList
                    files={files}
                    onDelete={(file) => handleDeleteFile(file)}
                    canDelete={canEdit}
                  />
                ) : (
                  <Text type="span">
                    {translate('ThereAreNoRegisteredFiles')}
                  </Text>
                )}
              </DivFile>
            </BorderContainer>
          )}
        </HolderCommonInfos>
        <HolderSpecificsInfo>
          <HolderContainerTitle>
            <Text color="black" type="span" weight="bold">
              {translate('instrumentInformation')}
            </Text>
          </HolderContainerTitle>
          <BorderContainerSpecificInfos>
            <HolderAlertLevel>
              <HolderTitle>
                <Text color="white" type="span" weight="default">
                  {translate('alertLevels')}
                </Text>
              </HolderTitle>
              <TableComponent
                tableData={listAlertLevels || []}
                columns={columnsTableAlertLevels}
                actions={canEdit ? actionsTableAlertLevels : undefined}
                paginator
                totalPages={totalPagesAlertLevels || 1}
                pageState={{
                  currentPage: pageAlerts,
                  totalPages: totalPagesAlertLevels || 1,
                  nextPage: () => setPageAlerts(pageAlerts + 1),
                  previousPage: () => setPageAlerts(pageAlerts - 1)
                }}
              />
              <Footer>
                {canEdit && (
                  <Button
                    variant="primary"
                    onClick={() => {
                      setAlertLevelEdit(null);
                      setShowModal(true);
                    }}
                    text={translate('newAlertLevel')}
                  />
                )}
              </Footer>
            </HolderAlertLevel>
            <HolderReference>
              <HolderTitle>
                <Text color="white" type="span" weight="default">
                  {translate('reference')}
                </Text>
              </HolderTitle>
              <TableComponent
                tableData={listReferences || []}
                columns={columnsTableReferences}
                actions={canEdit ? actionsTableReferences : undefined}
                paginator
                totalPages={totalPagesReferences || 1}
                pageState={{
                  currentPage: pageReferences,
                  totalPages: totalPagesReferences || 1,
                  nextPage: () => setPageReferences(pageReferences + 1),
                  previousPage: () => setPageReferences(pageReferences - 1)
                }}
              />
            </HolderReference>
          </BorderContainerSpecificInfos>
        </HolderSpecificsInfo>
      </MainContainer>
      <ViewModal
        showModal={showModal}
        title={
          alertLevelEdit
            ? `${translate('editAlertlevel')}`
            : `${translate('newAlertLevel')}`
        }
        onClose={() => {
          setShowModal(false);
          setAlertLevelEdit(null);
        }}
        component={
          <AlertLevelSurfaceMarkerForm
            alertLevel={alertLevelEdit}
            setShowModal={setShowModal}
          />
        }
        width={'auto'}
        height={'auto'}
      />
      <ViewModal
        showModal={showModalReference}
        title={`${translate('editReference')}`}
        onClose={() => {
          setShowModalReference(false);
          setReferenceEdit(null);
        }}
        component={
          <SurfaceMarkerReferenceForm
            reference={referenceEdit}
            setShowModal={setShowModalReference}
          />
        }
        width={'auto'}
        height={'auto'}
      />

      <ModalConfirm
        showModal={!!deleteAlertLevel}
        text={translate('ConfirmDelete')}
        subtitle={translate('ActionCannotBeUndone')}
        title={translate('deleteAlertLevel')}
        onClose={() => setDeleteAlertLevel(null)}
        onConfirm={() => handleDeleteAlertLevel(deleteAlertLevel!)}
      />
    </>
  );
};

export default EditSurfaceMarker;
