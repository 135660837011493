import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ListCompanyRelatedUsersAndStructuresQueryVariables = Types.Exact<{
  data: Types.ListCompanyRelatedUsersAndStructuresInput;
}>;


export type ListCompanyRelatedUsersAndStructuresQuery = { __typename?: 'Query', listCompanyRelatedUsersAndStructures: { __typename?: 'CompanyRelatedUsersAndStructuresType', id: string, status: boolean, name: string, logo?: string | null, cnpj: string, collaborators: Array<{ __typename?: 'BasicUserWithIdType', name: string, email: string, id: string }>, structures: Array<{ __typename?: 'StructureBaseType', name: string, id: string }> } };


export const ListCompanyRelatedUsersAndStructuresDocument = gql`
    query ListCompanyRelatedUsersAndStructures($data: ListCompanyRelatedUsersAndStructuresInput!) {
  listCompanyRelatedUsersAndStructures(data: $data) {
    id
    status
    name
    logo
    cnpj
    collaborators {
      name
      email
      id
    }
    structures {
      name
      id
    }
  }
}
    `;
export type ListCompanyRelatedUsersAndStructuresQueryResult = Apollo.QueryResult<ListCompanyRelatedUsersAndStructuresQuery, ListCompanyRelatedUsersAndStructuresQueryVariables>;