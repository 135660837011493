import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type CreateWaterLevelReadingMutationVariables = Types.Exact<{
  structureInfo: Types.StructureInfoInput;
  data: Array<Types.CreateWaterMeterReadingInput> | Types.CreateWaterMeterReadingInput;
}>;


export type CreateWaterLevelReadingMutation = { __typename?: 'Mutation', createWaterLevelReading: Array<{ __typename?: 'ValidateReadingValuesInfosModelType', line?: number | null, instrument?: string | null, error?: string | null }> };


export const CreateWaterLevelReadingDocument = gql`
    mutation CreateWaterLevelReading($structureInfo: StructureInfoInput!, $data: [CreateWaterMeterReadingInput!]!) {
  createWaterLevelReading(structureInfo: $structureInfo, data: $data) {
    line
    instrument
    error
  }
}
    `;
export type CreateWaterLevelReadingMutationFn = Apollo.MutationFunction<CreateWaterLevelReadingMutation, CreateWaterLevelReadingMutationVariables>;
export type CreateWaterLevelReadingMutationResult = Apollo.MutationResult<CreateWaterLevelReadingMutation>;
export type CreateWaterLevelReadingMutationOptions = Apollo.BaseMutationOptions<CreateWaterLevelReadingMutation, CreateWaterLevelReadingMutationVariables>;