import styled from 'styled-components';
import { Color } from '../../../../Styles/Styles';

export const DivContainer = styled.div`
  background-color: white;
  border: 1px solid ${Color.Brown1};
  border-radius: 5px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1000;
  width: 320px;
  height: 500px;
  color: black;
`;

export const DivNotification = styled.div`
  padding: 25px 20px;
  box-sizing: border-box;
  border-bottom: 1px solid ${Color.Brown3};
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DivWithouNotification = styled.div`
  background-color: white;
  border: 1px solid ${Color.Brown1};
  border-radius: 5px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1000;
  width: 320px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: start;
  padding: 20px 0;
`;
