import Text from '../../Atoms/Text/Text';
import { IconProfileProps } from './IconProfile.interface';
import { DivContainerProfile } from './IconProfile.styles';

const IconProfile = ({ acronym, disabled }: IconProfileProps) => {
  const nameParts = acronym.split(' ');
  const initialsArray = nameParts.map((part) => part.charAt(0).toUpperCase());
  const initialsString = initialsArray.slice(0, 2).join('');

  return (
    <DivContainerProfile disabled={disabled} acronym={acronym}>
      <Text type="span" color="white">
        {initialsString}
      </Text>
    </DivContainerProfile>
  );
};

export default IconProfile;
