import styled from 'styled-components';

export const DivContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const DivPagination = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: auto;
`;

export const DivNotification = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 20px;
`;

export const DivFilter = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  justify-content: space-around;
  width: 100%;
  gap: 10px;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
`;
