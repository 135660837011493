import { useMutation } from '@apollo/client';
import {
  DeleteStructureDamRaisingDocument,
  DeleteStructureDamRaisingMutation,
  DeleteStructureDamRaisingMutationVariables
} from '../graphql/generated/deleteStructureDamRaising';

export function useDeleteStructureDamRaising(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [deleteStructureDamRaising, { data, error, loading }] = useMutation<
    DeleteStructureDamRaisingMutation,
    DeleteStructureDamRaisingMutationVariables
  >(DeleteStructureDamRaisingDocument, {
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    deleteStructureDamRaising,
    data,
    error,
    loading
  };
}
