import Skeleton from 'react-loading-skeleton';
import { DrawerMenuTemplateProps } from './DrawerMenuTemplate.interface';
import {
  DivContainer,
  DivContainerSkeleton
} from './DrawerMenuTemplate.styles';
import DrawerMenu from '../../Molecules/DrawerMenu/DrawerMenu';

const DrawerMenuTemplate = ({
  content,
  title,
  menu = 'Menu',
  width,
  titleMenu,
  loading
}: DrawerMenuTemplateProps) => {
  return (
    <>
      {loading ? (
        <DivContainerSkeleton>
          <Skeleton height="95%" width={300} />
          <DivContainer>
            <Skeleton height={100} width="100%" />
            <Skeleton height={600} width="100%" />
          </DivContainer>
        </DivContainerSkeleton>
      ) : (
        <DrawerMenu
          menu={menu}
          title={titleMenu}
          width={width}
          component={
            <DivContainer>
              {title}
              {content}
            </DivContainer>
          }
        />
      )}
    </>
  );
};

export default DrawerMenuTemplate;
