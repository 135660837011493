import Button from '../../Atoms/Button/Button';
import Text from '../../Atoms/Text/Text';
import { HolderButtonLabel } from './LabelButtonStyles';
interface LabelButtonProps {
  onClick: () => void;
  Buttontext: string;
  title: string;
}

// TODO: Este component pode não ser necessário, pois pode ser substituído por um componente de texto e um botão comum

const LabelButton = ({ onClick, Buttontext, title }: LabelButtonProps) => {
  return (
    <HolderButtonLabel>
      <Text weight="bold" size="medium" type="h5" color='black'>
        {title}
      </Text>
      <Button variant="secondary" onClick={onClick} text={Buttontext} />
    </HolderButtonLabel>
  );
};

export default LabelButton;
