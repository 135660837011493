import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type MutationMutationVariables = Types.Exact<{ [key: string]: never }>;

export type MutationMutation = { __typename?: 'Mutation'; acceptTerms: string };

export const MutationDocument = gql`
  mutation Mutation {
    acceptTerms
  }
`;

export type MutationMutationFn = Apollo.MutationFunction<
  MutationMutation,
  MutationMutationVariables
>;

export type MutationMutationResult = Apollo.MutationResult<MutationMutation>;

export type MutationMutationOptions = Apollo.BaseMutationOptions<
  MutationMutation,
  MutationMutationVariables
>;
