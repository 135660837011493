import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type CreateSurfaceMarkersReadingMutationVariables = Types.Exact<{
  structureInfo: Types.StructureInfoInput;
  data: Array<Types.CreateSurfaceMarkersReadingInput> | Types.CreateSurfaceMarkersReadingInput;
}>;


export type CreateSurfaceMarkersReadingMutation = { __typename?: 'Mutation', createSurfaceMarkersReading: Array<{ __typename?: 'ValidateReadingValuesInfosModelType', line?: number | null, instrument?: string | null, error?: string | null }> };


export const CreateSurfaceMarkersReadingDocument = gql`
    mutation CreateSurfaceMarkersReading($structureInfo: StructureInfoInput!, $data: [CreateSurfaceMarkersReadingInput!]!) {
  createSurfaceMarkersReading(structureInfo: $structureInfo, data: $data) {
    line
    instrument
    error
  }
}
    `;
export type CreateSurfaceMarkersReadingMutationFn = Apollo.MutationFunction<CreateSurfaceMarkersReadingMutation, CreateSurfaceMarkersReadingMutationVariables>;
export type CreateSurfaceMarkersReadingMutationResult = Apollo.MutationResult<CreateSurfaceMarkersReadingMutation>;
export type CreateSurfaceMarkersReadingMutationOptions = Apollo.BaseMutationOptions<CreateSurfaceMarkersReadingMutation, CreateSurfaceMarkersReadingMutationVariables>;