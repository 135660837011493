import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AlertLevelsSelectInterface } from '../../../../@Types/Instruments/piezometer';
import PiezometersModel, {
  resolutionANM
} from '../../../../Models/PiezometerModel';
import { CreatePiezometerAlertLevelInput } from '../../../../data/graphql/base-schema';
import { useListSectionsByStructure } from '../../../../data/hooks/use-list-sections-by-structure.query';
import { DefaultInput } from '../../../Inputs/InputsStyle';
import InstrumentSelects from '../../../Selects/Instruments/InstrumentsSelects';
import { PiezometerControlLevelTable } from '../../../Tables/Instruments/PiezometerControlLevelTable';
import {
  CustomInputArea,
  RegisterInstrumentEspecificsInputs
} from '../RegisterInstrumentStyle';
import { AddLevelControlComponent } from './AddLevelControlComponent';
import { useParams } from 'react-router-dom';

export const RegisterPiezometerComponent = ({
  setInstrumentEspecificData,
  instrumentEspecificData,
  specificInfosError,
  isEditing,
  crossSection,
  longitudinalSection,
  setCrossSection,
  setLongitudinalSection
}: any) => {
  const [alertLevels, setAlertLevels] = useState<AlertLevelsSelectInterface>();
  const [controlLevels, setControlLevels] = useState<
    CreatePiezometerAlertLevelInput[]
  >([]);
  const [specificInfos, setSpecificInfos] = useState<any>({
    topCote: instrumentEspecificData.specificInfos?.topCote || '',
    bottomCote: instrumentEspecificData.specificInfos?.bottomCote || '',
    referenceDate:
      instrumentEspecificData.specificInfos?.referenceDate || new Date()
  });
  const [registerDate, setRegisterDate] = useState<Date>(new Date());
  const { t } = useTranslation();
  const { structureId } = useParams();
  const sectionList = useListSectionsByStructure(structureId || '');

  const sectionLongitudinalOptions = sectionList.data?.listSectionsByStructure
    .filter((e) => e.type === 'Longitudinal')
    .map((ele) => ({ id: ele.id, value: ele.id, label: ele.name }));

  const sectionCrossOptions = sectionList.data?.listSectionsByStructure
    .filter((e) => e.type === 'Cross')
    .map((ele) => ({ id: ele.id, value: ele.id, label: ele.name }));

  useEffect(() => {
    if (alertLevels?.id === 'Other') {
      setInstrumentEspecificData('alertLevels', controlLevels);
    }
    if (alertLevels?.id === 'ResolutionANM') {
      setInstrumentEspecificData('alertLevels', resolutionANM);
    }
  }, [controlLevels, alertLevels]);

  useEffect(() => {
    handleSpecificInfos('referenceDate', registerDate);
  }, [registerDate]);

  const handleSpecificInfos = (key: string, value: any) => {
    const newSpecificInfos = { ...specificInfos, [key]: value };
    setSpecificInfos(newSpecificInfos);
    setInstrumentEspecificData('specificInfos', newSpecificInfos);
  };

  const handleRemove = (index: number) => {
    setControlLevels((prev: CreatePiezometerAlertLevelInput[]) =>
      prev.filter((_, i) => i !== index)
    );
  };

  return (
    <RegisterInstrumentEspecificsInputs>
      {!isEditing &&
        InstrumentSelects.PiezometerType({
          width: 80,
          options: sectionCrossOptions,
          placeholder: t('TransversalSection'),
          setSelected: setCrossSection,
          selected: crossSection ? crossSection : undefined,
          updateValue: () => {}
        })}
      {!isEditing && <div></div>}
      <AddLevelControlComponent
        controlLevels={controlLevels}
        setControlLevels={setControlLevels}
        registerDate={registerDate}
        setRegisterDate={setRegisterDate}
        setInstrumentEspecificData={setInstrumentEspecificData}
        instrumentEspecificData={instrumentEspecificData}
        setAlertLevels={setAlertLevels}
      />
      {}
      {!isEditing &&
        InstrumentSelects.PiezometerType({
          width: 80,
          options: sectionLongitudinalOptions,
          placeholder: t('LongitudinalSection'),
          setSelected: setLongitudinalSection,
          selected: longitudinalSection ? longitudinalSection : undefined,
          updateValue: () => {}
        })}
      {!isEditing && <div></div>}
      {!isEditing && (
        <CustomInputArea>
          <DefaultInput
            type="number"
            placeholder={t('TopQuota')}
            onChange={(e) =>
              handleSpecificInfos('topCote', parseFloat(e.target.value))
            }
            value={specificInfos.topCote}
            error={!specificInfos.topCote && specificInfosError}
          />
          <DefaultInput
            type="number"
            placeholder={t('FundQuota')}
            onChange={(e) =>
              handleSpecificInfos('bottomCote', parseFloat(e.target.value))
            }
            value={specificInfos.bottomCote}
            error={!specificInfos.bottomCote && specificInfosError}
          />
        </CustomInputArea>
      )}
      {!isEditing && <div></div>}
      {InstrumentSelects.PiezometerType({
        width: 80,
        options: PiezometersModel().alertLevels,
        placeholder: t('ControlLevel'),
        setSelected: setAlertLevels,
        selected: alertLevels,
        updateValue: () => {}
      })}
      {!isEditing && <div></div>}
      <PiezometerControlLevelTable
        controlLevels={instrumentEspecificData.alertLevels}
        handleRemove={handleRemove}
        setInstrumentEspecificData={setInstrumentEspecificData}
        instrumentEspecificData={instrumentEspecificData}
        alertLevels={alertLevels}
      />
    </RegisterInstrumentEspecificsInputs>
  );
};
