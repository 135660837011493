import * as Types from '../../base-schema';

import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';
export type FindActionPlanMembersQueryVariables = Types.Exact<{
  actionPlanId: Types.Scalars['String']['input'];
}>;

export type FindActionPlanMembersQuery = {
  __typename?: 'Query';
  findActionPlanMembers: Array<{
    __typename?: 'ActionPlanTeamMembersDataType';
    id: string;
    name: string;
    email: string;
  }>;
};

export const FindActionPlanMembersDocument = gql`
  query FindActionPlanMembers($actionPlanId: String!) {
    findActionPlanMembers(actionPlanId: $actionPlanId) {
      id
      name
      email
    }
  }
`;

export type FindActionPlanMembersQueryResult = Apollo.QueryResult<
  FindActionPlanMembersQuery,
  FindActionPlanMembersQueryVariables
>;
