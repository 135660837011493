import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type DeleteRecordActivitiesVideoMutationVariables = Types.Exact<{
  deleteRecordActivitiesVideoId: Types.Scalars['String']['input'];
}>;


export type DeleteRecordActivitiesVideoMutation = { __typename?: 'Mutation', deleteRecordActivitiesVideo: string };


export const DeleteRecordActivitiesVideoDocument = gql`
    mutation DeleteRecordActivitiesVideo($deleteRecordActivitiesVideoId: String!) {
  deleteRecordActivitiesVideo(id: $deleteRecordActivitiesVideoId)
}
    `;
export type DeleteRecordActivitiesVideoMutationFn = Apollo.MutationFunction<DeleteRecordActivitiesVideoMutation, DeleteRecordActivitiesVideoMutationVariables>;
export type DeleteRecordActivitiesVideoMutationResult = Apollo.MutationResult<DeleteRecordActivitiesVideoMutation>;
export type DeleteRecordActivitiesVideoMutationOptions = Apollo.BaseMutationOptions<DeleteRecordActivitiesVideoMutation, DeleteRecordActivitiesVideoMutationVariables>;