import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ListStructuresQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ListStructuresQuery = { __typename?: 'Query', listStructures: Array<{ __typename?: 'ListStructuresType', structureId: string, name: string, companyName: string, status: boolean }> };


export const ListStructuresDocument = gql`
    query ListStructures {
  listStructures {
    structureId
    name
    companyName
    status
  }
}
    `;
export type ListStructuresQueryResult = Apollo.QueryResult<ListStructuresQuery, ListStructuresQueryVariables>;