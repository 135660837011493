import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ListAlertsLevelByPiezometersPagQueryVariables = Types.Exact<{
  pageInfo: Types.PageInput;
  instrumentId: Types.Scalars['String']['input'];
}>;

export type ListAlertsLevelByPiezometersPagQuery = {
  __typename?: 'Query';
  listAlertsLevelByPiezometersPag: {
    __typename?: 'PaginatedPiezometerInaAlertLevels';
    count: number;
    nodes: Array<{
      __typename?: 'ListPiezometerInaAlertLevelsType';
      id: string;
      name: string;
      drained?: string | null;
      undrained?: string | null;
      coteValue: number;
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      page: number;
      offset: number;
      limit: number;
      totalPages: number;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  };
};

export const ListAlertsLevelByPiezometersPagDocument = gql`
  query ListAlertsLevelByPiezometersPag(
    $pageInfo: PageInput!
    $instrumentId: String!
  ) {
    listAlertsLevelByPiezometersPag(
      pageInfo: $pageInfo
      instrumentId: $instrumentId
    ) {
      nodes {
        id
        name
        drained
        undrained
        coteValue
      }
      count
      pageInfo {
        page
        offset
        limit
        totalPages
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;
export type ListAlertsLevelByPiezometersPagQueryResult = Apollo.QueryResult<
  ListAlertsLevelByPiezometersPagQuery,
  ListAlertsLevelByPiezometersPagQueryVariables
>;
