import { enUS, ptBR } from 'date-fns/locale';
import i18next from 'i18next';
import { Dispatch, SetStateAction } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';

interface DatePickerProps {
  hasInterval?: boolean;
  startDate?: Date | null;
  endDate?: Date | null;
  setStartDate?: Dispatch<Date | null> | Dispatch<SetStateAction<Date>>;
  setEndDate?: Dispatch<Date | null> | Dispatch<SetStateAction<Date>>;
  dateText?: string;
  showTimeSelect?: boolean;
  onChange?: (e: any) => void;
}

export const DatePickerComponent = ({
  hasInterval,
  setStartDate,
  setEndDate,
  startDate,
  endDate,
  dateText,
  showTimeSelect = true,
  onChange
}: DatePickerProps) => {
  const { t } = useTranslation();
  const handleStartDateChange = (date: Date | null) => {
    if (setStartDate) setStartDate(date!);
    // Impede que a data de fim seja menor que a data de início
    if (date && endDate && date > endDate) {
      if (setEndDate) setEndDate(date);
    }
  };

  const handleEndDateChange = (date: Date | null) => {
    // Impede que a data de início seja maior que a data de fim
    if (startDate && date && startDate > date) {
      if (setStartDate) setStartDate(date);
    } else {
      if (setEndDate) setEndDate(date!);
    }
  };

  if (onChange) {
    return (
      <>
        <DatePicker
          selected={startDate}
          onChange={(e) => {
            onChange(e);
            handleStartDateChange(e);
          }}
          selectsStart
          startDate={startDate}
          endDate={hasInterval ? endDate : null}
          placeholderText={dateText || t('InitialDate')}
          className="custom-datepicker"
          locale={i18next.language.includes('pt') ? ptBR : enUS}
          dateFormat={
            showTimeSelect
              ? i18next.language.includes('pt')
                ? 'dd/MM/yyyy HH:mm'
                : 'MM/dd/yyyy HH:mm'
              : i18next.language.includes('pt')
                ? 'dd/MM/yyyy'
                : 'MM/dd/yyyy'
          }
          showTimeSelect={showTimeSelect}
          timeIntervals={1}
          showTimeSelectOnly={false}
          timeCaption="Time"
          timeFormat="HH:mm"
          showIcon
        />
        {hasInterval ? (
          <>
            <span className="datapicker-separator">-</span>
            <DatePicker
              selected={endDate}
              onChange={(e) => {
                onChange(e);
                handleEndDateChange(e);
              }}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              placeholderText={t('EndDate')}
              className="custom-datepicker"
              locale={i18next.language.includes('pt') ? ptBR : enUS}
              dateFormat={
                i18next.language.includes('pt')
                  ? `dd/MM/yyyy ${showTimeSelect ? 'HH:mm' : ''}`
                  : `MM/dd/yyyy ${showTimeSelect ? 'HH:mm' : ''}`
              }
              showTimeSelect={showTimeSelect}
              showTimeSelectOnly={false}
              timeIntervals={1}
              timeCaption="Time"
              timeFormat="HH:mm"
              showIcon
            />
          </>
        ) : null}
      </>
    );
  }

  return (
    <>
      <DatePicker
        selected={startDate}
        onChange={handleStartDateChange}
        selectsStart
        startDate={startDate}
        endDate={hasInterval ? endDate : null}
        placeholderText={dateText || t('InitialDate')}
        className="custom-datepicker"
        locale={i18next.language.includes('pt') ? ptBR : enUS}
        dateFormat={
          showTimeSelect
            ? i18next.language.includes('pt')
              ? 'dd/MM/yyyy HH:mm'
              : 'MM/dd/yyyy HH:mm'
            : i18next.language.includes('pt')
              ? 'dd/MM/yyyy'
              : 'MM/dd/yyyy'
        }
        showTimeSelect={showTimeSelect}
        timeIntervals={1}
        showTimeSelectOnly={false}
        timeCaption="Time"
        timeFormat="HH:mm"
        showIcon
      />
      {hasInterval ? (
        <>
          <span className="datapicker-separator">-</span>
          <DatePicker
            selected={endDate}
            onChange={handleEndDateChange}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            placeholderText={t('EndDate')}
            className="custom-datepicker"
            locale={i18next.language.includes('pt') ? ptBR : enUS}
            dateFormat={
              i18next.language.includes('pt')
                ? `dd/MM/yyyy ${showTimeSelect ? 'HH:mm' : ''}`
                : `MM/dd/yyyy ${showTimeSelect ? 'HH:mm' : ''}`
            }
            showTimeSelect={showTimeSelect}
            showTimeSelectOnly={false}
            timeIntervals={1}
            timeCaption="Time"
            timeFormat="HH:mm"
            showIcon
          />
        </>
      ) : null}
    </>
  );
};
