import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type UpdateRecordObservationMutationVariables = Types.Exact<{
  data: Types.UpdateRecordObservationInput;
}>;


export type UpdateRecordObservationMutation = { __typename?: 'Mutation', updateRecordObservation: string };


export const UpdateRecordObservationDocument = gql`
    mutation UpdateRecordObservation($data: UpdateRecordObservationInput!) {
  updateRecordObservation(data: $data)
}
    `;
export type UpdateRecordObservationMutationFn = Apollo.MutationFunction<UpdateRecordObservationMutation, UpdateRecordObservationMutationVariables>;
export type UpdateRecordObservationMutationResult = Apollo.MutationResult<UpdateRecordObservationMutation>;
export type UpdateRecordObservationMutationOptions = Apollo.BaseMutationOptions<UpdateRecordObservationMutation, UpdateRecordObservationMutationVariables>;