import Skeleton from 'react-loading-skeleton';
import { MainScreenTemplateProps } from './MainScreenTemplate.interface';
import {
  Container,
  Content,
  DivContent,
  Header,
  Sidebar
} from './MainScreenTemplateStyled';

const MainScreenTemplate = ({
  header,
  sidebar,
  content,
  loading
}: MainScreenTemplateProps) => {
  return loading ? (
    <Container>
      <Header>
        <Skeleton width="100%" height="30px" />
      </Header>
      <DivContent>
        <Sidebar>
          <Skeleton width="100%" height="100%" />
        </Sidebar>
        <Content>
          <Skeleton width="100%" height="100%" />
        </Content>
      </DivContent>
    </Container>
  ) : (
    <Container>
      <Header>{header}</Header>
      <DivContent>
        <Sidebar>{sidebar}</Sidebar>
        <Content>{content}</Content>
      </DivContent>
    </Container>
  );
};

export default MainScreenTemplate;
