import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type SaveGistmCommentsMutationVariables = Types.Exact<{
  comments: Array<Types.CreateGistmCommentsInput> | Types.CreateGistmCommentsInput;
  structureId: Types.Scalars['String']['input'];
}>;


export type SaveGistmCommentsMutation = { __typename?: 'Mutation', saveGistmComments: Array<{ __typename?: 'GistmCommentsType', comment: string, id: string }> };


export const SaveGistmCommentsDocument = gql`
    mutation SaveGistmComments($comments: [CreateGistmCommentsInput!]!, $structureId: String!) {
  saveGistmComments(comments: $comments, structureId: $structureId) {
    comment
    id
  }
}
    `;
export type SaveGistmCommentsMutationFn = Apollo.MutationFunction<SaveGistmCommentsMutation, SaveGistmCommentsMutationVariables>;
export type SaveGistmCommentsMutationResult = Apollo.MutationResult<SaveGistmCommentsMutation>;
export type SaveGistmCommentsMutationOptions = Apollo.BaseMutationOptions<SaveGistmCommentsMutation, SaveGistmCommentsMutationVariables>;