import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ListModulesByStructureIdQueryVariables = Types.Exact<{
  structureId: Types.Scalars['String']['input'];
}>;


export type ListModulesByStructureIdQuery = { __typename?: 'Query', listModulesByStructureId: Array<{ __typename?: 'ModuleType', id: string, name: string }> };


export const ListModulesByStructureIdDocument = gql`
    query ListModulesByStructureId($structureId: String!) {
  listModulesByStructureId(structureId: $structureId) {
    id
    name
  }
}
    `;
export type ListModulesByStructureIdQueryResult = Apollo.QueryResult<ListModulesByStructureIdQuery, ListModulesByStructureIdQueryVariables>;