import React from 'react';
import { useTranslation } from 'react-i18next';
import Text from '../../Atoms/Text/Text';
import InputCheckbox from '../../Molecules/InputCheckbox/InputCheckbox';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import Button from '../../Atoms/Button/Button';
import { z } from 'zod';
import {
  AcceptTermsContainer,
  DivTermsText,
  DivButton,
  DivCheckbox,
  DivModalContainer,
  DivHeader
} from './AcceptTerms.styles';
import { useMutation } from '@apollo/client';
import {
  MutationDocument,
  MutationMutation,
  MutationMutationVariables
} from '../../../../data/graphql/generated/accpetTerms.mutation';
import ErrorsTreatments from '../../../../utils/errorTreatment';
import { toastfyError } from '../../../Toastify';

const AcceptTerms = () => {
  const { t: translate } = useTranslation();

  const [acceptTerms] = useMutation<
    MutationMutation,
    MutationMutationVariables
  >(MutationDocument);

  const acceptTermsSchema = z.object({
    accept: z.boolean().refine((value) => value === true, {
      message: translate('fieldIsRequired')
    })
  });

  type AcceptTermsSchemaType = z.infer<typeof acceptTermsSchema>;

  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm<AcceptTermsSchemaType>({
    resolver: zodResolver(acceptTermsSchema)
  });

  const handleAccept = async () => {
    await acceptTerms({
      onCompleted: () => {
        const user = JSON.parse(localStorage.getItem('user') || '');
        if (user) {
          const updatedUser = {
            ...user,
            hasAcceptedTerms: true
          };
          localStorage.setItem('user', JSON.stringify(updatedUser));
        }

        location.reload();
      },
      onError: ({ graphQLErrors }) => {
        const errorMessage = ErrorsTreatments(
          graphQLErrors[0].message,
          translate
        );
        toastfyError(errorMessage);
      }
    });
  };

  return (
    <DivModalContainer>
      <AcceptTermsContainer>
        <DivHeader>
          <Text type="span" size="medium" weight="bold">
            {translate('termsOfUse')}
          </Text>
        </DivHeader>
        <DivTermsText>
          <Text type="p">{translate('termsOfUseText1')}</Text>
          <Text type="p">{translate('termsOfUseText2')}</Text>
          <Text type="p">{translate('termsOfUseText3')}</Text>
          <Text type="p">{translate('termsOfUseText4')}</Text>
          <Text type="p">{translate('termsOfUseText5')}</Text>
        </DivTermsText>
        <DivCheckbox>
          <InputCheckbox
            label={translate('readTermsOfUse')}
            name="accept"
            register={register}
            error={!!errors.accept}
            errorMessage={errors.accept?.message}
          />
        </DivCheckbox>
        <DivButton>
          <Button
            onClick={handleSubmit(handleAccept)}
            text={translate('Accept')}
            variant="primary"
          />
        </DivButton>
      </AcceptTermsContainer>
    </DivModalContainer>
  );
};

export default AcceptTerms;
