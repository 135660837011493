import styled from 'styled-components';
import { Color } from '../../../../Styles/Styles';

export const DivVolumeContainer = styled.div`
  color: ${Color.Text1};
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const TitleVolume = styled.span`
  padding: 15px 0;
  font-weight: bold;
`;

export const DivContainerVolumeList = styled.div`
  width: 100%;
`;

export const DivVolumeList = styled.div`
  display: grid;
  grid-template-columns: 90% 5%;
  align-items: center;
  gap: 25px;
  padding-bottom: 15px;

  svg {
    font-size: 20px;
    cursor: pointer;
    color: ${Color.Brown1} !important;
  }
`;

export const DivBodyModal = styled.div`
  height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DivContainerTableModalFiles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
`;
