import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type CreateUserPermissionMutationVariables = Types.Exact<{
  data: Types.CreateUserPermissionInput;
}>;


export type CreateUserPermissionMutation = { __typename?: 'Mutation', createUserPermission: string };


export const CreateUserPermissionDocument = gql`
    mutation CreateUserPermission($data: CreateUserPermissionInput!) {
  createUserPermission(data: $data)
}
    `;
export type CreateUserPermissionMutationFn = Apollo.MutationFunction<CreateUserPermissionMutation, CreateUserPermissionMutationVariables>;
export type CreateUserPermissionMutationResult = Apollo.MutationResult<CreateUserPermissionMutation>;
export type CreateUserPermissionMutationOptions = Apollo.BaseMutationOptions<CreateUserPermissionMutation, CreateUserPermissionMutationVariables>;