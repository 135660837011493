export const TABLE_IDS = {
  PIEZOMETER_LIST_TABLE: 'piezometerListTable',
  PLUVIOMETER_LIST_TABLE: 'pluviometerListTable',
  SURFACE_MARKER_LIST_TABLE: 'surfaceMarkerListTable',
  WATER_LEVEL_LIST_TABLE: 'waterLevelListTable',
  READINGS_PIEZOMETER_TABLE: 'readingsPiezometerTable',
  READINGS_PLUVIOMETER_TABLE: 'readingsPluviometerTable',
  READINGS_SURFACEMARKER_TABLE: 'readingsSurfaceMarkerTable',
  READINGS_WATERLEVEL_TABLE: 'readingsWaterLevelTable'
} as const;

export type TableId = (typeof TABLE_IDS)[keyof typeof TABLE_IDS];
