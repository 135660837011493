import styled from 'styled-components';
import { Color, FontSize } from '../../../../Styles/Styles';

export const IdentifiesContainer = styled.div`
  padding: 25px;

  span {
    color: ${Color.Brown1};
  }
`;

export const IdentifiesHeader = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 25px;
  padding-bottom: 15px;
  span {
    font-weight: bold;
  }
`;

export const TextButtonAdd = styled.button`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: ${Color.Brown1};
  font-size: ${FontSize.Small};
`;
