import * as Types from '../base-schema';

import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';
export type ActionPlanReprovalFlowMutationVariables = Types.Exact<{
  comment: Types.Scalars['String']['input'];
  actionPlanId: Types.Scalars['String']['input'];
}>;

export type ActionPlanReprovalFlowMutation = {
  __typename?: 'Mutation';
  actionPlanReprovalFlow: string;
};

export const ActionPlanReprovalFlowDocument = gql`
  mutation ActionPlanReprovalFlow($comment: String!, $actionPlanId: String!) {
    actionPlanReprovalFlow(comment: $comment, actionPlanId: $actionPlanId)
  }
`;

export type ActionPlanReprovalFlowMutationFn = Apollo.MutationFunction<
  ActionPlanReprovalFlowMutation,
  ActionPlanReprovalFlowMutationVariables
>;

export type ActionPlanReprovalFlowMutationResult =
  Apollo.MutationResult<ActionPlanReprovalFlowMutation>;

export type ActionPlanReprovalFlowMutationOptions = Apollo.BaseMutationOptions<
  ActionPlanReprovalFlowMutation,
  ActionPlanReprovalFlowMutationVariables
>;
