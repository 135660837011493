import { useTranslation } from 'react-i18next';
import { SurfaceMarkerReferenceFormProps } from './SurfaceMarkerReferenceForm.interface';
import { useParams } from 'react-router-dom';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@apollo/client';
import {
  EditSurfaceMarkerReferenceMutation,
  EditSurfaceMarkerReferenceMutationVariables,
  EditSurfaceMarkerReferenceDocument
} from '../../../../data/graphql/generated/editSurfaceMarkerReference';
import { toastfyError, toastfySuccess } from '../../../Toastify';
import ErrorsTreatments from '../../../../utils/errorTreatment';
import { ListReferencesBySurfaceMarkerPagDocument } from '../../../../data/graphql/query/generated/listSurfaceMarkerReferences';
import {
  Form,
  FormContent,
  FormFooter
} from './SurfaceMarkerReferenceForm.styles';
import DatepickerInput from '../../Molecules/DatepickerInput/DatepickerInput';
import InputText from '../../Molecules/InputText/InputText';
import Button from '../../Atoms/Button/Button';
import { ListReferencesDataProps } from '../../Pages/EditSurfaceMarker/EditSurfaceMarker.interface';

export const SurfaceMarkerReferenceForm = ({
  setShowModal,
  reference
}: SurfaceMarkerReferenceFormProps<ListReferencesDataProps>) => {
  const { t: translate } = useTranslation();
  const { structureId, instrumentId } = useParams();
  const alertLevelSchema = z.object({
    elevation: z.string().min(1, translate('fillRequiredFields')),
    coordinateE: z.string().min(1, translate('fillRequiredFields')),
    coordinateN: z.string().min(1, translate('fillRequiredFields')),
    date: z.date().nullable()
  });

  type formAlertLevelSchemaType = z.infer<typeof alertLevelSchema>;
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<formAlertLevelSchemaType>({
    resolver: zodResolver(alertLevelSchema),
    defaultValues: {
      elevation: reference?.elevation.toString(),
      coordinateE: reference?.coordinateE.toString(),
      coordinateN: reference?.coordinateN.toString(),
      date: reference?.date ? new Date(reference.date) : null
    }
  });
  const [editSurfaceMarkerReferences] = useMutation<
    EditSurfaceMarkerReferenceMutation,
    EditSurfaceMarkerReferenceMutationVariables
  >(EditSurfaceMarkerReferenceDocument, {});

  const handleEditReference = async (data: formAlertLevelSchemaType) => {
    const referenceData = {
      instrumentId: instrumentId!,
      referenceId: reference!.id,
      elevation: parseInt(data.elevation),
      coordinateE: parseInt(data.coordinateE),
      coordinateN: parseInt(data.coordinateN),
      date: new Date(data.date ?? new Date())
    };
    await editSurfaceMarkerReferences({
      variables: {
        structureInfo: {
          structureId: structureId!
        },
        data: referenceData
      },
      awaitRefetchQueries: true,
      refetchQueries: [ListReferencesBySurfaceMarkerPagDocument],
      onCompleted: () => {
        setShowModal(false);
        toastfySuccess(translate('referenceUpdate'));
      },
      onError: ({ graphQLErrors }) => {
        const errorMessage = ErrorsTreatments(
          graphQLErrors[0].message,
          translate
        );
        toastfyError(errorMessage);
      }
    });
  };

  return (
    <Form>
      <FormContent>
        <InputText
          width="315px"
          control={control}
          label={translate('elevation')}
          name={'elevation'}
          type={'number'}
          error={!!errors.elevation}
          errorMessage={errors?.elevation?.message}
        />
        <InputText
          width="315px"
          control={control}
          label={translate('coordinateE')}
          name={'coordinateE'}
          type={'number'}
          error={!!errors.coordinateE}
          errorMessage={errors?.coordinateE?.message}
        />
        <InputText
          width="315px"
          control={control}
          label={translate('coordinateN')}
          name={'coordinateN'}
          type={'number'}
          error={!!errors.coordinateN}
          errorMessage={errors?.coordinateN?.message}
        />
        <DatepickerInput
          control={control}
          label={translate('date')}
          name="date"
          placeholder={translate('date')}
          time={false}
          error={!!errors.date}
          errorMessage={errors?.date?.message}
        />
      </FormContent>
      <FormFooter>
        <Button
          size="medium"
          text={`${translate('editReference')}`}
          onClick={handleSubmit(handleEditReference)}
          variant={'primary'}
        />
      </FormFooter>
    </Form>
  );
};

export default SurfaceMarkerReferenceForm;
