import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type CreateStructureHydrologyMutationVariables = Types.Exact<{
  data: Types.CreateStructureHydrologyInput;
  structureId: Types.Scalars['String']['input'];
}>;


export type CreateStructureHydrologyMutation = { __typename?: 'Mutation', createStructureHydrology: string };


export const CreateStructureHydrologyDocument = gql`
    mutation CreateStructureHydrology($data: CreateStructureHydrologyInput!, $structureId: String!) {
  createStructureHydrology(data: $data, structureId: $structureId)
}
    `;
export type CreateStructureHydrologyMutationFn = Apollo.MutationFunction<CreateStructureHydrologyMutation, CreateStructureHydrologyMutationVariables>;
export type CreateStructureHydrologyMutationResult = Apollo.MutationResult<CreateStructureHydrologyMutation>;
export type CreateStructureHydrologyMutationOptions = Apollo.BaseMutationOptions<CreateStructureHydrologyMutation, CreateStructureHydrologyMutationVariables>;