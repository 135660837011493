import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ListUsersQueryVariables = Types.Exact<{
  data: Types.ListUsersInput;
}>;


export type ListUsersQuery = { __typename?: 'Query', listUsers: Array<{ __typename?: 'AdminUserType', id: string, status: boolean, name: string, email: string, phoneNumber: string, position: string, company: { __typename?: 'AdminBaseCompanyType', id: string, name: string, cnpj: string }, role: { __typename?: 'AdminUserRoleType', role: Types.UserRole } }> };


export const ListUsersDocument = gql`
    query ListUsers($data: ListUsersInput!) {
  listUsers(data: $data) {
    id
    status
    name
    email
    phoneNumber
    position
    company {
      id
      name
      cnpj
    }
    role {
      role
    }
  }
}
    `;
export type ListUsersQueryResult = Apollo.QueryResult<ListUsersQuery, ListUsersQueryVariables>;