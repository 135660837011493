import { useQuery } from '@apollo/client';
import { StructureInfoInput } from '../graphql/base-schema';
import {
  GetModuleByStructureDocument,
  GetModuleByStructureQuery,
  GetModuleByStructureQueryVariables
} from '../graphql/query/generated/modulesByStructure.query';

export function useModuleList(
  userId: string,
  structureInfo: StructureInfoInput,
  onCompleted?: () => void,
  onError?: () => void
) {
  const { data, loading, error } = useQuery<
    GetModuleByStructureQuery,
    GetModuleByStructureQueryVariables
  >(GetModuleByStructureDocument, {
    fetchPolicy: 'no-cache',
    variables: {
      userId,
      structureInfo
    },
    skip: !structureInfo.structureId,
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    data,
    loading,
    error
  };
}
