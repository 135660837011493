import { styled } from 'styled-components';

interface PictureGalleryProps {
  imagesGrid?: number;
}

export const DivContainerPictures = styled.div<PictureGalleryProps>`
  display: grid;
  grid-template-columns: ${(props) =>
    props.imagesGrid
      ? `repeat(${props.imagesGrid}, ${100 / props.imagesGrid}%)`
      : 'repeat(3, 33%)'};
  gap: 5px;
  width: 100%;
`;

export const ImgsContainer = styled.div`
  max-width: 300px;
  cursor: pointer;
`;

export const HolderPicturesNotFound = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
