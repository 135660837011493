import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type DeleteInstrumentMutationVariables = Types.Exact<{
  structureId: Types.Scalars['String']['input'];
  instrumentId: Types.Scalars['String']['input'];
}>;


export type DeleteInstrumentMutation = { __typename?: 'Mutation', deleteInstrument: string };


export const DeleteInstrumentDocument = gql`
    mutation DeleteInstrument($structureId: String!, $instrumentId: String!) {
  deleteInstrument(structureId: $structureId, instrumentId: $instrumentId)
}
    `;
export type DeleteInstrumentMutationFn = Apollo.MutationFunction<DeleteInstrumentMutation, DeleteInstrumentMutationVariables>;
export type DeleteInstrumentMutationResult = Apollo.MutationResult<DeleteInstrumentMutation>;
export type DeleteInstrumentMutationOptions = Apollo.BaseMutationOptions<DeleteInstrumentMutation, DeleteInstrumentMutationVariables>;