import React from 'react';
import { Color } from '../../../Styles/Styles';
interface SurfaceMarkerProps {
  size?: number;
  fillColor?: string;
  strokeColor?: string;
}
const SurfaceMarkerIcon = ({
  size = 267,
  fillColor = Color.Brown1,
  strokeColor = Color.Brown1
}: SurfaceMarkerProps) => {
  return (
    <svg
      width={size}
      height={(size * 93) / 63}
      viewBox="0 0 63 93"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="16"
        y="2"
        width="30"
        height="15"
        stroke={strokeColor}
        strokeWidth="4"
      />
      <rect
        x="46"
        y="6"
        width="7"
        height="7"
        stroke={strokeColor}
        strokeWidth="4"
      />
      <rect
        x="24"
        y="24"
        width="14"
        height="7"
        stroke={strokeColor}
        strokeWidth="4"
      />
      <rect x="20" y="4" width="4" height="7" fill={fillColor} />
      <rect x="29" y="18" width="4" height="7" fill={fillColor} />
      <rect x="29" y="33" width="4" height="60" fill={fillColor} />
      <rect
        x="29"
        y="34"
        width="4"
        height="60"
        transform="rotate(-30 29 34)"
        fill={fillColor}
      />
      <rect
        x="30"
        y="32"
        width="4"
        height="60"
        transform="rotate(30 30 32)"
        fill={fillColor}
      />
    </svg>
  );
};

export default SurfaceMarkerIcon;
