import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type SaveInstrumentDocsMutationVariables = Types.Exact<{
  data: Types.SaveInstrumentDocsInput;
}>;

export type SaveInstrumentDocsMutation = {
  __typename?: 'Mutation';
  saveInstrumentDocs: Array<{
    __typename?: 'InstrumentDocsType';
    id: string;
    fileName: string;
    url: string;
  }>;
};

export const SaveInstrumentDocsDocument = gql`
  mutation SaveInstrumentDocs($data: SaveInstrumentDocsInput!) {
    saveInstrumentDocs(data: $data) {
      id
      fileName
      url
    }
  }
`;

export type SaveInstrumentDocsMutationFn = Apollo.MutationFunction<
  SaveInstrumentDocsMutation,
  SaveInstrumentDocsMutationVariables
>;

export type SaveInstrumentDocsMutationResult =
  Apollo.MutationResult<SaveInstrumentDocsMutation>;

export type SaveInstrumentDocsMutationOptions = Apollo.BaseMutationOptions<
  SaveInstrumentDocsMutation,
  SaveInstrumentDocsMutationVariables
>;
