import { useMutation } from '@apollo/client';
import {
  SaveGeneralCommentsDocument,
  SaveGeneralCommentsMutationVariables,
  SaveGeneralCommentsMutation
} from '../graphql/generated/saveGeneralComments.mutation';

export function useGeneralComments(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [saveGeneralComments, { data, error, loading }] = useMutation<
    SaveGeneralCommentsMutationVariables,
    SaveGeneralCommentsMutation
  >(SaveGeneralCommentsDocument, {
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    saveGeneralComments,
    data,
    error,
    loading
  };
}
