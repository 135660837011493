import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type UpdateAtoMutationVariables = Types.Exact<{
  data: Types.UpdateAtoInput;
}>;


export type UpdateAtoMutation = { __typename?: 'Mutation', updateAto: string };


export const UpdateAtoDocument = gql`
    mutation UpdateAto($data: UpdateAtoInput!) {
  updateAto(data: $data)
}
    `;
export type UpdateAtoMutationFn = Apollo.MutationFunction<UpdateAtoMutation, UpdateAtoMutationVariables>;
export type UpdateAtoMutationResult = Apollo.MutationResult<UpdateAtoMutation>;
export type UpdateAtoMutationOptions = Apollo.BaseMutationOptions<UpdateAtoMutation, UpdateAtoMutationVariables>;