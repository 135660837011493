import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type CreatePluviometerReadingMutationVariables = Types.Exact<{
  structureInfo: Types.StructureInfoInput;
  data: Array<Types.PluviometerReadingInput> | Types.PluviometerReadingInput;
}>;


export type CreatePluviometerReadingMutation = { __typename?: 'Mutation', createPluviometerReading: Array<{ __typename?: 'ValidateReadingValuesInfosModelType', line?: number | null, instrument?: string | null, error?: string | null }> };


export const CreatePluviometerReadingDocument = gql`
    mutation CreatePluviometerReading($structureInfo: StructureInfoInput!, $data: [PluviometerReadingInput!]!) {
  createPluviometerReading(structureInfo: $structureInfo, data: $data) {
    line
    instrument
    error
  }
}
    `;
export type CreatePluviometerReadingMutationFn = Apollo.MutationFunction<CreatePluviometerReadingMutation, CreatePluviometerReadingMutationVariables>;
export type CreatePluviometerReadingMutationResult = Apollo.MutationResult<CreatePluviometerReadingMutation>;
export type CreatePluviometerReadingMutationOptions = Apollo.BaseMutationOptions<CreatePluviometerReadingMutation, CreatePluviometerReadingMutationVariables>;