import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type FindAllAssociatedActionPlansQueryVariables = Types.Exact<{
  actionPlanId: Types.Scalars['String']['input'];
}>;


export type FindAllAssociatedActionPlansQuery = { __typename?: 'Query', findAllAssociatedActionPlans: Array<{ __typename?: 'ListAllAssociatedActionPlansType', id: string, code: string, images: Array<string>, description: string, createdAt: Date }> };


export const FindAllAssociatedActionPlansDocument = gql`
    query FindAllAssociatedActionPlans($actionPlanId: String!) {
  findAllAssociatedActionPlans(actionPlanId: $actionPlanId) {
    id
    code
    images
    description
    createdAt
  }
}
    `;
export type FindAllAssociatedActionPlansQueryResult = Apollo.QueryResult<FindAllAssociatedActionPlansQuery, FindAllAssociatedActionPlansQueryVariables>;