import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { z, ZodIssueCode } from 'zod';
import {
  InstrumentReadingTypeEnum,
  InstrumentStatusEnum,
  InstrumentTypeEnum
} from '../../../../data/graphql/base-schema';
import Button from '../../Atoms/Button/Button';
import InputSelectSearch from '../../Molecules/InputSelectSearch/InputSelectSearch';
import { OptionType } from '../../Molecules/InputSelectSearch/InputSelectSearch.interfaces';
import { ExportPiezometerInaProps } from './ExportTableReadingPiezometerIna.interface';
import DatepickerInput from '../../Molecules/DatepickerInput/DatepickerInput';
import {
  DivBody,
  DivInputs,
  HolderCheckboxLis,
  HolderExports,
  HolderFooter,
  HolderInputsForm
} from './ExportTableReadingPiezometerIna.styles';
import { HolderCheckbox } from '../ExportTable/ExportTableStyles';
import InputCheckbox from '../../Molecules/InputCheckbox/InputCheckbox';
import Text from '../../Atoms/Text/Text';
import InputText from '../../Molecules/InputText/InputText';
import { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import {
  ExportPiezometerInaReadingsByStructureDocument,
  ExportPiezometerInaReadingsByStructureQuery,
  ExportPiezometerInaReadingsByStructureQueryVariables
} from '../../../../data/graphql/query/generated/exportPiezometerInaReadingsByStructure.query';
import Enums from '../../../../utils/enumns';
import { toastfyError, toastfySuccess } from '../../../Toastify';

const FilterFormType = z
  .object({
    name: z.string().nullable().optional(),
    type: z
      .object({
        value: z.string(),
        label: z.string()
      })
      .nullable()
      .optional(),
    status: z
      .object({
        value: z.string(),
        label: z.string()
      })
      .nullable()
      .optional(),
    startDate: z.date().nullable().optional(),
    endDate: z.date().nullable().optional()
  })
  .superRefine((data, ctx) => {
    const { startDate, endDate } = data;

    if ((!startDate && endDate) || (!endDate && startDate)) {
      ctx.addIssue({
        code: ZodIssueCode.custom,
        path: ['startDate', 'endDate'],
        message: 'NeedStartDateAndEndDate'
      });
    }

    if (startDate && endDate && startDate > endDate) {
      ctx.addIssue({
        code: ZodIssueCode.custom,
        path: ['startDate', 'endDate'],
        message: 'validationDate'
      });
    }
  });

type FilterFormValues = z.infer<typeof FilterFormType>;

const ExportTableReadingPiezometerIna = ({
  columns
}: ExportPiezometerInaProps) => {
  const { structureId, instrumentType } = useParams();
  const [searchFilterParams, setSearchFilterParams] = useSearchParams();
  const { t: translate } = useTranslation();
  const { instrumentsEnum } = Enums();

  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    columns.map((col) => col.key)
  );

  const status: OptionType[] = [
    { label: translate('active'), value: InstrumentStatusEnum.Active },
    { label: translate('inactive'), value: InstrumentStatusEnum.Inactive }
  ];

  const type: OptionType[] = [
    {
      label: translate('Automatic'),
      value: InstrumentReadingTypeEnum.Automatic
    },
    { label: translate('Manual'), value: InstrumentReadingTypeEnum.Manual }
  ];

  const [exportTableData] = useLazyQuery<
    ExportPiezometerInaReadingsByStructureQuery,
    ExportPiezometerInaReadingsByStructureQueryVariables
  >(ExportPiezometerInaReadingsByStructureDocument);

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<FilterFormValues>({
    resolver: zodResolver(FilterFormType),
    defaultValues: {
      name: searchFilterParams.get('name')
        ? searchFilterParams.get('name')
        : undefined,
      type: searchFilterParams.get('type')
        ? {
            value: searchFilterParams.get('type')!,
            label: searchFilterParams.get('type')!
          }
        : undefined,
      status: searchFilterParams.get('status')
        ? {
            value: searchFilterParams.get('status')!,
            label: searchFilterParams.get('status')!
          }
        : undefined,
      startDate: searchFilterParams.get('startDate')
        ? new Date(searchFilterParams.get('startDate')!)
        : undefined,
      endDate: searchFilterParams.get('endDate')
        ? new Date(searchFilterParams.get('endDate')!)
        : undefined
    }
  });

  const handleFilterSubmit = (data: FilterFormValues) => {
    exportTableData({
      variables: {
        instrumentType: instrumentsEnum[
          instrumentType as keyof typeof instrumentsEnum
        ] as unknown as InstrumentTypeEnum,
        selectedFields: selectedColumns,
        structureId: structureId!,
        filters: {
          name: data.name,
          isAutomatic:
            data.type?.value === InstrumentReadingTypeEnum.Automatic
              ? true
              : false,
          operationalStatus: data.status?.value,
          date:
            data.startDate && data.endDate
              ? [data.startDate, data.endDate]
              : null
        }
      },
      onCompleted: () => {
        toastfySuccess(translate('ExportTableEmail'));
        const params = new URLSearchParams(searchFilterParams);
        params.delete('exporttable');
        setSearchFilterParams(params);
      },
      onError: () => {
        toastfyError(translate('error'));
      }
    });
  };

  const handleCheckboxChange = (checked: boolean, key: string) => {
    setSelectedColumns((prevSelected) =>
      checked
        ? [...prevSelected, key]
        : prevSelected.filter((colKey) => colKey !== key)
    );
  };

  return (
    <HolderExports>
      <DivBody>
        <Text type="label" color="black">
          {translate('Filters')}
        </Text>
        <HolderInputsForm>
          <DivInputs>
            <InputText
              type="text"
              label={translate('name')}
              name="name"
              width="300px"
              control={control}
              error={!!errors.name}
              errorMessage={errors.name?.message}
            />
          </DivInputs>
          <DivInputs>
            <InputSelectSearch
              errorMessage={errors.type?.message}
              label={translate('typeOfReading')}
              name="type"
              options={type}
              width="300px"
              control={control}
              error={!!errors.type}
              placeholder={translate('typeOfReading')}
            />
          </DivInputs>
          <DivInputs>
            <InputSelectSearch
              errorMessage={errors.status?.message}
              label={translate('Status')}
              name="status"
              options={status}
              width="300px"
              control={control}
              error={!!errors.status}
              placeholder={translate('Status')}
            />
          </DivInputs>
          <DivInputs>
            <DatepickerInput
              placeholder={translate('startDate')}
              label={translate('startDate')}
              name="startDate"
              control={control}
              error={!!errors.startDate}
              errorMessage={errors.startDate?.message}
              time={false}
            />
          </DivInputs>
          <DivInputs>
            <DatepickerInput
              placeholder={translate('EndDate')}
              label={translate('EndDate')}
              name="endDate"
              control={control}
              error={!!errors.endDate}
              errorMessage={errors.endDate?.message}
              time={false}
            />
          </DivInputs>
        </HolderInputsForm>
        <Text type="label" color="black">
          {translate('Fields')}
        </Text>
        <HolderCheckboxLis>
          {columns.map(({ key, label }) => (
            <HolderCheckbox key={key}>
              <InputCheckbox
                label={label}
                name={key}
                value={selectedColumns.includes(key)}
                onChange={(newValue) => handleCheckboxChange(newValue, key)}
              />
            </HolderCheckbox>
          ))}
        </HolderCheckboxLis>
      </DivBody>
      <HolderFooter>
        <Button
          variant="primary"
          size="medium"
          text={translate('ExportTable')}
          onClick={handleSubmit(handleFilterSubmit)}
        />
      </HolderFooter>
    </HolderExports>
  );
};

export default ExportTableReadingPiezometerIna;
