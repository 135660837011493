import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ReactivateAtoMutationVariables = Types.Exact<{
  reactivateAtoId: Types.Scalars['String']['input'];
}>;


export type ReactivateAtoMutation = { __typename?: 'Mutation', reactivateAto: string };


export const ReactivateAtoDocument = gql`
    mutation ReactivateAto($reactivateAtoId: String!) {
  reactivateAto(id: $reactivateAtoId)
}
    `;
export type ReactivateAtoMutationFn = Apollo.MutationFunction<ReactivateAtoMutation, ReactivateAtoMutationVariables>;
export type ReactivateAtoMutationResult = Apollo.MutationResult<ReactivateAtoMutation>;
export type ReactivateAtoMutationOptions = Apollo.BaseMutationOptions<ReactivateAtoMutation, ReactivateAtoMutationVariables>;