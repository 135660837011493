import styled from 'styled-components';

export const DivContainerComment = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
`;

export const DivContainerSeeComments = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 270px;
  overflow-y: auto;
`;

export const DivContainerAllComment = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10%;
  width: 100%;
`;

export const HolderNoComments = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 100%;
`;

export const DivImagesComments = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30px, 1fr));
  grid-gap: 10px;
  width: 100%;
  box-sizing: border-box;
`;

export const DivComment = styled.div`
  box-sizing: border-box;
  padding: 10px;
  border-bottom: solid 1px rgba(150, 116, 66, 0.5);
  width: 100%;
`;
