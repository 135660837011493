import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  padding-left: 10px;
  padding-right: 10px;
  max-height: 200px;
  min-height: 100px;
  overflow: auto;
`;

export const FileListUl = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  list-style: none;
  padding: 0;
`;

export const FileItem = styled.li`
  display: flex;
  align-items: center;
  padding: 5px;
  gap: 10px;
`;

export const DivName = styled.div`
  cursor: pointer;
  color: #000000;
`;
