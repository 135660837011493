export interface AtoData {
  construction: string;
  local: string;
  responsible: string;
  initialDate: string;
  finalDate: string;
}

export const __initialAtoData = {
  construction: '',
  local: '',
  responsible: '',
  initialDate: '',
  finalDate: ''
};
