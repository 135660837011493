import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type CreateStructureModulesMutationVariables = Types.Exact<{
  data: Types.CreateStructureModulesInput;
}>;


export type CreateStructureModulesMutation = { __typename?: 'Mutation', createStructureModules: string };


export const CreateStructureModulesDocument = gql`
    mutation CreateStructureModules($data: CreateStructureModulesInput!) {
  createStructureModules(data: $data)
}
    `;
export type CreateStructureModulesMutationFn = Apollo.MutationFunction<CreateStructureModulesMutation, CreateStructureModulesMutationVariables>;
export type CreateStructureModulesMutationResult = Apollo.MutationResult<CreateStructureModulesMutation>;
export type CreateStructureModulesMutationOptions = Apollo.BaseMutationOptions<CreateStructureModulesMutation, CreateStructureModulesMutationVariables>;