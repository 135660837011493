import React from 'react';
import Image from '../../Atoms/Image/Image';
import Text from '../../Atoms/Text/Text';
import InfoRow from '../../Molecules/InfoRow/InfoRow';
import {
  CardContainer,
  Content,
  Header,
  HolderImageHeaderLeft,
  HolderImageHeaderRight,
  HolderTitleHeader
} from './ReportCardStyles';
import { MdImageNotSupported } from 'react-icons/md';
import { Color } from '../../../../Styles/Styles';
import { t } from 'i18next';
type ReportCardProps = {
  logoLeft: string;
  logoRight: string;
  title: string;
  contractNumber: string;
  contractualTerm: string;
  elapsedDuration: string;
  remainingDuration: string;
  responsiblePerson: string;
  company: string;
  rdoNumber: string;
  date: string;
};

const ReportCard: React.FC<ReportCardProps> = ({
  logoLeft,
  logoRight,
  title,
  contractNumber,
  contractualTerm,
  elapsedDuration,
  remainingDuration,
  responsiblePerson,
  company,
  rdoNumber,
  date
}) => {
  return (
    <CardContainer>
      <Header>
        <HolderImageHeaderLeft>
          {logoLeft !== '' ? (
            <Image src={logoLeft} alt="Logo Left" width="190" height="46" />
          ) : (
            <MdImageNotSupported size={25} color={`${Color.Brown1}`} />
          )}
        </HolderImageHeaderLeft>
        <HolderTitleHeader>
          <Text color="black" type="h3">
            {t('rdoTitle')}
          </Text>
        </HolderTitleHeader>

        <HolderImageHeaderRight>
          {logoRight !== '' ? (
            <Image src={logoRight} alt="Logo Right" width="190" height="46" />
          ) : (
            <MdImageNotSupported size={25} color={`${Color.Brown1}`} />
          )}
        </HolderImageHeaderRight>
      </Header>
      <Content>
        <InfoRow grid="1 / 1 / 2 / 3" label="" value={title} />
        <InfoRow
          grid="1 / 3 / 2 / 4"
          label={`${t('contract')}:`}
          value={contractNumber}
        />
        <InfoRow grid="2 / 1 / 3 / 2" label="RDO Nº:" value={rdoNumber} />
        <InfoRow grid="2 / 2 / 3 / 3" label="Data:" value={date} />
        <InfoRow
          grid="2 / 3 / 3 / 4"
          label={`${t('contractualPeriod')}:`}
          value={`${contractualTerm} dias`}
        />
        <InfoRow
          grid="3 / 1 / 4 / 3"
          label={`${t('contractor')}:`}
          value={company}
        />
        <InfoRow
          grid="3 / 3 / 4 / 4"
          label={`${t('timeElapsed')}:`}
          value={elapsedDuration}
        />
        <InfoRow
          grid="4 / 1 / 5 / 3"
          label={`${t('Responsible')}:`}
          value={responsiblePerson}
        />
        <InfoRow
          grid="4 / 3 / 5 / 4"
          label={`${t('dueDate')}:`}
          value={remainingDuration}
        />
      </Content>
    </CardContainer>
  );
};

export default ReportCard;
