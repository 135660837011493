import styled from 'styled-components';

import { Color } from '../../../../Styles/Styles';

export const DivSkeletonOptionsTable = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
`;

export const TableContent = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

export const DivContainerActions = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  margin-bottom: 10px;
`;

export const TableHeaderCellTh = styled.th`
  border-bottom: 1px solid ${Color.Brown3};
`;

export const DivContentFooter = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;

  button {
    margin-top: 10px;
  }
`;

export const DivMessageNoData = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 25px;
  color: ${Color.Gray1};
  box-sizing: border-box;
`;

export const DivTableComponents = styled.div`
  width: 100%;
`;

export const DivTable = styled.div`
  overflow: auto;
  width: 100%;
  max-height: 36.25rem;
  min-height: 18.75rem;
  background-color: #f5f5f5;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
`;

export const DivTableHeader = styled.div`
  width: 15px;
`;
