import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoCloseOutline } from 'react-icons/io5';
import { v4 as uuidv4 } from 'uuid';
import { DefaultButton } from '../../../Components/Buttons/DefaultButton';
import { InputFile } from '../../../Components/Forms/FormInspectionsStyle';
import { TableDocs } from '../../../Screens/AdminScreen/AdminScreenStyle';
import { Color } from '../../../Styles/Styles';
import {
  __initialPermissionsActive,
  __initialPermissionsData
} from '../Permissions/PermissionsInterface';
import { __initialAtoData } from './AtoInterface';

export function ATOFile() {
  const { t } = useTranslation();
  const [savedEvent, setSavedEvent] = useState<ChangeEvent<HTMLInputElement>>();
  const [image, setImage] = useState('');
  const [files, setFiles] = useState<{ url: string }[]>([]);

  const handleClose = (key: number) => {
    const aux = files.filter((e, index) => index !== key);
    setFiles(aux);
  };

  return (
    <div>
      <span
        style={{
          display: 'flex',
          color: Color.Brown1,
          width: '100%',
          justifyContent: 'center',
          padding: '15px 0',
          fontWeight: '800'
        }}
      >
        {t('referenceDocs')}
      </span>
      <InputFile>
        <input
          value={image}
          type="file"
          name="image"
          id="File_crack"
          accept=".pdf"
          onChange={(e) => {
            const arrayFiles = e?.target?.files?.[0];
            if (arrayFiles) {
              setImage(arrayFiles.name);
              setSavedEvent(e);
              setFiles((prev) => [
                ...prev,
                { url: URL.createObjectURL(arrayFiles) }
              ]);
            }
            setImage('');
          }}
        />
      </InputFile>
      <TableDocs>
        <tbody>
          {files.map((file, key) => {
            return (
              <tr key={key}>
                <td>
                  <a target="_blank" href={file.url} rel="noreferrer">
                    {t('doc') + key.toString() + '.pdf'}
                  </a>
                </td>
                <td>
                  {' '}
                  <IoCloseOutline
                    style={{ color: 'red' }}
                    onClick={() => handleClose(key)}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </TableDocs>
    </div>
  );
}
