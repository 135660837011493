import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type DeleteParticipantMutationVariables = Types.Exact<{
  participantId: Types.Scalars['String']['input'];
}>;


export type DeleteParticipantMutation = { __typename?: 'Mutation', deleteParticipant: string };


export const DeleteParticipantDocument = gql`
    mutation DeleteParticipant($participantId: String!) {
  deleteParticipant(participantId: $participantId)
}
    `;
export type DeleteParticipantMutationFn = Apollo.MutationFunction<DeleteParticipantMutation, DeleteParticipantMutationVariables>;
export type DeleteParticipantMutationResult = Apollo.MutationResult<DeleteParticipantMutation>;
export type DeleteParticipantMutationOptions = Apollo.BaseMutationOptions<DeleteParticipantMutation, DeleteParticipantMutationVariables>;