import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type DeleteRecordVideoMutationVariables = Types.Exact<{
  deleteRecordVideoId: Types.Scalars['String']['input'];
}>;


export type DeleteRecordVideoMutation = { __typename?: 'Mutation', deleteRecordVideo: string };


export const DeleteRecordVideoDocument = gql`
    mutation DeleteRecordVideo($deleteRecordVideoId: String!) {
  deleteRecordVideo(id: $deleteRecordVideoId)
}
    `;
export type DeleteRecordVideoMutationFn = Apollo.MutationFunction<DeleteRecordVideoMutation, DeleteRecordVideoMutationVariables>;
export type DeleteRecordVideoMutationResult = Apollo.MutationResult<DeleteRecordVideoMutation>;
export type DeleteRecordVideoMutationOptions = Apollo.BaseMutationOptions<DeleteRecordVideoMutation, DeleteRecordVideoMutationVariables>;