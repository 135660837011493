import { useMutation } from '@apollo/client';
import {
  UpdateConservationStateDocument,
  UpdateConservationStateMutation,
  UpdateConservationStateMutationVariables
} from '../graphql/generated/updateConservationState';
import { FindAllInspectionsByStructureDocument } from '../graphql/query/generated/findAllInspectionsByStructure.query';

export function useUpdateConservationState(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [updateConservationState, { data, loading, error }] = useMutation<
    UpdateConservationStateMutation,
    UpdateConservationStateMutationVariables
  >(UpdateConservationStateDocument, {
    refetchQueries: [FindAllInspectionsByStructureDocument],
    awaitRefetchQueries: true,
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    updateConservationState,
    data,
    loading,
    error
  };
}
