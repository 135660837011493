import { StyledVideo } from './Video.styles';
import { VideoProps } from './Video.interfaces';

const Video = ({
  src,
  width,
  height,
  controls = true,
  autoPlay = false,
  loop = false,
  muted = false,
  poster
}: VideoProps) => {
  return (
    <StyledVideo
      src={src}
      width={width}
      height={height}
      controls={controls}
      autoPlay={autoPlay}
      loop={loop}
      muted={muted}
      poster={poster}
    />
  );
};

export default Video;
