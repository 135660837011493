import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type DeleteInstrumentReadingMutationVariables = Types.Exact<{
  structureId: Types.Scalars['String']['input'];
  data: Types.DeleteInstrumentReadingInput;
}>;


export type DeleteInstrumentReadingMutation = { __typename?: 'Mutation', deleteInstrumentReading: string };


export const DeleteInstrumentReadingDocument = gql`
    mutation DeleteInstrumentReading($structureId: String!, $data: DeleteInstrumentReadingInput!) {
  deleteInstrumentReading(structureId: $structureId, data: $data)
}
    `;
export type DeleteInstrumentReadingMutationFn = Apollo.MutationFunction<DeleteInstrumentReadingMutation, DeleteInstrumentReadingMutationVariables>;
export type DeleteInstrumentReadingMutationResult = Apollo.MutationResult<DeleteInstrumentReadingMutation>;
export type DeleteInstrumentReadingMutationOptions = Apollo.BaseMutationOptions<DeleteInstrumentReadingMutation, DeleteInstrumentReadingMutationVariables>;