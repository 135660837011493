import { useMutation } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';
import { ProjectContext } from '../../../../Context/ContextAPI';
import { client } from '../../../../data/apollo.client';
import {
  DeleteRecordActivitiesImageDocument,
  DeleteRecordActivitiesImageMutation,
  DeleteRecordActivitiesImageMutationVariables
} from '../../../../data/graphql/generated/deleteRecordActivitiesImage';
import {
  DeleteRecordActivitiesVideoDocument,
  DeleteRecordActivitiesVideoMutation,
  DeleteRecordActivitiesVideoMutationVariables
} from '../../../../data/graphql/generated/deleteRecordActivitiesVideo';
import {
  SaveRecordActivitiesDocument,
  SaveRecordActivitiesMutation,
  SaveRecordActivitiesMutationVariables
} from '../../../../data/graphql/generated/saveRecordActivities';
import {
  UpdateRecordActivitiesDocument,
  UpdateRecordActivitiesMutation,
  UpdateRecordActivitiesMutationVariables
} from '../../../../data/graphql/generated/updateRecordActivities';
import {
  FindDataByRecordDocument,
  FindDataByRecordQuery
} from '../../../../data/graphql/query/generated/findDataByRecord';
import { sendActivityFile } from '../../../../data/services/ATOService';
import ToastifyModel from '../../../../Models/ToastifyModel';
import { API_URL } from '../../../../utils/const';
import ErrorsTreatments from '../../../../utils/errorTreatment';
import { UserData } from '../../../Map/types';
import { toastfyError, toastfySuccess } from '../../../Toastify';
import Button from '../../Atoms/Button/Button';
import { FileType } from '../../Molecules/FileViewer/FileViewer.interfaces';
import InputTextarea from '../../Molecules/InputTextarea/InputTextarea';
import InputFileWithList from '../InputFileWithList/InputFileWithList';
import { AddActivityFormProps } from './AddActivityForm.interface';
import {
  DivButtonArea,
  DivInputsArea,
  HolderForm
} from './AddActivityForm.styles';

const oneMegaByte = 1048576;

const AddActivityForm = ({
  setShowModal,
  recordId,
  editActivity
}: AddActivityFormProps) => {
  const { t } = useTranslation();
  const [activitiesCarriedOut, setActivitiesCarriedOut] = useState<string>(
    editActivity?.description ?? ''
  );
  const [imageFiles, setImageFiles] = useState<FileType[]>([]);
  const [videoFiles, setVideoFiles] = useState<FileType[]>([]);
  const [saveRecordActivities] = useMutation<
    SaveRecordActivitiesMutation,
    SaveRecordActivitiesMutationVariables
  >(SaveRecordActivitiesDocument);
  const [updateRecordActivities] = useMutation<
    UpdateRecordActivitiesMutation,
    UpdateRecordActivitiesMutationVariables
  >(UpdateRecordActivitiesDocument);
  const [deleteRecordActivitiesImage] = useMutation<
    DeleteRecordActivitiesImageMutation,
    DeleteRecordActivitiesImageMutationVariables
  >(DeleteRecordActivitiesImageDocument);
  const [deleteRecordActivitiesVideo] = useMutation<
    DeleteRecordActivitiesVideoMutation,
    DeleteRecordActivitiesVideoMutationVariables
  >(DeleteRecordActivitiesVideoDocument);
  const { userData } = useContext(ProjectContext) as {
    userData: UserData;
  };

  const addActivityFormSchema = z.object({
    activitiesCarriedOut: z
      .string()
      .min(1, {
        message: 'fieldIsRequired'
      })
      .refine((val) => val !== '', {
        message: 'fieldIsRequired'
      }),
    imageFiles: z.any().optional(),
    videoFiles: z.any().optional()
  });

  type AddActivityFormSchemaType = z.infer<typeof addActivityFormSchema>;

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors }
  } = useForm<AddActivityFormSchemaType>({
    resolver: zodResolver(addActivityFormSchema),
    defaultValues: {
      activitiesCarriedOut: editActivity?.description,
      imageFiles: editActivity
        ? [
            ...(imageFiles || []),
            ...editActivity.images.map((image) => {
              const file = new File([image.image], image.image, {
                type: 'image'
              });
              return file;
            })
          ]
        : undefined,
      videoFiles: editActivity
        ? [
            ...(videoFiles || []),
            ...editActivity.videos.map((video) => {
              const file = new File([video.video], video.video, {
                type: 'video'
              });
              return file;
            })
          ]
        : undefined
    }
  });

  const urlImage = '/ato/record/activity/image/';
  const urlVideo = '/ato/record/activity/video/';

  useEffect(() => {
    if (editActivity) {
      const editImageFiles: FileType[] = [
        ...(imageFiles || []),
        ...editActivity.images.map((image) => ({
          id: image.id,
          name: image.image,
          url: `${API_URL}/${image.image}`
        }))
      ];

      const editVideoFiles: FileType[] = [
        ...(videoFiles || []),
        ...editActivity.videos.map((video) => ({
          id: video.id,
          name: video.video,
          url: `${API_URL}/${video.video}`
        }))
      ];

      setImageFiles(editImageFiles);
      setValue(
        'imageFiles',
        editImageFiles.map((file) => file.file!)
      );
      setVideoFiles(editVideoFiles);
      setValue(
        'videoFiles',
        editVideoFiles.map((file) => file.file!)
      );
    }
  }, []);

  const handleSubmitAddActivityForm = async (
    data: AddActivityFormSchemaType
  ) => {
    const { activitiesCarriedOut } = data;

    if (editActivity?.id) {
      const imageFormData = new FormData();
      const videoFormData = new FormData();
      const imageExtensions = ['.png', '.jpg', '.jpeg'];
      const videoExtensions = ['.mkv', '.avi', '.mp4', '.wmv'];

      const allFiles = imageFiles.concat(videoFiles);

      allFiles.forEach((file) => {
        if (
          imageExtensions.some((imageExtension) =>
            file.name.endsWith(imageExtension)
          )
        ) {
          imageFormData.append('file', file.file as any);
        } else if (
          videoExtensions.some((videoExtension) =>
            file.name.endsWith(videoExtension)
          )
        ) {
          videoFormData.append('video', file.file as any);
        }
      });

      const sendImagePromise = sendActivityFile(
        imageFormData,
        urlImage,
        editActivity?.id || '',
        userData.token
      );
      const sendVideoPromise = sendActivityFile(
        videoFormData,
        urlVideo,
        editActivity?.id || '',
        userData.token
      );

      Promise.all([sendImagePromise, sendVideoPromise])
        .then(([imageResponse, videoResponse]) => {
          if (imageResponse === 201 || videoResponse === 201) {
            toastfySuccess(t('editedSuccessfully'));
          }
        })
        .catch(() => {
          toastfyError(t(ToastifyModel().toastifyMessage.error));
        })
        .finally(async () => {
          await client.refetchQueries({
            include: [
              {
                query: FindDataByRecordDocument,
                variables: {
                  recordId: recordId
                },
                awaitRefetchQueries: true
              }
            ]
          });
          toastfySuccess(t('editedSuccessfully'));
          setShowModal(false);
        });

      updateRecordActivities({
        variables: {
          data: {
            id: editActivity?.id,
            description: activitiesCarriedOut
          }
        },
        onCompleted: () => {
          setShowModal(false);
          toastfySuccess(t('editedSuccessfully'));
        },
        onError: ({ graphQLErrors }) => {
          const errorMessage = ErrorsTreatments(graphQLErrors[0].message, t);
          toastfyError(errorMessage);
        },
        update: (cache, { data }) => {
          if (!data) return;
          const existingData = cache.readQuery({
            query: FindDataByRecordDocument,
            variables: {
              recordId: recordId
            }
          }) as FindDataByRecordQuery;

          const updatedData = {
            ...existingData,
            findDataByRecord: {
              ...existingData.findDataByRecord,
              activities: existingData.findDataByRecord.activities.map(
                (item: any) =>
                  item.id === editActivity?.id
                    ? {
                        ...item,
                        id: editActivity?.id,
                        description: activitiesCarriedOut,
                        __typename: 'AtoRecordActivitiesDataType'
                      }
                    : item
              )
            }
          };

          cache.writeQuery({
            query: FindDataByRecordDocument,
            variables: {
              recordId: recordId
            },
            data: updatedData
          });
        }
      });
    } else {
      saveRecordActivities({
        variables: {
          description: activitiesCarriedOut,
          recordId: recordId
        },
        onCompleted: ({ saveRecordActivities }) => {
          const imageFormData = new FormData();
          const videoFormData = new FormData();
          const imageExtensions = ['.png', '.jpg', '.jpeg'];
          const videoExtensions = ['.mkv', '.avi', '.mp4', '.wmv'];
          const allFiles = imageFiles.concat(videoFiles);
          allFiles.forEach((file) => {
            if (
              imageExtensions.some((imageExtension) =>
                file.name.endsWith(imageExtension)
              )
            ) {
              imageFormData.append('file', file.file as any);
            } else if (
              videoExtensions.some((videoExtension) =>
                file.name.endsWith(videoExtension)
              )
            ) {
              videoFormData.append('video', file.file as any);
            }
          });
          const sendImagePromise = sendActivityFile(
            imageFormData,
            urlImage,
            saveRecordActivities,
            userData.token
          );
          const sendVideoPromise = sendActivityFile(
            videoFormData,
            urlVideo,
            saveRecordActivities,
            userData.token
          );
          Promise.all([sendImagePromise, sendVideoPromise])
            .then(([imageResponse, videoResponse]) => {
              if (imageResponse === 201 || videoResponse === 201) {
                toastfySuccess(t('registeredSuccessfully'));
              }
            })
            .catch(() => {
              toastfyError(t(ToastifyModel().toastifyMessage.error));
            })
            .finally(async () => {
              await client.refetchQueries({
                include: [
                  {
                    query: FindDataByRecordDocument,
                    variables: {
                      recordId: recordId
                    },
                    awaitRefetchQueries: true
                  }
                ]
              });
              toastfySuccess(t('registeredSuccessfully'));
              setShowModal(false);
            });
          setShowModal(false);
          toastfySuccess(t('registeredSuccessfully'));
        },
        onError: ({ graphQLErrors }) => {
          const errorMessage = ErrorsTreatments(graphQLErrors[0].message, t);
          toastfyError(errorMessage);
        },
        update: (cache, { data: backendResponse }) => {
          if (!backendResponse) return;
          const existingData = cache.readQuery({
            query: FindDataByRecordDocument,
            variables: {
              recordId: recordId
            }
          }) as FindDataByRecordQuery;

          const updatedData = {
            ...existingData,
            findDataByRecord: {
              ...existingData.findDataByRecord,
              activities: [
                ...existingData.findDataByRecord.activities,
                {
                  description: activitiesCarriedOut,
                  id: backendResponse.saveRecordActivities,
                  images: [],
                  videos: [],
                  __typename: 'AtoRecordActivitiesDataType'
                }
              ]
            }
          };

          cache.writeQuery({
            query: FindDataByRecordDocument,
            variables: {
              recordId: recordId
            },
            data: updatedData
          });
        }
      });
    }
  };

  const handleImageFileChange = (selectedFiles: File[]) => {
    const fileSize = imageFiles.reduce((acc, file) => {
      if (file && file.file) {
        return acc + file.file.size;
      }

      return acc;
    }, 0);
    const newselectedFiles: FileType[] = Array.from(selectedFiles).map(
      (file) => ({
        id: '',
        name: file.name,
        url: URL.createObjectURL(file),
        file: file
      })
    );

    const storedStateFiles = imageFiles
      .map((file) => file.file)
      .concat(newselectedFiles.map((file) => file.file)) as File[];

    setValue('imageFiles', storedStateFiles);
    if (fileSize <= 100 * oneMegaByte) {
      setImageFiles((prev) => [...prev, ...newselectedFiles]);
    }
  };

  const handleDeleteImage = (fileSelect: FileType) => {
    const updatedFiles: FileType[] = imageFiles.filter(
      (file) => file.id !== fileSelect.id
    );
    setImageFiles(updatedFiles);

    setValue(
      'imageFiles',
      updatedFiles.map((file) => file.file!)
    );

    if (editActivity) {
      const fileId = fileSelect.id?.split('.')[0] || '';

      deleteRecordActivitiesImage({
        variables: {
          deleteRecordActivitiesImageId: fileId
        },
        onCompleted: () => {
          toastfySuccess(t('deletedSuccessfully'));
        },
        onError: ({ graphQLErrors }) => {
          const errorMessage = ErrorsTreatments(graphQLErrors[0].message, t);
          toastfyError(errorMessage);
        },
        update: (cache, { data }) => {
          if (!data) return;
          const existingData = cache.readQuery({
            query: FindDataByRecordDocument,
            variables: {
              recordId: recordId
            }
          }) as FindDataByRecordQuery;

          const updatedData = {
            ...existingData,
            findDataByRecord: {
              ...existingData.findDataByRecord,
              activities: existingData.findDataByRecord.activities.map(
                (item: any) =>
                  item.id === editActivity?.id
                    ? {
                        ...item,
                        images: item.images.filter(
                          (image: any) => image.id !== fileId
                        )
                      }
                    : item
              )
            }
          };

          cache.writeQuery({
            query: FindDataByRecordDocument,
            variables: {
              recordId: recordId
            },
            data: updatedData
          });
        }
      });
    }
  };

  const handleVideoFileChange = (selectedFiles: File[]) => {
    const fileSize = imageFiles.reduce((acc, file) => {
      if (file && file.file) {
        return acc + file.file.size;
      }

      return acc;
    }, 0);
    const newselectedFiles: FileType[] = Array.from(selectedFiles).map(
      (file) => ({
        id: '',
        name: file.name,
        url: URL.createObjectURL(file),
        file: file
      })
    );

    const storedStateFiles = videoFiles
      .map((file) => file.file)
      .concat(newselectedFiles.map((file) => file.file)) as File[];

    setValue('videoFiles', storedStateFiles);
    if (fileSize <= 100 * oneMegaByte) {
      setVideoFiles((prev) => [...prev, ...newselectedFiles]);
    }
  };

  const handleDeleteVideo = (fileSelect: FileType) => {
    const updatedFiles: FileType[] = videoFiles.filter(
      (file) => file.id !== fileSelect.id
    );
    setVideoFiles(updatedFiles);

    setValue(
      'videoFiles',
      updatedFiles.map((file) => file.file!)
    );

    if (editActivity) {
      const fileId = fileSelect.id?.split('.')[0] || '';

      deleteRecordActivitiesVideo({
        variables: {
          deleteRecordActivitiesVideoId: fileId
        },
        onCompleted: () => {
          toastfySuccess(t('deletedSuccessfully'));
        },
        onError: ({ graphQLErrors }) => {
          const errorMessage = ErrorsTreatments(graphQLErrors[0].message, t);
          toastfyError(errorMessage);
        },
        update: (cache, { data }) => {
          if (!data) return;
          const existingData = cache.readQuery({
            query: FindDataByRecordDocument,
            variables: {
              recordId: recordId
            }
          }) as FindDataByRecordQuery;

          const updatedData = {
            ...existingData,
            findDataByRecord: {
              ...existingData.findDataByRecord,
              activities: existingData.findDataByRecord.activities.map(
                (item: any) =>
                  item.id === editActivity?.id
                    ? {
                        ...item,
                        videos: item.videos.filter(
                          (video: any) => video.id !== fileId
                        )
                      }
                    : item
              )
            }
          };

          cache.writeQuery({
            query: FindDataByRecordDocument,
            variables: {
              recordId: recordId
            },
            data: updatedData
          });
        }
      });
    }
  };

  return (
    <HolderForm>
      <div>
        <InputTextarea
          errorMessage={errors.activitiesCarriedOut?.message}
          error={!!errors.activitiesCarriedOut}
          height="100px"
          label={t('ActivitiesCarriedOut')}
          name="activitiesCarriedOut"
          onChange={(event) => setActivitiesCarriedOut(event.target.value)}
          register={register}
          value={activitiesCarriedOut}
          width="300px"
        />
      </div>
      <DivInputsArea>
        <InputFileWithList
          register={register}
          name="imageFiles"
          errorMessage={errors.imageFiles?.message}
          error={!!errors.imageFiles}
          accept=".png, .jpg, .jpeg"
          label={t('images')}
          multiple={true}
          files={imageFiles}
          onChange={(event) => handleImageFileChange(event.target.files as any)}
          onDelete={(file: FileType) => handleDeleteImage(file)}
        />
        <InputFileWithList
          register={register}
          name="videoFiles"
          errorMessage={errors.videoFiles?.message}
          error={!!errors.videoFiles}
          accept=".avi .mp4 .mkv .wmv"
          label={t('video')}
          multiple={true}
          files={videoFiles}
          onChange={(event) => handleVideoFileChange(event.target.files as any)}
          onDelete={(file: FileType) => handleDeleteVideo(file)}
        />
      </DivInputsArea>
      <DivButtonArea>
        <Button
          text={t('Cancel')}
          variant={'secondary'}
          size="small"
          onClick={() => {
            setShowModal(false);
          }}
        />
        <Button
          text={t('Save')}
          variant={'primary'}
          size="small"
          onClick={handleSubmit(handleSubmitAddActivityForm)}
        />
      </DivButtonArea>
    </HolderForm>
  );
};

export default AddActivityForm;
