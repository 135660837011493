import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AdvanceButtonStyled,
  CancelButtonStyled
} from '../../Components/Buttons/ButtonsStyle';
import {
  RegisterInstrumentBackground,
  RegisterInstrumentHeader,
  RegisterInstrumentModal,
  RegisterInstrumentTitle
} from '../../Components/Instruments/Register/RegisterInstrumentStyle';
import {
  toastfyDimiss,
  toastfyError,
  toastfySuccess,
  toastifyLoading
} from '../../Components/Toastify';
import ToastifyModel from '../../Models/ToastifyModel';
import {
  ButtonAreaAdmin,
  InputArea
} from '../../Screens/AdminScreen/AdminScreenStyle';
import { Color } from '../../Styles/Styles';
import { useDeleteUsersPermission } from '../../data/hooks/admin-permission/use-delete-user-permission';
import { useAdminDeleteEntity } from './AdminDeleteEntity';

export function AdminDeleteModal({
  id,
  idsPermission,
  type,
  name,
  nonDelete,
  setShowModal,
  setDeleting,
  title
}: {
  id: string;
  idsPermission?: {
    moduleAssignmentId: string;
    structureUserPermissionId: string;
  };
  nonDelete?: boolean;
  type: string;
  name: string;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  setDeleting: Dispatch<SetStateAction<{ status: boolean; data: any }>>;
  title: string;
}) {
  const { t } = useTranslation();
  const { deleteEntityById } = useAdminDeleteEntity(type);
  const { deleteUserPermission } = useDeleteUsersPermission();

  const SendDelete = async () => {
    if (type !== 'Permission') {
      const deleteResponse = await deleteEntityById(id);
      toastifyLoading(`${t('deleting')} ${name}...`);
      if (deleteResponse.data) {
        toastfyDimiss('toastLoading');
        toastfySuccess(`${name} ${t('successfullyDeactivated')}!`);
        setShowModal(false);
        setDeleting({ status: false, data: {} });
      } else if (deleteResponse.errors) {
        toastfyDimiss('toastLoading');
        toastfyError(
          deleteResponse.errors[0].message ||
            t(ToastifyModel().toastifyMessage.error)
        );
        setShowModal(false);
        setDeleting({ status: false, data: {} });
      }
    } else {
      if (
        idsPermission &&
        idsPermission?.moduleAssignmentId !== '' &&
        idsPermission?.structureUserPermissionId !== ''
      ) {
        const deleteResponse = await deleteUserPermission({
          variables: {
            data: {
              moduleAssignmentId: idsPermission?.moduleAssignmentId,
              structureUserPermissionId:
                idsPermission?.structureUserPermissionId
            }
          }
        });
        toastifyLoading(`${t('deleting')} ${name}...`);
        if (deleteResponse.data) {
          toastfyDimiss('toastLoading');
          toastfySuccess(`${name} ${t('successfullyDeactivated')}!`);
          setShowModal(false);
          setDeleting({ status: false, data: {} });
        } else if (deleteResponse.errors) {
          toastfyDimiss('toastLoading');
          toastfyError(
            deleteResponse.errors[0].message ||
              t(ToastifyModel().toastifyMessage.error)
          );
        }
      }
    }
  };

  return (
    <RegisterInstrumentBackground>
      <RegisterInstrumentModal
        style={{
          width: '60%',
          minWidth: 600,
          maxWidth: 650,
          height: 'max-content',
          minHeight: 'auto',
          color: Color.Text1
        }}
      >
        <RegisterInstrumentHeader>
          <RegisterInstrumentTitle>{`${t('Delete')} ${t(title)}`}</RegisterInstrumentTitle>
        </RegisterInstrumentHeader>
        {(!nonDelete || nonDelete == undefined) && (
          <InputArea>Deseja mesmo desativar {name}?</InputArea>
        )}
        {nonDelete && <InputArea>Não é possivel desabilitar {name}?</InputArea>}
        <ButtonAreaAdmin>
          <CancelButtonStyled
            onClick={() => {
              if (setShowModal) setShowModal(false);
              setDeleting({ status: false, data: {} });
            }}
          >
            {t('Cancel').toLocaleUpperCase()}
          </CancelButtonStyled>
          {(!nonDelete || nonDelete == undefined) && (
            <AdvanceButtonStyled onClick={() => SendDelete()}>
              {' '}
              {t('Disabled').toLocaleUpperCase()}
            </AdvanceButtonStyled>
          )}
        </ButtonAreaAdmin>
      </RegisterInstrumentModal>
    </RegisterInstrumentBackground>
  );
}
