import { Dispatch, SetStateAction } from 'react';
import { PiezometerInfoWindow } from './PiezometerInfoWindow';
import { PluviometerInfoWindow } from './PluviometerInfoWindow';
import { WaterLevelInfoWindow } from './WaterLevelInfoWindow';
import { SurfaceMarkerInfoWindow } from './SurfaceMarkerInfoWindow';
import { useTranslation } from 'react-i18next';
import { MapPopUpHeaderStyle } from './MapGoogle.styles';
import { IoIosClose } from 'react-icons/io';
import Icon from '../../V2/Atoms/Icon/Icon';
import Text from '../../V2/Atoms/Text/Text';

export interface MapGoogleInfoWindowProps {
  instrument: {
    id: string;
    type: string;
    name: string;
  };
  setInfoWindowOpen: Dispatch<SetStateAction<boolean>>;
}

export type InstrumentType =
  | 'piezometer'
  | 'ina'
  | 'pluviometer'
  | 'waterlevel'
  | 'surfacemarker';

export const MapGoogleInfoWindow = ({
  instrument,
  setInfoWindowOpen
}: MapGoogleInfoWindowProps) => {
  const { t: translation } = useTranslation();
  const instrumentInfoWindowMap = {
    piezometer: PiezometerInfoWindow,
    ina: PiezometerInfoWindow,
    pluviometer: PluviometerInfoWindow,
    waterlevel: WaterLevelInfoWindow,
    surfacemarker: SurfaceMarkerInfoWindow
  };

  const InstrumentInfoWindow =
    instrumentInfoWindowMap[
      instrument.type.toLocaleLowerCase() as InstrumentType
    ];

  if (InstrumentInfoWindow) {
    return (
      <InstrumentInfoWindow
        instrument={instrument}
        setInfoWindowOpen={setInfoWindowOpen}
      />
    );
  }

  return (
    <MapPopUpHeaderStyle>
      <Text type="h5">{translation('error')}</Text>
      <Icon
        variant={'medium'}
        onClick={() => setInfoWindowOpen(false)}
        Icon={IoIosClose}
      />
    </MapPopUpHeaderStyle>
  );
};
