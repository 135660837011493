import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type SignAtoRecordMutationVariables = Types.Exact<{
  recordId: Types.Scalars['String']['input'];
}>;


export type SignAtoRecordMutation = { __typename?: 'Mutation', signAtoRecord: string };


export const SignAtoRecordDocument = gql`
    mutation SignAtoRecord($recordId: String!) {
  signAtoRecord(recordId: $recordId)
}
    `;
export type SignAtoRecordMutationFn = Apollo.MutationFunction<SignAtoRecordMutation, SignAtoRecordMutationVariables>;
export type SignAtoRecordMutationResult = Apollo.MutationResult<SignAtoRecordMutation>;
export type SignAtoRecordMutationOptions = Apollo.BaseMutationOptions<SignAtoRecordMutation, SignAtoRecordMutationVariables>;