import { ApolloClient, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { API_URL } from '../utils/const';

export const client = new ApolloClient({
  link: setContext((_, { headers }) => {
    const user = localStorage.getItem('user');
    const token = user ? JSON.parse(user).token || '' : '';
    return {
      headers: {
        ...headers,
        authorization: token
      }
    };
  }).concat(
    createUploadLink({
      uri: `${API_URL}/graphql`,
      headers: {
        'keep-alive': 'true'
      }
    })
  ),
  cache: new InMemoryCache()
});
