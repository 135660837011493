import { zodResolver } from '@hookform/resolvers/zod';
import { t } from 'i18next';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import * as z from 'zod';
import Button from '../../Atoms/Button/Button';
import DatepickerInput from '../../Molecules/DatepickerInput/DatepickerInput';
import InputText from '../../Molecules/InputText/InputText';
import { DivHolderFilterForm } from './AtoRecordFilterComponent.styles';

const AtoRecordFilterComponent = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { t: translate } = useTranslation();
  const FilterFormType = z
    .object({
      startDate: z.date().nullable().optional(),
      endDate: z.date().nullable().optional(),
      recordNumber: z.string().optional()
    })
    .superRefine((data, ctx) => {
      const { startDate, endDate } = data;

      if (startDate && endDate) {
        if (endDate < startDate) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: translate('validationDate'),
            path: ['endDate']
          });
        }
      }
    });

  type FilterFormValues = z.infer<typeof FilterFormType>;
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm<FilterFormValues>({
    resolver: zodResolver(FilterFormType),
    defaultValues: {
      startDate: searchParams.get('startDate')
        ? new Date(searchParams.get('startDate')!)
        : null,
      endDate: searchParams.get('endDate')
        ? new Date(searchParams.get('endDate')!)
        : null,
      recordNumber: searchParams.get('recordNumber') || ''
    }
  });

  const handleFilterSubmit = (data: FilterFormValues) => {
    const startDate = data.startDate ? data.startDate.toISOString() : undefined;
    const endDate = data.endDate ? data.endDate.toISOString() : undefined;
    const recordNumber = data.recordNumber || undefined;
    const newSearchParams = new URLSearchParams(searchParams.toString());

    if (startDate) {
      newSearchParams.set('startDate', startDate);
    } else {
      newSearchParams.delete('startDate');
    }

    if (endDate) {
      newSearchParams.set('endDate', endDate);
    } else {
      newSearchParams.delete('endDate');
    }

    if (recordNumber) {
      newSearchParams.set('recordNumber', recordNumber);
    } else {
      newSearchParams.delete('recordNumber');
    }

    navigate({
      pathname: location.pathname,
      search: newSearchParams.toString()
    });

    searchParams.set('filter', 'false');
  };

  const handleClearFilters = () => {
    reset();
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.delete('startDate');
    newSearchParams.delete('endDate');
    newSearchParams.delete('recordNumber');
    navigate({
      pathname: location.pathname,
      search: newSearchParams.toString()
    });
  };

  return (
    <DivHolderFilterForm>
      <DatepickerInput
        label={t('startDate')}
        control={control}
        name="startDate"
        placeholder={t('startDate')}
        time={false}
        error={!!errors.startDate}
        errorMessage={errors?.startDate?.message}
      />
      <DatepickerInput
        label={t('finalDate')}
        control={control}
        name="endDate"
        placeholder={t('finalDate')}
        time={false}
        error={!!errors.endDate}
        errorMessage={errors?.endDate?.message}
      />
      <InputText
        label={t('RdoNumber')}
        type="text"
        control={control}
        name="recordNumber"
        error={!!errors.recordNumber}
        errorMessage={errors?.recordNumber?.message}
      />
      <Button
        variant="primary"
        size="medium"
        text={t('Filtrar')}
        onClick={handleSubmit(handleFilterSubmit)}
      />
      <Button
        variant="secondary"
        size="medium"
        text={t('cleanFilers')}
        onClick={handleClearFilters}
      />
    </DivHolderFilterForm>
  );
};

export default AtoRecordFilterComponent;
