import { useMutation } from '@apollo/client';
import {
  CreateWaterLevelDocument,
  CreateWaterLevelMutation,
  CreateWaterLevelMutationVariables
} from '../../graphql/generated/addWaterLevelInstrument';
import { ListInstrumentsByStructureDocument } from '../../graphql/query/generated/listInstrumentsByStructure.query';
import { ListWaterLevelByStructureDocument } from '../../graphql/query/generated/listWaterLevelByStructure.query';

export function useAddWaterLevel(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [addWaterLevel, { data, error, loading }] = useMutation<
    CreateWaterLevelMutation,
    CreateWaterLevelMutationVariables
  >(CreateWaterLevelDocument, {
    refetchQueries: [
      ListWaterLevelByStructureDocument,
      ListInstrumentsByStructureDocument
    ],
    awaitRefetchQueries: true,
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    addWaterLevel,
    data,
    error,
    loading
  };
}
