import styled, { keyframes } from 'styled-components';
import { Color, MaxWindowHeight, MaxWindowWidth } from '../../Styles/Styles';

export const StructureContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: ${MaxWindowHeight};
  /* max-height: 1350px; */
  width: ${MaxWindowWidth};
  min-width: 900px;
  min-height: 530px;
  box-sizing: border-box;
  position: absolute;
  overflow-x: hidden;
  height: 100%;
`;

export const DivContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 92%;
`;

export const StructureAreaDiv = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
`;

export const LateralDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;
  justify-content: space-evenly;
  background: ${Color.Gray4};
`;
const slideIn = keyframes`
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

export const ModuleName = styled.div`
  position: absolute;
  padding: 4px 8px;
  border-radius: 2px;
  left: 120%; /* Coloque o tooltip à direita do ícone */
  top: 4px;
  white-space: nowrap;
  display: inline-block;
  z-index: 2;
  opacity: 0;
  animation: ${slideIn} 0.2s ease forwards; /* Adicione a animação de keyframes */
  user-select: none;
`;

export const Icon = styled.div`
  position: relative;
  &:hover ${ModuleName} {
    opacity: 1;
    color: ${Color.Gray2};
  }
`;
