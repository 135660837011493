import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import Input from '../../Atoms/Input/Input';
import Text from '../../Atoms/Text/Text';
import { InputTextProps } from './InputText.interfaces';
import { ErrorStyled, InputContainer, InputStyled } from './InputText.styles';
import { useTranslation } from 'react-i18next';

//TODO: refatorar esse componente

const InputText = ({
  label,
  onChange,
  error,
  errorMessage,
  disabled,
  onKeyDown,
  name,
  value: propValue,
  type,
  width,
  control,
  required
}: InputTextProps) => {
  const { t: translate } = useTranslation();
  const [value, setValue] = useState(propValue || '');
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    setValue(propValue || '');
  }, [propValue]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <InputContainer>
      {control ? (
        <Controller
          name={name}
          control={control}
          defaultValue={''}
          render={({ field }) => (
            <InputStyled hasValue={!!field.value || isFocused}>
              <Input
                id={name}
                name={name}
                type={type}
                width={width}
                error={error}
                onKeyDown={onKeyDown}
                value={field.value}
                onChange={field.onChange}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                disabled={disabled}
              />
              <Text
                type="label"
                size="medium"
                htmlFor={name}
                error={error}
                color={disabled ? 'disabled' : 'brown'}
              >
                {label} {required && '*'}
              </Text>
              {error ? (
                <ErrorStyled>{errorMessage}</ErrorStyled>
              ) : (
                <ErrorStyled />
              )}
            </InputStyled>
          )}
        />
      ) : (
        <InputStyled hasValue={!!value || isFocused}>
          <Input
            id={name}
            name={name}
            type={type}
            width={width}
            error={error}
            onKeyDown={onKeyDown}
            value={value}
            onChange={handleChange}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            disabled={disabled}
          />
          <Text type="label" size="medium" htmlFor={name} error={error}>
            {label}
          </Text>
          {error ? (
            <ErrorStyled>{translate(errorMessage)}</ErrorStyled>
          ) : (
            <ErrorStyled />
          )}
        </InputStyled>
      )}
    </InputContainer>
  );
};

export default InputText;
