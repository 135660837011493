import Skeleton from 'react-loading-skeleton';
import Text from '../../Atoms/Text/Text';
import { GeneralTemplateProps } from './GeneralTemplate.interface';
import {
  DivBaseInfo,
  DivCards,
  DivContainerFooterData,
  DivContainerGeneralTemplate,
  DivContent,
  DivContentTemplate,
  DivHeader,
  DivHistory,
  DivMap,
  Div1
} from './GeneralTemplate.styles';

// TODO: Ajustar estilos

const GeneralTemplate = ({
  loading,
  title,
  baseInfo,
  cards,
  footerData,
  map,
  history,
  logo
}: GeneralTemplateProps) => {
  return loading ? (
    <DivContainerGeneralTemplate>
      <Skeleton width="300px" height="40px" />
      <DivHeader>
        <Skeleton width="90vw" height="80px" />
      </DivHeader>
      <DivContent>
        <DivHistory>
          <Skeleton width="40vw" height="150px" />
        </DivHistory>
        <DivMap>
          <Skeleton width="40vw" height="150px" />
        </DivMap>
      </DivContent>
      <DivContainerFooterData>
        <Skeleton width="90vw" height="150px" />
      </DivContainerFooterData>
    </DivContainerGeneralTemplate>
  ) : (
    <DivContainerGeneralTemplate>
      <DivContentTemplate>
        <Text type={'h1'}>{title}</Text>
        <Div1>
          {logo}
          <DivCards>{cards}</DivCards>
        </Div1>
        <Div1>
          <div>
            <DivBaseInfo>{baseInfo}</DivBaseInfo>
            <DivHistory>{history}</DivHistory>
          </div>
          <DivMap>{map}</DivMap>
        </Div1>
        <DivContainerFooterData>{footerData}</DivContainerFooterData>
      </DivContentTemplate>
    </DivContainerGeneralTemplate>
  );
};

export default GeneralTemplate;
