export interface PermissionsData {
  companyId: string;
  moduleId: string;
  moduleRoleId: string;
  structureId: string;
  userId: string;
}

export const __initialPermissionsData = {
  companyId: '',
  moduleId: '',
  moduleRoleId: '',
  structureId: '',
  userId: ''
};

export interface PermissionsActive {
  module: boolean;
  moduleRole: boolean;
  structure: boolean;
  user: boolean;
  instrument: boolean;
}

export const __initialPermissionsActive = {
  module: true,
  moduleRole: true,
  structure: true,
  user: true,
  instrument: true
};

export interface Mandatory {
  companyId: boolean;
  moduleId: boolean;
  moduleRoleId: boolean;
  structureId: boolean;
  userId: boolean;
}

export const __initialMandatory = {
  companyId: true,
  moduleId: true,
  moduleRoleId: true,
  structureId: true,
  userId: true
};
