import React from 'react';
import {
  DivContinerPopup,
  DivDontReadings,
  MapPopUpContentDataStyle,
  MapPopUpContentStyle,
  MapPopUpHeaderStyle,
  SpanInstrumentPopUpStyle
} from './MapGoogle.styles';
import { IoIosClose } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import {
  ListLatestSurfaceMarkerReadingDocument,
  ListLatestSurfaceMarkerReadingQuery,
  ListLatestSurfaceMarkerReadingQueryVariables
} from '../../../data/graphql/query/generated/listLatestSurfaceMarkerReading.query';
import { useQuery } from '@apollo/client';
import { MapGoogleInfoWindowProps } from './MapGoogleInfoWindow';
import Skeleton from 'react-loading-skeleton';
import Icon from '../../V2/Atoms/Icon/Icon';
import Text from '../../V2/Atoms/Text/Text';

export const SurfaceMarkerInfoWindow = ({
  instrument,
  setInfoWindowOpen
}: MapGoogleInfoWindowProps) => {
  const { t: translation } = useTranslation();

  const { data: lastInstrumentData, loading } = useQuery<
    ListLatestSurfaceMarkerReadingQuery,
    ListLatestSurfaceMarkerReadingQueryVariables
  >(ListLatestSurfaceMarkerReadingDocument, {
    variables: {
      instrumentId: instrument.id
    },
    fetchPolicy: 'no-cache'
  });

  const instrumentData = [
    {
      label: 'Type',
      value: instrument.type
    },
    {
      label: 'Status',
      value:
        lastInstrumentData?.listLatestSurfaceMarkerReading.operationalStatus
    },
    {
      label: 'LastReadingDate',
      value:
        lastInstrumentData?.listLatestSurfaceMarkerReading.date &&
        new Date(
          lastInstrumentData?.listLatestSurfaceMarkerReading.date
        ).toLocaleDateString()
    },
    {
      label: 'elevation',
      value: lastInstrumentData?.listLatestSurfaceMarkerReading.elevation
    },
    {
      label: 'Observation',
      value: lastInstrumentData?.listLatestSurfaceMarkerReading.observation
    },
    {
      label: 'AlertLevel',
      value: lastInstrumentData?.listLatestSurfaceMarkerReading.alertLevels
    },
    {
      label: 'coordinateE',
      value: lastInstrumentData?.listLatestSurfaceMarkerReading.coordinateE
    },
    {
      label: 'coordinateN',
      value: lastInstrumentData?.listLatestSurfaceMarkerReading.coordinateN
    }
  ];

  return (
    <>
      {loading ? (
        <Skeleton count={9} height={35} />
      ) : (
        <DivContinerPopup>
          <MapPopUpHeaderStyle>
            <Text type="h5">{instrument.name}</Text>
            <Icon
              variant={'medium'}
              onClick={() => setInfoWindowOpen(false)}
              Icon={IoIosClose}
            />
          </MapPopUpHeaderStyle>
          <MapPopUpContentStyle>
            {lastInstrumentData ? (
              instrumentData.map(({ label, value }) => (
                <MapPopUpContentDataStyle key={label}>
                  <SpanInstrumentPopUpStyle>
                    <Text type="p" color="black" size="small">
                      {translation(label)}
                    </Text>
                  </SpanInstrumentPopUpStyle>
                  <SpanInstrumentPopUpStyle>
                    <Text type="p" color="black" size="small">
                      {translation(value) || '-'}
                    </Text>
                  </SpanInstrumentPopUpStyle>
                </MapPopUpContentDataStyle>
              ))
            ) : (
              <DivDontReadings>
                <Text type="p">
                  {translation('dontHaveReadingsForThisInstrument')}
                </Text>
              </DivDontReadings>
            )}
          </MapPopUpContentStyle>
        </DivContinerPopup>
      )}
    </>
  );
};
