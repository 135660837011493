import styled from 'styled-components';
import { Color } from '../../../../../../Styles/Styles';

export const TableHeadContent = styled.thead`
  color: ${Color.Brown1};
  position: sticky;
  top: 0;
  background-color: #f5f5f5;
  z-index: 1;
`;
