import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultButton } from '../../../../Components/Buttons/DefaultButton';
import {
  toastfyDimiss,
  toastfyError,
  toastifyLoading
} from '../../../../Components/Toastify';
import ToastifyModel from '../../../../Models/ToastifyModel';
import {
  AdminContainer,
  AdminFilter,
  AdminSearch,
  AdminTitle,
  SearchIcon,
  StyledInput
} from '../../../../Screens/AdminScreen/AdminScreenStyle';
import { CreateStructureModulesInput } from '../../../../data/graphql/base-schema';
import { useListStructureAndModules } from '../../../../data/hooks/admin-structure-modules/use-list-structure-and-modules';
import { AdminDeleteModal } from '../../AdminDeleteModal';
import AdminTable from '../../AdminTable';
import { __initialModuleData } from './ModulesInterface';
import { ModulesModal } from './ModulesModal';
import Toggles from '../../../../Components/Buttons/Toggles/Toggles';

function Modules() {
  const { t } = useTranslation();
  const [status, setStatus] = useState<boolean>(false);
  const [search, setSearch] = useState('');
  const [showModulesModal, setShowModulesModal] = useState<boolean>(false);
  const [tableValues, setTableValues] = useState<
    {
      Structure: string;
      module: string;
      InitialDate: string;
      EndDate: string;
      status: string;
    }[]
  >([]);
  const [edit, setEdit] = useState<{ status: boolean; data: any }>({
    status: false,
    data: {}
  });
  const [deleting, setDeleting] = useState<{ status: boolean; data: any }>({
    status: false,
    data: {}
  });
  const {
    data: listStructuresAndModules,
    error: listStructuresAndModulesError,
    loading: listStructuresAndModulesLoading
  } = useListStructureAndModules();
  const [modulesData, setModulesData] =
    useState<CreateStructureModulesInput>(__initialModuleData);

  useEffect(() => {
    if (listStructuresAndModulesError) {
      toastfyError(t(ToastifyModel().toastifyMessage.error));
      toastfyDimiss('toastLoading');
    } else if (listStructuresAndModulesLoading) {
      toastifyLoading(t(ToastifyModel().toastifyMessage.loading));
    } else if (listStructuresAndModules) {
      const filteredTableValues =
        listStructuresAndModules.listStructureAndModules
          .filter((company: any) =>
            company.status === status ? status : status || !status
          )
          .map((item: any) => {
            const filteredItem: any = {};
            filteredItem['Structure'] = item.structureName;
            filteredItem['module'] = item.moduleName;
            filteredItem['InitialDate'] = new Date(
              item.start
            ).toLocaleDateString('pt-br');
            filteredItem['EndDate'] = new Date(item.end).toLocaleDateString(
              'pt-br'
            );
            filteredItem['status'] = item.status ? t('active') : t('inactive');
            return filteredItem;
          });
      setTableValues(filteredTableValues);
      toastfyDimiss('toastLoading');
    }
  }, [listStructuresAndModules, status]);

  return (
    <AdminContainer>
      <AdminTitle>{t('module')}</AdminTitle>
      <AdminFilter>
        <div>
          <DefaultButton
            title={`+ ${t('Add')}`}
            action={() => setShowModulesModal(true)}
          />
          {Toggles.ToggleStatus({
            check: status,
            setCheck: setStatus,
            key: 'status',
            text: t('status')
          })}
        </div>
        <AdminSearch>
          <StyledInput
            type="text"
            id="search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <SearchIcon size={20} />
        </AdminSearch>
      </AdminFilter>
      <AdminTable
        tableColumns={['Structure', 'module', 'InitialDate', 'EndDate']}
        tableValues={tableValues.filter(
          (e) =>
            t(e.Structure)
              .toLocaleLowerCase()
              .includes(search.toLocaleLowerCase()) ||
            t(e.module)
              ?.toLocaleLowerCase()
              .includes(search.toLocaleLowerCase()) ||
            e.InitialDate?.toLocaleLowerCase().includes(
              search.toLocaleLowerCase()
            ) ||
            e.EndDate?.toLocaleLowerCase().includes(search.toLocaleLowerCase())
        )}
        edit={edit}
        setEdit={setEdit}
        deleting={deleting}
        setDeleting={setDeleting}
      />
      {(showModulesModal || edit.status) && (
        <ModulesModal
          setShowModal={setShowModulesModal}
          edit={edit}
          setEdit={setEdit}
          modulesData={modulesData}
          setModulesData={setModulesData}
        />
      )}
      {deleting.status && (
        <AdminDeleteModal
          id={
            listStructuresAndModules?.listStructureAndModules.find(
              (e) =>
                e.moduleName === deleting.data.module &&
                e.structureName === deleting.data.Structure
            )?.id ?? ''
          }
          name={`${t(deleting.data.module)} ${t('ofStructure')} ${deleting.data.Structure}`}
          setShowModal={setShowModulesModal}
          setDeleting={setDeleting}
          type="Modules"
          title={t('module')}
        />
      )}
    </AdminContainer>
  );
}

export default Modules;
