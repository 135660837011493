import { useMutation } from '@apollo/client';
import {
  CreateStructureReservoirHandlingDocument,
  CreateStructureReservoirHandlingMutation,
  CreateStructureReservoirHandlingMutationVariables
} from '../../graphql/generated/createStructureReservoirHandling.mutation';
import { ListCompaniesDocument } from '../../graphql/query/generated/listCompanies.query';
import { ListCompanyRelatedUsersAndStructuresDocument } from '../../graphql/query/generated/listCompanyRelatedUsersAndStructures.query';
import { ListModulesRolesDocument } from '../../graphql/query/generated/listModulesRole.query';
import { ListStructuresDocument } from '../../graphql/query/generated/listStructures.query';
import { ListUsersDocument } from '../../graphql/query/generated/listUsers.query';
import { GetUserStructuresListDocument } from '../../graphql/query/generated/structureList.query';
import { GetStructureSummaryDocument } from '../../graphql/query/generated/structureSummary.query';

export function useCreateStructureReservoirHandling(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [createStructureReservoirHandling, { data, error, loading }] =
    useMutation<
      CreateStructureReservoirHandlingMutation,
      CreateStructureReservoirHandlingMutationVariables
    >(CreateStructureReservoirHandlingDocument, {
      refetchQueries: [
        ListStructuresDocument,
        ListCompaniesDocument,
        ListUsersDocument,
        ListModulesRolesDocument,
        GetUserStructuresListDocument,
        ListCompanyRelatedUsersAndStructuresDocument,
        GetStructureSummaryDocument
      ],
      awaitRefetchQueries: true,
      errorPolicy: 'all',
      onCompleted: onCompleted,
      onError: onError
    });

  return {
    createStructureReservoirHandling,
    data,
    error,
    loading
  };
}
