import { useTranslation } from 'react-i18next';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import {
  LoginInstructionsContainer,
  LoginInstructionsP,
  LoginInstructionsPContainer
} from '../LoginScreen/LoginScreenStyle';

export default function LoginInstructions() {
  const { t } = useTranslation();
  return (
    <LoginInstructionsContainer>
      <LoginInstructionsPContainer>
        <AiOutlineInfoCircle />
        <LoginInstructionsP>{t('PasswordLengthMessage')}</LoginInstructionsP>
      </LoginInstructionsPContainer>
      <LoginInstructionsPContainer>
        <AiOutlineInfoCircle />
        <LoginInstructionsP>{t('PasswordCriteriaMessage')}</LoginInstructionsP>
      </LoginInstructionsPContainer>
      <LoginInstructionsPContainer>
        <AiOutlineInfoCircle />
        <LoginInstructionsP>
          {t('AvoidKeyboardSequencesMessage')}
        </LoginInstructionsP>
      </LoginInstructionsPContainer>
    </LoginInstructionsContainer>
  );
}
