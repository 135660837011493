import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiSolidPencil } from 'react-icons/bi';
import { IoCloseOutline } from 'react-icons/io5';
import { useParams } from 'react-router-dom';
import {
  DeleteRecordActivitiesDocument,
  DeleteRecordActivitiesMutation,
  DeleteRecordActivitiesMutationVariables
} from '../../../../data/graphql/generated/deleteRecordActivities';
import {
  FindDataByRecordDocument,
  FindDataByRecordQuery
} from '../../../../data/graphql/query/generated/findDataByRecord';
import ErrorsTreatments from '../../../../utils/errorTreatment';
import { toastfyError, toastfySuccess } from '../../../Toastify';
import ModalConfirm from '../../Molecules/ModalConfirm/ModalConfirm';
import TableComponent from '../../Molecules/TableComponent/TableComponent';
import ViewModal from '../../Molecules/ViewModal/ViewModal';
import AddActivityForm from '../AddActivityForm/AddActivityForm';
import CardAtoInfo from '../CardInfo/CardInfo';
import {
  ActivitiesItem,
  ActivityType,
  CardAtoInfoActivitiesProps,
  RowToEditTypes
} from './CardAtoInfoActivities.interface';

export const CardAtoInfoActivities = ({
  activity
}: CardAtoInfoActivitiesProps) => {
  const { t } = useTranslation();
  const { recordId } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [idForDelete, setIdForDelete] = useState('');
  const [deleteRecordActivities] = useMutation<
    DeleteRecordActivitiesMutation,
    DeleteRecordActivitiesMutationVariables
  >(DeleteRecordActivitiesDocument, {});
  const [rowToEdit, setRowToEdit] = useState<RowToEditTypes>();

  const columnsData = [
    {
      key: 'indexId',
      label: 'id'
    },
    {
      key: 'Activity',
      label: t('Activity')
    }
  ];

  const tableData = activity.map((item: ActivityType, index) => ({
    Activity: item.description,
    images: item.images,
    videos: item.videos,
    id: item.id,
    indexId: index
  }));

  const actions = [
    {
      name: t('Edit'),
      icon: <BiSolidPencil />,
      onClick: (row: RowToEditTypes) => handleEditRow(row),
      canShow: 'Edit'
    },
    {
      name: t('Delete'),
      icon: <IoCloseOutline />,
      onClick: (row: RowToEditTypes) => {
        setIdForDelete(row.id);
      },
      canShow: 'Delete'
    }
  ];

  const handleDeleteRow = (id: string) => {
    return deleteRecordActivities({
      variables: {
        deleteRecordActivitiesId: id
      },
      onCompleted: () => {
        toastfySuccess(t('deletedSuccessfully'));
      },
      onError: ({ graphQLErrors }) => {
        const errorMessage = ErrorsTreatments(graphQLErrors[0].message, t);
        toastfyError(errorMessage);
      },
      update: (cache, { data }) => {
        if (!data) return;
        const existingData = cache.readQuery({
          query: FindDataByRecordDocument,
          variables: {
            recordId: recordId
          }
        }) as FindDataByRecordQuery;

        const updatedData = {
          ...existingData,
          findDataByRecord: {
            ...existingData.findDataByRecord,
            activities: existingData.findDataByRecord.activities.filter(
              (item: ActivitiesItem) => item.id !== id
            )
          }
        };

        cache.writeQuery({
          query: FindDataByRecordDocument,
          variables: {
            recordId: recordId
          },
          data: updatedData
        });
      }
    });
  };

  const handleEditRow = (row: RowToEditTypes) => {
    setRowToEdit(row);
    setShowEditModal(true);
  };

  const handleAddForm = () => {
    setShowModal(true);
  };

  return (
    <>
      <CardAtoInfo
        title={t('ActivitiesPerformedOnSite')}
        buttonLabel={t('Add+')}
        onButtonClick={handleAddForm}
      >
        <TableComponent
          columns={columnsData}
          tableData={tableData}
          actions={actions}
        />
      </CardAtoInfo>
      <ViewModal
        showModal={showModal}
        width={40}
        height={50}
        title={t('addActivityPerformedOnWork')}
        onClose={() => setShowModal(false)}
        component={
          <AddActivityForm
            setShowModal={setShowModal}
            recordId={recordId?.toString() || ''}
          />
        }
      />
      <ViewModal
        showModal={showEditModal}
        width={40}
        height={50}
        title={t('editActivityPerformedOnWork')}
        onClose={() => setShowEditModal(false)}
        component={
          <AddActivityForm
            setShowModal={setShowEditModal}
            recordId={recordId?.toString() || ''}
            editActivity={{
              id: rowToEdit?.id || '',
              description: rowToEdit?.Activity || '',
              images: rowToEdit?.images || [],
              videos: rowToEdit?.videos || []
            }}
          />
        }
      />
      <ModalConfirm
        showModal={!!idForDelete}
        title={`${t('Delete')} ${t('Activity')}`}
        text={t('ConfirmDelete')}
        subtitle={t('ActionCannotBeUndone')}
        onClose={() => {
          setIdForDelete('');
        }}
        onConfirm={() => {
          handleDeleteRow(idForDelete);
          setIdForDelete('');
        }}
      />
    </>
  );
};
