import { useQuery } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  GetStructureSummaryDocument,
  GetStructureSummaryQuery,
  GetStructureSummaryQueryVariables
} from '../../../../data/graphql/query/generated/structureSummary.query';
import MapGoogle from '../../../Map/MapGoogle';
import Button from '../../Atoms/Button/Button';
import Text from '../../Atoms/Text/Text';
import { GroupText } from '../../Molecules/GroupText/GroupText';
import SquareCard from '../../Molecules/SquareCard/SquareCard';
import TableComponent from '../../Molecules/TableComponent/TableComponent';
import ViewModal from '../../Molecules/ViewModal/ViewModal';
import GeneralPageModalData from '../../Organisms/GeneralPageModalData/GeneralPageModalData';
import GeneralTemplate from '../../Templates/GeneralTemplate/GeneralTemplate';
import {
  DivBaseInfo,
  DivButton,
  DivCards,
  DivContainerFooterData,
  MapArea
} from './GeneralPage.styles';
import ContentActionCard from '../../Organisms/ContentActionCard/ContentActionCard';
import Image from '../../Atoms/Image/Image';
import { BUCKET_URL } from '../../../../utils/const';

const GeneralPage = () => {
  const { t: translate } = useTranslation();
  const { structureId } = useParams();
  const { data: listStructureSummary, loading: loadingStructure } = useQuery<
    GetStructureSummaryQuery,
    GetStructureSummaryQueryVariables
  >(GetStructureSummaryDocument, {
    variables: {
      structureInfo: {
        structureId: structureId ?? ''
      }
    }
  });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalHistoryIsOpen, setModalHistoryIsOpen] = useState(false);
  const [titleModal, setTitleModal] = useState('');
  const [dataModal, setDataModal] = useState({});

  const columnsHistory = [
    {
      key: 'stage',
      label: `${translate('stage')}`
    },
    {
      key: 'elevation',
      label: `${translate('elevation')}`
    },
    {
      key: 'method',
      label: `${translate('method')}`
    },
    {
      key: 'date',
      label: `${translate('date')}`
    }
  ];

  const listHistory =
    listStructureSummary?.getStructureSummary.damRaisingHistory
      .map((item) => ({
        stage: item.stage,
        elevation: `${item.elevation} m`,
        method: item.method,
        date: item.date ? new Date(item.date).toLocaleDateString('pt-BR') : '-'
      }))
      .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

  const company = listStructureSummary?.getStructureSummary.company;

  const riskAssessment =
    listStructureSummary?.getStructureSummary.riskAssessment;

  const hydrologyInfos =
    listStructureSummary?.getStructureSummary.hydrologyInfos;

  const geometry = listStructureSummary?.getStructureSummary.geometry;

  const reservoirHandling =
    listStructureSummary?.getStructureSummary.reservoirHandling;

  const summary = listStructureSummary?.getStructureSummary.summary;

  const complex = listStructureSummary?.getStructureSummary.complex;

  const zipCodeFormat = (zipCode: string) => {
    return zipCode.replace(/(\d{5})(\d{3})/, '$1-$2');
  };

  return (
    <>
      <GeneralTemplate
        loading={loadingStructure}
        title={listStructureSummary?.getStructureSummary.name ?? ''}
        logo={
          <Image
            alt="company logo"
            src={`${BUCKET_URL}/${company?.logo}`}
            width="100px"
            height="100px"
            objectFit="contain"
          />
        }
        baseInfo={
          <>
            <ContentActionCard
              title={translate('Company')}
              action={() => {
                setTitleModal(translate('Company'));
                setModalIsOpen(true);
                setDataModal({
                  Company: company?.name ?? '-',
                  CNPJ: company?.cnpj ?? '-',
                  CEP: zipCodeFormat(company?.address.zipCode ?? '-'),
                  Address: company?.address.addressLine ?? '-',
                  City: company?.address.city ?? '-',
                  State: company?.address.state ?? '-',
                  Country: company?.address.country ?? '-'
                });
              }}
            >
              <DivBaseInfo>
                <GroupText
                  title={translate('Company')}
                  value={company?.name ?? '-'}
                  maxLength={10}
                />
                <GroupText
                  title={translate('CNPJ')}
                  value={company?.cnpj ?? '-'}
                />
                <GroupText
                  title={translate('CEP')}
                  value={zipCodeFormat(company?.address.zipCode ?? '-')}
                />
                <GroupText
                  title={translate('Address')}
                  value={company?.address.addressLine ?? '-'}
                  maxLength={20}
                />
              </DivBaseInfo>
            </ContentActionCard>
            <ContentActionCard
              title={translate('Complex')}
              action={() => {
                setTitleModal(translate('Complex'));
                setModalIsOpen(true);
                setDataModal({
                  Name: complex?.name ?? '-',
                  Address: complex?.address ?? '-',
                  CEP: complex?.zipCode ?? '-',
                  Neighborhood: complex?.neighborhood ?? '-',
                  City: complex?.city ?? '-',
                  State: complex?.state ?? '-',
                  Country: complex?.country ?? '-',
                  Telephone: complex?.phone ?? '-'
                });
              }}
            >
              <DivBaseInfo>
                <GroupText
                  title={translate('name')}
                  value={complex?.name ?? '-'}
                  maxLength={15}
                />
                <GroupText
                  title={translate('City')}
                  value={complex?.city ?? '-'}
                  maxLength={15}
                />
                <GroupText
                  title={translate('State')}
                  value={complex?.state ?? '-'}
                  maxLength={15}
                />
                <GroupText
                  title={translate('CEP')}
                  value={complex?.zipCode ?? '-'}
                  maxLength={15}
                />
              </DivBaseInfo>
            </ContentActionCard>
            <ContentActionCard
              title={translate('BasicInformation')}
              action={() => {
                setTitleModal(translate('BasicInformation'));
                setModalIsOpen(true);
                setDataModal({
                  OperationalStatus: summary?.operationalStatus ?? '-',
                  Responsible: `${listStructureSummary?.getStructureSummary.responsible.name ?? ''}, João Arruda`,
                  operationStart: summary?.operationStart
                    ? new Date(summary?.operationStart).toLocaleDateString()
                    : '-',
                  operationEnd: summary?.operationEnd
                    ? new Date(summary?.operationEnd).toLocaleDateString()
                    : '-',
                  decharacterizationDate: summary?.decharacterizationDate
                    ? new Date(
                        summary?.decharacterizationDate
                      ).toLocaleDateString()
                    : '-',
                  CoordinateSystem: summary?.datumName ?? '-',
                  Zone: summary?.utmZone ?? '-'
                });
              }}
            >
              <DivBaseInfo>
                <GroupText
                  title={translate('OperationalStatus')}
                  value={summary?.operationalStatus ?? '-'}
                />
                <GroupText
                  title={translate('Responsible')}
                  value={`${listStructureSummary?.getStructureSummary.responsible.name ?? ''}, João Arruda`}
                />
                <GroupText
                  title={translate('operationStart')}
                  value={
                    summary?.operationStart
                      ? new Date(summary?.operationStart).toLocaleDateString()
                      : '-'
                  }
                />
                <GroupText
                  title={translate('operationEnd')}
                  value={
                    summary?.operationEnd
                      ? new Date(summary?.operationEnd).toLocaleDateString()
                      : '-'
                  }
                />
              </DivBaseInfo>
            </ContentActionCard>
          </>
        }
        cards={
          <DivCards>
            <SquareCard
              label={translate('AssociatedPotentialHarm')}
              value={
                summary?.potentialDamage
                  ? translate(`${summary?.potentialDamage}`)
                  : '-'
              }
            />
            <SquareCard
              label={translate('RiskRating')}
              value={
                summary?.riskClassification
                  ? translate(`${summary?.riskClassification}`)
                  : '-'
              }
            />
            <SquareCard
              label={translate('CrestLength')}
              value={geometry?.crestLength?.toString() ?? '-'}
            />
            <SquareCard
              label={translate('MaximumHeight')}
              value={summary?.maxHeight?.toString() ?? '-'}
            />
            <SquareCard
              label={translate('ANMClassification')}
              value={
                summary?.ANMClassification
                  ? translate(`${summary?.ANMClassification}`)
                  : '-'
              }
            />
          </DivCards>
        }
        history={
          <>
            <Text type={'h4'} color="black">
              {translate('ChangeHistory')}
            </Text>
            <TableComponent
              tableData={listHistory?.slice(0, 1) || []}
              columns={columnsHistory}
            />
            {listHistory && listHistory.length > 1 && (
              <DivButton>
                <Button
                  variant={'secondary'}
                  text={translate('seeAll')}
                  size="small"
                  onClick={() => {
                    setModalHistoryIsOpen(true);
                    setTitleModal(translate('ChangeHistory'));
                  }}
                />
              </DivButton>
            )}
          </>
        }
        map={
          <MapArea>
            <MapGoogle />
          </MapArea>
        }
        footerData={
          <DivContainerFooterData>
            <ContentActionCard
              title={`${translate('RiskMapping')}`}
              action={() => {
                setTitleModal(translate('RiskMapping'));
                setModalIsOpen(true);
                setDataModal({
                  downstreamEnvironmentalInterest:
                    riskAssessment?.downstreamEnvironmentalInterest ?? '-',
                  downstreamHumanOccupation:
                    riskAssessment?.downstreamHumanOccupation ?? '-',
                  downstreamInstallations:
                    riskAssessment?.downstreamInstallations ?? '-',
                  wasteType: riskAssessment?.wasteType ?? '-'
                });
              }}
            >
              <GroupText
                title={translate('downstreamEnvironmentalInterest')}
                value={riskAssessment?.downstreamEnvironmentalInterest ?? '-'}
                maxLength={20}
              />
              <GroupText
                title={translate('downstreamHumanOccupation')}
                value={riskAssessment?.downstreamHumanOccupation ?? '-'}
                maxLength={20}
              />
              <GroupText
                title={translate('downstreamInstallations')}
                value={riskAssessment?.downstreamInstallations ?? '-'}
                maxLength={20}
              />
              <GroupText
                title={translate('wasteType')}
                value={riskAssessment?.wasteType ?? '-'}
                maxLength={20}
              />
            </ContentActionCard>
            <ContentActionCard
              title={`${translate('Hydrology')}`}
              action={() => {
                setTitleModal(translate('Hydrology'));
                setModalIsOpen(true);
                setDataModal({
                  NAMaximoMaximorum: hydrologyInfos?.NAMaximoMaximorum
                    ? `${hydrologyInfos?.NAMaximoMaximorum} m`
                    : '-',
                  outflowRate: hydrologyInfos?.outflowRate
                    ? `${hydrologyInfos?.outflowRate} m`
                    : '-',
                  criticalDuration: hydrologyInfos?.criticalDuration
                    ? `${hydrologyInfos?.criticalDuration} h`
                    : '-',
                  returnPeriod: hydrologyInfos?.returnPeriod
                    ? `${hydrologyInfos?.returnPeriod}`
                    : '-',
                  volumeTotalElevationCrest:
                    hydrologyInfos?.volumeTotalElevationCrest
                      ? `${hydrologyInfos?.volumeTotalElevationCrest} m²`
                      : '-',
                  projectRainfall: hydrologyInfos?.projectRainfall
                    ? `${hydrologyInfos?.projectRainfall} mm`
                    : '-',
                  inflowRate: hydrologyInfos?.inflowRate
                    ? `${hydrologyInfos?.inflowRate} m³/s`
                    : '-',
                  spillwayType: hydrologyInfos?.spillwayType
                    ? `${hydrologyInfos?.spillwayType}`
                    : '-',
                  reservoirContributionArea:
                    hydrologyInfos?.reservoirContributionArea
                      ? `${hydrologyInfos?.reservoirContributionArea} km²`
                      : '-'
                });
              }}
            >
              <GroupText
                title={translate('NAMaximoMaximorum')}
                value={
                  hydrologyInfos?.NAMaximoMaximorum
                    ? `${hydrologyInfos?.NAMaximoMaximorum} m`
                    : '-'
                }
              />
              <GroupText
                title={translate('outflowRate')}
                value={
                  hydrologyInfos?.outflowRate
                    ? `${hydrologyInfos?.outflowRate} m`
                    : '-'
                }
              />
              <GroupText
                title={translate('criticalDuration')}
                value={
                  hydrologyInfos?.criticalDuration
                    ? `${hydrologyInfos?.criticalDuration} h`
                    : '-'
                }
              />
              <GroupText
                title={translate('returnPeriod')}
                value={hydrologyInfos?.returnPeriod ?? '-'}
              />
            </ContentActionCard>
            <ContentActionCard
              title={`${translate('MassiveGeometry')}`}
              action={() => {
                setTitleModal(translate('MassiveGeometry'));
                setModalIsOpen(true);
                setDataModal({
                  bermLength: geometry?.bermLength
                    ? `${geometry?.bermLength} m`
                    : '-',
                  crestElevation: geometry?.crestElevation
                    ? `${geometry?.crestElevation} m`
                    : '-',
                  crestLength: geometry?.crestLength
                    ? `${geometry?.crestLength} m`
                    : '-',
                  crestWidth: geometry?.crestWidth
                    ? `${geometry?.crestWidth} m`
                    : '-',
                  globalSlopeDownstream: geometry?.globalSlopeDownstream ?? '-',
                  slopeBetweenBerms: geometry?.slopeBetweenBerms ?? '-',
                  upstreamSlopeInclination:
                    geometry?.upstreamSlopeInclination ?? '-'
                });
              }}
            >
              <GroupText
                title={translate('crestElevation')}
                value={
                  geometry?.crestElevation
                    ? `${geometry?.crestElevation} m`
                    : '-'
                }
                maxLength={20}
              />
              <GroupText
                title={translate('globalSlopeDownstream')}
                value={geometry?.globalSlopeDownstream ?? '-'}
                maxLength={20}
              />
              <GroupText
                title={translate('slopeBetweenBerms')}
                value={geometry?.slopeBetweenBerms ?? '-'}
                maxLength={20}
              />
              <GroupText
                title={translate('upstreamSlopeInclination')}
                value={geometry?.upstreamSlopeInclination ?? '-'}
                maxLength={20}
              />
            </ContentActionCard>
            {!summary?.decharacterizationDate && (
              <ContentActionCard
                title={`${translate('ReservoirManagement')}`}
                action={() => {
                  setTitleModal(translate('ReservoirManagement'));
                  setModalIsOpen(true);
                  setDataModal({
                    reservoirOccupiedArea:
                      reservoirHandling?.reservoirOccupiedArea
                        ? `${reservoirHandling?.reservoirOccupiedArea} m²`
                        : '-',
                    reservoirOccupiedVolume:
                      reservoirHandling?.reservoirOccupiedVolume
                        ? `${reservoirHandling?.reservoirOccupiedVolume} m³`
                        : '-',
                    reservoirTotalVolume:
                      reservoirHandling?.reservoirTotalVolume
                        ? `${reservoirHandling?.reservoirTotalVolume} m³`
                        : '-',
                    tailingVolumeHistory: reservoirHandling
                      ?.tailingVolumeHistory.length
                      ? new Date(
                          reservoirHandling?.tailingVolumeHistory.filter(
                            (item) => item.date
                          )[0].date
                        ).toLocaleDateString()
                      : '-',
                    waterVolume: reservoirHandling?.waterVolume
                      ? `${reservoirHandling?.waterVolume} m³`
                      : '-'
                  });
                }}
              >
                <GroupText
                  title={translate('reservoirOccupiedArea')}
                  value={
                    reservoirHandling?.reservoirOccupiedArea
                      ? `${reservoirHandling?.reservoirOccupiedArea} m²`
                      : '-'
                  }
                />
                <GroupText
                  title={translate('reservoirOccupiedVolume')}
                  value={
                    reservoirHandling?.reservoirOccupiedVolume
                      ? `${reservoirHandling?.reservoirOccupiedVolume} m³`
                      : '-'
                  }
                />
                <GroupText
                  title={translate('reservoirTotalVolume')}
                  value={
                    reservoirHandling?.reservoirTotalVolume
                      ? `${reservoirHandling?.reservoirTotalVolume} m³`
                      : '-'
                  }
                />
                <GroupText
                  title={translate('tailingVolumeHistory')}
                  value={
                    reservoirHandling?.tailingVolumeHistory.length
                      ? new Date(
                          reservoirHandling?.tailingVolumeHistory.filter(
                            (item) => item.date
                          )[0].date
                        ).toLocaleDateString()
                      : '-'
                  }
                />
              </ContentActionCard>
            )}
          </DivContainerFooterData>
        }
      />
      <ViewModal
        showModal={modalIsOpen}
        title={titleModal}
        onClose={() => setModalIsOpen(false)}
        component={<GeneralPageModalData generalPageData={dataModal} />}
      />
      <ViewModal
        showModal={modalHistoryIsOpen}
        title={titleModal}
        onClose={() => setModalHistoryIsOpen(false)}
        component={
          <TableComponent
            tableData={listHistory || []}
            columns={columnsHistory}
          />
        }
      />
    </>
  );
};

export default GeneralPage;
