import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import {
  AddButtonSkeleton,
  CellSkeleton,
  MainScreenSkeleton,
  PageContainer,
  RowSkeleton,
  TableSkeleton,
  TitleSkeleton
} from './SkeletonNewAtoPageStyles';

const SkeletonNewAtoPage: React.FC = () => {
  return (
    <MainScreenSkeleton>
      <TitleSkeleton>
        <Skeleton height={40} width={300} />
      </TitleSkeleton>
      <PageContainer>
        <TableSkeleton>
          <Skeleton height={300} />
        </TableSkeleton>
        <TableSkeleton>
          <AddButtonSkeleton>
            <Skeleton width={200} height={20} />
            <Skeleton width={100} height={20} />
          </AddButtonSkeleton>
          <Skeleton height={30} />
          <RowSkeleton>
            <CellSkeleton>
              <Skeleton height={30} />
            </CellSkeleton>
            <CellSkeleton>
              <Skeleton height={30} />
            </CellSkeleton>
            <CellSkeleton>
              <Skeleton height={30} />
            </CellSkeleton>
            <CellSkeleton>
              <Skeleton height={30} />
            </CellSkeleton>
          </RowSkeleton>
          <RowSkeleton>
            <CellSkeleton>
              <Skeleton height={30} />
            </CellSkeleton>
            <CellSkeleton>
              <Skeleton height={30} />
            </CellSkeleton>
            <CellSkeleton>
              <Skeleton height={30} />
            </CellSkeleton>
            <CellSkeleton>
              <Skeleton height={30} />
            </CellSkeleton>
          </RowSkeleton>
        </TableSkeleton>
        <TableSkeleton>
          <AddButtonSkeleton>
            <Skeleton width={200} height={20} />
            <Skeleton width={100} height={20} />
          </AddButtonSkeleton>
          <Skeleton height={30} />
          <RowSkeleton>
            <CellSkeleton>
              <Skeleton height={30} />
            </CellSkeleton>
            <CellSkeleton>
              <Skeleton height={30} />
            </CellSkeleton>
            <CellSkeleton>
              <Skeleton height={30} />
            </CellSkeleton>
            <CellSkeleton>
              <Skeleton height={30} />
            </CellSkeleton>
          </RowSkeleton>
          <RowSkeleton>
            <CellSkeleton>
              <Skeleton height={30} />
            </CellSkeleton>
            <CellSkeleton>
              <Skeleton height={30} />
            </CellSkeleton>
            <CellSkeleton>
              <Skeleton height={30} />
            </CellSkeleton>
            <CellSkeleton>
              <Skeleton height={30} />
            </CellSkeleton>
          </RowSkeleton>
        </TableSkeleton>
      </PageContainer>
    </MainScreenSkeleton>
  );
};

export default SkeletonNewAtoPage;
