import {
  AdvanceInstrumentButton,
  InstrumentButtonInterface,
  InstrumentSelectInterface
} from '../../@Types/Instruments/instruments';
class ReadingsController {
  static Register = () => {};

  static ShowModal({ showModal, setShowModal }: InstrumentButtonInterface) {
    if (setShowModal) !showModal ? setShowModal(true) : setShowModal(false);
  }

  static AdvanceScreen({
    step,
    instrumentSelected,
    setStep
  }: AdvanceInstrumentButton) {
    if (instrumentSelected && step === 1) {
      setStep(2);
      return true;
    } else {
      return false;
    }
  }
}

export default ReadingsController;
