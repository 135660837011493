import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type EditPiezometerAlertMutationVariables = Types.Exact<{
  structureInfo: Types.StructureInfoInput;
  data: Types.EditPiezometerAlertInput;
}>;


export type EditPiezometerAlertMutation = { __typename?: 'Mutation', editPiezometerAlert: string };


export const EditPiezometerAlertDocument = gql`
    mutation EditPiezometerAlert($structureInfo: StructureInfoInput!, $data: EditPiezometerAlertInput!) {
  editPiezometerAlert(structureInfo: $structureInfo, data: $data)
}
    `;
export type EditPiezometerAlertMutationFn = Apollo.MutationFunction<EditPiezometerAlertMutation, EditPiezometerAlertMutationVariables>;
export type EditPiezometerAlertMutationResult = Apollo.MutationResult<EditPiezometerAlertMutation>;
export type EditPiezometerAlertMutationOptions = Apollo.BaseMutationOptions<EditPiezometerAlertMutation, EditPiezometerAlertMutationVariables>;