import { useTranslation } from 'react-i18next';
import {
  InstalationLocaleInterface,
  InstrumentSelectInterface,
  ReadModeInterface
} from '../@Types/Instruments/instruments';

export type InstrumentsType =
  | 'Piezometer'
  | 'INA'
  | 'Pluviometer'
  | 'WaterLevel'
  | 'FlowMeter'
  | 'SurfaceMarker';
export type ReadModeType = 'Automatic' | 'Manual';
export type InstalationLocaleType =
  | 'Foundation'
  | 'Massive'
  | 'Shoulder'
  | 'DrainingMat'
  | 'VerticalFilter'
  | 'Other';

const InstrumentsModel = () => {
  const { t } = useTranslation();
  const types: InstrumentSelectInterface[] = [
    { label: t('Piezometer'), id: 'Piezometer', value: 'Piezometer', unt: 'm' },
    { label: t('INA'), id: 'INA', value: 'INA', unt: 'm' },
    {
      label: t('Pluviometer'),
      id: 'Pluviometer',
      value: 'Pluviometer',
      unt: 'mm'
    },
    { label: t('WaterLevel'), id: 'WaterLevel', value: 'WaterLevel', unt: 'm' },
    // { label: t("FlowMeter"), id: "FlowMeter", value: "FlowMeter", unt: "l/s" },
    {
      label: t('SurfaceMarker'),
      id: 'SurfaceMarker',
      value: 'SurfaceLandmark',
      unt: ['d/mm', 'v']
    }
  ];

  const readingMode: ReadModeInterface[] = [
    { label: t('Automatic'), id: 'Automatic', value: 'Automatic' },
    { label: t('Manual'), id: 'Manual', value: 'Manual' }
  ];

  const instalationLocale: InstalationLocaleInterface[] = [
    { label: t('Foundation'), id: 'Foundation', value: 'Foundation' },
    { label: t('Massive'), id: 'Massive', value: 'Massive' },
    { label: t('Shoulder'), id: 'Shoulder', value: 'Shoulder' },
    { label: t('DrainingMat'), id: 'DrainingMat', value: 'DrainingMat' },
    {
      label: t('VerticalFilter'),
      id: 'VerticalFilter',
      value: 'VerticalFilter'
    },
    { label: t('Other'), id: 'Other', value: 'CustomAction', action: () => {} }
  ];

  return { types, readingMode, instalationLocale };
};

export default InstrumentsModel;
