import { useTranslation } from 'react-i18next';
import { IoIosClose } from 'react-icons/io';
import { GraphHeaderInterface } from '../../../../../@Types/Instruments/instruments';
import GenerateGraph from '../../../../../Controllers/Instruments/GenerateGraph';
import { CloseIconStyle, GrapHeaderContainer } from './GraphHeader.style';

export default function GraphHeader({
  showModal,
  setShowModal,
  title,
  subtitle,
  showCloseIcon
}: GraphHeaderInterface) {
  const { t } = useTranslation();

  return (
    <GrapHeaderContainer>
      <h3>
        {t(title)} {subtitle ? `- ${t(subtitle)}` : null}
      </h3>
      {showCloseIcon && (
        <IoIosClose
          size={35}
          onClick={() => {
            GenerateGraph.ShowModal({ showModal, setShowModal });
          }}
          style={CloseIconStyle}
        />
      )}
    </GrapHeaderContainer>
  );
}
