import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type DeleteAtoDocsMutationVariables = Types.Exact<{
  deleteAtoDocsId: Types.Scalars['String']['input'];
}>;


export type DeleteAtoDocsMutation = { __typename?: 'Mutation', deleteAtoDocs: string };


export const DeleteAtoDocsDocument = gql`
    mutation DeleteAtoDocs($deleteAtoDocsId: String!) {
  deleteAtoDocs(id: $deleteAtoDocsId)
}
    `;
export type DeleteAtoDocsMutationFn = Apollo.MutationFunction<DeleteAtoDocsMutation, DeleteAtoDocsMutationVariables>;
export type DeleteAtoDocsMutationResult = Apollo.MutationResult<DeleteAtoDocsMutation>;
export type DeleteAtoDocsMutationOptions = Apollo.BaseMutationOptions<DeleteAtoDocsMutation, DeleteAtoDocsMutationVariables>;