import { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VscKebabVertical } from 'react-icons/vsc';
import { useOnClickOutside } from 'usehooks-ts';
import { ProjectContext } from '../../../../Context/ContextAPI';
import { ModuleData } from '../../../Map/types';
import Icon from '../../Atoms/Icon/Icon';
import {
  ActionMenuProps,
  AvailableContextActionsProps,
  DataDrivenPermissionsActionsMap
} from './ActionMenu.interface';
import {
  ActionMenuItem,
  DivContainerActionMenu,
  Menu
} from './ActionMenu.styles';

const ActionMenu = <T,>({
  availableContextActions,
  dataDrivenPermissions,
  dataActions
}: ActionMenuProps<T>) => {
  const [showMenu, setShowMenu] = useState(false);
  const { t: translate } = useTranslation();
  const ref = useRef(null);
  const { modulesByStructure } = useContext(ProjectContext) as {
    modulesByStructure: ModuleData[];
  };
  const modulePermission = modulesByStructure?.find((module) =>
    availableContextActions?.some(
      (action) => module.moduleName === action.permission?.module
    )
  )?.role;

  const handleClickOutside = () => {
    setShowMenu(false);
  };

  useOnClickOutside(ref, handleClickOutside);

  const checkActionPermission = (
    action: AvailableContextActionsProps<T>,
    permissions?: DataDrivenPermissionsActionsMap
  ) => {
    return permissions?.[action.canShow];
  };

  const isActionAllowed = (
    action: AvailableContextActionsProps<T>,
    canViewAction: boolean | undefined
  ) => {
    const hasModulePermission =
      action.permission && action.permission?.permission === modulePermission;
    const canShowBasedOnDataDriven =
      canViewAction || canViewAction === undefined;

    return hasModulePermission || canShowBasedOnDataDriven;
  };

  return (
    <DivContainerActionMenu ref={ref}>
      <Icon Icon={VscKebabVertical} onClick={() => setShowMenu(!showMenu)} />
      {showMenu && (
        <Menu>
          {availableContextActions?.map((action, index) => {
            const canViewAction = checkActionPermission(
              action,
              dataDrivenPermissions
            );

            if (isActionAllowed(action, canViewAction)) {
              return (
                <ActionMenuItem
                  key={index}
                  onClick={() => action.onClick(dataActions)}
                >
                  {action.icon}
                  {translate(action.name)}
                </ActionMenuItem>
              );
            }

            return null;
          })}
        </Menu>
      )}
    </DivContainerActionMenu>
  );
};

export default ActionMenu;
