import { useQuery } from '@apollo/client';
import {
  ListUsersDocument,
  ListUsersQuery,
  ListUsersQueryVariables
} from '../../graphql/query/generated/listUsers.query';

export function useListUsers(
  dataUsers: boolean,
  onCompleted?: () => void,
  onError?: () => void
) {
  const { data, loading, error } = useQuery<
    ListUsersQuery,
    ListUsersQueryVariables
  >(ListUsersDocument, {
    variables: {
      data: {
        listAllUsers: dataUsers
      }
    },
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    data,
    loading,
    error
  };
}
