import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type FindAllPsbDataQueryVariables = Types.Exact<{
  structureId: Types.Scalars['String']['input'];
}>;


export type FindAllPsbDataQuery = { __typename?: 'Query', findAllPsbData: Array<{ __typename?: 'PsbDataType', id: string, name: string, path: string, nested?: Array<{ __typename?: 'PsbDataType', id: string, name: string, path: string, nested?: Array<{ __typename?: 'PsbDataType', id: string, name: string, path: string, nested?: Array<{ __typename?: 'PsbDataType', id: string, name: string, path: string, nested?: Array<{ __typename?: 'PsbDataType', id: string, name: string, path: string }> | null }> | null }> | null }> | null }> };


export const FindAllPsbDataDocument = gql`
    query FindAllPsbData($structureId: String!) {
  findAllPsbData(structureId: $structureId) {
    id
    name
    path
    nested {
      id
      name
      path
      nested {
        id
        name
        path
        nested {
          id
          name
          path
          nested {
            id
            name
            path
          }
        }
      }
    }
  }
}
    `;
export type FindAllPsbDataQueryResult = Apollo.QueryResult<FindAllPsbDataQuery, FindAllPsbDataQueryVariables>;