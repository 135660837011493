import { useQuery } from '@apollo/client';
import {
  ListModulesRolesDocument,
  ListModulesRolesQuery,
  ListModulesRolesQueryVariables
} from '../../graphql/query/generated/listModulesRole.query';

export function useListModulesRole(
  onCompleted?: () => void,
  onError?: () => void
) {
  const { data, loading, error } = useQuery<
    ListModulesRolesQuery,
    ListModulesRolesQueryVariables
  >(ListModulesRolesDocument, {
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    data,
    loading,
    error
  };
}
