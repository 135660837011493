import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type UpdateRecordWorkShiftMutationVariables = Types.Exact<{
  data: Types.UpdateRecordWorkShiftInput;
}>;


export type UpdateRecordWorkShiftMutation = { __typename?: 'Mutation', updateRecordWorkShift: string };


export const UpdateRecordWorkShiftDocument = gql`
    mutation UpdateRecordWorkShift($data: UpdateRecordWorkShiftInput!) {
  updateRecordWorkShift(data: $data)
}
    `;
export type UpdateRecordWorkShiftMutationFn = Apollo.MutationFunction<UpdateRecordWorkShiftMutation, UpdateRecordWorkShiftMutationVariables>;
export type UpdateRecordWorkShiftMutationResult = Apollo.MutationResult<UpdateRecordWorkShiftMutation>;
export type UpdateRecordWorkShiftMutationOptions = Apollo.BaseMutationOptions<UpdateRecordWorkShiftMutation, UpdateRecordWorkShiftMutationVariables>;