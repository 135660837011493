import { t } from 'i18next';
import {
  toastfyDimiss,
  toastfyError,
  toastfySuccess,
  toastifyLoading
} from '../../Components/Toastify';
import ToastifyModel from '../../Models/ToastifyModel';
import { API_URL } from '../../utils/const';
import { client } from '../apollo.client';
import { FindAllGistmDataDocument } from '../graphql/query/generated/findAllGistmData.query';

export const sendGISTMFile = async (
  formData: FormData,
  structureId: string
) => {
  try {
    if (!formData) {
      throw new Error(t(ToastifyModel().toastifyMessage.fillRequiredFields));
    }

    toastifyLoading(`${t('registering')} ${t('GISTM')}...`);

    const user = localStorage.getItem('user');
    const token = user ? JSON.parse(user).token : '';

    const response = await fetch(
      `${API_URL}/gistm/upload/files/${structureId}`,
      {
        method: 'POST',
        body: formData,
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: token
        }
      }
    );
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    toastfyDimiss('toastLoading');
    toastfySuccess(`${t('GISTM')} ${t('registeredSuccessfully')}!`);

    return response.status;
  } catch (error) {
    console.error(error);
    toastfyDimiss('toastLoading');
    toastfyError(t(ToastifyModel().toastifyMessage.error));
  }
};

export const findAllGISTMData = async (structureId: string) => {
  try {
    const response = await client.query({
      query: FindAllGistmDataDocument,
      variables: {
        structureId
      }
    });
    return response.data;
  } catch (error) {
    toastfyDimiss('toastLoading');
    toastfyError(t(ToastifyModel().toastifyMessage.error));
  }
};

export const downloadGISTMFile = (row: any) => {
  fetch(row.url)
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const a = document.createElement('a');
      a.href = url;
      a.download = row.file;
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    });
};
