import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ListStructureAndModulesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ListStructureAndModulesQuery = { __typename?: 'Query', listStructureAndModules: Array<{ __typename?: 'ListStructureModulesType', id: string, structureName: string, moduleName: string, end: Date, start: Date, status: boolean }> };


export const ListStructureAndModulesDocument = gql`
    query ListStructureAndModules {
  listStructureAndModules {
    id
    structureName
    moduleName
    end
    start
    status
  }
}
    `;
export type ListStructureAndModulesQueryResult = Apollo.QueryResult<ListStructureAndModulesQuery, ListStructureAndModulesQueryVariables>;