import { useQuery } from '@apollo/client';
import {
  FindAllInspectionsByStructureDocument,
  FindAllInspectionsByStructureQuery,
  FindAllInspectionsByStructureQueryVariables
} from '../graphql/query/generated/findAllInspectionsByStructure.query';

export function useFindAllInspectionsByStructure(
  structureId: string,
  onCompleted?: () => void,
  onError?: () => void
) {
  const { data, loading, error } = useQuery<
    FindAllInspectionsByStructureQuery,
    FindAllInspectionsByStructureQueryVariables
  >(FindAllInspectionsByStructureDocument, {
    variables: {
      structureId
    },
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    data,
    loading,
    error
  };
}
