import { useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import {
  ListNotificationsByModulePagDocument,
  ListNotificationsByModulePagQuery,
  ListNotificationsByModulePagQueryVariables
} from '../../../../data/graphql/query/generated/listNotificationsByModulePag.query';
import { useMutation, useQuery } from '@apollo/client';
import NotificationAccordion from '../../Molecules/NotificationAccordion/NotificationAccordion';
import {
  DivContainer,
  DivFilter,
  DivNotification,
  DivPagination
} from './Notification.styles';
import Paginator from '../../Molecules/Paginator/Paginator';
import {
  SetNotificationUnreadStatusDocument,
  SetNotificationUnreadStatusMutation,
  SetNotificationUnreadStatusMutationVariables
} from '../../../../data/graphql/generated/setNotificationUnreadStatus';
import {
  NotificationType,
  NotificationTypeEnum
} from './Notification.interface';
import ErrorsTreatments from '../../../../utils/errorTreatment';
import { toastfyError } from '../../../Toastify';
import { useTranslation } from 'react-i18next';
import InputSelectSearch from '../../Molecules/InputSelectSearch/InputSelectSearch';
import DatepickerInput from '../../Molecules/DatepickerInput/DatepickerInput';

const Notification = () => {
  const [searchParam] = useSearchParams();
  const notificationModuleId = searchParam.get('notification') || '';
  const notificationModule = searchParam.get('module') || '';
  const { t: translate } = useTranslation();

  const notificationType: { [key: string]: NotificationTypeEnum } = {
    ActionPlan: NotificationTypeEnum.ActionPlanNotification
  };

  const getNotificationType = (
    module: string
  ): NotificationTypeEnum | undefined => {
    return notificationType[module];
  };

  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalForPage: 10
  });
  const [filters, setFilters] = useState<{
    unread: { value: string; label: string };
    dateRange: (Date | undefined)[];
  }>({
    unread: {
      value: '',
      label: ''
    },
    dateRange: []
  });

  const { data: notifications } = useQuery<
    ListNotificationsByModulePagQuery,
    ListNotificationsByModulePagQueryVariables
  >(ListNotificationsByModulePagDocument, {
    variables: {
      moduleId: notificationModuleId,
      pageInfo: {
        limit: pagination.totalForPage,
        page: pagination.currentPage
      },
      filters: {
        unread:
          filters.unread.value === ''
            ? undefined
            : filters.unread.value === 'Yes'
              ? true
              : false,
        dateRange:
          filters.dateRange[0] !== undefined &&
          filters.dateRange[1] !== undefined
            ? [
                new Date(filters.dateRange[0]!.setHours(0, 0, 0, 0)),
                new Date(filters.dateRange[1]!.setHours(23, 59, 59, 999))
              ]
            : undefined
      }
    },
    onError: ({ graphQLErrors }) => {
      const errorMessege = ErrorsTreatments(
        graphQLErrors[0].message,
        translate
      );
      toastfyError(errorMessege);
    },
    fetchPolicy: 'no-cache'
  });

  const [setNotificationUnread] = useMutation<
    SetNotificationUnreadStatusMutation,
    SetNotificationUnreadStatusMutationVariables
  >(SetNotificationUnreadStatusDocument);

  const handlePageChange = (newPage: number) => {
    setPagination((prev) => ({ ...prev, currentPage: newPage }));
  };

  const handleNotificationClick = (notification: NotificationType) => {
    if (notification.unread) return;
    setNotificationUnread({
      variables: {
        notificationId: notification.contentId,
        notificationModuleType: getNotificationType(notificationModule)
      },
      onError: ({ graphQLErrors }) => {
        const errorMessege = ErrorsTreatments(
          graphQLErrors[0].message,
          translate
        );
        toastfyError(errorMessege);
      },
      refetchQueries: [ListNotificationsByModulePagDocument]
    });
  };

  return (
    <DivContainer>
      <DivFilter>
        <InputSelectSearch
          name="unread"
          label={translate('unread')}
          placeholder={translate('select')}
          width="100px"
          options={[
            { value: 'Yes', label: translate('Yes') },
            { value: 'No', label: translate('No') },
            { value: '', label: translate('all') }
          ]}
          value={filters.unread}
          onChange={(value) =>
            setFilters((prev) => ({
              ...prev,
              unread: { value: value.value.toString(), label: value.label }
            }))
          }
        />
        <DatepickerInput
          width="150px"
          placeholder={translate('select')}
          label={translate('InitialDate')}
          time={false}
          name="initialDate"
          onChange={(value) =>
            setFilters((prev) => ({
              ...prev,
              dateRange: [value, prev.dateRange[1]]
            }))
          }
        />
        <DatepickerInput
          width="150px"
          placeholder={translate('select')}
          label={translate('EndDate')}
          time={false}
          name="endDate"
          onChange={(value) =>
            setFilters((prev) => ({
              ...prev,
              dateRange: [prev.dateRange[0], value]
            }))
          }
        />
      </DivFilter>
      <DivNotification>
        <NotificationAccordion
          notifications={
            notifications?.listNotificationsByModulePag.nodes.map(
              (notification) => ({
                contentId: notification.contentId,
                createdAt: new Date(
                  notification.createdAt
                ).toLocaleDateString(),
                body: notification.body ?? '',
                unread: !notification.unread
              })
            ) || []
          }
          onClick={handleNotificationClick}
        />
      </DivNotification>
      <DivPagination>
        <Paginator
          options={[
            { value: 10, label: '10' },
            { value: 50, label: '50' },
            { value: 100, label: '100' }
          ]}
          totalPages={
            notifications?.listNotificationsByModulePag.pageInfo.totalPages || 0
          }
          showInUrl={false}
          pageState={{
            totalForPageValue: {
              value: pagination.totalForPage,
              label: pagination.totalForPage.toString()
            },
            totalPages:
              notifications?.listNotificationsByModulePag.pageInfo.totalPages ||
              0,
            currentPage: pagination.currentPage,
            totalForPage: (value) =>
              setPagination((prev) => ({
                ...prev,
                totalForPage: Number(value.value)
              })),
            nextPage: () => handlePageChange(pagination.currentPage + 1),
            previousPage: () => handlePageChange(pagination.currentPage - 1)
          }}
        />
      </DivPagination>
    </DivContainer>
  );
};

export default Notification;
