import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type LoginMutationVariables = Types.Exact<{
  data: Types.LoginInput;
}>;

export type LoginMutation = {
  __typename?: 'Mutation';
  login: {
    __typename?: 'Login';
    token: string;
    isLoggedWithTemporaryPassword: boolean;
    hasAcceptedTerms: boolean;
    user: {
      __typename?: 'User';
      name: string;
      id: string;
      email: string;
      role: { __typename?: 'RoleType'; role: string };
    };
  };
};

export const LoginDocument = gql`
  mutation Login($data: LoginInput!) {
    login(data: $data) {
      token
      isLoggedWithTemporaryPassword
      hasAcceptedTerms
      user {
        role {
          role
        }
        name
        id
        email
      }
    }
  }
`;

export type LoginMutationFn = Apollo.MutationFunction<
  LoginMutation,
  LoginMutationVariables
>;

export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;

export type LoginMutationOptions = Apollo.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>;
