import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ExportInstrumentsByStructureQueryVariables = Types.Exact<{
  instrumentType: Types.InstrumentTypeEnum;
  structureId: Types.Scalars['String']['input'];
  selectedFields:
    | Array<Types.Scalars['String']['input']>
    | Types.Scalars['String']['input'];
  filters?: Types.InputMaybe<Types.ListInstrumentsByStructureFilterInput>;
}>;

export type ExportInstrumentsByStructureQuery = {
  __typename?: 'Query';
  exportInstrumentsByStructure: string;
};

export const ExportInstrumentsByStructureDocument = gql`
  query exportInstrumentsByStructure(
    $instrumentType: InstrumentTypeEnum!
    $structureId: String!
    $selectedFields: [String!]!
    $filters: ListInstrumentsByStructureFilterInput
  ) {
    exportInstrumentsByStructure(
      instrumentType: $instrumentType
      structureId: $structureId
      selectedFields: $selectedFields
      filters: $filters
    )
  }
`;

export type ExportInstrumentsByStructureQueryResult = Apollo.QueryResult<
  ExportInstrumentsByStructureQuery,
  ExportInstrumentsByStructureQueryVariables
>;
