import styled from 'styled-components';

export const DivContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  background-color: white;
  padding: 5px 30px 30px 30px;
  box-sizing: border-box;
  overflow: auto;
  row-gap: 20px;
`;

export const DivHolderChild = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
`;

export const DivHolderButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  row-gap: 20px;
`;
