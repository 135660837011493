import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type CreatePluviometerMutationVariables = Types.Exact<{
  structureInfo: Types.StructureInfoInput;
  alertLevels: Array<Types.PluviometerAlertLevelInput> | Types.PluviometerAlertLevelInput;
  generalInfo: Types.CreateInstrumentInput;
  specificInfo: Types.BasePluviometerInput;
}>;


export type CreatePluviometerMutation = { __typename?: 'Mutation', createPluviometer: string };


export const CreatePluviometerDocument = gql`
    mutation CreatePluviometer($structureInfo: StructureInfoInput!, $alertLevels: [PluviometerAlertLevelInput!]!, $generalInfo: CreateInstrumentInput!, $specificInfo: BasePluviometerInput!) {
  createPluviometer(
    structureInfo: $structureInfo
    alertLevels: $alertLevels
    generalInfo: $generalInfo
    specificInfo: $specificInfo
  )
}
    `;
export type CreatePluviometerMutationFn = Apollo.MutationFunction<CreatePluviometerMutation, CreatePluviometerMutationVariables>;
export type CreatePluviometerMutationResult = Apollo.MutationResult<CreatePluviometerMutation>;
export type CreatePluviometerMutationOptions = Apollo.BaseMutationOptions<CreatePluviometerMutation, CreatePluviometerMutationVariables>;