import { useTranslation } from 'react-i18next';
import { Color } from '../../../../Styles/Styles';
import Text from '../../Atoms/Text/Text';
import { WorkScheduleTableProps } from './WorkScheduleTable.interface';
import {
  LabelTdATOBold,
  TableATO,
  TableCellATOFour,
  TableCellATOOutput,
  TableHeaderATO,
  TableRowATO
} from './WorkScheduleTable.styles';

const WorkScheduleTable = ({ workShifts }: WorkScheduleTableProps) => {
  const { t } = useTranslation();
  return (
    <TableATO>
      <TableRowATO
        style={{ backgroundColor: Color.Brown3, color: Color.Brown1 }}
      >
        <TableHeaderATO>
          <Text type="p">{t('turn')}</Text>
        </TableHeaderATO>
        <TableHeaderATO>
          <Text type="p">{t('beguing')}</Text>
        </TableHeaderATO>
        <TableHeaderATO>
          <Text type="p">{t('end')}</Text>
        </TableHeaderATO>
        <TableHeaderATO>
          <Text type="p">{t('workedHours')}</Text>
        </TableHeaderATO>
      </TableRowATO>
      <TableRowATO>
        <TableCellATOFour
          style={{
            backgroundColor: Color.Brown3,
            color: Color.Brown1,
            fontWeight: 'bold'
          }}
        >
          <Text type="p">
            <LabelTdATOBold>{t('entryExit')}</LabelTdATOBold>
          </Text>
        </TableCellATOFour>
        <TableCellATOOutput>{workShifts?.[0]?.entry}</TableCellATOOutput>
        <TableCellATOOutput>{workShifts?.[0]?.exit}</TableCellATOOutput>
        <TableCellATOOutput rowSpan={2}>
          {workShifts?.[0]?.totalHours}
        </TableCellATOOutput>
      </TableRowATO>
      <TableRowATO>
        <TableCellATOFour
          style={{
            backgroundColor: Color.Brown3,
            color: Color.Brown1,
            fontWeight: 'bold'
          }}
        >
          <Text type="p">
            <LabelTdATOBold>{t('interval')}</LabelTdATOBold>
          </Text>
        </TableCellATOFour>
        <TableCellATOOutput>
          {workShifts?.[0]?.entryInterval}
        </TableCellATOOutput>
        <TableCellATOOutput>{workShifts?.[0]?.exitInterval}</TableCellATOOutput>
      </TableRowATO>
    </TableATO>
  );
};

export default WorkScheduleTable;
