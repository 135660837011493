import { useState } from 'react';
import {
  Container,
  ContentWrapper,
  DrawerWrapper,
  PageWrapper,
  ToggleButton
} from './DrawerMenu.styles';
import Icon from '../../Atoms/Icon/Icon';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import Text from '../../Atoms/Text/Text';
import { DrawerMenuComonProps } from './DrawerMenu.interface';
import { useTranslation } from 'react-i18next';

const DrawerMenu = ({
  component,
  menu,
  title,
  width = 300
}: DrawerMenuComonProps) => {
  const { t: translate } = useTranslation();
  const [isDrawerOpen, setDrawerOpen] = useState(true);

  return (
    <PageWrapper isOpen={isDrawerOpen}>
      <ToggleButton
        isDrawerOpen={isDrawerOpen}
        width={width}
        onClick={() => setDrawerOpen((prev) => !prev)}
      >
        <Icon
          Icon={isDrawerOpen ? IoIosArrowBack : IoIosArrowForward}
          size={18}
        />
      </ToggleButton>
      <Container isOpen={isDrawerOpen} width={width}>
        <DrawerWrapper isOpen={isDrawerOpen} width={width}>
          {title && <Text type="h5">{translate(title)}</Text>}
          {menu}
        </DrawerWrapper>
      </Container>
      <ContentWrapper isDrawerOpen={isDrawerOpen} width={width}>
        {component}
      </ContentWrapper>
    </PageWrapper>
  );
};

export default DrawerMenu;
