import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ListPluviometersByStructureQueryVariables = Types.Exact<{
  structureInfo: Types.StructureInfoInput;
}>;


export type ListPluviometersByStructureQuery = { __typename?: 'Query', listPluviometersByStructure: Array<{ __typename?: 'PluviometerListType', status: boolean, readingType: string, pluviometerType?: string | null, operationalStatus: Types.InstrumentStatusEnum, name: string, isUTM: boolean, installationDate?: Date | null, installLocation?: string | null, id?: string | null, deactivationDate?: Date | null, coordinateN: number, coordinateE: number, activationDate?: Date | null, type?: { __typename?: 'InstrumentType', instrumentType: string, id: string } | null }> };


export const ListPluviometersByStructureDocument = gql`
    query ListPluviometersByStructure($structureInfo: StructureInfoInput!) {
  listPluviometersByStructure(structureInfo: $structureInfo) {
    type {
      instrumentType
      id
    }
    status
    readingType
    pluviometerType
    operationalStatus
    name
    isUTM
    installationDate
    installLocation
    id
    deactivationDate
    coordinateN
    coordinateE
    activationDate
  }
}
    `;
export type ListPluviometersByStructureQueryResult = Apollo.QueryResult<ListPluviometersByStructureQuery, ListPluviometersByStructureQueryVariables>;