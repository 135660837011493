import styled from 'styled-components';

export const HolderForm = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
`;

export const DivInputsArea = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: start;
  row-gap: 10px;
`;

export const DivButtonArea = styled.div`
  width: 100%;
  height: 20%;
  display: flex;
  align-items: end;
  justify-content: end;
  gap: 12.8px;
  margin-top: 20px;
  padding: 20px 0;
  box-sizing: border-box;
`;
