import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type EditPiezometerControlCoteMutationVariables = Types.Exact<{
  structureInfo: Types.StructureInfoInput;
  data: Types.EditPiezometerControlCoteInput;
}>;


export type EditPiezometerControlCoteMutation = { __typename?: 'Mutation', editPiezometerControlCote: string };


export const EditPiezometerControlCoteDocument = gql`
    mutation EditPiezometerControlCote($structureInfo: StructureInfoInput!, $data: EditPiezometerControlCoteInput!) {
  editPiezometerControlCote(structureInfo: $structureInfo, data: $data)
}
    `;
export type EditPiezometerControlCoteMutationFn = Apollo.MutationFunction<EditPiezometerControlCoteMutation, EditPiezometerControlCoteMutationVariables>;
export type EditPiezometerControlCoteMutationResult = Apollo.MutationResult<EditPiezometerControlCoteMutation>;
export type EditPiezometerControlCoteMutationOptions = Apollo.BaseMutationOptions<EditPiezometerControlCoteMutation, EditPiezometerControlCoteMutationVariables>;