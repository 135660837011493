import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ListReferencesBySurfaceMarkerPagQueryVariables = Types.Exact<{
  pageInfo: Types.PageInput;
  instrumentId: Types.Scalars['String']['input'];
}>;


export type ListReferencesBySurfaceMarkerPagQuery = { __typename?: 'Query', listReferencesBySurfaceMarkerPag: { __typename?: 'PaginatedSurfaceMarkerReferences', count: number, nodes: Array<{ __typename?: 'ListSurfaceMarkersReferenceType', id: string, coordinateE?: number | null, coordinateN?: number | null, elevation?: number | null, date?: Date | null }>, pageInfo: { __typename?: 'PageInfo', page: number, offset: number, limit: number, totalPages: number, hasNextPage: boolean, hasPreviousPage: boolean } } };


export const ListReferencesBySurfaceMarkerPagDocument = gql`
    query ListReferencesBySurfaceMarkerPag($pageInfo: PageInput!, $instrumentId: String!) {
  listReferencesBySurfaceMarkerPag(
    pageInfo: $pageInfo
    instrumentId: $instrumentId
  ) {
    nodes {
      id
      coordinateE
      coordinateN
      elevation
      date
    }
    count
    pageInfo {
      page
      offset
      limit
      totalPages
      hasNextPage
      hasPreviousPage
    }
  }
}
    `;
export type ListReferencesBySurfaceMarkerPagQueryResult = Apollo.QueryResult<ListReferencesBySurfaceMarkerPagQuery, ListReferencesBySurfaceMarkerPagQueryVariables>;