import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ListSubscribedUserNotificationQueryVariables = Types.Exact<{
  filters: Types.ListSubscribedUserNotificationFIltersInput;
}>;


export type ListSubscribedUserNotificationQuery = { __typename?: 'Query', listSubscribedUserNotification: Array<{ __typename?: 'ListSubscribedUserNotificationType', criticality: string, id: string, instrumentType: Types.InstrumentTypeEnum, status: boolean, user: string }> };


export const ListSubscribedUserNotificationDocument = gql`
    query ListSubscribedUserNotification($filters: ListSubscribedUserNotificationFIltersInput!) {
  listSubscribedUserNotification(filters: $filters) {
    criticality
    id
    instrumentType
    status
    user
  }
}
    `;
export type ListSubscribedUserNotificationQueryResult = Apollo.QueryResult<ListSubscribedUserNotificationQuery, ListSubscribedUserNotificationQueryVariables>;