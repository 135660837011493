import { useMutation } from '@apollo/client';
import {
  EditStructureReservoirHandlingDocument,
  EditStructureReservoirHandlingMutation,
  EditStructureReservoirHandlingMutationVariables
} from '../../graphql/generated/editStructureReservoirHandling.mutation';
import { ListCompaniesDocument } from '../../graphql/query/generated/listCompanies.query';
import { ListCompanyRelatedUsersAndStructuresDocument } from '../../graphql/query/generated/listCompanyRelatedUsersAndStructures.query';
import { ListModulesByStructureIdDocument } from '../../graphql/query/generated/listModulesByStructureId.query';
import { ListModulesRolesDocument } from '../../graphql/query/generated/listModulesRole.query';
import { ListStructuresDocument } from '../../graphql/query/generated/listStructures.query';
import { ListUsersDocument } from '../../graphql/query/generated/listUsers.query';
import { GetModuleByStructureDocument } from '../../graphql/query/generated/modulesByStructure.query';
import { GetUserStructuresListDocument } from '../../graphql/query/generated/structureList.query';
import { GetStructureSummaryDocument } from '../../graphql/query/generated/structureSummary.query';

export function useEditStructureReservoirHandling(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [editReservoirHandling, { data, error, loading }] = useMutation<
    EditStructureReservoirHandlingMutation,
    EditStructureReservoirHandlingMutationVariables
  >(EditStructureReservoirHandlingDocument, {
    refetchQueries: [
      ListStructuresDocument,
      GetUserStructuresListDocument,
      ListModulesByStructureIdDocument,
      ListCompaniesDocument,
      ListUsersDocument,
      ListModulesRolesDocument,
      ListCompanyRelatedUsersAndStructuresDocument,
      GetStructureSummaryDocument,
      GetModuleByStructureDocument
    ],
    awaitRefetchQueries: true,
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    editReservoirHandling,
    data,
    error,
    loading
  };
}
