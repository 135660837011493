import styled from 'styled-components';

export const CardInfoNewAto = styled.div`
  color: black;
  width: 100%;
`;

export const HolderCardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const HolderCardChildren = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 50px 0px 50px;
`;
