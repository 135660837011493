import { useState } from 'react';
import { useClickAway } from '@uidotdev/usehooks';
import { useTranslation } from 'react-i18next';
import { VscKebabVertical } from 'react-icons/vsc';

import { ActionMenuProps } from './ActionMenu.interface';
import {
  ActionMenuItem,
  DivContainerActionMenu,
  Menu
} from './ActionMenu.styles';
import Icon from '../../../../Atoms/Icon/Icon';

export const ActionMenu = <T,>({
  availableContextActions,
  dataActions
}: ActionMenuProps<T>) => {
  const [showMenu, setShowMenu] = useState(false);
  const { t: translate } = useTranslation();
  const ref = useClickAway(() => {
    setShowMenu(false);
  });

  return (
    <DivContainerActionMenu ref={ref as React.RefObject<HTMLDivElement>}>
      <Icon
        Icon={VscKebabVertical}
        size={20}
        onClick={() => setShowMenu(!showMenu)}
      />
      {showMenu && (
        <Menu>
          {availableContextActions?.map((action, index) => {
            return (
              <ActionMenuItem
                key={index}
                onClick={() => action.onClick(dataActions)}
              >
                {action.icon}
                {translate(action.name)}
              </ActionMenuItem>
            );
          })}
        </Menu>
      )}
    </DivContainerActionMenu>
  );
};
