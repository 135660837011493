import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type SaveRecordWorkShiftMutationVariables = Types.Exact<{
  data: Types.SaveAtoRecordWorkShiftInput;
}>;


export type SaveRecordWorkShiftMutation = { __typename?: 'Mutation', saveRecordWorkShift: string };


export const SaveRecordWorkShiftDocument = gql`
    mutation SaveRecordWorkShift($data: SaveAtoRecordWorkShiftInput!) {
  saveRecordWorkShift(data: $data)
}
    `;
export type SaveRecordWorkShiftMutationFn = Apollo.MutationFunction<SaveRecordWorkShiftMutation, SaveRecordWorkShiftMutationVariables>;
export type SaveRecordWorkShiftMutationResult = Apollo.MutationResult<SaveRecordWorkShiftMutation>;
export type SaveRecordWorkShiftMutationOptions = Apollo.BaseMutationOptions<SaveRecordWorkShiftMutation, SaveRecordWorkShiftMutationVariables>;