import styled from 'styled-components';
import { Color } from '../../../../Styles/Styles';

export const TableHeadContent = styled.thead`
  color: ${Color.Brown1};
  background-color: ${Color.White};
  position: sticky;
  top: 0;
  z-index: 1;
`;
