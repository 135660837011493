import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type CreatePiezometerReadingMutationVariables = Types.Exact<{
  structureInfo: Types.StructureInfoInput;
  data: Array<Types.PiezometerInaReadingValuesInput> | Types.PiezometerInaReadingValuesInput;
}>;


export type CreatePiezometerReadingMutation = { __typename?: 'Mutation', createPiezometerReading: Array<{ __typename?: 'ValidateReadingValuesInfosModelType', error?: string | null }> };


export const CreatePiezometerReadingDocument = gql`
    mutation CreatePiezometerReading($structureInfo: StructureInfoInput!, $data: [PiezometerInaReadingValuesInput!]!) {
  createPiezometerReading(structureInfo: $structureInfo, data: $data) {
    error
  }
}
    `;
export type CreatePiezometerReadingMutationFn = Apollo.MutationFunction<CreatePiezometerReadingMutation, CreatePiezometerReadingMutationVariables>;
export type CreatePiezometerReadingMutationResult = Apollo.MutationResult<CreatePiezometerReadingMutation>;
export type CreatePiezometerReadingMutationOptions = Apollo.BaseMutationOptions<CreatePiezometerReadingMutation, CreatePiezometerReadingMutationVariables>;