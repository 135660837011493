import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type QueryQueryVariables = Types.Exact<{
  actionPlanId: Types.Scalars['String']['input'];
}>;


export type QueryQuery = { __typename?: 'Query', findAllActionPlanImages: Array<string> };


export const QueryDocument = gql`
    query Query($actionPlanId: String!) {
  findAllActionPlanImages(actionPlanId: $actionPlanId)
}
    `;
export type QueryQueryResult = Apollo.QueryResult<QueryQuery, QueryQueryVariables>;