import { PropsWithChildren } from 'react';

import { TableRowProps } from './TableRow.interface';
import { TableRowContent } from './TableRow.styles';

export const TableRow = ({
  children,
  onClick
}: PropsWithChildren<TableRowProps>) => {
  return <TableRowContent onClick={onClick}>{children}</TableRowContent>;
};
