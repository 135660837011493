import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type FindActionPlanDataQueryVariables = Types.Exact<{
  actionPlanId: Types.Scalars['String']['input'];
}>;

export type FindActionPlanDataQuery = {
  __typename?: 'Query';
  findActionPlanData: {
    __typename?: 'ActionPlanDataType';
    code: string;
    situation?: Types.ActionPlanSituationEnum | null;
    images: Array<string>;
    description: string;
    priority?: Types.ActionPlanPriorityEnum | null;
    timeFrame?: Date | null;
    imagesCount: number;
    responsible?: {
      __typename?: 'ActionPlanResponsibleDataType';
      id: string;
      name: string;
    } | null;
    comments: Array<{
      __typename?: 'ActionPlanCommentsDataType';
      id: string;
      comment: string;
      userName: string;
      createdAt: Date;
      files: Array<{
        __typename?: 'ActionPlanCommentFilesType';
        id: string;
        fileName: string;
        url: string;
      }>;
    }>;
    teamMembers: Array<{
      __typename?: 'ActionPlanTeamMembersDataType';
      id: string;
      name: string;
      email: string;
    }>;
  };
};

export const FindActionPlanDataDocument = gql`
  query FindActionPlanData($actionPlanId: String!) {
    findActionPlanData(actionPlanId: $actionPlanId) {
      code
      situation
      images
      description
      responsible {
        id
        name
      }
      priority
      timeFrame
      comments {
        id
        comment
        userName
        createdAt
        files {
          id
          fileName
          url
        }
      }
      teamMembers {
        id
        name
        email
      }
      imagesCount
    }
  }
`;

export type FindActionPlanDataQueryResult = Apollo.QueryResult<
  FindActionPlanDataQuery,
  FindActionPlanDataQueryVariables
>;
