import React, { useState } from 'react';
import { setDatasets } from 'react-chartjs-2/dist/utils';
import { useTranslation } from 'react-i18next';
import { BiSolidPencil } from 'react-icons/bi';
import { FaTrash } from 'react-icons/fa';
import { CreatePiezometerAlertLevelInput } from '../../../data/graphql/base-schema';
import { TableArea, TableComponentStyle } from '../TablesStyle';
import EditValueResolutionModal from './EditValueResolutionModal';
import { Color } from '../../../Styles/Styles';

interface PiezometerControlLevelTableProps {
  controlLevels: CreatePiezometerAlertLevelInput[];
  handleRemove: (index: number) => void;
  setInstrumentEspecificData: any;
  instrumentEspecificData: any;
  alertLevels: any;
}

export const PiezometerControlLevelTable = ({
  controlLevels,
  handleRemove,
  setInstrumentEspecificData,
  instrumentEspecificData,
  alertLevels
}: PiezometerControlLevelTableProps) => {
  const { t } = useTranslation();
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [data, setData] = useState<any>(null);
  const [index, setIndex] = useState<any>(null);

  return (
    <>
      <TableArea
        style={{
          position: 'absolute',
          width: '45%',
          top: 220,
          left: 30
        }}
      >
        <TableComponentStyle>
          <thead>
            <tr>
              <th>{t('name')}</th>
              <th>{`FS - ${t('Drained')}`}</th>
              <th>{`FS - ${t('NotDrained')}`}</th>
              <th>{t('Value')}</th>
              <th>{t('Actions')}</th>
            </tr>
          </thead>
          <tbody>
            {controlLevels &&
              (controlLevels?.length > 0 ? (
                controlLevels.map((v, i) => (
                  <tr key={i}>
                    <td>{t(v.name)}</td>
                    <td>
                      {v.drained != undefined || null
                        ? v.drained
                        : 'Não há dados'}
                    </td>
                    <td>
                      {v.undrained != undefined || null
                        ? v.undrained
                        : 'Não há dados'}
                    </td>
                    <td>{v.coteValue}</td>
                    <td>
                      {alertLevels?.id === 'ResolutionANM' && (
                        <BiSolidPencil
                          color={Color.Brown1}
                          style={{
                            cursor: 'pointer',
                            fontSize: '14px',
                            marginRight: '3px'
                          }}
                          onClick={() => {
                            setShowEditModal(true);
                            setData(v);
                            setIndex(i);
                          }}
                        />
                      )}
                      <FaTrash
                        color={Color.Brown1}
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleRemove(i)}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={5}>{t('NoCustomLevelsAdded')}</td>
                </tr>
              ))}
          </tbody>
        </TableComponentStyle>
      </TableArea>
      {showEditModal && (
        <EditValueResolutionModal
          showModal={showEditModal}
          setShowModal={setShowEditModal}
          data={data}
          index={index}
          setInstrumentEspecificData={setInstrumentEspecificData}
          instrumentEspecificData={instrumentEspecificData}
        />
      )}
    </>
  );
};
