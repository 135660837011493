import styled from 'styled-components';
import { Color } from '../../../../../../Styles/Styles';

export const TableCellContent = styled.td`
  color: ${(props) => props.color || Color.Text1};
  height: 54px;
  border-bottom: 0.5px solid ${Color.Brown3};
`;

export const TableCellContentFixedLeft = styled.td`
  color: ${(props) => props.color || Color.Text1};
  height: 54px;
  border-bottom: 0.5px solid ${Color.Brown3};
  background-color: #f5f5f5;
  position: sticky;
  left: 0;
`;

export const TableCellContentFixedRight = styled.td`
  color: ${(props) => props.color || Color.Text1};
  height: 54px;
  border-bottom: 0.5px solid ${Color.Brown3};
  background-color: #f5f5f5;
  position: sticky;
  right: 0;
`;
