import { useTranslation } from 'react-i18next';
import EditInstrumentTemplate from '../../Templates/EditInstrumentTemplate/EditInstrumentTamplate';
import { useNavigate, useParams } from 'react-router-dom';
import EditPiezometerAndInaPage from '../EditPiezometerAndInaPage/EditPiezometerAndInaPage';
import Icon from '../../Atoms/Icon/Icon';
import Text from '../../Atoms/Text/Text';
import { IoIosArrowBack } from 'react-icons/io';
import EditPluviometer from '../EditPluviometer/EditPluviometer';
import EditWaterLevel from '../EditWaterLevel/EditWaterLevel';
import EditSurfaceMarker from '../EditSurfaceMarker/EditSurfaceMarker';

const EditInstrumentPage = () => {
  const { t: translate } = useTranslation();
  const { structureId, instrumentType } = useParams();
  const navigate = useNavigate();
  const instrumentTypeTitle =
    instrumentType === 'ina'
      ? instrumentType?.toLocaleUpperCase()
      : instrumentType?.split('')[0].toLocaleUpperCase() +
        instrumentType!.slice(1);

  const componentMap: { [key: string]: React.ReactNode } = {
    piezometer: <EditPiezometerAndInaPage />,
    ina: <EditPiezometerAndInaPage />,
    pluviometer: <EditPluviometer />,
    waterlevel: <EditWaterLevel />,
    surfacemarker: <EditSurfaceMarker />
  };

  const handleNavigateInstrumentPage = () => {
    navigate(`/${structureId}/monitoring/instrument/${instrumentType}`);
  };

  const canEdit = location.pathname.includes('edit');

  return (
    <EditInstrumentTemplate
      header={
        <>
          <Icon
            Icon={IoIosArrowBack}
            variant="large"
            onClick={handleNavigateInstrumentPage}
          />
          <Text weight="bold" type="h2">
            {canEdit ? translate('Edit') : translate('View')}{' '}
            {translate(`${instrumentTypeTitle}`)}
          </Text>
        </>
      }
      content={componentMap[instrumentType || '']}
    />
  );
};

export default EditInstrumentPage;
