import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultButton } from '../../../Components/Buttons/DefaultButton';
import Toggles from '../../../Components/Buttons/Toggles/Toggles';
import {
  toastfyDimiss,
  toastfyError,
  toastifyLoading
} from '../../../Components/Toastify';
import ToastifyModel from '../../../Models/ToastifyModel';
import {
  AdminContainer,
  AdminFilter,
  AdminSearch,
  AdminTitle,
  DivContainer,
  SearchIcon,
  StyledInput
} from '../../../Screens/AdminScreen/AdminScreenStyle';
import { useListUsers } from '../../../data/hooks/admin-users/use-list-users';
import { AdminDeleteModal } from '../AdminDeleteModal';
import AdminTable from '../AdminTable';
import { UsersData, __initialUserData } from './UsersInterface';
import { UsersModal } from './UsersModal';

function Users() {
  const { t } = useTranslation();
  const [status, setStatus] = useState<boolean>(false);
  const [search, setSearch] = useState('');
  const {
    data: listUsers,
    loading: loadingList,
    error: listUsersError
  } = useListUsers(true);
  const [showUserModal, setShowUserModal] = useState<boolean>(false);
  const [tableValues, setTableValues] = useState<
    {
      name: string;
      email: string;
      position: string;
      role: string;
      company: string;
      status: string;
    }[]
  >([]);
  const [tableValuesFiltered, setTableValuesFiltered] = useState<
    {
      name: string;
      email: string;
      position: string;
      role: string;
      company: string;
      status: string;
    }[]
  >([]);
  const [edit, setEdit] = useState<{ status: boolean; data: any }>({
    status: false,
    data: {}
  });
  const [deleting, setDeleting] = useState<{ status: boolean; data: any }>({
    status: false,
    data: {}
  });

  useEffect(() => {
    if (listUsersError) {
      toastfyError(t(ToastifyModel().toastifyMessage.error));
      toastfyDimiss('toastLoading');
    } else if (loadingList) {
      toastifyLoading(t(ToastifyModel().toastifyMessage.loading));
    } else if (listUsers) {
      const filteredTableValues = listUsers.listUsers.map((item: any) => {
        return Object.keys(item).reduce((acc: any, key: any) => {
          if (key !== '__typename') {
            if (key === 'status') {
              acc[key] = item[key] ? t('active') : t('inactive');
            } else if (key === 'role') {
              acc[key] = item.role.role;
            } else if (key === 'company') {
              acc[key] = item.company.name;
            } else {
              acc[key] = item[key];
            }
          }

          return acc;
        }, {});
      });
      if (!status) {
        setTableValuesFiltered(
          filteredTableValues.filter((item: any) => item.status === t('active'))
        );
      } else {
        setTableValuesFiltered(filteredTableValues);
      }

      setTableValues(filteredTableValues);
      toastfyDimiss('toastLoading');
    }
  }, [listUsers]);
  const [userData, setUserData] = useState<UsersData>(__initialUserData);

  const activities = () => {
    if (status) {
      setTableValuesFiltered(
        tableValues.filter((e) => e.status.includes(t('active')))
      );
      return tableValuesFiltered;
    }

    setTableValuesFiltered(
      tableValues.filter(
        (e) =>
          e.status.includes(t('active')) || e.status.includes(t('inactive'))
      )
    );
    return tableValuesFiltered;
  };

  return (
    <DivContainer>
      <AdminContainer>
        <AdminTitle>{t('Users')}</AdminTitle>
        <AdminFilter>
          <div>
            <DefaultButton
              title={`+ ${t('Add')}`}
              action={() => setShowUserModal(true)}
            />
            {Toggles.ToggleStatus({
              check: status,
              setCheck: setStatus,
              key: 'status',
              text: t('status'),
              updateValue: activities
            })}
          </div>
          <AdminSearch>
            <StyledInput
              type="text"
              id="search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <SearchIcon size={20} />
          </AdminSearch>
        </AdminFilter>
        <AdminTable
          tableColumns={[
            'name',
            'email',
            'position',
            'company',
            'role',
            'status'
          ]}
          tableValues={tableValuesFiltered.filter(
            (e) =>
              e.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
              e.email
                ?.toLocaleLowerCase()
                .includes(search.toLocaleLowerCase()) ||
              e.position
                ?.toLocaleLowerCase()
                .includes(search.toLocaleLowerCase()) ||
              e.company
                ?.toLocaleLowerCase()
                .includes(search.toLocaleLowerCase()) ||
              e.role?.toLocaleLowerCase().includes(search.toLocaleLowerCase())
          )}
          edit={edit}
          setEdit={setEdit}
          deleting={deleting}
          setDeleting={setDeleting}
        />
        {showUserModal || edit.status ? (
          <UsersModal
            setShowModal={setShowUserModal}
            edit={edit}
            setEdit={setEdit}
            userData={userData}
            setUserData={setUserData}
            listUsers={listUsers}
          />
        ) : null}

        {deleting.status ? (
          <AdminDeleteModal
            id={deleting.data.id}
            name={deleting.data.name}
            setShowModal={setShowUserModal}
            setDeleting={setDeleting}
            type="Users"
            title={t('User')}
          />
        ) : null}
      </AdminContainer>
    </DivContainer>
  );
}

export default Users;
