import { StylesConfig } from 'react-select';
import styled from 'styled-components';
import { Color } from '../../Styles/Styles';

interface InputStyledProps {
  error?: boolean;
}

export const StyleInstrumentSelect: StylesConfig = {
  valueContainer: (provided) => ({
    ...provided,
    justifyItems: 'start'
  }),

  control: (provided, state) => ({
    ...provided,
    backgroundColor: 'transparent',
    border: 'none',
    borderBottom: `1px solid ${Color.Brown1}`,
    borderRadius: 'none',
    boxSizing: 'border-box',
    boxShadow: 'none',
    padding: 'none',
    fontSize: '11pt',
    cursor: 'pointer',
    marginBottom: '13px',
    userSelect: 'none',
    ':hover': {
      borderBottom: `1px solid ${Color.Brown1}`
    }
  }),

  indicatorSeparator: () => ({}), // disable indicator

  placeholder: (provided) => ({
    ...provided,
    color: Color.Brown1
  }),

  input: (provider) => ({
    ...provider,
    color: Color.Brown2,
    backgroundColor: 'transparent',
    textShadow: 'none',
    height: '20px',
    ':hover': {
      border: '1px solid transparent'
    }
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: Color.Brown1,
    ':hover': {
      color: Color.Brown1
    }
  }),
  noOptionsMessage: () => ({
    fontSize: '10pt',
    backgroundColor: Color.Brown3,
    cursor: 'pointer',
    padding: '10px 5px 10px 5px',
    marginTop: '-5px'
  }),

  option: (provided) => ({
    ...provided,
    fontSize: '10pt',
    backgroundColor: Color.Brown3,
    cursor: 'pointer',
    padding: '10px 5px 10px 5px',
    marginTop: '-5px',
    ':hover': {
      backgroundColor: Color.Gray1
    }
  }),
  singleValue: (provided) => ({
    ...provided,
    color: Color.Brown1
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    zIndex: 3
  })
};

export const DivSelectStyle = styled.div<InputStyledProps>`
  color: ${({ error }) => (error ? Color.Red1 : Color.Brown1)};
  background-color: ${({ error }) => (error ? '#ffe6e6' : 'white')};
`;

// TODO: Definir qual style será utilizado
// export const DivSelectStyle = styled.div<InputStyledProps>`
//   color: ${({ error }) => (error ? Color.Red1 : Color.Brown1)};
//   border: 1px solid ${({ error }) => (error ? Color.Red1 : 'transparent')};
// 	background-color: ${({ error }) => (error ? '#ffe6e6' : 'white')};
// 	border-radius: ${({ error }) => (error ? '5px' : 'none')};
// `
