import { Map, Marker } from '@vis.gl/react-google-maps';
import { useStructureSummary } from '../../data/hooks/use-structure-summary.query';
import { useParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { useState } from 'react';
import { useDrawingManager } from './MapGoogleComponents/useDrawingManager';
import { MapMenu } from './MapMenu';
import { useListInstrumentsByStructure } from '../../data/hooks/use-list-instuments-by-structure.query';
import { RectangleBounds } from './MapGoogleComponents/MapGoogleComponents.interfaces';
import { MarkerWithInfowindow } from './MapGoogleComponents/MarkerWithInfoWindow';
import {
  DEFAULT_CENTER_BRAZIL,
  DEFAULT_MAP_ZOOM,
  WITH_CENTER_MAP_ZOOM,
  DEFAULT_MAP_ID_FOR_API_CONTEXT
} from './mapGoogle.constants';

const MapGoogleDraw = () => {
  const { structureId } = useParams();
  const { data: structure, loading } = useStructureSummary(
    '',
    structureId || ''
  );
  const [selectedInstruments, setSelectedInstruments] = useState<string[]>([]);
  const [selectedSection, setSelectedSection] = useState<string[]>([]);
  const [status, setStatus] = useState<boolean>(true);
  const [icons, setIcons] = useState<boolean>(true);
  const { data: instrumentsByStructure } = useListInstrumentsByStructure(
    structureId || ''
  );

  const instruments =
    instrumentsByStructure?.listInstrumentsByStructure[0].typesList
      .filter((structure) => structure.instruments.length > 0)
      .map((instruments) => instruments.type.toLocaleUpperCase());

  const instrumentsType = instrumentsByStructure?.listInstrumentsByStructure[0];

  const sections =
    instrumentsByStructure?.listInstrumentsByStructure[0].sectionsList.map(
      (section) => section
    );

  const getAllSelectedMarkInstuments = () => {
    const selectedInstrumentsUpperCase = selectedInstruments
      .toLocaleString()
      .toLocaleUpperCase();
    const markInstrumentsData =
      instrumentsByStructure?.listInstrumentsByStructure[0].typesList
        .filter((instrumentType) =>
          selectedInstrumentsUpperCase.includes(
            instrumentType.type.toUpperCase()
          )
        )
        .flatMap((instrumentType) =>
          instrumentType.instruments
            .filter((instrument) => {
              const strStatus = status ? 'Active' : 'Inactive';
              return (
                instrument.operationalStatus.toLocaleLowerCase() ===
                strStatus.toLocaleLowerCase()
              );
            })
            .map((instrument) => ({
              id: instrument.id,
              name: instrument.name,
              lat: instrument.lat,
              lon: instrument.lon,
              type: instrumentType.type
            }))
        );

    return markInstrumentsData;
  };

  const getOnlySectionsMarkInstuments = () => {
    const sectionsRenderData = sections?.filter((section) =>
      selectedSection?.length ? selectedSection.includes(section.id) : true
    );
    const markInstrumentsData = sectionsRenderData?.flatMap((section) =>
      section.typesList.flatMap((instrumentType) =>
        instrumentType.instruments
          .filter((instrument) => {
            const strStatus = status ? 'Active' : 'Inative';
            return instrument.operationalStatus === strStatus;
          })
          .map((instrument) => ({
            id: instrument.id,
            name: instrument.name,
            lat: instrument.lat,
            lon: instrument.lon,
            type: instrumentType.type
          }))
      )
    );
    return markInstrumentsData;
  };

  const getMarkInstumentsData = () => {
    if (!selectedSection.length) {
      const markInstrumentsData = getAllSelectedMarkInstuments();
      return markInstrumentsData;
    }

    const markInstrumentsData = getOnlySectionsMarkInstuments();
    return markInstrumentsData;
  };

  const renderInstrumentsMarksInfo = () => {
    const markInstumentsData = getMarkInstumentsData();
    const markInstrumentsComponents = markInstumentsData?.map(
      (markInstument) => (
        <MarkerWithInfowindow
          key={markInstument.lat}
          markInstument={markInstument}
          title={structure?.getStructureSummary?.name}
          shouldRenderIcon={icons}
        />
      )
    );
    return markInstrumentsComponents ?? <></>;
  };

  const getSections = () => {
    const sectionsRenderData: RectangleBounds[] | undefined = sections
      ?.filter((section) => selectedSection.includes(section.id))
      .map((section) => ({
        north: section.initialLat,
        south: section.finalLat,
        east: section.initialLon,
        west: section.finalLon
      }));
    return sectionsRenderData?.length === 0 ? undefined : sectionsRenderData;
  };

  useDrawingManager(getSections());

  const { latitude, longitude } = structure?.getStructureSummary?.summary || {};
  const shouldRenderCenterPosition = latitude && longitude;
  const defaultZoom = shouldRenderCenterPosition
    ? WITH_CENTER_MAP_ZOOM
    : DEFAULT_MAP_ZOOM;
  const defaultCenter = shouldRenderCenterPosition
    ? { lat: latitude!, lng: longitude! }
    : DEFAULT_CENTER_BRAZIL;

  return (
    <>
      {loading ? (
        <Skeleton />
      ) : (
        <Map
          mapId={DEFAULT_MAP_ID_FOR_API_CONTEXT}
          defaultZoom={defaultZoom}
          defaultCenter={defaultCenter}
          mapTypeId={window.google?.maps?.MapTypeId?.SATELLITE}
          streetViewControl={false}
        >
          {shouldRenderCenterPosition && (
            <Marker
              position={{ lat: latitude!, lng: longitude! }}
              title={structure?.getStructureSummary?.name}
            />
          )}
          {renderInstrumentsMarksInfo()}
        </Map>
      )}
      <MapMenu
        setSelectedInstruments={setSelectedInstruments}
        selectedInstruments={selectedInstruments}
        instruments={instruments}
        instrumentsType={instrumentsType}
        setSelectedSection={setSelectedSection}
        selectedSection={selectedSection}
        status={status}
        setStatus={setStatus}
        icons={icons}
        setIcons={setIcons}
      />
    </>
  );
};

export default MapGoogleDraw;
