import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type SavePiezometerControlQuotaMutationVariables = Types.Exact<{
  data: Types.CreatePiezometerInput;
  instrumentId: Types.Scalars['String']['input'];
}>;

export type SavePiezometerControlQuotaMutation = {
  __typename?: 'Mutation';
  savePiezometerControlQuota: string;
};

export const SavePiezometerControlQuotaDocument = gql`
  mutation SavePiezometerControlQuota(
    $data: CreatePiezometerInput!
    $instrumentId: String!
  ) {
    savePiezometerControlQuota(data: $data, instrumentId: $instrumentId)
  }
`;

export type SavePiezometerControlQuotaMutationFn = Apollo.MutationFunction<
  SavePiezometerControlQuotaMutation,
  SavePiezometerControlQuotaMutationVariables
>;

export type SavePiezometerControlQuotaMutationResult =
  Apollo.MutationResult<SavePiezometerControlQuotaMutation>;

export type SavePiezometerControlQuotaMutationOptions =
  Apollo.BaseMutationOptions<
    SavePiezometerControlQuotaMutation,
    SavePiezometerControlQuotaMutationVariables
  >;
