import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetUserStructuresListQueryVariables = Types.Exact<{
  userId: Types.Scalars['String']['input'];
}>;


export type GetUserStructuresListQuery = { __typename?: 'Query', getUserStructuresList: { __typename?: 'CompanyWithStructureType', id: string, status: boolean, name: string, logo?: string | null, cnpj: string, structures: Array<{ __typename?: 'BasicStructureType', id: string, name: string, type: string, status: boolean }>, address: { __typename?: 'CompanyAddressType', country: string, state: string, city: string, zipCode?: string | null, addressLine?: string | null, number?: number | null } } };


export const GetUserStructuresListDocument = gql`
    query GetUserStructuresList($userId: String!) {
  getUserStructuresList(userId: $userId) {
    structures {
      id
      name
      type
      status
    }
    id
    status
    name
    logo
    cnpj
    address {
      country
      state
      city
      zipCode
      addressLine
      number
    }
  }
}
    `;
export type GetUserStructuresListQueryResult = Apollo.QueryResult<GetUserStructuresListQuery, GetUserStructuresListQueryVariables>;