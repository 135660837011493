import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type DeleteActionPlanCommentMutationVariables = Types.Exact<{
  commentId: Types.Scalars['String']['input'];
}>;


export type DeleteActionPlanCommentMutation = { __typename?: 'Mutation', deleteActionPlanComment: string };


export const DeleteActionPlanCommentDocument = gql`
    mutation DeleteActionPlanComment($commentId: String!) {
  deleteActionPlanComment(commentId: $commentId)
}
    `;
export type DeleteActionPlanCommentMutationFn = Apollo.MutationFunction<DeleteActionPlanCommentMutation, DeleteActionPlanCommentMutationVariables>;
export type DeleteActionPlanCommentMutationResult = Apollo.MutationResult<DeleteActionPlanCommentMutation>;
export type DeleteActionPlanCommentMutationOptions = Apollo.BaseMutationOptions<DeleteActionPlanCommentMutation, DeleteActionPlanCommentMutationVariables>;