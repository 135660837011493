import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ListAlertsLevelBySurfaceMarkerPagQueryVariables = Types.Exact<{
  pageInfo: Types.PageInput;
  instrumentId: Types.Scalars['String']['input'];
}>;


export type ListAlertsLevelBySurfaceMarkerPagQuery = { __typename?: 'Query', listAlertsLevelBySurfaceMarkerPag: { __typename?: 'PaginatedSurfaceMarkerAlertLevels', count: number, nodes: Array<{ __typename?: 'ListSurfaceMarkerAlertLevelType', id: string, coordinateE?: number | null, coordinateN?: number | null, elevation?: number | null, date?: Date | null, name?: string | null }>, pageInfo: { __typename?: 'PageInfo', page: number, offset: number, limit: number, totalPages: number, hasNextPage: boolean, hasPreviousPage: boolean } } };


export const ListAlertsLevelBySurfaceMarkerPagDocument = gql`
    query ListAlertsLevelBySurfaceMarkerPag($pageInfo: PageInput!, $instrumentId: String!) {
  listAlertsLevelBySurfaceMarkerPag(
    pageInfo: $pageInfo
    instrumentId: $instrumentId
  ) {
    nodes {
      id
      coordinateE
      coordinateN
      elevation
      date
      name
    }
    count
    pageInfo {
      page
      offset
      limit
      totalPages
      hasNextPage
      hasPreviousPage
    }
  }
}
    `;
export type ListAlertsLevelBySurfaceMarkerPagQueryResult = Apollo.QueryResult<ListAlertsLevelBySurfaceMarkerPagQuery, ListAlertsLevelBySurfaceMarkerPagQueryVariables>;