import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type EditPasswordMutationVariables = Types.Exact<{
  data: Types.EditPasswordInput;
}>;


export type EditPasswordMutation = { __typename?: 'Mutation', editPassword: string };


export const EditPasswordDocument = gql`
    mutation EditPassword($data: EditPasswordInput!) {
  editPassword(data: $data)
}
    `;
export type EditPasswordMutationFn = Apollo.MutationFunction<EditPasswordMutation, EditPasswordMutationVariables>;
export type EditPasswordMutationResult = Apollo.MutationResult<EditPasswordMutation>;
export type EditPasswordMutationOptions = Apollo.BaseMutationOptions<EditPasswordMutation, EditPasswordMutationVariables>;