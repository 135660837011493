import styled from 'styled-components';
import { Color } from '../../../../Styles/Styles';

export const DivVolumeContainer = styled.div`
  color: ${Color.Text1};
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 15px;
`;
export const TitleVolume = styled.span`
  padding: 15px 0;
  font-weight: bold;
`;
export const DivContainerVolumeList = styled.div`
  width: 100%;
`;
export const DivVolumeList = styled.div`
  display: grid;
  grid-template-columns: 90% 5%;
  align-items: center;
  gap: 25px;
  padding-bottom: 15px;

  span {
    color: ${Color.Text1} !important;
  }
`;
export const DivPadding = styled.div`
  padding-left: 30px;
`;
