import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type EditStructureDamTailingVolumeMutationVariables = Types.Exact<{
  data: Types.EditTailingVolumeInput;
}>;


export type EditStructureDamTailingVolumeMutation = { __typename?: 'Mutation', editStructureDamTailingVolume: string };


export const EditStructureDamTailingVolumeDocument = gql`
    mutation EditStructureDamTailingVolume($data: EditTailingVolumeInput!) {
  editStructureDamTailingVolume(data: $data)
}
    `;
export type EditStructureDamTailingVolumeMutationFn = Apollo.MutationFunction<EditStructureDamTailingVolumeMutation, EditStructureDamTailingVolumeMutationVariables>;
export type EditStructureDamTailingVolumeMutationResult = Apollo.MutationResult<EditStructureDamTailingVolumeMutation>;
export type EditStructureDamTailingVolumeMutationOptions = Apollo.BaseMutationOptions<EditStructureDamTailingVolumeMutation, EditStructureDamTailingVolumeMutationVariables>;