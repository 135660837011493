import { useMutation } from '@apollo/client';
import {
  CreateUserInstrumentNotificationDocument,
  CreateUserInstrumentNotificationMutation,
  CreateUserInstrumentNotificationMutationVariables
} from '../graphql/generated/createUserInstrumentNotification';
import { ListSubscribedUserNotificationDocument } from '../graphql/query/generated/listSubscribedUserNotification';

export function useCreateUserInstrumentNotification(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [createUserInstrumentNotification, { data, error, loading }] =
    useMutation<
      CreateUserInstrumentNotificationMutation,
      CreateUserInstrumentNotificationMutationVariables
    >(CreateUserInstrumentNotificationDocument, {
      refetchQueries: [ListSubscribedUserNotificationDocument],
      awaitRefetchQueries: true,
      errorPolicy: 'all',
      onCompleted: onCompleted,
      onError: onError
    });

  return {
    createUserInstrumentNotification,
    data,
    error,
    loading
  };
}
