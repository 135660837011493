import { useMutation } from '@apollo/client';
import {
  DeleteUserPermissionDocument,
  DeleteUserPermissionMutation,
  DeleteUserPermissionMutationVariables
} from '../../graphql/generated/deleteUserPermission.mutation';
import { ListCompaniesDocument } from '../../graphql/query/generated/listCompanies.query';
import { ListCompanyRelatedUsersAndStructuresDocument } from '../../graphql/query/generated/listCompanyRelatedUsersAndStructures.query';
import { ListModulesRolesDocument } from '../../graphql/query/generated/listModulesRole.query';
import { ListStructuresDocument } from '../../graphql/query/generated/listStructures.query';
import { ListUsersDocument } from '../../graphql/query/generated/listUsers.query';
import { ListUsersPermissionsDocument } from '../../graphql/query/generated/listUsersPermissions.query';
import { GetUserStructuresListDocument } from '../../graphql/query/generated/structureList.query';
import { GetStructureSummaryDocument } from '../../graphql/query/generated/structureSummary.query';
import { ListModulesByStructureIdDocument } from '../../graphql/query/generated/listModulesByStructureId.query';
import { ListStructureAndModulesDocument } from '../../graphql/query/generated/listStructureAndModules.query';
import { ListModulesDocument } from '../../graphql/query/generated/listModules';

export function useDeleteUsersPermission(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [deleteUserPermission, { data, error, loading }] = useMutation<
    DeleteUserPermissionMutation,
    DeleteUserPermissionMutationVariables
  >(DeleteUserPermissionDocument, {
    refetchQueries: [
      ListUsersPermissionsDocument,
      ListModulesByStructureIdDocument,
      ListStructureAndModulesDocument,
      ListModulesDocument,
      ListStructuresDocument,
      ListCompaniesDocument,
      ListUsersDocument,
      ListModulesRolesDocument,
      GetUserStructuresListDocument,
      ListCompanyRelatedUsersAndStructuresDocument,
      GetStructureSummaryDocument
    ],
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    deleteUserPermission,
    data,
    error,
    loading
  };
}
