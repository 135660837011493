import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type SaveRecordObservationMutationVariables = Types.Exact<{
  data: Types.SaveAtoRecordObservationInput;
}>;


export type SaveRecordObservationMutation = { __typename?: 'Mutation', saveRecordObservation: string };


export const SaveRecordObservationDocument = gql`
    mutation SaveRecordObservation($data: SaveAtoRecordObservationInput!) {
  saveRecordObservation(data: $data)
}
    `;
export type SaveRecordObservationMutationFn = Apollo.MutationFunction<SaveRecordObservationMutation, SaveRecordObservationMutationVariables>;
export type SaveRecordObservationMutationResult = Apollo.MutationResult<SaveRecordObservationMutation>;
export type SaveRecordObservationMutationOptions = Apollo.BaseMutationOptions<SaveRecordObservationMutation, SaveRecordObservationMutationVariables>;