import styled from 'styled-components';
import { Color } from '../../../../Styles/Styles';

export const DivContainerActionMenu = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  svg {
    color: ${Color.Gray3};
  }
`;

export const ActionMenuItem = styled.a`
  color: ${Color.Brown1};
  padding: 12px 16px;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 15px;

  &:hover {
    background-color: #f1f1f1;
    cursor: pointer;
  }

  svg {
    color: ${Color.Brown1};
  }
`;

export const Menu = styled.div`
  position: absolute;
  background-color: ${Color.White};
  min-width: 160px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  right: 105%;
  bottom: -50%;
  border-radius: 5px;
`;
