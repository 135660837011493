import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultButton } from '../../../../Components/Buttons/DefaultButton';
import {
  toastfyDimiss,
  toastfyError,
  toastifyLoading
} from '../../../../Components/Toastify';
import ToastifyModel from '../../../../Models/ToastifyModel';
import {
  AdminContainer,
  AdminFilter,
  AdminSearch,
  AdminTitle,
  SearchIcon,
  StyledInput
} from '../../../../Screens/AdminScreen/AdminScreenStyle';
import { CreateSectionInput } from '../../../../data/graphql/base-schema';
import { useListSection } from '../../../../data/hooks/admin-structure-sections/use-list-section';
import { AdminDeleteModal } from '../../AdminDeleteModal';
import AdminTable from '../../AdminTable';
import { SectionsModal } from './SectionModal';
import { __initialSectionsData } from './SectionsInterface';
import Toggles from '../../../../Components/Buttons/Toggles/Toggles';

function Sections() {
  const { t } = useTranslation();
  const [status, setStatus] = useState<boolean>(false);
  const [search, setSearch] = useState('');
  const [showModulesModal, setShowModulesModal] = useState<boolean>(false);
  const [tableValues, setTableValues] = useState<
    {
      Structure: string;
      Section: string;
      CoordEInitial: string;
      CoordNInitial: string;
      CoordEFinal: string;
      CoordNFinal: string;
      status: boolean;
      isUTM: boolean;
    }[]
  >([]);
  const [edit, setEdit] = useState<{ status: boolean; data: any }>({
    status: false,
    data: {}
  });
  const [deleting, setDeleting] = useState<{ status: boolean; data: any }>({
    status: false,
    data: {}
  });
  const {
    data: listSection,
    error: listSectionError,
    loading: listSectionLoading
  } = useListSection();
  const [sectionsData, setSectionsData] = useState<CreateSectionInput>(
    __initialSectionsData
  );

  useEffect(() => {
    if (listSectionError) {
      toastfyError(t(ToastifyModel().toastifyMessage.error));
      toastfyDimiss('toastLoading');
    } else if (listSectionLoading) {
      toastifyLoading(t(ToastifyModel().toastifyMessage.loading));
    } else if (listSection) {
      const filteredTableValues = listSection.listSection
        .filter((company: any) =>
          company.status === status ? status : status || !status
        )
        .map((item: any) => {
          return {
            id: item.id,
            Structure: item.structureName,
            Section: item.name,
            CoordEInitial: item.initialCoordinateE,
            CoordNInitial: item.initialCoordinateN,
            CoordEFinal: item.finalCoordinateE,
            CoordNFinal: item.finalCoordinateN,
            status: item.status ? t('active') : t('inactive'),
            isUTM: item.isUTM
          };
        });
      setTableValues(filteredTableValues);
      toastfyDimiss('toastLoading');
    }
  }, [listSection, status]);

  return (
    <AdminContainer>
      <AdminTitle>{t('Section')}</AdminTitle>
      <AdminFilter>
        <div>
          <DefaultButton
            title={`+ ${t('Add')}`}
            action={() => setShowModulesModal(true)}
          />
          {Toggles.ToggleStatus({
            check: status,
            setCheck: setStatus,
            key: 'status',
            text: t('status')
          })}
        </div>
        <AdminSearch>
          <StyledInput
            type="text"
            id="search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <SearchIcon size={20} />
        </AdminSearch>
      </AdminFilter>
      <AdminTable
        tableColumns={[
          'Structure',
          'Section',
          'CoordEInitial',
          'CoordNInitial',
          'CoordEFinal',
          'CoordNFinal'
        ]}
        tableValues={tableValues.filter(
          (e) =>
            t(e.Structure)
              .toLocaleLowerCase()
              .includes(search.toLocaleLowerCase()) ||
            e.Section?.toLocaleLowerCase().includes(search.toLocaleLowerCase())
        )}
        edit={edit}
        setEdit={setEdit}
        deleting={deleting}
        setDeleting={setDeleting}
      />
      {(showModulesModal || edit.status) && (
        <SectionsModal
          setShowModal={setShowModulesModal}
          edit={edit}
          setEdit={setEdit}
          sectionsData={sectionsData}
          setSectionsData={setSectionsData}
        />
      )}

      {deleting.status && (
        <AdminDeleteModal
          id={deleting.data.id}
          name={`${deleting.data.Section} ${t('ofStructure')} ${deleting.data.Structure}`}
          setShowModal={setShowModulesModal}
          setDeleting={setDeleting}
          type="Section"
          title={t('Section')}
        />
      )}
    </AdminContainer>
  );
}

export default Sections;
