import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type DeleteGistmFileMutationVariables = Types.Exact<{
  fileId: Types.Scalars['String']['input'];
}>;


export type DeleteGistmFileMutation = { __typename?: 'Mutation', deleteGistmFile: string };


export const DeleteGistmFileDocument = gql`
    mutation DeleteGistmFile($fileId: String!) {
  deleteGistmFile(fileId: $fileId)
}
    `;
export type DeleteGistmFileMutationFn = Apollo.MutationFunction<DeleteGistmFileMutation, DeleteGistmFileMutationVariables>;
export type DeleteGistmFileMutationResult = Apollo.MutationResult<DeleteGistmFileMutation>;
export type DeleteGistmFileMutationOptions = Apollo.BaseMutationOptions<DeleteGistmFileMutation, DeleteGistmFileMutationVariables>;