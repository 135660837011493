import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type DeleteStructureMutationVariables = Types.Exact<{
  structureId: Types.Scalars['String']['input'];
}>;


export type DeleteStructureMutation = { __typename?: 'Mutation', deleteStructure: string };


export const DeleteStructureDocument = gql`
    mutation DeleteStructure($structureId: String!) {
  deleteStructure(structureId: $structureId)
}
    `;
export type DeleteStructureMutationFn = Apollo.MutationFunction<DeleteStructureMutation, DeleteStructureMutationVariables>;
export type DeleteStructureMutationResult = Apollo.MutationResult<DeleteStructureMutation>;
export type DeleteStructureMutationOptions = Apollo.BaseMutationOptions<DeleteStructureMutation, DeleteStructureMutationVariables>;