import { EditInstrumentTemplateProps } from './EditInstrumentInterface';
import {
  DivHeaderContainer,
  DivPageContent,
  MainContainer
} from './EditInstrumentsTemplateStyles';
const EditInstrumentTemplate = ({
  content,
  header
}: EditInstrumentTemplateProps) => {
  return (
    <MainContainer>
      <DivHeaderContainer>{header}</DivHeaderContainer>
      <DivPageContent>{content}</DivPageContent>
    </MainContainer>
  );
};

export default EditInstrumentTemplate;
