import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type EditStructureHydrologyMutationVariables = Types.Exact<{
  data: Types.EditHydrologyInput;
}>;


export type EditStructureHydrologyMutation = { __typename?: 'Mutation', editStructureHydrology: string };


export const EditStructureHydrologyDocument = gql`
    mutation EditStructureHydrology($data: EditHydrologyInput!) {
  editStructureHydrology(data: $data)
}
    `;
export type EditStructureHydrologyMutationFn = Apollo.MutationFunction<EditStructureHydrologyMutation, EditStructureHydrologyMutationVariables>;
export type EditStructureHydrologyMutationResult = Apollo.MutationResult<EditStructureHydrologyMutation>;
export type EditStructureHydrologyMutationOptions = Apollo.BaseMutationOptions<EditStructureHydrologyMutation, EditStructureHydrologyMutationVariables>;