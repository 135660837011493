import styled from 'styled-components';

export const DivContainer = styled.div`
  background-color: #ffff;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  box-sizing: border-box;
`;

export const DivData = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 20px;
`;
