import React from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultButtonStyle } from './ButtonsStyle';

export const DefaultButton = ({
  title,
  action
}: {
  title: string;
  action: () => void;
}) => {
  const { t } = useTranslation();

  return <DefaultButtonStyle onClick={action}>{t(title)}</DefaultButtonStyle>;
};
