import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type EditStructureRiskAssessmentMutationVariables = Types.Exact<{
  data: Types.EditRiskAssessmentInput;
}>;


export type EditStructureRiskAssessmentMutation = { __typename?: 'Mutation', editStructureRiskAssessment: string };


export const EditStructureRiskAssessmentDocument = gql`
    mutation EditStructureRiskAssessment($data: EditRiskAssessmentInput!) {
  editStructureRiskAssessment(data: $data)
}
    `;
export type EditStructureRiskAssessmentMutationFn = Apollo.MutationFunction<EditStructureRiskAssessmentMutation, EditStructureRiskAssessmentMutationVariables>;
export type EditStructureRiskAssessmentMutationResult = Apollo.MutationResult<EditStructureRiskAssessmentMutation>;
export type EditStructureRiskAssessmentMutationOptions = Apollo.BaseMutationOptions<EditStructureRiskAssessmentMutation, EditStructureRiskAssessmentMutationVariables>;