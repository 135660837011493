import { StructureConatiner } from '../../../Screens/AdminScreen/AdminScreenStyle';
import Modules from './Modules/Modules';
import Sections from './Sections/Sections';
import Structures from './Structures/Structures';

function Structure() {
  return (
    <StructureConatiner>
      <Structures />
      <Sections />
      <Modules />
    </StructureConatiner>
  );
}

export default Structure;
