export interface AddressData {
  id: string;
  country: string;
  state: string;
  city: string;
  address: string;
  number: number | string;
}

export const __initialAddressData = {
  id: '',
  country: '',
  state: '',
  city: '',
  address: '',
  number: ''
};

export interface ClientData {
  id: string;
  address: {
    country: string;
    state: string;
    city: string;
    address: string;
    number: number | null;
    zipCode: string;
  };
  name: string;
  cnpj: string;
}

export const __initialClientData = {
  id: '',
  address: {
    country: '',
    state: '',
    city: '',
    address: '',
    number: null,
    zipCode: ''
  },
  name: '',
  cnpj: ''
};
export interface Mandatory {
  address: {
    country: boolean;
    state: boolean;
    city: boolean;
    address: boolean;
    number: boolean;
    zipCode: boolean;
  };
  name: boolean;
  cnpj: boolean;
  logo: boolean;
}

export const __initialMandatory = {
  address: {
    country: true,
    state: true,
    city: true,
    address: true,
    number: true,
    zipCode: true
  },
  name: true,
  cnpj: true,
  logo: true
};
