import styled from 'styled-components';

export const HolderExports = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const DivBody = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: auto;
`;

export const HolderFooter = styled.div`
  height: 20%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const HolderCheckboxLis = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
`;

export const HolderInputsForm = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
  padding: 20px 20px 10px 20px;
  box-sizing: border-box;
`;

export const HolderCheckbox = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 5px;
  white-space: nowrap;
  box-sizing: border-box;
`;

export const DivInputs = styled.div`
  width: min(100%, 315px);
  min-height: 60px;
`;
