import { useMutation } from '@apollo/client';
import {
  CreateUserDocument,
  CreateUserMutation,
  CreateUserMutationVariables
} from '../../graphql/generated/createUser.mutation';
import { ListCompaniesDocument } from '../../graphql/query/generated/listCompanies.query';
import { ListCompanyRelatedUsersAndStructuresDocument } from '../../graphql/query/generated/listCompanyRelatedUsersAndStructures.query';
import { ListModulesRolesDocument } from '../../graphql/query/generated/listModulesRole.query';
import { ListStructuresDocument } from '../../graphql/query/generated/listStructures.query';
import { ListUsersDocument } from '../../graphql/query/generated/listUsers.query';
import { GetUserStructuresListDocument } from '../../graphql/query/generated/structureList.query';
import { GetStructureSummaryDocument } from '../../graphql/query/generated/structureSummary.query';

export function useCreateUser(onCompleted?: () => void, onError?: () => void) {
  const [createUser, { data, error, loading }] = useMutation<
    CreateUserMutation,
    CreateUserMutationVariables
  >(CreateUserDocument, {
    refetchQueries: [
      ListStructuresDocument,
      ListCompaniesDocument,
      ListUsersDocument,
      ListModulesRolesDocument,
      GetUserStructuresListDocument,
      ListCompanyRelatedUsersAndStructuresDocument,
      GetStructureSummaryDocument
    ],
    awaitRefetchQueries: true,
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    createUser,
    data,
    error,
    loading
  };
}
