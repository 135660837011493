import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type DeleteUserPermissionMutationVariables = Types.Exact<{
  data: Types.DeleteUserPermissionInput;
}>;


export type DeleteUserPermissionMutation = { __typename?: 'Mutation', deleteUserPermission: string };


export const DeleteUserPermissionDocument = gql`
    mutation DeleteUserPermission($data: DeleteUserPermissionInput!) {
  deleteUserPermission(data: $data)
}
    `;
export type DeleteUserPermissionMutationFn = Apollo.MutationFunction<DeleteUserPermissionMutation, DeleteUserPermissionMutationVariables>;
export type DeleteUserPermissionMutationResult = Apollo.MutationResult<DeleteUserPermissionMutation>;
export type DeleteUserPermissionMutationOptions = Apollo.BaseMutationOptions<DeleteUserPermissionMutation, DeleteUserPermissionMutationVariables>;