import { useMutation } from '@apollo/client';
import {
  CreateSurfaceMarkerDocument,
  CreateSurfaceMarkerMutation,
  CreateSurfaceMarkerMutationVariables
} from '../../graphql/generated/addSurfaceMarker';
import { ListCompaniesDocument } from '../../graphql/query/generated/listCompanies.query';
import { ListInstrumentsByStructureDocument } from '../../graphql/query/generated/listInstrumentsByStructure.query';
import { ListSurfaceMarkersByStructureDocument } from '../../graphql/query/generated/listSurfaceMarkersByStructure.query';

export function useAddSurfaceMarker(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [addSurfaceMarker, { data, error, loading }] = useMutation<
    CreateSurfaceMarkerMutation,
    CreateSurfaceMarkerMutationVariables
  >(CreateSurfaceMarkerDocument, {
    refetchQueries: [
      ListSurfaceMarkersByStructureDocument,
      ListCompaniesDocument,
      ListInstrumentsByStructureDocument
    ],
    awaitRefetchQueries: true,
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    addSurfaceMarker,
    data,
    error,
    loading
  };
}
