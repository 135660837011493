import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type DeleteStructureDamRaisingMutationVariables = Types.Exact<{
  damRaisingId: Types.Scalars['String']['input'];
  structureId: Types.Scalars['String']['input'];
}>;


export type DeleteStructureDamRaisingMutation = { __typename?: 'Mutation', deleteStructureDamRaising: string };


export const DeleteStructureDamRaisingDocument = gql`
    mutation DeleteStructureDamRaising($damRaisingId: String!, $structureId: String!) {
  deleteStructureDamRaising(
    damRaisingId: $damRaisingId
    structureId: $structureId
  )
}
    `;
export type DeleteStructureDamRaisingMutationFn = Apollo.MutationFunction<DeleteStructureDamRaisingMutation, DeleteStructureDamRaisingMutationVariables>;
export type DeleteStructureDamRaisingMutationResult = Apollo.MutationResult<DeleteStructureDamRaisingMutation>;
export type DeleteStructureDamRaisingMutationOptions = Apollo.BaseMutationOptions<DeleteStructureDamRaisingMutation, DeleteStructureDamRaisingMutationVariables>;