import { useQuery } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsCardList, BsClipboard, BsFillPersonFill } from 'react-icons/bs';
import { CgNotes } from 'react-icons/cg';
import { GiBigWave, GiDam } from 'react-icons/gi';
import { GoAlert, GoMegaphone } from 'react-icons/go';
import {
  HiOutlineDocumentReport,
  HiOutlinePresentationChartLine
} from 'react-icons/hi';
import { MdAdminPanelSettings } from 'react-icons/md';
import { SiAnalogue, SiBookstack } from 'react-icons/si';
import { SlNotebook } from 'react-icons/sl';
import { VscGraph } from 'react-icons/vsc';
import { useNavigate, useParams } from 'react-router-dom';
import { ModuleData, UserData } from '../../@Types/types';
import { toastfyDimiss, toastfyError } from '../../Components/Toastify';
import { ProjectContext } from '../../Context/ContextAPI';
import {
  ListModulesDocument,
  ListModulesQuery,
  ListModulesQueryVariables
} from '../../data/graphql/query/generated/listModules';
import ToastifyModel from '../../Models/ToastifyModel';
import { Color, IconStyle } from '../../Styles/Styles';
import {
  Icon,
  LateralDiv,
  ModuleName,
  StructureAreaDiv
} from './StructureScreenStyle';

function StructureScreen() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { structureId } = useParams();
  const [hoveredModuleName, setHoveredModuleName] = useState<string>('');
  const { userData, modulesByStructure } = useContext(ProjectContext) as {
    userData: UserData;
    modulesByStructure: ModuleData[];
  };
  const { data: modulesInfo, error } = useQuery<
    ListModulesQuery,
    ListModulesQueryVariables
  >(ListModulesDocument, {
    errorPolicy: 'all',
    onCompleted: () => {
      toastfyDimiss('toastLoading');
    },
    onError: () => {
      toastfyError(error?.message || t(ToastifyModel().toastifyMessage.error));
      toastfyDimiss('toastLoading');
    }
  });
  const urlPath = location.pathname.split('/')[2];
  const urlPathAdmin = location.pathname.split('/')[1];
  const [modulesIcon] = useState([
    { icon: <BsCardList />, index: 1 }, // General
    { icon: <VscGraph />, index: 2 }, // Monitoring
    { icon: <BsClipboard />, index: 3 }, // Inspections
    { icon: <HiOutlinePresentationChartLine />, index: 4 }, // Action Plan
    { icon: <SlNotebook />, index: 5 }, // Stability
    { icon: <HiOutlineDocumentReport />, index: 6 }, // EoR
    { icon: <BsFillPersonFill />, index: 7 }, // ATO
    { icon: <GoMegaphone />, index: 8 }, // PSB
    { icon: <SiAnalogue />, index: 9 }, // GISTM
    { icon: <GiBigWave />, index: 11 }, // PAEBM
    { icon: <SiBookstack />, index: 10 }, // Dam Break
    { icon: <GoAlert />, index: 12 }, // Risk analysis
    { icon: <CgNotes />, index: 13 }, // Report
    { icon: <GiDam />, index: 14 } // Dashboard
  ]);
  const sidebarMenu = modulesInfo?.listModules.map((module) => ({
    id: module.id,
    name: module.name,
    index: module.index,
    icon: modulesIcon.find((icon) => icon.index === module.index)?.icon || (
      <VscGraph />
    )
  }));

  return (
    <StructureAreaDiv>
      <LateralDiv>
        {sidebarMenu?.map((object, index) => (
          <Icon
            style={{ zIndex: 1000 }}
            key={index}
            onMouseEnter={() => {
              setHoveredModuleName(object.name);
            }}
            onMouseLeave={() => setHoveredModuleName('')}
          >
            {modulesByStructure.some(
              (modules) =>
                modules.moduleId === object.id && modules.role !== 'forbidden'
            )
              ? React.cloneElement(object.icon, {
                  style: {
                    ...IconStyle,
                    color:
                      urlPath?.toLocaleLowerCase() ===
                      object.name.toLocaleLowerCase().replace(' ', '')
                        ? Color.Brown1
                        : 'unset'
                  },
                  onClick: () => {
                    const selectedModule = modulesByStructure.find(
                      (module) => module.moduleId === object.id
                    );
                    navigate(
                      `${structureId}/${selectedModule?.moduleName.toLocaleLowerCase()}`
                    );
                  }
                })
              : React.cloneElement(object.icon, {
                  style: { ...IconStyle, color: Color.Disabled }
                })}
            {hoveredModuleName === object.name && (
              <ModuleName
                style={{
                  color: modulesByStructure.some(
                    (modules) =>
                      modules.moduleId === object.id &&
                      modules.role !== 'forbidden'
                  )
                    ? urlPath?.toLocaleLowerCase() ===
                      object.name.toLocaleLowerCase().replace(' ', '')
                      ? Color.White
                      : 'unset'
                    : Color.Disabled,
                  backgroundColor: modulesByStructure.some(
                    (modules) =>
                      modules.moduleId === object.id &&
                      modules.role !== 'forbidden'
                  )
                    ? urlPath?.toLocaleLowerCase() ===
                      object.name.toLocaleLowerCase().replace(' ', '')
                      ? Color.Brown1
                      : Color.backgroundColor
                    : Color.backgroundColor
                }}
              >
                {t(object.name)}
              </ModuleName>
            )}
          </Icon>
        ))}
        {userData.role === 'owner' ? (
          <Icon
            onMouseEnter={() => {
              setHoveredModuleName('Admin');
            }}
            onMouseLeave={() => setHoveredModuleName('')}
          >
            <MdAdminPanelSettings
              onClick={() => navigate('/admin')}
              style={{
                ...IconStyle,
                color: urlPathAdmin === 'admin' ? Color.Brown1 : 'unset'
              }}
            />
            {hoveredModuleName === 'Admin' && (
              <ModuleName
                style={{
                  color: urlPathAdmin === 'Admin' ? Color.White : 'unset',
                  backgroundColor:
                    urlPathAdmin === 'Admin'
                      ? Color.Brown1
                      : Color.backgroundColor
                }}
              >
                Admin
              </ModuleName>
            )}
          </Icon>
        ) : null}
      </LateralDiv>
    </StructureAreaDiv>
  );
}

export default StructureScreen;
