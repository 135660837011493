import { gql, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';
import { client } from '../../../../data/apollo.client';
import {
  SaveRecordWorkShiftDocument,
  SaveRecordWorkShiftMutation,
  SaveRecordWorkShiftMutationVariables
} from '../../../../data/graphql/generated/saveRecordWorkShift';
import {
  UpdateRecordWorkShiftDocument,
  UpdateRecordWorkShiftMutation,
  UpdateRecordWorkShiftMutationVariables
} from '../../../../data/graphql/generated/updateRecordWorkShift';
import {
  FindDataByRecordDocument,
  FindDataByRecordQuery
} from '../../../../data/graphql/query/generated/findDataByRecord';
import ErrorsTreatments from '../../../../utils/errorTreatment';
import { toastfyError, toastfySuccess } from '../../../Toastify';
import Button from '../../Atoms/Button/Button';
import DatepickerInput from '../../Molecules/DatepickerInput/DatepickerInput';
import {
  WorkScheduleProps,
  WorkShiftsItem
} from './AddWorkScheduleAtoForm.interface';
import {
  DivButtonArea,
  DivInputsArea,
  HolderForm
} from './AddWorkScheduleAtoForm.styles';

const queryWorkShift = gql`
  query FindDataByRecord($recordId: String!) {
    findDataByRecord(recordId: $recordId) {
      workShifts {
        id
        entry
        entryInterval
        exit
        exitInterval
        observation
        totalHours
      }
      recordDate
    }
  }
`;

const WorkScheduleSchema = z.object({
  entryExitBeginning: z
    .date()
    .nullable()
    .refine((val) => val !== null, {
      message: 'fieldIsRequired'
    }),
  entryExitEnding: z
    .date()
    .nullable()
    .refine((val) => val !== null, {
      message: 'fieldIsRequired'
    }),
  intervalBeginning: z
    .date()
    .nullable()
    .refine((val) => val !== null, {
      message: 'fieldIsRequired'
    }),
  intervalEnding: z
    .date()
    .nullable()
    .refine((val) => val !== null, {
      message: 'fieldIsRequired'
    })
});

type WorkScheduleSchemaType = z.infer<typeof WorkScheduleSchema>;

const AddWorkScheduleAtoForm = ({
  setShowModal,
  recordId,
  editWorkSchedule,
  verifyIfDataExists
}: WorkScheduleProps) => {
  const { t } = useTranslation();
  const [saveWorkShift] = useMutation<
    SaveRecordWorkShiftMutation,
    SaveRecordWorkShiftMutationVariables
  >(SaveRecordWorkShiftDocument);
  const [updateWorkShift] = useMutation<
    UpdateRecordWorkShiftMutation,
    UpdateRecordWorkShiftMutationVariables
  >(UpdateRecordWorkShiftDocument);

  const [getWorkShift] = useLazyQuery(queryWorkShift);
  const { data: dataWorkShift } = useQuery(queryWorkShift);

  const editWorkShift = dataWorkShift?.findDataByRecord?.workShifts;

  const rdoDate = dataWorkShift?.findDataByRecord?.recordDate;

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<WorkScheduleSchemaType>({
    resolver: zodResolver(WorkScheduleSchema),
    defaultValues: async () => {
      const responseWorkShift = await getWorkShift({
        variables: {
          recordId: recordId
        }
      });

      const editWorkSchedule =
        responseWorkShift.data?.findDataByRecord?.workShifts;

      const rdoDate = responseWorkShift.data?.findDataByRecord?.recordDate;

      if (editWorkSchedule.length > 0) {
        return {
          entryExitBeginning: new Date(editWorkSchedule[0].entry),
          entryExitEnding: new Date(editWorkSchedule[0].exit),
          intervalBeginning: new Date(editWorkSchedule[0].entryInterval),
          intervalEnding: new Date(editWorkSchedule[0].exitInterval)
        };
      } else {
        return {
          entryExitBeginning: new Date(rdoDate),
          entryExitEnding: new Date(rdoDate),
          intervalBeginning: new Date(rdoDate),
          intervalEnding: new Date(rdoDate)
        };
      }
    }
  });

  const handleSubmitWorkSchedule = (data: WorkScheduleSchemaType) => {
    const {
      entryExitBeginning,
      entryExitEnding,
      intervalBeginning,
      intervalEnding
    } = data;

    if (editWorkSchedule?.id) {
      updateWorkShift({
        variables: {
          data: {
            observation: '',
            id: editWorkSchedule?.id,
            exitInterval: intervalEnding,
            exit: entryExitEnding,
            entryInterval: intervalBeginning,
            entry: entryExitBeginning
          }
        },
        onCompleted: () => {
          setShowModal(false);
          toastfySuccess(t('editedSuccessfully'));
        },
        onError: ({ graphQLErrors }) => {
          const errorMessage = ErrorsTreatments(graphQLErrors[0].message, t);
          toastfyError(errorMessage);
        },
        update: (cache, { data }) => {
          if (!data) return;
          const existingData = cache.readQuery({
            query: FindDataByRecordDocument,
            variables: {
              recordId: recordId
            }
          }) as FindDataByRecordQuery;

          const updatedData = {
            ...existingData,
            findDataByRecord: {
              ...existingData.findDataByRecord,
              workShifts: existingData.findDataByRecord.workShifts.map(
                (item: WorkShiftsItem) =>
                  item.id === editWorkSchedule?.id
                    ? {
                        ...item,
                        observation: '',
                        id: editWorkSchedule?.id,
                        exitInterval: intervalEnding,
                        exit: entryExitEnding,
                        entryInterval: intervalBeginning,
                        entry: entryExitBeginning,
                        __typename: 'AtoRecordWorkShiftType'
                      }
                    : item
              )
            }
          };

          cache.writeQuery({
            query: FindDataByRecordDocument,
            variables: {
              recordId: recordId
            },
            data: updatedData
          });
        }
      });
    } else {
      if (!verifyIfDataExists) {
        saveWorkShift({
          variables: {
            data: {
              entry: entryExitBeginning,
              exit: entryExitEnding,
              entryInterval: intervalBeginning,
              exitInterval: intervalEnding,
              observation: '',
              recordId: recordId || ''
            }
          },
          onCompleted: async () => {
            await client.refetchQueries({
              include: [
                {
                  query: FindDataByRecordDocument,
                  variables: {
                    recordId: recordId
                  },
                  awaitRefetchQueries: true
                }
              ]
            });
            setShowModal(false);
            toastfySuccess(t('registeredSuccessfully'));
          },
          onError: ({ graphQLErrors }) => {
            const errorMessage = ErrorsTreatments(graphQLErrors[0].message, t);
            toastfyError(errorMessage);
          }
        });
      } else {
        setShowModal(false);
        toastfyError(t('dataAlreadyExists'));
      }
    }
  };

  return (
    <HolderForm>
      <DivInputsArea>
        <DatepickerInput
          placeholder={t('entryExitBeginning')}
          enableMinMaxDateSelection={true}
          minDate={
            editWorkShift ? new Date(editWorkShift[0].entry) : new Date(rdoDate)
          }
          maxDate={
            editWorkShift ? new Date(editWorkShift[0].entry) : new Date(rdoDate)
          }
          time={true}
          error={!!errors.entryExitBeginning}
          errorMessage={errors.entryExitBeginning?.message}
          control={control}
          name="entryExitBeginning"
          label={t('entryExitBeginning')}
        />
        <DatepickerInput
          placeholder={t('entryExitEnding')}
          enableMinMaxDateSelection={true}
          minDate={
            editWorkShift ? new Date(editWorkShift[0].exit) : new Date(rdoDate)
          }
          maxDate={
            editWorkShift ? new Date(editWorkShift[0].exit) : new Date(rdoDate)
          }
          time={true}
          error={!!errors.entryExitEnding}
          errorMessage={errors.entryExitEnding?.message}
          control={control}
          name="entryExitEnding"
          label={t('entryExitEnding')}
        />
        <DatepickerInput
          placeholder={t('intervalBeginning')}
          enableMinMaxDateSelection={true}
          minDate={
            editWorkShift
              ? new Date(editWorkShift[0].entryInterval)
              : new Date(rdoDate)
          }
          maxDate={
            editWorkShift
              ? new Date(editWorkShift[0].entryInterval)
              : new Date(rdoDate)
          }
          time={true}
          error={!!errors.intervalBeginning}
          errorMessage={errors.intervalBeginning?.message}
          control={control}
          name="intervalBeginning"
          label={t('intervalBeginning')}
        />
        <DatepickerInput
          placeholder={t('intervalEnding')}
          enableMinMaxDateSelection={true}
          minDate={
            editWorkShift
              ? new Date(editWorkShift[0].exitInterval)
              : new Date(rdoDate)
          }
          maxDate={
            editWorkShift
              ? new Date(editWorkShift[0].exitInterval)
              : new Date(rdoDate)
          }
          time={true}
          error={!!errors.intervalEnding}
          errorMessage={errors.intervalEnding?.message}
          control={control}
          name="intervalEnding"
          label={t('intervalEnding')}
        />
      </DivInputsArea>
      <DivButtonArea>
        <Button
          text={t('Cancel')}
          variant={'secondary'}
          size="small"
          onClick={() => {
            setShowModal(false);
          }}
        />
        <Button
          text={t('Save')}
          variant={'primary'}
          size="small"
          onClick={handleSubmit(handleSubmitWorkSchedule)}
        />
      </DivButtonArea>
    </HolderForm>
  );
};

export default AddWorkScheduleAtoForm;
