import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultInput } from '../../../Components/Inputs/InputsStyle';
import { toastfyDimiss, toastifyLoading } from '../../../Components/Toastify';
import { useAddParticipants } from '../../../data/hooks/use-add.participants';
import {
  CustomRegisterParticipantArea,
  TextButtonInspection
} from '../InspectionsStyle';
import { AttributionInspectionTable } from './AttributionInspectionTable';

export interface Participant {
  name: string;
  id: string;
}

export const AddParticipantComponent = ({
  setSubParticipants,
  subParticipants,
  editing,
  inspectionId,
  selectedInspection
}: {
  setSubParticipants: Dispatch<SetStateAction<any[]>>;
  subParticipants: any[];
  editing: boolean;
  inspectionId: string;
  selectedInspection: any;
}) => {
  const { t } = useTranslation();
  const [participantName, setParticipantName] = useState('');
  const { addParticipants } = useAddParticipants();

  const addParticipant = async () => {
    if (editing && participantName !== '') {
      const response = await addParticipants({
        variables: {
          inspectionId: inspectionId,
          participants: [
            {
              name: participantName
            }
          ]
        }
      });
      toastifyLoading('Cadastrando nome...');
      if (response.data) {
        setSubParticipants((prevParticipants) => [
          ...prevParticipants,
          { name: participantName }
        ]);
        toastfyDimiss('toastLoading');
      }
    } else {
      if (participantName.trim() !== '') {
        setSubParticipants((prevParticipants) => [
          ...prevParticipants,
          { name: participantName }
        ]);
        setParticipantName('');
      }
    }
  };

  useEffect(() => {
    if (selectedInspection?.participants) {
      setSubParticipants(selectedInspection.participants);
    } else {
      setSubParticipants([]);
    }
  }, [selectedInspection]);

  return (
    <CustomRegisterParticipantArea>
      <DefaultInput
        style={{ width: '293px' }}
        placeholder={`${t('AddParticipant')} (${t('name')})`}
        value={participantName}
        onChange={(e) => {
          setParticipantName(e.target.value);
        }}
      />
      <TextButtonInspection
        onClick={() => {
          addParticipant();
        }}
      >
        {t('AddParticipant')}
      </TextButtonInspection>
      <AttributionInspectionTable
        subParticipants={subParticipants}
        setSubParticipants={setSubParticipants}
        editing={editing}
      />
      {subParticipants.length === 0 && <p>{t('NoParticipantsEntered')}</p>}
    </CustomRegisterParticipantArea>
  );
};
