import React from 'react';
import { RegisterInstrumnetInfosInterface } from '../../../@Types/Instruments/instruments';
import { instrumentsEnum } from '../instrumentsEnum';
import { RegisterPiezometerComponent } from './Piezometer/RegisterPiezometerComponent';
import { RegisterPluviometerComponent } from './Pluviometer/RegisterPluviometerComponent';
import { RegisterSurfaceMarkerComponent } from './SurfaceLandmark/RegisterSurfaceLandmarkComponent';
import { RegisterWaterLevelComponent } from './waterLevel/RegisterWaterLevel';

export const RegisterInstrumentInfos = (
  props: RegisterInstrumnetInfosInterface
) => {
  const {
    instrumentSelected,
    setInstrumentEspecificData,
    instrumentEspecificData,
    specificInfosError,
    isEditing,
    crossSection,
    longitudinalSection,
    setCrossSection,
    setLongitudinalSection
  } = props;
  switch (instrumentSelected as instrumentsEnum) {
    case instrumentsEnum.piezometer:
      return (
        <RegisterPiezometerComponent
          isEditing={isEditing}
          instrumentEspecificData={instrumentEspecificData}
          setInstrumentEspecificData={setInstrumentEspecificData}
          specificInfosError={specificInfosError}
          crossSection={crossSection}
          longitudinalSection={longitudinalSection}
          setCrossSection={setCrossSection}
          setLongitudinalSection={setLongitudinalSection}
        />
      );
    case instrumentsEnum.ina:
      return (
        <>
          <RegisterPiezometerComponent
            isEditing={isEditing}
            instrumentEspecificData={instrumentEspecificData}
            setInstrumentEspecificData={setInstrumentEspecificData}
            specificInfosError={specificInfosError}
            crossSection={crossSection}
            setCrossSection={setCrossSection}
            longitudinalSection={longitudinalSection}
            setLongitudinalSection={setLongitudinalSection}
          />
        </>
      );
    case instrumentsEnum.pluviometer:
      return (
        <RegisterPluviometerComponent
          instrumentEspecificData={instrumentEspecificData}
          setInstrumentEspecificData={setInstrumentEspecificData}
        />
      );
    case instrumentsEnum.waterLevel:
      return (
        <RegisterWaterLevelComponent
          instrumentEspecificData={instrumentEspecificData}
          setInstrumentEspecificData={setInstrumentEspecificData}
        />
      );
    case instrumentsEnum.surfaceMarker:
      return (
        <RegisterSurfaceMarkerComponent
          isEditing={isEditing}
          instrumentEspecificData={instrumentEspecificData}
          setInstrumentEspecificData={setInstrumentEspecificData}
          specificInfosError={specificInfosError}
        />
      );
    default:
      return <></>;
  }
};
