import styled from 'styled-components';

export const HolderForm = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 25px;
  box-sizing: border-box;
  padding: 10px 20px;
`;

export const HolderFooter = styled.div`
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const DivInput = styled.div`
  width: min(100%, 400px);
`;
