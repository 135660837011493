import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type EditWaterLevelReadingMutationVariables = Types.Exact<{
  structureInfo: Types.StructureInfoInput;
  data: Types.EditWaterLevelReadingInput;
}>;


export type EditWaterLevelReadingMutation = { __typename?: 'Mutation', editWaterLevelReading: string };


export const EditWaterLevelReadingDocument = gql`
    mutation EditWaterLevelReading($structureInfo: StructureInfoInput!, $data: EditWaterLevelReadingInput!) {
  editWaterLevelReading(structureInfo: $structureInfo, data: $data)
}
    `;
export type EditWaterLevelReadingMutationFn = Apollo.MutationFunction<EditWaterLevelReadingMutation, EditWaterLevelReadingMutationVariables>;
export type EditWaterLevelReadingMutationResult = Apollo.MutationResult<EditWaterLevelReadingMutation>;
export type EditWaterLevelReadingMutationOptions = Apollo.BaseMutationOptions<EditWaterLevelReadingMutation, EditWaterLevelReadingMutationVariables>;