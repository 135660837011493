/* eslint-disable react/react-in-jsx-scope */
import {
  HolderInstrumentInputRightSide,
  HolderInstrumentInputsLeftSide,
  RegisterInstrumentInputs,
  RegisterNumericStyled
} from './RegisterInstrumentStyle';
import InstrumentsModel from '../../../Models/InstrumentsModel';
import Toggles from '../../Buttons/Toggles/Toggles';
import { DatePickerComponent } from '../../DatePicker';
import { useTranslation } from 'react-i18next';
import { RegisterBasicInfosInterface } from '../../../@Types/Instruments/instruments';
import InstrumentSelects from '../../Selects/Instruments/InstrumentsSelects';
import { DefaultInput } from '../../Inputs/InputsStyle';
import { useEffect, useState } from 'react';
import InputFileWithList from '../../V2/Organisms/InputFileWithList/InputFileWithList';
import { FileType } from '../../V2/Molecules/FileViewer/FileViewer.interfaces';

export const RegisterBasicInfos = (props: RegisterBasicInfosInterface) => {
  const {
    setReadingModeSelected,
    installationDate,
    setInstallDate,
    activationDate,
    setActivationDate,
    setInstallationLocationSelected,
    readingModeSelected,
    installationLocationSelected,
    setBasicInfos,
    basicInfos,
    basicInfosError
  } = props;

  const { t } = useTranslation();

  const [files, setFiles] = useState<FileType[]>(
    basicInfos.docs && basicInfos.docs.length > 0
      ? basicInfos.docs.map((file) => {
          return {
            id: null,
            name: file.name,
            file: file,
            url: URL.createObjectURL(file)
          };
        })
      : []
  );

  useEffect(() => {
    setBasicInfos('activationDate', new Date(activationDate).toISOString());
    setBasicInfos('installationDate', new Date(installationDate).toISOString());
  }, [activationDate, installationDate]);

  const handleFileChange = (newFiles: FileList) => {
    const updateFiles = Array.from(newFiles).map((file) => ({
      id: null,
      name: file.name,
      file: file,
      url: URL.createObjectURL(file)
    }));

    setFiles((prev) => [...prev, ...updateFiles]);

    setBasicInfos('docs', [
      ...files.map((file) => file.file),
      ...updateFiles.map((file) => file.file)
    ]);
  };

  const handleDeleteFile = (fileToDelete: FileType) => {
    const updateFiles = files.filter((file) => file.file !== fileToDelete.file);
    setFiles(updateFiles);
    setBasicInfos('docs', updateFiles);
  };

  return (
    <RegisterInstrumentInputs>
      <HolderInstrumentInputsLeftSide>
        <DefaultInput
          style={{
            width: '90%',
            padding: '10px 10px 2px 5px',
            border: 'none',
            borderBottom: '1px solid',
            margin: '0px'
          }}
          onChange={(e) => {
            setBasicInfos('name', e.target.value);
          }}
          placeholder={t('name')}
          value={basicInfos.name}
          error={!basicInfos.name && basicInfosError}
        />
        <RegisterNumericStyled
          maxLength={30}
          onChange={(e) => {
            setBasicInfos(
              'coordinateN',
              Number(e.target.value.replace(/,/g, ''))
            );
          }}
          placeholder={`${t('Coordinate')} N`}
          value={basicInfos.coordinateN}
          error={!basicInfos.coordinateN && basicInfosError}
        />

        <RegisterNumericStyled
          maxLength={30}
          placeholder={`${t('Coordinate')} E`}
          onChange={(e) => {
            setBasicInfos(
              'coordinateE',
              Number(e.target.value.replace(/,/g, ''))
            );
          }}
          value={basicInfos.coordinateE}
          error={!basicInfos.coordinateE && basicInfosError}
        />
        <div style={{ width: '90%' }}>
          {Toggles.ToggleStatus({
            check: basicInfos.isUTM,
            setCheck: (value) => setBasicInfos('isUTM', value),
            key: 'isUTM',
            updateValue: setBasicInfos,
            text: 'Coordenadas em UTM'
          })}
        </div>
        {InstrumentSelects.InstrumentType({
          width: 93.7,
          placeholder: t('InstallationLocation'),
          options: InstrumentsModel().instalationLocale,
          setSelected: setInstallationLocationSelected,
          selected: installationLocationSelected,
          key: 'installLocation',
          updateValue: setBasicInfos
        })}
      </HolderInstrumentInputsLeftSide>

      <HolderInstrumentInputRightSide>
        <div style={{ width: '90%' }}>
          {Toggles.ToggleStatus({
            check: basicInfos.status,
            setCheck: () => {},
            key: 'status',
            updateValue: setBasicInfos,
            text: 'Status'
          })}
        </div>
        <div style={{ width: '90%' }}>
          {t('installationDate')}:
          <DatePickerComponent
            dateText={t('installationDate')}
            startDate={installationDate}
            setStartDate={setInstallDate}
            showTimeSelect={false}
          />
        </div>
        <div style={{ width: '90%' }}>
          {t('activactionDate')}:
          <DatePickerComponent
            dateText={t('activactionDate')}
            startDate={activationDate}
            setStartDate={setActivationDate}
            showTimeSelect={false}
          />
        </div>
        {InstrumentSelects.InstrumentType({
          width: 93.7,
          placeholder: t('ReadingMode'),
          options: InstrumentsModel().readingMode,
          setSelected: setReadingModeSelected,
          selected: readingModeSelected,
          key: 'readingType',
          updateValue: setBasicInfos,
          error: !basicInfos.readingType && basicInfosError
        })}
        <InputFileWithList
          name="file"
          accept=".xlsx,.pdf,.docx,.xls,.xlsx,.doc"
          label={t('File')}
          files={files}
          onChange={(event) => {
            if (event.target.files) {
              handleFileChange(event.target.files);
            }
          }}
          canDelete
          onDelete={(file) => handleDeleteFile(file)}
        />
      </HolderInstrumentInputRightSide>
    </RegisterInstrumentInputs>
  );
};
