import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ListReadingByPluviometerQueryVariables = Types.Exact<{
  endDate: Types.Scalars['DateTime']['input'];
  startDate: Types.Scalars['DateTime']['input'];
  instrumentIds: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
}>;


export type ListReadingByPluviometerQuery = { __typename?: 'Query', listReadingByPluviometer: Array<{ __typename?: 'ChartPluviometerReadingType', instrumentId: string, name: string, readings: Array<{ __typename?: 'BasePluviometerReadingType', date: Date, id: string, observation?: string | null, rainfall: number }> }> };


export const ListReadingByPluviometerDocument = gql`
    query ListReadingByPluviometer($endDate: DateTime!, $startDate: DateTime!, $instrumentIds: [String!]!) {
  listReadingByPluviometer(
    endDate: $endDate
    startDate: $startDate
    instrumentIds: $instrumentIds
  ) {
    instrumentId
    name
    readings {
      date
      id
      observation
      rainfall
    }
  }
}
    `;
export type ListReadingByPluviometerQueryResult = Apollo.QueryResult<ListReadingByPluviometerQuery, ListReadingByPluviometerQueryVariables>;