export interface UsersData {
  companyId: string;
  email: string;
  name: string;
  phoneNumber: string;
  position: string;
  role: string;
  id: string;
}

export const __initialUserData = {
  companyId: '',
  email: '',
  name: '',
  phoneNumber: '',
  position: '',
  role: '',
  id: ''
};

export interface User {
  id: string;
  status: boolean;
  name: string;
  email: string;
  phoneNumber: string;
  position: string;
  company: {
    id: string;
    name: string;
    cnpj: string;
  };
  role: {
    role: string;
  };
}

export interface Mandatory {
  companyId: boolean;
  email: boolean;
  name: boolean;
  phoneNumber: boolean;
  position: boolean;
  role: boolean;
}

export const __initialMandatory = {
  companyId: true,
  email: true,
  name: true,
  phoneNumber: true,
  position: true,
  role: true
};
