import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type DeleteStructureModuleMutationVariables = Types.Exact<{
  deleteStructureModuleId: Types.Scalars['String']['input'];
}>;


export type DeleteStructureModuleMutation = { __typename?: 'Mutation', deleteStructureModule: string };


export const DeleteStructureModuleDocument = gql`
    mutation DeleteStructureModule($deleteStructureModuleId: String!) {
  deleteStructureModule(id: $deleteStructureModuleId)
}
    `;
export type DeleteStructureModuleMutationFn = Apollo.MutationFunction<DeleteStructureModuleMutation, DeleteStructureModuleMutationVariables>;
export type DeleteStructureModuleMutationResult = Apollo.MutationResult<DeleteStructureModuleMutation>;
export type DeleteStructureModuleMutationOptions = Apollo.BaseMutationOptions<DeleteStructureModuleMutation, DeleteStructureModuleMutationVariables>;