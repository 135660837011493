import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type AddWaterLevelAlertLevelsMutationVariables = Types.Exact<{
  structureId: Types.Scalars['String']['input'];
  data: Array<Types.CreatePluviometerAndWaterLevelAlertLevelInput> | Types.CreatePluviometerAndWaterLevelAlertLevelInput;
}>;


export type AddWaterLevelAlertLevelsMutation = { __typename?: 'Mutation', addWaterLevelAlertLevels: string };


export const AddWaterLevelAlertLevelsDocument = gql`
    mutation AddWaterLevelAlertLevels($structureId: String!, $data: [CreatePluviometerAndWaterLevelAlertLevelInput!]!) {
  addWaterLevelAlertLevels(structureId: $structureId, data: $data)
}
    `;
export type AddWaterLevelAlertLevelsMutationFn = Apollo.MutationFunction<AddWaterLevelAlertLevelsMutation, AddWaterLevelAlertLevelsMutationVariables>;
export type AddWaterLevelAlertLevelsMutationResult = Apollo.MutationResult<AddWaterLevelAlertLevelsMutation>;
export type AddWaterLevelAlertLevelsMutationOptions = Apollo.BaseMutationOptions<AddWaterLevelAlertLevelsMutation, AddWaterLevelAlertLevelsMutationVariables>;