import { useMutation, useQuery } from '@apollo/client';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdDelete, MdEdit } from 'react-icons/md';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  InputMaybe,
  InstrumentReadingTypeEnum,
  InstrumentStatusEnum,
  InstrumentTypeEnum
} from '../../../../data/graphql/base-schema';
import {
  DeleteInstrumentReadingDocument,
  DeleteInstrumentReadingMutation,
  DeleteInstrumentReadingMutationVariables
} from '../../../../data/graphql/generated/deleteInstrumentReading';
import {
  ListReadingByPiezometersPagDocument,
  ListReadingByPiezometersPagQuery,
  ListReadingByPiezometersPagQueryVariables
} from '../../../../data/graphql/query/generated/listReadingByPiezometerPag.query';
import ToastifyModel from '../../../../Models/ToastifyModel';
import { InstrumentTemplateTypeEnum } from '../../../../utils/registerReadingsTemplate';
import { toastfyError, toastfySuccess } from '../../../Toastify';
import Button from '../../Atoms/Button/Button';
import Text from '../../Atoms/Text/Text';
import { AvailableContextActionsProps } from '../../Molecules/ActionMenu/ActionMenu.interface';
import ModalConfirm from '../../Molecules/ModalConfirm/ModalConfirm';
import ViewModal from '../../Molecules/ViewModal/ViewModal';
import FilterReadingsPage from '../../Organisms/FilterReadingsPage/FilterReadingsPage';
import RegisterPiezometerInaBathReadings from '../../Organisms/RegisterPiezometerInaBathReadings/RegisterPiezometerInaBathReadings';
import SimpleContentTemplate from '../../Templates/SimpleContentTemplate/SimpleContentTemplate';
import { ReadingsTableData } from '../ReadingsPageWaterLevel/ReadingsTable.interface';
import { DivContainerTable, DivTable } from './ReadingPagePzINA.styles';
import ReadingPiezometerAndIna from '../../Organisms/ReadingPiezometerAndInaForm/ReadingPiezometerAndIna';
import { ReadingPzAndInaFormType } from '../../Organisms/ReadingPiezometerAndInaForm/ReadingPiezometerAndIna.interface';
import TableDraggableComponent from '../../Molecules/TableDraggable/TableDraggableComponent';
import Enums from '../../../../utils/enumns';
import { ProjectContext } from '../../../../Context/ContextAPI';
import Paginator from '../../Molecules/Paginator/Paginator';
import { TABLE_IDS } from '../../../../utils/TableConsts';
import ExportTableReadingPiezometerIna from '../../Organisms/ExportPiezometerIna/ExportTableReadingPiezometerIna';

const ReadingPagePzINA = () => {
  const { structureId, instrumentType } = useParams();
  const [searchParams] = useSearchParams();
  const { t: translate } = useTranslation();
  const { UserRoles } = Enums();
  const { getUserRoleByModule } = useContext(ProjectContext) as {
    getUserRoleByModule: (moduleName: string) => string;
  };
  const userRole = getUserRoleByModule('monitoring');
  const [showModal, setShowModal] = useState<boolean>(false);
  const [editPzAndIna, setEditPzAndIna] =
    useState<ReadingPzAndInaFormType | null>(null);
  const [modalReadingIsOpen, setModalReadingIsOpen] = useState(false);
  const [deleting, setDeleting] = useState({
    status: false,
    readingId: '',
    instrumentId: ''
  });
  const structureInfo = {
    structureId: structureId || '',
    associatedStructureId: null
  };
  const actionsTable: AvailableContextActionsProps<any>[] = [
    {
      name: `${translate('Edit')}`,
      icon: <MdEdit />,
      onClick: (reading) => handleEditPzAndIna(reading),
      canShow: 'canEdit'
    },
    {
      name: `${translate('Delete')}`,
      icon: <MdDelete />,
      onClick: (item: any) => {
        setDeleting({
          status: true,
          readingId: item.readingId,
          instrumentId: item.instrumentId
        });
      },
      canShow: 'canDelete'
    }
  ];

  const instrumentSelected =
    instrumentType === 'ina'
      ? InstrumentTypeEnum.Ina
      : InstrumentTypeEnum.Piezometer;

  const {
    data: dataReadingsByPiezometers,
    loading: loadingReadingsByPiezometers
  } = useQuery<
    ListReadingByPiezometersPagQuery,
    ListReadingByPiezometersPagQueryVariables
  >(ListReadingByPiezometersPagDocument, {
    variables: {
      instrumentType: instrumentSelected,
      structureInfo,
      pageInfo: {
        limit: parseInt(searchParams.get('totalForPage') || '10') as number,
        page: parseInt(searchParams.get('currentPage') || '1')
      },
      filters: {
        instrumentsId:
          (searchParams.get('instrumentId') as InputMaybe<string[]>) ||
          undefined,
        readingType:
          (searchParams.get('type') as InstrumentReadingTypeEnum) || undefined,
        status:
          (searchParams.get('status') as InstrumentStatusEnum) || undefined,
        name: searchParams.get('name') || undefined,
        endDate: searchParams.get('endDate')
          ? new Date(searchParams.get('endDate') as string)
          : undefined,
        startDate: searchParams.get('startDate')
          ? new Date(searchParams.get('startDate') as string)
          : undefined
      }
    }
  });

  const [deleteInstrument] = useMutation<
    DeleteInstrumentReadingMutation,
    DeleteInstrumentReadingMutationVariables
  >(DeleteInstrumentReadingDocument);
  const optionsPaginatorDefault = [
    { value: 10, label: '10' },
    { value: 50, label: '50' },
    { value: 100, label: '100' }
  ];
  const totalPages =
    dataReadingsByPiezometers?.listReadingByPiezometersPag.pageInfo
      .totalPages || 1;

  const tableData =
    dataReadingsByPiezometers?.listReadingByPiezometersPag.nodes?.map(
      (item) => ({
        readingId: item.readingId,
        instrumentId: item.instrumentId,
        name: item.name,
        date: item.date ? new Date(item.date).toLocaleDateString() : '-',
        activationDate: item.activationDate
          ? new Date(item.activationDate).toLocaleDateString()
          : '-',
        deactivationDate: item.deactivationDate
          ? new Date(item.deactivationDate).toLocaleDateString()
          : '-',
        readingType: translate(item.readingType) ?? '-',
        installationDate: item.installationDate,
        installLocation: item.installLocation,
        operationalStatus:
          translate(item.operationalStatus.toLowerCase()) ?? '-',
        coordinateE: item.coordinateE,
        coordinateN: item.coordinateN,
        bottomCote: item.bottomCote,
        topCote: item.topCote,
        structureType: item.structureType,
        cote: item.cote,
        reading: item.reading,
        isDry: item.isDry ? translate('yes') : translate('no'),
        observation: item.observation,
        isUTM: item.isUTM ? translate('yes') : translate('no')
      })
    );

  const columnsPiezometer = [
    { label: translate('name'), key: 'name' },
    { label: translate('date'), key: 'date' },
    { label: translate('activationDate'), key: 'activationDate' },
    { label: translate('deactivationDate'), key: 'deactivationDate' },
    { label: translate('readingType'), key: 'readingType' },
    { label: translate('installationDate'), key: 'installationDate' },
    { label: translate('installLocation'), key: 'installLocation' },
    { label: translate('operationalStatus'), key: 'operationalStatus' },
    { label: translate('coordinateE'), key: 'coordinateE' },
    { label: translate('coordinateN'), key: 'coordinateN' },
    { label: translate('bottomCote'), key: 'bottomCote' },
    { label: translate('topCote'), key: 'topCote' },
    { label: translate('structureType'), key: 'structureType' },
    { label: translate('cote'), key: 'cote' },
    { label: translate('reading'), key: 'reading' },
    { label: translate('isDry'), key: 'isDry' },
    { label: translate('Observation'), key: 'observation' },
    { label: translate('IsUTM'), key: 'isUTM' }
  ];

  const exportColumns = [
    { key: 'name', label: translate('name') },
    { key: 'date', label: translate('date') },
    { key: 'isDry', label: translate('isDry') },
    { key: 'cote', label: translate('cote') },
    { key: 'reading', label: translate('reading') },
    { key: 'isAutomatic', label: translate('readingType') },
    { key: 'operationalStatus', label: translate('operationalStatus') },
    { key: 'topCote', label: translate('topCote') },
    { key: 'bottomCote', label: translate('bottomCote') },
    { key: 'installationDate', label: translate('installationDate') },
    { key: 'installLocation', label: translate('installLocation') },
    { key: 'observation', label: translate('observation') }
  ];

  const handleDeleteReading = () => {
    deleteInstrument({
      variables: {
        structureId: structureId!,
        data: {
          readingId: deleting.readingId,
          instrumentId: deleting.instrumentId
        }
      },
      onCompleted: () => {
        setDeleting({
          status: false,
          readingId: '',
          instrumentId: ''
        });
        toastfySuccess(translate('deletedSuccessfully'));
      },
      onError: () => {
        setDeleting({
          status: false,
          readingId: '',
          instrumentId: ''
        });
        toastfyError(translate(ToastifyModel().toastifyMessage.error));
      },
      refetchQueries: [ListReadingByPiezometersPagDocument]
    });
  };

  const handleEditPzAndIna = (readingRole: ReadingsTableData) => {
    const reading =
      dataReadingsByPiezometers?.listReadingByPiezometersPag.nodes?.find(
        (item) => item.readingId === readingRole.readingId
      );
    if (!reading) return;
    const newReading = {
      id: readingRole.readingId,
      cote: readingRole.cote.toLocaleString(),
      reading: readingRole.reading.toLocaleString(),
      isDry: readingRole.isDry,
      date: new Date(reading.date ?? Date.now()),
      observation: readingRole.observation
    };
    setEditPzAndIna(newReading);
    setShowModal(true);
  };

  return (
    <>
      <SimpleContentTemplate
        loading={loadingReadingsByPiezometers}
        title={<Text type="h1">{translate(instrumentSelected)} </Text>}
        button={
          userRole !== UserRoles.Viewer && (
            <>
              <Button
                onClick={() => {
                  setEditPzAndIna(null);
                  setShowModal(true);
                }}
                variant={'primary'}
                text={translate('RegisterReadings')}
              />
              <Button
                variant={'primary'}
                size="large"
                text={translate('RegisterReadingsFile')}
                onClick={() => setModalReadingIsOpen(true)}
              />
            </>
          )
        }
        content={
          <DivContainerTable>
            <DivTable>
              <TableDraggableComponent
                tableId={TABLE_IDS.READINGS_PIEZOMETER_TABLE}
                columnsArray={columnsPiezometer}
                tableData={tableData || []}
                actions={
                  userRole !== UserRoles.Viewer ? actionsTable : undefined
                }
                filterComponent={
                  <FilterReadingsPage instrument={instrumentSelected} />
                }
                paginator={
                  <Paginator
                    options={optionsPaginatorDefault}
                    totalPages={totalPages}
                    showInUrl
                  />
                }
                exportTable={
                  <ExportTableReadingPiezometerIna columns={exportColumns} />
                }
                sortUrl
                searchUrl
                columnSelect
                showCountColumn
              />
            </DivTable>
          </DivContainerTable>
        }
      />
      <ViewModal
        showModal={showModal}
        title={
          editPzAndIna
            ? `${translate('EditReadings')} ${translate(instrumentType)}`
            : `${translate('NewReading')} ${translate(instrumentType)}`
        }
        onClose={() => {
          setEditPzAndIna(null);
          setShowModal(false);
        }}
        component={
          <ReadingPiezometerAndIna
            instrumentType={instrumentType as InstrumentTypeEnum}
            reading={editPzAndIna as ReadingPzAndInaFormType}
            setShowModal={setShowModal}
          />
        }
        width="500px"
        height="600px"
      />
      <ModalConfirm
        showModal={deleting.status}
        onClose={() =>
          setDeleting({
            status: false,
            readingId: '',
            instrumentId: ''
          })
        }
        onConfirm={() => handleDeleteReading()}
        title={`${translate('Delete')} ${translate('reading')}`}
        text={translate('ConfirmDelete')}
      />

      <ViewModal
        showModal={modalReadingIsOpen}
        onClose={() => setModalReadingIsOpen(false)}
        title={translate(instrumentSelected)}
        height={'auto'}
        width={'auto'}
        component={
          <RegisterPiezometerInaBathReadings
            onClose={setModalReadingIsOpen}
            template={InstrumentTemplateTypeEnum.Piezometer}
            instrumentSelected={instrumentSelected}
          />
        }
      />
    </>
  );
};

export default ReadingPagePzINA;
