import Papa from 'papaparse';
import { useTranslation } from 'react-i18next';

function setId(id: number) {
  if (id <= 9) {
    return '00' + id.toString();
  } else if (id > 9 && id < 99) {
    return '0' + id.toString();
  }

  return id.toString();
}

const ExportData = () => {
  const { t: translate } = useTranslation();

  const ExportInspectionList = (filteredInspections: any) => {
    const data = filteredInspections.map((c: any) => ({
      ID: setId(c.systemId),
      Name: c.responsible,
      Date: new Date(c.date).toLocaleDateString('pt-br'),
      ConservationState: c.conservationStateCalc
        ? c.conservationStateCalc
        : '-',
      FichaDeInspeção: '',
      Anexo: ''
    }));

    const csv = Papa.unparse(data, {
      delimiter: ';',
      header: true,
      columns: [
        'ID',
        'Name',
        'Date',
        'ConservationState',
        'FichaDeInspeção',
        'Anexo'
      ]
    });

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'inspection list.csv';
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  const ExportReadingsList = (data: any, selectedType: string) => {
    const handleExportPiezometer = () => {
      const newData = data.map((c: any) => ({
        name: c.name,
        date: new Date(c.date).toLocaleDateString('pt-br'),
        isDry: c.isDry,
        readingType: c.readingType,
        installationDate: new Date(c.installationDate).toLocaleDateString(
          'pt-br'
        ),
        installLocation: c.installLocation,
        operationalStatus: c.operationalStatus,
        bottomCote: c.bottomCote,
        topCote: c.topCote,
        structureType: c.structureType,
        cote: c.cote,
        reading: c.reading,
        observation: c.observation
      }));

      const csv = Papa.unparse(newData, {
        delimiter: ';',
        header: true,
        columns: [
          'name',
          'date',
          'isDry',
          'readingType',
          'installationDate',
          'installLocation',
          'operationalStatus',
          'bottomCote',
          'topCote',
          'structureType',
          'cote',
          'reading',
          'observation'
        ]
      });

      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'piezometerOrInaReadings.csv';
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    };

    const handleExportPluviometer = () => {
      const newData = data.map((c: any) => ({
        name: c.name,
        date: new Date(c.date).toLocaleDateString('pt-br'),
        observation: c.observation,
        rainfall: c.rainfall,
        structureType: c.structureType,
        installLocation: c.installLocation,
        readingType: c.readingType,
        operationalStatus: c.operationalStatus
      }));

      const csv = Papa.unparse(newData, {
        delimiter: ';',
        header: true,
        columns: [
          'name',
          'date',
          'observation',
          'rainfall',
          'structureType',
          'readingType',
          'operationalStatus'
        ]
      });

      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'pluviometerReadings.csv';
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    };

    const handleExportWaterLevel = () => {
      const newData = data.map((c: any) => ({
        name: c.name,
        date: new Date(c.date).toLocaleDateString('pt-br'),
        structureType: c.structureType,
        readingType: c.readingType,
        observation: c.observation,
        value: c.value,
        operationalStatus: c.operationalStatus
      }));

      const csv = Papa.unparse(newData, {
        delimiter: ';',
        header: true,
        columns: [
          'name',
          'date',
          'structureType',
          'readingType',
          'observation',
          'value',
          'operationalStatus'
        ]
      });

      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'waterLevelReadings.csv';
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    };

    const handleExportSurfaceMarker = () => {
      const newData = data.map((c: any) => ({
        name: c.name,
        date: new Date(c.date).toLocaleDateString('pt-br'),
        coordinateE: c.displacements.coordinateE,
        coordinateN: c.displacements.coordinateN,
        elevation: c.displacements.elevation,
        structureType: c.structureType,
        readingType: c.readingType,
        operationalStatus: c.operationalStatus,
        observation: c.observation,
        installLocation: c.installLocation
      }));

      const csv = Papa.unparse(newData, {
        delimiter: ';',
        header: true,
        columns: [
          'name',
          'date',
          'coordinateE',
          'coordinateN',
          'elevation',
          'structureType',
          'readingType',
          'operationalStatus',
          'observation',
          'installLocation'
        ]
      });

      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'surfaceMarkerReadings.csv';
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    };

    const exportSelectedType = (selectedType: string) => {
      const strategy = {
        piezometer: () => handleExportPiezometer(),
        pluviometer: () => handleExportPluviometer(),
        ina: () => handleExportPiezometer(),
        waterlevel: () => handleExportWaterLevel(),
        surfacemarker: () => handleExportSurfaceMarker()
      };

      strategy[selectedType.toLowerCase() as keyof typeof strategy]();
    };

    exportSelectedType(selectedType);
  };

  const ExportInstrumentList = (data: any, type: string, columns: any) => {
    data.map((instrument: any) => ({
      name: instrument.name,
      activationDate: instrument.activationDate,
      coordinateE: instrument.coordinateE,
      coordinateN: instrument.coordinateN,
      installLocation: instrument.installLocation,
      installationDate: instrument.installationDate,
      readingType: instrument.readingType,
      status: instrument.status,
      deactivationDate: instrument.deactivationDate
    }));

    const csv = Papa.unparse(data, {
      delimiter: ';',
      header: true,
      columns: columns
    });

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `instrument${type}list.csv`;
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  const ExportActionPlanList = (data: any, columns: any) => {
    const dataExport = data.map((actionPlan: any) => ({
      code: actionPlan.code,
      type: actionPlan.type,
      name: actionPlan.responsibleName,
      responsible: actionPlan.responsible,
      status: translate(actionPlan.situation?.toLocaleLowerCase()),
      timeFrame: new Date(actionPlan.timeFrame).toLocaleDateString() || '',
      priority: translate(actionPlan.priority),
      teamMembersName: actionPlan.teamMembersName.join(', ')
    }));

    const csv = Papa.unparse(dataExport, {
      delimiter: ';',
      header: true,
      columns: columns.key
    });

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'ActionPlanlist.csv';
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  return {
    ExportInspectionList,
    ExportReadingsList,
    ExportInstrumentList,
    ExportActionPlanList
  };
};

export default ExportData;
