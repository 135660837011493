import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ListLatestReadingByPluviometerQueryVariables = Types.Exact<{
  instrumentId: Types.Scalars['String']['input'];
}>;


export type ListLatestReadingByPluviometerQuery = { __typename?: 'Query', listLatestReadingByPluviometer: { __typename?: 'ListLatestPluviometerReadingType', date: Date, id: string, name: string, rainfall: number, observation?: string | null, alertLevels?: string | null, operationalStatus: Types.InstrumentStatusEnum } };


export const ListLatestReadingByPluviometerDocument = gql`
    query ListLatestReadingByPluviometer($instrumentId: String!) {
  listLatestReadingByPluviometer(instrumentId: $instrumentId) {
    date
    id
    name
    rainfall
    observation
    alertLevels
    operationalStatus
  }
}
    `;
export type ListLatestReadingByPluviometerQueryResult = Apollo.QueryResult<ListLatestReadingByPluviometerQuery, ListLatestReadingByPluviometerQueryVariables>;