import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type FindAllGistmDataQueryVariables = Types.Exact<{
  structureId: Types.Scalars['String']['input'];
}>;

export type FindAllGistmDataQuery = {
  __typename?: 'Query';
  findAllGistmData: Array<{
    __typename?: 'GistmDataType';
    id: string;
    name: string;
    path: string;
    nested?: Array<{
      __typename?: 'GistmDataType';
      id: string;
      name: string;
      path: string;
      nested?: Array<{
        __typename?: 'GistmDataType';
        id: string;
        name: string;
        path: string;
        nested?: Array<{
          __typename?: 'GistmDataType';
          id: string;
          name: string;
          path: string;
          nested?: Array<{
            __typename?: 'GistmDataType';
            id: string;
            name: string;
            path: string;
          }> | null;
        }> | null;
      }> | null;
    }> | null;
  }>;
};

export const FindAllGistmDataDocument = gql`
  query FindAllGistmData($structureId: String!) {
    findAllGistmData(structureId: $structureId) {
      id
      name
      path
      nested {
        id
        name
        path
        nested {
          id
          name
          path
          nested {
            id
            name
            path
            nested {
              id
              name
              path
            }
          }
        }
      }
    }
  }
`;
export type FindAllGistmDataQueryResult = Apollo.QueryResult<
  FindAllGistmDataQuery,
  FindAllGistmDataQueryVariables
>;
