import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type DeleteRecordWeatherConditionsMutationVariables = Types.Exact<{
  deleteRecordWeatherConditionsId: Types.Scalars['String']['input'];
}>;


export type DeleteRecordWeatherConditionsMutation = { __typename?: 'Mutation', deleteRecordWeatherConditions: string };


export const DeleteRecordWeatherConditionsDocument = gql`
    mutation DeleteRecordWeatherConditions($deleteRecordWeatherConditionsId: String!) {
  deleteRecordWeatherConditions(id: $deleteRecordWeatherConditionsId)
}
    `;
export type DeleteRecordWeatherConditionsMutationFn = Apollo.MutationFunction<DeleteRecordWeatherConditionsMutation, DeleteRecordWeatherConditionsMutationVariables>;
export type DeleteRecordWeatherConditionsMutationResult = Apollo.MutationResult<DeleteRecordWeatherConditionsMutation>;
export type DeleteRecordWeatherConditionsMutationOptions = Apollo.BaseMutationOptions<DeleteRecordWeatherConditionsMutation, DeleteRecordWeatherConditionsMutationVariables>;