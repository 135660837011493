import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type EditUserMutationVariables = Types.Exact<{
  data: Types.EditUserInput;
}>;


export type EditUserMutation = { __typename?: 'Mutation', editUser: string };


export const EditUserDocument = gql`
    mutation EditUser($data: EditUserInput!) {
  editUser(data: $data)
}
    `;
export type EditUserMutationFn = Apollo.MutationFunction<EditUserMutation, EditUserMutationVariables>;
export type EditUserMutationResult = Apollo.MutationResult<EditUserMutation>;
export type EditUserMutationOptions = Apollo.BaseMutationOptions<EditUserMutation, EditUserMutationVariables>;