import React from 'react';
import { StyledIcon } from './Icon.styles';
import { IconProps } from './Icon.interfaces';
import { Tooltip } from 'react-tooltip';

const Icon: React.FC<IconProps> = ({
  Icon,
  variant = 'medium',
  tooltip,
  ...props
}) => {
  return (
    <>
      <StyledIcon
        data-tooltip-id={tooltip ?? undefined}
        data-tooltip-content={tooltip}
        as={Icon}
        variant={variant}
        {...props}
      />
      <Tooltip id={tooltip ?? undefined} />
    </>
  );
};

export default Icon;
