/* eslint-disable react/prop-types */
import { t } from 'i18next';
import { useState } from 'react';
import Image from '../../Atoms/Image/Image';
import Text from '../../Atoms/Text/Text';
import FileViewer from '../FileViewer/FileViewer';
import { FileType } from '../FileViewer/FileViewer.interfaces';
import { PictureGalleryProps } from './PictureGallery.interfaces';
import {
  DivContainerPictures,
  HolderPicturesNotFound,
  ImgsContainer
} from './PictureGallery.styles';
export const PictureGallery = ({
  files,
  imageWidth,
  imagesToShow,
  imagesGrid
}: PictureGalleryProps) => {
  const [selectImage, setSelectImage] = useState<FileType | null>(null);
  const limitedImages = files.slice(0, imagesToShow);
  return (
    <>
      {files.length === 0 ? (
        <HolderPicturesNotFound>
          <Text type={'h5'}>{t('ImagesNotFound')}</Text>
        </HolderPicturesNotFound>
      ) : (
        <DivContainerPictures imagesGrid={imagesGrid}>
          {limitedImages?.map((picture, index) => (
            <ImgsContainer key={index}>
              <Image
                alt={picture.url}
                src={picture.url}
                onClick={() => setSelectImage(picture)}
                key={index}
                width={imageWidth}
                maxHeight="100px"
              />
            </ImgsContainer>
          ))}
          <FileViewer
            showModal={!!selectImage}
            files={files}
            selectedFile={selectImage}
            onClose={() => setSelectImage(null)}
          />
        </DivContainerPictures>
      )}
    </>
  );
};
