import styled from 'styled-components';

export const DivTitle = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;

export const DivContentTeamMembers = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

export const DivTableTeam = styled.div`
  width: 90%;
`;

export const DivContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const DivInput = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DivContainerButtons = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: end;
  justify-content: flex-end;
  gap: 10px;
`;
