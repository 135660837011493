import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ListInstrumentByIdQueryVariables = Types.Exact<{
  instrumentId: Types.Scalars['String']['input'];
}>;

export type ListInstrumentByIdQuery = {
  __typename?: 'Query';
  listInstrumentById: {
    __typename?: 'BasicInstrumentTypeWithSectionAndDocs';
    readingType: string;
    installLocation?: string | null;
    status: boolean;
    operationalStatus: Types.InstrumentStatusEnum;
    coordinateE: number;
    coordinateN: number;
    isUTM: boolean;
    activationDate?: Date | null;
    deactivationDate?: Date | null;
    installationDate?: Date | null;
    id: string;
    name: string;
    type: {
      __typename?: 'BasicInstrumentTypeType';
      id: string;
      instrumentType: string;
    };
    sections: Array<{
      __typename?: 'BasicSectionsType';
      id: string;
      name: string;
      type?: string | null;
      isUTM: boolean;
      status: boolean;
    }>;
    docs: Array<{
      __typename?: 'InstrumentDocsType';
      id: string;
      fileName: string;
      url: string;
    }>;
  };
};

export const ListInstrumentByIdDocument = gql`
  query ListInstrumentById($instrumentId: String!) {
    listInstrumentById(instrumentId: $instrumentId) {
      type {
        id
        instrumentType
      }
      readingType
      installLocation
      status
      operationalStatus
      coordinateE
      coordinateN
      isUTM
      activationDate
      deactivationDate
      installationDate
      id
      name
      sections {
        id
        name
        type
        isUTM
        status
      }
      docs {
        id
        fileName
        url
      }
    }
  }
`;

export type ListInstrumentByIdQueryResult = Apollo.QueryResult<
  ListInstrumentByIdQuery,
  ListInstrumentByIdQueryVariables
>;
