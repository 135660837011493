import styled from 'styled-components';
export const HolderIconAndValueTable = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 5px;
`;

export const DivTable = styled.div`
  padding: 20px;
  box-sizing: border-box;
`;
