import { styled } from 'styled-components';
import { Color } from '../../../../Styles/Styles';

export const DivContainerVideos = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  justify-content: space-between;
  gap: 20px;
`;

export const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 300px;
  height: 130px;
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.7;
  z-index: 1;
`;

export const PlayButton = styled.a`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  cursor: pointer;
  color: white;

  svg {
    transition: color 0.5s ease-in-out;
  }

  svg:hover {
    color: ${Color.Disabled};
  }
`;

export const HolderVideosNotFound = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
