import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ListInstrumentsByStructurePagQueryVariables = Types.Exact<{
  pageInfo: Types.PageInput;
  instrumentType: Types.InstrumentTypeEnum;
  structureId: Types.Scalars['String']['input'];
  sortInfo?: Types.InputMaybe<Array<Types.SortInput> | Types.SortInput>;
  filters?: Types.InputMaybe<Types.ListInstrumentsByStructureFilterInput>;
}>;


export type ListInstrumentsByStructurePagQuery = { __typename?: 'Query', listInstrumentsByStructurePag: { __typename?: 'PaginatedInstrumentsByStructureType', count: number, nodes: Array<{ __typename?: 'ListInstrumentsByStructurePagType', readingType: string, installLocation?: string | null, status: boolean, operationalStatus: Types.InstrumentStatusEnum, coordinateE: number, coordinateN: number, isUTM: boolean, activationDate?: Date | null, deactivationDate?: Date | null, installationDate?: Date | null, id: string, name: string, depth?: number | null, bottomCote?: number | null, topCote?: number | null, type: { __typename?: 'BasicInstrumentTypeType', id: string, instrumentType: string } }>, pageInfo: { __typename?: 'PageInfo', page: number, offset: number, limit: number, totalPages: number, hasNextPage: boolean, hasPreviousPage: boolean } } };


export const ListInstrumentsByStructurePagDocument = gql`
    query ListInstrumentsByStructurePag($pageInfo: PageInput!, $instrumentType: InstrumentTypeEnum!, $structureId: String!, $sortInfo: [SortInput!], $filters: ListInstrumentsByStructureFilterInput) {
  listInstrumentsByStructurePag(
    pageInfo: $pageInfo
    instrumentType: $instrumentType
    structureId: $structureId
    sortInfo: $sortInfo
    filters: $filters
  ) {
    nodes {
      type {
        id
        instrumentType
      }
      readingType
      installLocation
      status
      operationalStatus
      coordinateE
      coordinateN
      isUTM
      activationDate
      deactivationDate
      installationDate
      id
      name
      depth
      bottomCote
      topCote
    }
    count
    pageInfo {
      page
      offset
      limit
      totalPages
      hasNextPage
      hasPreviousPage
    }
  }
}
    `;
export type ListInstrumentsByStructurePagQueryResult = Apollo.QueryResult<ListInstrumentsByStructurePagQuery, ListInstrumentsByStructurePagQueryVariables>;