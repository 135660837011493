import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type SetNotificationUnreadStatusMutationVariables = Types.Exact<{
  notificationModuleType: Types.NotificationTypeEnum;
  notificationId: Types.Scalars['String']['input'];
}>;

export type SetNotificationUnreadStatusMutation = {
  __typename?: 'Mutation';
  setNotificationUnreadStatus: string;
};

export const SetNotificationUnreadStatusDocument = gql`
  mutation SetNotificationUnreadStatus(
    $notificationModuleType: NotificationTypeEnum!
    $notificationId: String!
  ) {
    setNotificationUnreadStatus(
      notificationModuleType: $notificationModuleType
      notificationId: $notificationId
    )
  }
`;

export type SetNotificationUnreadStatusMutationFn = Apollo.MutationFunction<
  SetNotificationUnreadStatusMutation,
  SetNotificationUnreadStatusMutationVariables
>;

export type SetNotificationUnreadStatusMutationResult =
  Apollo.MutationResult<SetNotificationUnreadStatusMutation>;

export type SetNotificationUnreadStatusMutationOptions =
  Apollo.BaseMutationOptions<
    SetNotificationUnreadStatusMutation,
    SetNotificationUnreadStatusMutationVariables
  >;
