import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type FindAllAtoQueryVariables = Types.Exact<{
  structureId: Types.Scalars['String']['input'];
}>;


export type FindAllAtoQuery = { __typename?: 'Query', findAllAto: Array<{ __typename?: 'FindAllAtoType', id: string, title: string, contract: string, situation: Types.AtoSituationEnum, initialDate: Date, finalDate: Date, canFinish: boolean, responsibleCompany: { __typename?: 'BaseIdNameType', id: string, name: string }, verifier: { __typename?: 'BaseIdNameType', id: string, name: string }, clientApprover?: { __typename?: 'BaseIdNameType', id: string, name: string } | null, docs: Array<{ __typename?: 'BaseIdNameType', id: string, name: string }>, client: { __typename?: 'BaseIdNameType', id: string, name: string } }> };


export const FindAllAtoDocument = gql`
    query FindAllAto($structureId: String!) {
  findAllAto(structureId: $structureId) {
    id
    title
    contract
    situation
    responsibleCompany {
      id
      name
    }
    verifier {
      id
      name
    }
    clientApprover {
      id
      name
    }
    initialDate
    finalDate
    docs {
      id
      name
    }
    client {
      id
      name
    }
    canFinish
  }
}
    `;
export type FindAllAtoQueryResult = Apollo.QueryResult<FindAllAtoQuery, FindAllAtoQueryVariables>;