import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaEye } from 'react-icons/fa6';
import { IoIosArrowBack } from 'react-icons/io';
import { useNavigate, useParams } from 'react-router-dom';
import Icon from '../../Atoms/Icon/Icon';
import Text from '../../Atoms/Text/Text';
import { AvailableContextActionsProps } from '../../Molecules/ActionMenu/ActionMenu.interface';
import TableComponent from '../../Molecules/TableComponent/TableComponent';
import ViewModal from '../../Molecules/ViewModal/ViewModal';
import SeeMoreTemplate from '../../Templates/SeeMoreTemplate/SeeMoreTemplate';
import { lisAtoDataProps } from '../AtoAdminPage/AtoAdminPage.interfaces';
import { DivHeaderStability } from '../StabilityPage/StabilityPage.styles';

const StabilityHistoryPage = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [rowData, setRowData] = useState();
  const mockData = {
    typeOfAnalysis: 'Stability',
    methodOfSearch: 'methodOfSearch',
    methodOfCalc: 'methodOfCalc',
    condition: 'drenado',
    tSuper: 'n-circular'
  };
  const tableMockColumns = [
    {
      key: 'section',
      label: 'Section'
    },
    {
      key: 'date',
      label: 'Date'
    },
    {
      key: 'img',
      label: 'Image'
    }
  ];

  const tableMockData = [
    {
      id: 1,
      section: 'Record 1',
      date: '2022-01-01T00:00:00.000Z',
      img: 'img'
    },
    {
      id: 2,
      section: 'Record 2',
      date: '2022-01-01T00:00:00.000Z',
      img: 'img'
    },
    {
      id: 3,
      section: 'Record 3',
      date: '2022-01-01T00:00:00.000Z',
      img: 'img'
    },
    {
      id: 4,
      section: 'Record 4',
      date: '2022-01-01T00:00:00.000Z',
      img: 'img'
    },
    {
      id: 5,
      section: 'Record 5',
      date: '2022-01-01T00:00:00.000Z',
      img: 'img'
    }
  ];

  const { t: translate } = useTranslation();
  const navigate = useNavigate();
  const { structureId } = useParams();

  const actionsTable: AvailableContextActionsProps<lisAtoDataProps>[] = [
    {
      name: `${translate('Visualize')}`,
      icon: <FaEye />,
      onClick: () => {},
      canShow: 'true'
    }
  ];

  const navigateBack = () => {
    navigate(`/${structureId}/stability`);
  };

  return (
    <SeeMoreTemplate
      icon={<Icon Icon={IoIosArrowBack} onClick={navigateBack} />}
      title={translate('history')}
      header={null}
      content={
        <>
          <TableComponent
            tableData={tableMockData ?? []}
            columns={tableMockColumns}
            showInUrl={true}
            paginator={true}
            totalPages={10}
            actions={actionsTable}
            onSearch={() => null}
            onRow={(item: any) => {
              setModalIsOpen(true);
              setRowData(item);
            }}
          />
          <ViewModal
            showModal={modalIsOpen}
            title={translate('history')}
            onClose={() => setModalIsOpen(false)}
            height="70%"
            component={
              <>
                {Object.entries(mockData).map(([key, value], index) => (
                  <DivHeaderStability key={index}>
                    <Text type={'p'} color={'brown'}>
                      {key + ': '}
                    </Text>
                    <Text type={'p'} color={'black'}>
                      {value}
                    </Text>
                  </DivHeaderStability>
                ))}
                <DivHeaderStability>
                  <Text type={'p'} color={'brown'}>
                    {'Date: '}
                  </Text>
                  <Text type={'p'} color={'black'}>
                    {rowData?.date}
                  </Text>
                </DivHeaderStability>
                <img
                  src={require('./../../../../Images/tellusLogo.png')}
                  alt=""
                />
              </>
            }
          />
        </>
      }
      loading={false}
    />
  );
};

export default StabilityHistoryPage;
