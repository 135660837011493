import React, { Dispatch } from 'react';
import { useTranslation } from 'react-i18next';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import { FontSize } from '../../../Styles/Styles';
import './TogglesStyle.css';

interface ToggleInterface {
  check: boolean;
  setCheck: Dispatch<boolean>;
  updateValue?: any;
  key?: string;
  text: string;
}

class Toggles extends React.Component {
  static ToggleStatus = ({
    check,
    setCheck,
    updateValue,
    key,
    text
  }: ToggleInterface) => {
    const { t } = useTranslation();
    return (
      <span
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        <span style={{ marginRight: '10px', fontSize: FontSize.Small }}>
          {t(text)}{' '}
        </span>
        <Toggle
          className="custom-toggle"
          icons={false}
          checked={check}
          onChange={() => {
            setCheck(!check);
            if (updateValue && key) updateValue(key, !check);
          }}
        />
      </span>
    );
  };
}

export default Toggles;
