import { useMutation } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';
import { ProjectContext } from '../../../../Context/ContextAPI';
import { client } from '../../../../data/apollo.client';
import {
  UpdateRecordImageObservationDocument,
  UpdateRecordImageObservationMutation,
  UpdateRecordImageObservationMutationVariables
} from '../../../../data/graphql/generated/updateRecordImageObservation';
import { FindDataByRecordDocument } from '../../../../data/graphql/query/generated/findDataByRecord';

import { useParams } from 'react-router-dom';
import { FindGeneralInfoDocument } from '../../../../data/graphql/query/generated/findAtoGeneralInfo.query';
import { sendPhotoFile } from '../../../../data/services/ATOService';
import ToastifyModel from '../../../../Models/ToastifyModel';
import ErrorsTreatments from '../../../../utils/errorTreatment';
import { UserData } from '../../../Map/types';
import { toastfyError, toastfySuccess } from '../../../Toastify';
import Button from '../../Atoms/Button/Button';
import { FileType } from '../../Molecules/FileViewer/FileViewer.interfaces';
import InputTextarea from '../../Molecules/InputTextarea/InputTextarea';
import InputFileWithList from '../InputFileWithList/InputFileWithList';
import { AddPhotoAtoFormProps } from './AddPhotoAtoForm.interface';
import {
  DivButtonArea,
  DivInputsArea,
  HolderForm
} from './AddPhotoAtoForm.styles';

const oneMegaByte = 1048576;

const addPhotoAtoFormSchema = z.object({
  description: z
    .string()
    .min(1, {
      message: 'fieldIsRequired'
    })
    .refine((val) => val !== '', {
      message: 'fieldIsRequired'
    }),
  files: z
    .array(z.instanceof(File), { message: 'fieldIsRequired' })
    .min(1, { message: 'fieldIsRequired' })
    .max(1, { message: 'tooManyFiles' })
    .refine((val) => val.every((file) => file.size <= 5 * oneMegaByte), {
      message: 'veryBigFile'
    })
});

type AddPhotoAtoFormSchemaType = z.infer<typeof addPhotoAtoFormSchema>;

const AddPhotoAtoForm = ({
  setShowModal,
  recordId,
  editPhoto
}: AddPhotoAtoFormProps) => {
  const { t: translate } = useTranslation();
  const { atoId } = useParams();
  const [description, setDescription] = useState<string>(
    editPhoto?.description || ''
  );
  const [files, setFiles] = useState<FileType[]>([]);
  const [updateRecordImageObservation] = useMutation<
    UpdateRecordImageObservationMutation,
    UpdateRecordImageObservationMutationVariables
  >(UpdateRecordImageObservationDocument, {});
  const { userData } = useContext(ProjectContext) as {
    userData: UserData;
  };
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors }
  } = useForm<AddPhotoAtoFormSchemaType>({
    resolver: zodResolver(addPhotoAtoFormSchema),
    defaultValues: {
      description: editPhoto ? editPhoto?.description : ''
    }
  });

  const url = '/ato/record/image/';

  useEffect(() => {
    if (editPhoto) {
      setFiles([
        {
          id: editPhoto?.id,
          name: editPhoto?.name,
          url: editPhoto?.url
        }
      ]);
    }
  }, []);

  const handleSubmitAddPhotoAtoForm = async (
    data: AddPhotoAtoFormSchemaType
  ) => {
    const { description } = data;
    const isInvalid = files.length === 0;
    if (isInvalid) {
      setValue('files', []);
      return;
    }

    if (editPhoto?.id) {
      updateRecordImageObservation({
        variables: {
          data: {
            id: editPhoto?.id,
            observation: description
          }
        },
        onCompleted: () => {
          setShowModal(false);
          toastfySuccess(translate('editedSuccessfully'));
        },
        onError: ({ graphQLErrors }) => {
          const errorMessage = ErrorsTreatments(
            graphQLErrors[0].message,
            translate
          );
          toastfyError(errorMessage);
        },
        update: (cache, { data }) => {
          if (!data) return;

          const existingData = cache.readQuery({
            query: FindDataByRecordDocument,
            variables: {
              recordId: recordId
            }
          }) as Record<string, any>;

          const updatedImages = existingData.findDataByRecord.images.map(
            (image: any) =>
              image.id === (data.updateRecordImageObservation.id as string)
                ? data.updateRecordImageObservation
                : image
          );

          cache.writeQuery({
            query: FindDataByRecordDocument,
            variables: {
              recordId: recordId
            },
            data: {
              findDataByRecord: {
                ...existingData.findDataByRecord,
                images: updatedImages
              }
            }
          });
        }
      });
    }

    if (description.length !== 0 && files.length !== 0) {
      const formData = new FormData();

      files.forEach((file) => {
        formData.append('file', file.file as Blob);
      });

      formData.append('observation', description);

      const res = await sendPhotoFile(formData, url, recordId, userData.token);
      if (res?.status === 200) {
        await client.refetchQueries({
          include: [
            {
              query: FindDataByRecordDocument,
              variables: {
                recordId: recordId
              },
              awaitRefetchQueries: true
            },
            {
              query: FindGeneralInfoDocument,
              variables: {
                atoId: atoId
              },
              awaitRefetchQueries: true
            }
          ]
        });

        setShowModal(false);
      } else {
        toastfyError(translate(ToastifyModel().toastifyMessage.error));
      }
    }
  };

  const handleFileChange = (selectedFiles: File[]) => {
    const fileSize = selectedFiles[0].size;
    const newselectedFiles: FileType[] = Array.from(selectedFiles).map(
      (file) => ({
        id: '',
        name: file.name,
        url: URL.createObjectURL(file),
        file: file
      })
    );

    const storedStateFiles = files
      .map((file) => file.file)
      .concat(newselectedFiles.map((file) => file.file)) as File[];

    setValue('files', storedStateFiles);
    if (fileSize <= 5 * oneMegaByte) {
      setFiles((prev) => [...prev, ...newselectedFiles]);
    }
  };

  const handleDeleteImage = (fileSelect: FileType) => {
    const updatedFiles: FileType[] = files.filter(
      (file) => file.file !== fileSelect.file
    );
    setFiles(updatedFiles);

    setValue(
      'files',
      updatedFiles.map((file) => file.file!)
    );
  };

  return (
    <HolderForm>
      <DivInputsArea>
        <div>
          <InputTextarea
            errorMessage={errors.description?.message}
            error={!!errors.description}
            height="100px"
            label={translate('description')}
            name="description"
            onChange={(event) => setDescription(event.target.value)}
            register={register}
            value={description}
            width="300px"
          />
        </div>
        <InputFileWithList
          register={register}
          name="files"
          errorMessage={errors.files?.message}
          error={!!errors.files}
          accept=".png, .jpg, .jpeg"
          label=""
          multiple={true}
          files={files}
          onChange={(event) => handleFileChange(event.target.files as any)}
          onDelete={(file: FileType) => handleDeleteImage(file)}
        />
      </DivInputsArea>
      <DivButtonArea>
        <Button
          text={translate('Cancel')}
          variant={'secondary'}
          size="small"
          onClick={() => {
            setShowModal(false);
          }}
        />
        <Button
          text={translate('Save')}
          variant={'primary'}
          size="small"
          onClick={handleSubmit(handleSubmitAddPhotoAtoForm)}
        />
      </DivButtonArea>
    </HolderForm>
  );
};

export default AddPhotoAtoForm;
