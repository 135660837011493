import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type CreateAtoMutationVariables = Types.Exact<{
  structureId: Types.Scalars['String']['input'];
  data: Types.CreateAtoInput;
}>;


export type CreateAtoMutation = { __typename?: 'Mutation', createAto: string };


export const CreateAtoDocument = gql`
    mutation CreateAto($structureId: String!, $data: CreateAtoInput!) {
  createAto(structureId: $structureId, data: $data)
}
    `;
export type CreateAtoMutationFn = Apollo.MutationFunction<CreateAtoMutation, CreateAtoMutationVariables>;
export type CreateAtoMutationResult = Apollo.MutationResult<CreateAtoMutation>;
export type CreateAtoMutationOptions = Apollo.BaseMutationOptions<CreateAtoMutation, CreateAtoMutationVariables>;