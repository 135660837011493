import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { BsCheckLg } from 'react-icons/bs';
import { PiAsteriskBold } from 'react-icons/pi';
import {
  AdminInput,
  IconRequired,
  InputArea,
  RequiredInput
} from '../../../../../Screens/AdminScreen/AdminScreenStyle';
import { StepFour } from '../StructuresInterface';

export function StructureStepFour({
  structureStepFourData,
  setStructureStepFourData,
  error
}: {
  structureStepFourData: StepFour;
  setStructureStepFourData: Dispatch<SetStateAction<StepFour>>;
  error: boolean;
}) {
  const { t } = useTranslation();

  return (
    <InputArea>
      <RequiredInput>
        <AdminInput
          value={structureStepFourData.wasteType}
          onChange={(e) => {
            setStructureStepFourData((prevData) => ({
              ...prevData,
              wasteType: e.target.value
            }));
          }}
          style={
            error && !structureStepFourData.wasteType
              ? { borderColor: 'red', background: '#ffeded' }
              : {}
          }
          placeholder={t('TypeofWaste')}
        />
        {structureStepFourData.wasteType.length < 3 ? (
          <PiAsteriskBold style={IconRequired} />
        ) : (
          <BsCheckLg style={IconRequired} />
        )}
      </RequiredInput>
      <RequiredInput>
        <AdminInput
          value={structureStepFourData.downstreamHumanOccupation}
          onChange={(e) => {
            setStructureStepFourData((prevData) => ({
              ...prevData,
              downstreamHumanOccupation: e.target.value
            }));
          }}
          style={
            error && !structureStepFourData.downstreamHumanOccupation
              ? { borderColor: 'red', background: '#ffeded' }
              : {}
          }
          placeholder={t('HumanOccupationDownstreamDam')}
        />
        {structureStepFourData.downstreamHumanOccupation.length < 3 ? (
          <PiAsteriskBold style={IconRequired} />
        ) : (
          <BsCheckLg style={IconRequired} />
        )}
      </RequiredInput>
      <RequiredInput>
        <AdminInput
          value={structureStepFourData.downstreamEnvironmentalInterest}
          onChange={(e) => {
            setStructureStepFourData((prevData) => ({
              ...prevData,
              downstreamEnvironmentalInterest: e.target.value
            }));
          }}
          style={
            error && !structureStepFourData.downstreamEnvironmentalInterest
              ? { borderColor: 'red', background: '#ffeded' }
              : {}
          }
          placeholder={t('EnvironmentalInterestAreaDownstreamDam')}
        />
        {structureStepFourData.downstreamEnvironmentalInterest.length < 3 ? (
          <PiAsteriskBold style={IconRequired} />
        ) : (
          <BsCheckLg style={IconRequired} />
        )}
      </RequiredInput>
      <RequiredInput>
        <AdminInput
          value={structureStepFourData.downstreamInstallations}
          onChange={(e) => {
            setStructureStepFourData((prevData) => ({
              ...prevData,
              downstreamInstallations: e.target.value
            }));
          }}
          style={
            error && !structureStepFourData.downstreamInstallations
              ? { borderColor: 'red', background: '#ffeded' }
              : {}
          }
          placeholder={t('FacilityDownstreamAreaDam')}
        />
        {structureStepFourData.downstreamInstallations.length < 3 ? (
          <PiAsteriskBold style={IconRequired} />
        ) : (
          <BsCheckLg style={IconRequired} />
        )}
      </RequiredInput>
    </InputArea>
  );
}
