import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type FindAllActionPlansQueryVariables = Types.Exact<{
  structureId: Types.Scalars['String']['input'];
}>;


export type FindAllActionPlansQuery = { __typename?: 'Query', findAllActionPlans: Array<{ __typename?: 'ListAllActionPlansToExportTableType', id: string, code: string, type: Types.ActionPlanTypeEnum, responsibleName?: string | null, situation: Types.ActionPlanSituationEnum, timeFrame?: Date | null, priority?: Types.ActionPlanPriorityEnum | null, teamMembersName: Array<string> }> };


export const FindAllActionPlansDocument = gql`
    query FindAllActionPlans($structureId: String!) {
  findAllActionPlans(structureId: $structureId) {
    id
    code
    type
    responsibleName
    situation
    timeFrame
    priority
    teamMembersName
  }
}
    `;
export type FindAllActionPlansQueryResult = Apollo.QueryResult<FindAllActionPlansQuery, FindAllActionPlansQueryVariables>;