import { useMutation } from '@apollo/client';
import {
  DeleteInspectionDocument,
  DeleteInspectionMutation,
  DeleteInspectionMutationVariables
} from '../graphql/generated/deleteInspection.mutation';
import { FindAllInspectionsByStructureDocument } from '../graphql/query/generated/findAllInspectionsByStructure.query';
import { ListStructuresDocument } from '../graphql/query/generated/listStructures.query';
import { GetUserStructuresListDocument } from '../graphql/query/generated/structureList.query';

export function useDeleteInspection(
  id: string,
  onCompleted?: () => void,
  onError?: () => void
) {
  const [deleteUserInspection, { data, error, loading }] = useMutation<
    DeleteInspectionMutation,
    DeleteInspectionMutationVariables
  >(DeleteInspectionDocument, {
    variables: {
      inspectionId: id
    },
    refetchQueries: [
      ListStructuresDocument,
      GetUserStructuresListDocument,
      FindAllInspectionsByStructureDocument
    ],
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    deleteUserInspection,
    data,
    error,
    loading
  };
}
