import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type AddStructureDamTailingVolumeMutationVariables = Types.Exact<{
  data: Array<Types.AddDamTailingVolumeInput> | Types.AddDamTailingVolumeInput;
  structureId: Types.Scalars['String']['input'];
}>;


export type AddStructureDamTailingVolumeMutation = { __typename?: 'Mutation', addStructureDamTailingVolume: string };


export const AddStructureDamTailingVolumeDocument = gql`
    mutation AddStructureDamTailingVolume($data: [AddDamTailingVolumeInput!]!, $structureId: String!) {
  addStructureDamTailingVolume(data: $data, structureId: $structureId)
}
    `;
export type AddStructureDamTailingVolumeMutationFn = Apollo.MutationFunction<AddStructureDamTailingVolumeMutation, AddStructureDamTailingVolumeMutationVariables>;
export type AddStructureDamTailingVolumeMutationResult = Apollo.MutationResult<AddStructureDamTailingVolumeMutation>;
export type AddStructureDamTailingVolumeMutationOptions = Apollo.BaseMutationOptions<AddStructureDamTailingVolumeMutation, AddStructureDamTailingVolumeMutationVariables>;