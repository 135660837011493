import styled from 'styled-components';
import { Color } from '../../../../Styles/Styles';

export const RowContainer = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  column-gap: 5px;
  padding: 5px 0;
  border: 1px solid ${Color.Brown3};
`;
