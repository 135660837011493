import Skeleton from 'react-loading-skeleton';
import { DashboardTemplateProps } from './DashboardTemplate.interface';
import {
  ContainerDashboard,
  DivContainerImages,
  DivContainerInfos,
  DivContainerLeftSide,
  DivContainerRightSide,
  HolderCards,
  HolderContentPage,
  HolderHeader,
  HolderVideos
} from './DashboardTemplate.styles';

const DashboardTemplate = ({
  header,
  infosContent,
  imagesContent,
  cardContent,
  videosContent,
  tableContent,
  loading
}: DashboardTemplateProps) => {
  return (
    <>
      {loading ? (
        <ContainerDashboard>
          <HolderHeader>
            <Skeleton width="300px" height="48px" />
            <Skeleton width="200px" height="48px" />
          </HolderHeader>
          <HolderContentPage>
            <DivContainerLeftSide>
              <DivContainerInfos>
                <Skeleton width="100px" height="48px" />
                <Skeleton width="100px" height="48px" />
                <Skeleton width="100px" height="48px" />
                <Skeleton width="100px" height="48px" />
              </DivContainerInfos>
              <DivContainerImages>
                <Skeleton width="41vw" height="50vh" />
              </DivContainerImages>
            </DivContainerLeftSide>
            <DivContainerRightSide>
              <HolderCards>
                <Skeleton width="95px" height="95px" />
                <Skeleton width="95px" height="95px" />
                <Skeleton width="95px" height="95px" />
                <Skeleton width="95px" height="95px" />
                <Skeleton width="95px" height="95px" />
              </HolderCards>
              <HolderVideos>
                <Skeleton width="48vw" height="20vh" />
              </HolderVideos>
              <HolderVideos>
                <Skeleton width="48vw" height="15vh" />
              </HolderVideos>
            </DivContainerRightSide>
          </HolderContentPage>
        </ContainerDashboard>
      ) : (
        <ContainerDashboard>
          <HolderHeader>{header}</HolderHeader>
          <HolderContentPage>
            <DivContainerLeftSide>
              <DivContainerInfos>{infosContent}</DivContainerInfos>
              <DivContainerImages>{imagesContent}</DivContainerImages>
            </DivContainerLeftSide>
            <DivContainerRightSide>
              <HolderCards>{cardContent}</HolderCards>
              <HolderVideos>{tableContent}</HolderVideos>
              <HolderVideos>{videosContent}</HolderVideos>
            </DivContainerRightSide>
          </HolderContentPage>
        </ContainerDashboard>
      )}
    </>
  );
};

export default DashboardTemplate;
