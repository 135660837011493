import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import ChangeLocale from '../../Components/ChangeLocale';
import { regexPassword } from '../../Components/RegexValidator';
import logoGS3 from '../../Images/logoGS3.svg';
import { LanguageSelect } from '../../Styles/LanguageSelect';
import { useEditPassword } from '../../data/hooks/use-edit-password';
import {
  AllRightsReserved,
  DivInput,
  H1,
  LoginAreaResetPassword,
  LoginButton,
  LoginContainer,
  LoginInput,
  LoginLogo,
  LoginLogoReset,
  PasswordError,
  ResetPasswordForm
} from '../LoginScreen/LoginScreenStyle';
import LoginInstructions from './LoginInstructions';
import { IoMdEye, IoMdEyeOff } from 'react-icons/io';
import { ProjectContext } from '../../Context/ContextAPI';
import { toastfyError } from '../../Components/Toastify';

export default function ResetPasswordScreen() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [password, setPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [passwordValidation, setPasswordValidation] = useState<boolean>(true);
  const { editPassword } = useEditPassword(
    () => {
      return;
    },
    () => {
      setPasswordValidation(false);
    }
  );
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const acurrentYear = new Date().getFullYear();
  const { userData, dispatch } = useContext(ProjectContext) as {
    userData: any;
    dispatch: any;
  };

  const handleValidate = async (e: any) => {
    e.preventDefault();
    const regexValidate =
      regexPassword.test(password) && regexPassword.test(newPassword);

    if (password === newPassword && regexValidate) {
      setPasswordValidation(regexValidate);
    }

    if (password !== newPassword) {
      toastfyError(t('PasswordNotMatch'));
      return;
    }

    if (!regexPassword.test(password) || !regexPassword.test(newPassword)) {
      toastfyError(t('PasswordNotintheCorrectFormat'));
      return;
    }

    if (passwordValidation) {
      const editPasswordResponse = await editPassword({
        variables: { data: { newPassword } }
      });
      if (editPasswordResponse.data) {
        if (editPasswordResponse.data.editPassword) {
          dispatch({
            type: 'SET_USER_DATA',
            payload: {
              ...userData,
              isLoggedWithTemporaryPassword: false
            }
          });
          localStorage.setItem(
            'user',
            JSON.stringify({
              ...userData,
              isLoggedWithTemporaryPassword: false
            })
          );
          navigate('/');
        } else {
          navigate('/resetPassword');
        }
      }
    }
  };

  return (
    <LoginContainer>
      <LoginAreaResetPassword>
        <H1>{t('ResetPassword')}</H1>
        <ResetPasswordForm>
          <DivInput>
            <LoginInput
              onKeyDown={(target) =>
                target.key === 'Enter' ? handleValidate(target) : null
              }
              value={password}
              onChange={(e) =>
                setPassword(
                  e.target.value.length <= 50 ? e.target.value : password
                )
              }
              autoComplete="off"
              placeholder={t('Password')}
              type={showPassword ? 'text' : 'password'}
              name="password"
            />
            {showPassword ? (
              <IoMdEyeOff
                style={{
                  position: 'absolute',
                  cursor: 'pointer',
                  right: '10px'
                }}
                onClick={() => setShowPassword(false)}
              />
            ) : (
              <IoMdEye
                style={{
                  position: 'absolute',
                  cursor: 'pointer',
                  right: '10px'
                }}
                onClick={() => setShowPassword(true)}
              />
            )}
          </DivInput>
          <LoginInstructions />
          <DivInput>
            <LoginInput
              onKeyDown={(target) =>
                target.key === 'Enter' ? handleValidate(target) : null
              }
              value={newPassword}
              onChange={(e) =>
                setNewPassword(
                  e.target.value.length <= 50 ? e.target.value : newPassword
                )
              }
              autoComplete="off"
              placeholder={t('ConfirmPassword')}
              type={showNewPassword ? 'text' : 'password'}
              name="password"
            />
            {showNewPassword ? (
              <IoMdEyeOff
                style={{
                  position: 'absolute',
                  cursor: 'pointer',
                  right: '10px'
                }}
                onClick={() => setShowNewPassword(false)}
              />
            ) : (
              <IoMdEye
                style={{
                  position: 'absolute',
                  cursor: 'pointer',
                  right: '10px'
                }}
                onClick={() => setShowNewPassword(true)}
              />
            )}
          </DivInput>
          {!passwordValidation ? (
            <PasswordError>{t('IncorretPassword')}</PasswordError>
          ) : null}
          <LoginButton
            onClick={(target) => handleValidate(target)}
            type="submit"
          >
            {t('Save')}
          </LoginButton>
        </ResetPasswordForm>
      </LoginAreaResetPassword>
      <LoginLogoReset>
        <img draggable={false} src={logoGS3} alt="Logo"></img>
        <AllRightsReserved>
          © {t('AllRightsReserved')} - {acurrentYear}
        </AllRightsReserved>
      </LoginLogoReset>
      <LanguageSelect>
        <ChangeLocale setShowCard={() => null} hasMargin={true} />
      </LanguageSelect>
    </LoginContainer>
  );
}
