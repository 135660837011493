import styled from 'styled-components';

export const DivContainerStability = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 20% 80%;
`;

export const DivHeaderStability = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  p {
    padding: 0;
  }
`;

export const DivContentStability = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 20px;
`;

export const DivGraph = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 20px;
  button {
    width: 90%;
    display: flex;
    justify-content: flex-end;
  }
  img {
    width: 90%;
  }
`;
