import { enUS, ptBR } from 'date-fns/locale';
import i18next from 'i18next';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { IoIosClose } from 'react-icons/io';
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';
import Select from 'react-select';
import {
  AdvanceButtonStyled,
  CancelButtonStyled
} from '../../../../Components/Buttons/ButtonsStyle';
import { CloseIconStyle } from '../../../../Components/Graphs/Monitoring/Instrument/StyleInstrumentGraph';
import {
  RegisterInstrumentBackground,
  RegisterInstrumentHeader,
  RegisterInstrumentModal,
  RegisterInstrumentTitle
} from '../../../../Components/Instruments/Register/RegisterInstrumentStyle';
import { checkBoxStyle } from '../../../../Components/Map/MapStyle';
import { StyleInstrumentSelect } from '../../../../Components/Selects/InstrumentSelect';
import {
  toastfyDimiss,
  toastfyError,
  toastfySuccess,
  toastifyLoading
} from '../../../../Components/Toastify';
import ToastifyModel from '../../../../Models/ToastifyModel';
import {
  AdminDateContainer,
  ButtonAreaAdmin,
  CheckboxAdmin,
  InputArea,
  RequiredInput
} from '../../../../Screens/AdminScreen/AdminScreenStyle';
import { Color } from '../../../../Styles/Styles';
import { CreateStructureModulesInput } from '../../../../data/graphql/base-schema';
import { useListModulesByStructureId } from '../../../../data/hooks/admin-permission/use-list-modules-by-structureId';
import { useCreateStructureModules } from '../../../../data/hooks/admin-structure-modules/use-create-structures-modules';
import { useEditStructuresModules } from '../../../../data/hooks/admin-structure-modules/use-edit-structures-modules';
import { useListStructureAndModules } from '../../../../data/hooks/admin-structure-modules/use-list-structure-and-modules';
import { useListModules } from '../../../../data/hooks/use-list-modules.query';
import { useListStructures } from '../../../../data/hooks/use-list-structures.query';
import {
  Mandatory,
  __initialMandatory,
  __initialModuleData
} from './ModulesInterface';

export function ModulesModal({
  setShowModal,
  edit,
  setEdit,
  modulesData,
  setModulesData
}: {
  setShowModal: Dispatch<SetStateAction<boolean>>;
  edit: { status: boolean; data: any };
  setEdit: Dispatch<SetStateAction<{ status: boolean; data: any }>>;
  modulesData: CreateStructureModulesInput;
  setModulesData: Dispatch<SetStateAction<CreateStructureModulesInput>>;
}) {
  const { t } = useTranslation();
  const { data: listStructures } = useListStructures();
  const { data: listModulesByStructures } = useListModulesByStructureId(
    modulesData.structureId
  );
  const { data: listStructuresAndModules } = useListStructureAndModules();
  const { data: listModules } = useListModules();
  const { editStructuresModules } = useEditStructuresModules();
  const { createStructureModules } = useCreateStructureModules();
  const [structures, setStructures] = useState<
    { id: string; label: string; value: string }[]
  >([]);
  const [modulesOfStructures, setModulesOfStructures] = useState<
    { id: string; label: string; value: string }[]
  >([]);
  const [datePickers, setDatePickers] = useState<{
    [key: string]: { start: Date; end: Date; moduleId: string };
  }>({});
  const [checkedModules, setCheckedModules] = useState<{
    [key: string]: boolean;
  }>({});
  const [mandatorys, setMandatorys] = useState<Mandatory>(__initialMandatory);

  useEffect(() => {
    if (edit.status && edit.data) {
      setShowModal(true);
      setModulesData((prev) => ({
        ...prev,
        structureId:
          listStructures?.listStructures.find(
            (role) => role.name === edit.data.Structure
          )?.structureId ?? ''
      }));
      setDatePickers((prevState) => ({
        ...prevState,
        [edit.data.module]: {
          ...prevState[edit.data.module],
          end: edit.data.EndDate ? parseDate(edit.data.EndDate) : new Date(),
          start: edit.data.InitialDate
            ? parseDate(edit.data.InitialDate)
            : new Date()
        }
      }));
      setCheckedModules((prevState) => ({
        ...prevState,
        [edit.data.module]: true
      }));
    }
    if (listStructures) {
      const structures = listStructures.listStructures
        .filter((e) => e.status === true)
        .map((structure: any) => ({
          id: structure.structureId,
          label: structure.name,
          value: structure.structureId
        }));
      setStructures(structures);
    }
    if (
      listModulesByStructures &&
      listModulesByStructures.listModulesByStructureId &&
      listModules
    ) {
      const modules = listModules.listModules
        .filter(
          (list) =>
            list.name !==
            listModulesByStructures.listModulesByStructureId.find(
              (e) => list.name === e.name
            )?.name
        )
        .map((module: any) => ({
          id: module.id,
          label: module.name,
          value: module.id
        }));

      setModulesOfStructures(modules);
      if (!edit.status) {
        const initialCheckedModules: { [key: string]: boolean } =
          modules.reduce((acc: { [key: string]: boolean }, curr) => {
            acc[curr.label] = false;
            return acc;
          }, {});
        setCheckedModules(initialCheckedModules);
      }
    }
  }, [listStructures, listModulesByStructures]);

  const mandatoryInputs = () => {
    const checkEmptyFields = (data: CreateStructureModulesInput): string[] => {
      const emptyFields: string[] = [];

      if (!data.structureId) emptyFields.push('structureId');
      if (datePickers) emptyFields.push('datePickers');

      return emptyFields;
    };
    const emptyFields = checkEmptyFields(modulesData);

    for (const item of emptyFields) {
      setMandatorys((prev) => ({
        ...prev,
        [item]: false
      }));
    }
  };

  const handleEndDateChange = (
    date: Date | null,
    moduleLabel: string,
    moduleId: string
  ) => {
    setDatePickers((prevState) => ({
      ...prevState,
      [moduleLabel]: {
        ...prevState[moduleLabel],
        end: date || new Date(),
        moduleId: moduleId
      }
    }));
  };

  const handleStartDateChange = (
    date: Date | null,
    moduleLabel: string,
    moduleId: string
  ) => {
    setDatePickers((prevState) => ({
      ...prevState,
      [moduleLabel]: {
        ...prevState[moduleLabel],
        start: date || new Date(),
        moduleId: moduleId
      }
    }));
  };

  const handleCheckboxChange = (moduleLabel: string) => {
    setCheckedModules((prevState) => ({
      ...prevState,
      [moduleLabel]: !prevState[moduleLabel]
    }));
  };

  const sendModule = async () => {
    setModulesData((prev) => ({
      ...prev,
      modules: Object.values(datePickers).map((datePicker) => ({
        start: datePicker.start,
        end: datePicker.end,
        moduleId: datePicker.moduleId
      }))
    }));

    const updatedModulesData = {
      ...modulesData,
      modules: Object.values(datePickers).map((datePicker) => ({
        start: datePicker.start,
        end: datePicker.end,
        moduleId: datePicker.moduleId
      }))
    };

    if (datePickers && updatedModulesData.modules.length > 0) {
      const createResponse = await createStructureModules({
        variables: {
          data: {
            modules: updatedModulesData.modules,
            structureId: updatedModulesData.structureId
          }
        }
      });
      toastifyLoading(`${t('registering')} ${t('module')}...`);
      if (createResponse.data) {
        toastfyDimiss('toastLoading');
        toastfySuccess(`${t('module')} ${t('registeredSuccessfully')}!`);
        setDatePickers({});
        setModulesData(__initialModuleData);
        setShowModal(false);
        setEdit({ status: false, data: {} });
        setStructures([]);
      } else if (createResponse.errors) {
        toastfyDimiss('toastLoading');
        toastfyError(
          createResponse.errors[0].message ||
            t(ToastifyModel().toastifyMessage.error)
        );
      }
    } else {
      mandatoryInputs();
      toastfyDimiss('toastLoading');
      toastfyError(t(ToastifyModel().toastifyMessage.fillRequiredFields));
    }
  };

  const editModule = async () => {
    const permission = listStructuresAndModules?.listStructureAndModules.find(
      (e) =>
        e.moduleName === edit.data.module &&
        e.structureName === edit.data.Structure
    );

    if (datePickers && permission?.id) {
      const createResponse = await editStructuresModules({
        variables: {
          data: {
            id: permission?.id ?? '',
            end: datePickers[edit.data.module]?.end,
            start: datePickers[edit.data.module]?.start
          }
        }
      });
      toastifyLoading(`${t('editing')} ${t('module')}...`);
      if (createResponse.data) {
        toastfyDimiss('toastLoading');
        toastfySuccess(`${t('module')} ${t('editedSuccessfully')}!`);
        setDatePickers({});
        setModulesData(__initialModuleData);
        setShowModal(false);
        setEdit({ status: false, data: {} });
      } else if (createResponse.errors) {
        toastfyDimiss('toastLoading');
        toastfyError(
          createResponse.errors[0].message ||
            t(ToastifyModel().toastifyMessage.error)
        );
      }
    } else {
      mandatoryInputs();
      toastfyDimiss('toastLoading');
      toastfyError(t(ToastifyModel().toastifyMessage.fillRequiredFields));
    }
  };

  const parseDate = (dateString: string) => {
    const parts = dateString.split('/');
    return new Date(
      parseInt(parts[2], 10),
      parseInt(parts[1], 10) - 1,
      parseInt(parts[0], 10)
    );
  };

  return (
    <RegisterInstrumentBackground>
      <RegisterInstrumentModal
        style={{
          width: '60%',
          minWidth: 600,
          maxWidth: 650,
          height: 'max-content',
          minHeight: '0'
        }}
      >
        <RegisterInstrumentHeader>
          <RegisterInstrumentTitle>{`${t('Register')} ${t('module')}`}</RegisterInstrumentTitle>
          <IoIosClose
            size={35}
            onClick={() => {
              if (setShowModal) setShowModal(false);
              setEdit({ status: false, data: {} });
            }}
            style={CloseIconStyle}
          />
        </RegisterInstrumentHeader>
        <InputArea>
          <RequiredInput style={{ position: 'relative', width: '50%' }}>
            <Select
              styles={{
                ...StyleInstrumentSelect,
                control: (provided) => ({
                  ...provided,
                  border: 'none',
                  borderBottom: mandatorys.structureId
                    ? `1px solid ${Color.Brown1}`
                    : '1px solid red',
                  borderRadius: 'none',
                  boxSizing: 'border-box',
                  boxShadow: 'none',
                  padding: 'none',
                  fontSize: '11pt',
                  cursor: 'pointer',
                  marginBottom: '13px',
                  userSelect: 'none',
                  background: 'none',
                  ':hover': {
                    borderBottom: mandatorys.structureId
                      ? `1px solid ${Color.Brown1}`
                      : '1px solid red'
                  }
                })
              }}
              isSearchable={false}
              placeholder={t('Structure')}
              hideSelectedOptions
              options={structures}
              onChange={(e: any) => {
                setModulesData((prev) => ({
                  ...prev,
                  structureId: e.id
                }));
              }}
              noOptionsMessage={() => t('NoOptions')}
              maxMenuHeight={200}
              value={structures.find(
                (structure: any) => structure.id === modulesData.structureId
              )}
            />
          </RequiredInput>
          <div
            style={{
              display: 'flex',
              color: Color.Brown1,
              flexDirection: 'column'
            }}
          >
            <span
              style={{
                display: 'flex',
                color: Color.Brown1,
                width: '100%',
                justifyContent: 'center',
                padding: '15px 0',
                fontWeight: '800'
              }}
            >
              {t('module')}
            </span>
            {modulesOfStructures &&
              !edit.status &&
              modulesOfStructures.map((module, index) => {
                const moduleLabel = module.label;
                const moduleId = module.id;
                return (
                  <AdminDateContainer key={index}>
                    <CheckboxAdmin
                      onClick={() => handleCheckboxChange(moduleLabel)}
                    >
                      {t(module.label)}{' '}
                      {checkedModules[moduleLabel] ? (
                        <MdCheckBox style={checkBoxStyle} />
                      ) : (
                        <MdCheckBoxOutlineBlank style={checkBoxStyle} />
                      )}
                    </CheckboxAdmin>
                    {checkedModules[moduleLabel] && (
                      <>
                        {t('Date')}:
                        <DatePicker
                          selected={datePickers[moduleLabel]?.start}
                          onChange={(date) =>
                            handleStartDateChange(date, moduleLabel, moduleId)
                          }
                          selectsStart
                          startDate={datePickers[moduleLabel]?.start}
                          endDate={datePickers[moduleLabel]?.end}
                          placeholderText={t('InitialDate')}
                          className="custom-datepicker"
                          locale={i18next.language.includes('pt') ? ptBR : enUS}
                          dateFormat={
                            true
                              ? i18next.language.includes('pt')
                                ? 'dd/MM/yyyy HH:mm'
                                : 'MM/dd/yyyy HH:mm'
                              : i18next.language.includes('pt')
                                ? 'dd/MM/yyyy'
                                : 'MM/dd/yyyy'
                          }
                          timeIntervals={1}
                          timeCaption="Time"
                          timeFormat="HH:mm"
                          showIcon
                        />{' '}
                        <DatePicker
                          selected={datePickers[moduleLabel]?.end}
                          onChange={(date) =>
                            handleEndDateChange(date, moduleLabel, moduleId)
                          }
                          selectsEnd
                          startDate={datePickers[moduleLabel]?.start}
                          endDate={datePickers[moduleLabel]?.end}
                          minDate={datePickers[moduleLabel]?.start}
                          placeholderText={t('EndDate')}
                          className="custom-datepicker"
                          locale={i18next.language.includes('pt') ? ptBR : enUS}
                          dateFormat={
                            true
                              ? i18next.language.includes('pt')
                                ? 'dd/MM/yyyy HH:mm'
                                : 'MM/dd/yyyy HH:mm'
                              : i18next.language.includes('pt')
                                ? 'dd/MM/yyyy'
                                : 'MM/dd/yyyy'
                          }
                          timeIntervals={1}
                          timeCaption="Time"
                          timeFormat="HH:mm"
                          showIcon
                        />
                      </>
                    )}
                  </AdminDateContainer>
                );
              })}
            {edit.status && (
              <AdminDateContainer>
                {checkedModules[edit.data.module] && (
                  <div
                    style={{ borderColor: mandatorys.structureId ? '' : 'red' }}
                  >
                    {t('Date')}:
                    <DatePicker
                      selected={datePickers[edit.data.module]?.start}
                      onChange={(date) =>
                        handleStartDateChange(
                          date,
                          edit.data.module,
                          listModulesByStructures?.listModulesByStructureId.find(
                            (role) => role.name === edit.data.module
                          )?.id ?? ''
                        )
                      }
                      selectsStart
                      startDate={datePickers[edit.data.module]?.start}
                      endDate={datePickers[edit.data.module]?.end}
                      placeholderText={t('InitialDate')}
                      className="custom-datepicker"
                      locale={i18next.language.includes('pt') ? ptBR : enUS}
                      dateFormat={
                        true
                          ? i18next.language.includes('pt')
                            ? 'dd/MM/yyyy HH:mm'
                            : 'MM/dd/yyyy HH:mm'
                          : i18next.language.includes('pt')
                            ? 'dd/MM/yyyy'
                            : 'MM/dd/yyyy'
                      }
                      timeIntervals={1}
                      timeCaption="Time"
                      timeFormat="HH:mm"
                      showIcon
                    />{' '}
                    <DatePicker
                      selected={datePickers[edit.data.module]?.end}
                      onChange={(date) =>
                        handleEndDateChange(
                          date,
                          edit.data.module,
                          listModulesByStructures?.listModulesByStructureId.find(
                            (role) => role.name === edit.data.module
                          )?.id ?? ''
                        )
                      }
                      selectsEnd
                      startDate={datePickers[edit.data.module]?.start}
                      endDate={datePickers[edit.data.module]?.end}
                      minDate={datePickers[edit.data.module]?.start}
                      placeholderText={t('EndDate')}
                      className="custom-datepicker"
                      locale={i18next.language.includes('pt') ? ptBR : enUS}
                      dateFormat={
                        true
                          ? i18next.language.includes('pt')
                            ? 'dd/MM/yyyy HH:mm'
                            : 'MM/dd/yyyy HH:mm'
                          : i18next.language.includes('pt')
                            ? 'dd/MM/yyyy'
                            : 'MM/dd/yyyy'
                      }
                      timeIntervals={1}
                      timeCaption="Time"
                      timeFormat="HH:mm"
                      showIcon
                    />
                  </div>
                )}
              </AdminDateContainer>
            )}
          </div>
        </InputArea>
        <ButtonAreaAdmin style={{ justifyContent: 'flex-end' }}>
          <CancelButtonStyled
            onClick={() => {
              if (setShowModal) setShowModal(false);
              setEdit({ status: false, data: {} });
              setModulesData(__initialModuleData);
            }}
          >
            {t('Cancel').toLocaleUpperCase()}
          </CancelButtonStyled>
          {!edit.status && (
            <AdvanceButtonStyled onClick={() => sendModule()}>
              {' '}
              {t('Save').toLocaleUpperCase()}
            </AdvanceButtonStyled>
          )}
          {edit.status && (
            <AdvanceButtonStyled onClick={() => editModule()}>
              {' '}
              {t('Edit').toLocaleUpperCase()}
            </AdvanceButtonStyled>
          )}
        </ButtonAreaAdmin>
      </RegisterInstrumentModal>
    </RegisterInstrumentBackground>
  );
}
