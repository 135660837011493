import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type CreateStructureDamRaisingMutationVariables = Types.Exact<{
  structureId: Types.Scalars['String']['input'];
  data: Array<Types.CreateStructureDamRaisingInput> | Types.CreateStructureDamRaisingInput;
}>;


export type CreateStructureDamRaisingMutation = { __typename?: 'Mutation', createStructureDamRaising: string };


export const CreateStructureDamRaisingDocument = gql`
    mutation CreateStructureDamRaising($structureId: String!, $data: [CreateStructureDamRaisingInput!]!) {
  createStructureDamRaising(structureId: $structureId, data: $data)
}
    `;
export type CreateStructureDamRaisingMutationFn = Apollo.MutationFunction<CreateStructureDamRaisingMutation, CreateStructureDamRaisingMutationVariables>;
export type CreateStructureDamRaisingMutationResult = Apollo.MutationResult<CreateStructureDamRaisingMutation>;
export type CreateStructureDamRaisingMutationOptions = Apollo.BaseMutationOptions<CreateStructureDamRaisingMutation, CreateStructureDamRaisingMutationVariables>;