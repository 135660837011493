import { useQuery } from '@apollo/client';
import {
  ListSectionDocument,
  ListSectionQuery,
  ListSectionQueryVariables
} from '../../graphql/query/generated/listSection.query';

export function useListSection(onCompleted?: () => void, onError?: () => void) {
  const { data, loading, error } = useQuery<
    ListSectionQuery,
    ListSectionQueryVariables
  >(ListSectionDocument, {
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    data,
    loading,
    error
  };
}
