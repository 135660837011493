import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { HolderButtonsMonitoringScreen } from '../../Components/Buttons/ButtonsStyle';
import MapGoogleDraw from '../../Components/Map/MapGoogleDraw';
import Button from '../../Components/V2/Atoms/Button/Button';
import { MonitoringContainer } from './MonitoringScreenStyle';

export const MonitoringScreen = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { structureId } = useParams();

  return (
    <MonitoringContainer>
      <HolderButtonsMonitoringScreen>
        <Button
          text={t('Instruments')}
          onClick={() =>
            navigate(`/${structureId}/monitoring/instrument/piezometer`)
          }
          variant="primary"
        />
        <Button
          text={t('readings')}
          onClick={() =>
            navigate(`/${structureId}/monitoring/readings/piezometer`)
          }
          variant="primary"
        />
        <Button
          text={t('graphs')}
          onClick={() => navigate(`/${structureId}/monitoring/graphs`)}
          variant="primary"
        />
        <Button
          text={t('sectionGraphs')}
          onClick={() => {
            navigate(`/${structureId}/monitoring/sectionGraphs`);
          }}
          variant="primary"
        />
      </HolderButtonsMonitoringScreen>
      <MapGoogleDraw />
    </MonitoringContainer>
  );
};
