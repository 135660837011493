import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type EditPiezometerReadingMutationVariables = Types.Exact<{
  structureInfo: Types.StructureInfoInput;
  data: Types.EditPiezometerInaReadingInput;
}>;


export type EditPiezometerReadingMutation = { __typename?: 'Mutation', editPiezometerReading: string };


export const EditPiezometerReadingDocument = gql`
    mutation EditPiezometerReading($structureInfo: StructureInfoInput!, $data: EditPiezometerInaReadingInput!) {
  editPiezometerReading(structureInfo: $structureInfo, data: $data)
}
    `;
export type EditPiezometerReadingMutationFn = Apollo.MutationFunction<EditPiezometerReadingMutation, EditPiezometerReadingMutationVariables>;
export type EditPiezometerReadingMutationResult = Apollo.MutationResult<EditPiezometerReadingMutation>;
export type EditPiezometerReadingMutationOptions = Apollo.BaseMutationOptions<EditPiezometerReadingMutation, EditPiezometerReadingMutationVariables>;