import { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiSolidPencil } from 'react-icons/bi';
import { BsCheckLg } from 'react-icons/bs';
import { IoCloseOutline } from 'react-icons/io5';
import { PiAsteriskBold } from 'react-icons/pi';
import { NumericFormat } from 'react-number-format';
import { DatePickerComponent } from '../../../../../Components/DatePicker';
import { RegisterNumericStyle } from '../../../../../Components/Instruments/Register/RegisterInstrumentStyle';
import { TableComponentStyle } from '../../../../../Components/Tables/TablesStyle';
import {
  toastfyDimiss,
  toastfyError,
  toastfySuccess,
  toastifyLoading
} from '../../../../../Components/Toastify';
import ToastifyModel from '../../../../../Models/ToastifyModel';
import {
  ActionsRow,
  AdminInput,
  ContainerGeneralData,
  IconRequired,
  InputArea,
  PartData,
  RequiredInput
} from '../../../../../Screens/AdminScreen/AdminScreenStyle';
import { useDeleteStructureDamRaising } from '../../../../../data/hooks/use-delete-structure-dam-raising';
import { useEditStructureDamRaising } from '../../../../../data/hooks/use-edit-structure-dam-raising';
import { TextButtonInspection } from '../../../../Inspection/InspectionsStyle';
import {
  DataItemStepThree,
  StepThree,
  __initialStepLightThree
} from '../StructuresInterface';

export function StructureStepThree({
  structureStepThreeData,
  setStructureStepThreeData
}: {
  structureStepThreeData: StepThree;
  setStructureStepThreeData: Dispatch<SetStateAction<StepThree>>;
}) {
  const { t } = useTranslation();
  const [date, setDate] = useState<Date | null>(null);
  const [tableData, setTableData] = useState<DataItemStepThree>(
    __initialStepLightThree
  );
  const [editingTable, setEditingTable] = useState<number | null>(null);
  const [errorTable, setErrorTable] = useState<boolean>(false);
  const { deleteStructureDamRaising } = useDeleteStructureDamRaising();
  const { editStructureDamRaising } = useEditStructureDamRaising();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'elevation') {
      setTableData((prev) => ({
        ...prev,
        [e.target.name]: Number(e.target.value.replace(/,/g, ''))
      }));
    } else {
      setTableData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    }
  };

  const addHistory = async () => {
    if (
      !tableData.stage ||
      !tableData.method ||
      !tableData.elevation ||
      !date
    ) {
      toastfyError(t(ToastifyModel().toastifyMessage.fillRequiredFields));
      setErrorTable(true);
      return;
    }
    if (date > new Date()) {
      toastfyError(t('DateNotGreaterThanCurrentDate'));
      setErrorTable(true);
      return;
    }
    setStructureStepThreeData((prev) => ({
      data: [
        ...prev.data,
        {
          date: date ?? new Date(),
          elevation: tableData.elevation,
          method: tableData.method,
          stage: tableData.stage
        }
      ]
    }));
    setTableData(__initialStepLightThree);
    setErrorTable(false);
  };

  const updateHistory = async () => {
    const updatedData = structureStepThreeData.data.map((item) => {
      if (editingTable) {
        if (item.id === editingTable) {
          return {
            ...item,
            date: date ?? new Date(),
            elevation: tableData.elevation,
            method: tableData.method,
            stage: tableData.stage
          };
        }
        return item;
      } else {
        return item;
      }
    });

    setStructureStepThreeData((prev) => ({
      ...prev,
      data: updatedData
    }));

    setEditingTable(null);
    setTableData(__initialStepLightThree);
    setErrorTable(false);
  };

  const deleteStage = async (index: number, row: any) => {
    if (row.id) {
      const response = await deleteStructureDamRaising({
        variables: {
          damRaisingId: row.id,
          structureId: row.structureId
        }
      });
      toastifyLoading('Deletando...');
      if (response.data) {
        toastfyDimiss('toastLoading');
        toastfySuccess('Deletado Com Sucesso');
        toastfyDimiss('toastfySuccess');
      } else if (response.errors) {
        toastfyDimiss('toastLoading');
        toastfyError(
          response.errors[0].message || t(ToastifyModel().toastifyMessage.error)
        );
      }
    }

    const updatedData = structureStepThreeData.data.filter(
      (_, i) => i !== index
    );

    setStructureStepThreeData((prev) => ({
      ...prev,
      data: updatedData
    }));
  };

  const editStage = async (row: any) => {
    if (row.id) {
      const response = await editStructureDamRaising({
        variables: {
          data: {
            date: row.date,
            elevation: row.elevation,
            id: row.id,
            method: row.method,
            stage: row.stage,
            structureId: row.structureId
          }
        }
      });
      toastifyLoading('Editando...');
      if (response.data) {
        toastfyDimiss('toastLoading');
        toastfySuccess('Editado Com Sucesso');
        toastfyDimiss('toastfySuccess');
      } else if (response.errors) {
        toastfyDimiss('toastLoading');
        toastfyError(
          response.errors[0].message || t(ToastifyModel().toastifyMessage.error)
        );
      }
    }

    setTableData(() => ({
      id: row.id,
      date: row.date,
      elevation: row.elevation,
      method: row.method,
      stage: row.stage
    }));
    setDate(new Date(row.date));
    setEditingTable(row.id);
  };

  return (
    <InputArea>
      <ContainerGeneralData style={{ gridTemplateColumns: '40% 60%' }}>
        <PartData>
          <RequiredInput>
            <AdminInput
              value={tableData.stage}
              onChange={handleChange}
              name="stage"
              placeholder={t('stage')}
              style={
                errorTable && !tableData.stage
                  ? { borderColor: 'red', background: '#ffe6e6' }
                  : {}
              }
            />
            {tableData.stage.length < 3 ? (
              <PiAsteriskBold style={IconRequired} />
            ) : (
              <BsCheckLg style={IconRequired} />
            )}
          </RequiredInput>
          <RequiredInput>
            <AdminInput
              value={tableData.method}
              onChange={handleChange}
              name="method"
              placeholder={t('method')}
              style={
                errorTable && !tableData.method
                  ? { borderColor: 'red', background: '#ffe6e6' }
                  : {}
              }
            />
            {tableData.method.length < 3 ? (
              <PiAsteriskBold style={IconRequired} />
            ) : (
              <BsCheckLg style={IconRequired} />
            )}
          </RequiredInput>
          <RequiredInput>
            <NumericFormat
              name="elevation"
              maxLength={30}
              onChange={handleChange}
              style={{
                ...RegisterNumericStyle,
                width: '50%',
                padding: '10px 0',
                borderColor: errorTable && !tableData.elevation ? 'red' : '',
                background: errorTable && !tableData.elevation ? '#ffe6e6' : ''
              }}
              placeholder={`${t('elevation')}`}
              // thousandSeparator
              value={tableData.elevation === 0 ? '' : tableData.elevation}
            />
            {tableData.elevation === 0 ? (
              <PiAsteriskBold style={IconRequired} />
            ) : (
              <BsCheckLg style={IconRequired} />
            )}
          </RequiredInput>
          <div>
            {t('Date')}:
            <DatePickerComponent
              startDate={date}
              setStartDate={setDate}
              showTimeSelect={false}
            />
          </div>
          <TextButtonInspection
            style={{ width: '60%' }}
            onClick={() =>
              editingTable !== null ? updateHistory() : addHistory()
            }
          >
            {editingTable !== null ? t('Update') : t('Add')}
          </TextButtonInspection>
        </PartData>
        <PartData>
          {structureStepThreeData.data.length !== 0 && (
            <div style={{ height: 'auto', overflowY: 'auto', width: '90%' }}>
              <TableComponentStyle>
                <thead style={{ position: 'sticky', top: 0 }}>
                  <tr>
                    <th>{t('stage')}</th>
                    <th>{t('method')}</th>
                    <th>{t('Elevation')}</th>
                    <th>{t('Date')}</th>
                    <th>{t('actions')}</th>
                  </tr>
                </thead>
                <tbody>
                  {structureStepThreeData.data.map((participant, index) => (
                    <tr key={index}>
                      <td>{participant.stage}</td>
                      <td>{participant.method}</td>
                      <td>{participant.elevation}</td>
                      <td>
                        {new Date(participant.date).toLocaleDateString(
                          'pt-br'
                        ) || ''}
                      </td>
                      <td>
                        <ActionsRow>
                          {participant.id && (
                            <BiSolidPencil
                              onClick={() => editStage(participant)}
                            />
                          )}
                          <IoCloseOutline
                            style={{ color: 'red' }}
                            onClick={() => deleteStage(index, participant)}
                          />
                        </ActionsRow>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </TableComponentStyle>
            </div>
          )}
          {structureStepThreeData.data.length === 0 && (
            <div style={{ height: 'auto', overflowY: 'auto', width: '90%' }}>
              <TableComponentStyle>
                <thead style={{ position: 'sticky', top: 0 }}>
                  <tr>
                    <th>{t('stage')}</th>
                    <th>{t('method')}</th>
                    <th>{t('Elevation')}</th>
                    <th>{t('Date')}</th>
                    <th>{t('actions')}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={5}>Nenhuma etapa criada</td>
                  </tr>
                </tbody>
              </TableComponentStyle>
            </div>
          )}
        </PartData>
      </ContainerGeneralData>
    </InputArea>
  );
}
