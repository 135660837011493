import { useQuery } from '@apollo/client';
import {
  DivContainer,
  DivNotification,
  DivWithouNotification
} from './GeneralNotification.styles';
import { useRef } from 'react';
import { useOnClickOutside } from 'usehooks-ts';
import { useSearchParams } from 'react-router-dom';
import { GeneralNotificationProps } from './GeneralNotification.interface';
import { useTranslation } from 'react-i18next';
import Text from '../../Atoms/Text/Text';
import {
  ListUnreadModuleNotificationsDocument,
  ListUnreadModuleNotificationsQuery,
  ListUnreadModuleNotificationsQueryVariables
} from '../../../../data/graphql/query/generated/listUnreadModuleNotifications.query';

const GeneralNotification = ({
  setShowNotificationsCard
}: GeneralNotificationProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [searchParam, setSearchParam] = useSearchParams();
  const { t: translate } = useTranslation();

  const { data: notifications } = useQuery<
    ListUnreadModuleNotificationsQuery,
    ListUnreadModuleNotificationsQueryVariables
  >(ListUnreadModuleNotificationsDocument, {
    fetchPolicy: 'no-cache'
  });

  const handleClickOutside = () => {
    setShowNotificationsCard(false);
  };

  useOnClickOutside(ref, handleClickOutside);

  if (notifications?.listUnreadModuleNotifications.length === 0)
    return (
      <DivWithouNotification ref={ref}>
        <Text type="span" size="large" weight="bold">
          {translate('NoNotifications')}
        </Text>
      </DivWithouNotification>
    );

  return (
    <DivContainer ref={ref}>
      {notifications?.listUnreadModuleNotifications.map((notification) => (
        <DivNotification
          key={notification.moduleId}
          onClick={() => {
            const searchParams = new URLSearchParams(searchParam);
            searchParams.set('notification', notification.moduleId);
            searchParams.set('module', notification.module);
            setSearchParam(searchParams);
            setShowNotificationsCard(false);
          }}
        >
          <Text type="span" weight="bold">
            {translate(notification.module)}
          </Text>
          <Text type="span">({notification.count})</Text>
        </DivNotification>
      ))}
    </DivContainer>
  );
};

export default GeneralNotification;
