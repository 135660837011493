import { UsersPreferences } from './localStorageFunctions.types';

const USERS_PREFERENCES_KEY = 'usersPreferences';

export const readLocalStorageItem = (item: string) => {
  return JSON.parse(localStorage.getItem(item) || '{}');
};

export const saveLocalStorageItem = (item: string, itemData: object) => {
  localStorage.setItem(item, JSON.stringify(itemData));
};

export const saveLocalStorageTablePreferences = (
  userKey: string,
  tableKey: string,
  tablePreferencesKey: string,
  tablePreferencesObj: any[]
) => {
  const usersPreferences = (readLocalStorageItem(
    USERS_PREFERENCES_KEY
  ) as UsersPreferences) || { users: {} };
  console.log(usersPreferences);

  const updatedItems = {
    ...usersPreferences,
    users: {
      ...usersPreferences.users,
      [userKey]: {
        ...(usersPreferences.users[userKey] || {}),
        tableColumns: {
          ...(usersPreferences.users[userKey]?.tableColumns || {}),
          tables: {
            ...(usersPreferences.users[userKey]?.tableColumns?.tables || {}),
            [tableKey]: {
              ...(usersPreferences.users[userKey]?.tableColumns?.tables[
                tableKey
              ] || {}),
              [tablePreferencesKey]: tablePreferencesObj
            }
          }
        }
      }
    }
  };

  saveLocalStorageItem(USERS_PREFERENCES_KEY, updatedItems);
};
