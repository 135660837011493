import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type EditSectionMutationVariables = Types.Exact<{
  data: Types.EditSectionInput;
}>;


export type EditSectionMutation = { __typename?: 'Mutation', editSection: string };


export const EditSectionDocument = gql`
    mutation EditSection($data: EditSectionInput!) {
  editSection(data: $data)
}
    `;
export type EditSectionMutationFn = Apollo.MutationFunction<EditSectionMutation, EditSectionMutationVariables>;
export type EditSectionMutationResult = Apollo.MutationResult<EditSectionMutation>;
export type EditSectionMutationOptions = Apollo.BaseMutationOptions<EditSectionMutation, EditSectionMutationVariables>;