import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type SetAtoDoneMutationVariables = Types.Exact<{
  setAtoDoneId: Types.Scalars['String']['input'];
}>;


export type SetAtoDoneMutation = { __typename?: 'Mutation', setAtoDone: string };


export const SetAtoDoneDocument = gql`
    mutation SetAtoDone($setAtoDoneId: String!) {
  setAtoDone(id: $setAtoDoneId)
}
    `;
export type SetAtoDoneMutationFn = Apollo.MutationFunction<SetAtoDoneMutation, SetAtoDoneMutationVariables>;
export type SetAtoDoneMutationResult = Apollo.MutationResult<SetAtoDoneMutation>;
export type SetAtoDoneMutationOptions = Apollo.BaseMutationOptions<SetAtoDoneMutation, SetAtoDoneMutationVariables>;