import styled from 'styled-components';
import { Color } from '../../../../Styles/Styles';
export const SelectLevel = styled.select`
  border: none;
  color: ${Color.Brown1};
  outline: none;
  padding: 5px 5px;
  border-radius: none;
`;

export const OptionLevel = styled.option`
  background-color: ${Color.Brown3};
  color: white;
  padding: 5px 5px;
  border-radius: none;
  &:hover {
    background-color: ${Color.Brown1};
  }
`;
