import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type DeleteRecordObservationMutationVariables = Types.Exact<{
  data: Types.DeleteAtoRecordObservationInput;
}>;


export type DeleteRecordObservationMutation = { __typename?: 'Mutation', deleteRecordObservation: string };


export const DeleteRecordObservationDocument = gql`
    mutation DeleteRecordObservation($data: DeleteAtoRecordObservationInput!) {
  deleteRecordObservation(data: $data)
}
    `;
export type DeleteRecordObservationMutationFn = Apollo.MutationFunction<DeleteRecordObservationMutation, DeleteRecordObservationMutationVariables>;
export type DeleteRecordObservationMutationResult = Apollo.MutationResult<DeleteRecordObservationMutation>;
export type DeleteRecordObservationMutationOptions = Apollo.BaseMutationOptions<DeleteRecordObservationMutation, DeleteRecordObservationMutationVariables>;