import { useMutation } from '@apollo/client';
import {
  ForgotPasswordDocument,
  ForgotPasswordMutation,
  ForgotPasswordMutationVariables
} from '../graphql/generated/forgotPassword';

export function useForgotPassword(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [forgotPassword, { data, error, loading }] = useMutation<
    ForgotPasswordMutation,
    ForgotPasswordMutationVariables
  >(ForgotPasswordDocument, {
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    forgotPassword,
    data,
    error,
    loading
  };
}
