import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type SetActionPlanProgressMutationVariables = Types.Exact<{
  data: Types.SetActionPlanProgressInput;
}>;


export type SetActionPlanProgressMutation = { __typename?: 'Mutation', setActionPlanProgress: string };


export const SetActionPlanProgressDocument = gql`
    mutation SetActionPlanProgress($data: SetActionPlanProgressInput!) {
  setActionPlanProgress(data: $data)
}
    `;
export type SetActionPlanProgressMutationFn = Apollo.MutationFunction<SetActionPlanProgressMutation, SetActionPlanProgressMutationVariables>;
export type SetActionPlanProgressMutationResult = Apollo.MutationResult<SetActionPlanProgressMutation>;
export type SetActionPlanProgressMutationOptions = Apollo.BaseMutationOptions<SetActionPlanProgressMutation, SetActionPlanProgressMutationVariables>;