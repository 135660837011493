import { Dispatch, SetStateAction } from 'react';
import FormInspectionsTwoOptions from '../../../Components/Forms/FormInspectionsTwoOptions';
import { ContainerThirdStep } from '../InspectionsStyle';

export function ThirdStepInspection({
  stepThreeStates,
  setStepThreeStates,
  error,
  inspectionId
}: {
  stepThreeStates: any;
  setStepThreeStates: Dispatch<SetStateAction<any>>;
  error: boolean;
  inspectionId: string;
}) {
  return (
    <ContainerThirdStep>
      <FormInspectionsTwoOptions
        stepStates={stepThreeStates}
        setStepStates={setStepThreeStates}
        centerTitle={true}
        topic="Erosões ou processos de instabilização"
        title="ErosionInstabilizationProcesses"
        error={error}
        inspectionId={inspectionId}
      />
      <FormInspectionsTwoOptions
        stepStates={stepThreeStates}
        setStepStates={setStepThreeStates}
        centerTitle={true}
        topic="Borda Livre Operacional"
        title="OperatingFreeboard"
        error={error}
        inspectionId={inspectionId}
      />
    </ContainerThirdStep>
  );
}
