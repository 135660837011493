import styled from 'styled-components';
import { Color, FontSize } from '../../../../Styles/Styles';

export const CheckboxContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const CheckboxLabel = styled.label<{ error?: boolean }>`
  display: flex;
  align-items: center;
  color: ${({ error }) => (error ? Color.Red1 : Color.Brown1)};
  font-size: ${FontSize.Medium};

  &:hover {
    font-weight: 600;
  }
`;

export const CheckboxInput = styled.input<{ error?: boolean }>`
  margin-right: 10px;

  &:checked {
    accent-color: ${({ error }) => (error ? Color.Red1 : Color.Brown1)};
  }
`;

export const ErrorStyled = styled.div`
  color: ${Color.Red1};
  font-size: ${FontSize.Xsmall};
  height: 15px;
  margin-bottom: 5px;
`;
