import { useMap } from '@vis.gl/react-google-maps';
import { useEffect, useRef } from 'react';
import { RectangleBounds } from './MapGoogleComponents.interfaces';

export const useDrawingManager = (
  sectionsBoundsArray: RectangleBounds[] = []
) => {
  const map = useMap();
  const rectanglesRef = useRef<google.maps.Rectangle[]>([]);

  useEffect(() => {
    if (!map) return;

    rectanglesRef.current.forEach((rectangle) => rectangle.setMap(null));
    rectanglesRef.current = [];

    const newRectangles: google.maps.Rectangle[] = sectionsBoundsArray
      .map((bounds) => {
        const rectangle = new google.maps.Rectangle({
          bounds,
          editable: false,
          draggable: false,
          fillColor: 'white',
          fillOpacity: 0.4,
          strokeWeight: 2
        });
        rectangle.setMap(map);
        return rectangle;
      });

    rectanglesRef.current = newRectangles;

  }, [map, sectionsBoundsArray]);

  return () => {
    rectanglesRef.current.forEach((rectangle) => rectangle.setMap(null));
  };
};
