import { gql } from '@apollo/client';
import {
  ActionPlanFilterInput,
  PageInput,
  SortInput
} from '../graphql/base-schema';

export interface FindActionPlanAllInfoVariables {
  pageInfo: PageInput;
  structureId: string;
  filters?: ActionPlanFilterInput;
  sortInfo: SortInput[];
}

export interface FindActionPlanAllInfoQuery {
  findActionPlanPag: {
    nodes: {
      name: string;
      id: string;
      priority: string;
      responsible: string;
      status: string;
      timeFrame: string;
      createdAt: string;
      type: string;
    }[];
    count: number;
    pageInfo: {
      page: number;
      offset: number;
      limit: number;
      totalPages: number;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  };
  findCalculatedInfos: {
    totalPlans: number;
    totalPlansOpened: number;
    totalPlansInProgress: number;
    totalPlansDone: number;
    totalPlansLate: number;
    totalPlansHighPriority: number;
  };
}

export const FindActionPlanAllInfoDocument = gql`
  query FindActionPlanAllInfo(
    $pageInfo: PageInput!
    $structureId: String!
    $filters: ActionPlanFilterInput
    $sortInfo: [SortInput!]
  ) {
    findActionPlanPag(
      pageInfo: $pageInfo
      structureId: $structureId
      filters: $filters
      sortInfo: $sortInfo
    ) {
      nodes {
        name: code
        id
        priority
        responsible: responsibleName
        status: situation
        timeFrame
        createdAt
        type
      }
      count
      pageInfo {
        page
        offset
        limit
        totalPages
        hasNextPage
        hasPreviousPage
      }
    }
    findCalculatedInfos(structureId: $structureId) {
      totalPlans
      totalPlansOpened
      totalPlansInProgress
      totalPlansDone
      totalPlansLate
      totalPlansHighPriority
    }
  }
`;
