import { useMutation } from '@apollo/client';
import {
  EditUserDocument,
  EditUserMutation,
  EditUserMutationVariables
} from '../../graphql/generated/editUser.mutation';
import { ListCompaniesDocument } from '../../graphql/query/generated/listCompanies.query';
import { ListCompanyRelatedUsersAndStructuresDocument } from '../../graphql/query/generated/listCompanyRelatedUsersAndStructures.query';
import { ListModulesRolesDocument } from '../../graphql/query/generated/listModulesRole.query';
import { ListStructuresDocument } from '../../graphql/query/generated/listStructures.query';
import { ListUsersDocument } from '../../graphql/query/generated/listUsers.query';
import { GetUserStructuresListDocument } from '../../graphql/query/generated/structureList.query';
import { GetStructureSummaryDocument } from '../../graphql/query/generated/structureSummary.query';

export function useEditUser(onCompleted?: () => void, onError?: () => void) {
  const [editUser, { data, error, loading }] = useMutation<
    EditUserMutation,
    EditUserMutationVariables
  >(EditUserDocument, {
    refetchQueries: [
      ListStructuresDocument,
      ListCompaniesDocument,
      ListUsersDocument,
      ListModulesRolesDocument,
      GetUserStructuresListDocument,
      ListCompanyRelatedUsersAndStructuresDocument,
      GetStructureSummaryDocument
    ],
    awaitRefetchQueries: true,
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    editUser,
    data,
    error,
    loading
  };
}
