import { useMutation } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MdDelete } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { z } from 'zod';
import {
  DeleteActionPlanCommentDocument,
  DeleteActionPlanCommentMutation,
  DeleteActionPlanCommentMutationVariables
} from '../../../../data/graphql/generated/deleteActionPlanComment.mutation';
import {
  SaveActionPlanCommentDocument,
  SaveActionPlanCommentMutation,
  SaveActionPlanCommentMutationVariables
} from '../../../../data/graphql/generated/saveActionPlanComment.mutation';
import ErrorsTreatments from '../../../../utils/errorTreatment';
import { toastfyError, toastfySuccess } from '../../../Toastify';
import Button from '../../Atoms/Button/Button';
import InputTextarea from '../../Molecules/InputTextarea/InputTextarea';
import ModalConfirm from '../../Molecules/ModalConfirm/ModalConfirm';
import {
  CommentsDeleteData,
  ModalCommentsProps
} from '../../Pages/ActionPlanGeneratedPage/ActionPlanGeneratedPage.interface';
import Comment from './../../Organisms/Comment/Comment';
import { ActionPlanCommentsProps } from './ActionPlanComments.interface';
import {
  DivComment,
  DivContainerAllComment,
  DivContainerComment,
  DivContainerSeeComments,
  DivImagesComments,
  HolderNoComments
} from './ActionPlanComments.styles';
import Text from '../../Atoms/Text/Text';
import {
  FaCommentSlash,
  FaFile,
  FaFileExcel,
  FaFileImage,
  FaFilePdf
} from 'react-icons/fa6';
import { Color } from '../../../../Styles/Styles';
import {
  FindActionPlanDataDocument,
  FindActionPlanDataQuery
} from '../../../../data/graphql/query/generated/findActionPlanData.query';
import InputFileWithList from '../InputFileWithList/InputFileWithList';
import { FileType } from '../../Molecules/FileViewer/FileViewer.interfaces';
import FileViewer from '../../Molecules/FileViewer/FileViewer';
import { API_URL } from '../../../../utils/const';
import { ProjectContext } from '../../../../Context/ContextAPI';
import { UserData } from '../../../../@Types/types';
import Icon from '../../Atoms/Icon/Icon';
import { FaFileAlt } from 'react-icons/fa';
import { IconType } from 'react-icons/lib';

const ActionPlanComments = ({
  disabled,
  actionPlanData
}: ActionPlanCommentsProps) => {
  const { t: translate } = useTranslation();
  const [comment, setComment] = useState('');
  const [files, setFiles] = useState<FileType[]>([]);
  const [selectedFile, setSelectedFile] = useState<FileType | null>(null);
  const [modalConfirm, setModalConfirm] = useState<ModalCommentsProps>({
    isOpen: false,
    commentData: {
      id: '',
      comment: '',
      userName: ''
    }
  });
  const { actionPlanId } = useParams();
  const { userData } = useContext(ProjectContext) as {
    userData: UserData;
  };

  const commentSchema = z.object({
    comment: z.string().refine((val) => val.length > 0, {
      message: translate('fieldIsRequired')
    }),
    files: z.array(z.instanceof(File)).optional()
  });

  type commentSchemaType = z.infer<typeof commentSchema>;

  const {
    register: registerComment,
    handleSubmit: handleSubmitComment,
    setValue,
    formState: { errors: errorsComment }
  } = useForm<commentSchemaType>({
    resolver: zodResolver(commentSchema),
    values: {
      comment: '',
      files: []
    }
  });

  const [saveActionPlanComment] = useMutation<
    SaveActionPlanCommentMutation,
    SaveActionPlanCommentMutationVariables
  >(SaveActionPlanCommentDocument);

  const [deleteActionPlanComment] = useMutation<
    DeleteActionPlanCommentMutation,
    DeleteActionPlanCommentMutationVariables
  >(DeleteActionPlanCommentDocument);

  const handleCreateComment = (commentData: commentSchemaType) => {
    saveActionPlanComment({
      variables: {
        data: {
          actionPlanId: actionPlanId!,
          comment: commentData.comment,
          files: commentData.files
        }
      },
      onCompleted: () => {
        toastfySuccess(translate('registeredSuccessfully'));
        setComment('');
        setFiles([]);
      },
      onError: ({ graphQLErrors }) => {
        const errorMessege = ErrorsTreatments(
          graphQLErrors[0].message,
          translate
        );
        toastfyError(errorMessege);
      },
      update: (cache, { data }) => {
        if (!data) return;
        const existingData = cache.readQuery<FindActionPlanDataQuery>({
          query: FindActionPlanDataDocument,
          variables: {
            actionPlanId: actionPlanId!
          }
        });

        if (!existingData) return;

        const updatedData = {
          ...existingData,
          findActionPlanData: {
            ...existingData.findActionPlanData,
            comments: [
              {
                id: data.saveActionPlanComment.id,
                comment: data.saveActionPlanComment.comment,
                userName: userData.name,
                createdAt: new Date().toISOString(),
                files: data.saveActionPlanComment.files,
                __typename: 'ActionPlanCommentsDataType'
              },
              ...(existingData.findActionPlanData.comments || [])
            ]
          }
        };
        cache.writeQuery({
          query: FindActionPlanDataDocument,
          variables: {
            actionPlanId: actionPlanId!
          },
          data: updatedData
        });
      }
    });
  };

  const handleDeleteComment = (commentData: CommentsDeleteData) => {
    deleteActionPlanComment({
      onCompleted: () => {
        toastfySuccess(translate('deletedSuccessfully'));
        setModalConfirm({
          isOpen: false,
          commentData: {
            id: '',
            comment: '',
            userName: ''
          }
        });
      },
      onError: ({ graphQLErrors }) => {
        const errorMessage = ErrorsTreatments(
          graphQLErrors[0].message,
          translate
        );
        toastfyError(errorMessage);
      },
      variables: {
        commentId: commentData.id
      },
      update: (cache, { data }) => {
        if (!data) return;
        const existingData = cache.readQuery({
          query: FindActionPlanDataDocument,
          variables: {
            actionPlanId: actionPlanId ?? ''
          }
        }) as FindActionPlanDataQuery | undefined;

        const updatedData = {
          ...existingData,
          findActionPlanData: {
            ...existingData?.findActionPlanData,
            comments: existingData?.findActionPlanData.comments?.filter(
              (comment) => comment.id !== commentData.id
            )
          }
        };
        cache.writeQuery({
          query: FindActionPlanDataDocument,
          variables: {
            actionPlanId: actionPlanId ?? ''
          },
          data: updatedData
        });
      }
    });
  };

  const isWithinLastTenMinutes = (createdAt: Date) => {
    const diffMilliseconds =
      new Date().getTime() - new Date(createdAt).getTime();
    const diffMinutes = diffMilliseconds / (1000 * 60);
    return diffMinutes < 10;
  };

  const getCommentActions = (
    comment: FindActionPlanDataQuery['findActionPlanData']['comments'][0],
    isLastComment: boolean,
    isMoreThanTenMinutes: boolean
  ) => {
    if (isLastComment && isMoreThanTenMinutes) {
      return [
        {
          icon: <MdDelete />,
          name: 'Delete',
          onClick: () =>
            setModalConfirm({
              isOpen: true,
              commentData: {
                id: comment.id,
                comment: comment.comment,
                userName: comment.userName
              }
            }),
          canShow: 'delete'
        }
      ];
    }

    return undefined;
  };

  const handleFileChange = (selectedFiles: File[]) => {
    const newselectedFiles: FileType[] = Array.from(selectedFiles).map(
      (file) => ({
        id: '',
        name: file.name,
        url: URL.createObjectURL(file),
        file: file
      })
    );

    const storedStateFiles = files
      .map((file) => file.file)
      .concat(newselectedFiles.map((file) => file.file)) as File[];

    setValue('files', storedStateFiles);
    setFiles((prev) => [...prev, ...newselectedFiles]);
  };

  const handleDeleteImage = (fileSelect: FileType) => {
    const updatedFiles: FileType[] = files.filter(
      (file) => file.file !== fileSelect.file
    );
    setFiles(updatedFiles);

    setValue(
      'files',
      updatedFiles.map((file) => file.file!)
    );
  };

  const getIcon = (fileName: string): IconType => {
    const extension = fileName?.split('.').pop();
    if (!extension) return FaFile;

    const icon: { [key: string]: IconType } = {
      docx: FaFileAlt,
      xlsx: FaFileExcel,
      jpg: FaFileImage,
      png: FaFileImage,
      pdf: FaFilePdf
    };
    return icon[extension] || FaFile;
  };

  return (
    <>
      <DivContainerAllComment>
        <DivContainerComment>
          <InputFileWithList
            multiple={true}
            label=""
            name="files"
            accept=".docx, .xlsx, .jpg, .png, .pdf"
            register={registerComment}
            onChange={(event) => {
              if (event.target.files) {
                handleFileChange(Array.from(event.target.files));
              }
            }}
            onDelete={(file: FileType) => handleDeleteImage(file)}
            files={files}
            error={!!errorsComment.files?.message}
            errorMessage={errorsComment.files?.message}
          />
          <InputTextarea
            name={'comment'}
            label={translate('comment')}
            width="100%"
            errorMessage={errorsComment.comment?.message}
            register={registerComment}
            error={!!errorsComment.comment}
            onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => {
              setComment(event.target.value);
            }}
            value={comment}
            disabled={disabled}
          />
          <Button
            variant={'primary'}
            text="Send"
            size="small"
            onClick={handleSubmitComment(handleCreateComment)}
            disabled={disabled}
          />
        </DivContainerComment>
        <DivContainerSeeComments>
          {actionPlanData?.findActionPlanData.comments.length === 0 ? (
            <HolderNoComments>
              <Text type={'span'} size="medium" color="brown">
                {translate('noCommentsActionPlan')}
              </Text>
              <FaCommentSlash size={25} color={`${Color.Brown1}`} />
            </HolderNoComments>
          ) : (
            actionPlanData?.findActionPlanData.comments.map(
              (comment, index) => {
                const isLastComment = index === 0;
                const isMoreThanTenMinutes = isWithinLastTenMinutes(
                  comment.createdAt
                );
                const actions = getCommentActions(
                  comment,
                  isLastComment,
                  isMoreThanTenMinutes
                );

                return (
                  <DivComment key={comment.id}>
                    <Comment
                      key={comment.id}
                      acronym={comment.userName}
                      date={
                        new Date(comment.createdAt).toLocaleDateString() || ''
                      }
                      user={comment.userName}
                      comment={comment.comment}
                      actions={actions}
                    />
                    <DivImagesComments>
                      {comment.files?.map((file) => (
                        <Icon
                          key={file.id}
                          Icon={getIcon(file.fileName)}
                          onClick={() => window.open(`${API_URL}/${file.url}`)}
                          tooltip={file.fileName}
                        />
                      ))}
                    </DivImagesComments>
                  </DivComment>
                );
              }
            )
          )}
        </DivContainerSeeComments>
      </DivContainerAllComment>
      <ModalConfirm
        showModal={modalConfirm.isOpen}
        title="Delete"
        text={translate('AreYouSureDesiredToDeleteThisComment')}
        onClose={() =>
          setModalConfirm({
            isOpen: false,
            commentData: {
              id: '',
              comment: '',
              userName: ''
            }
          })
        }
        onConfirm={() => handleDeleteComment(modalConfirm.commentData)}
      />

      <FileViewer
        showModal={!!selectedFile}
        files={[selectedFile as FileType]}
        onClose={() => setSelectedFile(null)}
        selectedFile={selectedFile}
      />
    </>
  );
};

export default ActionPlanComments;
