import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultButton } from '../../../../Components/Buttons/DefaultButton';
import Toggles from '../../../../Components/Buttons/Toggles/Toggles';
import {
  toastfyDimiss,
  toastfyError,
  toastifyLoading
} from '../../../../Components/Toastify';
import ToastifyModel from '../../../../Models/ToastifyModel';
import {
  AdminContainer,
  AdminFilter,
  AdminSearch,
  AdminTitle,
  SearchIcon,
  StyledInput
} from '../../../../Screens/AdminScreen/AdminScreenStyle';
import { useListStructures } from '../../../../data/hooks/use-list-structures.query';
import { AdminDeleteModal } from '../../AdminDeleteModal';
import AdminTable from '../../AdminTable';
import { StructureModal } from './StructuresModal';

function Structures() {
  const { t } = useTranslation();
  const [status, setStatus] = useState<boolean>(false);
  const [search, setSearch] = useState('');
  const {
    data: listStructure,
    loading: loadingListStructure,
    error: listStructureError
  } = useListStructures();
  const [showStructureModal, setShowStructureModal] = useState<boolean>(false);
  const [edit, setEdit] = useState<{ status: boolean; data: any }>({
    status: false,
    data: {}
  });
  const [tableValues, setTableValues] = useState<
    { status: string; name: string; Company: string }[]
  >([]);
  const [tableValuesFiltered, setTableValuesFiltered] = useState<
    { status: string; name: string; Company: string }[]
  >([]);
  const [deleting, setDeleting] = useState<{ status: boolean; data: any }>({
    status: false,
    data: {}
  });

  useEffect(() => {
    if (listStructureError) {
      toastfyError(t(ToastifyModel().toastifyMessage.error));
      toastfyDimiss('toastLoading');
    } else if (loadingListStructure) {
      toastifyLoading(t(ToastifyModel().toastifyMessage.loading));
    } else if (listStructure) {
      const filteredTableValues = listStructure.listStructures.map(
        (item: any) => {
          const filteredItem: any = {};
          filteredItem['id'] = item.structureId;
          filteredItem['name'] = item.name;
          filteredItem['Company'] = item.companyName;
          filteredItem['status'] = item.status ? t('active') : t('inactive');
          return filteredItem;
        }
      );

      if (!status) {
        setTableValuesFiltered(
          filteredTableValues.filter((item: any) => item.status === t('active'))
        );
      } else {
        setTableValuesFiltered(filteredTableValues);
      }

      setTableValues(filteredTableValues);
      toastfyDimiss('toastLoading');
    }
  }, [listStructure]);

  const activities = () => {
    if (status) {
      setTableValuesFiltered(
        tableValues.filter((e) => e.status.includes(t('active')))
      );
      return tableValuesFiltered;
    }
    setTableValuesFiltered(
      tableValues.filter(
        (e) =>
          e.status.includes(t('active')) || e.status.includes(t('inactive'))
      )
    );
    return tableValuesFiltered;
  };

  return (
    <AdminContainer>
      <AdminTitle>{t('Structure')}</AdminTitle>
      <AdminFilter>
        <div>
          <DefaultButton
            title={`+ ${t('Add')}`}
            action={() => setShowStructureModal(true)}
          />
          {Toggles.ToggleStatus({
            check: status,
            setCheck: setStatus,
            key: 'status',
            text: t('status'),
            updateValue: activities
          })}
        </div>
        <AdminSearch>
          <StyledInput
            type="text"
            id="search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <SearchIcon size={20} />
        </AdminSearch>
      </AdminFilter>
      <AdminTable
        tableColumns={['name', 'Company', 'status']}
        tableValues={tableValuesFiltered.filter(
          (e) =>
            e.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
            e.Company.toLocaleLowerCase().includes(search.toLocaleLowerCase())
        )}
        edit={edit}
        setEdit={setEdit}
        deleting={deleting}
        setDeleting={setDeleting}
      />
      {(showStructureModal || edit.status) && (
        <StructureModal
          setShowModal={setShowStructureModal}
          showModal={showStructureModal}
          edit={edit}
          setEdit={setEdit}
        />
      )}
      {deleting.status ? (
        <AdminDeleteModal
          id={
            listStructure?.listStructures.find(
              (e) =>
                e.name === deleting.data.name &&
                e.companyName === deleting.data.Company
            )?.structureId ?? ''
          }
          name={deleting.data.name}
          setShowModal={setShowStructureModal}
          setDeleting={setDeleting}
          type="Structures"
          title={t('Structure')}
        />
      ) : null}
    </AdminContainer>
  );
}

export default Structures;
