import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ListSectionsByStructureQueryVariables = Types.Exact<{
  structureId: Types.Scalars['String']['input'];
}>;


export type ListSectionsByStructureQuery = { __typename?: 'Query', listSectionsByStructure: Array<{ __typename?: 'SectionType', finalCoordinateE: number, finalCoordinateN: number, id: string, initialCoordinateE: number, initialCoordinateN: number, name: string, status: boolean, type?: string | null }> };


export const ListSectionsByStructureDocument = gql`
    query ListSectionsByStructure($structureId: String!) {
  listSectionsByStructure(structureId: $structureId) {
    finalCoordinateE
    finalCoordinateN
    id
    initialCoordinateE
    initialCoordinateN
    name
    status
    type
  }
}
    `;
export type ListSectionsByStructureQueryResult = Apollo.QueryResult<ListSectionsByStructureQuery, ListSectionsByStructureQueryVariables>;