import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultButton } from '../../../Components/Buttons/DefaultButton';
import Toggles from '../../../Components/Buttons/Toggles/Toggles';
import {
  toastfyDimiss,
  toastfyError,
  toastifyLoading
} from '../../../Components/Toastify';
import ToastifyModel from '../../../Models/ToastifyModel';
import {
  AdminContainer,
  AdminFilter,
  AdminSearch,
  AdminTitle,
  SearchIcon,
  StyledInput
} from '../../../Screens/AdminScreen/AdminScreenStyle';
import { useListPermissionsUsers } from '../../../data/hooks/admin-permission/use-list-users-permission';
import { AdminDeleteModal } from '../AdminDeleteModal';
import AdminTable from '../AdminTable';
import { AtoData, __initialAtoData } from './AtoInterface';
import { ATOModal } from './AtoModal';

function Ato() {
  const { t }: any = useTranslation();
  const [edit, setEdit] = useState<{ status: boolean; data: any }>({
    status: false,
    data: {}
  });
  const [status, setStatus] = useState<boolean>(false);
  const [search, setSearch] = useState('');
  const [showATOModal, setShowATOModal] = useState<boolean>(false);
  const {
    data: listPermissions,
    loading: loadingList,
    error: listPermissionsError
  } = useListPermissionsUsers();
  const [tableValues, setTableValues] = useState<
    {
      company: string;
      Structures: string;
      User: string;
      module: string;
      Permission: string;
    }[]
  >([]);
  const [deleting, setDeleting] = useState<{ status: boolean; data: any }>({
    status: false,
    data: {}
  });
  const [atoData, setAtoData] = useState<AtoData>(__initialAtoData);
  const [tableValuesFiltered, setTableValuesFiltered] = useState<
    {
      construction: string;
      local: string;
      responsible: string;
      initialDate: string;
      finalDate: string;
    }[]
  >([]);

  useEffect(() => {
    if (listPermissionsError) {
      toastfyError(t(ToastifyModel().toastifyMessage.error));
      toastfyDimiss('toastLoading');
    } else if (loadingList) {
      toastifyLoading(t(ToastifyModel().toastifyMessage.loading));
    } else if (listPermissions) {
      const filteredTableValues = listPermissions.listUsersPermissions.map(
        (item: any) => {
          const filteredItem: any = {};
          filteredItem['construction'] = item.companyName;
          filteredItem['Local'] = item.structureName;
          filteredItem['Responsible'] = item.userName;
          filteredItem['status'] = item.moduleName;
          filteredItem['Edit'] = item.moduleName;
          return filteredItem;
        }
      );

      if (!status) {
        setTableValuesFiltered(
          filteredTableValues.filter((item: any) => item.status === t('active'))
        );
      } else {
        setTableValuesFiltered(filteredTableValues);
      }

      setTableValues(filteredTableValues);
      toastfyDimiss('toastLoading');
    }
  }, [listPermissions]);

  // const activities = () => {
  //   if (status) {
  //     setTableValuesFiltered(tableValues.filter((e) => e.status.includes(t("active"))))
  //     return tableValuesFiltered;
  //   }
  //   setTableValuesFiltered(tableValues.filter((e) => e.status.includes(t("active")) || e.status.includes(t("inactive"))))
  //   return tableValuesFiltered;
  // }

  return (
    <AdminContainer>
      <AdminTitle>{t('Users')}</AdminTitle>
      <AdminFilter>
        <div>
          <DefaultButton
            title={`+ ${t('Add')}`}
            action={() => setShowATOModal(true)}
          />
          {Toggles.ToggleStatus({
            check: status,
            setCheck: setStatus,
            key: 'status',
            text: t('status'),
            updateValue: 'activities'
          })}
        </div>
        <AdminSearch>
          <StyledInput
            type="text"
            id="search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <SearchIcon size={20} />
        </AdminSearch>
      </AdminFilter>
      <AdminTable
        tableColumns={['Users', 'Instrument', 'module', 'ControlLevel']}
        tableValues={tableValues.filter(
          (e) =>
            t(e.Permission)
              .toLocaleLowerCase()
              .includes(t(search.toLocaleLowerCase())) ||
            t(e.Structures)
              .toLocaleLowerCase()
              .includes(t(search.toLocaleLowerCase())) ||
            t(e.User)
              .toLocaleLowerCase()
              .includes(t(search.toLocaleLowerCase())) ||
            t(e.company)
              .toLocaleLowerCase()
              .includes(t(search.toLocaleLowerCase())) ||
            t(e.module)
              .toLocaleLowerCase()
              .includes(t(search.toLocaleLowerCase()))
        )}
        edit={edit}
        setEdit={setEdit}
        deleting={deleting}
        setDeleting={setDeleting}
      />
      {(showATOModal || edit.status) && (
        <ATOModal
          setShowModal={setShowATOModal}
          showModal={showATOModal}
          edit={edit}
          setEdit={setEdit}
          atoData={atoData}
          setAtoData={setAtoData}
          companies={[]}
        />
      )}
      {/* {deleting.status && (
        <AdminDeleteModal
          id={deleting.data.id}
          name={`${deleting.data.Permission} ${t("ofUser")} ${deleting.data.User} ${t("ofStructure")} ${deleting.data.Structures}`}
          setShowModal={setShowATOModal}
          setDeleting={setDeleting}
          type="Permission"
          idsPermission={{
            moduleAssignmentId: listPermissions?.listUsersPermissions.find((e) => e.moduleName === deleting.data.module && e.structureName === deleting.data.Structures)?.moduleAssignmentId ?? '',
            structureUserPermissionId: listPermissions?.listUsersPermissions.find((e) => e.moduleName === deleting.data.module && e.structureName === deleting.data.Structures)?.structureUserPermissionId ?? ''
          }}
        />
      )} */}
    </AdminContainer>
  );
}

export default Ato;
