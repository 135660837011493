import { z } from 'zod';
import InputTextarea from '../../Molecules/InputTextarea/InputTextarea';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import Button from '../../Atoms/Button/Button';
import InputFileWithList from '../InputFileWithList/InputFileWithList';
import InputText from '../../Molecules/InputText/InputText';
import { DivContainer, DivForm } from './SupportForm.styles';
import { FileType } from '../../Molecules/FileViewer/FileViewer.interfaces';
import { useContext, useState } from 'react';
import { toastfyError, toastfySuccess } from '../../../Toastify';
import { API_URL } from '../../../../utils/const';
import { ProjectContext } from '../../../../Context/ContextAPI';
import { UserData } from '../../../../@Types/types';
import { useTranslation } from 'react-i18next';
import { SupportFormProps } from './SupportForm.interfaces';

const SupportForm = ({ setShowModalSupport }: SupportFormProps) => {
  const { userData } = useContext(ProjectContext) as { userData: UserData };
  const { t } = useTranslation();
  const [files, setFiles] = useState<FileType[]>([]);

  const supportSchema = z.object({
    subject: z
      .string({ message: t('fieldIsRequired') })
      .min(1, { message: t('fieldIsRequired') }),
    message: z
      .string()
      .min(1, { message: t('fieldIsRequired') })
      .min(1, { message: t('fieldIsRequired') }),
    attachments: z
      .array(z.instanceof(File))
      .optional()
      .refine(
        (value) => {
          return value?.every((file) => file instanceof File);
        },
        { message: t('fieldIsRequired') }
      )
  });

  type SupportFormType = z.infer<typeof supportSchema>;

  const {
    control,
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm<SupportFormType>({
    resolver: zodResolver(supportSchema)
  });

  const onSubmit = async (data: SupportFormType) => {
    const formData = new FormData();
    formData.append('subject', data.subject);
    formData.append('emailBody', data.message);
    data.attachments?.forEach((file) => {
      formData.append('images', file);
    });
    const response = await fetch(`${API_URL}/user/support`, {
      method: 'POST',
      body: formData,
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: userData.token
      }
    });
    if (response.ok) {
      toastfySuccess(t('EmailSent'));
      setShowModalSupport(false);
    }

    if (!response.ok) {
      toastfyError(t('errorRegistering'));
    }
  };

  const handleChangeFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const newFiles = Array.from(event.target.files).map((file) => ({
        id: '',
        name: file.name,
        url: URL.createObjectURL(file),
        file: file
      }));
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
      setValue(
        'attachments',
        newFiles.map((file) => file.file)
      );
    }
  };

  const handleDeleteFile = (selectFile: FileType) => {
    const newFiles = files.filter((file) => file.file !== selectFile.file);
    setFiles(newFiles);
    setValue('attachments', newFiles.map((file) => file.file) as File[]);
  };

  return (
    <DivContainer>
      <DivForm>
        <InputText
          label={t('Subject')}
          name="subject"
          control={control}
          type="text"
          error={!!errors.subject}
          errorMessage={errors.subject?.message}
          width="280px"
        />
        <div>
          <InputTextarea
            label={t('Message')}
            name="message"
            register={register}
            error={!!errors.message}
            errorMessage={errors.message?.message}
          />
        </div>
        <InputFileWithList
          label={t('Attachment')}
          name="attachments"
          onChange={(event) => {
            handleChangeFiles(event);
          }}
          onDelete={(file: FileType) => {
            handleDeleteFile(file);
          }}
          accept="image/*"
          multiple
          files={files}
          error={!!errors.attachments}
          errorMessage={errors.attachments?.message}
        />
      </DivForm>
      <Button
        variant="primary"
        text={t('Send')}
        size="large"
        onClick={handleSubmit(onSubmit)}
      />
    </DivContainer>
  );
};

export default SupportForm;
