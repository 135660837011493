import { useMutation } from '@apollo/client';
import {
  DeleteCompanyLogoDocument,
  DeleteCompanyLogoMutation,
  DeleteCompanyLogoMutationVariables
} from '../graphql/generated/deleteCompanyLogo';
import { ListCompaniesDocument } from '../graphql/query/generated/listCompanies.query';

export function useDeleteCompanyLogo(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [deleteCompanyLogo, { data, error, loading }] = useMutation<
    DeleteCompanyLogoMutation,
    DeleteCompanyLogoMutationVariables
  >(DeleteCompanyLogoDocument, {
    refetchQueries: [ListCompaniesDocument],
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    deleteCompanyLogo,
    data,
    error,
    loading
  };
}
