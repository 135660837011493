import styled from 'styled-components';
import { Color } from '../../../../Styles/Styles';

export const SidebarContainer = styled.div`
  width: min(300px, 100%);
  height: 100%;
  background-color: ${Color.Brown3};
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
`;

export const MenuItem = styled.label`
  color: ${Color.Brown1};
  font-size: 18px;
  padding: 10px 0;
  margin: 5px 0;
  cursor: pointer;
  display: flex;

  &:hover {
    color: ${Color.Brown2};
  }

  input[type='radio'] {
    accent-color: ${Color.Brown1};
  }
`;
