import { Body, Container, Content, Footer, Head } from './ModalConfirm.styles';
import Text from '../../Atoms/Text/Text';
import Button from '../../Atoms/Button/Button';
import { ModalConfirmProps } from './ModalConfirm.interfaces';

const ModalConfirm = ({
  onClose,
  onConfirm,
  title,
  text,
  subtitle,
  showModal
}: ModalConfirmProps) => {
  return (
    <Container showModal={showModal}>
      <Content showModal={showModal}>
        <Head>
          <Text type="h3">{title}</Text>
        </Head>
        <Body>
          <Text type="label" size="medium">
            {text}
          </Text>
          {subtitle && (
            <Text type="label" size="small">
              {subtitle}
            </Text>
          )}
        </Body>
        <Footer>
          <Button onClick={onClose} text="Cancel" variant="secondary" />
          <Button onClick={onConfirm} text="Confirm" variant="primary" />
        </Footer>
      </Content>
    </Container>
  );
};

export default ModalConfirm;
