import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetModuleByStructureQueryVariables = Types.Exact<{
  userId: Types.Scalars['String']['input'];
  structureInfo: Types.StructureInfoInput;
}>;


export type GetModuleByStructureQuery = { __typename?: 'Query', getModuleByStructure: Array<{ __typename?: 'ModuleFlagType', id: string, name: Types.ModuleEnum, role?: Types.ModuleRoleEnum | null }> };


export const GetModuleByStructureDocument = gql`
    query GetModuleByStructure($userId: String!, $structureInfo: StructureInfoInput!) {
  getModuleByStructure(userId: $userId, structureInfo: $structureInfo) {
    id
    name
    role
  }
}
    `;
export type GetModuleByStructureQueryResult = Apollo.QueryResult<GetModuleByStructureQuery, GetModuleByStructureQueryVariables>;