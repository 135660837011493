import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type DeleteCompanyLogoMutationVariables = Types.Exact<{
  companyId: Types.Scalars['String']['input'];
}>;


export type DeleteCompanyLogoMutation = { __typename?: 'Mutation', deleteCompanyLogo: string };


export const DeleteCompanyLogoDocument = gql`
    mutation DeleteCompanyLogo($companyId: String!) {
  deleteCompanyLogo(companyId: $companyId)
}
    `;
export type DeleteCompanyLogoMutationFn = Apollo.MutationFunction<DeleteCompanyLogoMutation, DeleteCompanyLogoMutationVariables>;
export type DeleteCompanyLogoMutationResult = Apollo.MutationResult<DeleteCompanyLogoMutation>;
export type DeleteCompanyLogoMutationOptions = Apollo.BaseMutationOptions<DeleteCompanyLogoMutation, DeleteCompanyLogoMutationVariables>;