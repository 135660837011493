import styled from 'styled-components';

export const Card = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 10px;
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-start;
`;

export const CardContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const CardFooter = styled.div`
  width: 100%;
  display: flex;
  align-items: flex;
  justify-content: flex-end;
`;
