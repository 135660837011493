import { SectionTypeEnum } from '../../../../data/graphql/base-schema';

export const __initialSectionsData = {
  finalCoordinateE: Number(),
  finalCoordinateN: Number(),
  initialCoordinateN: Number(),
  initialCoordinateE: Number(),
  name: '',
  structureId: '',
  type: SectionTypeEnum.Cross
};

export interface Mandatory {
  finalCoordinateE: boolean;
  finalCoordinateN: boolean;
  initialCoordinateN: boolean;
  initialCoordinateE: boolean;
  name: boolean;
  structureId: boolean;
  type: boolean;
  image: boolean;
}

export const __initialMandatory = {
  finalCoordinateE: true,
  finalCoordinateN: true,
  initialCoordinateN: true,
  initialCoordinateE: true,
  name: true,
  structureId: true,
  type: true,
  image: true
};
