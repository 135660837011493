import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type CreateWaterLevelMutationVariables = Types.Exact<{
  structureInfo: Types.StructureInfoInput;
  alertLevels: Array<Types.CreateWaterLevelAlertLevelsInput> | Types.CreateWaterLevelAlertLevelsInput;
  generalInfo: Types.CreateInstrumentInput;
}>;


export type CreateWaterLevelMutation = { __typename?: 'Mutation', createWaterLevel: string };


export const CreateWaterLevelDocument = gql`
    mutation CreateWaterLevel($structureInfo: StructureInfoInput!, $alertLevels: [CreateWaterLevelAlertLevelsInput!]!, $generalInfo: CreateInstrumentInput!) {
  createWaterLevel(
    structureInfo: $structureInfo
    alertLevels: $alertLevels
    generalInfo: $generalInfo
  )
}
    `;
export type CreateWaterLevelMutationFn = Apollo.MutationFunction<CreateWaterLevelMutation, CreateWaterLevelMutationVariables>;
export type CreateWaterLevelMutationResult = Apollo.MutationResult<CreateWaterLevelMutation>;
export type CreateWaterLevelMutationOptions = Apollo.BaseMutationOptions<CreateWaterLevelMutation, CreateWaterLevelMutationVariables>;