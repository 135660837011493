import { RowContainer } from './InfoRowStyles';
import Text from '../../Atoms/Text/Text';
type InfoRowProps = {
  label: string;
  value?: string | React.ReactNode;
  grid?: string;
};

// TODO: Este component pode não ser necessário, pois pode ser substituído por um componente de texto comum

const InfoRow = ({ label, value, grid }: InfoRowProps) => {
  return (
    <RowContainer style={{ gridArea: grid }}>
      <Text weight="bold" color="black" type="label">
        {label}
      </Text>
      <Text color="black" type="span">
        {value}
      </Text>
    </RowContainer>
  );
};

export default InfoRow;
