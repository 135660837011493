import * as Types from '../base-schema';

import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';
export type SaveActionPlanTeamMembersMutationVariables = Types.Exact<{
  usersId:
    | Array<Types.Scalars['String']['input']>
    | Types.Scalars['String']['input'];
  actionPlanId: Types.Scalars['String']['input'];
}>;

export type SaveActionPlanTeamMembersMutation = {
  __typename?: 'Mutation';
  saveActionPlanTeamMembers: Array<{
    __typename?: 'SavedActionPlanTeamMembersType';
    email: string;
    id: string;
    name: string;
  }>;
};

export const SaveActionPlanTeamMembersDocument = gql`
  mutation SaveActionPlanTeamMembers(
    $usersId: [String!]!
    $actionPlanId: String!
  ) {
    saveActionPlanTeamMembers(usersId: $usersId, actionPlanId: $actionPlanId) {
      email
      id
      name
    }
  }
`;

export type SaveActionPlanTeamMembersMutationFn = Apollo.MutationFunction<
  SaveActionPlanTeamMembersMutation,
  SaveActionPlanTeamMembersMutationVariables
>;

export type SaveActionPlanTeamMembersMutationResult =
  Apollo.MutationResult<SaveActionPlanTeamMembersMutation>;

export type SaveActionPlanTeamMembersMutationOptions =
  Apollo.BaseMutationOptions<
    SaveActionPlanTeamMembersMutation,
    SaveActionPlanTeamMembersMutationVariables
  >;
