import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AdvanceButtonStyled,
  CancelButtonStyled
} from '../../../Components/Buttons/ButtonsStyle';
import { DefaultInput } from '../../../Components/Inputs/InputsStyle';
import { RegisterReadingsArea } from '../../../Components/Instruments/Readings/DataBaseStyles';
import {
  RegisterInstrumentBackground,
  RegisterInstrumentHeader,
  RegisterInstrumentModal,
  RegisterInstrumentTitle
} from '../../../Components/Instruments/Register/RegisterInstrumentStyle';
import {
  toastfyDimiss,
  toastfyError,
  toastfySuccess,
  toastifyLoading
} from '../../../Components/Toastify';
import ToastifyModel from '../../../Models/ToastifyModel';
import { ButtonAreaDelete } from '../../../Screens/AdminScreen/AdminScreenStyle';
import { useDeleteSubscribedUserNotification } from '../../../data/hooks/use-delete-subscribed-user-notification';

interface NotificationDeleteModalTypes {
  deleting: { status: boolean; data: any };
  setDeleting: Dispatch<SetStateAction<{ status: boolean; data: any }>>;
}

export default function NotificationDeleteModal(
  props: NotificationDeleteModalTypes
) {
  const { t } = useTranslation();
  const { deleteSubscribedUserNotification } =
    useDeleteSubscribedUserNotification();

  const handleDelete = async () => {
    const response = await deleteSubscribedUserNotification({
      variables: {
        userNotificationId: props.deleting.data.id
      }
    });
    toastifyLoading('Deletando Notificação...');
    if (response.data) {
      toastfyDimiss('toastLoading');
      toastfySuccess('Notificação Deletada Com Sucesso');
      props.setDeleting({ status: false, data: {} });
      toastfyDimiss('toastfySuccess');
    } else if (response.errors) {
      toastfyDimiss('toastLoading');
      toastfyError(
        response.errors[0].message || t(ToastifyModel().toastifyMessage.error)
      );
    }
  };

  return (
    <RegisterInstrumentBackground>
      <RegisterInstrumentModal
        style={{
          width: '50%',
          minWidth: 600,
          maxWidth: 650,
          height: 'max-content',
          minHeight: '300px'
        }}
      >
        <RegisterInstrumentHeader>
          <RegisterInstrumentTitle>{`${t('Delete')} ${t('Notification')}`}</RegisterInstrumentTitle>
        </RegisterInstrumentHeader>
        <RegisterReadingsArea>
          <DefaultInput
            type="text"
            value={props.deleting.data.Users}
            readOnly
          />
          <DefaultInput
            type="text"
            value={props.deleting.data.Instrument}
            readOnly
          />
          <DefaultInput
            type="text"
            value={props.deleting.data.AlertLevel}
            readOnly
          />
        </RegisterReadingsArea>
        <ButtonAreaDelete>
          <CancelButtonStyled
            onClick={() => props.setDeleting({ status: false, data: {} })}
          >
            {' '}
            {t('Cancel').toLocaleUpperCase()}
          </CancelButtonStyled>
          <AdvanceButtonStyled
            onClick={() => {
              handleDelete();
            }}
          >
            {' '}
            {t('Delete').toLocaleUpperCase()}
          </AdvanceButtonStyled>
        </ButtonAreaDelete>
      </RegisterInstrumentModal>
    </RegisterInstrumentBackground>
  );
}
