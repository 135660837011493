export const API_URL =
  process.env.REACT_APP_BASE_URL || 'http://localhost:3001';

export const BUCKET_URL =
  process.env.REACT_APP_BUCKET_URL || 'http://localhost:3001';

export const GOOGLE_MAPS_TOKEN =
  process.env.REACT_APP_GOOGLE_MAPS_TOKEN || 'CRIAR TOKEN TELLUS';

export const ONE_MEGABYTE = 1048576;
