import { useMutation } from '@apollo/client';
import {
  CreatePiezometerDocument,
  CreatePiezometerMutation,
  CreatePiezometerMutationVariables
} from '../../graphql/generated/addPiezometerInstrument';
import { ListInstrumentsByStructureDocument } from '../../graphql/query/generated/listInstrumentsByStructure.query';
import { ListPiezometersByStructureDocument } from '../../graphql/query/generated/listPiezometersByStructure.query';

export function useCreatePiezometer(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [addPiezometer, { data, error, loading }] = useMutation<
    CreatePiezometerMutation,
    CreatePiezometerMutationVariables
  >(CreatePiezometerDocument, {
    refetchQueries: [
      ListPiezometersByStructureDocument,
      ListInstrumentsByStructureDocument
    ],
    awaitRefetchQueries: true,
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    addPiezometer,
    data,
    error,
    loading
  };
}
