import { useMutation, useQuery } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router-dom';
import { z } from 'zod';
import {
  SaveActionPlanTeamMembersDocument,
  SaveActionPlanTeamMembersMutation,
  SaveActionPlanTeamMembersMutationVariables
} from '../../../../data/graphql/generated/saveActionPlanTeamMembers.mutation';
import {
  FindActionPlanMembersDocument,
  FindActionPlanMembersQuery,
  FindActionPlanMembersQueryVariables
} from '../../../../data/graphql/query/generated/findActionPlanMembers.query';
import ErrorsTreatments from '../../../../utils/errorTreatment';
import { toastfyError, toastfySuccess } from '../../../Toastify';
import Button from '../../Atoms/Button/Button';
import InputSelectSearch from '../../Molecules/InputSelectSearch/InputSelectSearch';
import ViewModal from '../../Molecules/ViewModal/ViewModal';
import {
  DivContainerButtons,
  DivContainer,
  DivInput
} from '../../Pages/ActionPlanTeamPage/ActionPlanTeamPage.styles';
import { OptionItem } from '../CreateActionPlan/CreateActionPlan.interface';
import { ActionPlanSaveMemberProps } from './ActionPlanSaveMember.interface';
import {
  FindActionPlanDataDocument,
  FindActionPlanDataQuery
} from '../../../../data/graphql/query/generated/findActionPlanData.query';

const ActionPlanSaveMember = ({
  setSaveModalIsOpen
}: ActionPlanSaveMemberProps) => {
  const { t: translate } = useTranslation();
  const { actionPlanId } = useParams();

  const [saveActionPlanTeamMembers] = useMutation<
    SaveActionPlanTeamMembersMutation,
    SaveActionPlanTeamMembersMutationVariables
  >(SaveActionPlanTeamMembersDocument);

  const { data: dataMembers, loading: loadingMembers } = useQuery<
    FindActionPlanMembersQuery,
    FindActionPlanMembersQueryVariables
  >(FindActionPlanMembersDocument, {
    variables: {
      actionPlanId: actionPlanId || ''
    },
    onError: ({ graphQLErrors }) => {
      const errorMessage = ErrorsTreatments(
        graphQLErrors[0].message,
        translate
      );
      toastfyError(errorMessage);
    }
  });

  const actionPlanTeamSchema = z.object({
    member: z
      .object({
        value: z.string(),
        label: z.string()
      })
      .nullable()
      .refine((val) => val !== null, {
        message: translate('fieldIsRequired')
      })
  });

  type actionPlanTeamSchemaType = z.infer<typeof actionPlanTeamSchema>;

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<actionPlanTeamSchemaType>({
    resolver: zodResolver(actionPlanTeamSchema)
  });

  const nameOptions: OptionItem[] =
    dataMembers?.findActionPlanMembers.map((member) => ({
      label: member.name,
      value: member.id
    })) ?? [];

  const handleSaveMember = (data: actionPlanTeamSchemaType) => {
    saveActionPlanTeamMembers({
      variables: {
        actionPlanId: actionPlanId ?? '',
        usersId: [data.member?.value]
      },
      onCompleted: () => {
        toastfySuccess(
          `${translate('member')} ${translate('registeredSuccessfully')}`
        );
        setSaveModalIsOpen(false);
      },
      onError: ({ graphQLErrors }) => {
        const errorMessage = ErrorsTreatments(
          graphQLErrors[0].message,
          translate
        );
        toastfyError(errorMessage);
        setSaveModalIsOpen(false);
      },
      update: (cache, { data }) => {
        if (!data) return;
        const existingData = cache.readQuery({
          query: FindActionPlanDataDocument,
          variables: {
            actionPlanId: actionPlanId!
          }
        }) as FindActionPlanDataQuery | undefined;

        const updatedData = {
          ...existingData,
          findActionPlanData: {
            ...existingData?.findActionPlanData,
            teamMembers: {
              ...existingData?.findActionPlanData.teamMembers,
              ...data.saveActionPlanTeamMembers
            }
          }
        };

        cache.writeQuery({
          query: FindActionPlanDataDocument,
          variables: {
            actionPlanId: actionPlanId!
          },
          data: updatedData
        });

        const existingMembers = cache.readQuery({
          query: FindActionPlanMembersDocument,
          variables: {
            actionPlanId: actionPlanId!
          }
        }) as FindActionPlanMembersQuery | undefined;

        const updatedMembers = {
          ...existingMembers,
          findActionPlanMembers: {
            ...existingMembers?.findActionPlanMembers,
            ...data.saveActionPlanTeamMembers
          }
        };

        cache.writeQuery({
          query: FindActionPlanMembersDocument,
          variables: {
            actionPlanId: actionPlanId!
          },
          data: updatedMembers
        });
      }
    });
  };

  return (
    <ViewModal
      showModal={true}
      title={`${translate('Add')} ${translate('team')}`}
      onClose={() => setSaveModalIsOpen(false)}
      width="400px"
      height="320px"
      component={
        loadingMembers ? (
          <DivContainer>
            <Skeleton height={35} width={350} />
            <DivContainerButtons>
              <Skeleton height={40} width={100} />
              <Skeleton height={40} width={100} />
            </DivContainerButtons>
          </DivContainer>
        ) : (
          <DivContainer>
            <DivInput>
              <InputSelectSearch
                errorMessage={errors.member?.message}
                label={translate('member')}
                name="member"
                placeholder={`${translate('select')}`}
                options={nameOptions}
                width="300px"
                error={!!errors.member}
                control={control}
              />
            </DivInput>
            <DivContainerButtons>
              <Button
                onClick={() => setSaveModalIsOpen(false)}
                text="Cancel"
                variant="secondary"
                size="small"
              />
              <Button
                onClick={handleSubmit(handleSaveMember)}
                text="Confirm"
                variant="primary"
                size="small"
              />
            </DivContainerButtons>
          </DivContainer>
        )
      }
    />
  );
};

export default ActionPlanSaveMember;
