import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { sendPSBFile } from '../../../../data/services/PSBService';
import { toastfyError } from '../../../Toastify';
import Button from '../../Atoms/Button/Button';
import Text from '../../Atoms/Text/Text';
import { FileType } from '../../Molecules/FileViewer/FileViewer.interfaces';
import ModalStep from '../../Molecules/ModalStep/ModalStep';
import ViewModal from '../../Molecules/ViewModal/ViewModal';
import PSBList from '../../Organisms/PSBList/PSBList';
import PSBListFiles from '../../Organisms/PSBListFiles/PSBListFiles';
import { PSBListVolumes } from '../../Organisms/PSBListVolumes/PSBListVolumes';
import { Comment } from '../../Organisms/VolumeCheckbox/VolumeCheckbox.interface';
import SimpleContentTemplate from '../../Templates/SimpleContentTemplate/SimpleContentTemplate';

const PSBScreen = () => {
  const { t: translate } = useTranslation();
  const { structureId, psbId } = useParams();
  const nagivate = useNavigate();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [comments, setComments] = useState<Comment[]>([]);
  const [files, setFiles] = useState<FileType[]>([]);
  const [error, setError] = useState(false);

  const handleSubmit = async (): Promise<boolean> => {
    if (comments.length !== 0 && files.length !== 0) {
      const formData = new FormData();
      files.forEach((file) => {
        formData.append('files', file.file as any);
      });
      // TODO: Ajustar o tipo de fileIds
      formData.append(
        'psbDataIds',
        comments.map((comment) => comment.id) as any
      );
      const res = await sendPSBFile(formData, structureId!);
      if (res === 200) {
        handleCloseModalAdd();
      }

      return true;
    } else {
      if (files.length === 0) {
        setError(true);
      }

      toastfyError(translate('fillRequiredFields'));
      return false;
    }
  };

  const handleCloseModalAdd = () => {
    setShowModal(false);
    setFiles([]);
    setComments([]);
    setError(false);
  };

  const handleClose = () => {
    nagivate(`/${structureId}/psb`);
  };

  return (
    <>
      <SimpleContentTemplate
        loading={false}
        title={<Text type={'h1'}>{translate('PSB')}</Text>}
        button={
          <Button
            onClick={() => setShowModal(true)}
            text={`${translate('Add')} ${translate('File')}`}
            size="medium"
            variant="primary"
          />
        }
        content={<PSBList />}
      />
      {showModal && (
        <ModalStep
          title={translate('Add')}
          onClose={() => handleCloseModalAdd()}
          steps={[
            {
              name: '',
              component: (
                <PSBListVolumes
                  setShowModal={setShowModal}
                  comments={comments}
                  setComments={setComments}
                  files={files}
                  setFiles={setFiles}
                  error={error}
                  setError={setError}
                />
              ),
              onNext: () => handleSubmit()
            }
          ]}
        />
      )}
      <ViewModal
        showModal={psbId ? true : false}
        title={`${translate('View')} ${translate('File')}`}
        onClose={() => handleClose()}
        component={<PSBListFiles />}
        width={'768px'}
        height={'600px'}
      />
    </>
  );
};

export default PSBScreen;
