import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type UpdateRecordWeatherConditionsMutationVariables = Types.Exact<{
  data: Types.UpdateRecordWeatherConditionsInput;
}>;


export type UpdateRecordWeatherConditionsMutation = { __typename?: 'Mutation', updateRecordWeatherConditions: string };


export const UpdateRecordWeatherConditionsDocument = gql`
    mutation UpdateRecordWeatherConditions($data: UpdateRecordWeatherConditionsInput!) {
  updateRecordWeatherConditions(data: $data)
}
    `;
export type UpdateRecordWeatherConditionsMutationFn = Apollo.MutationFunction<UpdateRecordWeatherConditionsMutation, UpdateRecordWeatherConditionsMutationVariables>;
export type UpdateRecordWeatherConditionsMutationResult = Apollo.MutationResult<UpdateRecordWeatherConditionsMutation>;
export type UpdateRecordWeatherConditionsMutationOptions = Apollo.BaseMutationOptions<UpdateRecordWeatherConditionsMutation, UpdateRecordWeatherConditionsMutationVariables>;