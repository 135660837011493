import { NumericFormat } from 'react-number-format';
import styled, { CSSProperties } from 'styled-components';
import { Color, FontSize } from '../../../Styles/Styles';

interface RegisterNumericStyledProps {
  error?: boolean;
}

interface DivShowLevelProps {
  showAddLevel?: boolean;
}

export const RegisterInstrumentBackground = styled.div`
  position: fixed;
  background-color: ${Color.backgroundColor}b9;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;

export const RegisterInstrumentModal = styled.div`
  position: fixed;
  background-color: ${Color.White};
  width: 90%;
  height: 80%;
  padding-bottom: 10px;
  z-index: 3;
  flex-direction: column;
  box-shadow: 0px 4px 4px 0px #00000040;
`;

export const RegisterInstrumentHeader = styled.div`
  position: static;
  border-bottom: 1px solid ${Color.Brown1};
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  h4 {
    padding-left: 25px;
  }
`;

export const RegisterInstrumentTitle = styled.span`
  color: ${Color.Brown1};
  position: relative;
  left: 20px;
  font-weight: bold;
`;

export const CustomRegisterInstrumentTitle = styled.span`
  color: ${Color.Brown1};
  position: relative;
  left: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  font-size: ${FontSize.Medium};
`;

export const RegisterInstrumentMenu = styled.div`
  position: relative;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RegisterInstrumentStepTitle = styled.span`
  color: ${Color.Brown1};
  position: relative;
  font-weight: bold;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px 0 10px 0;
`;

export const RegisterInstrumentInputs = styled.div`
  width: 100%;
  height: 100%;
  color: ${Color.Brown1};
  font-size: ${FontSize.Small};
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  padding: 0 15px;
  box-sizing: border-box;
`;

export const HolderInstrumentInputsLeftSide = styled.div`
  width: 49%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 25px;
`;

export const HolderInstrumentInputRightSide = styled.div`
  width: 49%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: 28px;
`;

export const RegisterInstrumentEspecificsInputs = styled.div`
  margin-top: 10px;
  width: 100%;
  color: ${Color.Brown1};
  left: 50%;
  transform: translate(-50%);
  position: relative;
  padding: 7px;
  box-sizing: border-box;
  font-size: ${FontSize.Small};

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  justify-items: center;
  .div1 {
    grid-area: 1 / 1 / 6 / 2;
  }
  .div2 {
    grid-area: 1 / 2 / 6 / 3;
  }
`;

export const RegisterNumericStyle: CSSProperties = {
  color: Color.Brown1,
  border: 'none',
  borderBottom: '1px solid',
  padding: ' 0px 10px 10px 10px',
  width: '75%',
  marginTop: '10px',
  marginBottom: '15px',
  fontSize: FontSize.Small
};

export const RegisterNumericStyled = styled(
  NumericFormat
)<RegisterNumericStyledProps>`
  color: ${({ error }) => (error ? Color.Red1 : Color.Brown1)};
  border: none;
  border-bottom: 1px solid;
  padding: 10px 10px 2px 5px;
  width: 90%;
  font-size: ${FontSize.Small};
  background-color: ${({ error }) => (error ? '#ffe6e6' : 'white')};
`;

export const RegisterInstrumentButtons = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 10px;
  display: flex;
  justify-content: space-between;
`;

export const CustomRegisterInstrumentArea = styled.div`
  width: 48%;
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0px;
  top: 15px;

  input {
    width: 85%;
  }
`;

export const CustomRegisterInputArea = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px 10px 0px 0px;
  box-sizing: border-box;
  width: 93%;
  input:nth-child(3) {
    width: 115px;
  }
  input {
    margin-right: 2px;
    margin-left: 2px;
  }
`;

export const CustomInputArea = styled.div`
  display: flex;
  position: relative;
  width: 82%;

  input {
    margin-right: 5px;
    margin-left: 5px;
  }
`;

export const DivShowLevel = styled.div<DivShowLevelProps>`
  display: ${({ showAddLevel }) => (showAddLevel ? 'block' : 'none')};
`;
