import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AdvanceButtonStyled,
  CancelButtonStyled
} from '../../Components/Buttons/ButtonsStyle';
import {
  RegisterInstrumentBackground,
  RegisterInstrumentHeader,
  RegisterInstrumentModal,
  RegisterInstrumentTitle
} from '../../Components/Instruments/Register/RegisterInstrumentStyle';
import {
  toastfyDimiss,
  toastfyError,
  toastfySuccess,
  toastifyLoading
} from '../../Components/Toastify';
import ToastifyModel from '../../Models/ToastifyModel';
import {
  ButtonAreaAdmin,
  InputArea
} from '../../Screens/AdminScreen/AdminScreenStyle';
import { Color } from '../../Styles/Styles';
import { useDeleteInspection } from '../../data/hooks/use-delete-inspection';

export function InspectionDeleteModal({
  id,
  name,
  setShowModal,
  setDeleting
}: {
  id: string;
  name: string | number;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  setDeleting: Dispatch<SetStateAction<{ status: boolean; data: any }>>;
}) {
  const { t } = useTranslation();
  const { deleteUserInspection } = useDeleteInspection(id);

  const SendDelete = async () => {
    const deleteResponse = await deleteUserInspection();
    toastifyLoading(`${t('deleting')} ${name}...`);
    if (deleteResponse.data) {
      toastfyDimiss('toastLoading');
      toastfySuccess(`${name} ${t('successfullyDeactivated')}!`);
      setShowModal(false);
      setDeleting({ status: false, data: {} });
    } else if (deleteResponse.errors) {
      toastfyDimiss('toastLoading');
      toastfyError(
        deleteResponse.errors[0].message ||
          t(ToastifyModel().toastifyMessage.error)
      );
      setShowModal(false);
      setDeleting({ status: false, data: {} });
    }
  };

  return (
    <RegisterInstrumentBackground>
      <RegisterInstrumentModal
        style={{
          width: '60%',
          minWidth: 600,
          maxWidth: 650,
          height: 'max-content',
          minHeight: 'auto',
          color: Color.Text1
        }}
      >
        <RegisterInstrumentHeader>
          <RegisterInstrumentTitle>{`${t('Delete')} ${t('NewClient')}`}</RegisterInstrumentTitle>
        </RegisterInstrumentHeader>
        <InputArea>Deseja mesmo desativar a inspeção {name}?</InputArea>
        <ButtonAreaAdmin>
          <CancelButtonStyled
            onClick={() => {
              if (setShowModal) setShowModal(false);
              setDeleting({ status: false, data: {} });
            }}
          >
            {t('Cancel').toLocaleUpperCase()}
          </CancelButtonStyled>
          <AdvanceButtonStyled onClick={() => SendDelete()}>
            {' '}
            {t('Disabled').toLocaleUpperCase()}
          </AdvanceButtonStyled>
        </ButtonAreaAdmin>
      </RegisterInstrumentModal>
    </RegisterInstrumentBackground>
  );
}
