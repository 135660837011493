import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type UpdateGeneralCommentsMutationVariables = Types.Exact<{
  inspectionId: Types.Scalars['String']['input'];
  newGeneralComments: Types.Scalars['String']['input'];
}>;

export type UpdateGeneralCommentsMutation = {
  __typename?: 'Mutation';
  updateGeneralComments: string;
};

export const UpdateGeneralCommentsDocument = gql`
  mutation UpdateGeneralComments(
    $inspectionId: String!
    $newGeneralComments: String!
  ) {
    updateGeneralComments(
      inspectionId: $inspectionId
      newGeneralComments: $newGeneralComments
    )
  }
`;
export type UpdateGeneralCommentsMutationFn = Apollo.MutationFunction<
  UpdateGeneralCommentsMutation,
  UpdateGeneralCommentsMutationVariables
>;
export type UpdateGeneralCommentsMutationResult =
  Apollo.MutationResult<UpdateGeneralCommentsMutation>;
export type UpdateGeneralCommentsMutationOptions = Apollo.BaseMutationOptions<
  UpdateGeneralCommentsMutation,
  UpdateGeneralCommentsMutationVariables
>;
