import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ListSectionQueryVariables = Types.Exact<{ [key: string]: never }>;

export type ListSectionQuery = {
  __typename?: 'Query';
  listSection: Array<{
    __typename?: 'ListSectionsType';
    id: string;
    finalCoordinateE: number;
    finalCoordinateN: number;
    initialCoordinateE: number;
    initialCoordinateN: number;
    name: string;
    status: boolean;
    type: Types.SectionTypeEnum;
    structureName: string;
    isUTM: boolean;
  }>;
};

export const ListSectionDocument = gql`
  query ListSection {
    listSection {
      id
      finalCoordinateE
      finalCoordinateN
      initialCoordinateE
      initialCoordinateN
      name
      status
      type
      structureName
      isUTM
    }
  }
`;
export type ListSectionQueryResult = Apollo.QueryResult<
  ListSectionQuery,
  ListSectionQueryVariables
>;
