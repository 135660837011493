import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type DeleteInspectionMutationVariables = Types.Exact<{
  inspectionId: Types.Scalars['String']['input'];
}>;


export type DeleteInspectionMutation = { __typename?: 'Mutation', deleteInspection: string };


export const DeleteInspectionDocument = gql`
    mutation DeleteInspection($inspectionId: String!) {
  deleteInspection(inspectionId: $inspectionId)
}
    `;
export type DeleteInspectionMutationFn = Apollo.MutationFunction<DeleteInspectionMutation, DeleteInspectionMutationVariables>;
export type DeleteInspectionMutationResult = Apollo.MutationResult<DeleteInspectionMutation>;
export type DeleteInspectionMutationOptions = Apollo.BaseMutationOptions<DeleteInspectionMutation, DeleteInspectionMutationVariables>;