import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type UpdateRecordImageObservationMutationVariables = Types.Exact<{
  data: Types.UpdateRecordImageObservationInput;
}>;


export type UpdateRecordImageObservationMutation = { __typename?: 'Mutation', updateRecordImageObservation: string };


export const UpdateRecordImageObservationDocument = gql`
    mutation UpdateRecordImageObservation($data: UpdateRecordImageObservationInput!) {
  updateRecordImageObservation(data: $data)
}
    `;
export type UpdateRecordImageObservationMutationFn = Apollo.MutationFunction<UpdateRecordImageObservationMutation, UpdateRecordImageObservationMutationVariables>;
export type UpdateRecordImageObservationMutationResult = Apollo.MutationResult<UpdateRecordImageObservationMutation>;
export type UpdateRecordImageObservationMutationOptions = Apollo.BaseMutationOptions<UpdateRecordImageObservationMutation, UpdateRecordImageObservationMutationVariables>;