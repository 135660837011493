import styled from 'styled-components';
import { Color } from '../../../../Styles/Styles';

export const DivContainerGeneralPageData = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 15px;

  span {
    font-weight: lighter;
  }
`;

export const DivGroupText = styled.div`
  border-bottom: 0.5px solid ${Color.Brown3};
  padding-bottom: 15px;
`;
