import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ListModulesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ListModulesQuery = { __typename?: 'Query', listModules: Array<{ __typename?: 'ModuleType', id: string, name: string, index: number }> };


export const ListModulesDocument = gql`
    query ListModules {
  listModules {
    id
    name
    index
  }
}
    `;
export type ListModulesQueryResult = Apollo.QueryResult<ListModulesQuery, ListModulesQueryVariables>;