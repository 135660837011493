/* eslint-disable @typescript-eslint/no-explicit-any */
// RegisterPluviometerComponent
import React, { useEffect, useState } from 'react';
import {
  CustomRegisterInstrumentArea,
  CustomRegisterInstrumentTitle,
  RegisterInstrumentEspecificsInputs,
  RegisterInstrumentInputs
} from '../RegisterInstrumentStyle';
import { useTranslation } from 'react-i18next';
import { CustomControlLevelTable } from '../../../Tables/Instruments/CustomControlLevelTable';
import { DatePickerComponent } from '../../../DatePicker';
import { DefaultInput } from '../../../Inputs/InputsStyle';
import { toastfyError } from '../../../Toastify';
import { FaCirclePlus } from 'react-icons/fa6';
import ToastifyModel from '../../../../Models/ToastifyModel';

export const RegisterPluviometerComponent = (props: any) => {
  const { setInstrumentEspecificData, instrumentEspecificData } = props;
  const { t } = useTranslation();
  const [registerDate, setRegisterDate] = useState<Date>(new Date());
  const [specificInfosError, setSpecificInfosError] = useState(false);

  useEffect(() => {
    setInstrumentEspecificData('readingDate', registerDate);
  }, [registerDate]);

  const addControlLevel = () => {
    if (!instrumentEspecificData.name || !instrumentEspecificData.value) {
      toastfyError(t(ToastifyModel().toastifyMessage.fillRequiredFields));
      setSpecificInfosError(true);
      return;
    }
    setSpecificInfosError(false);

    const newLevel = {
      name: instrumentEspecificData.name,
      value: instrumentEspecificData.value
    };

    const updatedLevels = instrumentEspecificData.alertLevelsPv
      ? [...instrumentEspecificData.alertLevelsPv, newLevel]
      : [newLevel];

    setInstrumentEspecificData('alertLevelsPv', updatedLevels);
    setInstrumentEspecificData('name', '');
    setInstrumentEspecificData('value', '');
  };

  return (
    <RegisterInstrumentEspecificsInputs>
      <CustomRegisterInstrumentArea>
        <CustomRegisterInstrumentTitle>
          {t('CustomControlLevels')}
        </CustomRegisterInstrumentTitle>
        <DefaultInput
          onChange={(e) => setInstrumentEspecificData('name', e.target.value)}
          value={instrumentEspecificData.name || ''}
          placeholder={t('LevelName')}
          error={!instrumentEspecificData.name && specificInfosError}
        />
        <DefaultInput
          type="number"
          onChange={(e) =>
            setInstrumentEspecificData('value', parseFloat(e.target.value) || 0)
          }
          value={instrumentEspecificData.value || ''}
          placeholder={t('Value')}
          error={!instrumentEspecificData.value && specificInfosError}
        />
        <div
          style={{
            width: '50%',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'center',
            flexDirection: 'column',
            rowGap: '10px'
          }}
        >
          <div
            onClick={addControlLevel}
            style={{
              padding: '5px 10px 5px 0px',
              display: 'flex',
              alignItems: 'center',
              columnGap: '5px',
              cursor: 'pointer'
            }}
          >
            <FaCirclePlus size={18} color="#32CD32" />
            <span>{t('AddLevel')}</span>
          </div>

          <div style={{}}>
            {t('Date')}:
            <DatePickerComponent
              startDate={registerDate}
              setStartDate={setRegisterDate}
            />
          </div>
        </div>
      </CustomRegisterInstrumentArea>
      <CustomControlLevelTable
        controlLevels={instrumentEspecificData.alertLevelsPv || []}
        onDelete={(index: any) => {
          const updatedLevels = [...instrumentEspecificData.alertLevelsPv];
          updatedLevels.splice(index, 1);
          setInstrumentEspecificData('alertLevelsPv', updatedLevels);
        }}
      />
    </RegisterInstrumentEspecificsInputs>
  );
};
