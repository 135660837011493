import { useMutation, useQuery } from '@apollo/client';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdDelete, MdEdit } from 'react-icons/md';
import { useParams, useSearchParams } from 'react-router-dom';
import {
  InputMaybe,
  InstrumentReadingTypeEnum,
  InstrumentStatusEnum,
  InstrumentTypeEnum
} from '../../../../data/graphql/base-schema';
import {
  DeleteInstrumentReadingDocument,
  DeleteInstrumentReadingMutation,
  DeleteInstrumentReadingMutationVariables
} from '../../../../data/graphql/generated/deleteInstrumentReading';
import {
  ListReadingBySurfaceMarkersPagDocument,
  ListReadingBySurfaceMarkersPagQuery,
  ListReadingBySurfaceMarkersPagQueryVariables
} from '../../../../data/graphql/query/generated/listReadingBySurfaceMarkersPag.query';
import ToastifyModel from '../../../../Models/ToastifyModel';
import ReadingsTableConst from '../../../../utils/ReadingsTable.const';
import { InstrumentTemplateTypeEnum } from '../../../../utils/registerReadingsTemplate';
import { toastfyError, toastfySuccess } from '../../../Toastify';
import Button from '../../Atoms/Button/Button';
import Text from '../../Atoms/Text/Text';
import { AvailableContextActionsProps } from '../../Molecules/ActionMenu/ActionMenu.interface';
import ModalConfirm from '../../Molecules/ModalConfirm/ModalConfirm';
import ViewModal from '../../Molecules/ViewModal/ViewModal';
import FilterReadingsPage from '../../Organisms/FilterReadingsPage/FilterReadingsPage';
import ReadingSurfaceMarker from '../../Organisms/ReadingSurfaceMarkerForm/ReadingSurfaceMarker';
import { ReadingSurfaceMarkerFormType } from '../../Organisms/ReadingSurfaceMarkerForm/ReadingSurfaceMarker.interfaces';
import RegisterSurfaceMarkerBathReadings from '../../Organisms/RegisterSurfaceMarkerBathReadings/RegisterSurfaceMarkerBathReadings';
import SimpleContentTemplate from '../../Templates/SimpleContentTemplate/SimpleContentTemplate';
import { ReadingsTableData } from '../ReadingsPageWaterLevel/ReadingsTable.interface';
import {
  DivContainerTable,
  DivTable
} from './ReadingsPageSurfaceMarker.styles';
import { ProjectContext } from '../../../../Context/ContextAPI';
import Enums from '../../../../utils/enumns';
import Paginator from '../../Molecules/Paginator/Paginator';
import TableDraggableComponent from '../../Molecules/TableDraggable/TableDraggableComponent';
import { TABLE_IDS } from '../../../../utils/TableConsts';

const ReadingsPageSurfaceMarker = () => {
  const { structureId } = useParams();
  const [searchParams] = useSearchParams();
  const { t: translate } = useTranslation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [editSurfaceMaker, setEditSurfaceMarker] =
    useState<ReadingSurfaceMarkerFormType | null>(null);
  const filters = {
    instrumentsId: (searchParams.get('name') as InputMaybe<string[]>) || null,
    readingType:
      (searchParams.get('type') as InstrumentReadingTypeEnum) || null,
    status: (searchParams.get('status') as InstrumentStatusEnum) || null
  };
  const { UserRoles } = Enums();
  const { getUserRoleByModule } = useContext(ProjectContext) as {
    getUserRoleByModule: (moduleName: string) => string;
  };
  const userRole = getUserRoleByModule('monitoring');
  const [modalReadingIsOpen, setModalReadingIsOpen] = useState(false);
  const [deleting, setDeleting] = useState({
    status: false,
    readingId: '',
    instrumentId: ''
  });
  const structureInfo = {
    structureId: structureId || '',
    associatedStructureId: null
  };
  const actionsTable: AvailableContextActionsProps<any>[] = [
    {
      name: `${translate('Edit')}`,
      icon: <MdEdit />,
      onClick: (reading) => handleEditSurfaceMarker(reading),
      canShow: 'canEdit'
    },
    {
      name: `${translate('Delete')}`,
      icon: <MdDelete />,
      onClick: (item: any) => {
        setDeleting({
          status: true,
          readingId: item.readingId,
          instrumentId: item.instrumentId
        });
      },
      canShow: 'canDelete'
    }
  ];

  const {
    data: dataReadingsBySurfaceMarkers,
    loading: loadingReadingsBySurfaceMarkers
  } = useQuery<
    ListReadingBySurfaceMarkersPagQuery,
    ListReadingBySurfaceMarkersPagQueryVariables
  >(ListReadingBySurfaceMarkersPagDocument, {
    variables: {
      structureInfo,
      pageInfo: {
        limit: parseInt(searchParams.get('totalForPage') || '10') as number,
        page: parseInt(searchParams.get('currentPage') || '1')
      },
      filters
    }
  });

  const [deleteInstrument] = useMutation<
    DeleteInstrumentReadingMutation,
    DeleteInstrumentReadingMutationVariables
  >(DeleteInstrumentReadingDocument);
  const optionsPaginatorDefault = [
    { value: 10, label: '10' },
    { value: 50, label: '50' },
    { value: 100, label: '100' }
  ];
  const totalPages =
    dataReadingsBySurfaceMarkers?.listReadingBySurfaceMarkersPag.pageInfo
      .totalPages || 0;

  const tableData =
    dataReadingsBySurfaceMarkers?.listReadingBySurfaceMarkersPag.nodes?.map(
      (item): ReadingsTableData => ({
        readingId: item.reading.id,
        instrumentId: item.instrumentId,
        name: item.name ?? '-',
        observation: item.reading.observation ?? '-',
        date:
          new Date(item.reading.date ?? 0).toLocaleDateString('pt-BR') ?? '-',
        coordinateE: item.reading.coordinateE ?? '-',
        coordinateN: item.reading.coordinateN ?? '-',
        elevation: item.reading.elevation ?? '-',
        operationalStatus:
          translate(item.operationalStatus.toLowerCase()) ?? '-',
        readingType: translate(item.readingType) ?? '-',
        structureType: item.structureType ?? '-'
      })
    );

  const handleEditSurfaceMarker = (readingRole: ReadingsTableData) => {
    const reading =
      dataReadingsBySurfaceMarkers?.listReadingBySurfaceMarkersPag.nodes?.find(
        (item) => item.reading.id === readingRole.readingId
      );
    if (!reading) return;
    const newReading = {
      id: readingRole.readingId,
      elevation: readingRole.elevation.toLocaleString(),
      coordinateE: readingRole.coordinateE,
      coordinateN: readingRole.coordinateN,
      date: new Date(reading.reading.date ?? Date.now()),
      observation: readingRole.observation
    };
    setEditSurfaceMarker(newReading);
    setShowModal(true);
  };

  const handleDeleteReading = () => {
    deleteInstrument({
      variables: {
        structureId: structureId!,
        data: {
          readingId: deleting.readingId,
          instrumentId: deleting.instrumentId
        }
      },
      onCompleted: () => {
        setDeleting({
          status: false,
          readingId: '',
          instrumentId: ''
        });
        toastfySuccess(translate('deletedSuccessfully'));
      },
      onError: () => {
        setDeleting({
          status: false,
          readingId: '',
          instrumentId: ''
        });
        toastfyError(translate(ToastifyModel().toastifyMessage.error));
      },
      refetchQueries: [ListReadingBySurfaceMarkersPagDocument]
    });
  };

  return (
    <>
      <SimpleContentTemplate
        loading={loadingReadingsBySurfaceMarkers}
        title={<Text type="h1">{translate('SurfaceMarker')} </Text>}
        button={
          userRole !== UserRoles.Viewer && (
            <>
              <Button
                onClick={() => {
                  setEditSurfaceMarker(null);
                  setShowModal(true);
                }}
                variant={'primary'}
                text={translate('RegisterReadings')}
              />
              <Button
                variant={'primary'}
                size="large"
                text={translate('RegisterReadingsFile')}
                onClick={() => setModalReadingIsOpen(true)}
              />
            </>
          )
        }
        content={
          <DivContainerTable>
            <DivTable>
              <TableDraggableComponent
                tableId={TABLE_IDS.READINGS_SURFACEMARKER_TABLE}
                columnsArray={ReadingsTableConst(translate).surfaceMarker}
                tableData={tableData || []}
                actions={
                  userRole !== UserRoles.Viewer ? actionsTable : undefined
                }
                filterComponent={
                  <FilterReadingsPage
                    instrument={InstrumentTypeEnum.SurfaceMarker}
                  />
                }
                paginator={
                  <Paginator
                    options={optionsPaginatorDefault}
                    totalPages={totalPages}
                    showInUrl
                  />
                }
                sortUrl
                searchUrl
                columnSelect
                showCountColumn
              />
            </DivTable>
          </DivContainerTable>
        }
      />
      <ViewModal
        showModal={showModal}
        title={
          editSurfaceMaker
            ? `${translate('EditReadings')} ${translate('SurfaceMarker')}`
            : `${translate('NewReading')} ${translate('SurfaceMarker')}`
        }
        onClose={() => {
          setEditSurfaceMarker(null);
          setShowModal(false);
        }}
        component={
          <ReadingSurfaceMarker
            reading={editSurfaceMaker as ReadingSurfaceMarkerFormType}
            setShowModal={setShowModal}
          />
        }
        width="500px"
        height="600px"
      />
      <ModalConfirm
        showModal={deleting.status}
        onClose={() =>
          setDeleting({
            status: false,
            readingId: '',
            instrumentId: ''
          })
        }
        onConfirm={() => handleDeleteReading()}
        title={`${translate('Delete')} ${translate('reading')}`}
        text={translate('ConfirmDelete')}
      />

      <ViewModal
        showModal={modalReadingIsOpen}
        onClose={() => setModalReadingIsOpen(false)}
        title={translate('SurfaceMarker')}
        height={'auto'}
        width={'auto'}
        component={
          <RegisterSurfaceMarkerBathReadings
            onClose={setModalReadingIsOpen}
            template={InstrumentTemplateTypeEnum.SurfaceMarker}
          />
        }
      />
    </>
  );
};

export default ReadingsPageSurfaceMarker;
