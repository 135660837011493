import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type AddParticipantsMutationVariables = Types.Exact<{
  inspectionId: Types.Scalars['String']['input'];
  participants: Array<Types.CreateParticipantsInput> | Types.CreateParticipantsInput;
}>;


export type AddParticipantsMutation = { __typename?: 'Mutation', addParticipants: string };


export const AddParticipantsDocument = gql`
    mutation AddParticipants($inspectionId: String!, $participants: [CreateParticipantsInput!]!) {
  addParticipants(inspectionId: $inspectionId, participants: $participants)
}
    `;
export type AddParticipantsMutationFn = Apollo.MutationFunction<AddParticipantsMutation, AddParticipantsMutationVariables>;
export type AddParticipantsMutationResult = Apollo.MutationResult<AddParticipantsMutation>;
export type AddParticipantsMutationOptions = Apollo.BaseMutationOptions<AddParticipantsMutation, AddParticipantsMutationVariables>;