import styled from 'styled-components';
import { Color, FontSize } from '../../../Styles/Styles';

export const HolderPage = styled.div`
  width: 85%;
  height: 90%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
`;

export const HolderFilterAndTable = styled.div`
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  column-gap: 10px;
`;

export const Tooltip = styled.span`
  visibility: hidden;
  color: white;
  background-color: black;
  text-align: center;
  border-radius: 6px;
  padding: 20px 2px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 40%;
  margin-left: -100px;
  ::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
`;

export const CellWithTooltip = styled.td`
  position: relative;
  &:hover ${Tooltip} {
    visibility: visible;
  }
`;

export const HolderOverflowTable = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;

  &::-webkit-scrollbar {
    width: 8px; /*  Largura da barra de rolagem */
  }

  &::-webkit-scrollbar-track {
    background: rgba(
      0,
      0,
      0,
      0.1
    ); /* Cor de fundo da trilha da barra de rolagem */
    border-radius: 10px; /* Arredondamento da trilha */
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(150, 116, 66, 1); /* Cor da barra de rolagem */
    border-radius: 10px; /* Arredondamento da barra de rolagem */
  }

  &::-webkit-scrollbar-thumb:hover {
    background: rgba(
      150,
      116,
      66,
      0.8
    ); /* Cor da barra de rolagem ao passar o mouse */
  }
`;

export const DataBaseButtonArea = styled.div`
  position: absolute;
  right: 10px;
  bottom: 10px;

  button {
    margin: 5px;
  }
`;

export const RegisterReadingsArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  position: relative;
  align-self: center;
  top: 20px;
  color: ${Color.Brown1};
`;

export const HolderFilterSelect = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const PagesButtonArea = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 25px;
  color: ${Color.Brown1};

  span {
    font-size: ${FontSize.Small};
    margin: 0 10px 0 10px;
    user-select: none;
  }

  svg:hover {
    cursor: pointer;
  }
`;

export const DateFilterArea = styled.div`
  display: flex;
`;

export const FilterBotton = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  width: 100%;
`;
