import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type EditUserPermissionMutationVariables = Types.Exact<{
  data: Types.EditUserPermissionInput;
}>;


export type EditUserPermissionMutation = { __typename?: 'Mutation', editUserPermission: string };


export const EditUserPermissionDocument = gql`
    mutation EditUserPermission($data: EditUserPermissionInput!) {
  editUserPermission(data: $data)
}
    `;
export type EditUserPermissionMutationFn = Apollo.MutationFunction<EditUserPermissionMutation, EditUserPermissionMutationVariables>;
export type EditUserPermissionMutationResult = Apollo.MutationResult<EditUserPermissionMutation>;
export type EditUserPermissionMutationOptions = Apollo.BaseMutationOptions<EditUserPermissionMutation, EditUserPermissionMutationVariables>;