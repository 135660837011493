import * as Types from '../base-schema';

import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';
export type SetActionPlanCanceledMutationVariables = Types.Exact<{
  comment: Types.Scalars['String']['input'];
  actionPlanId: Types.Scalars['String']['input'];
}>;

export type SetActionPlanCanceledMutation = {
  __typename?: 'Mutation';
  setActionPlanCanceled: string;
};

export const SetActionPlanCanceledDocument = gql`
  mutation SetActionPlanCanceled($comment: String!, $actionPlanId: String!) {
    setActionPlanCanceled(comment: $comment, actionPlanId: $actionPlanId)
  }
`;

export type SetActionPlanCanceledMutationFn = Apollo.MutationFunction<
  SetActionPlanCanceledMutation,
  SetActionPlanCanceledMutationVariables
>;

export type SetActionPlanCanceledMutationResult =
  Apollo.MutationResult<SetActionPlanCanceledMutation>;

export type SetActionPlanCanceledMutationOptions = Apollo.BaseMutationOptions<
  SetActionPlanCanceledMutation,
  SetActionPlanCanceledMutationVariables
>;
