import styled from 'styled-components';
import { Color } from '../../../../Styles/Styles';

// TODO: Ajustar estilos

export const DivContainerGeneralTemplate = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${Color.White};
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: auto;
`;

export const DivContentTemplate = styled.div`
  width: 90%;
`;

export const DivHeader = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  align-items: center;
  gap: 15px;
`;

export const DivBaseInfo = styled.div`
  width: 100%;
`;

export const DivCards = styled.div`
  width: 100%;
`;

export const DivContent = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  align-items: center;
  padding: 15px 0;
  box-sizing: border-box;
`;

export const DivHistory = styled.div`
  width: 100%;
  height: 230px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
`;

export const DivMap = styled.div`
  width: 100%;
  height: 100%;
  min-height: 600px;
  max-height: 600px;
  padding: 15px 0;
  box-sizing: border-box;
`;

export const DivContainerFooterData = styled.div`
  width: 100%;
`;

export const Div1 = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  align-items: center;
  gap: 15px;
`;
