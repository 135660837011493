import styled from 'styled-components';

export const HolderFormAto = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 25px;
  box-sizing: border-box;
`;

export const DivInput = styled.div`
  width: min(100%, 230px);
  min-height: 60px;
`;

export const DivDoc = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const HolderTable = styled.div`
  width: 330px;
  overflow: auto;
  max-height: 170px;
`;

export const HolderFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  padding: 20px 0;
`;
