import { useMutation } from '@apollo/client';
import {
  DeleteSubscribedUserNotificationDocument,
  DeleteSubscribedUserNotificationMutation,
  DeleteSubscribedUserNotificationMutationVariables
} from '../graphql/generated/deleteSubscribedUserNotification';
import { ListSubscribedUserNotificationDocument } from '../graphql/query/generated/listSubscribedUserNotification';

export function useDeleteSubscribedUserNotification(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [deleteSubscribedUserNotification, { data, error, loading }] =
    useMutation<
      DeleteSubscribedUserNotificationMutation,
      DeleteSubscribedUserNotificationMutationVariables
    >(DeleteSubscribedUserNotificationDocument, {
      refetchQueries: [ListSubscribedUserNotificationDocument],
      awaitRefetchQueries: true,
      errorPolicy: 'all',
      onCompleted: onCompleted,
      onError: onError
    });

  return {
    deleteSubscribedUserNotification,
    data,
    error,
    loading
  };
}
