import styled from 'styled-components';
import { Color } from '../../../../Styles/Styles';

export const DivContainerFilterHistory = styled.div`
  display: flex;
  gap: 30px;
`;

export const DivContainerDescription = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 30px;
  border-bottom: 1px solid ${Color.Brown1};
`;

export const DivContentDescriptionTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  img {
    width: 125px;
  }
`;

export const DivContainerText = styled.div`
  color: ${Color.Text1};
  word-wrap: break-word;
`;

export const DivContentDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  img {
    width: 125px;
  }
`;

export const DivImages = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
