import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type DeleteInstrumentDocByIdMutationVariables = Types.Exact<{
  deleteInstrumentDocByIdId: Types.Scalars['String']['input'];
}>;

export type DeleteInstrumentDocByIdMutation = {
  __typename?: 'Mutation';
  deleteInstrumentDocById: string;
};

export const DeleteInstrumentDocByIdDocument = gql`
  mutation DeleteInstrumentDocById($deleteInstrumentDocByIdId: String!) {
    deleteInstrumentDocById(id: $deleteInstrumentDocByIdId)
  }
`;

export type DeleteInstrumentDocByIdMutationFn = Apollo.MutationFunction<
  DeleteInstrumentDocByIdMutation,
  DeleteInstrumentDocByIdMutationVariables
>;

export type DeleteInstrumentDocByIdMutationResult =
  Apollo.MutationResult<DeleteInstrumentDocByIdMutation>;

export type DeleteInstrumentDocByIdMutationOptions = Apollo.BaseMutationOptions<
  DeleteInstrumentDocByIdMutation,
  DeleteInstrumentDocByIdMutationVariables
>;
