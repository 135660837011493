import styled, { CSSProperties, keyframes } from 'styled-components';
import { Color, FontSize } from './Styles';

export const UserDiv = styled.div`
  background-color: ${Color.Brown1};
  border-radius: 50%;
  position: absolute;
  right: 20px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  user-select: none;
  cursor: pointer;
  z-index: 2;
`;

export const UserCardDivStyle = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100%;
  gap: 15px;
`;

export const DivHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

export const DivSignatureAndSupport = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 15px;
`;

export const UserCardStyle = {
  borderRadius: '5px',
  backgroundColor: Color.Gray2,
  width: '230px',
  height: '175px',
  top: '20px',
  cursor: 'default',
  padding: '10px',
  boxSizing: 'border-box'
};

export const UserName = styled.span`
  color: ${Color.White};
  font-size: ${FontSize.Xsmall};
  top: 24px;
  left: 60px;
`;

export const MyAccount = styled.span`
  position: absolute;
  color: ${Color.White};
  font-size: ${FontSize.Xsmall};
  top: 30px;
  left: 15px;
  cursor: pointer;
`;

export const UserNotificationStyle: CSSProperties = {
  display: 'flex',
  cursor: 'pointer',
  color: Color.Title2,
  fontSize: '40px'
};

const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: scale(1);
  }
  40% {
    transform: scale(1.1);
  }
  60% {
    transform: scale(0.9);
  }
`;

export const ExclamationStyle = styled.span`
  position: absolute;
  background-color: red;
  border-radius: 50%;
  top: 10px;
  right: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 13px;
  height: 13px;
  animation: ${bounce} 0.5s ease-in infinite;
`;

export const LanguageSelect = styled.div`
  position: absolute;
  bottom: 1px;
  left: 5px;
`;

export const Exit = styled.span`
  position: absolute;
  color: ${Color.White};
  font-size: ${FontSize.Xsmall};
  bottom: 10px;
  right: 15px;
  cursor: pointer;
`;

export const UserDivStyle = styled.div`
  background-color: ${Color.Brown1};
  border-radius: 50%;
  width: 40px;
  min-width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  user-select: none;
  cursor: pointer;
`;

export const DivNotificationCardStyle = styled.div`
  position: absolute;
  border-radius: '5px';
  background-color: ${Color.Gray4};
  top: 0;
  right: -10px;
  left: -120px;
  bottom: -280px;
  z-index: 4;
`;

export const DivNotificationViewAll = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  background-color: ${Color.Gray4};
  color: ${Color.White};
  left: 0;
  transform: translate(45%, 50%);
  bottom: 30px;
  cursor: pointer;
  z-index: 4;
`;

export const ButtonNotification = styled.button`
  text-decoration: underline;
  background-color: transparent;
  border: none;
  color: ${Color.Brown1};
  cursor: pointer;
  font-size: 18px;
  font-weight: 400;
  z-index: 4;
`;
