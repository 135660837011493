import { useMutation, useQuery } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { MdDownload } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { z } from 'zod';
import ToastifyModel from '../../../../Models/ToastifyModel';
import {
  CreateWaterLevelReadingDocument,
  CreateWaterLevelReadingMutation,
  CreateWaterLevelReadingMutationVariables
} from '../../../../data/graphql/generated/createWaterLevelReading.mutation';
import { ListReadingByWaterLevelPagDocument } from '../../../../data/graphql/query/generated/listReadingByWaterLevelPag.query';
import {
  ListWaterLevelByStructureDocument,
  ListWaterLevelByStructureQuery,
  ListWaterLevelByStructureQueryVariables
} from '../../../../data/graphql/query/generated/listWaterLevelByStructure.query';
import { ONE_MEGABYTE } from '../../../../utils/const';
import { toastfyError, toastfySuccess } from '../../../Toastify';
import Button from '../../Atoms/Button/Button';
import { FileType } from '../../Molecules/FileViewer/FileViewer.interfaces';
import InputSelectSearch from '../../Molecules/InputSelectSearch/InputSelectSearch';
import InputFileWithList from '../InputFileWithList/InputFileWithList';
import {
  BathReadingsValues,
  CSVRow,
  DataItem,
  RegisterWaterLevelBathReadingsProps
} from './RegisterWaterLevelBathReadings.interface';
import { DivButtons } from './RegisterWaterLevelBathReadings.styles';

const RegisterWaterLevelBathReadings = ({
  template,
  onClose
}: RegisterWaterLevelBathReadingsProps) => {
  const { structureId } = useParams();
  const structureInfo = {
    structureId: structureId || '',
    associatedStructureId: null
  };
  const { t: translate } = useTranslation();
  const bathReadingsSchema = z.object({
    instruments: z
      .object({
        value: z.string(),
        label: z.string()
      })
      .nullable()
      .refine((val) => val !== null, {
        message: translate('fieldIsRequired')
      }),
    files: z
      .array(z.instanceof(File), { message: 'fieldIsRequired' })
      .refine((val) => val.every((file) => file.size <= 5 * ONE_MEGABYTE), {
        message: 'veryBigFile'
      })
  });

  type bathReadingsSchemaType = z.infer<typeof bathReadingsSchema>;

  const {
    control,
    handleSubmit,
    register,
    setValue,
    formState: { errors }
  } = useForm<bathReadingsSchemaType>({
    resolver: zodResolver(bathReadingsSchema)
  });

  const definedHeaders = ['date', 'observation', 'value'];

  const [uploadedData, setUploadedData] = useState<any | null>(null);
  const [files, setFiles] = useState<FileType[]>([]);
  const [createReading] = useMutation<
    CreateWaterLevelReadingMutation,
    CreateWaterLevelReadingMutationVariables
  >(CreateWaterLevelReadingDocument);

  const { data: listWaterLevels } = useQuery<
    ListWaterLevelByStructureQuery,
    ListWaterLevelByStructureQueryVariables
  >(ListWaterLevelByStructureDocument, {
    variables: {
      structureInfo
    }
  });

  const instrumentsData = listWaterLevels?.listWaterLevelByStructure.map(
    (item) => ({
      label: item.name || '',
      value: item.id || ''
    })
  );

  async function handleSaveWaterLevel(
    bathReadingsFormData: bathReadingsSchemaType
  ) {
    const hasNonEmptyValues = (item: DataItem): boolean =>
      Object.values(item).some((value) => value?.length > 0);

    const truncateDate = (dateStr: string | undefined): string | undefined =>
      dateStr && dateStr.length === 29 ? dateStr.substring(0, 19) : dateStr;

    const filteredData = uploadedData.filter(hasNonEmptyValues);

    const preparedData = filteredData.map((item: BathReadingsValues) => ({
      ...item,
      date: truncateDate(item.date)
    }));

    const readingsData = preparedData.map((item: DataItem) => ({
      date: item.date.replace(/^["\\]|["\\]$/g, ''),
      instrumentId: bathReadingsFormData.instruments?.value ?? null,
      instrumentName: bathReadingsFormData.instruments?.label ?? '',
      observation: item.observation,
      value:
        item.value === null || isNaN(item.value) ? 0 : parseFloat(item.value)
    }));

    createReading({
      variables: {
        structureInfo,
        data: readingsData
      },
      onCompleted: (response) => {
        if (!response.createWaterLevelReading[0]?.error) {
          toastfySuccess(translate('successRegisterReading'));
          onClose(false);
        } else {
          toastfyError(
            translate(response.createWaterLevelReading[0]?.error || '')
          );
          onClose(false);
        }
      },
      onError: ({ message }) => {
        if (message) return toastfyError(message);
      },
      refetchQueries: [ListReadingByWaterLevelPagDocument],
      awaitRefetchQueries: true
    });
  }

  const handleDownload = async (file: string) => {
    try {
      const fileUrl = `/Excel/${file}.csv`;
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const fileName = `${file}.csv`;
      const link = document.createElement('a');

      link.href = URL.createObjectURL(blob);
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      toastfyError(ToastifyModel().toastifyMessage.error);
    }
  };

  const handleFileChange = (files: File[]) => {
    const file = files[0];
    const newFiles = Array.from(files).map((file) => ({
      id: '',
      name: file.name,
      url: URL.createObjectURL(file),
      file: file
    }));
    setFiles((prev) => [...prev, ...newFiles]);
    setValue('files', newFiles.map((file) => file.file) as File[]);

    const reader = new FileReader();

    reader.onload = (event: ProgressEvent<FileReader>) =>
      processCSV(event.target?.result as string);
    reader.onerror = () => toastfyError(ToastifyModel().toastifyMessage.error);
    reader.readAsText(file);
  };

  const processCSV = (csvContent: string) => {
    const rows = csvContent
      .split('\n')
      .map((row) => row.trim())
      .filter(Boolean);
    const headers = parseHeaders(rows[0]);

    if (!validateHeaders(headers)) {
      toastfyError(ToastifyModel().toastifyMessage.error);
      return;
    }

    const data = rows
      .slice(1)
      .map((row) => parseRow(row, headers))
      .filter(Boolean);
    setUploadedData(data as CSVRow[]);
  };

  const parseHeaders = (headerRow: string): string[] =>
    headerRow.split(';').map((header) => header.trim().toLowerCase());

  const validateHeaders = (headers: string[]): boolean =>
    definedHeaders.every((requiredHeader) => headers.includes(requiredHeader));

  const parseRow = (row: string, headers: string[]): CSVRow | null => {
    const values = row.split(';').map((value) => value.trim());
    const rowObj: CSVRow = {};

    headers.forEach((header, index) => {
      if (definedHeaders.includes(header)) {
        rowObj[header] = values[index] || '';
      }
    });

    return Object.keys(rowObj).length > 0 ? rowObj : null;
  };

  const handleDeleteImage = (fileSelect: FileType) => {
    const updatedFiles: FileType[] = files.filter(
      (file) => file.file !== fileSelect.file
    );
    setFiles(updatedFiles);

    setValue(
      'files',
      updatedFiles.map((file) => file.file!)
    );
  };

  return (
    <>
      <InputSelectSearch
        errorMessage={errors.instruments?.message}
        label={translate('Instruments')}
        name="instruments"
        options={instrumentsData ?? []}
        width="300px"
        error={!!errors.instruments}
        control={control}
      />
      <Button
        variant={'secondary'}
        icon={MdDownload}
        text={`${translate('download')} ${translate('template')}`}
        onClick={() => handleDownload(template)}
      />
      <InputFileWithList
        register={register}
        name="files"
        errorMessage={errors.files?.message}
        error={!!errors.files}
        label=""
        multiple={false}
        files={files}
        onChange={(event) => handleFileChange(event.target.files as any)}
        onDelete={(file: FileType) => handleDeleteImage(file)}
        accept={'.csv'}
      />
      <DivButtons>
        <Button
          variant={'secondary'}
          text={translate('cancel')}
          size="small"
          onClick={() => onClose(false)}
        />
        <Button
          variant={'primary'}
          text={translate('send')}
          size="small"
          onClick={handleSubmit(handleSaveWaterLevel)}
        />
      </DivButtons>
    </>
  );
};

export default RegisterWaterLevelBathReadings;
