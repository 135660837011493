import { styled } from 'styled-components';
import { Color } from '../../../../Styles/Styles';

export const DivContainerGroupText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const SpanGroupText = styled.span`
  color: ${Color.Brown1};
  font-weight: bold;
`;

export const SpanGroupTextValue = styled.span`
  color: ${Color.Text1};
`;

export const DivContainerSpanCard = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  padding: 10px 0 0 0;
  box-sizing: border-box;
`;
