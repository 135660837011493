import { StylesConfig } from 'react-select';
import styled from 'styled-components';
import { Color } from './Styles';

export const StructuresDiv = styled.div`
  position: absolute;
  right: 80px;
`;

export const StyleStructureSelect: StylesConfig = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: 'none',
    boxSizing: 'border-box',
    boxShadow: 'none',
    padding: 'none',
    fontSize: '11pt',
    cursor: 'pointer',
    userSelect: 'none'
  }),

  indicatorSeparator: () => ({}), // disable indicator

  placeholder: (provided) => ({
    ...provided,
    color: Color.Gray1
  }),

  input: (provider) => ({
    ...provider,
    color: Color.Gray1,
    backgroundColor: 'transparent',
    textShadow: 'none',
    height: '20px',
    ':hover': {
      border: '1px solid transparent'
    }
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: Color.Gray1,
    ':hover': {
      color: Color.Gray1
    }
  }),
  noOptionsMessage: () => ({
    color: Color.Disabled,
    fontSize: '10pt',
    marginLeft: '10px',
    marginTop: '-5px',
    backgroundColor: Color.backgroundColor
  }),

  option: (provided) => ({
    ...provided,
    color: Color.White,
    fontSize: '10pt',
    backgroundColor: Color.backgroundColor,
    cursor: 'pointer',
    marginTop: '-5px',
    ':hover': {
      backgroundColor: Color.Gray2
    }
  }),
  singleValue: (provided) => ({
    ...provided,
    color: Color.Gray1
  }),
  menu: (provided) => ({
    ...provided,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    zIndex: 3
  })
};
