import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type DeleteSectionMutationVariables = Types.Exact<{
  sectionId: Types.Scalars['String']['input'];
}>;


export type DeleteSectionMutation = { __typename?: 'Mutation', deleteSection: string };


export const DeleteSectionDocument = gql`
    mutation DeleteSection($sectionId: String!) {
  deleteSection(sectionId: $sectionId)
}
    `;
export type DeleteSectionMutationFn = Apollo.MutationFunction<DeleteSectionMutation, DeleteSectionMutationVariables>;
export type DeleteSectionMutationResult = Apollo.MutationResult<DeleteSectionMutation>;
export type DeleteSectionMutationOptions = Apollo.BaseMutationOptions<DeleteSectionMutation, DeleteSectionMutationVariables>;