import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type CreatePiezometerMutationVariables = Types.Exact<{
  structureInfo: Types.StructureInfoInput;
  alertLevels: Array<Types.CreatePiezometerAlertLevelInput> | Types.CreatePiezometerAlertLevelInput;
  generalInfo: Types.CreateInstrumentWithSectionInput;
  specificInfo: Types.CreatePiezometerInput;
}>;


export type CreatePiezometerMutation = { __typename?: 'Mutation', createPiezometer: string };


export const CreatePiezometerDocument = gql`
    mutation CreatePiezometer($structureInfo: StructureInfoInput!, $alertLevels: [CreatePiezometerAlertLevelInput!]!, $generalInfo: CreateInstrumentWithSectionInput!, $specificInfo: CreatePiezometerInput!) {
  createPiezometer(
    structureInfo: $structureInfo
    alertLevels: $alertLevels
    generalInfo: $generalInfo
    specificInfo: $specificInfo
  )
}
    `;
export type CreatePiezometerMutationFn = Apollo.MutationFunction<CreatePiezometerMutation, CreatePiezometerMutationVariables>;
export type CreatePiezometerMutationResult = Apollo.MutationResult<CreatePiezometerMutation>;
export type CreatePiezometerMutationOptions = Apollo.BaseMutationOptions<CreatePiezometerMutation, CreatePiezometerMutationVariables>;