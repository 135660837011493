import styled from 'styled-components';
import { Color } from '../../../../Styles/Styles';

export const CardContainer = styled.div`
  width: 100%;
  border: 1px solid ${Color.Brown3};
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
`;

export const HolderTitleHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
  padding: 15px;
  border: 1px solid ${Color.Brown3};
`;
export const HolderImageHeaderLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  padding: 15px;
  border: 1px solid ${Color.Brown3};
`;
export const HolderImageHeaderRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
  padding: 15px;
  border: 1px solid ${Color.Brown3};
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
`;
