import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type AddPiezometerInaAlertLevelsMutationVariables = Types.Exact<{
  structureId: Types.Scalars['String']['input'];
  data: Array<Types.CreatePiezometerInaAlertLevelsInput> | Types.CreatePiezometerInaAlertLevelsInput;
}>;


export type AddPiezometerInaAlertLevelsMutation = { __typename?: 'Mutation', addPiezometerInaAlertLevels: string };


export const AddPiezometerInaAlertLevelsDocument = gql`
    mutation AddPiezometerInaAlertLevels($structureId: String!, $data: [CreatePiezometerInaAlertLevelsInput!]!) {
  addPiezometerInaAlertLevels(structureId: $structureId, data: $data)
}
    `;
export type AddPiezometerInaAlertLevelsMutationFn = Apollo.MutationFunction<AddPiezometerInaAlertLevelsMutation, AddPiezometerInaAlertLevelsMutationVariables>;
export type AddPiezometerInaAlertLevelsMutationResult = Apollo.MutationResult<AddPiezometerInaAlertLevelsMutation>;
export type AddPiezometerInaAlertLevelsMutationOptions = Apollo.BaseMutationOptions<AddPiezometerInaAlertLevelsMutation, AddPiezometerInaAlertLevelsMutationVariables>;