import styled, { CSSProperties } from 'styled-components';
import { Color, FontSize } from '../../../../../Styles/Styles';

export const GrapHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px;
  font-size: ${FontSize.Medium};
  color: ${Color.Brown2};
  border-bottom: 1px solid ${Color.Brown2};
  & h3 {
    color: ${Color.Brown1};
  }

  & span {
    position: absolute;
    top: 0px;
    right: 25px;
  }
`;

export const CloseIconStyle: CSSProperties = {
  cursor: 'pointer',
  color: Color.Brown1
};
