import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type UpdateRecordEquipmentAndLaborMutationVariables = Types.Exact<{
  data: Types.UpdateEquipmentLaborInput;
}>;


export type UpdateRecordEquipmentAndLaborMutation = { __typename?: 'Mutation', updateRecordEquipmentAndLabor: string };


export const UpdateRecordEquipmentAndLaborDocument = gql`
    mutation UpdateRecordEquipmentAndLabor($data: UpdateEquipmentLaborInput!) {
  updateRecordEquipmentAndLabor(data: $data)
}
    `;
export type UpdateRecordEquipmentAndLaborMutationFn = Apollo.MutationFunction<UpdateRecordEquipmentAndLaborMutation, UpdateRecordEquipmentAndLaborMutationVariables>;
export type UpdateRecordEquipmentAndLaborMutationResult = Apollo.MutationResult<UpdateRecordEquipmentAndLaborMutation>;
export type UpdateRecordEquipmentAndLaborMutationOptions = Apollo.BaseMutationOptions<UpdateRecordEquipmentAndLaborMutation, UpdateRecordEquipmentAndLaborMutationVariables>;