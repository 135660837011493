import styled from 'styled-components';
export const MainScreenSkeleton = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  background-color: white;
  padding: 15px 30px;
  box-sizing: border-box;
`;

export const TitleSkeleton = styled.div`
  width: 300px;
  height: 40px;
  margin-bottom: 20px;
`;
export const PageContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 30px;
  padding: 20px 20%;
  box-sizing: border-box;
`;
export const TableSkeleton = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

export const RowSkeleton = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const CellSkeleton = styled.div`
  width: 100%;
  height: 30px;
`;

export const AddButtonSkeleton = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  width: 100px;
  height: 20px;
  margin: 10px 0;
`;
