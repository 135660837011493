import styled from 'styled-components';
import { Color } from '../../Styles/Styles';

export const MonitoringContainer = styled.div`
  flex: 1;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  background-color: ${Color.White};
`;

export const DivContainerTable = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;

export const DivTable = styled.div`
  width: 100%;
  height: 100%;
`;
