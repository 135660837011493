import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { BsCheckLg } from 'react-icons/bs';
import { PiAsteriskBold } from 'react-icons/pi';
import { NumericFormat } from 'react-number-format';
import { RegisterNumericStyle } from '../../../../../Components/Instruments/Register/RegisterInstrumentStyle';
import {
  AdminInput,
  ContainerGeneralData,
  IconRequired,
  InputArea,
  PartData,
  RequiredInput
} from '../../../../../Screens/AdminScreen/AdminScreenStyle';
import { StepFive } from '../StructuresInterface';

export function StructureStepFive({
  structureStepFiveData,
  setStructureStepFiveData,
  error
}: {
  structureStepFiveData: StepFive;
  setStructureStepFiveData: Dispatch<SetStateAction<StepFive>>;
  error: boolean;
}) {
  const { t } = useTranslation();

  return (
    <InputArea>
      <ContainerGeneralData>
        <PartData>
          <RequiredInput>
            <NumericFormat
              maxLength={30}
              onChange={(e: any) => {
                setStructureStepFiveData((prev) => ({
                  ...prev,
                  crestElevation: Number(e.target.value.replace(/,/g, ''))
                }));
              }}
              style={{
                ...RegisterNumericStyle,
                width: '50%',
                padding: '10px 0',
                borderColor:
                  error && !structureStepFiveData.crestElevation ? 'red' : '',
                background:
                  error && !structureStepFiveData.crestElevation
                    ? '#ffeded'
                    : ''
              }}
              placeholder={`${t('CrestRise')}(m)`}
              // thousandSeparator
              value={
                structureStepFiveData.crestElevation === 0
                  ? ''
                  : structureStepFiveData.crestElevation
              }
            />
            {structureStepFiveData.crestElevation === 0 ? (
              <PiAsteriskBold style={IconRequired} />
            ) : (
              <BsCheckLg style={IconRequired} />
            )}
          </RequiredInput>
          <RequiredInput>
            <NumericFormat
              maxLength={30}
              onChange={(e: any) => {
                setStructureStepFiveData((prev) => ({
                  ...prev,
                  crestWidth: Number(e.target.value.replace(/,/g, ''))
                }));
              }}
              style={{
                ...RegisterNumericStyle,
                width: '50%',
                padding: '10px 0',
                borderColor:
                  error && !structureStepFiveData.crestWidth ? 'red' : '',
                background:
                  error && !structureStepFiveData.crestWidth ? '#ffeded' : ''
              }}
              placeholder={`${t('CrestWidth')}(m)`}
              // thousandSeparator
              value={
                structureStepFiveData.crestWidth === 0
                  ? ''
                  : structureStepFiveData.crestWidth
              }
            />
            {structureStepFiveData.crestWidth === 0 ? (
              <PiAsteriskBold style={IconRequired} />
            ) : (
              <BsCheckLg style={IconRequired} />
            )}
          </RequiredInput>
          <RequiredInput>
            <NumericFormat
              maxLength={30}
              onChange={(e: any) => {
                setStructureStepFiveData((prev) => ({
                  ...prev,
                  crestLength: Number(e.target.value.replace(/,/g, ''))
                }));
              }}
              style={{
                ...RegisterNumericStyle,
                width: '50%',
                padding: '10px 0',
                borderColor:
                  error && !structureStepFiveData.crestLength ? 'red' : '',
                background:
                  error && !structureStepFiveData.crestLength ? '#ffeded' : ''
              }}
              placeholder={`${t('CrestLength')}(m)`}
              // thousandSeparator
              value={
                structureStepFiveData.crestLength === 0
                  ? ''
                  : structureStepFiveData.crestLength
              }
            />
            {structureStepFiveData.crestLength === 0 ? (
              <PiAsteriskBold style={IconRequired} />
            ) : (
              <BsCheckLg style={IconRequired} />
            )}
          </RequiredInput>
          <RequiredInput>
            <AdminInput
              value={structureStepFiveData.upstreamSlopeInclination}
              onChange={(e) => {
                setStructureStepFiveData((prev) => ({
                  ...prev,
                  upstreamSlopeInclination: e.target.value
                }));
              }}
              style={
                error && !structureStepFiveData.upstreamSlopeInclination
                  ? { borderColor: 'red', background: '#ffeded' }
                  : {}
              }
              placeholder={`${t('UpstreamSlope')}(m)`}
            />
            {structureStepFiveData.upstreamSlopeInclination.length < 3 ? (
              <PiAsteriskBold style={IconRequired} />
            ) : (
              <BsCheckLg style={IconRequired} />
            )}
          </RequiredInput>
        </PartData>
        <PartData>
          <RequiredInput>
            <NumericFormat
              maxLength={30}
              onChange={(e: any) => {
                setStructureStepFiveData((prev) => ({
                  ...prev,
                  bermLength: Number(e.target.value.replace(/,/g, ''))
                }));
              }}
              style={{
                ...RegisterNumericStyle,
                width: '50%',
                padding: '10px 0',
                borderColor:
                  error && !structureStepFiveData.bermLength ? 'red' : '',
                background:
                  error && !structureStepFiveData.bermLength ? '#ffeded' : ''
              }}
              placeholder={`${t('BermWidth')}(m)`}
              // thousandSeparator
              value={
                structureStepFiveData.bermLength === 0
                  ? ''
                  : structureStepFiveData.bermLength
              }
            />
            {structureStepFiveData.bermLength === 0 ? (
              <PiAsteriskBold style={IconRequired} />
            ) : (
              <BsCheckLg style={IconRequired} />
            )}
          </RequiredInput>
          <RequiredInput>
            <AdminInput
              value={structureStepFiveData.slopeBetweenBerms}
              onChange={(e) => {
                setStructureStepFiveData((prev) => ({
                  ...prev,
                  slopeBetweenBerms: e.target.value
                }));
              }}
              style={
                error && !structureStepFiveData.slopeBetweenBerms
                  ? { borderColor: 'red', background: '#ffeded' }
                  : {}
              }
              placeholder={t('SlopeBetweenBerms')}
            />
            {structureStepFiveData.slopeBetweenBerms.length < 3 ? (
              <PiAsteriskBold style={IconRequired} />
            ) : (
              <BsCheckLg style={IconRequired} />
            )}
          </RequiredInput>
          <RequiredInput>
            <AdminInput
              value={structureStepFiveData.globalSlopeDownstream}
              onChange={(e) => {
                setStructureStepFiveData((prev) => ({
                  ...prev,
                  globalSlopeDownstream: e.target.value
                }));
              }}
              style={
                error && !structureStepFiveData.globalSlopeDownstream
                  ? { borderColor: 'red', background: '#ffeded' }
                  : {}
              }
              placeholder={t('GlobalDownstreamSlope')}
            />
            {structureStepFiveData.globalSlopeDownstream.length < 3 ? (
              <PiAsteriskBold style={IconRequired} />
            ) : (
              <BsCheckLg style={IconRequired} />
            )}
          </RequiredInput>
        </PartData>
      </ContainerGeneralData>
    </InputArea>
  );
}
