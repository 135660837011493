import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ListReadingBySurfaceMarkersPagQueryVariables = Types.Exact<{
  structureInfo: Types.StructureInfoInput;
  pageInfo?: Types.InputMaybe<Types.PageInput>;
  sortInfo?: Types.InputMaybe<Array<Types.SortInput> | Types.SortInput>;
  filters?: Types.InputMaybe<Types.FilterInput>;
}>;


export type ListReadingBySurfaceMarkersPagQuery = { __typename?: 'Query', listReadingBySurfaceMarkersPag: { __typename?: 'SurfaceMarkersReadingPaginatedType', count: number, nodes: Array<{ __typename?: 'PaginatedSurfaceMarkersReadingType', structureType?: string | null, readingsType?: Types.InstrumentReadingTypeEnum | null, readingType: string, operationalStatus: Types.InstrumentStatusEnum, observation?: string | null, name: string, installLocation?: string | null, instrumentId: string, coordinateN: number, coordinateE: number, reading: { __typename?: 'BaseSurfaceMarkersReadingType', observation?: string | null, id: string, elevation?: number | null, date: Date, coordinateN?: number | null, coordinateE?: number | null }, displacements: { __typename?: 'Displacements', elevation?: number | null, coordinateN?: number | null, coordinateE?: number | null } }>, pageInfo: { __typename?: 'PageInfo', totalPages: number, page: number, offset: number, limit: number, hasPreviousPage: boolean, hasNextPage: boolean } } };


export const ListReadingBySurfaceMarkersPagDocument = gql`
    query ListReadingBySurfaceMarkersPag($structureInfo: StructureInfoInput!, $pageInfo: PageInput, $sortInfo: [SortInput!], $filters: FilterInput) {
  listReadingBySurfaceMarkersPag(
    structureInfo: $structureInfo
    pageInfo: $pageInfo
    sortInfo: $sortInfo
    filters: $filters
  ) {
    nodes {
      structureType
      readingsType
      readingType
      reading {
        observation
        id
        elevation
        date
        coordinateN
        coordinateE
      }
      operationalStatus
      observation
      name
      installLocation
      instrumentId
      displacements {
        elevation
        coordinateN
        coordinateE
      }
      coordinateN
      coordinateE
    }
    count
    pageInfo {
      totalPages
      page
      offset
      limit
      hasPreviousPage
      hasNextPage
    }
  }
}
    `;
export type ListReadingBySurfaceMarkersPagQueryResult = Apollo.QueryResult<ListReadingBySurfaceMarkersPagQuery, ListReadingBySurfaceMarkersPagQueryVariables>;