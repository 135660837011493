import styled from 'styled-components';
import { Color } from '../../../../Styles/Styles';

export const TableHeaderCellContent = styled.th`
  border-bottom: 1px solid ${Color.Brown3};
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }

  svg {
    color: ${Color.Brown3};
    font-size: 15px;
  }

  svg:hover {
    color: ${Color.Brown1};
    cursor: pointer;
    transition: ease-in-out 0.3s;
  }
`;
