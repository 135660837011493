import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { DefaultButton } from '../../../Components/Buttons/DefaultButton';
import Toggles from '../../../Components/Buttons/Toggles/Toggles';
import { StyleInstrumentSelect } from '../../../Components/Selects/InstrumentSelect';
import {
  FilterTitle,
  TableHolderFilter,
  TableHolderFilterAndTitle
} from '../../../Components/Tables/TablesStyle';
import {
  AdminContainer,
  AdminFilter,
  AdminInput,
  AdminTitle,
  DivContainer
} from '../../../Screens/AdminScreen/AdminScreenStyle';
import { InstrumentTypeEnum } from '../../../data/graphql/base-schema';
import { useListCompany } from '../../../data/hooks/admin-company/use-list-company';
import { useListSubscribedUserNotification } from '../../../data/hooks/use-list-subscribed-user-notification';
import AdminTable from '../AdminTable';
import NotificationDeleteModal from './NotificationDeleteModal';
import { NotificationsModal } from './NotificationsModal';

function Notifications() {
  const { t }: any = useTranslation();
  const [updateStatus, setUpdateStatus] = useState<boolean>(true);
  const [status, setStatus] = useState<boolean>(false);
  const [edit, setEdit] = useState<{ status: boolean; data: any }>({
    status: false,
    data: {}
  });
  const [showNotificationModal, setShowNotificationModal] =
    useState<boolean>(false);
  const [criticalitiesState, setCriticalitiesState] = useState<string[]>([]);
  const [instrumentType, setInstrumentType] =
    useState<InstrumentTypeEnum | null>(null);
  const [userName, setUserName] = useState<string>('');
  const [deleting, setDeleting] = useState<{ status: boolean; data: any }>({
    status: false,
    data: {}
  });
  const [notificationData, setNotificationData] = useState<any>({});
  const { data: listClient } = useListCompany(true);
  const { data: listSubscribedUserNotification } =
    useListSubscribedUserNotification(criticalitiesState, instrumentType, null);

  const list =
    listSubscribedUserNotification?.listSubscribedUserNotification || [];

  const filteredTableValues = list
    .filter((e) => {
      if (updateStatus) {
        return e.status;
      } else {
        return e;
      }
    })
    .map((item: any) => {
      const filteredItem: any = {};
      filteredItem['Users'] = item.user;
      filteredItem['Instrument'] = item.instrumentType;
      filteredItem['AlertLevel'] = item.criticality;
      filteredItem['id'] = item.id;
      filteredItem['status'] = item.status ? t('active') : t('inactive');
      return filteredItem;
    });

  const companies: { id: string; label: string; value: string }[] | undefined =
    listClient?.listCompanies
      .filter((company: any) => company.status === true)
      .map((company: any) => ({
        id: company.id,
        label: company.name,
        value: company.id
      }));

  const instruments = [
    { label: t('Ina'), id: 'Ina', value: 'Ina' },
    { label: t('Piezometer'), id: 'Piezometer', value: 'Piezometer' },
    { label: t('Pluviometer'), id: 'Pluviometer', value: 'Pluviometer' },
    { label: t('SurfaceMarker'), id: 'SurfaceMarker', value: 'SurfaceMarker' },
    { label: t('WaterLevel'), id: 'WaterLevel', value: 'WaterLevel' }
  ];

  const criticalities = [
    { label: t('Atenção'), id: 'Atenção', value: 'Atenção' },
    { label: t('Alerta'), id: 'Alerta', value: 'Alerta' },
    { label: t('Emergência'), id: 'Emergência', value: 'Emergência' }
  ];

  const handleFilterInstruments = (e) => {
    setInstrumentType(e.id);
  };

  const handleFilterCriticalities = (e) => {
    setCriticalitiesState([e.id.toUpperCase()]);
  };

  const activities = () => {
    setUpdateStatus(!updateStatus);
  };

  return (
    <DivContainer>
      <AdminContainer>
        <AdminTitle>{t('Notifications')}</AdminTitle>
        <TableHolderFilterAndTitle style={{ width: '90%' }}>
          <FilterTitle>{t('Filters')}</FilterTitle>
          <TableHolderFilter>
            <Select
              styles={{
                ...StyleInstrumentSelect,
                container: (provided) => ({
                  ...provided,
                  width: '200px'
                })
              }}
              isSearchable={false}
              placeholder={t('Instruments')}
              hideSelectedOptions
              options={instruments}
              onChange={(e) => {
                handleFilterInstruments(e);
              }}
              noOptionsMessage={() => t('NoOptions')}
              maxMenuHeight={200}
            />
            <Select
              styles={{
                ...StyleInstrumentSelect,
                container: (provided) => ({
                  ...provided,
                  width: '200px'
                })
              }}
              isSearchable={false}
              placeholder={t('AlertLevel')}
              hideSelectedOptions
              options={criticalities}
              onChange={(e) => {
                handleFilterCriticalities(e);
              }}
              noOptionsMessage={() => t('NoOptions')}
              maxMenuHeight={200}
            />
            <AdminInput
              style={{ width: '200px' }}
              value={userName}
              onChange={(e) => {
                setUserName(e.target.value);
              }}
              placeholder={t('User')}
            />
          </TableHolderFilter>
        </TableHolderFilterAndTitle>
        <AdminFilter>
          <div style={{ paddingBottom: '15px' }}>
            <DefaultButton
              title={`+ ${t('Add')}`}
              action={() => setShowNotificationModal(true)}
            />
            {Toggles.ToggleStatus({
              check: status,
              setCheck: setStatus,
              key: 'status',
              text: t('status'),
              updateValue: activities
            })}
          </div>
        </AdminFilter>
        <AdminTable
          tableColumns={['Users', 'Instrument', 'AlertLevel', 'status']}
          tableValues={filteredTableValues.filter((e) =>
            t(e.Users)
              .toLocaleLowerCase()
              .includes(userName.toLocaleLowerCase())
          )}
          edit={edit}
          setEdit={setEdit}
          deleting={deleting}
          setDeleting={setDeleting}
        />
        {showNotificationModal && (
          <NotificationsModal
            setShowModal={setShowNotificationModal}
            showModal={showNotificationModal}
            notificationData={notificationData}
            setNotificationData={setNotificationData}
            companies={companies}
          />
        )}
        {deleting.status && (
          <NotificationDeleteModal
            deleting={deleting}
            setDeleting={setDeleting}
          />
        )}
      </AdminContainer>
    </DivContainer>
  );
}

export default Notifications;
