import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type SaveRecordActivitiesMutationVariables = Types.Exact<{
  description: Types.Scalars['String']['input'];
  recordId: Types.Scalars['String']['input'];
}>;


export type SaveRecordActivitiesMutation = { __typename?: 'Mutation', saveRecordActivities: string };


export const SaveRecordActivitiesDocument = gql`
    mutation SaveRecordActivities($description: String!, $recordId: String!) {
  saveRecordActivities(description: $description, recordId: $recordId)
}
    `;
export type SaveRecordActivitiesMutationFn = Apollo.MutationFunction<SaveRecordActivitiesMutation, SaveRecordActivitiesMutationVariables>;
export type SaveRecordActivitiesMutationResult = Apollo.MutationResult<SaveRecordActivitiesMutation>;
export type SaveRecordActivitiesMutationOptions = Apollo.BaseMutationOptions<SaveRecordActivitiesMutation, SaveRecordActivitiesMutationVariables>;