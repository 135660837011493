import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type EditCompanyMutationVariables = Types.Exact<{
  data: Types.EditCompanyInput;
}>;


export type EditCompanyMutation = { __typename?: 'Mutation', editCompany: string };


export const EditCompanyDocument = gql`
    mutation EditCompany($data: EditCompanyInput!) {
  editCompany(data: $data)
}
    `;
export type EditCompanyMutationFn = Apollo.MutationFunction<EditCompanyMutation, EditCompanyMutationVariables>;
export type EditCompanyMutationResult = Apollo.MutationResult<EditCompanyMutation>;
export type EditCompanyMutationOptions = Apollo.BaseMutationOptions<EditCompanyMutation, EditCompanyMutationVariables>;