import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type CreateStructureMutationVariables = Types.Exact<{
  data: Types.CreateStructureInput;
}>;


export type CreateStructureMutation = { __typename?: 'Mutation', createStructure: string };


export const CreateStructureDocument = gql`
    mutation CreateStructure($data: CreateStructureInput!) {
  createStructure(data: $data)
}
    `;
export type CreateStructureMutationFn = Apollo.MutationFunction<CreateStructureMutation, CreateStructureMutationVariables>;
export type CreateStructureMutationResult = Apollo.MutationResult<CreateStructureMutation>;
export type CreateStructureMutationOptions = Apollo.BaseMutationOptions<CreateStructureMutation, CreateStructureMutationVariables>;