import { useQuery } from '@apollo/client';
import { InstrumentTypeEnum } from '../graphql/base-schema';
import {
  ListSubscribedUserNotificationDocument,
  ListSubscribedUserNotificationQuery,
  ListSubscribedUserNotificationQueryVariables
} from '../graphql/query/generated/listSubscribedUserNotification';

export function useListSubscribedUserNotification(
  criticalities: string[],
  instrumentType: InstrumentTypeEnum | null,
  userName: string,
  onCompleted?: () => void,
  onError?: () => void
) {
  const { data, loading, error } = useQuery<
    ListSubscribedUserNotificationQuery,
    ListSubscribedUserNotificationQueryVariables
  >(ListSubscribedUserNotificationDocument, {
    variables: {
      filters: {
        criticalities: criticalities,
        instrumentType: instrumentType,
        userName: userName
      }
    },
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    data,
    loading,
    error
  };
}
