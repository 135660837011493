import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultButton } from '../../../Components/Buttons/DefaultButton';
import Toggles from '../../../Components/Buttons/Toggles/Toggles';
import {
  toastfyDimiss,
  toastfyError,
  toastfySuccess,
  toastifyLoading
} from '../../../Components/Toastify';
import ToastifyModel from '../../../Models/ToastifyModel';
import {
  AdminContainer,
  AdminFilter,
  AdminSearch,
  AdminTitle,
  DivContainer,
  SearchIcon,
  StyledInput
} from '../../../Screens/AdminScreen/AdminScreenStyle';
import { useListCompany } from '../../../data/hooks/admin-company/use-list-company';
import { AdminDeleteModal } from '../AdminDeleteModal';
import AdminTable from '../AdminTable';
import { ClientData, __initialClientData } from './ClientInterface';
import { ClientModal } from './ClientModal';
import InputFile from '../../../Components/V2/Molecules/InputFile/InputFile';
import { API_URL } from '../../../utils/const';
import axios from 'axios';

function Clients() {
  const { t } = useTranslation();
  const [status, setStatus] = useState<boolean>(false);
  const [search, setSearch] = useState('');
  const {
    data: listClient,
    loading: loadingList,
    error: listClientError
  } = useListCompany(true);
  const [showClientModal, setShowClientModal] = useState<boolean>(false);
  const [edit, setEdit] = useState<{ status: boolean; data: any }>({
    status: false,
    data: {}
  });
  const [clientData, setClientData] = useState<ClientData>(__initialClientData);
  const [tableValues, setTableValues] = useState<
    { status: string; name: string; cnpj: string }[]
  >([]);
  const [tableValuesFiltered, setTableValuesFiltered] = useState<
    { status: string; name: string; cnpj: string }[]
  >([]);
  const [deleting, setDeleting] = useState<{ status: boolean; data: any }>({
    status: false,
    data: {}
  });

  useEffect(() => {
    if (listClientError) {
      toastfyError(t(ToastifyModel().toastifyMessage.error));
      toastfyDimiss('toastLoading');
    } else if (loadingList) {
      toastifyLoading(t(ToastifyModel().toastifyMessage.loading));
    } else if (listClient) {
      const filteredTableValues = listClient.listCompanies.map((item: any) => {
        return Object.keys(item).reduce((acc: any, key: any) => {
          if (key !== '__typename') {
            if (key === 'status') {
              acc[key] = item[key] ? t('active') : t('inactive');
            } else {
              acc[key] = item[key];
            }
          }

          return acc;
        }, {});
      });

      if (!status) {
        setTableValuesFiltered(
          filteredTableValues.filter((item: any) => item.status === t('active'))
        );
      } else {
        setTableValuesFiltered(filteredTableValues);
      }

      setTableValues(filteredTableValues);
      toastfyDimiss('toastLoading');
    }
  }, [listClient]);

  const activities = () => {
    if (status) {
      setTableValuesFiltered(
        tableValues.filter((event) => event.status.includes(t('active')))
      );
      return tableValuesFiltered;
    }

    setTableValuesFiltered(
      tableValues.filter(
        (event) =>
          event.status.includes(t('active')) ||
          event.status.includes(t('inactive'))
      )
    );
    return tableValuesFiltered;
  };

  const handleSaveReadings = async (file: File) => {
    const user = localStorage.getItem('user');
    const token = user ? JSON.parse(user).token || '' : '';

    const formData = new FormData();
    formData.append('reading', file);

    toastifyLoading(`${t('registering')}...`);
    try {
      const response = await axios.post(
        `${API_URL}/cmoc/script/reading`,
        formData,
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            Authorization: token
          }
        }
      );
      if (response.status === 200) {
        toastfyDimiss('toastLoading');
        toastfySuccess(`${t('saving')}`);
      }
    } catch (error) {
      toastfyDimiss('toastLoading');
      toastfyError(t(ToastifyModel().toastifyMessage.error));
    }
  };

  return (
    <DivContainer>
      <AdminContainer>
        <AdminTitle>{t('Clients')}</AdminTitle>
        <AdminFilter>
          <div>
            <DefaultButton
              title={`+ ${t('Add')}`}
              action={() => setShowClientModal(true)}
            />
            {Toggles.ToggleStatus({
              check: status,
              setCheck: setStatus,
              key: 'status',
              text: t('status'),
              updateValue: activities
            })}
          </div>
          <AdminSearch>
            <StyledInput
              type="text"
              id="search"
              value={search}
              onChange={(event) => setSearch(event.target.value)}
            />
            <SearchIcon size={20} />
          </AdminSearch>
        </AdminFilter>
        <AdminTable
          tableColumns={['name', 'cnpj', 'status']}
          tableValues={tableValuesFiltered.filter(
            (event) =>
              event.name
                .toLocaleLowerCase()
                .includes(search.toLocaleLowerCase()) ||
              event.cnpj
                .toLocaleLowerCase()
                .includes(search.toLocaleLowerCase())
          )}
          edit={edit}
          setEdit={setEdit}
          deleting={deleting}
          setDeleting={setDeleting}
        />
        {showClientModal || edit.status ? (
          <ClientModal
            setShowModal={setShowClientModal}
            clientData={clientData}
            setClientData={setClientData}
            edit={edit}
            setEdit={setEdit}
          />
        ) : null}
        {deleting.status ? (
          <AdminDeleteModal
            id={deleting.data.id}
            name={deleting.data.name}
            setShowModal={setShowClientModal}
            setDeleting={setDeleting}
            type="Clients"
            title={t('Clients')}
          />
        ) : null}
      </AdminContainer>
      <InputFile
        accept="*"
        label={`${t('Send')} ${t('readings')} ${t('CMOC')}`}
        name="file"
        onChange={(event) => {
          if (event.target.files && event.target.files.length > 0) {
            handleSaveReadings(event.target.files[0]);
          }
        }}
      />
    </DivContainer>
  );
}

export default Clients;
