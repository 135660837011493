import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiSolidPencil } from 'react-icons/bi';
import { BsCheckLg } from 'react-icons/bs';
import { IoCloseOutline } from 'react-icons/io5';
import { PiAsteriskBold } from 'react-icons/pi';
import { NumericFormat } from 'react-number-format';
import { DatePickerComponent } from '../../../../../Components/DatePicker';
import { RegisterNumericStyle } from '../../../../../Components/Instruments/Register/RegisterInstrumentStyle';
import { TableComponentStyle } from '../../../../../Components/Tables/TablesStyle';
import {
  toastfyDimiss,
  toastfyError,
  toastfySuccess,
  toastifyLoading
} from '../../../../../Components/Toastify';
import ToastifyModel from '../../../../../Models/ToastifyModel';
import {
  ActionsRow,
  ContainerGeneralData,
  IconRequired,
  InputArea,
  PartData,
  RequiredInput
} from '../../../../../Screens/AdminScreen/AdminScreenStyle';
import { CreateStructureTailingVolumeInput } from '../../../../../data/graphql/base-schema';
import { useEditStructureDamTailingVolume } from '../../../../../data/hooks/use-edit-structure-dam-tailing-tolume';
import { TextButtonInspection } from '../../../../Inspection/InspectionsStyle';
import { StepSeven, __initialStepSevenArray } from '../StructuresInterface';

export function StructureStepSeven({
  structureStepSevenData,
  setStructureStepSevenData,
  structureId,
  error
}: {
  structureStepSevenData: StepSeven;
  setStructureStepSevenData: Dispatch<SetStateAction<StepSeven>>;
  structureId: string;
  error: boolean;
}) {
  const { t } = useTranslation();
  const [date, setDate] = useState<Date | null>(null);
  const [tableData, setTableData] = useState<CreateStructureTailingVolumeInput>(
    __initialStepSevenArray
  );
  const [errorTable, setErrorTable] = useState<boolean>(false);
  const [editingTable, setEditingTable] = useState<string | null>(null);
  const { editStructureDamTailingVolume } = useEditStructureDamTailingVolume();

  const addHistory = () => {
    if (!tableData.volume || !tableData.date || !date) {
      toastfyError(t(ToastifyModel().toastifyMessage.fillRequiredFields));
      setErrorTable(true);
      return;
    }
    if (structureStepSevenData.tailingVolumeHistory) {
      if (
        structureStepSevenData.tailingVolumeHistory.some(
          (x) => x.volume === tableData.volume
        )
      )
        return;
      setStructureStepSevenData((prev) => ({
        ...prev,
        tailingVolumeHistory: [
          ...prev.tailingVolumeHistory,
          {
            date: date,
            volume: tableData.volume
          }
        ]
      }));
    } else {
      setStructureStepSevenData((prev) => ({
        ...prev,
        tailingVolumeHistory: [
          {
            date: date,
            volume: tableData.volume
          }
        ]
      }));
    }
    setErrorTable(false);
  };

  const handleEdit = async () => {
    if (!tableData.volume || !tableData.date) {
      toastfyError(t(ToastifyModel().toastifyMessage.fillRequiredFields));
      setErrorTable(true);
      return;
    }

    if (editingTable) {
      const response = await editStructureDamTailingVolume({
        variables: {
          data: {
            date: tableData.date,
            id: editingTable,
            structureId: structureId,
            volume: tableData.volume
          }
        }
      });
      toastifyLoading('Editando...');
      if (response.data) {
        toastfyDimiss('toastLoading');
        toastfySuccess('Editado Com Sucesso');
        toastfyDimiss('toastfySuccess');
      } else if (response.errors) {
        toastfyDimiss('toastLoading');
        toastfyError(
          response.errors[0].message || t(ToastifyModel().toastifyMessage.error)
        );
      }
    }

    const updatedData = structureStepSevenData.tailingVolumeHistory?.map(
      (item) => {
        if (item.id === editingTable) {
          return {
            ...item,
            date: date,
            volume: tableData.volume
          };
        }
        return item;
      }
    );
    setStructureStepSevenData((prev) => ({
      ...prev,
      tailingVolumeHistory: updatedData
    }));
    setEditingTable(null);
  };

  const editStage = (row: any) => {
    const parsedDate =
      typeof row.date === 'string' ? new Date(row.date) : row.date;

    setTableData(() => ({
      date: parsedDate,
      volume: row.volume
    }));
    setDate(parsedDate);
    setEditingTable(row.id);
  };

  const deleteStage = (index: number) => {
    //TODO falta colocar o endpoint de deletar, nao existe no back
    const updatedData = structureStepSevenData.tailingVolumeHistory?.filter(
      (item, i) => i !== index
    );
    setStructureStepSevenData((prev) => ({
      ...prev,
      tailingVolumeHistory: updatedData
    }));
  };

  return (
    <InputArea>
      <ContainerGeneralData>
        <PartData>
          <RequiredInput>
            <NumericFormat
              maxLength={30}
              onChange={(e: any) => {
                setStructureStepSevenData((prev) => ({
                  ...prev,
                  reservoirOccupiedArea: Number(
                    e.target.value.replace(/,/g, '')
                  )
                }));
              }}
              style={{
                ...RegisterNumericStyle,
                width: '50%',
                padding: '10px 0',
                borderColor:
                  error && !structureStepSevenData.reservoirOccupiedArea
                    ? 'red'
                    : '',
                backgroundColor:
                  error && !structureStepSevenData.reservoirOccupiedArea
                    ? '#ffeded'
                    : ''
              }}
              placeholder={`${t('AreaOccupiedReservoir')}(m²)`}
              // thousandSeparator
              value={
                structureStepSevenData.reservoirOccupiedArea === 0
                  ? ''
                  : structureStepSevenData.reservoirOccupiedArea
              }
            />
            {structureStepSevenData.reservoirOccupiedArea === 0 ? (
              <PiAsteriskBold style={IconRequired} />
            ) : (
              <BsCheckLg style={IconRequired} />
            )}
          </RequiredInput>
          <RequiredInput>
            <NumericFormat
              maxLength={30}
              onChange={(e: any) => {
                setStructureStepSevenData((prev) => ({
                  ...prev,
                  reservoirTotalVolume: Number(e.target.value.replace(/,/g, ''))
                }));
              }}
              style={{
                ...RegisterNumericStyle,
                width: '50%',
                padding: '10px 0',
                borderColor:
                  error && !structureStepSevenData.reservoirTotalVolume
                    ? 'red'
                    : '',
                backgroundColor:
                  error && !structureStepSevenData.reservoirTotalVolume
                    ? '#ffeded'
                    : ''
              }}
              placeholder={`${t('TotalReservoirVolume')}(m²)`}
              // thousandSeparator
              value={
                structureStepSevenData.reservoirTotalVolume === 0
                  ? ''
                  : structureStepSevenData.reservoirTotalVolume
              }
            />
            {structureStepSevenData.reservoirTotalVolume === 0 ? (
              <PiAsteriskBold style={IconRequired} />
            ) : (
              <BsCheckLg style={IconRequired} />
            )}
          </RequiredInput>
          <RequiredInput>
            <NumericFormat
              maxLength={30}
              onChange={(e: any) => {
                setStructureStepSevenData((prev) => ({
                  ...prev,
                  waterVolume: Number(e.target.value.replace(/,/g, ''))
                }));
              }}
              style={{
                ...RegisterNumericStyle,
                width: '50%',
                padding: '10px 0',
                borderColor:
                  error && !structureStepSevenData.waterVolume ? 'red' : '',
                backgroundColor:
                  error && !structureStepSevenData.waterVolume ? '#ffeded' : ''
              }}
              placeholder={`${t('WaterLevel')}(m²)`}
              // thousandSeparator
              value={
                structureStepSevenData.waterVolume === 0
                  ? ''
                  : structureStepSevenData.waterVolume
              }
            />
            {structureStepSevenData.waterVolume === 0 ? (
              <PiAsteriskBold style={IconRequired} />
            ) : (
              <BsCheckLg style={IconRequired} />
            )}
          </RequiredInput>
          <RequiredInput>
            <NumericFormat
              maxLength={30}
              onChange={(e: any) => {
                setStructureStepSevenData((prev) => ({
                  ...prev,
                  reservoirOccupiedVolume: Number(
                    e.target.value.replace(/,/g, '')
                  )
                }));
              }}
              style={{
                ...RegisterNumericStyle,
                width: '50%',
                padding: '10px 0',
                borderColor:
                  error && !structureStepSevenData.reservoirOccupiedVolume
                    ? 'red'
                    : '',
                backgroundColor:
                  error && !structureStepSevenData.reservoirOccupiedVolume
                    ? '#ffeded'
                    : ''
              }}
              placeholder={`${t('reservoirOccupiedVolume')}(m²)`}
              // thousandSeparator
              value={
                structureStepSevenData.reservoirOccupiedVolume === 0
                  ? ''
                  : structureStepSevenData.reservoirOccupiedVolume
              }
            />
            {structureStepSevenData.reservoirOccupiedVolume === 0 ? (
              <PiAsteriskBold style={IconRequired} />
            ) : (
              <BsCheckLg style={IconRequired} />
            )}
          </RequiredInput>
        </PartData>
        <PartData>
          <RequiredInput>
            <NumericFormat
              name="volume"
              maxLength={30}
              onChange={(e: any) => {
                setTableData((prev) => ({
                  ...prev,
                  volume: Number(e.target.value.replace(/,/g, ''))
                }));
              }}
              style={{
                ...RegisterNumericStyle,
                width: '50%',
                padding: '10px 0',
                borderColor: errorTable && !tableData.volume ? 'red' : '',
                backgroundColor:
                  errorTable && !tableData.volume ? '#ffeded' : ''
              }}
              placeholder={`${t('volume')}(m³)`}
              // thousandSeparator
              value={tableData.volume === 0 ? '' : tableData.volume}
            />
            {tableData.volume === 0 ? (
              <PiAsteriskBold style={IconRequired} />
            ) : (
              <BsCheckLg style={IconRequired} />
            )}
          </RequiredInput>
          <div>
            {t('Date')}:
            <DatePickerComponent
              startDate={date}
              setStartDate={setDate}
              showTimeSelect={false}
            />
          </div>
          <TextButtonInspection
            style={{ width: '60%' }}
            onClick={
              editingTable !== null ? () => handleEdit() : () => addHistory()
            }
          >
            {editingTable !== null ? t('Update') : t('Add')}
          </TextButtonInspection>
          {structureStepSevenData.tailingVolumeHistory?.length !== 0 && (
            <div style={{ height: 'auto', overflowY: 'auto', width: '60%' }}>
              <TableComponentStyle>
                <thead style={{ position: 'sticky', top: 0 }}>
                  <tr>
                    <th>{t('volume')}</th>
                    <th>{t('date')}</th>
                    <th>{t('actions')}</th>
                  </tr>
                </thead>
                <tbody>
                  {structureStepSevenData.tailingVolumeHistory?.map(
                    (participant, index) => {
                      return (
                        <tr key={index}>
                          <td>{participant.volume} m³</td>
                          <td>
                            {new Date(participant.date).toLocaleDateString(
                              'pt-br'
                            )}
                          </td>
                          <td>
                            <ActionsRow>
                              {participant.id && (
                                <BiSolidPencil
                                  onClick={() => editStage(participant)}
                                />
                              )}
                              <IoCloseOutline
                                style={{ color: 'red' }}
                                onClick={() => deleteStage(index)}
                              />
                            </ActionsRow>
                          </td>
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </TableComponentStyle>
            </div>
          )}
          {structureStepSevenData.tailingVolumeHistory?.length === 0 && (
            <div style={{ height: 'auto', overflowY: 'auto', width: '60%' }}>
              <TableComponentStyle>
                <thead style={{ position: 'sticky', top: 0 }}>
                  <tr>
                    <th>{t('volume')}</th>
                    <th>{t('date')}</th>
                    <th>{t('actions')}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan={5}>Nenhuma etapa criada</td>
                  </tr>
                </tbody>
              </TableComponentStyle>
            </div>
          )}
        </PartData>
      </ContainerGeneralData>
    </InputArea>
  );
}
