import { useTranslation } from 'react-i18next';
import { GroupText } from '../../Molecules/GroupText/GroupText';
import { GeneralPageModalDataProps } from './GeneralPageModalData.interface';
import {
  DivContainerGeneralPageData,
  DivGroupText
} from './GeneralPageModalData.styles';

const GeneralPageModalData = ({
  generalPageData
}: GeneralPageModalDataProps) => {
  const { t: translate } = useTranslation();

  return (
    <DivContainerGeneralPageData>
      {generalPageData &&
        Object.entries(generalPageData).map(([key, value]) => (
          <DivGroupText key={key}>
            <GroupText
              key={key}
              title={translate(key)}
              value={(value as string) ?? ''}
            />
          </DivGroupText>
        ))}
    </DivContainerGeneralPageData>
  );
};

export default GeneralPageModalData;
