import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type DeleteRecordRedAlertPeriodMutationVariables = Types.Exact<{
  deleteRecordRedAlertPeriodId: Types.Scalars['String']['input'];
}>;


export type DeleteRecordRedAlertPeriodMutation = { __typename?: 'Mutation', deleteRecordRedAlertPeriod: string };


export const DeleteRecordRedAlertPeriodDocument = gql`
    mutation DeleteRecordRedAlertPeriod($deleteRecordRedAlertPeriodId: String!) {
  deleteRecordRedAlertPeriod(id: $deleteRecordRedAlertPeriodId)
}
    `;
export type DeleteRecordRedAlertPeriodMutationFn = Apollo.MutationFunction<DeleteRecordRedAlertPeriodMutation, DeleteRecordRedAlertPeriodMutationVariables>;
export type DeleteRecordRedAlertPeriodMutationResult = Apollo.MutationResult<DeleteRecordRedAlertPeriodMutation>;
export type DeleteRecordRedAlertPeriodMutationOptions = Apollo.BaseMutationOptions<DeleteRecordRedAlertPeriodMutation, DeleteRecordRedAlertPeriodMutationVariables>;