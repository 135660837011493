import { TextareaStyled } from './Textarea.styles';
import { TextareaProps } from './Textarea.interfaces';

const Textarea = ({
  error,
  name,
  width,
  height,
  register,
  ...props
}: TextareaProps) => {
  return (
    <TextareaStyled
      error={error}
      width={width}
      height={height}
      {...(register ? register(name) : {})}
      {...props}
    />
  );
};

export default Textarea;
