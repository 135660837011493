import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type UpdateRecordActivitiesMutationVariables = Types.Exact<{
  data: Types.UpdateRecordActivityInput;
}>;


export type UpdateRecordActivitiesMutation = { __typename?: 'Mutation', updateRecordActivities: string };


export const UpdateRecordActivitiesDocument = gql`
    mutation UpdateRecordActivities($data: UpdateRecordActivityInput!) {
  updateRecordActivities(data: $data)
}
    `;
export type UpdateRecordActivitiesMutationFn = Apollo.MutationFunction<UpdateRecordActivitiesMutation, UpdateRecordActivitiesMutationVariables>;
export type UpdateRecordActivitiesMutationResult = Apollo.MutationResult<UpdateRecordActivitiesMutation>;
export type UpdateRecordActivitiesMutationOptions = Apollo.BaseMutationOptions<UpdateRecordActivitiesMutation, UpdateRecordActivitiesMutationVariables>;