import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type SaveGeneralCommentsMutationVariables = Types.Exact<{
  inspectionId: Types.Scalars['String']['input'];
  generalComments: Types.Scalars['String']['input'];
}>;

export type SaveGeneralCommentsMutation = {
  __typename?: 'Mutation';
  generalComments: string;
  inspectionId: string;
};

export const SaveGeneralCommentsDocument = gql`
  mutation SaveGeneralComments(
    $inspectionId: String!
    $generalComments: String!
  ) {
    saveGeneralComments(
      inspectionId: $inspectionId
      generalComments: $generalComments
    )
  }
`;

export type SaveGeneralCommentsMutationFn = Apollo.MutationFunction<
  SaveGeneralCommentsMutation,
  SaveGeneralCommentsMutationVariables
>;

export type SaveGeneralCommentsMutationResult =
  Apollo.MutationResult<SaveGeneralCommentsMutation>;

export type SaveGeneralCommentsMutationOptions = Apollo.BaseMutationOptions<
  SaveGeneralCommentsMutation,
  SaveGeneralCommentsMutationVariables
>;
