import React from 'react';
import { useTranslation } from 'react-i18next';
import { IconBaseProps } from 'react-icons/lib';
import Skeleton from 'react-loading-skeleton';
import { capitalizeFirstLetter } from '../../../../utils/StringManipulattor';
import Icon from '../Icon/Icon';
import { ButtonProps } from './Button.interfaces';
import {
  ButtonDangerStyled,
  ButtonPrimaryStyled,
  ButtonSecondaryStyled,
  ContainerSkeleton
} from './Button.styles';

const setVariant = {
  primary: ButtonPrimaryStyled,
  secondary: ButtonSecondaryStyled,
  danger: ButtonDangerStyled
};

const Button: React.FC<ButtonProps> = ({
  text,
  variant,
  onClick,
  disabled,
  size = 'medium',
  error,
  icon,
  ...props
}) => {
  const { t: translate } = useTranslation();
  const ButtonComponent = setVariant[variant];
  return (
    <ButtonComponent
      error={error}
      onClick={onClick}
      disabled={disabled}
      size={size}
      {...props}
    >
      {text && translate(capitalizeFirstLetter(text))}
      {icon && <Icon Icon={icon} variant={'small'} />}
    </ButtonComponent>
  );
};

export const ButtonPrimaryStyledSkeleton = () => (
  <ContainerSkeleton>
    <Skeleton
      wrapper={ButtonPrimaryStyled}
      style={{ position: 'absolute', height: '50px', width: '150px' }}
    />
  </ContainerSkeleton>
);

export const ButtonSecondaryStyledSkeleton = () => (
  <ContainerSkeleton>
    <Skeleton
      wrapper={ButtonSecondaryStyled}
      style={{ position: 'absolute', height: '50px', width: '150px' }}
    />
  </ContainerSkeleton>
);

export const ButtonDangerStyledSkeleton = () => (
  <ContainerSkeleton>
    <Skeleton
      wrapper={ButtonDangerStyled}
      style={{ position: 'absolute', height: '50px', width: '150px' }}
    />
  </ContainerSkeleton>
);

export default Button;
