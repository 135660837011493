import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type SaveAtoDocsMutationVariables = Types.Exact<{
  docs: Array<Types.AtoDocsInput> | Types.AtoDocsInput;
  atoId: Types.Scalars['String']['input'];
}>;


export type SaveAtoDocsMutation = { __typename?: 'Mutation', saveAtoDocs: Array<{ __typename?: 'AtoDocsIdModelType', name: string, id: string }> };


export const SaveAtoDocsDocument = gql`
    mutation SaveAtoDocs($docs: [AtoDocsInput!]!, $atoId: String!) {
  saveAtoDocs(docs: $docs, atoId: $atoId) {
    name
    id
  }
}
    `;
export type SaveAtoDocsMutationFn = Apollo.MutationFunction<SaveAtoDocsMutation, SaveAtoDocsMutationVariables>;
export type SaveAtoDocsMutationResult = Apollo.MutationResult<SaveAtoDocsMutation>;
export type SaveAtoDocsMutationOptions = Apollo.BaseMutationOptions<SaveAtoDocsMutation, SaveAtoDocsMutationVariables>;