import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type FindDataToChartQueryVariables = Types.Exact<{
  setionId: Types.Scalars['String']['input'];
}>;


export type FindDataToChartQuery = { __typename?: 'Query', findDataToChart: { __typename?: 'DataForSectionChartType', sectionName: string, sectionImage?: string | null, inferiorPointY?: number | null, imageWidth?: number | null, imageHeight?: number | null, piezometers: Array<{ __typename?: 'PiezometerToChartType', id: string, name: string, chartItemType: string, readings: Array<{ __typename?: 'ReadingsToChartType', x?: number | null, y?: number | null, topQuote?: number | null, bottomQuote?: number | null, quota: number }>, alertLevels: Array<{ __typename?: 'AlertLevelToChartType', x?: number | null, y?: number | null, name: string }> }> } };


export const FindDataToChartDocument = gql`
    query FindDataToChart($setionId: String!) {
  findDataToChart(setionId: $setionId) {
    sectionName
    sectionImage
    inferiorPointY
    imageWidth
    imageHeight
    piezometers {
      id
      name
      chartItemType
      readings {
        x
        y
        topQuote
        bottomQuote
        quota
      }
      alertLevels {
        x
        y
        name
      }
    }
  }
}
    `;
export type FindDataToChartQueryResult = Apollo.QueryResult<FindDataToChartQuery, FindDataToChartQueryVariables>;