import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en from './en.json';
import pt_br from './pt_br.json';
import es from './es.json';
import zh from './zh.json';

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'pt_BR',
    resources: {
      pt_BR: {
        translation: pt_br
      },
      en: {
        translation: en
      },
      es: {
        translation: es
      },
      zh: {
        translation: zh
      }
    }
  });
