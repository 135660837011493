import { useQuery } from '@apollo/client';
import {
  GetStructureSummaryDocument,
  GetStructureSummaryQuery,
  GetStructureSummaryQueryVariables
} from '../graphql/query/generated/structureSummary.query';

export function useStructureSummary(
  associatedStructureId: string,
  structureId: string,
  onCompleted?: () => void,
  onError?: () => void
) {
  const { data, loading, error } = useQuery<
    GetStructureSummaryQuery,
    GetStructureSummaryQueryVariables
  >(GetStructureSummaryDocument, {
    variables: {
      structureInfo: {
        associatedStructureId,
        structureId
      }
    },
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    data,
    loading,
    error
  };
}
