import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ListLatestSurfaceMarkerReadingQueryVariables = Types.Exact<{
  instrumentId: Types.Scalars['String']['input'];
}>;


export type ListLatestSurfaceMarkerReadingQuery = { __typename?: 'Query', listLatestSurfaceMarkerReading: { __typename?: 'ListLatestSurfaceMarkerReadingType', alertLevels?: string | null, coordinateE?: number | null, coordinateN?: number | null, elevation?: number | null, date: Date, observation?: string | null, instrumentId: string, name: string, operationalStatus: string, type: string } };


export const ListLatestSurfaceMarkerReadingDocument = gql`
    query ListLatestSurfaceMarkerReading($instrumentId: String!) {
  listLatestSurfaceMarkerReading(instrumentId: $instrumentId) {
    alertLevels
    coordinateE
    coordinateN
    elevation
    date
    observation
    instrumentId
    name
    operationalStatus
    type
  }
}
    `;
export type ListLatestSurfaceMarkerReadingQueryResult = Apollo.QueryResult<ListLatestSurfaceMarkerReadingQuery, ListLatestSurfaceMarkerReadingQueryVariables>;