import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type DeleteRecordImageMutationVariables = Types.Exact<{
  deleteRecordImageId: Types.Scalars['String']['input'];
}>;


export type DeleteRecordImageMutation = { __typename?: 'Mutation', deleteRecordImage: string };


export const DeleteRecordImageDocument = gql`
    mutation DeleteRecordImage($deleteRecordImageId: String!) {
  deleteRecordImage(id: $deleteRecordImageId)
}
    `;
export type DeleteRecordImageMutationFn = Apollo.MutationFunction<DeleteRecordImageMutation, DeleteRecordImageMutationVariables>;
export type DeleteRecordImageMutationResult = Apollo.MutationResult<DeleteRecordImageMutation>;
export type DeleteRecordImageMutationOptions = Apollo.BaseMutationOptions<DeleteRecordImageMutation, DeleteRecordImageMutationVariables>;