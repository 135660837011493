import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsCheckLg } from 'react-icons/bs';
import { PiAsteriskBold } from 'react-icons/pi';
import { NumericFormat } from 'react-number-format';
import Select from 'react-select';
import Toggle from 'react-toggle';
import { DatePickerComponent } from '../../../../../Components/DatePicker';
import { RegisterNumericStyle } from '../../../../../Components/Instruments/Register/RegisterInstrumentStyle';
import { StyleInstrumentSelect } from '../../../../../Components/Selects/InstrumentSelect';
import Text from '../../../../../Components/V2/Atoms/Text/Text';
import {
  AdminInput,
  ContainerGeneralData,
  IconRequired,
  InputArea,
  PartData,
  RequiredInput
} from '../../../../../Screens/AdminScreen/AdminScreenStyle';
import { Color } from '../../../../../Styles/Styles';
import {
  AnmClassificationEnum,
  ClassificationEnum
} from '../../../../../data/graphql/base-schema';
import { StepTwo, datumEnum, operationalStatus } from '../StructuresInterface';

export function StructureStepTwo({
  structureStepTwoData,
  setStructureStepTwoData,
  error
}: {
  structureStepTwoData: StepTwo;
  setStructureStepTwoData: Dispatch<SetStateAction<StepTwo>>;
  error: boolean;
}) {
  const { t } = useTranslation();
  const [coordinates, setCoordinates] = useState<
    { id: string; label: string; value: string }[]
  >([]);
  const [risk, setRisk] = useState<
    { id: string; label: string; value: string }[]
  >([]);
  const [initialdate, setInitialDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  useEffect(() => {
    setStructureStepTwoData((prev) => ({
      ...prev,
      operationStart: initialdate ?? null,
      operationEnd: endDate ?? null
    }));
  }, [initialdate, endDate]);

  useEffect(() => {
    const coordinates = Object.values(AnmClassificationEnum).map((value) => ({
      id: value,
      label: value === 'NA' ? t(value) : value,
      value: value
    }));
    setCoordinates(coordinates);

    const risk = Object.values(ClassificationEnum).map((value) => ({
      id: value,
      label: t(value),
      value: value
    }));
    setRisk(risk);
    if (structureStepTwoData.operationStart) {
      setInitialDate(new Date(structureStepTwoData.operationStart));
    }
    if (structureStepTwoData.operationEnd) {
      setEndDate(new Date(structureStepTwoData.operationEnd));
    }
  }, []);

  return (
    <>
      <InputArea>
        <ContainerGeneralData>
          <PartData>
            <RequiredInput style={{ position: 'relative', width: '50%' }}>
              <Select
                styles={{
                  ...StyleInstrumentSelect,
                  control: (provided) => ({
                    ...provided,
                    border: 'none',
                    borderBottom:
                      error && !structureStepTwoData.datumName
                        ? '1px solid red'
                        : `1px solid ${Color.Brown1}`,
                    borderRadius: 'none',
                    boxSizing: 'border-box',
                    boxShadow: 'none',
                    padding: 'none',
                    fontSize: '11pt',
                    cursor: 'pointer',
                    marginBottom: '13px',
                    userSelect: 'none',
                    background:
                      error && !structureStepTwoData.datumName
                        ? '#ffe6e6'
                        : 'none',
                    ':hover': {
                      borderBottom:
                        error && !structureStepTwoData.datumName
                          ? '1px solid red'
                          : `1px solid ${Color.Brown1}`
                    }
                  })
                }}
                isSearchable={false}
                placeholder={t('CoordinateSystem')}
                hideSelectedOptions
                options={datumEnum}
                onChange={(e: any) => {
                  setStructureStepTwoData((prev) => ({
                    ...prev,
                    datumName: e.id
                  }));
                }}
                noOptionsMessage={() => t('NoOptions')}
                maxMenuHeight={200}
                value={datumEnum.find(
                  (e: any) => e.id === structureStepTwoData.datumName
                )}
              />
            </RequiredInput>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                padding: '15px',
                gap: '10px'
              }}
            >
              <Text type="label">{t('IsUTM')}:</Text>
              <Toggle
                className="custom-toggle"
                checked={structureStepTwoData.isUTM}
                onChange={(e) => {
                  setStructureStepTwoData((prev) => ({
                    ...prev,
                    isUTM: e.target.checked
                  }));
                }}
              />
            </div>
            <RequiredInput>
              <NumericFormat
                maxLength={30}
                onChange={(e) => {
                  const value = Number(e.target.value.replace(/,/g, ''));
                  setStructureStepTwoData((prevData) => ({
                    ...prevData,
                    coordinateE: value
                  }));
                }}
                style={{
                  ...RegisterNumericStyle,
                  width: '50%',
                  padding: '10px 0',
                  borderColor:
                    (error && !structureStepTwoData.coordinateE) ||
                    (error && structureStepTwoData.coordinateE > 90) ||
                    (error && structureStepTwoData.coordinateE < -90)
                      ? 'red'
                      : '',
                  background:
                    (error && !structureStepTwoData.coordinateE) ||
                    (error && structureStepTwoData.coordinateE > 90) ||
                    (error && structureStepTwoData.coordinateE < -90)
                      ? '#ffe6e6'
                      : ''
                }}
                placeholder={`${t('coordinateE')}`}
                // thousandSeparator
                value={
                  structureStepTwoData.coordinateE === 0
                    ? ''
                    : structureStepTwoData.coordinateE
                }
              />
              {structureStepTwoData.coordinateE === 0 ? (
                <PiAsteriskBold style={IconRequired} />
              ) : (
                <BsCheckLg style={IconRequired} />
              )}
            </RequiredInput>
            <RequiredInput>
              <NumericFormat
                maxLength={30}
                onChange={(e) => {
                  const value = Number(e.target.value.replace(/,/g, ''));
                  setStructureStepTwoData((prevData) => ({
                    ...prevData,
                    coordinateN: value
                  }));
                }}
                style={{
                  ...RegisterNumericStyle,
                  width: '50%',
                  padding: '10px 0',
                  borderColor:
                    (error && !structureStepTwoData.coordinateN) ||
                    (error && structureStepTwoData.coordinateN > 180) ||
                    (error && structureStepTwoData.coordinateN < -180)
                      ? 'red'
                      : '',
                  background:
                    (error && !structureStepTwoData.coordinateN) ||
                    (error && structureStepTwoData.coordinateN > 180) ||
                    (error && structureStepTwoData.coordinateN < -180)
                      ? '#ffe6e6'
                      : ''
                }}
                placeholder={`${t('coordinateN')}`}
                // thousandSeparator
                value={
                  structureStepTwoData.coordinateN === 0
                    ? ''
                    : structureStepTwoData.coordinateN
                }
              />
              {structureStepTwoData.coordinateN === 0 ? (
                <PiAsteriskBold style={IconRequired} />
              ) : (
                <BsCheckLg style={IconRequired} />
              )}
            </RequiredInput>

            <RequiredInput>
              <AdminInput
                value={structureStepTwoData.utmZone}
                onChange={(e) => {
                  setStructureStepTwoData((prev) => ({
                    ...prev,
                    utmZone: e.target.value
                  }));
                }}
                style={{
                  ...RegisterNumericStyle,
                  width: '50%',
                  padding: '10px 0',
                  borderColor:
                    error && !structureStepTwoData.utmZone ? 'red' : '',
                  background:
                    error && !structureStepTwoData.utmZone ? '#ffe6e6' : ''
                }}
                placeholder={`${t('Zone')}UTM`}
              />
              {structureStepTwoData.utmZone.length < 2 ? (
                <PiAsteriskBold style={IconRequired} />
              ) : (
                <BsCheckLg style={IconRequired} />
              )}
            </RequiredInput>
            <RequiredInput>
              <NumericFormat
                maxLength={30}
                onChange={(e) => {
                  setStructureStepTwoData((prevData) => ({
                    ...prevData,
                    maxHeight: Number(e.target.value.replace(/,/g, ''))
                  }));
                }}
                style={{
                  ...RegisterNumericStyle,
                  width: '50%',
                  padding: '10px 0'
                  // borderColor: error && !structureStepTwoData.maxHeight ? 'red' : '',
                  // background: error && !structureStepTwoData.maxHeight ? '#ffe6e6' : ""
                }}
                placeholder={`${t('MaximumHeight')}`}
                // thousandSeparator
                value={
                  structureStepTwoData.maxHeight === 0
                    ? ''
                    : structureStepTwoData.maxHeight
                }
              />
              {structureStepTwoData.maxHeight === 0 ? (
                <PiAsteriskBold style={IconRequired} />
              ) : (
                <BsCheckLg style={IconRequired} />
              )}
            </RequiredInput>
          </PartData>
          <PartData>
            <RequiredInput style={{ position: 'relative', width: '50%' }}>
              <Select
                styles={{
                  ...StyleInstrumentSelect,
                  control: (provided) => ({
                    ...provided,
                    border: 'none',
                    borderBottom:
                      error && !structureStepTwoData.potentialDamage
                        ? '1px solid red'
                        : `1px solid ${Color.Brown1}`,
                    borderRadius: 'none',
                    boxSizing: 'border-box',
                    boxShadow: 'none',
                    padding: 'none',
                    fontSize: '11pt',
                    cursor: 'pointer',
                    marginBottom: '13px',
                    userSelect: 'none',
                    background:
                      error && !structureStepTwoData.potentialDamage
                        ? '#ffe6e6'
                        : 'none',
                    ':hover': {
                      borderBottom:
                        error && !structureStepTwoData.potentialDamage
                          ? '1px solid red'
                          : `1px solid ${Color.Brown1}`
                    }
                  })
                }}
                isSearchable={false}
                placeholder={t('AssociatedPotentialHarm')}
                hideSelectedOptions
                options={risk}
                onChange={(e: any) => {
                  setStructureStepTwoData((prev) => ({
                    ...prev,
                    potentialDamage: e.id
                  }));
                }}
                noOptionsMessage={() => t('NoOptions')}
                maxMenuHeight={200}
                value={risk.find(
                  (e: any) => e.id === structureStepTwoData.potentialDamage
                )}
              />
            </RequiredInput>
            <RequiredInput style={{ position: 'relative', width: '50%' }}>
              <Select
                styles={{
                  ...StyleInstrumentSelect,
                  control: (provided) => ({
                    ...provided,
                    border: 'none',
                    borderBottom:
                      error && !structureStepTwoData.riskClassification
                        ? '1px solid red'
                        : `1px solid ${Color.Brown1}`,
                    borderRadius: 'none',
                    boxSizing: 'border-box',
                    boxShadow: 'none',
                    padding: 'none',
                    fontSize: '11pt',
                    cursor: 'pointer',
                    marginBottom: '13px',
                    userSelect: 'none',
                    background:
                      error && !structureStepTwoData.riskClassification
                        ? '#ffe6e6'
                        : 'none',
                    ':hover': {
                      borderBottom:
                        error && !structureStepTwoData.riskClassification
                          ? '1px solid red'
                          : `1px solid ${Color.Brown1}`
                    }
                  })
                }}
                isSearchable={false}
                placeholder={t('RiskRating')}
                hideSelectedOptions
                options={risk}
                onChange={(e: any) => {
                  setStructureStepTwoData((prev) => ({
                    ...prev,
                    riskClassification: e.id
                  }));
                }}
                noOptionsMessage={() => t('NoOptions')}
                maxMenuHeight={200}
                value={risk.find(
                  (e: any) => e.id === structureStepTwoData.riskClassification
                )}
              />
            </RequiredInput>
            <RequiredInput style={{ position: 'relative', width: '50%' }}>
              <Select
                styles={{
                  ...StyleInstrumentSelect,
                  control: (provided) => ({
                    ...provided,
                    border: 'none',
                    borderBottom:
                      error && !structureStepTwoData.ANMClassification
                        ? '1px solid red'
                        : `1px solid ${Color.Brown1}`,
                    borderRadius: 'none',
                    boxSizing: 'border-box',
                    boxShadow: 'none',
                    padding: 'none',
                    fontSize: '11pt',
                    cursor: 'pointer',
                    marginBottom: '13px',
                    userSelect: 'none',
                    background:
                      error && !structureStepTwoData.ANMClassification
                        ? '#ffe6e6'
                        : 'none',
                    ':hover': {
                      borderBottom:
                        error && !structureStepTwoData.ANMClassification
                          ? '1px solid red'
                          : `1px solid ${Color.Brown1}`
                    }
                  })
                }}
                isSearchable={false}
                placeholder={t('ANMClassification')}
                hideSelectedOptions
                options={coordinates}
                onChange={(e: any) => {
                  setStructureStepTwoData((prev) => ({
                    ...prev,
                    ANMClassification: e.id
                  }));
                }}
                noOptionsMessage={() => t('NoOptions')}
                maxMenuHeight={200}
                value={coordinates.find(
                  (e: any) => e.id === structureStepTwoData.ANMClassification
                )}
              />
            </RequiredInput>
            <RequiredInput style={{ position: 'relative', width: '50%' }}>
              <Select
                styles={{
                  ...StyleInstrumentSelect,
                  control: (provided) => ({
                    ...provided,
                    border: 'none',
                    borderBottom:
                      error && !structureStepTwoData.operationalStatus
                        ? '1px solid red'
                        : `1px solid ${Color.Brown1}`,
                    borderRadius: 'none',
                    boxSizing: 'border-box',
                    boxShadow: 'none',
                    padding: 'none',
                    fontSize: '11pt',
                    cursor: 'pointer',
                    marginBottom: '13px',
                    userSelect: 'none',
                    background:
                      error && !structureStepTwoData.operationalStatus
                        ? '#ffe6e6'
                        : 'none',
                    ':hover': {
                      borderBottom:
                        error && !structureStepTwoData.operationalStatus
                          ? '1px solid red'
                          : `1px solid ${Color.Brown1}`
                    }
                  })
                }}
                isSearchable={false}
                placeholder={t('OperationalStatus')}
                hideSelectedOptions
                options={operationalStatus}
                onChange={(e: any) => {
                  setStructureStepTwoData((prev) => ({
                    ...prev,
                    operationalStatus: e.id
                  }));
                }}
                noOptionsMessage={() => t('NoOptions')}
                maxMenuHeight={200}
                value={operationalStatus.find(
                  (e: any) => e.id === structureStepTwoData.operationalStatus
                )}
              />
            </RequiredInput>
            <span
              style={{
                display: 'flex',
                color: Color.Brown1,
                width: '100%',
                justifyContent: 'center',
                padding: '15px 0',
                fontWeight: '800'
              }}
            >
              {t('Operation')}
            </span>
            <div
              style={{
                borderColor:
                  error && !structureStepTwoData.operationStart ? 'red' : '',
                background:
                  error && !structureStepTwoData.operationStart ? '#ffe6e6' : ''
              }}
            >
              <DatePickerComponent
                startDate={initialdate}
                setStartDate={setInitialDate}
                showTimeSelect={false}
                dateText={t('InitialDate')}
              />
            </div>
            <div
              style={{
                borderColor:
                  error && !structureStepTwoData.operationEnd ? 'red' : '',
                background:
                  error && !structureStepTwoData.operationEnd ? '#ffe6e6' : ''
              }}
            >
              <DatePickerComponent
                startDate={endDate}
                setStartDate={setEndDate}
                showTimeSelect={false}
                dateText={t('EndDate')}
              />
            </div>
          </PartData>
        </ContainerGeneralData>
      </InputArea>
    </>
  );
}
