import React from 'react';
import {
  DivContinerPopup,
  DivDontReadings,
  MapPopUpContentDataStyle,
  MapPopUpContentStyle,
  MapPopUpHeaderStyle,
  SpanInstrumentPopUpStyle
} from './MapGoogle.styles';
import { IoIosClose } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import {
  ListLatestReadingByPiezometerDocument,
  ListLatestReadingByPiezometerQuery,
  ListLatestReadingByPiezometerQueryVariables
} from '../../../data/graphql/query/generated/listLatestReadingByPiezometer.query';
import { useQuery } from '@apollo/client';
import { MapGoogleInfoWindowProps } from './MapGoogleInfoWindow';
import Skeleton from 'react-loading-skeleton';
import Icon from '../../V2/Atoms/Icon/Icon';
import Text from '../../V2/Atoms/Text/Text';

export const PiezometerInfoWindow = ({
  instrument,
  setInfoWindowOpen
}: MapGoogleInfoWindowProps) => {
  const { t: translation } = useTranslation();

  const { data: lastInstrumentData, loading } = useQuery<
    ListLatestReadingByPiezometerQuery,
    ListLatestReadingByPiezometerQueryVariables
  >(ListLatestReadingByPiezometerDocument, {
    variables: {
      instrumentId: instrument.id
    },
    fetchPolicy: 'no-cache'
  });

  const instrumentData = [
    {
      label: 'Type',
      value:
        instrument.type === 'Ina'
          ? instrument.type.toLocaleUpperCase()
          : instrument.type
    },
    {
      label: 'Status',
      value: lastInstrumentData?.listLatestReadingByPiezometer.operationalStatus
    },
    {
      label: 'LastReadingDate',
      value:
        lastInstrumentData?.listLatestReadingByPiezometer.date &&
        new Date(
          lastInstrumentData?.listLatestReadingByPiezometer.date
        ).toLocaleDateString()
    },
    {
      label: 'ControlLevel',
      value: lastInstrumentData?.listLatestReadingByPiezometer.alertLevels
    },
    {
      label: 'cote',
      value: lastInstrumentData?.listLatestReadingByPiezometer.cote
    }
  ];

  return (
    <>
      {loading ? (
        <Skeleton count={6} height={35} />
      ) : (
        <DivContinerPopup>
          <MapPopUpHeaderStyle>
            <Text type="h5">{instrument.name}</Text>
            <Icon
              variant={'medium'}
              onClick={() => setInfoWindowOpen(false)}
              Icon={IoIosClose}
            />
          </MapPopUpHeaderStyle>
          <MapPopUpContentStyle>
            {lastInstrumentData ? (
              instrumentData.map(({ label, value }) => (
                <MapPopUpContentDataStyle key={label}>
                  <SpanInstrumentPopUpStyle>
                    <Text type="p" color="black" size="small">
                      {translation(label)}
                    </Text>
                  </SpanInstrumentPopUpStyle>
                  <SpanInstrumentPopUpStyle>
                    <Text type="p" color="black" size="small">
                      {translation(value) || '-'}
                    </Text>
                  </SpanInstrumentPopUpStyle>
                </MapPopUpContentDataStyle>
              ))
            ) : (
              <DivDontReadings>
                <Text type="p">
                  {translation('dontHaveReadingsForThisInstrument')}
                </Text>
              </DivDontReadings>
            )}
          </MapPopUpContentStyle>
        </DivContinerPopup>
      )}
    </>
  );
};
