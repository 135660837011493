import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type EditStructureReservoirHandlingMutationVariables = Types.Exact<{
  data: Types.EditReservoirHandlingInput;
}>;


export type EditStructureReservoirHandlingMutation = { __typename?: 'Mutation', editStructureReservoirHandling: string };


export const EditStructureReservoirHandlingDocument = gql`
    mutation EditStructureReservoirHandling($data: EditReservoirHandlingInput!) {
  editStructureReservoirHandling(data: $data)
}
    `;
export type EditStructureReservoirHandlingMutationFn = Apollo.MutationFunction<EditStructureReservoirHandlingMutation, EditStructureReservoirHandlingMutationVariables>;
export type EditStructureReservoirHandlingMutationResult = Apollo.MutationResult<EditStructureReservoirHandlingMutation>;
export type EditStructureReservoirHandlingMutationOptions = Apollo.BaseMutationOptions<EditStructureReservoirHandlingMutation, EditStructureReservoirHandlingMutationVariables>;