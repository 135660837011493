import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ListReadingBySurfaceMarkersQueryVariables = Types.Exact<{
  endDate: Types.Scalars['DateTime']['input'];
  startDate: Types.Scalars['DateTime']['input'];
  instrumentIds: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
}>;


export type ListReadingBySurfaceMarkersQuery = { __typename?: 'Query', listReadingBySurfaceMarkers: Array<{ __typename?: 'ChartSurfaceMarkerReadingType', instrumentId: string, name: string, elevationData: Array<{ __typename?: 'GenericSurfaceMarkerReadingType', date: Date, value?: number | null }>, displacementElevationData: Array<{ __typename?: 'GenericDisplacementSurfaceDataType', displacement?: number | null, date: Date }> }> };


export const ListReadingBySurfaceMarkersDocument = gql`
    query ListReadingBySurfaceMarkers($endDate: DateTime!, $startDate: DateTime!, $instrumentIds: [String!]!) {
  listReadingBySurfaceMarkers(
    endDate: $endDate
    startDate: $startDate
    instrumentIds: $instrumentIds
  ) {
    instrumentId
    name
    elevationData {
      date
      value
    }
    displacementElevationData {
      displacement
      date
    }
  }
}
    `;
export type ListReadingBySurfaceMarkersQueryResult = Apollo.QueryResult<ListReadingBySurfaceMarkersQuery, ListReadingBySurfaceMarkersQueryVariables>;