import styled from 'styled-components';

export const HolderVideos = styled.div``;

export const HolderPictures = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;
