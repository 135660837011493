import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiSolidPencil } from 'react-icons/bi';
import { IoMdEye } from 'react-icons/io';
import { IoCloseOutline } from 'react-icons/io5';
import { useParams } from 'react-router-dom';
import {
  DeleteRecordVideoDocument,
  DeleteRecordVideoMutation,
  DeleteRecordVideoMutationVariables
} from '../../../../data/graphql/generated/deleteRecordVideo';
import {
  FindDataByRecordDocument,
  FindDataByRecordQuery
} from '../../../../data/graphql/query/generated/findDataByRecord';
import { BUCKET_URL } from '../../../../utils/const';
import ErrorsTreatments from '../../../../utils/errorTreatment';
import { toastfyError, toastfySuccess } from '../../../Toastify';
import FileViewer from '../../Molecules/FileViewer/FileViewer';
import { FileType } from '../../Molecules/FileViewer/FileViewer.interfaces';
import ModalConfirm from '../../Molecules/ModalConfirm/ModalConfirm';
import TableComponent from '../../Molecules/TableComponent/TableComponent';
import ViewModal from '../../Molecules/ViewModal/ViewModal';
import AddVideoAtoForm from '../AddVideoAtoForm/AddVideoAtoForm';
import CardAtoInfo from '../CardInfo/CardInfo';
import {
  CardAtoInfoVideosProps,
  RecordVideoItem,
  RecordVideosType,
  RowToEditType
} from './CardAtoInfoVideos.interface';

export const CardAtoInfoVideos = ({ recordVideos }: CardAtoInfoVideosProps) => {
  const { t } = useTranslation();
  const { recordId } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [updateDelete, setUpdateDelete] = useState(false);
  const [deleteRecordVideo] = useMutation<
    DeleteRecordVideoMutation,
    DeleteRecordVideoMutationVariables
  >(DeleteRecordVideoDocument);
  const [rowToEdit, setRowToEdit] = useState<RowToEditType>();
  const [file, setFile] = useState<FileType | null>(null);
  const [idForDelete, setIdForDelete] = useState<string>('');

  const tableColumns = [
    {
      key: 'indexId',
      label: 'id'
    },
    {
      key: 'video',
      label: t('video')
    }
  ];

  const tableData = recordVideos.map((item: RecordVideosType, index) => ({
    video: item.video,
    id: item.id,
    indexId: index
  }));

  const actions = [
    {
      name: t('View'),
      icon: IoMdEye,
      onClick: (row: RowToEditType) => onTapOpenVideo(row),
      canShow: 'view'
    },
    {
      name: t('Edit'),
      icon: <BiSolidPencil />,
      onClick: (row: RowToEditType) => {
        handleEditRow(row);
      },
      canShow: 'edit'
    },
    {
      name: t('Delete'),
      icon: <IoCloseOutline />,
      onClick: (row: RowToEditType) => {
        setIdForDelete(row.id);
      },
      canShow: 'delete'
    }
  ];

  const handleDeleteRow = (id: string) => {
    deleteRecordVideo({
      variables: {
        deleteRecordVideoId: id
      },
      onCompleted: () => {
        toastfySuccess(t('deletedSuccessfully'));
        setUpdateDelete(!updateDelete);
      },
      onError: ({ graphQLErrors }) => {
        const errorMessage = ErrorsTreatments(graphQLErrors[0].message, t);
        toastfyError(errorMessage);
      },
      update: (cache, { data }) => {
        if (!data) return;
        const existingData = cache.readQuery({
          query: FindDataByRecordDocument,
          variables: {
            recordId: recordId
          }
        }) as FindDataByRecordQuery;

        const updatedData = {
          ...existingData,
          findDataByRecord: {
            ...existingData.findDataByRecord,
            recordVideos: existingData.findDataByRecord.recordVideos.filter(
              (item: RecordVideoItem) => item.id !== id
            )
          }
        };

        cache.writeQuery({
          query: FindDataByRecordDocument,
          variables: {
            recordId: recordId
          },
          data: updatedData
        });
      }
    });
  };

  const handleEditRow = (row: RowToEditType) => {
    setRowToEdit(row);
    setShowEditModal(true);
  };

  const handleAddForm = () => {
    setShowModal(true);
  };

  const onTapOpenVideo = (row: RowToEditType) => {
    setFile({
      id: row.id,
      name: row.video,
      url: `${BUCKET_URL}/${row.video}`
    });
  };

  return (
    <>
      <CardAtoInfo
        title={t('video')}
        buttonLabel={t('Add+')}
        onButtonClick={handleAddForm}
      >
        <TableComponent
          columns={tableColumns}
          tableData={tableData}
          actions={actions}
        />
      </CardAtoInfo>
      <ViewModal
        showModal={showModal}
        width={40}
        height={50}
        title={t('addvideo')}
        onClose={() => setShowModal(false)}
        component={
          <AddVideoAtoForm
            setShowModal={setShowModal}
            recordId={recordId?.toString() || ''}
          />
        }
      />
      <ViewModal
        showModal={showEditModal}
        width={40}
        height={50}
        title={t('editvideo')}
        onClose={() => setShowEditModal(false)}
        component={
          <AddVideoAtoForm
            setShowModal={setShowEditModal}
            recordId={recordId?.toString() || ''}
            editVideo={{
              id: rowToEdit?.id || '',
              description: rowToEdit?.observation || '',
              name: rowToEdit?.video || '',
              url: `${BUCKET_URL}/${rowToEdit?.video}`
            }}
          />
        }
      />
      <ModalConfirm
        showModal={!!idForDelete}
        title={`${t('Delete')} ${t('Activity')}`}
        text={t('ConfirmDelete')}
        subtitle={t('ActionCannotBeUndone')}
        onClose={() => {
          setIdForDelete('');
        }}
        onConfirm={() => {
          handleDeleteRow(idForDelete);
          setIdForDelete('');
        }}
      />
      <FileViewer
        showModal={!!file}
        files={[file as FileType]}
        onClose={() => setFile(null)}
        selectedFile={file}
      />
    </>
  );
};
