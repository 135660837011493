import Skeleton from 'react-loading-skeleton';
import Text from '../../Atoms/Text/Text';
import { FourStepsTemplateProps } from './FourStepsTemplate.interface';
import {
  Container,
  DivButtons,
  DivContent,
  DivHeader,
  DivHolderButtonsHeader,
  DivTitle
} from './FourStepsTemplate.styles';

const FourStepsTemplate = ({
  icon,
  title,
  button,
  contentPictures,
  inputs,
  commentContent,
  buttons,
  loading
}: FourStepsTemplateProps) => {
  return loading ? (
    <Container>
      <DivHeader>
        <DivTitle>
          <Skeleton width="300px" height="40px" />
        </DivTitle>
        <DivHolderButtonsHeader>
          <Skeleton width="150px" height="40px" />
          <Skeleton width="150px" height="40px" />
        </DivHolderButtonsHeader>
      </DivHeader>
      <DivContent>
        <Skeleton width="1320px" height="150px" />
      </DivContent>
      <DivContent>
        <Skeleton width="1320px" height="150px" />
      </DivContent>
      <DivContent>
        <Skeleton width="500px" height="150px" />
      </DivContent>
      <DivButtons>
        <Skeleton width="1320px" height="50px" />
      </DivButtons>
    </Container>
  ) : (
    <Container>
      <DivHeader>
        <DivTitle>
          {icon}
          <Text weight="bold" type="h2">
            {title}
          </Text>
        </DivTitle>
        <div>{button}</div>
      </DivHeader>
      <DivContent>{contentPictures}</DivContent>
      <DivContent>{inputs}</DivContent>
      <DivContent>{commentContent}</DivContent>
      <DivButtons>{buttons}</DivButtons>
    </Container>
  );
};

export default FourStepsTemplate;
