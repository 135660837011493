import { useQuery } from '@apollo/client';
import {
  ListStructuresDocument,
  ListStructuresQuery,
  ListStructuresQueryVariables
} from '../graphql/query/generated/listStructures.query';

export function useListStructures(
  onCompleted?: () => void,
  onError?: () => void
) {
  const { data, loading, error } = useQuery<
    ListStructuresQuery,
    ListStructuresQueryVariables
  >(ListStructuresDocument, {
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    data,
    loading,
    error
  };
}
