export interface NotificationType {
  contentId: string;
  createdAt: string;
  body: string;
  unread: boolean;
}

export enum NotificationTypeEnum {
  InstrumentNotification = 'InstrumentNotification',
  AtoNotification = 'AtoNotification',
  ActionPlanNotification = 'ActionPlanNotification'
}
