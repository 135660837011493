import { useMutation } from '@apollo/client';
import {
  EditPasswordDocument,
  EditPasswordMutation,
  EditPasswordMutationVariables
} from '../graphql/generated/editPassword';

export function useEditPassword(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [editPassword, { data, error, loading }] = useMutation<
    EditPasswordMutation,
    EditPasswordMutationVariables
  >(EditPasswordDocument, {
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    editPassword,
    data,
    error,
    loading
  };
}
