import { useMutation } from '@apollo/client';
import {
  AddStructureDamTailingVolumeDocument,
  AddStructureDamTailingVolumeMutation,
  AddStructureDamTailingVolumeMutationVariables
} from '../graphql/generated/addStructureDamTailingVolume';
import { GetStructureSummaryDocument } from '../graphql/query/generated/structureSummary.query';

export const useAddStructureDamTailingVolume = (
  onCompleted?: () => void,
  onError?: () => void
) => {
  const [addStructureDamTailingVolume, { data, error, loading }] = useMutation<
    AddStructureDamTailingVolumeMutation,
    AddStructureDamTailingVolumeMutationVariables
  >(AddStructureDamTailingVolumeDocument, {
    refetchQueries: [GetStructureSummaryDocument],
    awaitRefetchQueries: true,
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    addStructureDamTailingVolume,
    data,
    error,
    loading
  };
};
