import HeaderMenu from '../../Components/HeaderMenu';
import MainScreenTemplate from '../../Components/V2/Templates/MainScreenTemplate/MainScreenTemplate';
import StructureScreen from '../StructureScreen/StructureScreen';
import { Outlet } from 'react-router-dom';

function AdminScreen() {
  return (
    <MainScreenTemplate
      header={<HeaderMenu />}
      sidebar={<StructureScreen />}
      content={<Outlet />}
    />
  );
}

export default AdminScreen;
