import { useTranslation } from 'react-i18next';
import {
  AlertLevelsSelectInterface,
  SelectSignalInterface
} from '../@Types/Instruments/piezometer';

export type AlertLevelsType = 'ResolutionANM' | 'Other';
export type SingnalType = '≥' | '≤' | '>' | '<' | '';

const PiezometersModel = () => {
  const { t } = useTranslation();

  const alertLevels: AlertLevelsSelectInterface[] = [
    {
      label: `${t('Resolution')} ANM`,
      id: 'ResolutionANM',
      value: 'ResolutionANM'
    },
    { label: `${t('Other')}`, id: 'Other', value: 'Other', action: () => {} }
  ];

  const signals: SelectSignalInterface[] = [
    { label: 'N/A', id: '', value: '' },
    { label: '≥', id: '≥', value: '≥' },
    { label: '≤', id: '≤', value: '≤' },
    { label: '>', id: '>', value: '>' },
    { label: '<', id: '<', value: '<' }
  ];

  return { alertLevels, signals };
};

export const resolutionANM = [
  {
    name: 'ATENÇÃO',
    drained: '1,30 ≤ FS < 1,50',
    undrained: '1,20 ≤ FS < 1,30',
    coteValue: 0
  },
  {
    name: 'ALERTA',
    drained: '1,10 ≤ FS < 1,30',
    undrained: '1,00 ≤ FS < 1,20',
    coteValue: 0
  },
  { name: 'EMERGÊNCIA', drained: '1,10', undrained: '1,00', coteValue: 0 }
];

export default PiezometersModel;
