import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoIosClose } from 'react-icons/io';
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';
import Select from 'react-select';
import {
  AdvanceButtonStyled,
  CancelButtonStyled
} from '../../../Components/Buttons/ButtonsStyle';
import { CloseIconStyle } from '../../../Components/Graphs/Monitoring/Instrument/StyleInstrumentGraph';
import {
  RegisterInstrumentBackground,
  RegisterInstrumentHeader,
  RegisterInstrumentModal,
  RegisterInstrumentTitle
} from '../../../Components/Instruments/Register/RegisterInstrumentStyle';
import { checkBoxStyle } from '../../../Components/Map/MapStyle';
import { StyleInstrumentSelect } from '../../../Components/Selects/InstrumentSelect';
import {
  toastfyDimiss,
  toastfyError,
  toastfySuccess,
  toastifyLoading
} from '../../../Components/Toastify';
import ToastifyModel from '../../../Models/ToastifyModel';
import {
  ButtonAreaAdmin,
  CheckboxAdmin,
  InputArea,
  RequiredInput
} from '../../../Screens/AdminScreen/AdminScreenStyle';
import { Color } from '../../../Styles/Styles';
import { useListCompanyRelatedUsersAndStructuresLazy } from '../../../data/hooks/admin-permission/use-list-company-related-users-and-structures';
import { useCreateUserInstrumentNotification } from '../../../data/hooks/use-create-user-instrument-notification';
import {
  PermissionsActive,
  __initialPermissionsActive,
  __initialPermissionsData
} from './../Permissions/PermissionsInterface';

type AlertType = 'alert' | 'attention' | 'emergency';

export function NotificationsModal({
  setShowModal,
  showModal,
  notificationData,
  setNotificationData,
  companies
}: {
  setShowModal: Dispatch<SetStateAction<boolean>>;
  showModal: boolean;
  notificationData: any;
  setNotificationData: Dispatch<SetStateAction<object>>;
  companies: { id: string; label: string; value: string }[] | undefined;
}) {
  const { t } = useTranslation();
  const [typeOfAlert, setTypeOfAlert] = useState<{
    attention: boolean;
    alert: boolean;
    emergency: boolean;
  }>({ attention: false, alert: false, emergency: false });
  const [active, setActive] = useState<PermissionsActive>(
    __initialPermissionsActive
  );
  const [structures, setStructures] = useState<
    { id: string; label: string; value: string }[]
  >([]);
  const [users, setUsers] = useState<
    { id: string; label: string; value: string }[]
  >([]);
  const { createUserInstrumentNotification } =
    useCreateUserInstrumentNotification();

  const { listCompanyRelatedUsersAndStructures } =
    useListCompanyRelatedUsersAndStructuresLazy('');

  const instruments = [
    { label: t('Ina'), id: 'Ina', value: 'Ina' },
    { label: t('Piezometer'), id: 'Piezometer', value: 'Piezometer' },
    { label: t('Pluviometer'), id: 'Pluviometer', value: 'Pluviometer' },
    { label: t('SurfaceMarker'), id: 'SurfaceMarker', value: 'SurfaceMarker' },
    { label: t('WaterLevel'), id: 'WaterLevel', value: 'WaterLevel' }
  ];

  const handleChangeCompanies = async (id: string) => {
    const response = await listCompanyRelatedUsersAndStructures({
      variables: {
        data: {
          id: id
        }
      }
    });
    if (response.data) {
      const structures =
        response.data.listCompanyRelatedUsersAndStructures.structures.map(
          (structure: any) => ({
            id: structure.id,
            label: structure.name,
            value: structure.id
          })
        );

      setStructures(structures);

      const users =
        response.data.listCompanyRelatedUsersAndStructures.collaborators.map(
          (structure: any) => ({
            id: structure.id,
            label: structure.name,
            value: structure.id
          })
        );
      setUsers(users);
    }
  };

  const createNotification = async () => {
    const alertLabels = {
      alert: 'Alerta',
      attention: 'Atenção',
      emergency: 'Emergência'
    };

    const ArraytypeOfAlert = Object.entries(typeOfAlert)
      .filter(([key, value]) => value)
      .map(([key]) => alertLabels[key as AlertType]);

    const createResponse = await createUserInstrumentNotification({
      variables: {
        data: {
          companyId: notificationData.companyId,
          criticalities: ArraytypeOfAlert,
          instrumentType: notificationData.instrument,
          receiverId: notificationData.userId,
          structureId: notificationData.structureId
        }
      }
    });
    toastifyLoading('Cadastrando Notificação...');
    if (createResponse.data) {
      toastfyDimiss('toastLoading');
      toastfySuccess('Notificação cadastrada com sucesso!');
      setShowModal(false);
      toastfyDimiss('toastfySuccess');
    } else {
      toastfyDimiss('toastLoading');
      toastfyError(
        createResponse.errors[0].message ||
          t(ToastifyModel().toastifyMessage.error)
      );
    }
  };

  return (
    <RegisterInstrumentBackground>
      <RegisterInstrumentModal
        style={{
          width: '60%',
          minWidth: 600,
          maxWidth: 650,
          height: 'max-content',
          minHeight: '0'
        }}
      >
        <RegisterInstrumentHeader>
          <RegisterInstrumentTitle>{`${t('Register')} ${t('Notifications')}`}</RegisterInstrumentTitle>
          <IoIosClose
            size={35}
            onClick={() => {
              if (setShowModal) setShowModal(false);
            }}
            style={CloseIconStyle}
          />
        </RegisterInstrumentHeader>
        <InputArea>
          <>
            <RequiredInput style={{ position: 'relative', width: '50%' }}>
              <Select
                styles={StyleInstrumentSelect}
                isSearchable={false}
                placeholder={t('Company')}
                hideSelectedOptions
                options={companies}
                onChange={(e: any) => {
                  setNotificationData((prev) => ({
                    ...prev,
                    companyId: e.id
                  }));
                  setActive((prev) => ({
                    ...prev,
                    structure: false
                  }));
                  handleChangeCompanies(e.id);
                }}
                noOptionsMessage={() => t('NoOptions')}
                maxMenuHeight={200}
              />
            </RequiredInput>
            <RequiredInput style={{ position: 'relative', width: '50%' }}>
              <Select
                styles={StyleInstrumentSelect}
                isSearchable={false}
                placeholder={t('Structure')}
                hideSelectedOptions
                options={structures}
                onChange={(e: any) => {
                  setNotificationData((prev) => ({
                    ...prev,
                    structureId: e.id
                  }));
                  setActive((prev) => ({
                    ...prev,
                    user: false
                  }));
                }}
                noOptionsMessage={() => t('NoOptions')}
                maxMenuHeight={200}
                isDisabled={active.structure}
              />
            </RequiredInput>
            <RequiredInput style={{ position: 'relative', width: '50%' }}>
              <Select
                styles={StyleInstrumentSelect}
                isSearchable={false}
                placeholder={t('User')}
                hideSelectedOptions
                options={users}
                onChange={(e: any) => {
                  setNotificationData((prev) => ({
                    ...prev,
                    userId: e.id
                  }));
                  setActive((prev) => ({
                    ...prev,
                    instrument: false
                  }));
                }}
                noOptionsMessage={() => t('NoOptions')}
                maxMenuHeight={200}
                isDisabled={active.user}
              />
            </RequiredInput>
            <RequiredInput style={{ position: 'relative', width: '50%' }}>
              <Select
                styles={StyleInstrumentSelect}
                isSearchable={false}
                placeholder={t('Instrument')}
                hideSelectedOptions
                options={instruments}
                onChange={(e: any) => {
                  setNotificationData((prev) => ({
                    ...prev,
                    instrument: e.id
                  }));
                }}
                noOptionsMessage={() => t('NoOptions')}
                maxMenuHeight={200}
                isDisabled={active.instrument}
              />
            </RequiredInput>
            <span
              style={{
                display: 'flex',
                color: Color.Brown1,
                width: '100%',
                justifyContent: 'center',
                padding: '15px 0',
                fontWeight: '800'
              }}
            >
              {t('AlertLevel')}
            </span>
            <div
              style={{
                display: 'flex',
                alignItems: 'flex-start',
                color: Color.Brown1,
                flexDirection: 'column',
                justifyContent: 'center'
              }}
            >
              <CheckboxAdmin
                id="owner"
                onClick={() => {
                  setTypeOfAlert((prev) => ({
                    ...prev,
                    attention: !prev.attention
                  }));
                }}
              >
                {t('Attention')}{' '}
                {typeOfAlert.attention ? (
                  <MdCheckBox style={checkBoxStyle} />
                ) : (
                  <MdCheckBoxOutlineBlank style={checkBoxStyle} />
                )}
              </CheckboxAdmin>
              <CheckboxAdmin
                id="owner"
                onClick={() => {
                  setTypeOfAlert((prev) => ({ ...prev, alert: !prev.alert }));
                }}
              >
                {t('Alert')}{' '}
                {typeOfAlert.alert ? (
                  <MdCheckBox style={checkBoxStyle} />
                ) : (
                  <MdCheckBoxOutlineBlank style={checkBoxStyle} />
                )}
              </CheckboxAdmin>
              <CheckboxAdmin
                id="owner"
                onClick={() => {
                  setTypeOfAlert((prev) => ({
                    ...prev,
                    emergency: !prev.emergency
                  }));
                }}
              >
                {t('Emergency')}{' '}
                {typeOfAlert.emergency ? (
                  <MdCheckBox style={checkBoxStyle} />
                ) : (
                  <MdCheckBoxOutlineBlank style={checkBoxStyle} />
                )}
              </CheckboxAdmin>
            </div>
          </>
        </InputArea>
        <ButtonAreaAdmin style={{ justifyContent: 'flex-end' }}>
          <CancelButtonStyled
            onClick={() => {
              if (setShowModal) setShowModal(false);
              setNotificationData(__initialPermissionsData);
              setActive(__initialPermissionsActive);
            }}
          >
            {t('Cancel').toLocaleUpperCase()}
          </CancelButtonStyled>
          <AdvanceButtonStyled onClick={() => createNotification()}>
            {' '}
            {t('Save').toLocaleUpperCase()}
          </AdvanceButtonStyled>
        </ButtonAreaAdmin>
      </RegisterInstrumentModal>
    </RegisterInstrumentBackground>
  );
}
