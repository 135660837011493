import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BiSolidPencil } from 'react-icons/bi';
import { IoCloseOutline } from 'react-icons/io5';
import { useParams } from 'react-router-dom';
import {
  FindDataByRecordDocument,
  FindDataByRecordQuery
} from '../../../../data/graphql/query/generated/findDataByRecord';
import { toastfyError, toastfySuccess } from '../../../Toastify';
import ModalConfirm from '../../Molecules/ModalConfirm/ModalConfirm';

import { useMutation } from '@apollo/client';
import {
  DeleteRecordRedAlertPeriodDocument,
  DeleteRecordRedAlertPeriodMutation,
  DeleteRecordRedAlertPeriodMutationVariables
} from '../../../../data/graphql/generated/deleteRecordRedAlertPeriod';
import ErrorsTreatments from '../../../../utils/errorTreatment';
import TableComponent from '../../Molecules/TableComponent/TableComponent';
import ViewModal from '../../Molecules/ViewModal/ViewModal';
import AlertPeriodForm from '../AlertPeriodForm/AlertPeriodForm';
import CardAtoInfo from '../CardInfo/CardInfo';
import {
  CardAtoInfoAlertPeriodProps,
  RedAlertPeriodItem,
  RedAlertPeriodType,
  RowToEditType
} from './CardAtoInfoAlertPeriod.interface';

const CardAtoInfoAlertPeriod = ({
  redAlertPeriod
}: CardAtoInfoAlertPeriodProps) => {
  const { t } = useTranslation();
  const { recordId } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [updateDelete, setUpdateDelete] = useState(false);
  const [deleteRedAlertPeriod] = useMutation<
    DeleteRecordRedAlertPeriodMutation,
    DeleteRecordRedAlertPeriodMutationVariables
  >(DeleteRecordRedAlertPeriodDocument);
  const [rowToEdit, setRowToEdit] = useState<RowToEditType>();
  const [idForDelete, setIdForDelete] = useState<string>('');

  const tableColumns = [
    {
      key: 'indexId',
      label: 'id'
    },
    {
      key: 'start',
      label: t('start')
    },
    {
      key: 'End',
      label: t('End')
    }
  ];

  const tableData = redAlertPeriod.map((item: RedAlertPeriodType, index) => ({
    End: item.end,
    start: item.start,
    id: item.id,
    indexId: index
  }));

  const actions = [
    {
      name: t('Edit'),
      icon: <BiSolidPencil />,
      onClick: (row: RowToEditType) => {
        handleEditRow(row);
      },
      canShow: 'edit'
    },
    {
      name: t('Delete'),
      icon: <IoCloseOutline />,
      onClick: (row: RowToEditType) => {
        setIdForDelete(row.id);
      },
      canShow: 'delete'
    }
  ];

  const handleDeleteRow = (id: string) => {
    deleteRedAlertPeriod({
      variables: {
        deleteRecordRedAlertPeriodId: id
      },
      onCompleted: () => {
        toastfySuccess(t('deletedSuccessfully'));
        setUpdateDelete(!updateDelete);
      },
      onError: ({ graphQLErrors }) => {
        const errorMessage = ErrorsTreatments(graphQLErrors[0].message, t);
        toastfyError(errorMessage);
      },
      update: (cache, { data }) => {
        if (!data) return;
        const existingData = cache.readQuery({
          query: FindDataByRecordDocument,
          variables: {
            recordId: recordId
          }
        }) as FindDataByRecordQuery;

        const updatedData = {
          ...existingData,
          findDataByRecord: {
            ...existingData.findDataByRecord,
            redAlertPeriods:
              existingData.findDataByRecord.redAlertPeriods.filter(
                (item: RedAlertPeriodItem) => {
                  return item.id !== id;
                }
              )
          }
        };

        cache.writeQuery({
          query: FindDataByRecordDocument,
          variables: {
            recordId: recordId
          },
          data: updatedData
        });
      }
    });
  };

  const handleEditRow = (row: RowToEditType) => {
    setRowToEdit(row);
    setShowEditModal(true);
  };

  const handleAddForm = () => {
    setShowModal(true);
  };

  return (
    <>
      <CardAtoInfo
        title={t('PeriodOfRedAlert')}
        buttonLabel={t('Add+')}
        onButtonClick={handleAddForm}
      >
        <TableComponent
          columns={tableColumns}
          tableData={tableData}
          actions={actions}
        />
      </CardAtoInfo>
      <ViewModal
        showModal={showModal}
        width={40}
        height={70}
        title={t('addRedAlertPeriod')}
        onClose={() => setShowModal(false)}
        component={<AlertPeriodForm setShowModal={setShowModal} />}
      />
      <ViewModal
        showModal={showEditModal}
        width={40}
        height={50}
        title={t('editRedAlertPeriod')}
        onClose={() => setShowEditModal(false)}
        component={
          <AlertPeriodForm
            setShowModal={setShowEditModal}
            idAlertPeriod={rowToEdit?.id}
          />
        }
      />
      <ModalConfirm
        showModal={!!idForDelete}
        title={`${t('Delete')} ${t('Activity')}`}
        text={t('ConfirmDelete')}
        subtitle={t('ActionCannotBeUndone')}
        onClose={() => {
          setIdForDelete('');
        }}
        onConfirm={() => {
          handleDeleteRow(idForDelete);
          setIdForDelete('');
        }}
      />
    </>
  );
};

export default CardAtoInfoAlertPeriod;
