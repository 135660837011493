import { z } from 'zod';
import { AlertLevelFormPvProps } from './AlertLevelForm.interfaces';
import { useMutation } from '@apollo/client';
import {
  AddPluviometerAlertLevelsMutation,
  AddPluviometerAlertLevelsMutationVariables,
  AddPluviometerAlertLevelsDocument
} from '../../../../data/graphql/generated/addPluviometerAlertLevels.mutation';
import {
  EditPluviometerAndWaterLevelAlertMutation,
  EditPluviometerAndWaterLevelAlertMutationVariables,
  EditPluviometerAndWaterLevelAlertDocument
} from '../../../../data/graphql/generated/editPluviometerAndWaterLevelAlert';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toastfyError, toastfySuccess } from '../../../Toastify';
import ErrorsTreatments from '../../../../utils/errorTreatment';
import { Form, FormContent, FormFooter } from './AlertLevelForm.styles';
import InputText from '../../Molecules/InputText/InputText';
import Button from '../../Atoms/Button/Button';
import { ListAlertsLevelByPluviometerPagDocument } from '../../../../data/graphql/query/generated/listAlertLevelsByPluviometerPag';
import {
  AddWaterLevelAlertLevelsMutation,
  AddWaterLevelAlertLevelsMutationVariables,
  AddWaterLevelAlertLevelsDocument
} from '../../../../data/graphql/generated/addWaterLevelAlertLevels.mutation';
import { ListAlertsLevelByWaterLevelPagDocument } from '../../../../data/graphql/query/generated/listAlertLevelsByWaterLevelPag';
import { listAlertlevelDataProps } from '../../Pages/EditPluviometer/EditPluviometer.interfaces';

const alertLevelSchema = z.object({
  name: z.string().min(1, 'O campo deve ser preenchido'),
  value: z.string().min(1, 'O campo deve ser preenchido')
});

type formAlertLevelSchemaType = z.infer<typeof alertLevelSchema>;

const AlertLevelFormPv = ({
  alertLevel,
  setShowModal
}: AlertLevelFormPvProps<listAlertlevelDataProps>) => {
  const { t: translate } = useTranslation();
  const { structureId, instrumentId, instrumentType } = useParams();
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<formAlertLevelSchemaType>({
    resolver: zodResolver(alertLevelSchema),
    defaultValues: async () => {
      if (alertLevel?.id) {
        return {
          name: alertLevel?.name,
          value: parseInt(alertLevel?.value ?? '').toString()
        };
      } else {
        return {
          name: '',
          value: ''
        };
      }
    }
  });

  const [addPluviometerAlertLevels] = useMutation<
    AddPluviometerAlertLevelsMutation,
    AddPluviometerAlertLevelsMutationVariables
  >(AddPluviometerAlertLevelsDocument);

  const [addWaterLevelControlLevels] = useMutation<
    AddWaterLevelAlertLevelsMutation,
    AddWaterLevelAlertLevelsMutationVariables
  >(AddWaterLevelAlertLevelsDocument);

  const [editPluviometerAndWaterLevelAlert] = useMutation<
    EditPluviometerAndWaterLevelAlertMutation,
    EditPluviometerAndWaterLevelAlertMutationVariables
  >(EditPluviometerAndWaterLevelAlertDocument);

  const handleUpdateAlertLevel = async (data: formAlertLevelSchemaType) => {
    const alertLevelData = {
      alertId: alertLevel?.id ?? '',
      instrumentId: instrumentId!,
      name: data.name,
      value: parseInt(data.value)
    };
    editPluviometerAndWaterLevelAlert({
      variables: {
        structureId: structureId!,
        data: alertLevelData
      },
      refetchQueries: [
        ListAlertsLevelByPluviometerPagDocument,
        ListAlertsLevelByWaterLevelPagDocument
      ],
      onCompleted: () => {
        setShowModal(false);
        toastfySuccess(translate('updateAlertLevels'));
      },
      onError: ({ graphQLErrors }) => {
        const errorMessage = ErrorsTreatments(
          graphQLErrors[0].message,
          translate
        );
        toastfyError(errorMessage);
      }
    });
  };

  const handleNewAlertLevel = async (data: formAlertLevelSchemaType) => {
    const alertLevelData = {
      instrumentId: instrumentId!,
      name: data.name,
      value: parseInt(data.value)
    };

    if (instrumentType === 'pluviometer') {
      return addPluviometerAlertLevels({
        variables: {
          structureId: structureId!,
          data: alertLevelData
        },
        onCompleted: () => {
          setShowModal(false);
          toastfySuccess(translate('registeredSuccessfully'));
        },
        refetchQueries: [ListAlertsLevelByPluviometerPagDocument],
        onError: ({ graphQLErrors }) => {
          const errorMessage = ErrorsTreatments(
            graphQLErrors[0].message,
            translate
          );
          toastfyError(errorMessage);
        }
      });
    }

    if (instrumentType === 'waterlevel') {
      addWaterLevelControlLevels({
        variables: {
          structureId: structureId!,
          data: alertLevelData
        },
        onCompleted: () => {
          setShowModal(false);
          toastfySuccess(translate('registeredSuccessfully'));
        },
        refetchQueries: [ListAlertsLevelByWaterLevelPagDocument],
        onError: ({ graphQLErrors }) => {
          const errorMessage = ErrorsTreatments(
            graphQLErrors[0].message,
            translate
          );
          toastfyError(errorMessage);
        }
      });
    }
  };

  return (
    <Form>
      <FormContent>
        <InputText
          width="315px"
          control={control}
          label={translate('name')}
          name={'name'}
          type={'text'}
          error={!!errors.name}
          errorMessage={errors?.name?.message}
        />
        <InputText
          width="315px"
          control={control}
          label={translate('value')}
          name={'value'}
          type={'number'}
          error={!!errors.value}
          errorMessage={errors?.value?.message}
        />
      </FormContent>
      <FormFooter>
        <Button
          size="medium"
          text={
            alertLevel
              ? `${translate('editAlertlevel')}`
              : `${translate('newAlertLevel')}`
          }
          onClick={
            alertLevel
              ? handleSubmit(handleUpdateAlertLevel)
              : handleSubmit(handleNewAlertLevel)
          }
          variant={'primary'}
        />
      </FormFooter>
    </Form>
  );
};

export default AlertLevelFormPv;
