import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type FindAtoVerifiersQueryVariables = Types.Exact<{
  responsibleCompanyId: Types.Scalars['String']['input'];
  structureId: Types.Scalars['String']['input'];
}>;


export type FindAtoVerifiersQuery = { __typename?: 'Query', findAtoVerifiers: Array<{ __typename?: 'VerifierApproverType', name: string, id: string, email: string }> };


export const FindAtoVerifiersDocument = gql`
    query FindAtoVerifiers($responsibleCompanyId: String!, $structureId: String!) {
  findAtoVerifiers(
    responsibleCompanyId: $responsibleCompanyId
    structureId: $structureId
  ) {
    name
    id
    email
  }
}
    `;
export type FindAtoVerifiersQueryResult = Apollo.QueryResult<FindAtoVerifiersQuery, FindAtoVerifiersQueryVariables>;