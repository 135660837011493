import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillDelete } from 'react-icons/ai';
import { MdFileDownload } from 'react-icons/md';
import Text from '../../Atoms/Text/Text';
import ModalConfirm from '../../Molecules/ModalConfirm/ModalConfirm';
import { DataType } from './PSBListFiles.interface';
import { DivContainerPSBListFiles } from './PSBListFiles.styles';
import { downloadPSBFile } from '../../../../data/services/PSBService';
import { useParams, useSearchParams } from 'react-router-dom';
import TableComponent from '../../Molecules/TableComponent/TableComponent';
import { useMutation, useQuery } from '@apollo/client';
import {
  DeletePsbFileDocument,
  DeletePsbFileMutation,
  DeletePsbFileMutationVariables
} from '../../../../data/graphql/generated/deletePsbFile.mutation';
import {
  FindFilesPagDocument,
  FindFilesPagQuery,
  FindFilesPagQueryVariables
} from '../../../../data/graphql/query/generated/findFilesPag.query';
import { AvailableContextActionsProps } from '../../Molecules/ActionMenu/ActionMenu.interface';
import { toastfyError, toastfySuccess } from '../../../Toastify';

const PSBListFiles = () => {
  const { t: translate } = useTranslation();
  const { structureId, psbId } = useParams();
  const [searchParams] = useSearchParams();

  const [idDeletePsbFile, setIdDeletePsbFile] = useState<string | null>();

  const { data: listFilesPsb } = useQuery<
    FindFilesPagQuery,
    FindFilesPagQueryVariables
  >(FindFilesPagDocument, {
    variables: {
      psbId: psbId!,
      structureId: structureId!,
      pageInfo: {
        limit: parseInt(searchParams.get('totalForPage') || '10') as number,
        page: parseInt(searchParams.get('currentPage') || '1')
      }
    },
    fetchPolicy: 'no-cache',
    errorPolicy: 'all'
  });

  const [deletePsbFile] = useMutation<
    DeletePsbFileMutation,
    DeletePsbFileMutationVariables
  >(DeletePsbFileDocument);

  const totalPages = listFilesPsb?.findFilesPag.pageInfo.totalPages;

  const columns = [
    { key: 'File', label: `${translate('File')}` },
    { key: 'date', label: `${translate('date')}` }
  ];

  const dataTable =
    listFilesPsb?.findFilesPag.nodes.map((item) => {
      return {
        id: item.id,
        File: item.name,
        date: new Date(item.date).toLocaleDateString() || '',
        url: item.file
      };
    }) || [];

  const handleDeleteClick = async (row: DataType) => {
    setIdDeletePsbFile(row.id);
  };

  const actions: AvailableContextActionsProps<DataType> = [
    {
      name: `${translate('Download')}`,
      icon: <MdFileDownload />,
      onClick: downloadPSBFile
    },
    {
      name: `${translate('Delete')}`,
      icon: <AiFillDelete />,
      onClick: handleDeleteClick
    }
  ];

  const handleConfirmDelete = async (id: string) => {
    await deletePsbFile({
      variables: { fileId: id },
      errorPolicy: 'all',
      refetchQueries: [FindFilesPagDocument],
      awaitRefetchQueries: true,
      onCompleted: () => {
        toastfySuccess(translate('deletedSuccessfully'));
      },
      onError: () => {
        toastfyError(translate('errorDelete'));
      }
    });
    setIdDeletePsbFile(null);
  };

  return (
    <DivContainerPSBListFiles>
      {!listFilesPsb?.findFilesPag.nodes.length ? (
        <Text type={'span'}> {translate('ThereAreNoRegisteredFiles')}</Text>
      ) : (
        <TableComponent
          columns={columns}
          tableData={dataTable}
          actions={actions}
          showInUrl={true}
          paginator={true}
          totalPages={totalPages}
        />
      )}
      <ModalConfirm
        showModal={!!idDeletePsbFile}
        onClose={() => setIdDeletePsbFile(null)}
        onConfirm={() => handleConfirmDelete(idDeletePsbFile!)}
        title={translate('Delete')}
        text={translate('ConfirmDeleteFile')}
      />
    </DivContainerPSBListFiles>
  );
};

export default PSBListFiles;
