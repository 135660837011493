import styled from 'styled-components';

export const DivButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  padding-top: 50px;
`;
