import { useQuery } from '@apollo/client';
import {
  ListUsersPermissionsDocument,
  ListUsersPermissionsQuery,
  ListUsersPermissionsQueryVariables
} from '../../graphql/query/generated/listUsersPermissions.query';

export function useListPermissionsUsers(
  onCompleted?: () => void,
  onError?: () => void
) {
  const { data, loading, error } = useQuery<
    ListUsersPermissionsQuery,
    ListUsersPermissionsQueryVariables
  >(ListUsersPermissionsDocument, {
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    data,
    loading,
    error
  };
}
