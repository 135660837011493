import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type SaveRecordWeatherConditionsMutationVariables = Types.Exact<{
  data: Types.SaveAtoRecordWeatherConditionsInput;
}>;


export type SaveRecordWeatherConditionsMutation = { __typename?: 'Mutation', saveRecordWeatherConditions: string };


export const SaveRecordWeatherConditionsDocument = gql`
    mutation SaveRecordWeatherConditions($data: SaveAtoRecordWeatherConditionsInput!) {
  saveRecordWeatherConditions(data: $data)
}
    `;
export type SaveRecordWeatherConditionsMutationFn = Apollo.MutationFunction<SaveRecordWeatherConditionsMutation, SaveRecordWeatherConditionsMutationVariables>;
export type SaveRecordWeatherConditionsMutationResult = Apollo.MutationResult<SaveRecordWeatherConditionsMutation>;
export type SaveRecordWeatherConditionsMutationOptions = Apollo.BaseMutationOptions<SaveRecordWeatherConditionsMutation, SaveRecordWeatherConditionsMutationVariables>;