import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { FlowMeterTypeInterface } from '../../../@Types/Instruments/flowmeter';
import {
  InstrumentTypeInterface,
  InstrumentsNameTypeInterface
} from '../../../@Types/Instruments/instruments';
import { PiezometerTypeInterface } from '../../../@Types/Instruments/piezometer';
import { DivSelectStyle, StyleInstrumentSelect } from '../InstrumentSelect';

class InstrumentSelects extends React.Component {
  static InstrumentType = ({
    width,
    placeholder,
    options,
    setSelected,
    selected,
    updateValue,
    key,
    isMulti,
    error
  }: InstrumentTypeInterface) => {
    const { t } = useTranslation();
    return (
      <div style={{ width: width + '%' }}>
        <DivSelectStyle error={error}>
          <Select
            placeholder={placeholder}
            options={options}
            styles={StyleInstrumentSelect}
            hideSelectedOptions
            maxMenuHeight={200}
            value={selected}
            onChange={(e: any) => {
              if (setSelected) setSelected(e);
              if (updateValue && key) updateValue(key, e.id);
            }}
            noOptionsMessage={() => t('NoOptions')}
            isMulti={isMulti}
          />
        </DivSelectStyle>
      </div>
    );
  };

  static InstrumentsName = ({
    width,
    placeholder,
    options,
    setSelected,
    selected,
    updateValue,
    key,
    isMulti
  }: InstrumentsNameTypeInterface) => {
    const { t } = useTranslation();
    return (
      <div style={{ width: width + '%' }}>
        <Select
          placeholder={placeholder}
          options={options}
          styles={StyleInstrumentSelect}
          hideSelectedOptions
          maxMenuHeight={150}
          value={selected}
          onChange={(e: any) => {
            if (setSelected) setSelected(e);
            if (updateValue && key) updateValue(key, e.id);
          }}
          noOptionsMessage={() => t('NoOptions')}
          isMulti={isMulti}
        />
      </div>
    );
  };

  static SectionsType = (
    {
      width,
      placeholder,
      options,
      setSelected,
      selected,
      updateValue,
      key,
      isMulti
    }: any /*tipagem do banco */
  ) => {
    const { t } = useTranslation();
    return (
      <div style={{ width: width + '%' }}>
        <Select
          placeholder={placeholder}
          options={options}
          styles={StyleInstrumentSelect}
          hideSelectedOptions
          maxMenuHeight={200}
          value={selected}
          onChange={(e: any) => {
            if (setSelected) setSelected(e);
            if (updateValue && key) updateValue(key, e.id);
          }}
          noOptionsMessage={() => t('NoOptions')}
          isMulti={isMulti}
        />
      </div>
    );
  };

  static PiezometerType = ({
    width,
    placeholder,
    options,
    setSelected,
    selected,
    updateValue,
    key
  }: PiezometerTypeInterface) => {
    const { t } = useTranslation();
    return (
      <div style={{ width: width + '%' }}>
        <Select
          placeholder={placeholder}
          options={options}
          styles={StyleInstrumentSelect}
          hideSelectedOptions
          maxMenuHeight={200}
          value={selected}
          onChange={(e: any) => {
            setSelected(e);
            if (updateValue && key) updateValue(key, e.id);
          }}
          noOptionsMessage={() => t('NoOptions')}
        />
      </div>
    );
  };

  static FlowMeter = ({
    options,
    placeholder,
    selected,
    setSelected,
    width,
    key,
    updateValue
  }: FlowMeterTypeInterface) => {
    const { t } = useTranslation();
    return (
      <div style={{ width: width + '%' }}>
        <Select
          placeholder={placeholder}
          options={options}
          styles={StyleInstrumentSelect}
          hideSelectedOptions
          value={selected}
          onChange={(e: any) => {
            setSelected(e);
            if (updateValue && key) updateValue(key, e.id);
          }}
          noOptionsMessage={() => t('NoOptions')}
        />
      </div>
    );
  };
}

export default InstrumentSelects;
