import { useMutation } from '@apollo/client';
import {
  AddParticipantsDocument,
  AddParticipantsMutation,
  AddParticipantsMutationVariables
} from '../graphql/generated/addParticipants';

export function useAddParticipants(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [addParticipants, { data, error, loading }] = useMutation<
    AddParticipantsMutation,
    AddParticipantsMutationVariables
  >(AddParticipantsDocument, {
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    addParticipants,
    data,
    error,
    loading
  };
}
