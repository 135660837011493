import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { BsCheckLg } from 'react-icons/bs';
import { IoIosClose } from 'react-icons/io';
import { PiAsteriskBold } from 'react-icons/pi';
import InputMask from 'react-input-mask';
import Select from 'react-select';
import {
  AdvanceButtonStyled,
  CancelButtonStyled
} from '../../../Components/Buttons/ButtonsStyle';
import { CloseIconStyle } from '../../../Components/Graphs/Monitoring/Instrument/StyleInstrumentGraph';
import {
  RegisterInstrumentBackground,
  RegisterInstrumentHeader,
  RegisterInstrumentModal,
  RegisterInstrumentTitle
} from '../../../Components/Instruments/Register/RegisterInstrumentStyle';
import { StyleInstrumentSelect } from '../../../Components/Selects/InstrumentSelect';
import {
  toastfyDimiss,
  toastfyError,
  toastfySuccess,
  toastifyLoading
} from '../../../Components/Toastify';
import InputFile from '../../../Components/V2/Molecules/InputFile/InputFile';
import ToastifyModel from '../../../Models/ToastifyModel';
import {
  AdminInput,
  ButtonAreaAdmin,
  IconRequired,
  InputArea,
  InputMaskStyle,
  RequiredInput
} from '../../../Screens/AdminScreen/AdminScreenStyle';
import { Color } from '../../../Styles/Styles';
import { useCreateCompany } from '../../../data/hooks/admin-company/use-create-company';
import { useEditCompany } from '../../../data/hooks/admin-company/use-edit-company';
import { useDeleteCompanyLogo } from '../../../data/hooks/use-delete-company-logo';
import { BUCKET_URL } from '../../../utils/const';
import {
  ClientData,
  __initialClientData,
  __initialMandatory
} from './ClientInterface';
import { postLogoFile } from './ClientRest';
import InputCheckbox from '../../../Components/V2/Molecules/InputCheckbox/InputCheckbox';
import FileList from '../../../Components/V2/Molecules/FileList/FileList';
import { FileType } from '../../../Components/V2/Molecules/FileViewer/FileViewer.interfaces';

const latinAmericanCountries = [
  { id: 'Argentina', label: 'Argentina', value: 'Argentina' },
  { id: 'Bolivia', label: 'Bolívia', value: 'Bolivia' },
  { id: 'Brasil', label: 'Brasil', value: 'Brasil' },
  { id: 'Chile', label: 'Chile', value: 'Chile' },
  { id: 'Colombia', label: 'Colômbia', value: 'Colombia' },
  { id: 'Costa Rica', label: 'Costa Rica', value: 'Costa Rica' },
  { id: 'Cuba', label: 'Cuba', value: 'Cuba' },
  {
    id: 'Republica Dominicana',
    label: 'República Dominicana',
    value: 'Republica Dominicana'
  },
  { id: 'Equador', label: 'Equador', value: 'Equador' },
  { id: 'El Salvador', label: 'El Salvador', value: 'El Salvador' },
  { id: 'Guatemala', label: 'Guatemala', value: 'Guatemala' },
  { id: 'Honduras', label: 'Honduras', value: 'Honduras' },
  { id: 'Mexico', label: 'México', value: 'Mexico' },
  { id: 'Nicaragua', label: 'Nicarágua', value: 'Nicaragua' },
  { id: 'Panama', label: 'Panamá', value: 'Panama' },
  { id: 'Paraguai', label: 'Paraguai', value: 'Paraguai' },
  { id: 'Peru', label: 'Peru', value: 'Peru' },
  { id: 'Porto Rico', label: 'Porto Rico', value: 'Porto Rico' },
  { id: 'Uruguai', label: 'Uruguai', value: 'Uruguai' },
  { id: 'Venezuela', label: 'Venezuela', value: 'Venezuela' }
];

export function ClientModal({
  setShowModal,
  edit,
  setEdit,
  clientData,
  setClientData
}: {
  setShowModal: Dispatch<SetStateAction<boolean>>;
  edit: { status: boolean; data: any };
  setEdit: Dispatch<SetStateAction<{ status: boolean; data: any }>>;
  clientData: ClientData;
  setClientData: Dispatch<SetStateAction<ClientData>>;
}) {
  const { t } = useTranslation();
  const { createClient } = useCreateCompany();
  const { editClient } = useEditCompany();
  const [savedEvent, setSavedEvent] = useState<ChangeEvent<HTMLInputElement>>();
  const token = localStorage.user
    ? JSON.parse(localStorage.user).token
    : null;
  const [image, setImage] = useState<FileType[]>([]);
  const [error, setError] = useState(false);
  const { deleteCompanyLogo } = useDeleteCompanyLogo();
  const [noNumber, setNoNumber] = useState<boolean>(
    edit.status ? !edit.data.address.number : false
  );

  const handleCepChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const newCep = event.target.value
      .split('')
      .filter((e) => e !== '-')
      .join('');

    setClientData((prevData) => ({
      ...prevData,
      address: {
        ...prevData.address,
        state: '',
        city: '',
        address: '',
        zipCode: newCep
      }
    }));

    try {
      if (newCep.split('').find((e) => e === '_') === undefined) {
        toastifyLoading(t(ToastifyModel().toastifyMessage.loading));
        const response = await fetch(
          `https://viacep.com.br/ws/${newCep}/json/`
        );
        const data = await response.json();
        toastfyDimiss('toastLoading');

        setClientData((prevData) => ({
          ...prevData,
          id: data.id,
          address: {
            ...prevData.address,
            state: data.uf,
            city: data.localidade,
            address: data.logradouro,
            zipCode: newCep
          }
        }));
      }
    } catch (error) {
      toastfyError(t(ToastifyModel().toastifyMessage.error));
    }
  };

  const handleClientOperation = async (operation: string) => {
    if (
      clientData.name &&
      clientData.cnpj &&
      clientData.address.zipCode &&
      clientData.address.country &&
      clientData.address.state &&
      clientData.address.city &&
      image.length > 0
    ) {
      const formData = new FormData();
      formData.append(
        'logo',
        savedEvent && savedEvent?.target.files
          ? savedEvent?.target.files[0]
          : ''
      );

      const requestData = {
        variables: {
          data: {
            address: {
              addressLine: clientData.address.address,
              city: clientData.address.city,
              country: clientData.address.country,
              number: clientData.address.number,
              state: clientData.address.state,
              zipCode: clientData.address.zipCode
            },
            name: clientData.name,
            cnpj: clientData.cnpj
          }
        }
      };

      await (operation === 'edit'
        ? editClient({
            variables: {
              data: {
                id: clientData.id || '',
                address: {
                  addressLine: clientData.address.address,
                  city: clientData.address.city,
                  country: clientData.address.country,
                  number: clientData.address.number,
                  state: clientData.address.state,
                  zipCode: clientData.address.zipCode
                },
                name: clientData.name,
                cnpj: clientData.cnpj
              }
            }
          }).then(async (responseData) => {
            if (savedEvent && responseData.data && edit.data.id) {
              await postLogoFile(token, edit.data.id, formData);
              toastifyLoading(`${t('registering')} ${t('client')}...`);
              if (responseData.data) {
                toastfyDimiss('toastLoading');
                toastfySuccess(`${t('client')} ${t('editedSuccessfully')}!`);
                setShowModal(false);
                setClientData(__initialClientData);
                setShowModal(false);
                setEdit({ status: false, data: {} });
                window.location.reload();
              } else if (responseData.errors) {
                toastfyDimiss('toastLoading');
                toastfyError(
                  responseData.errors[0].message ||
                    t(ToastifyModel().toastifyMessage.error)
                );
              }
            } else {
              toastifyLoading(`${t('registering')} ${t('client')}...`);
              if (responseData.data) {
                toastfyDimiss('toastLoading');
                toastfySuccess(`${t('client')} ${t('editedSuccessfully')}!`);
                setShowModal(false);
                setClientData(__initialClientData);
                setShowModal(false);
                setEdit({ status: false, data: {} });
                window.location.reload();
              } else if (responseData.errors) {
                toastfyDimiss('toastLoading');
                toastfyError(
                  responseData.errors[0].message ||
                    t(ToastifyModel().toastifyMessage.error)
                );
              }
            }
          })
        : createClient(requestData).then(async (responseData) => {
            if (responseData.data) {
              if (savedEvent && responseData.data?.createCompany) {
                await postLogoFile(
                  token,
                  responseData.data?.createCompany,
                  formData
                );
                toastifyLoading(`${t('registering')} ${t('client')}...`);
                toastfyDimiss('toastLoading');
                toastfySuccess(
                  `${t('client')} ${t('registeredSuccessfully')}!`
                );
                setShowModal(false);
                setClientData(__initialClientData);
                setShowModal(false);
                setEdit({ status: false, data: {} });
                window.location.reload();
              }
            } else if (responseData.errors) {
              toastfyDimiss('toastLoading');
              toastfyError(
                responseData.errors[0].message ||
                  t(ToastifyModel().toastifyMessage.error)
              );
            }
          }));
    } else {
      toastfyDimiss('toastLoading');
      toastfyError(t(ToastifyModel().toastifyMessage.fillRequiredFields));
      setError(true);
    }
  };

  const handleInputValue = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    if (e.target.id === 'Client') {
      setClientData((prevData) => ({
        ...prevData,
        name: e.target.value,
        address: {
          ...prevData.address
        }
      }));
    } else if (e.target.id === 'CNPJ') {
      setClientData((prevData) => ({
        ...prevData,
        cnpj: e.target.value,
        address: {
          ...prevData.address
        }
      }));
    } else {
      setClientData((prevData) => ({
        ...prevData,
        address: {
          ...prevData.address,
          [e.target.name]: e.target.value
        }
      }));
    }
  };

  const handleClose = () => {
    setShowModal(false);
    setEdit({ status: false, data: {} });
    setClientData(__initialClientData);
  };

  const handleInputFile = (e: ChangeEvent<HTMLInputElement>) => {
    const FileType = ['JPG', 'PNG', 'JPEG'];
    const isImage = FileType.some((imageType) =>
      e.target.files![0].name.toUpperCase().includes(imageType)
    );
    if (isImage) {
      if (e.target.files) {
        setSavedEvent(e);
        setImage([
          {
            id: '',
            name: e.target.files[0].name,
            url: URL.createObjectURL(e.target.files[0]),
            file: e.target.files[0]
          }
        ]);
      }
    } else {
      toastfyError(t('InvalidFileType'));
    }
  };

  const handleDeleteImage = async (imgSelect: FileType) => {
    if (imgSelect.id) {
      const response = await deleteCompanyLogo({
        variables: {
          companyId: imgSelect.id
        }
      });
      if (response.data) {
        toastifyLoading(t(ToastifyModel().toastifyMessage.loading));
        toastfyDimiss('toastLoading');
        toastfySuccess(t('deletedSuccessfully'));
        setImage([]);
      } else {
        toastfyError(t(ToastifyModel().toastifyMessage.error));
      }
    } else {
      setImage([]);
    }
  };

  useEffect(() => {
    if (edit.status && edit.data) {
      if (edit.data.address.zipCode?.includes('-')) {
        const [pre, su] = edit.data.address.zipCode.split('-');
        setClientData((prevData) => ({
          ...prevData,
          address: {
            ...prevData.address,
            zipCode: pre + su
          }
        }));
      } else {
        setClientData((prevData) => ({
          ...prevData,
          address: {
            ...prevData.address,
            zipCode: edit.data.address.zipCode
          }
        }));
      }
      setClientData((prevData) => ({
        ...prevData,
        id: edit.data.id,
        cnpj: edit.data.cnpj,
        name: edit.data.name,
        address: {
          ...prevData.address,
          country: 'Brasil',
          state: edit.data.address.state,
          city: edit.data.address.city,
          address: edit.data.address.addressLine,
          number: edit.data.address.number
        }
      }));
      setImage([
        {
          id: edit.data.id,
          name: edit.data.name,
          url: `${BUCKET_URL}/${edit.data.logo}`,
          file: new File([], edit.data.logo)
        }
      ]);
      setShowModal(true);
    }
  }, []);

  const handleCheckboxChange = (newValue: boolean) => {
    setNoNumber(newValue);
    if (newValue) {
      setClientData((prevData) => ({
        ...prevData,
        address: { ...prevData.address, number: null }
      }));
    }
  };

  return (
    <RegisterInstrumentBackground>
      <RegisterInstrumentModal
        style={{
          width: '60%',
          minWidth: 600,
          maxWidth: 650,
          height: 'max-content'
        }}
      >
        <RegisterInstrumentHeader>
          <RegisterInstrumentTitle>
            {edit.status ? `${t('Edit')}` : `${t('Register')} `}{' '}
            {`${t('client')}`}
          </RegisterInstrumentTitle>
          <IoIosClose
            size={35}
            onClick={() => handleClose()}
            style={CloseIconStyle}
          />
        </RegisterInstrumentHeader>
        <InputArea>
          <RequiredInput>
            <AdminInput
              required
              placeholder={t('name')}
              value={clientData.name}
              id="Client"
              onChange={(e) => handleInputValue(e)}
              style={{ borderColor: error && !clientData.name ? 'red' : '' }}
            />
            {clientData.name.length < 2 ? (
              <PiAsteriskBold style={IconRequired} />
            ) : (
              <BsCheckLg style={IconRequired} />
            )}
          </RequiredInput>
          <RequiredInput>
            <InputMask
              required
              placeholder="CNPJ"
              id="CNPJ"
              style={{
                ...InputMaskStyle,
                borderColor: error && !clientData.cnpj ? 'red' : ''
              }}
              mask={clientData.cnpj ? '99.999.999/9999-99' : ''}
              value={clientData.cnpj}
              onChange={(e) => handleInputValue(e)}
            />
            {!clientData.cnpj ? (
              <PiAsteriskBold style={IconRequired} />
            ) : (
              <BsCheckLg style={IconRequired} />
            )}
          </RequiredInput>
          <RequiredInput>
            <InputMask
              required
              placeholder={t('CEP')}
              id="CNPJ"
              style={{
                ...InputMaskStyle,
                borderColor: error && !clientData.address.zipCode ? 'red' : ''
              }}
              mask={clientData.address.zipCode ? '99999-999' : ''}
              value={clientData.address.zipCode}
              onChange={(e) => handleCepChange(e)}
            />
            {clientData.address.zipCode.length <= 7 ? (
              <PiAsteriskBold style={IconRequired} />
            ) : (
              <BsCheckLg style={IconRequired} />
            )}
          </RequiredInput>
          <RequiredInput>
            <AdminInput
              placeholder={t('State')}
              required
              value={clientData.address.state}
              id="State"
              name="state"
              onChange={(e) => handleInputValue(e)}
              style={{
                borderColor: error && !clientData.address.state ? 'red' : ''
              }}
            />
            {!clientData.address.state ? (
              <PiAsteriskBold style={IconRequired} />
            ) : (
              <BsCheckLg style={IconRequired} />
            )}
          </RequiredInput>
          <RequiredInput>
            <AdminInput
              placeholder={t('City')}
              required
              value={clientData.address.city}
              id="City"
              name="city"
              onChange={(e) => handleInputValue(e)}
              style={{
                borderColor: error && !clientData.address.city ? 'red' : ''
              }}
            />
            {!clientData.address.city ? (
              <PiAsteriskBold style={IconRequired} />
            ) : (
              <BsCheckLg style={IconRequired} />
            )}
          </RequiredInput>
          <RequiredInput>
            <AdminInput
              placeholder={t('Address')}
              required
              value={clientData.address.address}
              id="Address"
              name="address"
              onChange={(e) => handleInputValue(e)}
            />
          </RequiredInput>
          {!noNumber && (
            <AdminInput
              placeholder={t('Number')}
              type="number"
              id="Number"
              name="number"
              value={clientData.address.number || ''}
              onChange={(e) =>
                setClientData((prevData) => ({
                  ...prevData,
                  address: {
                    ...prevData.address,
                    number: Number(e.target.value)
                  }
                }))
              }
            />
          )}
          <InputCheckbox
            name="noNumber"
            label="Sem número"
            value={noNumber}
            onChange={handleCheckboxChange}
          />
          <RequiredInput style={{ position: 'relative', width: '50%' }}>
            <Select
              styles={{
                ...StyleInstrumentSelect,
                control: (provided) => ({
                  ...provided,
                  border: 'none',
                  borderBottom:
                    error && !clientData.address.country
                      ? '1px solid red'
                      : `1px solid ${Color.Brown1}`,
                  borderRadius: 'none',
                  boxSizing: 'border-box',
                  boxShadow: 'none',
                  padding: 'none',
                  fontSize: '11pt',
                  cursor: 'pointer',
                  marginBottom: '13px',
                  userSelect: 'none',
                  ':hover': {
                    borderBottom:
                      error && !clientData.address.country
                        ? '1px solid red'
                        : `1px solid ${Color.Brown1}`
                  }
                })
              }}
              isSearchable={false}
              placeholder={t('Country')}
              hideSelectedOptions
              options={latinAmericanCountries}
              onChange={(e: any) => {
                setClientData((prevData) => ({
                  ...prevData,
                  address: {
                    ...prevData.address,
                    country: e.id
                  }
                }));
              }}
              noOptionsMessage={() => t('NoOptions')}
              maxMenuHeight={200}
              value={latinAmericanCountries.find(
                (company: any) => company.id === clientData.address.country
              )}
            />
          </RequiredInput>
          <InputFile
            onChange={(e) => handleInputFile(e)}
            name="logo"
            error={error && image.length === 0}
            accept=".png, .jpg, .jpeg"
            label={t('UploadLogo')}
          />
          {image.length > 0 && (
            <FileList
              files={image}
              onDelete={(img: FileType) => {
                handleDeleteImage(img);
              }}
            />
          )}
        </InputArea>
        <ButtonAreaAdmin style={{ justifyContent: 'flex-end' }}>
          <CancelButtonStyled onClick={() => handleClose()}>
            {t('Cancel').toLocaleUpperCase()}
          </CancelButtonStyled>
          {!edit.status && (
            <AdvanceButtonStyled
              onClick={async () => await handleClientOperation('register')}
            >
              {' '}
              {t('Save').toLocaleUpperCase()}
            </AdvanceButtonStyled>
          )}
          {edit.status && (
            <AdvanceButtonStyled
              onClick={async () => await handleClientOperation('edit')}
            >
              {' '}
              {t('Edit').toLocaleUpperCase()}
            </AdvanceButtonStyled>
          )}
        </ButtonAreaAdmin>
      </RegisterInstrumentModal>
    </RegisterInstrumentBackground>
  );
}
