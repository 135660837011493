/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { CustomRegisterInstrumentTitle } from '../RegisterInstrumentStyle';
import { DefaultInput } from '../../../Inputs/InputsStyle';
import { useTranslation } from 'react-i18next';
import { toastfyError } from '../../../Toastify';
import { FaCirclePlus } from 'react-icons/fa6';
import { CustomControlLevelSurfaceTable } from './components/CustomAlertsTable';
import { DatePickerComponent } from '../../../DatePicker';
import ToastifyModel from '../../../../Models/ToastifyModel';

export const RegisterSurfaceMarkerComponent = (props: any) => {
  const {
    setInstrumentEspecificData,
    instrumentEspecificData,
    specificInfosError,
    isEditing
  } = props;
  const { t } = useTranslation();
  const [levelDate, setLevelDate] = useState<Date>(new Date());
  const [referenceDate, setReferenceDate] = useState<Date>(new Date());
  const [levelDateError, setLevelDateError] = useState<boolean>(false);
  const [reference, setReference] = useState<any>({
    referenceElevation:
      instrumentEspecificData?.references?.[0]?.referenceElevation || '',
    referenceCoordinateN:
      instrumentEspecificData?.references?.[0]?.referenceCoordinateN || '',
    referenceCoordinateE:
      instrumentEspecificData?.references?.[0]?.referenceCoordinateE || '',
    referenceDate: instrumentEspecificData?.references?.[0]?.referenceDate || ''
  });

  useEffect(() => {
    setInstrumentEspecificData('levelDate', levelDate.toISOString());
    setInstrumentEspecificData('referenceDate', referenceDate.toISOString());
    handleReference('referenceDate', referenceDate.toISOString());
  }, [levelDate, referenceDate]);

  const addControlLevel = () => {
    if (
      !instrumentEspecificData.levelName ||
      !instrumentEspecificData.levelElevation ||
      !instrumentEspecificData.levelCoordinateN ||
      !instrumentEspecificData.levelCoordinateE
    ) {
      toastfyError(t(ToastifyModel().toastifyMessage.fillRequiredFields));
      setLevelDateError(true);
      return;
    }
    setLevelDateError(false);
    const newLevel = {
      levelName: instrumentEspecificData.levelName,
      levelElevation: instrumentEspecificData.levelElevation,
      levelCoordinateN: instrumentEspecificData.levelCoordinateN,
      levelCoordinateE: instrumentEspecificData.levelCoordinateE,
      levelDate: levelDate.toISOString()
    };

    const updatedLevels = instrumentEspecificData.alertLevelsSurface
      ? [...instrumentEspecificData.alertLevelsSurface, newLevel]
      : [newLevel];

    setInstrumentEspecificData('alertLevelsSurface', updatedLevels);
    setInstrumentEspecificData('levelName', '');
    setInstrumentEspecificData('levelElevation', '');
    setInstrumentEspecificData('levelCoordinateN', '');
    setInstrumentEspecificData('levelCoordinateE', '');
  };

  const handleReference = (key: string, value: any) => {
    const newReference = { ...reference, [key]: value };
    setReference(newReference);
    setInstrumentEspecificData('references', [newReference]);
  };

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'center',
        paddingTop: '15px'
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '50%',
          paddingRight: '5px',
          paddingLeft: '5px'
        }}
      >
        <CustomRegisterInstrumentTitle style={{ fontSize: '16px' }}>
          {t('CustomControlLevels')}
        </CustomRegisterInstrumentTitle>

        <DefaultInput
          onChange={(e) =>
            setInstrumentEspecificData('levelName', e.target.value)
          }
          value={instrumentEspecificData.levelName}
          placeholder={t('LevelName')}
          error={!instrumentEspecificData.levelName && levelDateError}
        />
        <DefaultInput
          type="number"
          onChange={(e) =>
            setInstrumentEspecificData(
              'levelElevation',
              parseFloat(e.target.value)
            )
          }
          value={instrumentEspecificData.levelElevation}
          placeholder={t('elevation')}
          error={!instrumentEspecificData.levelElevation && levelDateError}
        />
        <DefaultInput
          type="number"
          onChange={(e) =>
            setInstrumentEspecificData(
              'levelCoordinateN',
              parseFloat(e.target.value)
            )
          }
          value={instrumentEspecificData.levelCoordinateN}
          placeholder={t('coordinateN')}
          error={!instrumentEspecificData.levelCoordinateN && levelDateError}
        />
        <DefaultInput
          type="number"
          onChange={(e) =>
            setInstrumentEspecificData(
              'levelCoordinateE',
              parseFloat(e.target.value)
            )
          }
          value={instrumentEspecificData.levelCoordinateE || ''}
          placeholder={t('coordinateE')}
          error={!instrumentEspecificData.levelCoordinateE && levelDateError}
        />
        <DatePickerComponent
          startDate={levelDate}
          setStartDate={setLevelDate}
          dateText={t('Date')}
        />
        <div
          onClick={addControlLevel}
          style={{
            display: 'flex',
            alignItems: 'center',
            columnGap: '5px',
            cursor: 'pointer',
            marginBottom: '15px'
          }}
        >
          <FaCirclePlus size={15} color="#32CD32" />
          <span style={{ color: 'black', fontSize: '14px' }}>
            {t('AddLevel')}
          </span>
        </div>
        <CustomControlLevelSurfaceTable
          controlLevels={instrumentEspecificData.alertLevelsSurface || []}
          onDelete={(index: any) => {
            const updatedLevels = [
              ...instrumentEspecificData.alertLevelsSurface
            ];
            updatedLevels.splice(index, 1);
            setInstrumentEspecificData('alertLevelsSurface', updatedLevels);
          }}
        />
      </div>
      {!isEditing && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '50%',
            paddingRight: '5px',
            paddingLeft: '5px'
          }}
        >
          <CustomRegisterInstrumentTitle style={{ fontSize: '16px' }}>
            {t('registerReference')}
          </CustomRegisterInstrumentTitle>
          <DefaultInput
            type="number"
            onChange={(e) =>
              handleReference('referenceElevation', parseFloat(e.target.value))
            }
            value={reference.referenceElevation}
            placeholder={t('elevation')}
            error={!reference.referenceElevation && specificInfosError}
          />
          <DefaultInput
            type="number"
            onChange={(e) =>
              handleReference(
                'referenceCoordinateN',
                parseFloat(e.target.value)
              )
            }
            value={reference.referenceCoordinateN}
            placeholder={t('coordinateN')}
            error={!reference.referenceCoordinateN && specificInfosError}
          />
          <DefaultInput
            type="number"
            onChange={(e) =>
              handleReference(
                'referenceCoordinateE',
                parseFloat(e.target.value)
              )
            }
            value={reference.referenceCoordinateE}
            placeholder={t('coordinateE')}
            error={!reference.referenceCoordinateE && specificInfosError}
          />
          <DatePickerComponent
            startDate={referenceDate}
            setStartDate={setReferenceDate}
            dateText={t('Date')}
          />
        </div>
      )}
    </div>
  );
};
