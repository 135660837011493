import { useMutation } from '@apollo/client';
import {
  CreateInspectionDocument,
  CreateInspectionMutation,
  CreateInspectionMutationVariables
} from '../graphql/generated/createInpection.mutation';
import { FindAllInspectionsByStructureDocument } from '../graphql/query/generated/findAllInspectionsByStructure.query';
import { ListStructuresDocument } from '../graphql/query/generated/listStructures.query';
import { GetUserStructuresListDocument } from '../graphql/query/generated/structureList.query';

export function useCreateInspection(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [createInspection, { data, error, loading }] = useMutation<
    CreateInspectionMutation,
    CreateInspectionMutationVariables
  >(CreateInspectionDocument, {
    refetchQueries: [
      ListStructuresDocument,
      GetUserStructuresListDocument,
      FindAllInspectionsByStructureDocument
    ],
    awaitRefetchQueries: true,
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    createInspection,
    data,
    error,
    loading
  };
}
