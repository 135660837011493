import { useMutation } from '@apollo/client';
import {
  CreateUserPermissionDocument,
  CreateUserPermissionMutation,
  CreateUserPermissionMutationVariables
} from '../../graphql/generated/createUserPermission.mutation';
import { FindAllPsbDataDocument } from '../../graphql/query/generated/findAllPsbData.query';
import { ListCompaniesDocument } from '../../graphql/query/generated/listCompanies.query';
import { ListCompanyRelatedUsersAndStructuresDocument } from '../../graphql/query/generated/listCompanyRelatedUsersAndStructures.query';
import { ListModulesRolesDocument } from '../../graphql/query/generated/listModulesRole.query';
import { ListStructuresDocument } from '../../graphql/query/generated/listStructures.query';
import { ListUsersDocument } from '../../graphql/query/generated/listUsers.query';
import { ListUsersPermissionsDocument } from '../../graphql/query/generated/listUsersPermissions.query';
import { GetUserStructuresListDocument } from '../../graphql/query/generated/structureList.query';
import { GetStructureSummaryDocument } from '../../graphql/query/generated/structureSummary.query';

export function useCreateUserPermission(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [createUserPermission, { data, error, loading }] = useMutation<
    CreateUserPermissionMutation,
    CreateUserPermissionMutationVariables
  >(CreateUserPermissionDocument, {
    refetchQueries: [
      ListUsersPermissionsDocument,
      ListStructuresDocument,
      ListCompaniesDocument,
      ListUsersDocument,
      ListModulesRolesDocument,
      GetUserStructuresListDocument,
      ListCompanyRelatedUsersAndStructuresDocument,
      GetStructureSummaryDocument,
      FindAllPsbDataDocument
    ],
    awaitRefetchQueries: true,
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    createUserPermission,
    data,
    error,
    loading
  };
}
