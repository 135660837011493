import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultButton } from '../../../Components/Buttons/DefaultButton';
import {
  toastfyDimiss,
  toastfyError,
  toastifyLoading
} from '../../../Components/Toastify';
import ToastifyModel from '../../../Models/ToastifyModel';
import {
  AdminContainer,
  AdminFilter,
  AdminSearch,
  AdminTitle,
  DivContainer,
  SearchIcon,
  StyledInput
} from '../../../Screens/AdminScreen/AdminScreenStyle';
import { useListCompany } from '../../../data/hooks/admin-company/use-list-company';
import { useListPermissionsUsers } from '../../../data/hooks/admin-permission/use-list-users-permission';
import { AdminDeleteModal } from '../AdminDeleteModal';
import AdminTable from '../AdminTable';
import {
  PermissionsData,
  __initialPermissionsData
} from './PermissionsInterface';
import { PermissionModal } from './PermissionsModal';

function Permissions() {
  const { t: translate } = useTranslation();
  const [edit, setEdit] = useState<{ status: boolean; data: any }>({
    status: false,
    data: {}
  });
  const [search, setSearch] = useState('');
  const [showPermissionsModal, setShowPermissionsModal] =
    useState<boolean>(false);
  const {
    data: listPermissions,
    loading: loadingList,
    error: listPermissionsError
  } = useListPermissionsUsers();
  const [tableValues, setTableValues] = useState<
    {
      company: string;
      structures: string;
      user: string;
      module: string;
      permission: string;
      structureUserPermissionId: string;
      moduleAssignmentId: string;
    }[]
  >([]);
  const [deleting, setDeleting] = useState<{ status: boolean; data: any }>({
    status: false,
    data: {}
  });
  const [companies, setCompanies] = useState<
    { id: string; label: string; value: string }[]
  >([]);
  const { data: listClient } = useListCompany(true);
  const [permissionData, setPermissionData] = useState<PermissionsData>(
    __initialPermissionsData
  );

  useEffect(() => {
    if (listPermissionsError) {
      toastfyError(translate(ToastifyModel().toastifyMessage.error));
      toastfyDimiss('toastLoading');
    } else if (loadingList) {
      toastifyLoading(translate(ToastifyModel().toastifyMessage.loading));
    } else if (listPermissions) {
      const filteredTableValues = listPermissions.listUsersPermissions.map(
        (item) => ({
          company: item.companyName,
          structures: item.structureName,
          user: item.userName,
          module: item.moduleName,
          permission: item.moduleRoleName,
          structureUserPermissionId: item.structureUserPermissionId,
          moduleAssignmentId: item.moduleAssignmentId
        })
      );

      setTableValues(filteredTableValues);
      toastfyDimiss('toastLoading');
      if (listClient) {
        const activeCompanies = listClient.listCompanies
          .filter((company) => company.status === true) // Filtra apenas as empresas ativas
          .map((company) => ({
            id: company.id,
            label: company.name,
            value: company.id
          }));

        setCompanies(activeCompanies);
      }
    }
  }, [listPermissions, listClient]);

  return (
    <DivContainer>
      <AdminContainer>
        <AdminTitle>{translate('Permissions')}</AdminTitle>
        <AdminFilter>
          <div>
            <DefaultButton
              title={`+ ${translate('Add')}`}
              action={() => setShowPermissionsModal(true)}
            />
          </div>
          <AdminSearch>
            <StyledInput
              type="text"
              id="search"
              value={search}
              onChange={(event) => setSearch(event.target.value)}
            />
            <SearchIcon size={20} />
          </AdminSearch>
        </AdminFilter>
        <AdminTable
          tableColumns={[
            'company',
            'structures',
            'user',
            'module',
            'permission'
          ]}
          tableValues={tableValues.filter(
            (item) =>
              translate(item.permission)
                .toLocaleLowerCase()
                .includes(translate(search.toLocaleLowerCase())) ||
              translate(item.structures)
                .toLocaleLowerCase()
                .includes(translate(search.toLocaleLowerCase())) ||
              translate(item.user)
                .toLocaleLowerCase()
                .includes(translate(search.toLocaleLowerCase())) ||
              translate(item.company)
                .toLocaleLowerCase()
                .includes(translate(search.toLocaleLowerCase())) ||
              translate(item.module)
                .toLocaleLowerCase()
                .includes(translate(search.toLocaleLowerCase()))
          )}
          edit={edit}
          setEdit={setEdit}
          deleting={deleting}
          setDeleting={setDeleting}
        />
        {(showPermissionsModal || edit.status) && (
          <PermissionModal
            setShowModal={setShowPermissionsModal}
            edit={edit}
            setEdit={setEdit}
            permissionData={permissionData}
            setPermissionData={setPermissionData}
            companies={companies}
          />
        )}
        {deleting.status && (
          <AdminDeleteModal
            id={deleting.data.id}
            name={`${deleting.data.Permission} ${translate('ofUser')} ${deleting.data.User} ${translate('ofStructure')} ${deleting.data.Structures}`}
            setShowModal={setShowPermissionsModal}
            setDeleting={setDeleting}
            type="Permission"
            idsPermission={{
              moduleAssignmentId:
                listPermissions?.listUsersPermissions.find(
                  (item) =>
                    item.moduleName === deleting.data.module &&
                    item.structureName === deleting.data.Structures
                )?.moduleAssignmentId ?? '',
              structureUserPermissionId:
                listPermissions?.listUsersPermissions.find(
                  (item) =>
                    item.moduleName === deleting.data.module &&
                    item.structureName === deleting.data.Structures
                )?.structureUserPermissionId ?? ''
            }}
            title={translate('Permission')}
          />
        )}
      </AdminContainer>
    </DivContainer>
  );
}

export default Permissions;
