import React, { ReactNode } from 'react';
import { BiSolidPencil } from 'react-icons/bi';
import { IoCloseOutline } from 'react-icons/io5';
import Icon from '../../Atoms/Icon/Icon';
import LabelButton from '../../Molecules/LabelButton/LabelButton';
import { CardInfoNewAto, HolderCardHeader } from './CardInfoStyles';
import Text from '../../Atoms/Text/Text';
type CardProps = {
  title?: string;
  buttonLabel?: string;
  onButtonClick?: () => void;
  children: ReactNode;
  onClickEdit?: () => void;
  onClickDelete?: () => void;
  cardTitle?: string;
};

const CardAtoInfo: React.FC<CardProps> = ({
  title,
  buttonLabel,
  onButtonClick,
  children,
  cardTitle,
  onClickEdit,
  onClickDelete
}) => {
  return (
    <CardInfoNewAto>
      <HolderCardHeader>
        {cardTitle && (
          <Text weight="bold" size="medium" type="h5" color="black">
            {cardTitle}
          </Text>
        )}
        {onButtonClick && title !== undefined && buttonLabel !== undefined && (
          <LabelButton
            title={title}
            Buttontext={buttonLabel}
            onClick={onButtonClick}
          />
        )}
        {onClickEdit && (
          <Icon Icon={BiSolidPencil} variant="small" onClick={onClickEdit} />
        )}
        {onClickDelete && (
          <Icon Icon={IoCloseOutline} variant="small" onClick={onClickDelete} />
        )}
      </HolderCardHeader>
      {children}
    </CardInfoNewAto>
  );
};

export default CardAtoInfo;
