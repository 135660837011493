import { useTranslation } from 'react-i18next';
import FileList from '../../Molecules/FileList/FileList';
import InputFile from '../../Molecules/InputFile/InputFile';
import { DivContainer } from './InputFileWithList.styled';
import { InputFileWithListProps } from './InputFileWithList.interfaces';

const InputFileWithList = ({
  onChange,
  name,
  error,
  errorMessage,
  accept,
  label,
  files,
  multiple,
  onDelete,
  register,
  value,
  canDelete = true
}: InputFileWithListProps) => {
  const { t: translate } = useTranslation();

  return (
    <DivContainer>
      <InputFile
        value={value}
        register={register}
        onChange={onChange}
        name={name}
        error={error}
        accept={accept}
        multiple={multiple}
        label={translate(label)}
        errorMessage={errorMessage}
      />
      {files.length > 0 && (
        <FileList files={files} onDelete={onDelete} canDelete={canDelete} />
      )}
    </DivContainer>
  );
};

export default InputFileWithList;
