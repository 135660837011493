import { useLazyQuery } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams
} from 'react-router-dom';
import { z } from 'zod';
import {
  InstrumentReadingTypeEnum,
  InstrumentStatusEnum,
  InstrumentTypeEnum
} from '../../../../data/graphql/base-schema';
import {
  ListPiezometersByStructureDocument,
  ListPiezometersByStructureQuery,
  ListPiezometersByStructureQueryVariables
} from '../../../../data/graphql/query/generated/listPiezometersByStructure.query';
import {
  ListPluviometersByStructureDocument,
  ListPluviometersByStructureQuery,
  ListPluviometersByStructureQueryVariables
} from '../../../../data/graphql/query/generated/listPluviometersByStructure.query';
import {
  ListSurfaceMarkersByStructureDocument,
  ListSurfaceMarkersByStructureQuery,
  ListSurfaceMarkersByStructureQueryVariables
} from '../../../../data/graphql/query/generated/listSurfaceMarkersByStructure.query';
import {
  ListWaterLevelByStructureDocument,
  ListWaterLevelByStructureQuery,
  ListWaterLevelByStructureQueryVariables
} from '../../../../data/graphql/query/generated/listWaterLevelByStructure.query';
import Button from '../../Atoms/Button/Button';
import InputSelectSearch from '../../Molecules/InputSelectSearch/InputSelectSearch';
import { OptionType } from '../../Molecules/InputSelectSearch/InputSelectSearch.interfaces';
import { FilterReadingsPageProps } from './FilterReadingsPage.iterface';
import {
  DivContainerButtons,
  DivContainerForm
} from './FilterReadingsPage.styles';

const FilterReadingsPage = ({ instrument }: FilterReadingsPageProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { structureId } = useParams();
  const [searchParams] = useSearchParams();
  const { t: translate } = useTranslation();
  const structureInfo = {
    structureId: structureId || '',
    associatedStructureId: null
  };
  const status: OptionType[] = [
    { label: translate('active'), value: InstrumentStatusEnum.Active },
    { label: translate('inactive'), value: InstrumentStatusEnum.Inactive }
  ];

  const type: OptionType[] = [
    {
      label: translate('Automatic'),
      value: InstrumentReadingTypeEnum.Automatic
    },
    { label: translate('Manual'), value: InstrumentReadingTypeEnum.Manual }
  ];

  const [name, setName] = useState<OptionType[]>([]);

  const [listPiezometer] = useLazyQuery<
    ListPiezometersByStructureQuery,
    ListPiezometersByStructureQueryVariables
  >(ListPiezometersByStructureDocument);

  const [listPluviometer] = useLazyQuery<
    ListPluviometersByStructureQuery,
    ListPluviometersByStructureQueryVariables
  >(ListPluviometersByStructureDocument);

  const [listWaterLevel] = useLazyQuery<
    ListWaterLevelByStructureQuery,
    ListWaterLevelByStructureQueryVariables
  >(ListWaterLevelByStructureDocument);

  const [listSurfaceMarker] = useLazyQuery<
    ListSurfaceMarkersByStructureQuery,
    ListSurfaceMarkersByStructureQueryVariables
  >(ListSurfaceMarkersByStructureDocument);

  useEffect(() => {
    if (instrument === InstrumentTypeEnum.Piezometer) {
      listPiezometer({
        variables: {
          structureInfo
        },
        onCompleted: (data) => {
          const piezometers = data?.listPiezometersByStructure.filter(
            (item) => item.type.instrumentType === 'Piezometer'
          );
          const piezometersData =
            piezometers?.map((item) => ({
              label: item.name,
              value: item.id
            })) || [];

          setName(piezometersData);
        }
      });
    } else if (instrument === InstrumentTypeEnum.Ina) {
      listPiezometer({
        variables: {
          structureInfo
        },
        onCompleted: (data) => {
          const ina = data?.listPiezometersByStructure.filter(
            (item) => item.type.instrumentType === 'Ina'
          );
          const inaData =
            ina?.map((item) => ({
              label: item.name,
              value: item.id
            })) || [];

          setName(inaData);
        }
      });
    } else if (instrument === InstrumentTypeEnum.Pluviometer) {
      listPluviometer({
        variables: {
          structureInfo
        },
        onCompleted: (data) => {
          const pluviometer =
            data?.listPluviometersByStructure.map((item) => ({
              label: item.name,
              value: item.id ?? ''
            })) || [];

          setName(pluviometer);
        }
      });
    } else if (instrument === InstrumentTypeEnum.WaterLevel) {
      listWaterLevel({
        variables: {
          structureInfo
        },
        onCompleted: (data) => {
          const waterLevel =
            data?.listWaterLevelByStructure.map((item) => ({
              label: item.name,
              value: item.id ?? ''
            })) || [];

          setName(waterLevel);
        }
      });
    } else if (instrument === InstrumentTypeEnum.SurfaceMarker) {
      listSurfaceMarker({
        variables: {
          structureInfo
        },
        onCompleted: (data) => {
          const surfaceMarker =
            data?.listSurfaceMarkersByStructure.map((item) => ({
              label: item.name,
              value: item.id ?? ''
            })) || [];

          setName(surfaceMarker);
        }
      });
    }
  }, []);

  const FilterFormType = z.object({
    instrumentId: z
      .object({
        value: z.string(),
        label: z.string()
      })
      .nullable()
      .optional(),
    type: z
      .object({
        value: z.string(),
        label: z.string()
      })
      .nullable()
      .optional(),
    status: z
      .object({
        value: z.string(),
        label: z.string()
      })
      .nullable()
      .optional()
  });

  type FilterFormValues = z.infer<typeof FilterFormType>;
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors }
  } = useForm<FilterFormValues>({
    resolver: zodResolver(FilterFormType),
    defaultValues: {
      instrumentId: searchParams.get('instrumentId')
        ? {
            value: searchParams.get('instrumentId')!,
            label: searchParams.get('instrumentId')!
          }
        : undefined,
      type: searchParams.get('type')
        ? {
            value: searchParams.get('type')!,
            label: searchParams.get('type')!
          }
        : undefined,
      status: searchParams.get('status')
        ? {
            value: searchParams.get('status')!,
            label: searchParams.get('status')!
          }
        : undefined
    }
  });

  const handleFilterSubmit = (data: FilterFormValues) => {
    const { instrumentId, type, status } = data;

    const newSearchParams = new URLSearchParams(searchParams.toString());

    const filterParams: Record<string, string | undefined> = {
      instrumentId: instrumentId?.value,
      type: type?.value,
      status: status?.value
    };

    Object.entries(filterParams).forEach(([key, value]) => {
      if (value) {
        newSearchParams.set(key, value);
      } else {
        newSearchParams.delete(key);
      }
    });

    newSearchParams.delete('filter');

    navigate({
      pathname: location.pathname,
      search: newSearchParams.toString()
    });
  };

  const handleClearFilters = () => {
    const newSearchParams = new URLSearchParams(searchParams.toString());
    newSearchParams.delete('instrumentId');
    newSearchParams.delete('type');
    newSearchParams.delete('status');
    navigate({
      pathname: location.pathname,
      search: newSearchParams.toString()
    });
    setValue('instrumentId', { value: '', label: '' });
    setValue('type', { value: '', label: '' });
    setValue('status', { value: '', label: '' });
  };

  return (
    <div>
      <DivContainerForm>
        <InputSelectSearch
          errorMessage={errors.instrumentId?.message}
          label={translate('name')}
          name="instrumentId"
          options={name}
          width="300px"
          control={control}
          error={!!errors.instrumentId}
        />
        <InputSelectSearch
          errorMessage={errors.type?.message}
          label={translate('typeOfReading')}
          placeholder={translate('typeOfReading')}
          name="type"
          options={type}
          width="300px"
          control={control}
          error={!!errors.type}
        />
        <InputSelectSearch
          errorMessage={errors.status?.message}
          label={translate('Status')}
          placeholder={translate('Status')}
          name="status"
          options={status}
          width="300px"
          control={control}
          error={!!errors.status}
        />
      </DivContainerForm>
      <DivContainerButtons>
        <Button
          variant="secondary"
          size="small"
          text={translate('clean')}
          onClick={handleClearFilters}
        />
        <Button
          variant="primary"
          size="small"
          text={translate('Filtrar')}
          onClick={handleSubmit(handleFilterSubmit)}
        />
      </DivContainerButtons>
    </div>
  );
};

export default FilterReadingsPage;
