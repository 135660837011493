import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { BsCheckLg } from 'react-icons/bs';
import { PiAsteriskBold } from 'react-icons/pi';
import { NumericFormat } from 'react-number-format';
import { RegisterNumericStyle } from '../../../../../Components/Instruments/Register/RegisterInstrumentStyle';
import {
  AdminInput,
  ContainerGeneralData,
  IconRequired,
  InputArea,
  PartData,
  RequiredInput
} from '../../../../../Screens/AdminScreen/AdminScreenStyle';
import { StepSix } from '../StructuresInterface';

export function StructureStepSix({
  structureStepSixData,
  setStructureStepSixData,
  error
}: {
  structureStepSixData: StepSix;
  setStructureStepSixData: Dispatch<SetStateAction<StepSix>>;
  error: boolean;
}) {
  const { t } = useTranslation();

  return (
    <InputArea>
      <ContainerGeneralData>
        <PartData>
          <RequiredInput>
            <NumericFormat
              maxLength={30}
              onChange={(e: any) => {
                setStructureStepSixData((prev) => ({
                  ...prev,
                  NAMaximoMaximorum: Number(e.target.value.replace(/,/g, ''))
                }));
              }}
              style={{
                ...RegisterNumericStyle,
                width: '50%',
                padding: '10px 0',
                borderColor:
                  error && !structureStepSixData.NAMaximoMaximorum ? 'red' : '',
                background:
                  error && !structureStepSixData.NAMaximoMaximorum
                    ? '#ffeded'
                    : ''
              }}
              placeholder={`${t('N.A.MaximusMaximorum')}(m)`}
              // thousandSeparator
              value={
                structureStepSixData.NAMaximoMaximorum === 0
                  ? ''
                  : structureStepSixData.NAMaximoMaximorum
              }
            />
            {structureStepSixData.NAMaximoMaximorum === 0 ? (
              <PiAsteriskBold style={IconRequired} />
            ) : (
              <BsCheckLg style={IconRequired} />
            )}
          </RequiredInput>
          <RequiredInput>
            <NumericFormat
              maxLength={30}
              onChange={(e: any) => {
                setStructureStepSixData((prev) => ({
                  ...prev,
                  criticalDuration: Number(e.target.value.replace(/,/g, ''))
                }));
              }}
              style={{
                ...RegisterNumericStyle,
                width: '50%',
                padding: '10px 0',
                borderColor:
                  error && !structureStepSixData.criticalDuration ? 'red' : '',
                background:
                  error && !structureStepSixData.criticalDuration
                    ? '#ffeded'
                    : ''
              }}
              placeholder={`${t('CriticalDuration')}(h)`}
              // thousandSeparator
              value={
                structureStepSixData.criticalDuration === 0
                  ? ''
                  : structureStepSixData.criticalDuration
              }
            />
            {structureStepSixData.criticalDuration === 0 ? (
              <PiAsteriskBold style={IconRequired} />
            ) : (
              <BsCheckLg style={IconRequired} />
            )}
          </RequiredInput>
          <RequiredInput>
            <NumericFormat
              maxLength={30}
              onChange={(e: any) => {
                setStructureStepSixData((prev) => ({
                  ...prev,
                  inflowRate: Number(e.target.value.replace(/,/g, ''))
                }));
              }}
              style={{
                ...RegisterNumericStyle,
                width: '50%',
                padding: '10px 0',
                borderColor:
                  error && !structureStepSixData.inflowRate ? 'red' : '',
                background:
                  error && !structureStepSixData.inflowRate ? '#ffeded' : ''
              }}
              placeholder={`${t('AffluentFlow')}(m²/s)`}
              // thousandSeparator
              value={
                structureStepSixData.inflowRate === 0
                  ? ''
                  : structureStepSixData.inflowRate
              }
            />
            {structureStepSixData.inflowRate === 0 ? (
              <PiAsteriskBold style={IconRequired} />
            ) : (
              <BsCheckLg style={IconRequired} />
            )}
          </RequiredInput>
          <RequiredInput>
            <NumericFormat
              maxLength={30}
              onChange={(e: any) => {
                setStructureStepSixData((prev) => ({
                  ...prev,
                  outflowRate: Number(e.target.value.replace(/,/g, ''))
                }));
              }}
              style={{
                ...RegisterNumericStyle,
                width: '50%',
                padding: '10px 0',
                borderColor:
                  error && !structureStepSixData.outflowRate ? 'red' : '',
                background:
                  error && !structureStepSixData.outflowRate ? '#ffeded' : ''
              }}
              placeholder={`${t('EffluentFlow')}(m²/s)`}
              // thousandSeparator
              value={
                structureStepSixData.outflowRate === 0
                  ? ''
                  : structureStepSixData.outflowRate
              }
            />
            {structureStepSixData.outflowRate === 0 ? (
              <PiAsteriskBold style={IconRequired} />
            ) : (
              <BsCheckLg style={IconRequired} />
            )}
          </RequiredInput>
          <RequiredInput>
            <NumericFormat
              maxLength={30}
              onChange={(e: any) => {
                setStructureStepSixData((prev) => ({
                  ...prev,
                  projectRainfall: Number(e.target.value.replace(/,/g, ''))
                }));
              }}
              style={{
                ...RegisterNumericStyle,
                width: '50%',
                padding: '10px 0',
                borderColor:
                  error && !structureStepSixData.projectRainfall ? 'red' : '',
                background:
                  error && !structureStepSixData.projectRainfall
                    ? '#ffeded'
                    : ''
              }}
              placeholder={`${t('ProjectRain')}(mm)`}
              // thousandSeparator
              value={
                structureStepSixData.projectRainfall === 0
                  ? ''
                  : structureStepSixData.projectRainfall
              }
            />
            {structureStepSixData.projectRainfall === 0 ? (
              <PiAsteriskBold style={IconRequired} />
            ) : (
              <BsCheckLg style={IconRequired} />
            )}
          </RequiredInput>
        </PartData>
        <PartData>
          <RequiredInput>
            <NumericFormat
              maxLength={30}
              onChange={(e: any) => {
                setStructureStepSixData((prev) => ({
                  ...prev,
                  reservoirContributionArea: Number(
                    e.target.value.replace(/,/g, '')
                  )
                }));
              }}
              style={{
                ...RegisterNumericStyle,
                width: '50%',
                padding: '10px 0',
                borderColor:
                  error && !structureStepSixData.reservoirContributionArea
                    ? 'red'
                    : '',
                background:
                  error && !structureStepSixData.reservoirContributionArea
                    ? '#ffeded'
                    : ''
              }}
              placeholder={`${t('ContribuitionBasin')}`}
              // thousandSeparator
              value={
                structureStepSixData.reservoirContributionArea === 0
                  ? ''
                  : structureStepSixData.reservoirContributionArea
              }
            />
            {structureStepSixData.reservoirContributionArea === 0 ? (
              <PiAsteriskBold style={IconRequired} />
            ) : (
              <BsCheckLg style={IconRequired} />
            )}
          </RequiredInput>
          <RequiredInput>
            <AdminInput
              maxLength={30}
              onChange={(e: any) => {
                setStructureStepSixData((prev) => ({
                  ...prev,
                  returnPeriod: e.target.value.replace(/,/g, '')
                }));
              }}
              style={{
                ...RegisterNumericStyle,
                width: '50%',
                padding: '10px 0',
                borderColor:
                  error && !structureStepSixData.returnPeriod ? 'red' : '',
                background:
                  error && !structureStepSixData.returnPeriod ? '#ffeded' : ''
              }}
              placeholder={`${t('ReturnTime')} ${t('year')}s`}
              value={
                structureStepSixData.returnPeriod === 0
                  ? ''
                  : structureStepSixData.returnPeriod
              }
            />
            {structureStepSixData.returnPeriod === 0 ? (
              <PiAsteriskBold style={IconRequired} />
            ) : (
              <BsCheckLg style={IconRequired} />
            )}
          </RequiredInput>
          <RequiredInput>
            <NumericFormat
              maxLength={30}
              onChange={(e: any) => {
                setStructureStepSixData((prev) => ({
                  ...prev,
                  volumeTotalElevationCrest: Number(
                    e.target.value.replace(/,/g, '')
                  )
                }));
              }}
              style={{
                ...RegisterNumericStyle,
                width: '50%',
                padding: '10px 0',
                borderColor:
                  error && !structureStepSixData.volumeTotalElevationCrest
                    ? 'red'
                    : '',
                background:
                  error && !structureStepSixData.volumeTotalElevationCrest
                    ? '#ffeded'
                    : ''
              }}
              placeholder={`${t('TotalVolumeAtCrestElevation')}(m²)`}
              // thousandSeparator
              value={
                structureStepSixData.volumeTotalElevationCrest === 0
                  ? ''
                  : structureStepSixData.volumeTotalElevationCrest
              }
            />
            {structureStepSixData.volumeTotalElevationCrest === 0 ? (
              <PiAsteriskBold style={IconRequired} />
            ) : (
              <BsCheckLg style={IconRequired} />
            )}
          </RequiredInput>
          <RequiredInput>
            <AdminInput
              type="number"
              value={
                structureStepSixData.spillwaySill === 0
                  ? ''
                  : structureStepSixData.spillwaySill
              }
              onChange={(e) => {
                setStructureStepSixData((prev) => ({
                  ...prev,
                  spillwaySill: Number(e.target.value.replace(/,/g, ''))
                }));
              }}
              style={
                error && !structureStepSixData.spillwaySill
                  ? { borderColor: 'red', background: '#ffeded' }
                  : {}
              }
              placeholder={`${t('OverflowThreshold')}(m)`}
            />
            {structureStepSixData.spillwaySill.toLocaleString().length < 3 ? (
              <PiAsteriskBold style={IconRequired} />
            ) : (
              <BsCheckLg style={IconRequired} />
            )}
          </RequiredInput>
          <RequiredInput>
            <AdminInput
              value={structureStepSixData.spillwayType}
              onChange={(e) => {
                setStructureStepSixData((prev) => ({
                  ...prev,
                  spillwayType: e.target.value
                }));
              }}
              style={
                error && !structureStepSixData.spillwayType
                  ? { borderColor: 'red', background: '#ffeded' }
                  : {}
              }
              placeholder={t('TypeOfOverflow')}
            />
            {structureStepSixData.spillwayType.length < 3 ? (
              <PiAsteriskBold style={IconRequired} />
            ) : (
              <BsCheckLg style={IconRequired} />
            )}
          </RequiredInput>
        </PartData>
      </ContainerGeneralData>
    </InputArea>
  );
}
