import { useMutation } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';
import { AtoObservationTypeEnum } from '../../../../data/graphql/base-schema';
import {
  SaveRecordObservationDocument,
  SaveRecordObservationMutation,
  SaveRecordObservationMutationVariables
} from '../../../../data/graphql/generated/saveRecordObservation';
import {
  UpdateRecordObservationDocument,
  UpdateRecordObservationMutation,
  UpdateRecordObservationMutationVariables
} from '../../../../data/graphql/generated/updateRecordObservation';
import {
  FindDataByRecordDocument,
  FindDataByRecordQuery
} from '../../../../data/graphql/query/generated/findDataByRecord';
import ErrorsTreatments from '../../../../utils/errorTreatment';
import { toastfyError, toastfySuccess } from '../../../Toastify';
import Button from '../../Atoms/Button/Button';
import InputTextarea from '../../Molecules/InputTextarea/InputTextarea';
import {
  AtoObservationFormProps,
  ObservationFormItemType
} from './AtoObservationForm.interface';
import {
  DivButtonArea,
  DivInputsArea,
  HolderForm
} from './AtoObservationForm.styles';

const atoObsFormFormSchema = z.object({
  observation: z
    .string()
    .min(1, {
      message: 'fieldIsRequired'
    })
    .refine((val) => val !== '', {
      message: 'fieldIsRequired'
    })
});

type AtoObservationFormSchemaType = z.infer<typeof atoObsFormFormSchema>;

const AtoObservationForm = ({
  recordId,
  type,
  setShowModal,
  editObservation
}: AtoObservationFormProps) => {
  const { t: translate } = useTranslation();
  const [observation, setObservation] = useState<string>(
    editObservation?.observation || ''
  );
  const [saveRecordObservation, { loading }] = useMutation<
    SaveRecordObservationMutation,
    SaveRecordObservationMutationVariables
  >(SaveRecordObservationDocument, {});
  const [updateRecordObservation] = useMutation<
    UpdateRecordObservationMutation,
    UpdateRecordObservationMutationVariables
  >(UpdateRecordObservationDocument, {});

  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm<AtoObservationFormSchemaType>({
    resolver: zodResolver(atoObsFormFormSchema),
    defaultValues: {
      observation: editObservation ? editObservation?.observation : ''
    }
  });

  const handleSubmitObservationForm = (data: AtoObservationFormSchemaType) => {
    const { observation } = data;
    if (editObservation?.id) {
      updateRecordObservation({
        variables: {
          data: {
            id: editObservation?.id,
            observation: observation,
            type: editObservation?.type as AtoObservationTypeEnum
          }
        },
        onCompleted: () => {
          setShowModal(false);
          toastfySuccess(translate('editedSuccessfully'));
        },
        onError: ({ graphQLErrors }) => {
          const errorMessage = ErrorsTreatments(
            graphQLErrors[0].message,
            translate
          );
          toastfyError(errorMessage);
        },
        update: (cache, { data }) => {
          if (!data) return;
          const existingData = cache.readQuery({
            query: FindDataByRecordDocument,
            variables: {
              recordId: recordId
            }
          }) as FindDataByRecordQuery;

          if (type === 'ResponsibleCompanyObservation') {
            const updatedData = {
              ...existingData,
              findDataByRecord: {
                ...existingData.findDataByRecord,
                responsibleCompanyObservations: [
                  ...existingData.findDataByRecord.responsibleCompanyObservations.map(
                    (item: ObservationFormItemType) =>
                      item.id === editObservation?.id
                        ? {
                            ...item,
                            id: editObservation.id,
                            observation: observation,
                            __typename: 'AtoRecordObservationsType'
                          }
                        : item
                  )
                ]
              }
            };

            cache.writeQuery({
              query: FindDataByRecordDocument,
              variables: {
                recordId: recordId
              },
              data: updatedData
            });
          }

          if (type === 'InspectionObservation') {
            const updatedData = {
              ...existingData,
              findDataByRecord: {
                ...existingData.findDataByRecord,
                inspectionObservations: [
                  ...existingData.findDataByRecord.inspectionObservations.map(
                    (item: ObservationFormItemType) =>
                      item.id === editObservation?.id
                        ? {
                            ...item,
                            id: editObservation.id,
                            observation: observation,
                            __typename: 'AtoRecordObservationsType'
                          }
                        : item
                  )
                ]
              }
            };
            cache.writeQuery({
              query: FindDataByRecordDocument,
              variables: {
                recordId: recordId
              },
              data: updatedData
            });
          }

          if (type === 'WorkSafetyObservation') {
            const updatedData = {
              ...existingData,
              findDataByRecord: {
                ...existingData.findDataByRecord,
                workSafetyObservations: [
                  ...existingData.findDataByRecord.workSafetyObservations.map(
                    (item: ObservationFormItemType) =>
                      item.id === editObservation?.id
                        ? {
                            ...item,
                            id: editObservation.id,
                            observation: observation,
                            __typename: 'AtoRecordObservationsType'
                          }
                        : item
                  )
                ]
              }
            };
            cache.writeQuery({
              query: FindDataByRecordDocument,
              variables: {
                recordId: recordId
              },
              data: updatedData
            });
          }
        }
      });
    } else {
      saveRecordObservation({
        variables: {
          data: {
            observation: observation,
            recordId: recordId,
            type: type
          }
        },
        onCompleted: () => {
          setShowModal(false);
          toastfySuccess(translate('registeredSuccessfully'));
        },
        onError: ({ graphQLErrors }) => {
          const errorMessage = ErrorsTreatments(
            graphQLErrors[0].message,
            translate
          );
          toastfyError(errorMessage);
        },
        update: (cache, { data: backendResponse }) => {
          if (!backendResponse) return;
          const existingData = cache.readQuery({
            query: FindDataByRecordDocument,
            variables: {
              recordId: recordId
            }
          }) as FindDataByRecordQuery;

          if (type === 'ResponsibleCompanyObservation') {
            const updatedData = {
              ...existingData,
              findDataByRecord: {
                ...existingData.findDataByRecord,
                responsibleCompanyObservations: [
                  ...existingData.findDataByRecord
                    .responsibleCompanyObservations,
                  {
                    id: backendResponse.saveRecordObservation,
                    observation: observation,
                    __typename: 'AtoRecordObservationsType'
                  }
                ]
              }
            };

            cache.writeQuery({
              query: FindDataByRecordDocument,
              variables: {
                recordId: recordId
              },
              data: updatedData
            });
          }

          if (type === 'InspectionObservation') {
            const updatedData = {
              ...existingData,
              findDataByRecord: {
                ...existingData.findDataByRecord,
                inspectionObservations: [
                  ...existingData.findDataByRecord.inspectionObservations,
                  {
                    id: backendResponse.saveRecordObservation,
                    observation: observation,
                    __typename: 'AtoRecordObservationsType'
                  }
                ]
              }
            };

            cache.writeQuery({
              query: FindDataByRecordDocument,
              variables: {
                recordId: recordId
              },
              data: updatedData
            });
          }

          if (type === 'WorkSafetyObservation') {
            const updatedData = {
              ...existingData,
              findDataByRecord: {
                ...existingData.findDataByRecord,
                workSafetyObservations: [
                  ...existingData.findDataByRecord.workSafetyObservations,
                  {
                    id: backendResponse.saveRecordObservation,
                    observation: observation,
                    __typename: 'AtoRecordObservationsType'
                  }
                ]
              }
            };

            cache.writeQuery({
              query: FindDataByRecordDocument,
              variables: {
                recordId: recordId
              },
              data: updatedData
            });
          }
        }
      });
    }
  };

  return (
    <HolderForm>
      <DivInputsArea>
        <InputTextarea
          errorMessage={errors.observation?.message}
          error={!!errors.observation}
          height="100px"
          label={translate('observation')}
          name="observation"
          onChange={(event) => setObservation(event.target.value)}
          register={register}
          value={observation}
          width="300px"
        />
      </DivInputsArea>
      <DivButtonArea>
        <Button
          text={translate('Cancel')}
          variant={'secondary'}
          size="small"
          disabled={loading}
          onClick={() => {
            setShowModal(false);
          }}
        />
        <Button
          text={translate('Save')}
          variant={'primary'}
          size="small"
          disabled={loading}
          onClick={handleSubmit(handleSubmitObservationForm)}
        />
      </DivButtonArea>
    </HolderForm>
  );
};

export default AtoObservationForm;
