import { useQuery } from '@apollo/client';
import {
  ListStructureAndModulesDocument,
  ListStructureAndModulesQuery,
  ListStructureAndModulesQueryVariables
} from '../../graphql/query/generated/listStructureAndModules.query';

export function useListStructureAndModules(
  onCompleted?: () => void,
  onError?: () => void
) {
  const { data, loading, error } = useQuery<
    ListStructureAndModulesQuery,
    ListStructureAndModulesQueryVariables
  >(ListStructureAndModulesDocument, {
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    data,
    loading,
    error
  };
}
