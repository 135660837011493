import { useMutation } from '@apollo/client';
import {
  EditStructureDamTailingVolumeDocument,
  EditStructureDamTailingVolumeMutation,
  EditStructureDamTailingVolumeMutationVariables
} from '../graphql/generated/editStructureDamTailingVolume.mutation';

export function useEditStructureDamTailingVolume(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [editStructureDamTailingVolume, { data, error, loading }] = useMutation<
    EditStructureDamTailingVolumeMutation,
    EditStructureDamTailingVolumeMutationVariables
  >(EditStructureDamTailingVolumeDocument, {
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    editStructureDamTailingVolume,
    data,
    error,
    loading
  };
}
