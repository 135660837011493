import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';
import {
  AtoRecordWeatherConditionsType,
  AtoWeatherConditionEnum
} from '../../../../data/graphql/base-schema';
import {
  SaveRecordWeatherConditionsDocument,
  SaveRecordWeatherConditionsMutation,
  SaveRecordWeatherConditionsMutationVariables
} from '../../../../data/graphql/generated/saveRecordWeatherConditions';
import {
  UpdateRecordWeatherConditionsDocument,
  UpdateRecordWeatherConditionsMutation,
  UpdateRecordWeatherConditionsMutationVariables
} from '../../../../data/graphql/generated/updateRecordWeatherConditions';
import {
  FindDataByRecordDocument,
  FindDataByRecordQuery
} from '../../../../data/graphql/query/generated/findDataByRecord';
import ErrorsTreatments from '../../../../utils/errorTreatment';
import { toastfyError, toastfySuccess } from '../../../Toastify';
import Button from '../../Atoms/Button/Button';
import DatepickerInput from '../../Molecules/DatepickerInput/DatepickerInput';
import InputSelectSearch from '../../Molecules/InputSelectSearch/InputSelectSearch';
import { WeatherConditionProps } from './WeatherCondition.interface';
import {
  DivButtonArea,
  DivInputsArea,
  HolderForm
} from './WeatherConditionForm.styles';

const queryWeatherConditions = gql`
  query FindDataByRecord($recordId: String!) {
    findDataByRecord(recordId: $recordId) {
      weatherConditions {
        end
        id
        start
        weather
      }
      recordDate
    }
  }
`;

const wheaterConditionSchema = z.object({
  condition: z
    .object({
      value: z.string(),
      label: z.string()
    })
    .nullable()
    .refine((val) => val !== null && val.value !== '', {
      message: 'fieldIsRequired'
    }),
  initialPeriod: z
    .date()
    .nullable()
    .refine((val) => val !== null, {
      message: 'fieldIsRequired'
    }),
  finalPeriod: z
    .date()
    .nullable()
    .refine((val) => val !== null, {
      message: 'fieldIsRequired'
    })
});

type WheaterConditionSchemaType = z.infer<typeof wheaterConditionSchema>;

const WeatherConditionForm = ({
  setShowModal,
  recordId,
  idWeatherCondition
}: WeatherConditionProps) => {
  const { t } = useTranslation();
  const [saveRecordWeatherConditions] = useMutation<
    SaveRecordWeatherConditionsMutation,
    SaveRecordWeatherConditionsMutationVariables
  >(SaveRecordWeatherConditionsDocument);
  const [updateRecordWeatherConditions] = useMutation<
    UpdateRecordWeatherConditionsMutation,
    UpdateRecordWeatherConditionsMutationVariables
  >(UpdateRecordWeatherConditionsDocument);

  const [getWeatherCondition, { data }] = useLazyQuery(queryWeatherConditions);

  const editWeatherCondition = data?.findDataByRecord?.weatherConditions?.find(
    (item: AtoRecordWeatherConditionsType) => item.id === idWeatherCondition
  );

  const rdoDate = data?.findDataByRecord?.recordDate;

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<WheaterConditionSchemaType>({
    resolver: zodResolver(wheaterConditionSchema),
    defaultValues: async () => {
      const responseWeatherCondition = await getWeatherCondition({
        variables: {
          recordId: recordId
        }
      });

      const weatherCondition =
        responseWeatherCondition.data?.findDataByRecord?.weatherConditions?.find(
          (item: AtoRecordWeatherConditionsType) =>
            item.id === idWeatherCondition
        );

      const rdoDate =
        responseWeatherCondition.data?.findDataByRecord?.recordDate;
      if (idWeatherCondition) {
        return {
          condition: {
            value: weatherCondition.weather,
            label: weatherCondition.weather
          },
          initialPeriod: new Date(weatherCondition.start),
          finalPeriod: new Date(weatherCondition.end)
        };
      } else {
        return {
          condition: {
            value: '',
            label: ''
          },
          initialPeriod: new Date(rdoDate),
          finalPeriod: new Date(rdoDate)
        };
      }
    }
  });

  const conditionOptions = [
    {
      label: t('Good'),
      value: 'Good'
    },
    {
      label: t('Rainy'),
      value: 'Rainy'
    }
  ];

  const handleSubmitWeather = (data: WheaterConditionSchemaType) => {
    const { finalPeriod, initialPeriod, condition } = data;
    const { value: weather } = condition;

    if (editWeatherCondition?.id) {
      updateRecordWeatherConditions({
        variables: {
          data: {
            id: editWeatherCondition?.id,
            end: finalPeriod,
            start: initialPeriod,
            weather: weather as AtoWeatherConditionEnum
          }
        },
        onCompleted: () => {
          setShowModal(false);
          toastfySuccess(t('editedSuccessfully'));
        },
        onError: ({ graphQLErrors }) => {
          const errorMessage = ErrorsTreatments(graphQLErrors[0].message, t);
          toastfyError(errorMessage);
        },
        update: (cache, { data }) => {
          if (!data) return;
          const existingData = cache.readQuery({
            query: FindDataByRecordDocument,
            variables: {
              recordId: recordId
            }
          }) as FindDataByRecordQuery;

          const updatedData = {
            ...existingData,
            findDataByRecord: {
              ...existingData.findDataByRecord,
              weatherConditions:
                existingData.findDataByRecord.weatherConditions.map(
                  (item: AtoRecordWeatherConditionsType) =>
                    item.id === editWeatherCondition?.id
                      ? {
                          ...item,
                          id: editWeatherCondition?.id,
                          end: finalPeriod,
                          start: initialPeriod,
                          weather: weather as AtoWeatherConditionEnum,
                          __typename: 'AtoRecordWeatherConditionsType'
                        }
                      : item
                )
            }
          };

          cache.writeQuery({
            query: FindDataByRecordDocument,
            variables: {
              recordId: recordId
            },
            data: updatedData
          });
        }
      });
    } else {
      saveRecordWeatherConditions({
        variables: {
          data: {
            weather: weather as AtoWeatherConditionEnum,
            start: initialPeriod,
            recordId: recordId,
            end: finalPeriod
          }
        },
        onCompleted: () => {
          setShowModal(false);
          toastfySuccess(t('registeredSuccessfully'));
        },
        onError: ({ graphQLErrors }) => {
          const errorMessage = ErrorsTreatments(graphQLErrors[0].message, t);
          toastfyError(errorMessage);
        },
        update: (cache, { data }) => {
          if (!data) return;
          const existingData = cache.readQuery({
            query: FindDataByRecordDocument,
            variables: {
              recordId: recordId
            }
          }) as FindDataByRecordQuery;

          const updatedData = {
            ...existingData,
            findDataByRecord: {
              ...existingData.findDataByRecord,
              weatherConditions: [
                ...existingData.findDataByRecord.weatherConditions,
                {
                  weather: weather as AtoWeatherConditionEnum,
                  start: initialPeriod,
                  id: data?.saveRecordWeatherConditions,
                  end: finalPeriod,
                  __typename: 'AtoRecordWeatherConditionsType'
                }
              ]
            }
          };

          cache.writeQuery({
            query: FindDataByRecordDocument,
            variables: {
              recordId: recordId
            },
            data: updatedData
          });
        }
      });
    }
  };

  return (
    <HolderForm>
      <DivInputsArea>
        <InputSelectSearch
          errorMessage={errors.condition?.message}
          placeholder="Selecione uma opção"
          label={t('condition')}
          name="condition"
          options={conditionOptions}
          width="200px"
          control={control}
          error={!!errors.condition}
        />
        <DatepickerInput
          placeholder={t('initialPeriod')}
          enableMinMaxDateSelection={true}
          minDate={
            editWeatherCondition
              ? new Date(editWeatherCondition?.start)
              : new Date(rdoDate)
          }
          maxDate={
            editWeatherCondition
              ? new Date(editWeatherCondition?.start)
              : new Date(rdoDate)
          }
          time={true}
          error={!!errors.initialPeriod}
          errorMessage={errors.initialPeriod?.message}
          control={control}
          name="initialPeriod"
          label={t('initialPeriod')}
        />
        <DatepickerInput
          placeholder={t('finalPeriod')}
          enableMinMaxDateSelection={true}
          minDate={
            editWeatherCondition
              ? new Date(editWeatherCondition?.end)
              : new Date(rdoDate)
          }
          maxDate={
            editWeatherCondition
              ? new Date(editWeatherCondition?.end)
              : new Date(rdoDate)
          }
          time={true}
          error={!!errors.finalPeriod}
          errorMessage={errors.finalPeriod?.message}
          control={control}
          name="finalPeriod"
          label={t('finalPeriod')}
        />
      </DivInputsArea>
      <DivButtonArea>
        <Button
          text={t('Cancel')}
          variant={'secondary'}
          size="small"
          onClick={() => {
            setShowModal(false);
          }}
        />
        <Button
          text={t('Save')}
          variant={'primary'}
          size="small"
          onClick={handleSubmit(handleSubmitWeather)}
        />
      </DivButtonArea>
    </HolderForm>
  );
};

export default WeatherConditionForm;
