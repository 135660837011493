import Accordion from '../../Molecules/Accordion/Accordion';
import Volume from '../Volume/Volume';
import { DivContainerGISTM } from './GISTMList.style';
import { useFindAllGistmData } from '../../../../data/hooks/gistm/findAllGistmData';
import { useParams } from 'react-router-dom';

export default function GISTMList() {
  const { structureId } = useParams();
  const { data: findAllGistm } = useFindAllGistmData(structureId || '');
  const url = location.pathname;

  return (
    <DivContainerGISTM>
      {findAllGistm?.findAllGistmData.map((volumeItem) => (
        <Accordion
          key={volumeItem.id}
          component={
            volumeItem.nested &&
            volumeItem.nested.map((contentItem) => {
              if (contentItem.nested) {
                for (const i of contentItem.nested) {
                  if (i.nested && i.nested.length > 0) {
                    i.nested.map((aux) => {
                      return (
                        <Volume
                          key={aux.id}
                          data={[aux]}
                          title={aux.name}
                          url={url}
                        />
                      );
                    });
                  } else {
                    return (
                      <Volume
                        key={contentItem.id}
                        data={contentItem.nested}
                        title={contentItem.name}
                        url={url}
                      />
                    );
                  }
                }
              }
            })
          }
          title={volumeItem.name}
        />
      ))}
    </DivContainerGISTM>
  );
}
