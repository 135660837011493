import styled from 'styled-components';
import { Color } from '../../../../Styles/Styles';
import { IconProfileProps } from './IconProfile.interface';

export const DivContainerProfile = styled.div<IconProfileProps>`
  background-color: ${(props) => (props.disabled ? Color.Gray1 : Color.Brown1)};
  width: 40px;
  min-width: 40px;
  height: 40px;
  min-height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${Color.White};
  transition: background-color 0.3s ease;

  &:hover {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'default')};
  }
  ${(props) =>
    !props.disabled &&
    `
    &:hover {
      background-color: ${Color.Brown2};
    }
  `}
`;
