import { useMutation } from '@apollo/client';
import {
  CreatePluviometerDocument,
  CreatePluviometerMutation,
  CreatePluviometerMutationVariables
} from '../../graphql/generated/addPluviometerInstrumente';
import { ListInstrumentsByStructureDocument } from '../../graphql/query/generated/listInstrumentsByStructure.query';
import { ListPluviometersByStructureDocument } from '../../graphql/query/generated/listPluviometersByStructure.query';

export function useAddPluviometer(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [addPluviometer, { data, error, loading }] = useMutation<
    CreatePluviometerMutation,
    CreatePluviometerMutationVariables
  >(CreatePluviometerDocument, {
    awaitRefetchQueries: true,
    refetchQueries: [
      ListPluviometersByStructureDocument,
      ListInstrumentsByStructureDocument
    ],
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    addPluviometer,
    data,
    error,
    loading
  };
}
