/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MdDelete } from 'react-icons/md';
import { TableArea, TableComponentStyle } from '../../../../Tables/TablesStyle';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import { Color } from '../../../../../Styles/Styles';

export const CustomControlLevelSurfaceTable = ({
  controlLevels,
  onDelete
}: any) => {
  const { t } = useTranslation();

  const formatDate = (dateString: Date) => {
    const date = new Date(dateString);
    return format(date, 'dd/MM/yyyy HH:mm', { locale: ptBR });
  };
  return (
    <TableArea style={{ width: '100%', maxHeight: '120px' }}>
      <TableComponentStyle>
        <thead>
          <tr>
            <th>{t('name')}</th>
            <th>{t('elevation')}</th>
            <th>{t('coordinateN')}</th>
            <th>{t('coordinateE')}</th>
            <th>{t('date')}</th>
            <th>{t('delete')}</th>
          </tr>
        </thead>
        <tbody>
          {controlLevels.length > 0 ? (
            controlLevels.map((level: any, index: number) => (
              <tr key={index}>
                <td>{level.levelName}</td>
                <td>{level.levelElevation}</td>
                <td>{level.levelCoordinateN}</td>
                <td>{level.levelCoordinateE}</td>
                <td>{formatDate(level.levelDate)}</td>
                <td>
                  <div
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <MdDelete
                      color={Color.Brown1}
                      onClick={() => onDelete(index)}
                    />
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={5} style={{ textAlign: 'center' }}>
                {t('NoDataAvailable')}
              </td>
            </tr>
          )}
        </tbody>
      </TableComponentStyle>
    </TableArea>
  );
};
