import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ListWaterLevelByStructureQueryVariables = Types.Exact<{
  structureInfo: Types.StructureInfoInput;
}>;


export type ListWaterLevelByStructureQuery = { __typename?: 'Query', listWaterLevelByStructure: Array<{ __typename?: 'WaterLevelListType', id?: string | null, activationDate?: Date | null, coordinateE: number, coordinateN: number, isUTM: boolean, installLocation?: string | null, installationDate?: Date | null, name: string, readingType: string, status: boolean, operationalStatus: Types.InstrumentStatusEnum, deactivationDate?: Date | null, type?: { __typename?: 'InstrumentType', id: string, instrumentType: string } | null }> };


export const ListWaterLevelByStructureDocument = gql`
    query ListWaterLevelByStructure($structureInfo: StructureInfoInput!) {
  listWaterLevelByStructure(structureInfo: $structureInfo) {
    id
    activationDate
    coordinateE
    coordinateN
    isUTM
    installLocation
    installationDate
    name
    readingType
    status
    operationalStatus
    deactivationDate
    type {
      id
      instrumentType
    }
  }
}
    `;
export type ListWaterLevelByStructureQueryResult = Apollo.QueryResult<ListWaterLevelByStructureQuery, ListWaterLevelByStructureQueryVariables>;