import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ListLatestReadingByPiezometerQueryVariables = Types.Exact<{
  instrumentId: Types.Scalars['String']['input'];
}>;


export type ListLatestReadingByPiezometerQuery = { __typename?: 'Query', listLatestReadingByPiezometer: { __typename?: 'ListLatestPiezometerReadingType', alertLevels?: string | null, cote: number, date: Date, id: string, name: string, operationalStatus: string } };


export const ListLatestReadingByPiezometerDocument = gql`
    query ListLatestReadingByPiezometer($instrumentId: String!) {
  listLatestReadingByPiezometer(instrumentId: $instrumentId) {
    alertLevels
    cote
    date
    id
    name
    operationalStatus
  }
}
    `;
export type ListLatestReadingByPiezometerQueryResult = Apollo.QueryResult<ListLatestReadingByPiezometerQuery, ListLatestReadingByPiezometerQueryVariables>;