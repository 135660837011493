import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { MssGenericDataInput } from '../../../../data/graphql/base-schema';
import { IdentifiesContainer, IdentifiesHeader } from './DamMassifStyles';
import { YesNoBodyTable } from './YesNoBodyTable';
import { TableComponentStyle } from '../../../../Components/Tables/TablesStyle';

export function PresenceOfTreesAnimalsInsects({
  setStepStates,
  stepStates,
  error,
  inspectionId
}: {
  setStepStates: Dispatch<SetStateAction<any>>;
  stepStates: MssGenericDataInput[];
  error: boolean;
  inspectionId: string;
}) {
  const { t }: any = useTranslation();

  const topics = [
    {
      topic: 'Presença de árvores ou arbustos',
      title: 'PresenceOfTreesOrBushes'
    },
    {
      topic: 'Vegetação de cor mais destacadas',
      title: 'VegetationOfBrighterColor'
    },
    {
      topic: 'Formigueiros / Cupins / Roedores',
      title: 'AntsTermitesRodents'
    },
    {
      topic: 'Animais (verificar pegadas / fezes)',
      title: 'AnimalsCheckFootprintsFeces'
    },
    {
      topic: 'Presença de tocas ou buracos',
      title: 'PresenceOfDensOrHoles'
    }
  ];

  return (
    <IdentifiesContainer>
      <IdentifiesHeader>
        <span>{t('PresenceOfTrees/Animals/Insects')}</span>
      </IdentifiesHeader>
      <TableComponentStyle>
        <thead>
          <tr>
            <th></th>
            <th>{t('Value')}</th>
            <th>{t('Observation')}</th>
            <th>{t('Image')}</th>
          </tr>
        </thead>
        <tbody>
          {topics.map((topic, index) => (
            <YesNoBodyTable
              setState={setStepStates}
              state={stepStates}
              topic={topic.topic}
              title={topic.title}
              key={index}
              error={error}
              inspectionId={inspectionId}
            />
          ))}
        </tbody>
      </TableComponentStyle>
    </IdentifiesContainer>
  );
}
