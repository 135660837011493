import React, { ReactNode } from 'react';
import Text from '../../Atoms/Text/Text';
import {
  SignatureCardHeader,
  SignatureAtoContainer,
  HolderSignature
} from './SignatureContainerStyles';
type SignatureontainerProps = {
  title: string;
  children: ReactNode;
};

const SignatureContainer: React.FC<SignatureontainerProps> = ({
  title,
  children
}) => {
  return (
    <SignatureAtoContainer>
      <SignatureCardHeader>
        <Text weight="bold" size="medium" type="h5" color="black">
          {title}
        </Text>
      </SignatureCardHeader>
      <HolderSignature>{children}</HolderSignature>
    </SignatureAtoContainer>
  );
};

export default SignatureContainer;
