import styled from 'styled-components';
import { Color } from '../../../../Styles/Styles';

export const DivModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
`;

export const AcceptTermsContainer = styled.div`
  width: 90%;
  height: 90%;
  display: flex;
  background-color: ${Color.White};
  border-radius: 4px;
  padding: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
`;

export const DivHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: start;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid ${Color.Brown3};
  box-sizing: border-box;
`;

export const DivTermsText = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  padding: 15px;
  box-sizing: border-box;
  overflow: auto;
`;

export const DivCheckbox = styled.div`
  padding: 20px 0 0 0;
  box-sizing: border-box;
`;

export const DivButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  padding: 10px;
  box-sizing: border-box;
`;
