import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type RecordApprovalFlowMutationVariables = Types.Exact<{
  recordId: Types.Scalars['String']['input'];
}>;


export type RecordApprovalFlowMutation = { __typename?: 'Mutation', recordApprovalFlow: string };


export const RecordApprovalFlowDocument = gql`
    mutation RecordApprovalFlow($recordId: String!) {
  recordApprovalFlow(recordId: $recordId)
}
    `;
export type RecordApprovalFlowMutationFn = Apollo.MutationFunction<RecordApprovalFlowMutation, RecordApprovalFlowMutationVariables>;
export type RecordApprovalFlowMutationResult = Apollo.MutationResult<RecordApprovalFlowMutation>;
export type RecordApprovalFlowMutationOptions = Apollo.BaseMutationOptions<RecordApprovalFlowMutation, RecordApprovalFlowMutationVariables>;