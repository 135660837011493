import { useMutation } from '@apollo/client';
import {
  EditStructureDamRaisingDocument,
  EditStructureDamRaisingMutation,
  EditStructureDamRaisingMutationVariables
} from '../graphql/generated/editStructureDamRaising.mutation';

export function useEditStructureDamRaising(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [editStructureDamRaising, { data, error, loading }] = useMutation<
    EditStructureDamRaisingMutation,
    EditStructureDamRaisingMutationVariables
  >(EditStructureDamRaisingDocument, {
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    editStructureDamRaising,
    data,
    error,
    loading
  };
}
