import { MutationHookOptions, useMutation } from '@apollo/client';
import { DocumentNode } from 'graphql';

export function useGenericMutation<TData = any, TVariables = any>(
  mutationDocument: DocumentNode,
  options?: MutationHookOptions<TData, TVariables>
) {
  const [mutate, { data, error, loading }] = useMutation<TData, TVariables>(
    mutationDocument,
    options
  );

  return {
    mutate,
    data,
    error,
    loading
  };
}
