import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type EditStructureAndModulesMutationVariables = Types.Exact<{
  data: Types.EditStructureModulesInput;
}>;


export type EditStructureAndModulesMutation = { __typename?: 'Mutation', editStructureAndModules: string };


export const EditStructureAndModulesDocument = gql`
    mutation EditStructureAndModules($data: EditStructureModulesInput!) {
  editStructureAndModules(data: $data)
}
    `;
export type EditStructureAndModulesMutationFn = Apollo.MutationFunction<EditStructureAndModulesMutation, EditStructureAndModulesMutationVariables>;
export type EditStructureAndModulesMutationResult = Apollo.MutationResult<EditStructureAndModulesMutation>;
export type EditStructureAndModulesMutationOptions = Apollo.BaseMutationOptions<EditStructureAndModulesMutation, EditStructureAndModulesMutationVariables>;