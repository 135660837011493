import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type DeletePsbFileMutationVariables = Types.Exact<{
  fileId: Types.Scalars['String']['input'];
}>;


export type DeletePsbFileMutation = { __typename?: 'Mutation', deletePsbFile: string };


export const DeletePsbFileDocument = gql`
    mutation DeletePsbFile($fileId: String!) {
  deletePsbFile(fileId: $fileId)
}
    `;
export type DeletePsbFileMutationFn = Apollo.MutationFunction<DeletePsbFileMutation, DeletePsbFileMutationVariables>;
export type DeletePsbFileMutationResult = Apollo.MutationResult<DeletePsbFileMutation>;
export type DeletePsbFileMutationOptions = Apollo.BaseMutationOptions<DeletePsbFileMutation, DeletePsbFileMutationVariables>;