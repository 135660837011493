import styled, { CSSProperties } from 'styled-components';
import { Color, FontSize } from '../../../../Styles/Styles';

export const GrapHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px;
  font-size: ${FontSize.Medium};
  color: ${Color.Brown2};
  border-bottom: 1px solid ${Color.Brown2};
  & h3 {
    color: ${Color.Brown1};
  }

  & span {
    position: absolute;
    top: 0px;
    right: 25px;
  }
`;

export const GrapAsideMenu = styled.aside`
  position: absolute;
  top: 77px;
  bottom: 0;
  left: 0;
  width: 292px;
  /* height: 100%; */
  background-color: ${Color.Brown3};
  overflow-y: auto;

  & li {
    list-style: none;
  }
`;

export const SpanTitle = styled.span`
  text-decoration: none;
  color: ${Color.Brown1};
  font-size: ${FontSize.Medium};
  font-weight: 700;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
`;

export const ASubTitle = styled.a`
  text-decoration: none;
  color: ${Color.Brown1};
  font-size: ${FontSize.Small};
  font-weight: 400;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
`;

export const UlSubMenu = styled.ul`
  margin-top: 16px;
`;

export const LiSubMenu = styled.li`
  margin-bottom: 12px;
`;

export const LiSubMenuMap = styled.li`
  display: flex;
  user-select: none;
  margin-bottom: 12px;
  margin-left: -24px;
  font-size: ${FontSize.Small};
  color: ${Color.Brown1};
`;

export const InstrumentInputSelect = styled.div`
  color: ${Color.Brown1};
  background-color: ${Color.Brown3};
`;

export const CloseIconStyle: CSSProperties = {
  cursor: 'pointer',
  color: Color.Brown1
};

export const GraphOptionsDate = styled.div`
  position: absolute;
  top: 13%;
  right: 5%;
  width: 30%;
  /* border: 1px solid blue; */
  z-index: 3;
`;

export const GraphWindowInstrument = styled.div`
  position: absolute;
  top: 22%;
  left: 300px;
  /* right: 0; */
  width: 76%;
  /* max-width: 65%; */
  min-height: 75%;
  max-height: 480px;
  height: max-content;
  z-index: 3;
  /* overflow-y: scroll; */

  @media (max-height: 730px) {
    max-height: 50%;
  }
`;

export const DatePickerContainerStyle = styled.div`
  position: absolute;
  top: -10%;
  right: 0;
  display: flex;
  align-items: center;
`;

export const AreaOutsideChartStyle = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 20%;
  border: 1px solid blue;
`;

export const UlMenu = styled.ul`
  list-style: none;
  margin-top: 34px;
  user-select: none;
  margin-bottom: 64px;
`;
