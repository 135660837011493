import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type CreateStructureRiskAssessmentMutationVariables = Types.Exact<{
  structureId: Types.Scalars['String']['input'];
  data: Types.CreateStructureRiskAssessmentInput;
}>;


export type CreateStructureRiskAssessmentMutation = { __typename?: 'Mutation', createStructureRiskAssessment: string };


export const CreateStructureRiskAssessmentDocument = gql`
    mutation CreateStructureRiskAssessment($structureId: String!, $data: CreateStructureRiskAssessmentInput!) {
  createStructureRiskAssessment(structureId: $structureId, data: $data)
}
    `;
export type CreateStructureRiskAssessmentMutationFn = Apollo.MutationFunction<CreateStructureRiskAssessmentMutation, CreateStructureRiskAssessmentMutationVariables>;
export type CreateStructureRiskAssessmentMutationResult = Apollo.MutationResult<CreateStructureRiskAssessmentMutation>;
export type CreateStructureRiskAssessmentMutationOptions = Apollo.BaseMutationOptions<CreateStructureRiskAssessmentMutation, CreateStructureRiskAssessmentMutationVariables>;