import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type UpdateDateMutationVariables = Types.Exact<{
  inspectionId: Types.Scalars['String']['input'];
  newDate: Types.Scalars['DateTime']['input'];
}>;


export type UpdateDateMutation = { __typename?: 'Mutation', updateDate: string };


export const UpdateDateDocument = gql`
    mutation UpdateDate($inspectionId: String!, $newDate: DateTime!) {
  updateDate(inspectionId: $inspectionId, newDate: $newDate)
}
    `;
export type UpdateDateMutationFn = Apollo.MutationFunction<UpdateDateMutation, UpdateDateMutationVariables>;
export type UpdateDateMutationResult = Apollo.MutationResult<UpdateDateMutation>;
export type UpdateDateMutationOptions = Apollo.BaseMutationOptions<UpdateDateMutation, UpdateDateMutationVariables>;