import { useLazyQuery, useQuery } from '@apollo/client';
import {
  ListReadingByWaterLevelDocument,
  ListReadingByWaterLevelQuery,
  ListReadingByWaterLevelQueryVariables
} from '../../graphql/query/generated/listReadingByWaterLevel';

export function useListReadingByWaterLevels(
  structureId: string,
  endDate: Date,
  startDate: Date,
  instrumentIds: string,
  onCompleted?: () => void,
  onError?: () => void
) {
  const { data, loading, error } = useQuery<
    ListReadingByWaterLevelQuery,
    ListReadingByWaterLevelQueryVariables
  >(ListReadingByWaterLevelDocument, {
    variables: {
      structureId,
      endDate,
      startDate,
      instrumentIds
    },
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    data,
    loading,
    error
  };
}

export function useListReadingByWaterLevelsLazy(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [listReadingByWaterLevels, { data, loading, error }] = useLazyQuery<
    ListReadingByWaterLevelQuery,
    ListReadingByWaterLevelQueryVariables
  >(ListReadingByWaterLevelDocument, {
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    listReadingByWaterLevels,
    data,
    loading,
    error
  };
}
