import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type UpdateSectionChartParametersMutationVariables = Types.Exact<{
  parameters: Types.UpdateSectionChartParametersInput;
  sectionId: Types.Scalars['String']['input'];
}>;


export type UpdateSectionChartParametersMutation = { __typename?: 'Mutation', updateSectionChartParameters: string };


export const UpdateSectionChartParametersDocument = gql`
    mutation UpdateSectionChartParameters($parameters: UpdateSectionChartParametersInput!, $sectionId: String!) {
  updateSectionChartParameters(parameters: $parameters, sectionId: $sectionId)
}
    `;
export type UpdateSectionChartParametersMutationFn = Apollo.MutationFunction<UpdateSectionChartParametersMutation, UpdateSectionChartParametersMutationVariables>;
export type UpdateSectionChartParametersMutationResult = Apollo.MutationResult<UpdateSectionChartParametersMutation>;
export type UpdateSectionChartParametersMutationOptions = Apollo.BaseMutationOptions<UpdateSectionChartParametersMutation, UpdateSectionChartParametersMutationVariables>;