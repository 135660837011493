import styled from 'styled-components';
import { Color } from '../../Styles/Styles';

export const FormInspectionsContainer = styled.div`
  width: 370px;
`;

export const InspectionInputsDiv = styled.div`
  display: flex;
  /* flex-direction: column; */
  /* align-items: center; */
  justify-content: center;
  width: 100%;
  max-width: 250px; /* Define a largura máxima */
  margin: auto; /* Centraliza na horizontal */
  height: auto;
`;

export const Title = styled.h5`
  background-color: ${Color.White};
  font-size: 18px;
  font-weight: 700;
  color: ${Color.Brown1};
`;

export const InputFile = styled.div`
  padding: 15px;
  display: flex;
  gap: 10px;

  input[type='file'] {
    cursor: pointer;
    border: 1px solid ${Color.Brown1};
    padding: 0 15px 0 0;
    border-radius: 5px;
    color: ${Color.Brown1};
  }

  input[type='file']::file-selector-button {
    cursor: pointer;
    background: ${Color.Brown1};
    color: ${Color.White};
    padding: 8px;
    border-radius: 5px 0 0 5px;
    border: 0;
  }
`;

export const DivInputViewImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;
