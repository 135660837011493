import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type EditSurfaceMarkerMutationVariables = Types.Exact<{
  structureInfo: Types.StructureInfoInput;
  data: Types.EditSurfaceMarkerInput;
}>;


export type EditSurfaceMarkerMutation = { __typename?: 'Mutation', editSurfaceMarker: string };


export const EditSurfaceMarkerDocument = gql`
    mutation EditSurfaceMarker($structureInfo: StructureInfoInput!, $data: EditSurfaceMarkerInput!) {
  editSurfaceMarker(structureInfo: $structureInfo, data: $data)
}
    `;
export type EditSurfaceMarkerMutationFn = Apollo.MutationFunction<EditSurfaceMarkerMutation, EditSurfaceMarkerMutationVariables>;
export type EditSurfaceMarkerMutationResult = Apollo.MutationResult<EditSurfaceMarkerMutation>;
export type EditSurfaceMarkerMutationOptions = Apollo.BaseMutationOptions<EditSurfaceMarkerMutation, EditSurfaceMarkerMutationVariables>;