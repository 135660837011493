import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type DeleteRecordActivitiesImageMutationVariables = Types.Exact<{
  deleteRecordActivitiesImageId: Types.Scalars['String']['input'];
}>;


export type DeleteRecordActivitiesImageMutation = { __typename?: 'Mutation', deleteRecordActivitiesImage: string };


export const DeleteRecordActivitiesImageDocument = gql`
    mutation DeleteRecordActivitiesImage($deleteRecordActivitiesImageId: String!) {
  deleteRecordActivitiesImage(id: $deleteRecordActivitiesImageId)
}
    `;
export type DeleteRecordActivitiesImageMutationFn = Apollo.MutationFunction<DeleteRecordActivitiesImageMutation, DeleteRecordActivitiesImageMutationVariables>;
export type DeleteRecordActivitiesImageMutationResult = Apollo.MutationResult<DeleteRecordActivitiesImageMutation>;
export type DeleteRecordActivitiesImageMutationOptions = Apollo.BaseMutationOptions<DeleteRecordActivitiesImageMutation, DeleteRecordActivitiesImageMutationVariables>;