import { ReactElement, useState } from 'react';
import {
  AdvancedMarker,
  InfoWindow,
  Pin,
  useAdvancedMarkerRef
} from '@vis.gl/react-google-maps';
import { Color } from '../../../Styles/Styles';
import { WiRaindrops } from 'react-icons/wi';
import { FaArrowUpFromGroundWater } from 'react-icons/fa6';
import { TbArrowsUpRight } from 'react-icons/tb';
import { RiTestTubeFill } from 'react-icons/ri';
import { CustomMarkerStyle } from '../MapStyle';
import { TextInstumentsStyle } from './MapGoogle.styles';
import { MapGoogleInfoWindow } from './MapGoogleInfoWindow';

interface MarkerWithInfowindowProps {
  markInstument?: {
    id: string;
    name: string;
    lat: number;
    lon: number;
    type: string;
  };
  title?: string;
  shouldRenderIcon?: boolean;
}

export const MarkerWithInfowindow = ({
  markInstument,
  title,
  shouldRenderIcon
}: MarkerWithInfowindowProps) => {
  const [infowindowOpen, setInfowindowOpen] = useState(false);
  const [markerRef, marker] = useAdvancedMarkerRef();

  const getMarkerIcon = (type: string) => {
    const getIcon: { [key: string]: ReactElement } = {
      Pluviometer: <WiRaindrops style={CustomMarkerStyle} />,
      WaterLevel: <FaArrowUpFromGroundWater style={CustomMarkerStyle} />,
      SurfaceMarker: <TbArrowsUpRight style={CustomMarkerStyle} />,
      Piezometer: <RiTestTubeFill style={CustomMarkerStyle} />,
      Ina: <RiTestTubeFill style={CustomMarkerStyle} />
    };
    return getIcon[type];
  };

  return (
    <>
      <AdvancedMarker
        ref={markerRef}
        key={'AdvancedMarker' + markInstument!.lat}
        onClick={() => {
          shouldRenderIcon && setInfowindowOpen(!infowindowOpen);
        }}
        position={{ lat: markInstument!.lat, lng: markInstument!.lon }}
        title={title}
      >
        <Pin
          scale={shouldRenderIcon ? 1 : 0}
          background={Color.White}
          borderColor={Color.Brown1}
        >
          {shouldRenderIcon ? (
            getMarkerIcon(markInstument!.type)
          ) : (
            <TextInstumentsStyle>{markInstument!.name}</TextInstumentsStyle>
          )}
        </Pin>
      </AdvancedMarker>
      {infowindowOpen && (
        <InfoWindow
          anchor={marker}
          headerDisabled={true}
          disableAutoPan={true}
          minWidth={280}
        >
          <MapGoogleInfoWindow
            instrument={{
              type: markInstument!.type,
              id: markInstument!.id,
              name: markInstument!.name
            }}
            setInfoWindowOpen={setInfowindowOpen}
          />
        </InfoWindow>
      )}
    </>
  );
};
