import React, { Dispatch, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import ChangeLocale from '../../Components/ChangeLocale';
import { Action, ProjectContext } from '../../Context/ContextAPI';
import logoGS3 from '../../Images/logoGS3.svg';
import { LanguageSelect } from '../../Styles/LanguageSelect';
import ForgotMyPasswordModal from './ForgotMyPasswordModal';
import {
  AllRightsReserved,
  ForgotPassword,
  H1,
  DivInput,
  LoginArea,
  LoginButton,
  LoginContainer,
  LoginError,
  LoginForm,
  LoginInput,
  LoginLogo
} from './LoginScreenStyle';
import { IoMdEye, IoMdEyeOff } from 'react-icons/io';
import { toastfyError } from '../../Components/Toastify';
import { useMutation } from '@apollo/client';
import {
  LoginDocument,
  LoginMutation,
  LoginMutationVariables
} from '../../data/graphql/generated/login.mutation';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

function LoginScreen() {
  document.title = 'Login';
  const navigate = useNavigate();
  const { t: translate } = useTranslation();
  const { dispatch } = useContext(ProjectContext) as {
    dispatch: Dispatch<Action>;
  };

  const [forgotPasswordModal, setForgotPasswordModal] =
    useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const acurrentYear = new Date().getFullYear();

  const [login] = useMutation<LoginMutation, LoginMutationVariables>(
    LoginDocument
  );

  const loginSchema = z.object({
    email: z.string().email({ message: translate('emailInvalid') }),
    password: z.string().min(1, { message: translate('NeedsPassword') })
  });

  type LoginSchemaType = z.infer<typeof loginSchema>;

  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm<LoginSchemaType>({
    resolver: zodResolver(loginSchema)
  });
  const loginFunctions = async (data: LoginSchemaType) => {
    await login({
      variables: { data: { email: data.email, password: data.password } },
      errorPolicy: 'all',
      onCompleted: (data) => {
        dispatch({
          type: 'SET_USER_DATA',
          payload: {
            name: data.login.user.name,
            id: data.login.user.id,
            token: data.login.token,
            role: data.login.user.role.role,
            isLoggedWithTemporaryPassword:
              data.login.isLoggedWithTemporaryPassword,
            hasAcceptedTerms: data.login.hasAcceptedTerms
          }
        });
        localStorage.setItem(
          'user',
          JSON.stringify({
            name: data.login.user.name,
            id: data.login.user.id,
            token: data.login.token,
            role: data.login.user.role.role,
            isLoggedWithTemporaryPassword:
              data.login.isLoggedWithTemporaryPassword,
            hasAcceptedTerms: data.login.hasAcceptedTerms
          })
        );

        if (data.login.isLoggedWithTemporaryPassword === false) {
          const urlPath = localStorage.getItem('urlPath');
          if (urlPath) {
            navigate(urlPath);
            localStorage.removeItem('urlPath');
          } else {
            navigate('/');
          }
        } else {
          navigate('/resetPassword');
        }
      },
      onError: () => {
        toastfyError(translate('LoginValidate'));
      }
    });
  };

  useEffect(() => {
    localStorage.removeItem('user');
    localStorage.removeItem('userModules');
    dispatch({ type: 'CLEAR_DATA' });
  }, []);

  return (
    <>
      <LoginContainer>
        <LoginArea>
          <H1>{translate('LoginTitle')}</H1>
          <LoginForm>
            <DivInput>
              <LoginInput
                {...register('email')}
                onKeyDown={(target) =>
                  target.key === 'Enter' ? handleSubmit(loginFunctions) : null
                }
                autoComplete="off"
                placeholder={translate('User')}
                type="text"
                name="email"
              />
            </DivInput>
            {errors.email ? (
              <LoginError>{errors.email.message}</LoginError>
            ) : null}
            <DivInput>
              <LoginInput
                {...register('password')}
                onKeyDown={(target) =>
                  target.key === 'Enter' ? handleSubmit(loginFunctions) : null
                }
                autoComplete="off"
                placeholder={translate('Password')}
                type={showPassword ? 'text' : 'password'}
                name="password"
              />
              {showPassword ? (
                <IoMdEyeOff
                  style={{
                    position: 'absolute',
                    cursor: 'pointer',
                    right: '10px'
                  }}
                  onClick={() => setShowPassword(false)}
                />
              ) : (
                <IoMdEye
                  style={{
                    position: 'absolute',
                    cursor: 'pointer',
                    right: '10px'
                  }}
                  onClick={() => setShowPassword(true)}
                />
              )}
            </DivInput>
            <ForgotPassword onClick={() => setForgotPasswordModal(true)}>
              {translate('ForgotMyPassword')}
            </ForgotPassword>
            <LoginButton onClick={handleSubmit(loginFunctions)} type="submit">
              {translate('Login')}
            </LoginButton>
          </LoginForm>
        </LoginArea>
        <LoginLogo>
          <img draggable={false} src={logoGS3} alt="Logo"></img>
          <AllRightsReserved>
            © {translate('AllRightsReserved')} - {acurrentYear}
          </AllRightsReserved>
        </LoginLogo>
        <LanguageSelect>
          <ChangeLocale setShowCard={() => null} hasMargin={true} />
        </LanguageSelect>
      </LoginContainer>
      {forgotPasswordModal && (
        <ForgotMyPasswordModal
          setShowModal={setForgotPasswordModal}
          showModal={forgotPasswordModal}
        />
      )}
    </>
  );
}

export default LoginScreen;
