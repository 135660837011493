import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  ModalBody,
  ModalContainer,
  ModalContent,
  ModalFooter,
  ModalHeader,
  TabHeader
} from './ModalStep.styles';
import Icon from '../../Atoms/Icon/Icon';
import { IoIosClose } from 'react-icons/io';
import Text from '../../Atoms/Text/Text';
import Button from '../../Atoms/Button/Button';
import { ModalStepProps } from './ModalStep.interfaces';

const ModalStep = ({
  steps,
  title,
  onClose,
  width,
  height,
  editing = false,
  nextButton,
  saveButton
}: ModalStepProps) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [savedSteps, setSavedSteps] = useState<Set<number>>(
    new Set(editing ? steps.map((_, index) => index) : [0])
  );
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const modalBodyRef = useRef<HTMLDivElement>(null);

  const handleNext = async () => {
    setIsLoading(true);
    const canProceed = await steps[currentStep].onNext();
    setIsLoading(false);
    if (canProceed) {
      setSavedSteps((prev) => new Set(prev).add(currentStep + 1));
      if (currentStep < steps.length - 1) {
        setCurrentStep(currentStep + 1);
      } else {
        onClose();
      }
    }
  };

  const handlePrev = () => {
    if (steps.length === 1) {
      onClose();
      return;
    }

    setCurrentStep(currentStep - 1);
  };

  const handleSelectStep = (index: number) => {
    if (savedSteps.has(index)) {
      setCurrentStep(index);
    }
  };

  useEffect(() => {
    if (modalBodyRef.current) {
      modalBodyRef.current.scrollTop = 0;
    }
  }, [currentStep]);

  const CurrentComponent = steps[currentStep].component;

  return (
    <ModalContainer>
      <Modal width={width} height={height}>
        <ModalContent>
          <ModalHeader>
            <Text type="h2">{t(title)}</Text>
            <Icon Icon={IoIosClose} variant="large" onClick={onClose} />
          </ModalHeader>
          <TabHeader>
            {steps.map(
              (step, index) =>
                // TODO: criar button para header no padrão do atomic design
                step.name && (
                  <button
                    key={index}
                    onClick={() => handleSelectStep(index)}
                    disabled={!savedSteps.has(index)}
                    className={index === currentStep ? 'active' : ''}
                  >
                    {t(step.name)}
                  </button>
                )
            )}
          </TabHeader>
          <ModalBody ref={modalBodyRef}>{CurrentComponent}</ModalBody>
          <ModalFooter>
            <Button
              variant="secondary"
              onClick={handlePrev}
              disabled={
                steps.length === 1 ? false : currentStep === 0 || isLoading
              }
              text={
                steps.length === 1
                  ? t('Cancel').toLocaleUpperCase()
                  : t('Back').toLocaleUpperCase()
              }
            />
            <Button
              variant="primary"
              onClick={handleNext}
              disabled={isLoading}
              text={
                isLoading
                  ? `${t('Loading').toLocaleUpperCase()}...`
                  : currentStep === steps.length - 1
                    ? saveButton
                      ? t(saveButton).toLocaleUpperCase()
                      : t('Save').toLocaleUpperCase()
                    : nextButton
                      ? t(nextButton).toLocaleUpperCase()
                      : t('Advance').toLocaleUpperCase()
              }
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </ModalContainer>
  );
};

export default ModalStep;
