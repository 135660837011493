import { CreateStructureModulesInput } from '../../../../data/graphql/base-schema';

export interface ModuleData {
  modules: CreateStructureModulesInput[];
  structureId: string;
}

export const __initialModuleData = {
  modules: [],
  structureId: ''
};

export interface Mandatory {
  datePickers: boolean;
  structureId: boolean;
}

export const __initialMandatory = {
  datePickers: true,
  structureId: true
};
