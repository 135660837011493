import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type UpdateRecordRedAlertPeriodMutationVariables = Types.Exact<{
  data: Types.UpdateRecordRedAlertPeriodInput;
}>;


export type UpdateRecordRedAlertPeriodMutation = { __typename?: 'Mutation', updateRecordRedAlertPeriod: string };


export const UpdateRecordRedAlertPeriodDocument = gql`
    mutation UpdateRecordRedAlertPeriod($data: UpdateRecordRedAlertPeriodInput!) {
  updateRecordRedAlertPeriod(data: $data)
}
    `;
export type UpdateRecordRedAlertPeriodMutationFn = Apollo.MutationFunction<UpdateRecordRedAlertPeriodMutation, UpdateRecordRedAlertPeriodMutationVariables>;
export type UpdateRecordRedAlertPeriodMutationResult = Apollo.MutationResult<UpdateRecordRedAlertPeriodMutation>;
export type UpdateRecordRedAlertPeriodMutationOptions = Apollo.BaseMutationOptions<UpdateRecordRedAlertPeriodMutation, UpdateRecordRedAlertPeriodMutationVariables>;