import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ListModulesRolesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ListModulesRolesQuery = { __typename?: 'Query', listModulesRoles: Array<{ __typename?: 'ModuleRoleType', id: string, role: string }> };


export const ListModulesRolesDocument = gql`
    query ListModulesRoles {
  listModulesRoles {
    id
    role
  }
}
    `;
export type ListModulesRolesQueryResult = Apollo.QueryResult<ListModulesRolesQuery, ListModulesRolesQueryVariables>;