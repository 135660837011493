import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type DeleteRecordEquipmentAndLaborMutationVariables = Types.Exact<{
  data: Types.DeleteAtoRecordEquipmentAndLaborInput;
}>;


export type DeleteRecordEquipmentAndLaborMutation = { __typename?: 'Mutation', deleteRecordEquipmentAndLabor: string };


export const DeleteRecordEquipmentAndLaborDocument = gql`
    mutation DeleteRecordEquipmentAndLabor($data: DeleteAtoRecordEquipmentAndLaborInput!) {
  deleteRecordEquipmentAndLabor(data: $data)
}
    `;
export type DeleteRecordEquipmentAndLaborMutationFn = Apollo.MutationFunction<DeleteRecordEquipmentAndLaborMutation, DeleteRecordEquipmentAndLaborMutationVariables>;
export type DeleteRecordEquipmentAndLaborMutationResult = Apollo.MutationResult<DeleteRecordEquipmentAndLaborMutation>;
export type DeleteRecordEquipmentAndLaborMutationOptions = Apollo.BaseMutationOptions<DeleteRecordEquipmentAndLaborMutation, DeleteRecordEquipmentAndLaborMutationVariables>;