import { useLazyQuery, useQuery } from '@apollo/client';
import {
  ListReadingBySurfaceMarkersDocument,
  ListReadingBySurfaceMarkersQuery,
  ListReadingBySurfaceMarkersQueryVariables
} from '../../graphql/query/generated/listSurfaceMarkerReading';

export function useListSurfaceMarkerReading(
  endDate: Date,
  startDate: Date,
  instrumentIds: string,
  onCompleted?: () => void,
  onError?: () => void
) {
  const { data, loading, error } = useQuery<
    ListReadingBySurfaceMarkersQuery,
    ListReadingBySurfaceMarkersQueryVariables
  >(ListReadingBySurfaceMarkersDocument, {
    variables: {
      endDate,
      startDate,
      instrumentIds
    },
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    data,
    loading,
    error
  };
}

export function useListSurfaceMarkerReadingLazy(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [listReadingSurfaceMarker, { data, loading, error }] = useLazyQuery<
    ListReadingBySurfaceMarkersQuery,
    ListReadingBySurfaceMarkersQueryVariables
  >(ListReadingBySurfaceMarkersDocument, {
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    listReadingSurfaceMarker,
    data,
    loading,
    error
  };
}
