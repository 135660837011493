import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type FindGistmCommentsQueryVariables = Types.Exact<{
  gistmId: Types.Scalars['String']['input'];
  structureId: Types.Scalars['String']['input'];
}>;


export type FindGistmCommentsQuery = { __typename?: 'Query', findGistmComments: Array<{ __typename?: 'ListGistmCommentsType', comment: string, createdAt: Date, id: string, userName: string }> };


export const FindGistmCommentsDocument = gql`
    query FindGistmComments($gistmId: String!, $structureId: String!) {
  findGistmComments(gistmId: $gistmId, structureId: $structureId) {
    comment
    createdAt
    id
    userName
  }
}
    `;
export type FindGistmCommentsQueryResult = Apollo.QueryResult<FindGistmCommentsQuery, FindGistmCommentsQueryVariables>;