import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type EditSurfaceMarkersReadingMutationVariables = Types.Exact<{
  structureInfo: Types.StructureInfoInput;
  data: Types.EditSurfaceMarkersReadingInput;
}>;


export type EditSurfaceMarkersReadingMutation = { __typename?: 'Mutation', editSurfaceMarkersReading: string };


export const EditSurfaceMarkersReadingDocument = gql`
    mutation EditSurfaceMarkersReading($structureInfo: StructureInfoInput!, $data: EditSurfaceMarkersReadingInput!) {
  editSurfaceMarkersReading(structureInfo: $structureInfo, data: $data)
}
    `;
export type EditSurfaceMarkersReadingMutationFn = Apollo.MutationFunction<EditSurfaceMarkersReadingMutation, EditSurfaceMarkersReadingMutationVariables>;
export type EditSurfaceMarkersReadingMutationResult = Apollo.MutationResult<EditSurfaceMarkersReadingMutation>;
export type EditSurfaceMarkersReadingMutationOptions = Apollo.BaseMutationOptions<EditSurfaceMarkersReadingMutation, EditSurfaceMarkersReadingMutationVariables>;