import React, { useEffect, useState } from 'react';
import Text from '../../Atoms/Text/Text';
import {
  ErrorStyled,
  InputContainer,
  InputMaskStyle,
  InputStyled
} from './InputMask.styles';
import { InputMaskProps } from './InputMask.interfaces';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const InputMask = ({
  label,
  onChange,
  error,
  errorMessage,
  onKeyDown,
  name,
  value: propValue,
  mask,
  width,
  control,
  disabled,
  required
}: InputMaskProps) => {
  const { t: translate } = useTranslation();
  const [value, setValue] = useState(propValue || '');
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    setValue(propValue || '');
  }, [propValue]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <InputContainer>
      {control ? (
        <Controller
          name={name}
          control={control}
          defaultValue={''}
          render={({ field }) => (
            <InputStyled hasValue={!!field.value || isFocused}>
              <InputMaskStyle
                id={name}
                name={name}
                type="text"
                width={width}
                error={error}
                onKeyDown={onKeyDown}
                value={
                  field.value as string | number | readonly string[] | undefined
                }
                onChange={field.onChange}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                mask={mask}
              />
              <Text
                type="label"
                size="medium"
                htmlFor={name}
                error={error}
                color={disabled ? 'disabled' : 'brown'}
              >
                {label} {required && '*'}
              </Text>
              {error ? (
                <ErrorStyled>{errorMessage}</ErrorStyled>
              ) : (
                <ErrorStyled />
              )}
            </InputStyled>
          )}
        />
      ) : (
        <InputStyled hasValue={!!value || isFocused}>
          <InputMaskStyle
            id={name}
            name={name}
            type="text"
            width={width}
            error={error}
            onKeyDown={onKeyDown}
            value={value}
            onChange={handleChange}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            mask={mask}
          />
          <Text type="label" size="medium" htmlFor={name} error={error}>
            {label}
          </Text>
          {error ? (
            <ErrorStyled>{translate(errorMessage)}</ErrorStyled>
          ) : (
            <ErrorStyled />
          )}
        </InputStyled>
      )}
    </InputContainer>
  );
};

export default InputMask;
