import React, { useEffect, useState } from 'react';
import {
  CustomRegisterInputArea,
  CustomRegisterInstrumentArea,
  CustomRegisterInstrumentTitle,
  DivShowLevel
} from '../RegisterInstrumentStyle';
import { useTranslation } from 'react-i18next';
import PiezometersModel from '../../../../Models/PiezometerModel';
import { DefaultInput } from '../../../Inputs/InputsStyle';
import { DatePickerComponent } from '../../../DatePicker';
import { CreatePiezometerAlertLevelInput } from '../../../../data/graphql/base-schema';
import { toastfyError } from '../../../Toastify';
import ToastifyModel from '../../../../Models/ToastifyModel';
import { FaCirclePlus } from 'react-icons/fa6';
import { OptionLevel, SelectLevel } from './addLevelStyles';

interface IFormData {
  name: string;
  value1: string;
  signal1: string;
  signal2: string;
  value2: string;
  coteValue: number;
  isDrained: string;
  error?: boolean;
}

export const AddLevelControlComponent = ({
  setControlLevels,
  registerDate,
  setRegisterDate,
  instrumentEspecificData,
  setInstrumentEspecificData,
  setAlertLevels
}: any) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<IFormData>({
    name: instrumentEspecificData.formData?.name || '',
    value1: instrumentEspecificData.formData?.value1 || '',
    signal1: instrumentEspecificData.formData?.signal1 || '',
    signal2: instrumentEspecificData.formData?.signal2 || '',
    value2: instrumentEspecificData.formData?.value2 || '',
    coteValue: instrumentEspecificData.formData?.coteValue || 0,
    isDrained: 'drained', // Default to drained
    error: instrumentEspecificData.formData?.error || false
  });
  const [showAddLevel, setShowAddLevel] = useState<boolean>(false);

  const handleOnChange = (field: string, value: any) => {
    setFormData({
      ...formData,
      [field]: value
    });
  };

  const handleAdd = () => {
    if (
      !formData.name ||
      (!formData.value1 && !formData.value2) ||
      (!formData.signal1 && !formData.signal2) ||
      !formData.coteValue
    ) {
      setFormData({
        ...formData,
        error: true
      });
      toastfyError(t(ToastifyModel().toastifyMessage.fillRequiredFields));
      return;
    }
    // Verificar se o zero pode entrar como valor de operador
    if (
      (formData.value1 && !formData.signal1) ||
      (formData.value2 && !formData.signal2)
    ) {
      toastfyError(
        t(
          'Para adicionar dois valores ao fator de segurança, são necessários dois operadores de comparação.'
        )
      );
      return;
    }

    if (
      (!formData.value1 && formData.signal1) ||
      (!formData.value2 && formData.signal2)
    ) {
      toastfyError(
        t(
          'Para adicionar dois operadores de comparação ao fator de segurança, são necessários dois valores.'
        )
      );
      return;
    }

    // Remover espaços extras quando N/A for selecionado
    const signal1 = formData.signal1 === '' ? '' : formData.signal1;
    const signal2 = formData.signal2 === '' ? '' : formData.signal2;

    const levelValue =
      `${formData.value1} ${signal1} FS ${signal2} ${formData.value2}`
        .replace(/\s+/g, ' ')
        .trim();

    const ControlLevelData: CreatePiezometerAlertLevelInput = {
      name: formData.name,
      drained: formData.isDrained === 'drained' ? levelValue : null,
      undrained: formData.isDrained === 'undrained' ? levelValue : null,
      coteValue: formData.coteValue
    };
    setControlLevels((prev: CreatePiezometerAlertLevelInput[]) => [
      ...prev,
      ControlLevelData
    ]);
    setAlertLevels({
      label: 'Outro',
      id: 'Other',
      value: 'Other'
    });

    setFormData({
      name: '',
      value1: '',
      signal1: '',
      signal2: '',
      value2: '',
      coteValue: 0,
      isDrained: 'drained',
      error: false
    });
  };

  useEffect(() => {
    setInstrumentEspecificData('formData', formData);
  }, [formData]);

  return (
    <CustomRegisterInstrumentArea>
      <CustomRegisterInstrumentTitle
        style={{ cursor: 'pointer' }}
        onClick={() => setShowAddLevel(!showAddLevel)}
      >
        {showAddLevel ? '-' : '+'} {t('AddCustomLevel')}
      </CustomRegisterInstrumentTitle>
      {
        <DivShowLevel showAddLevel={showAddLevel}>
          <DefaultInput
            placeholder={t('LevelName')}
            value={formData.name}
            onChange={(e) => handleOnChange('name', e.target.value)}
            error={!formData.name && formData.error}
          />
          <CustomRegisterInputArea></CustomRegisterInputArea>
          <CustomRegisterInputArea>
            <DefaultInput
              type="number"
              placeholder={`${t('Value')}`}
              value={formData.value1}
              onChange={(e) => handleOnChange('value1', e.target.value)}
              error={!formData.value1 && formData.error}
            />
            <SelectLevel
              value={formData.signal1}
              onChange={(e) => handleOnChange('signal1', e.target.value)}
            >
              {PiezometersModel().signals.map((signal) => (
                <OptionLevel key={signal.id} value={signal.value}>
                  {signal.label}
                </OptionLevel>
              ))}
            </SelectLevel>
            <SelectLevel
              value={formData.isDrained}
              onChange={(e) => handleOnChange('isDrained', e.target.value)}
            >
              <OptionLevel value="drained">{t(' FS Drenado')}</OptionLevel>
              <OptionLevel value="undrained">{t('FS Não Drenado')}</OptionLevel>
            </SelectLevel>
            <SelectLevel
              value={formData.signal2}
              onChange={(e) => handleOnChange('signal2', e.target.value)}
            >
              {PiezometersModel().signals.map((signal) => (
                <OptionLevel key={signal.id} value={signal.value}>
                  {signal.label}
                </OptionLevel>
              ))}
            </SelectLevel>
            <DefaultInput
              placeholder={`${t('Value')}`}
              value={formData.value2}
              onChange={(e) => handleOnChange('value2', e.target.value)}
              error={!formData.value2 && formData.error}
            />
          </CustomRegisterInputArea>
          <DefaultInput
            type="number"
            placeholder={`${t('Valor da Cota')}`}
            value={formData.coteValue}
            onChange={(e) =>
              handleOnChange('coteValue', parseFloat(e.target.value))
            }
            error={!formData.coteValue && formData.error}
          />
          <div style={{}}>
            {t('Date')}:
            <DatePickerComponent
              startDate={registerDate}
              setStartDate={setRegisterDate}
            />
          </div>
          <div
            onClick={handleAdd}
            style={{
              padding: '5px 10px 5px 0px',
              display: 'flex',
              alignItems: 'center',
              columnGap: '5px',
              cursor: 'pointer'
            }}
          >
            <FaCirclePlus size={18} color="#32CD32" />
            <span>{t('AddLevel')}</span>
          </div>
        </DivShowLevel>
      }
    </CustomRegisterInstrumentArea>
  );
};
