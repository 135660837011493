import { PropsWithChildren } from 'react';

import { TableRowProps } from './TableHeaderRow.interface';
import { TableRowContent } from './TableHeaderRow.styles';

export const TableHeaderRow = ({
  children
}: PropsWithChildren<TableRowProps>) => {
  return <TableRowContent>{children}</TableRowContent>;
};
