import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ListReadingByPiezometerQueryVariables = Types.Exact<{
  endDate: Types.Scalars['DateTime']['input'];
  startDate: Types.Scalars['DateTime']['input'];
  instrumentIds: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
}>;


export type ListReadingByPiezometerQuery = { __typename?: 'Query', listReadingByPiezometer: Array<{ __typename?: 'ChartPiezometerReadingType', instrumentId: string, name: string, readings: Array<{ __typename?: 'PiezometerInaReadingValuesType', id: string, date: Date, cote: number, isDry: boolean }>, cotes: Array<{ __typename?: 'ControlCotesType', topCote: number, bottomCote: number, referenceDate: Date }>, alertLevels: Array<{ __typename?: 'PiezometerInaAlertLevelsType', name: string, drained?: string | null, undrained?: string | null, coteValue: number }> }> };


export const ListReadingByPiezometerDocument = gql`
    query ListReadingByPiezometer($endDate: DateTime!, $startDate: DateTime!, $instrumentIds: [String!]!) {
  listReadingByPiezometer(
    endDate: $endDate
    startDate: $startDate
    instrumentIds: $instrumentIds
  ) {
    instrumentId
    name
    readings {
      id
      date
      cote
      isDry
    }
    cotes {
      topCote
      bottomCote
      referenceDate
    }
    alertLevels {
      name
      drained
      undrained
      coteValue
    }
  }
}
    `;
export type ListReadingByPiezometerQueryResult = Apollo.QueryResult<ListReadingByPiezometerQuery, ListReadingByPiezometerQueryVariables>;