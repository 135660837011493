import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type UpdateConservationStateMutationVariables = Types.Exact<{
  inspectionId: Types.Scalars['String']['input'];
  newData: Array<Types.UpdateConservationStateInput> | Types.UpdateConservationStateInput;
}>;


export type UpdateConservationStateMutation = { __typename?: 'Mutation', updateConservationState: string };


export const UpdateConservationStateDocument = gql`
    mutation UpdateConservationState($inspectionId: String!, $newData: [UpdateConservationStateInput!]!) {
  updateConservationState(inspectionId: $inspectionId, newData: $newData)
}
    `;
export type UpdateConservationStateMutationFn = Apollo.MutationFunction<UpdateConservationStateMutation, UpdateConservationStateMutationVariables>;
export type UpdateConservationStateMutationResult = Apollo.MutationResult<UpdateConservationStateMutation>;
export type UpdateConservationStateMutationOptions = Apollo.BaseMutationOptions<UpdateConservationStateMutation, UpdateConservationStateMutationVariables>;