import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ListInstrumentsByStructureQueryVariables = Types.Exact<{
  structureInfo: Types.StructureInfoInput;
}>;


export type ListInstrumentsByStructureQuery = { __typename?: 'Query', listInstrumentsByStructure: Array<{ __typename?: 'ListAllInstrumentsType', typesList: Array<{ __typename?: 'ListInstrumentsByTypeType', totalActive: number, totalInactive: number, type: string, instruments: Array<{ __typename?: 'InfosInstrumentsType', id: string, name: string, operationalStatus: Types.InstrumentStatusEnum, coordinateE: number, coordinateN: number, lat: number, lon: number }> }>, sectionsList: Array<{ __typename?: 'ListInstrumentsBySectionsType', name: string, id: string, type: string, finalCoordinateE: number, finalCoordinateN: number, initialCoordinateE: number, initialCoordinateN: number, finalLat: number, finalLon: number, initialLat: number, initialLon: number, typesList: Array<{ __typename?: 'ListInstrumentsByTypeType', type: string, totalActive: number, totalInactive: number, instruments: Array<{ __typename?: 'InfosInstrumentsType', id: string, name: string, operationalStatus: Types.InstrumentStatusEnum, coordinateE: number, coordinateN: number, lat: number, lon: number }> }> }> }> };


export const ListInstrumentsByStructureDocument = gql`
    query ListInstrumentsByStructure($structureInfo: StructureInfoInput!) {
  listInstrumentsByStructure(structureInfo: $structureInfo) {
    typesList {
      instruments {
        id
        name
        operationalStatus
        coordinateE
        coordinateN
        lat
        lon
      }
      totalActive
      totalInactive
      type
    }
    sectionsList {
      typesList {
        type
        instruments {
          id
          name
          operationalStatus
          coordinateE
          coordinateN
          lat
          lon
        }
        totalActive
        totalInactive
      }
      name
      id
      type
      finalCoordinateE
      finalCoordinateN
      initialCoordinateE
      initialCoordinateN
      finalLat
      finalLon
      initialLat
      initialLon
    }
  }
}
    `;
export type ListInstrumentsByStructureQueryResult = Apollo.QueryResult<ListInstrumentsByStructureQuery, ListInstrumentsByStructureQueryVariables>;