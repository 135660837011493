import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ListSurfaceMarkersByStructureQueryVariables = Types.Exact<{
  structureInfo: Types.StructureInfoInput;
}>;


export type ListSurfaceMarkersByStructureQuery = { __typename?: 'Query', listSurfaceMarkersByStructure: Array<{ __typename?: 'ListSurfaceMarkerType', id?: string | null, activationDate?: Date | null, coordinateE: number, coordinateN: number, isUTM: boolean, installLocation?: string | null, installationDate?: Date | null, name: string, readingType: string, status: boolean, operationalStatus: Types.InstrumentStatusEnum, deactivationDate?: Date | null, type?: { __typename?: 'InstrumentType', id: string, instrumentType: string } | null, surfaceMarker?: { __typename?: 'SurfaceMarkerType', id: string } | null }> };


export const ListSurfaceMarkersByStructureDocument = gql`
    query ListSurfaceMarkersByStructure($structureInfo: StructureInfoInput!) {
  listSurfaceMarkersByStructure(structureInfo: $structureInfo) {
    id
    activationDate
    coordinateE
    coordinateN
    isUTM
    installLocation
    installationDate
    name
    readingType
    status
    operationalStatus
    deactivationDate
    type {
      id
      instrumentType
    }
    surfaceMarker {
      id
    }
  }
}
    `;
export type ListSurfaceMarkersByStructureQueryResult = Apollo.QueryResult<ListSurfaceMarkersByStructureQuery, ListSurfaceMarkersByStructureQueryVariables>;