import React from 'react';
import {
  DivContinerPopup,
  DivDontReadings,
  MapPopUpContentDataStyle,
  MapPopUpContentStyle,
  MapPopUpHeaderStyle,
  SpanInstrumentPopUpStyle
} from './MapGoogle.styles';
import { IoIosClose } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import {
  ListLatestReadingByPluviometerDocument,
  ListLatestReadingByPluviometerQuery,
  ListLatestReadingByPluviometerQueryVariables
} from '../../../data/graphql/query/generated/listLatesReadingByPluviometer.query';
import { MapGoogleInfoWindowProps } from './MapGoogleInfoWindow';
import Skeleton from 'react-loading-skeleton';
import Icon from '../../V2/Atoms/Icon/Icon';
import Text from '../../V2/Atoms/Text/Text';

export const PluviometerInfoWindow = ({
  instrument,
  setInfoWindowOpen
}: MapGoogleInfoWindowProps) => {
  const { t: translation } = useTranslation();

  const { data: lastInstrumentData, loading } = useQuery<
    ListLatestReadingByPluviometerQuery,
    ListLatestReadingByPluviometerQueryVariables
  >(ListLatestReadingByPluviometerDocument, {
    variables: {
      instrumentId: instrument.id
    },
    fetchPolicy: 'no-cache'
  });

  const instrumentData = [
    {
      label: 'Type',
      value: instrument.type
    },
    {
      label: 'Status',
      value:
        lastInstrumentData?.listLatestReadingByPluviometer.operationalStatus
    },
    {
      label: 'LastReadingDate',
      value:
        lastInstrumentData?.listLatestReadingByPluviometer.date &&
        new Date(
          lastInstrumentData?.listLatestReadingByPluviometer.date
        ).toLocaleDateString()
    },
    {
      label: 'LastReadingValue',
      value: lastInstrumentData?.listLatestReadingByPluviometer.rainfall
    },
    {
      label: 'Observation',
      value: lastInstrumentData?.listLatestReadingByPluviometer.observation
    },
    {
      label: 'ControlLevel',
      value: lastInstrumentData?.listLatestReadingByPluviometer.alertLevels
    }
  ];

  return (
    <>
      {loading ? (
        <Skeleton count={7} height={35} />
      ) : (
        <DivContinerPopup>
          <MapPopUpHeaderStyle>
            <Text type="h5">{instrument.name}</Text>
            <Icon
              variant={'medium'}
              onClick={() => setInfoWindowOpen(false)}
              Icon={IoIosClose}
            />
          </MapPopUpHeaderStyle>
          <MapPopUpContentStyle>
            {lastInstrumentData ? (
              instrumentData.map(({ label, value }) => (
                <MapPopUpContentDataStyle key={label}>
                  <SpanInstrumentPopUpStyle>
                    <Text type="p" color="black" size="small">
                      {translation(label)}
                    </Text>
                  </SpanInstrumentPopUpStyle>
                  <SpanInstrumentPopUpStyle>
                    <Text type="p" color="black" size="small">
                      {translation(value) || '-'}
                    </Text>
                  </SpanInstrumentPopUpStyle>
                </MapPopUpContentDataStyle>
              ))
            ) : (
              <DivDontReadings>
                <Text type="p">
                  {translation('dontHaveReadingsForThisInstrument')}
                </Text>
              </DivDontReadings>
            )}
          </MapPopUpContentStyle>
        </DivContinerPopup>
      )}
    </>
  );
};
