import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const toastfyError: (message: string) => void = (message: string) => {
  toast.error(message, {
    toastId: 'toastError',
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    closeButton: false
  });
};

export const toastfySuccess: (message: string) => void = (message: string) => {
  toast.success(message, {
    toastId: 'toastSuccess',
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    closeButton: false
  });
};

export const toastifyLoading: (message: string) => void = (message: string) => {
  toast.info(message, {
    toastId: 'toastLoading',
    position: toast.POSITION.TOP_RIGHT, // Posição do toast
    autoClose: 5000, // Tempo de fechamento automático em milissegundos
    hideProgressBar: true, // Ocultar barra de progresso
    closeOnClick: true, // Fechar toast ao clicar
    pauseOnHover: false, // Pausar temporariamente ao passar o mouse
    draggable: true, // Permitir arrastar o toast
    progress: undefined, // Progresso personalizado para a barra
    closeButton: false
  });
};

export const toastfyDimiss: (props: string) => void = (className) => {
  toast.dismiss(className);
};
