import { useMutation } from '@apollo/client';
import {
  CreateStructureRiskAssessmentDocument,
  CreateStructureRiskAssessmentMutation,
  CreateStructureRiskAssessmentMutationVariables
} from '../../graphql/generated/createStructureRiskAssessment.mutation';
import { ListCompaniesDocument } from '../../graphql/query/generated/listCompanies.query';
import { ListCompanyRelatedUsersAndStructuresDocument } from '../../graphql/query/generated/listCompanyRelatedUsersAndStructures.query';
import { ListModulesRolesDocument } from '../../graphql/query/generated/listModulesRole.query';
import { ListStructuresDocument } from '../../graphql/query/generated/listStructures.query';
import { ListUsersDocument } from '../../graphql/query/generated/listUsers.query';
import { GetModuleByStructureDocument } from '../../graphql/query/generated/modulesByStructure.query';
import { GetUserStructuresListDocument } from '../../graphql/query/generated/structureList.query';
import { GetStructureSummaryDocument } from '../../graphql/query/generated/structureSummary.query';

export function useCreateStructureRiskAssessment(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [createStructureRiskAssessment, { data, error, loading }] = useMutation<
    CreateStructureRiskAssessmentMutation,
    CreateStructureRiskAssessmentMutationVariables
  >(CreateStructureRiskAssessmentDocument, {
    refetchQueries: [
      ListStructuresDocument,
      ListCompaniesDocument,
      ListUsersDocument,
      ListModulesRolesDocument,
      GetUserStructuresListDocument,
      ListCompanyRelatedUsersAndStructuresDocument,
      GetStructureSummaryDocument,
      GetModuleByStructureDocument
    ],
    awaitRefetchQueries: true,
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    createStructureRiskAssessment,
    data,
    error,
    loading
  };
}
