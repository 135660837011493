import { useReducer } from 'react';

interface ReducerProps {
  type: string;
  payload: { [key in any]: any };
}
interface State {
  [key: string]: any;
}

const reducerFunction = (state: State, action: ReducerProps) => {
  switch (action.type) {
    case action.type:
      return {
        ...state,
        [action.type]: action.payload[action.type]
      };
    default:
      return state;
  }
};

export const useReducerHook = (initialState: State) => {
  const [state, dispatch] = useReducer(reducerFunction, initialState);
  const updateValue = (key: string, value: any) => {
    dispatch({
      type: key,
      payload: { [key]: value }
    });
  };
  return {
    state,
    updateValue
  };
};
