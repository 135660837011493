import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type EditPluviometerReadingMutationVariables = Types.Exact<{
  structureInfo: Types.StructureInfoInput;
  data: Types.EditPluviometerReadingInput;
}>;


export type EditPluviometerReadingMutation = { __typename?: 'Mutation', editPluviometerReading: string };


export const EditPluviometerReadingDocument = gql`
    mutation EditPluviometerReading($structureInfo: StructureInfoInput!, $data: EditPluviometerReadingInput!) {
  editPluviometerReading(structureInfo: $structureInfo, data: $data)
}
    `;
export type EditPluviometerReadingMutationFn = Apollo.MutationFunction<EditPluviometerReadingMutation, EditPluviometerReadingMutationVariables>;
export type EditPluviometerReadingMutationResult = Apollo.MutationResult<EditPluviometerReadingMutation>;
export type EditPluviometerReadingMutationOptions = Apollo.BaseMutationOptions<EditPluviometerReadingMutation, EditPluviometerReadingMutationVariables>;