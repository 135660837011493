import React, { useContext } from 'react';
import Select from 'react-select';
import {
  StructuresDiv,
  StyleStructureSelect
} from '../Styles/StructureSelectStyle';
import { useTranslation } from 'react-i18next';
import { ProjectContext } from '../Context/ContextAPI';
import { StructureSelectType } from '../@Types/types';
import { useNavigate, useParams } from 'react-router-dom';

function StructureSelect(propsStyles?: any) {
  const { t } = useTranslation();
  const { structures } = useContext(ProjectContext) as {
    structures: StructureSelectType[];
  };
  const { structureId } = useParams();
  const navigate = useNavigate();

  const handleStructureSelected = (structure: StructureSelectType) => {
    if (structure) {
      const currentPath = location.pathname;
      const pathParts = currentPath.split('/');
      pathParts[1] = structure.id;
      const newPath = pathParts.join('/');
      navigate(newPath);
    }
  };

  return (
    <StructuresDiv
      style={{ position: propsStyles.styles ? 'unset' : 'absolute' }}
    >
      <Select
        styles={propsStyles.styles || StyleStructureSelect}
        placeholder={
          structures.find((structure) => structure.id === structureId)?.label ||
          t('SelectAStructure')
        }
        noOptionsMessage={() => t('NoOptions')}
        isSearchable={false}
        hideSelectedOptions
        value={
          structures.find((structure) => structure.id === structureId) || ''
        }
        onChange={(e) => handleStructureSelected(e as StructureSelectType)}
        options={structures}
        maxMenuHeight={200}
        controlShouldRenderValue={propsStyles.styles ? true : false}
      />
    </StructuresDiv>
  );
}

export default StructureSelect;
