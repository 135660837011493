import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type EditStructureDamRaisingMutationVariables = Types.Exact<{
  data: Types.EditStructureDamRaisingInput;
}>;


export type EditStructureDamRaisingMutation = { __typename?: 'Mutation', editStructureDamRaising: string };


export const EditStructureDamRaisingDocument = gql`
    mutation EditStructureDamRaising($data: editStructureDamRaisingInput!) {
  editStructureDamRaising(data: $data)
}
    `;
export type EditStructureDamRaisingMutationFn = Apollo.MutationFunction<EditStructureDamRaisingMutation, EditStructureDamRaisingMutationVariables>;
export type EditStructureDamRaisingMutationResult = Apollo.MutationResult<EditStructureDamRaisingMutation>;
export type EditStructureDamRaisingMutationOptions = Apollo.BaseMutationOptions<EditStructureDamRaisingMutation, EditStructureDamRaisingMutationVariables>;