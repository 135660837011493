import styled from 'styled-components';

export const HolderCards = styled.div`
  width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
`;

export const DivContainerSquareCardList = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-between;
`;

export const DivContainerTable = styled.div`
  width: 90%;
`;