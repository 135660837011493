import * as Types from '../base-schema';

import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';
export type AssociateActionPlansMutationVariables = Types.Exact<{
  parentId: Types.Scalars['String']['input'];
  actionPlanId: Types.Scalars['String']['input'];
}>;

export type AssociateActionPlansMutation = {
  __typename?: 'Mutation';
  associateActionPlans: string;
};

export const AssociateActionPlansDocument = gql`
  mutation AssociateActionPlans($parentId: String!, $actionPlanId: String!) {
    associateActionPlans(parentId: $parentId, actionPlanId: $actionPlanId)
  }
`;

export type AssociateActionPlansMutationFn = Apollo.MutationFunction<
  AssociateActionPlansMutation,
  AssociateActionPlansMutationVariables
>;

export type AssociateActionPlansMutationResult =
  Apollo.MutationResult<AssociateActionPlansMutation>;

export type AssociateActionPlansMutationOptions = Apollo.BaseMutationOptions<
  AssociateActionPlansMutation,
  AssociateActionPlansMutationVariables
>;
