import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IoSearch } from 'react-icons/io5';
import Icon from '../../Atoms/Icon/Icon';
import InputText from '../../Molecules/InputText/InputText';
import { InputSearchProps } from './InputSearch.interface';
import { SearchContainer, SearchInput } from './InputSearch.styles';
import { useDebounceCallback } from 'usehooks-ts';
import { useSearchParams } from 'react-router-dom';

const InputSearch = ({ onSearch }: InputSearchProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [query, setQuery] = useState('');
  const { t: translate } = useTranslation();

  const [, setSearchParams] = useSearchParams();

  const handleInputChange = useDebounceCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setQuery(event.target.value);
      onSearch(event.target.value);
      setSearchParams((prevParams) => {
        const newParams = new URLSearchParams(prevParams);
        newParams.set('generalSearchString', event.target.value);
        return newParams;
      });
    },
    600
  );

  return (
    <SearchContainer isExpanded={isExpanded}>
      <Icon
        Icon={IoSearch}
        onClick={() => setIsExpanded(!isExpanded)}
        variant="small"
      />
      <SearchInput isExpanded={isExpanded}>
        <InputText
          type="text"
          value={query}
          onChange={handleInputChange}
          label={translate('search')}
          name="search"
        />
      </SearchInput>
    </SearchContainer>
  );
};

export default InputSearch;
