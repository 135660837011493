import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ListReadingByWaterLevelQueryVariables = Types.Exact<{
  structureId: Types.Scalars['String']['input'];
  endDate: Types.Scalars['DateTime']['input'];
  startDate: Types.Scalars['DateTime']['input'];
  instrumentIds: Array<Types.Scalars['String']['input']> | Types.Scalars['String']['input'];
}>;


export type ListReadingByWaterLevelQuery = { __typename?: 'Query', listReadingByWaterLevel: Array<{ __typename?: 'ChartWaterLevelReadingType', instrumentId: string, name: string, spillwaySill?: number | null, readings: Array<{ __typename?: 'BaseWaterLevelReadingType', id: string, value: number, date: Date, observation?: string | null }> }> };


export const ListReadingByWaterLevelDocument = gql`
    query ListReadingByWaterLevel($structureId: String!, $endDate: DateTime!, $startDate: DateTime!, $instrumentIds: [String!]!) {
  listReadingByWaterLevel(
    structureId: $structureId
    endDate: $endDate
    startDate: $startDate
    instrumentIds: $instrumentIds
  ) {
    instrumentId
    name
    spillwaySill
    readings {
      id
      value
      date
      observation
    }
  }
}
    `;
export type ListReadingByWaterLevelQueryResult = Apollo.QueryResult<ListReadingByWaterLevelQuery, ListReadingByWaterLevelQueryVariables>;