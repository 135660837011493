import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ListSectionsByStructureDocument,
  ListSectionsByStructureQuery,
  ListSectionsByStructureQueryVariables
} from '../../../../data/graphql/query/generated/listSectionsByStructure.query';
import Button from '../../Atoms/Button/Button';
import Text from '../../Atoms/Text/Text';
import DatepickerInput from '../../Molecules/DatepickerInput/DatepickerInput';
import MenuRadio from '../../Molecules/MenuRadio/MenuRadio';
import SimpleContentTemplate from '../../Templates/SimpleContentTemplate/SimpleContentTemplate';
import {
  DivContainerStability,
  DivContentStability,
  DivGraph,
  DivHeaderStability
} from './StabilityPage.styles';

const StabilityPage = () => {
  const mockData = {
    typeOfAnalysis: 'Stability',
    methodOfSearch: 'methodOfSearch',
    methodOfCalc: 'methodOfCalc',
    condition: 'drenado',
    tSuper: 'n-circular'
  };
  const { t: translate } = useTranslation();
  const { structureId } = useParams();
  const navigate = useNavigate();

  const {
    data: listSections,
    loading,
    error
  } = useQuery<
    ListSectionsByStructureQuery,
    ListSectionsByStructureQueryVariables
  >(ListSectionsByStructureDocument, {
    variables: {
      structureId: structureId ?? ''
    }
  });

  const optionsSections = listSections?.listSectionsByStructure?.map(
    (section) => ({
      label: section.name,
      onClick: () => {},
      value: section.id
    })
  );

  const navigateTo = () => {
    navigate(`/${structureId}/stability/history`);
  };

  return (
    <SimpleContentTemplate
      loading={loading}
      title={<Text type={'h1'}>{translate('Stability')}</Text>}
      button={
        <Button
          variant={'secondary'}
          text={translate('history')}
          onClick={navigateTo}
        />
      }
      content={
        <DivContainerStability>
          <MenuRadio
            options={optionsSections ?? []}
            title={translate('Sections')}
          />
          <div>
            <DivContentStability>
              <Text type={'h4'}>{translate('File')}</Text>
              {Object.entries(mockData).map(([key, value], index) => (
                <DivHeaderStability key={index}>
                  <Text type={'p'} color={'brown'}>
                    {key + ': '}
                  </Text>
                  <Text type={'p'} color={'black'}>
                    {value}
                  </Text>
                </DivHeaderStability>
              ))}
              <DatepickerInput
                label={translate('startDate')}
                name="startDate"
                placeholder={translate('startDate')}
                time={false}
              />
            </DivContentStability>
            <DivGraph>
              <Button variant={'secondary'} text={translate('GenerateGraph')} />
              <img
                src={require('./../../../../Images/tellusLogo.png')}
                alt=""
              />
            </DivGraph>
          </div>
        </DivContainerStability>
      }
    />
  );
};

export default StabilityPage;
