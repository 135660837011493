import { useMutation } from '@apollo/client';
import {
  DeleteParticipantDocument,
  DeleteParticipantMutation,
  DeleteParticipantMutationVariables
} from '../graphql/generated/deleteParticipant';

export function useDeleteParticipant(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [deleteParticipant, { data, error, loading }] = useMutation<
    DeleteParticipantMutation,
    DeleteParticipantMutationVariables
  >(DeleteParticipantDocument, {
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    deleteParticipant,
    data,
    error,
    loading
  };
}
