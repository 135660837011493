import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Text from '../../Atoms/Text/Text';
import Textarea from '../../Atoms/Textarea/Textarea';
import { InputTextareaProps } from './InputTextarea.interfaces';
import {
  DivLabel,
  ErrorStyled,
  TextareaContainer,
  TextareaStyled
} from './InputTextarea.styles';

const InputTextarea = ({
  label,
  onChange,
  error,
  errorMessage,
  name,
  register,
  value: propValue,
  width,
  height,
  disabled,
  required
}: InputTextareaProps) => {
  const { t: translate } = useTranslation();
  const [value, setValue] = useState(propValue || '');

  useEffect(() => {
    setValue(propValue || '');
  }, [propValue]);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value);
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <TextareaContainer>
      <DivLabel>
        <Text type="label" htmlFor={name} error={error} disabled={disabled}>
          {label} {required && '*'}
        </Text>
      </DivLabel>
      <TextareaStyled>
        <Textarea
          id={name}
          name={name}
          error={error}
          width={width}
          height={height}
          value={value}
          register={register}
          onChange={handleChange}
          disabled={disabled}
        />
      </TextareaStyled>
      {error ? (
        <ErrorStyled>{translate(errorMessage)}</ErrorStyled>
      ) : (
        <ErrorStyled />
      )}
    </TextareaContainer>
  );
};

export default InputTextarea;
