import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsCheckLg } from 'react-icons/bs';
import { IoIosClose } from 'react-icons/io';
import { PiAsteriskBold } from 'react-icons/pi';
import {
  AdvanceButtonStyled,
  CancelButtonStyled
} from '../../../Components/Buttons/ButtonsStyle';
import { DatePickerComponent } from '../../../Components/DatePicker';
import { CloseIconStyle } from '../../../Components/Graphs/Monitoring/Instrument/StyleInstrumentGraph';
import {
  RegisterInstrumentBackground,
  RegisterInstrumentHeader,
  RegisterInstrumentModal,
  RegisterInstrumentTitle
} from '../../../Components/Instruments/Register/RegisterInstrumentStyle';
import {
  AdminInput,
  ButtonAreaAdmin,
  IconRequired,
  InputArea,
  RequiredInput
} from '../../../Screens/AdminScreen/AdminScreenStyle';
import { Color } from '../../../Styles/Styles';
import {
  __initialPermissionsActive,
  __initialPermissionsData
} from '../Permissions/PermissionsInterface';
import { ATOFile } from './AtoFile';
import { AtoData, __initialAtoData } from './AtoInterface';

export function ATOModal({
  setShowModal,
  showModal,
  edit,
  setEdit,
  atoData,
  setAtoData,
  companies
}: {
  setShowModal: Dispatch<SetStateAction<boolean>>;
  showModal: boolean;
  edit: { status: boolean; data: any };
  setEdit: Dispatch<SetStateAction<{ status: boolean; data: any }>>;
  atoData: AtoData;
  setAtoData: Dispatch<SetStateAction<AtoData>>;
  companies: { id: string; label: string; value: string }[];
}) {
  const { t } = useTranslation();
  const [initialDate, setInitialDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());

  const SendPermission = async () => {};

  const EditPermission = async () => {};

  useEffect(() => {
    if (edit.status && edit.data) {
      setShowModal(true);
    }
  }, []);

  return (
    <RegisterInstrumentBackground>
      <RegisterInstrumentModal
        style={{
          width: '60%',
          minWidth: 600,
          maxWidth: 650,
          height: 'max-content',
          minHeight: '0'
        }}
      >
        <RegisterInstrumentHeader>
          <RegisterInstrumentTitle>{`${t('Register')} ${t('Notifications')}`}</RegisterInstrumentTitle>
          <IoIosClose
            size={35}
            onClick={() => {
              if (setShowModal) setShowModal(false);
              setEdit({ status: false, data: {} });
            }}
            style={CloseIconStyle}
          />
        </RegisterInstrumentHeader>
        <InputArea>
          {!edit.status && (
            <>
              <RequiredInput>
                <AdminInput
                  value={atoData.construction}
                  onChange={(e) => {
                    setAtoData((prevData) => ({
                      ...prevData,
                      construction:
                        e.target.value.length <= 15
                          ? e.target.value
                          : atoData.construction
                    }));
                  }}
                  placeholder={t('construction')}
                />
                {atoData.construction.length <= 3 ? (
                  <PiAsteriskBold style={IconRequired} />
                ) : (
                  <BsCheckLg style={IconRequired} />
                )}
              </RequiredInput>
              <RequiredInput>
                <AdminInput
                  value={atoData.local}
                  onChange={(e) => {
                    setAtoData((prevData) => ({
                      ...prevData,
                      construction:
                        e.target.value.length <= 15
                          ? e.target.value
                          : atoData.local
                    }));
                  }}
                  placeholder={t('Local')}
                />
                {atoData.local.length <= 3 ? (
                  <PiAsteriskBold style={IconRequired} />
                ) : (
                  <BsCheckLg style={IconRequired} />
                )}
              </RequiredInput>
              <RequiredInput>
                <AdminInput
                  value={atoData.responsible}
                  onChange={(e) => {
                    setAtoData((prevData) => ({
                      ...prevData,
                      construction:
                        e.target.value.length <= 15
                          ? e.target.value
                          : atoData.responsible
                    }));
                  }}
                  placeholder={t('Responsible')}
                />
                {atoData.responsible.length <= 3 ? (
                  <PiAsteriskBold style={IconRequired} />
                ) : (
                  <BsCheckLg style={IconRequired} />
                )}
              </RequiredInput>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '50%',
                  color: Color.Brown1,
                  alignItems: 'flex-start'
                }}
              >
                <div>
                  {t('InitialDate')}:
                  <DatePickerComponent
                    startDate={initialDate}
                    setStartDate={setInitialDate}
                    showTimeSelect={false}
                  />
                </div>
                <div>
                  {t('EndDate')}:
                  <DatePickerComponent
                    startDate={endDate}
                    setStartDate={setEndDate}
                    showTimeSelect={false}
                  />
                </div>
              </div>
              <ATOFile />
            </>
          )}
        </InputArea>
        <ButtonAreaAdmin style={{ justifyContent: 'flex-end' }}>
          <CancelButtonStyled
            onClick={() => {
              if (setShowModal) setShowModal(false);
              setEdit({ status: false, data: {} });
              setAtoData(__initialAtoData);
            }}
          >
            {t('Cancel').toLocaleUpperCase()}
          </CancelButtonStyled>
          {!edit.status && (
            <AdvanceButtonStyled onClick={() => SendPermission()}>
              {' '}
              {t('Save').toLocaleUpperCase()}
            </AdvanceButtonStyled>
          )}
          {edit.status && (
            <AdvanceButtonStyled onClick={() => EditPermission()}>
              {' '}
              {t('Edit').toLocaleUpperCase()}
            </AdvanceButtonStyled>
          )}
        </ButtonAreaAdmin>
      </RegisterInstrumentModal>
    </RegisterInstrumentBackground>
  );
}
