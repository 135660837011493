import { API_URL } from '../../../utils/const';

export async function postLogoFile(
  token: string,
  id: string,
  formData: FormData
) {
  const fullURL = `${API_URL}/company/${id}/logo`;

  const response = await fetch(fullURL, {
    method: 'PATCH',
    body: formData,
    headers: {
      'Access-Control-Allow-Origin': '*',
      Authorization: token
    }
  });

  if (response.status !== 200) {
    throw new Error('Erro');
  }
}
