import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ListUnreadModuleNotificationsQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type ListUnreadModuleNotificationsQuery = {
  __typename?: 'Query';
  listUnreadModuleNotifications: Array<{
    __typename?: 'ListModuleNotificationsType';
    module: Types.ModuleEnum;
    moduleId: string;
    count: number;
  }>;
};

export const ListUnreadModuleNotificationsDocument = gql`
  query ListUnreadModuleNotifications {
    listUnreadModuleNotifications {
      module
      moduleId
      count
    }
  }
`;

export type ListUnreadModuleNotificationsQueryResult = Apollo.QueryResult<
  ListUnreadModuleNotificationsQuery,
  ListUnreadModuleNotificationsQueryVariables
>;
