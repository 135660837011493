import styled from 'styled-components';
import { Color, FontSize } from '../../Styles/Styles';

interface InputStyledProps {
  error?: boolean;
}

export const DefaultInput = styled.input<InputStyledProps>`
  color: ${({ error }) => (error ? Color.Red1 : Color.Brown1)};
  border: none;
  border-bottom: 1px solid;
  padding: 1px 1px 1px 1px;
  width: 75%;
  margin-top: 10px;
  margin-bottom: 15px;
  font-size: ${FontSize.Small};
  background-color: ${({ error }) => (error ? '#ffe6e6' : 'white')};

  &::placeholder {
    color: ${Color.Brown1};
  }
`;
