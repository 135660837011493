import React, { useState } from 'react';
import { SidebarContainer, MenuItem } from './MenuRadio.styles';
import { MenuRadioProps, MenuOptions } from './MenuRadio.interface';
import Text from '../../Atoms/Text/Text';
import Input from '../../Atoms/Input/Input';

const MenuRadio = ({ title, options, preSelected }: MenuRadioProps) => {
  const [selectedOption, setSelectedOption] = useState<string | number>(
    preSelected || options[0]?.value || ''
  );

  const handleSelection = (option: MenuOptions) => {
    setSelectedOption(option.value);
    option.onClick({
      label: option.label,
      value: option.value
    });
  };

  return (
    <SidebarContainer>
      <Text type="h3" color="brown">
        {title}
      </Text>
      {options.map((option: MenuOptions, index: number) => (
        <MenuItem key={index}>
          <Input
            type="radio"
            name="menu-options"
            value={option.value}
            checked={selectedOption === option.value}
            width="15%"
            onChange={() => handleSelection(option)}
          />
          {option.label}
        </MenuItem>
      ))}
    </SidebarContainer>
  );
};

export default MenuRadio;
