import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type SaveRecordEquipmentAndLaborMutationVariables = Types.Exact<{
  data: Types.SaveRecordEquipmentAndLaborInput;
}>;


export type SaveRecordEquipmentAndLaborMutation = { __typename?: 'Mutation', saveRecordEquipmentAndLabor: string };


export const SaveRecordEquipmentAndLaborDocument = gql`
    mutation SaveRecordEquipmentAndLabor($data: SaveRecordEquipmentAndLaborInput!) {
  saveRecordEquipmentAndLabor(data: $data)
}
    `;
export type SaveRecordEquipmentAndLaborMutationFn = Apollo.MutationFunction<SaveRecordEquipmentAndLaborMutation, SaveRecordEquipmentAndLaborMutationVariables>;
export type SaveRecordEquipmentAndLaborMutationResult = Apollo.MutationResult<SaveRecordEquipmentAndLaborMutation>;
export type SaveRecordEquipmentAndLaborMutationOptions = Apollo.BaseMutationOptions<SaveRecordEquipmentAndLaborMutation, SaveRecordEquipmentAndLaborMutationVariables>;