import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type CreateStructureSummaryMutationVariables = Types.Exact<{
  data: Types.CreateStructureSummaryInput;
}>;


export type CreateStructureSummaryMutation = { __typename?: 'Mutation', createStructureSummary: string };


export const CreateStructureSummaryDocument = gql`
    mutation CreateStructureSummary($data: CreateStructureSummaryInput!) {
  createStructureSummary(data: $data)
}
    `;
export type CreateStructureSummaryMutationFn = Apollo.MutationFunction<CreateStructureSummaryMutation, CreateStructureSummaryMutationVariables>;
export type CreateStructureSummaryMutationResult = Apollo.MutationResult<CreateStructureSummaryMutation>;
export type CreateStructureSummaryMutationOptions = Apollo.BaseMutationOptions<CreateStructureSummaryMutation, CreateStructureSummaryMutationVariables>;