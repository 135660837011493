import { Dispatch, SetStateAction } from 'react';
import FormInspectionsTwoOptions from '../../../Components/Forms/FormInspectionsTwoOptions';
import { ContainerFirstStep } from '../InspectionsStyle';

export function FifthStepInspection({
  stepFiveStates,
  setStepFiveStates,
  error,
  inspectionId
}: {
  stepFiveStates: any;
  setStepFiveStates: Dispatch<SetStateAction<any>>;
  error: boolean;
  inspectionId: string;
}) {
  return (
    <ContainerFirstStep>
      <FormInspectionsTwoOptions
        setStepStates={setStepFiveStates}
        stepStates={stepFiveStates}
        topic="O acesso aos piezômetros está limpo e em boas condições?"
        title="IsAccessPiezometersCleanAndGoodCondition?"
        error={error}
        inspectionId={inspectionId}
      />
      <FormInspectionsTwoOptions
        setStepStates={setStepFiveStates}
        stepStates={stepFiveStates}
        topic="Os piezômetros estão protegidos e trancados?"
        title="ArePiezometersSecuredLocked?"
        error={error}
        inspectionId={inspectionId}
      />
      <FormInspectionsTwoOptions
        setStepStates={setStepFiveStates}
        stepStates={stepFiveStates}
        topic="Os piezômetros estão em bom estado de conservação?"
        title="ArePiezometersGoodCondition?"
        error={error}
        inspectionId={inspectionId}
      />
      <FormInspectionsTwoOptions
        setStepStates={setStepFiveStates}
        stepStates={stepFiveStates}
        topic="As réguas de nível estão bom estado de conservação e leitura?"
        title="AreLevelRulersGoodConditionReadable?"
        error={error}
        inspectionId={inspectionId}
      />
      <FormInspectionsTwoOptions
        setStepStates={setStepFiveStates}
        stepStates={stepFiveStates}
        topic="Os instrumentos estão devidamente identificados?"
        title="AreInstrumentsProperlyIdentified?"
        error={error}
        inspectionId={inspectionId}
      />
      <FormInspectionsTwoOptions
        setStepStates={setStepFiveStates}
        stepStates={stepFiveStates}
        topic="Outros (identificar)"
        title="OthersIdentify"
        error={error}
        inspectionId={inspectionId}
      />
    </ContainerFirstStep>
  );
}
