import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type DeleteSubscribedUserNotificationMutationVariables = Types.Exact<{
  userNotificationId: Types.Scalars['String']['input'];
}>;


export type DeleteSubscribedUserNotificationMutation = { __typename?: 'Mutation', deleteSubscribedUserNotification: string };


export const DeleteSubscribedUserNotificationDocument = gql`
    mutation DeleteSubscribedUserNotification($userNotificationId: String!) {
  deleteSubscribedUserNotification(userNotificationId: $userNotificationId)
}
    `;
export type DeleteSubscribedUserNotificationMutationFn = Apollo.MutationFunction<DeleteSubscribedUserNotificationMutation, DeleteSubscribedUserNotificationMutationVariables>;
export type DeleteSubscribedUserNotificationMutationResult = Apollo.MutationResult<DeleteSubscribedUserNotificationMutation>;
export type DeleteSubscribedUserNotificationMutationOptions = Apollo.BaseMutationOptions<DeleteSubscribedUserNotificationMutation, DeleteSubscribedUserNotificationMutationVariables>;