import styled from 'styled-components';
import { Color } from '../../../../Styles/Styles';

export const DivVolumeCheckboxContainer = styled.div`
  color: ${Color.Text1};
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const DivContainerVolumeCheckboxList = styled.div`
  width: 100%;
`;
export const DivVolumeCheckboxList = styled.div`
  svg {
    font-size: 20px;
    cursor: pointer;
    color: ${Color.Brown1} !important;
  }
`;
export const DivPadding = styled.div`
  padding-left: 30px;
`;
