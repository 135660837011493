import { useLazyQuery, useQuery } from '@apollo/client';
import {
  ListReadingByPluviometerDocument,
  ListReadingByPluviometerQuery,
  ListReadingByPluviometerQueryVariables
} from '../../graphql/query/generated/listReadingByPluviometer.query';

export function useListReadingByPluviometer(
  endDate: Date,
  startDate: Date,
  instrumentIds: string,
  onCompleted?: () => void,
  onError?: () => void
) {
  const { data, loading, error } = useQuery<
    ListReadingByPluviometerQuery,
    ListReadingByPluviometerQueryVariables
  >(ListReadingByPluviometerDocument, {
    variables: {
      endDate,
      startDate,
      instrumentIds
    },
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    data,
    loading,
    error
  };
}

export function useListReadingByPluviometerLazy(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [listReadingByPluviometer, { data, loading, error }] = useLazyQuery<
    ListReadingByPluviometerQuery,
    ListReadingByPluviometerQueryVariables
  >(ListReadingByPluviometerDocument, {
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    listReadingByPluviometer,
    data,
    loading,
    error
  };
}
