import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type CreateSurfaceMarkerMutationVariables = Types.Exact<{
  structureInfo: Types.StructureInfoInput;
  references: Array<Types.SurfaceMarkersBaseDataInput> | Types.SurfaceMarkersBaseDataInput;
  alertLevels: Array<Types.SurfaceMarkersAlertLevelInput> | Types.SurfaceMarkersAlertLevelInput;
  generalInfo: Types.CreateInstrumentWithSectionInput;
}>;


export type CreateSurfaceMarkerMutation = { __typename?: 'Mutation', createSurfaceMarker: string };


export const CreateSurfaceMarkerDocument = gql`
    mutation CreateSurfaceMarker($structureInfo: StructureInfoInput!, $references: [SurfaceMarkersBaseDataInput!]!, $alertLevels: [SurfaceMarkersAlertLevelInput!]!, $generalInfo: CreateInstrumentWithSectionInput!) {
  createSurfaceMarker(
    structureInfo: $structureInfo
    references: $references
    alertLevels: $alertLevels
    generalInfo: $generalInfo
  )
}
    `;
export type CreateSurfaceMarkerMutationFn = Apollo.MutationFunction<CreateSurfaceMarkerMutation, CreateSurfaceMarkerMutationVariables>;
export type CreateSurfaceMarkerMutationResult = Apollo.MutationResult<CreateSurfaceMarkerMutation>;
export type CreateSurfaceMarkerMutationOptions = Apollo.BaseMutationOptions<CreateSurfaceMarkerMutation, CreateSurfaceMarkerMutationVariables>;