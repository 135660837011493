import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type CreateActionPlanMutationVariables = Types.Exact<{
  data: Types.CreateActionPlanInput;
}>;


export type CreateActionPlanMutation = { __typename?: 'Mutation', createActionPlan: string };


export const CreateActionPlanDocument = gql`
    mutation CreateActionPlan($data: CreateActionPlanInput!) {
  createActionPlan(data: $data)
}
    `;
export type CreateActionPlanMutationFn = Apollo.MutationFunction<CreateActionPlanMutation, CreateActionPlanMutationVariables>;
export type CreateActionPlanMutationResult = Apollo.MutationResult<CreateActionPlanMutation>;
export type CreateActionPlanMutationOptions = Apollo.BaseMutationOptions<CreateActionPlanMutation, CreateActionPlanMutationVariables>;