import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react';
import { useTranslation } from 'react-i18next';
import { ImagesType, UserData } from '../../../@Types/types';
import { DatePickerComponent } from '../../../Components/DatePicker';
import { InputFile } from '../../../Components/Forms/FormInspectionsStyle';
import { DefaultInput } from '../../../Components/Inputs/InputsStyle';
import { RegisterInstrumentBackground } from '../../../Components/Instruments/Register/RegisterInstrumentStyle';
import {
  toastfyDimiss,
  toastfyError,
  toastifyLoading
} from '../../../Components/Toastify';
import { ProjectContext } from '../../../Context/ContextAPI';
import ReadingsController from '../../../Controllers/Instruments/ReadingsController';
import ToastifyModel from '../../../Models/ToastifyModel';
import { useCreateInspection } from '../../../data/hooks/use-create-inspection.mutation';
import { useUpdateDate } from '../../../data/hooks/use-update-date';
import { RegisterArea } from '../InspectionsStyle';
import { StepsRegisterInspections } from '../Register/StepsRegisterInspections';
import { AddParticipantComponent } from './AddParticipantComponent';
import { API_URL } from '../../../utils/const';
import ModalStep from '../../../Components/V2/Molecules/ModalStep/ModalStep';
import FileList from '../../../Components/V2/Molecules/FileList/FileList';
import { FileType } from '../../../Components/V2/Molecules/FileViewer/FileViewer.interfaces';
import { useParams } from 'react-router-dom';
import { FindAllInspectionsByStructureDocument } from '../../../data/graphql/query/generated/findAllInspectionsByStructure.query';
import Enums from '../../../utils/enumns';
import { GroupText } from '../../../Components/V2/Molecules/GroupText/GroupText';
import Text from '../../../Components/V2/Atoms/Text/Text';
import TableComponent from '../../../Components/V2/Molecules/TableComponent/TableComponent';

interface InspectionProps {
  setShowModal: Dispatch<SetStateAction<boolean>>;
  editing: boolean;
  inspectionId: string;
  setInspectionId: Dispatch<SetStateAction<string>>;
  selectedInspection: InspectionType | null;
}

interface InspectionType {
  conservationStateCalc: number;
  date: string;
  generalComments: string;
  id: string;
  participants: InspectionParticipantsType[];
  responsible: string;
  structureId: string;
  systemId: number;
  systemPdf: string;
  userPdf: string;
}

interface InspectionParticipantsType {
  company: string;
  id: string;
  name: string;
  position: string;
  signature: string;
  status: boolean;
}

export function AttributionInspection({
  setShowModal,
  editing,
  inspectionId,
  setInspectionId,
  selectedInspection
}: InspectionProps) {
  const { t: translate } = useTranslation();
  const { userData, getUserRoleByModule } = useContext(ProjectContext) as {
    userData: UserData;
    getUserRoleByModule: (moduleName: string) => string;
  };
  const userRoleInModule = getUserRoleByModule('Inspections');
  const { structureId } = useParams();
  const { UserRoles } = Enums();
  const [participants] = useState<{ name: string }[]>([
    { name: userData.name }
  ]);
  const [subParticipants, setSubParticipants] = useState<
    InspectionParticipantsType[]
  >([]);
  const [showStepsInspectionsModal, setShowStepsInspectionsModal] =
    useState<boolean>(false);
  const { createInspection } = useCreateInspection();
  const [date, setDate] = useState<Date>(
    (selectedInspection && new Date(selectedInspection.date)) || new Date()
  );
  const [imageFileInspection, setImageFileInspection] = useState<ImagesType[]>([
    {
      fileSent: false,
      file: false,
      id: null,
      endpoint: 'mss=genericData',
      topic: '',
      title: ''
    }
  ]);
  const [requiredFieldImage, setRequiredFieldImage] = useState<boolean>(false);
  const { updateDate } = useUpdateDate();
  const [pdf, setPdf] = useState<FileType[]>([]);

  let newArray: { name: string }[];

  async function postSituationAccess(formData: FormData, id: string) {
    const response = await fetch(`${API_URL}/inspection/${id}/pdf`, {
      method: 'POST',
      body: formData,
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: userData.token
      }
    });

    if (response.status !== 200) {
      throw new Error('Erro');
    }
  }

  async function SendAtribuitionInspection() {
    if (date > new Date()) {
      toastfyError(translate('DateNotGreaterThanCurrentDate'));
      return;
    }

    if (selectedInspection?.id) {
      const updatedImageFileInspection = [...imageFileInspection];

      if (updatedImageFileInspection[0].fileSent) {
        const formData = new FormData();
        formData.append('pdf', updatedImageFileInspection[0].file[0]);
        postSituationAccess(formData, selectedInspection.id);
      }

      setRequiredFieldImage(false);
      setShowStepsInspectionsModal(!showStepsInspectionsModal);
      return;
    } else {
      if (
        imageFileInspection[0].fileSent === false ||
        imageFileInspection[0].fileSent === null
      ) {
        setRequiredFieldImage(true);
        toastfyError(
          translate(ToastifyModel().toastifyMessage.fillRequiredFields)
        );
        return;
      }

      if (subParticipants.length > 0) {
        const res = subParticipants.map((obj) => {
          const { id, ...rest } = obj;
          return rest;
        });
        newArray = participants.concat(res);
      } else {
        newArray = participants;
      }

      const createResponse = await createInspection({
        variables: {
          data: {
            structureId: structureId?.toString() || '',
            participants: newArray,
            date: new Date(date.toDateString())
          }
        }
      });
      toastifyLoading(translate('registering') + '...');

      if (createResponse.data) {
        setRequiredFieldImage(false);
        setInspectionId(createResponse.data.createInspection);
        toastfyDimiss('toastLoading');
        ReadingsController.ShowModal({
          showModal: showStepsInspectionsModal,
          setShowModal: setShowStepsInspectionsModal
        });
      } else if (createResponse.errors) {
        toastfyDimiss('toastLoading');
        toastfyError(
          createResponse.errors[0].message ||
            translate(ToastifyModel().toastifyMessage.error)
        );
      }

      const updatedImageFileInspection = [...imageFileInspection];

      if (createResponse.data !== null && createResponse.data !== undefined) {
        updatedImageFileInspection[0].id = createResponse.data.createInspection;
        setImageFileInspection(updatedImageFileInspection);

        if (updatedImageFileInspection[0].file !== null) {
          const formData = new FormData();
          formData.append('pdf', updatedImageFileInspection[0].file[0]);
          postSituationAccess(formData, createResponse.data.createInspection);
        }
      }
    }
  }

  function handleChangeImage(event: ChangeEvent<HTMLInputElement>) {
    const filesSelected = event.target.files && event.target.files.length > 0;

    setImageFileInspection((state) => [
      {
        ...state[0],
        fileSent: filesSelected,
        file: event.target.files,
        id: null,
        endpoint: 'mss=genericData',
        topic: '',
        title: event.target.dataset.title
      }
    ]);
    setPdf(
      Array.from(event.target.files!).map((file: File) => {
        return {
          id: null,
          name: file.name,
          url: URL.createObjectURL(file)
        };
      })
    );
  }

  async function updateDateFunction(date: Date) {
    if (date > new Date()) {
      toastfyError(translate('DateNotGreaterThanCurrentDate'));
      return;
    }

    setDate(date);
    if (editing) {
      const response = await updateDate({
        variables: {
          inspectionId: inspectionId,
          newDate: date
        },
        refetchQueries: [
          {
            query: FindAllInspectionsByStructureDocument,
            variables: {
              structureId: structureId
            }
          }
        ]
      });
      toastifyLoading(translate('registering') + '...');
      if (response.data) {
        toastfyDimiss('toastLoading');
      }
    }
  }

  const handleDeleteFile = (pdf: FileType) => {
    if (pdf.id) {
      toastfyError(translate('notPossibleDeleteFileTryUpdate'));
      return false;
    } else {
      const updatedImageFileInspection = [...imageFileInspection];
      updatedImageFileInspection[0].file = null;
      setImageFileInspection(updatedImageFileInspection);
      setPdf([]);
      return true;
    }
  };

  const hanldePdf = async () => {
    if (!selectedInspection) return;
    const response = await fetch(`${API_URL}/${selectedInspection.userPdf}`);
    const id = selectedInspection.userPdf.replace('.pdf', '');
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    setPdf([
      {
        id: id,
        name: selectedInspection.id,
        url: url
      }
    ]);
  };

  useEffect(() => {
    if (selectedInspection?.userPdf) {
      hanldePdf();
    }
  }, []);

  return (
    <>
      <RegisterInstrumentBackground>
        <ModalStep
          width={30}
          height={70}
          onClose={() => setShowModal(false)}
          title={`${
            editing
              ? userRoleInModule !== UserRoles.Viewer
                ? `${translate('Edit')} ${translate('Inspection')}`
                : `${translate('View')} ${translate('Inspection')}`
              : `${translate('Register')} ${translate('Inspection')}`
          }`}
          saveButton="Advance"
          steps={[
            {
              name: '',
              component: (
                <>
                  {userRoleInModule !== UserRoles.Viewer ? (
                    <RegisterArea>
                      <DefaultInput
                        disabled
                        style={{ width: '293px' }}
                        placeholder={`${translate('InspectedFor')} (${translate('name')})`}
                        value={userData.name}
                      />
                      <AddParticipantComponent
                        setSubParticipants={setSubParticipants}
                        subParticipants={subParticipants}
                        editing={editing}
                        inspectionId={inspectionId}
                        selectedInspection={selectedInspection}
                      />
                      <div>
                        {translate('DateOfInspection')}:
                        <DatePickerComponent
                          startDate={date}
                          setStartDate={setDate}
                          showTimeSelect={false}
                          onChange={(date: Date) => updateDateFunction(date)}
                        />
                      </div>
                      <InputFile>
                        <input
                          type="file"
                          name="image"
                          id="File_crack"
                          accept=".pdf"
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => handleChangeImage(event)}
                          style={{
                            outline: requiredFieldImage
                              ? '1px solid red'
                              : 'none'
                          }}
                        />
                      </InputFile>
                      {pdf.length > 0 && (
                        <FileList
                          files={pdf}
                          onDelete={(pdf: FileType) => {
                            handleDeleteFile(pdf);
                          }}
                          canDelete={userRoleInModule !== UserRoles.Viewer}
                        />
                      )}
                    </RegisterArea>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '10px'
                      }}
                    >
                      <GroupText
                        title={translate('InspectedFor')}
                        value={userData.name}
                      />
                      <GroupText
                        title={translate('DateOfInspection')}
                        value={date.toLocaleDateString()}
                      />
                      {pdf.length > 0 && (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <Text type="span">{translate('File')}</Text>
                          <FileList
                            files={pdf}
                            onDelete={(pdf: FileType) => {
                              handleDeleteFile(pdf);
                            }}
                            canDelete={userRoleInModule !== UserRoles.Viewer}
                          />
                        </div>
                      )}
                      <TableComponent
                        columns={[
                          {
                            key: 'name',
                            label: translate('Participants')
                          }
                        ]}
                        tableData={
                          selectedInspection
                            ? selectedInspection.participants.map((item) => {
                                return {
                                  name: item.name
                                };
                              })
                            : []
                        }
                      />
                    </div>
                  )}
                </>
              ),
              onNext: () => SendAtribuitionInspection()
            }
          ]}
        />
      </RegisterInstrumentBackground>
      {showStepsInspectionsModal && (
        <StepsRegisterInspections
          selectedInspection={selectedInspection!}
          inspectionId={inspectionId}
          showModal={showStepsInspectionsModal}
          setShowStepsInspectionsModal={setShowStepsInspectionsModal}
          setShowModal={setShowModal}
          editing={editing}
        />
      )}
    </>
  );
}
