import styled from 'styled-components';

export const HolderExports = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const HolderFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const HolderCheckboxLis = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
`;

export const HolderCheckbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px;
  white-space: nowrap;
`;
