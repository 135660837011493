import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ExportPluviometerReadingsByStructureQueryVariables = Types.Exact<{
  instrumentType: Types.InstrumentTypeEnum;
  structureId: Types.Scalars['String']['input'];
  selectedFields:
    | Array<Types.Scalars['String']['input']>
    | Types.Scalars['String']['input'];
  filters?: Types.InputMaybe<Types.ExportPluviometerReadingFiltersInput>;
}>;

export type ExportPluviometerReadingsByStructureQuery = {
  __typename?: 'Query';
  exportPluviometerReadingsByStructure: string;
};

export const ExportPluviometerReadingsByStructureDocument = gql`
  query ExportPluviometerReadingsByStructure(
    $instrumentType: InstrumentTypeEnum!
    $structureId: String!
    $selectedFields: [String!]!
    $filters: ExportPluviometerReadingFiltersInput
  ) {
    exportPluviometerReadingsByStructure(
      instrumentType: $instrumentType
      structureId: $structureId
      selectedFields: $selectedFields
      filters: $filters
    )
  }
`;

export type ExportPluviometerReadingsByStructureQueryResult =
  Apollo.QueryResult<
    ExportPluviometerReadingsByStructureQuery,
    ExportPluviometerReadingsByStructureQueryVariables
  >;
