const ErrorsTreatments = (
  errorString: string,
  translate: (key: string) => string
): string => {
  const parts = errorString.split('=');

  if (parts.length < 3) {
    return errorString;
  }

  const key = parts[0].trim();
  const value = parts[2].trim();

  return `${key} - ${translate(value)}`;
};

export default ErrorsTreatments;
