import { SimpleContentTemplateProps } from './SimpleContentTemplate.interface';

import Skeleton from 'react-loading-skeleton';
import {
  Container,
  DivButton,
  DivContainerSkeleton,
  DivContent,
  DivHeader,
  DivTitle
} from './SimpleContentTemplateStyle';

const SimpleContentTemplate = ({
  title,
  button,
  content,
  loading
}: SimpleContentTemplateProps) => {
  return (
    <>
      {loading ? (
        <Container>
          <DivHeader>
            <DivTitle>
              <Skeleton width="300px" height="48px" />
            </DivTitle>
            <DivButton>
              <Skeleton width="200px" height="48px" />
            </DivButton>
          </DivHeader>
          <DivContent>
            <DivContainerSkeleton>
              <Skeleton width="90%" height="450px" />
            </DivContainerSkeleton>
          </DivContent>
        </Container>
      ) : (
        <Container>
          <DivHeader>
            <DivTitle>{title}</DivTitle>
            <DivButton>{button}</DivButton>
          </DivHeader>
          <DivContent>{content}</DivContent>
        </Container>
      )}
    </>
  );
};

export default SimpleContentTemplate;
