import styled from 'styled-components';
import { Color } from '../../../../Styles/Styles';

export const DivAccordionContainer = styled.div`
  width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const AccordionButton = styled.button`
  position: relative;
  background-color: ${Color.White};
  cursor: pointer;
  padding: 18px;
  width: 90%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
  border-bottom: 1px solid ${Color.Brown1};

  &:hover {
    background-color: #f1f1f1;
  }
`;

export const AccordionItemContainer = styled.div`
  display: flex;
  gap: 20px;
  color: ${Color.Brown1};
  width: 100%;
`;

export const DivAccordionItemContainer = styled.div`
  display: flex;
  gap: 20px;
  color: ${Color.Brown1};
  width: 100%;
`;
export const AccordionPlusMinusContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translate(0, -50%);
  color: ${Color.Brown1};
`;

export const AccordionPanel = styled.div<{ active: boolean }>`
  padding: 0 18px;
  background-color: white;
  max-height: ${({ active }) => (active ? '100%' : '0')};
  overflow: hidden;
  transition: max-height 0.4s ease-in;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
