import { useMutation } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';
import { AtoRecordEquipmentAndLaborDataType } from '../../../../data/graphql/base-schema';
import {
  SaveRecordEquipmentAndLaborDocument,
  SaveRecordEquipmentAndLaborMutation,
  SaveRecordEquipmentAndLaborMutationVariables
} from '../../../../data/graphql/generated/saveRecordEquipmentAndLabor';
import {
  UpdateRecordEquipmentAndLaborDocument,
  UpdateRecordEquipmentAndLaborMutation,
  UpdateRecordEquipmentAndLaborMutationVariables
} from '../../../../data/graphql/generated/updateRecordEquipmentAndLabor';
import {
  FindDataByRecordDocument,
  FindDataByRecordQuery
} from '../../../../data/graphql/query/generated/findDataByRecord';
import ToastifyModel from '../../../../Models/ToastifyModel';
import ErrorsTreatments from '../../../../utils/errorTreatment';
import { toastfyError, toastfySuccess } from '../../../Toastify';
import Button from '../../Atoms/Button/Button';
import InputText from '../../Molecules/InputText/InputText';
import { AtoQuantityDescriptionFormProps } from './AtoQuantityDescriptionForm.interface';
import {
  DivButtonArea,
  DivInputsArea,
  HolderForm
} from './AtoQuantityDescriptionForm.styles';

const AtoQuantityDescriptionForm = ({
  setShowModal,
  recordId,
  type,
  editQuantityDescription
}: AtoQuantityDescriptionFormProps) => {
  const { t } = useTranslation();
  const [description, setDescription] = useState<string>('');
  const [quantity, setQuantity] = useState<string>('');
  const [saveRecordEquipmentAndLabor] = useMutation<
    SaveRecordEquipmentAndLaborMutation,
    SaveRecordEquipmentAndLaborMutationVariables
  >(SaveRecordEquipmentAndLaborDocument);
  const [updateRecordEquipmentAndLabor] = useMutation<
    UpdateRecordEquipmentAndLaborMutation,
    UpdateRecordEquipmentAndLaborMutationVariables
  >(UpdateRecordEquipmentAndLaborDocument);

  const atoQuantDescriSchema = z.object({
    quantity: z.string().min(1, {
      message: t('fieldIsRequired')
    }),
    description: z.string().min(1, {
      message: t('fieldIsRequired')
    })
  });

  type AtoQuantDescriFormSchemaType = z.infer<typeof atoQuantDescriSchema>;

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<AtoQuantDescriFormSchemaType>({
    resolver: zodResolver(atoQuantDescriSchema),
    defaultValues: {
      quantity: editQuantityDescription
        ? editQuantityDescription?.quantity.toString()
        : undefined,
      description: editQuantityDescription?.description
    }
  });

  const handleSubmitQuantDescriForm = (data: AtoQuantDescriFormSchemaType) => {
    const { description, quantity } = data;
    if (editQuantityDescription?.id) {
      updateRecordEquipmentAndLabor({
        variables: {
          data: {
            description: description,
            quantity: Number(quantity),
            id: editQuantityDescription.id,
            type: type
          }
        },
        onCompleted: () => {
          setShowModal(false);
          toastfySuccess(t('editedSuccessfully'));
        },
        onError: ({ graphQLErrors }) => {
          const errorMessage = ErrorsTreatments(graphQLErrors[0].message, t);
          toastfyError(errorMessage);
        },
        update: (cache, { data }) => {
          if (!data) return;
          const existingData = cache.readQuery({
            query: FindDataByRecordDocument,
            variables: {
              recordId: recordId
            }
          }) as FindDataByRecordQuery;

          if (type === 'Labor') {
            const updatedData = {
              ...existingData,
              findDataByRecord: {
                ...existingData.findDataByRecord,
                labor: existingData.findDataByRecord.labor.map(
                  (item: AtoRecordEquipmentAndLaborDataType) =>
                    item.id === editQuantityDescription?.id
                      ? {
                          ...item,
                          description: description,
                          quantity: Number(quantity),
                          id: editQuantityDescription.id,
                          __typename: 'AtoRecordEquipmentAndLaborDataType'
                        }
                      : item
                )
              }
            };
            cache.writeQuery({
              query: FindDataByRecordDocument,
              variables: {
                recordId: recordId
              },
              data: updatedData
            });
          }

          if (type === 'Equipment') {
            const updatedData = {
              ...existingData,
              findDataByRecord: {
                ...existingData.findDataByRecord,
                equipments: existingData.findDataByRecord.equipments.map(
                  (item: AtoRecordEquipmentAndLaborDataType) =>
                    item.id === editQuantityDescription?.id
                      ? {
                          ...item,
                          description: description,
                          quantity: Number(quantity),
                          id: editQuantityDescription.id,
                          __typename: 'AtoRecordEquipmentAndLaborDataType'
                        }
                      : item
                )
              }
            };
            cache.writeQuery({
              query: FindDataByRecordDocument,
              variables: {
                recordId: recordId
              },
              data: updatedData
            });
          }
        }
      });
    } else {
      saveRecordEquipmentAndLabor({
        variables: {
          data: {
            description: description,
            quantity: Number(quantity),
            recordId: recordId,
            type: type
          }
        },
        onCompleted: () => {
          setShowModal(false);
          toastfySuccess(t('registeredSuccessfully'));
        },
        onError: ({ graphQLErrors }) => {
          toastfyError(
            graphQLErrors[0].message || t(ToastifyModel().toastifyMessage.error)
          );
        },
        update: (cache, { data: backendResponse }) => {
          if (!backendResponse) return;
          const existingData = cache.readQuery({
            query: FindDataByRecordDocument,
            variables: {
              recordId: recordId
            }
          }) as FindDataByRecordQuery;

          if (type === 'Labor') {
            const updatedData = {
              ...existingData,
              findDataByRecord: {
                ...existingData.findDataByRecord,
                labor: [
                  ...existingData.findDataByRecord.labor,
                  {
                    description: description,
                    id: backendResponse.saveRecordEquipmentAndLabor,
                    quantity: Number(quantity),
                    __typename: 'AtoRecordEquipmentAndLaborDataType'
                  }
                ]
              }
            };

            cache.writeQuery({
              query: FindDataByRecordDocument,
              variables: {
                recordId: recordId
              },
              data: updatedData
            });
          }

          if (type === 'Equipment') {
            const updatedData = {
              ...existingData,
              findDataByRecord: {
                ...existingData.findDataByRecord,
                equipments: [
                  ...existingData.findDataByRecord.equipments,
                  {
                    description: description,
                    id: backendResponse.saveRecordEquipmentAndLabor,
                    quantity: Number(quantity),
                    __typename: 'AtoRecordEquipmentAndLaborDataType'
                  }
                ]
              }
            };

            cache.writeQuery({
              query: FindDataByRecordDocument,
              variables: {
                recordId: recordId
              },
              data: updatedData
            });
          }
        }
      });
    }
  };

  return (
    <HolderForm>
      <DivInputsArea>
        <InputText
          errorMessage={errors.quantity?.message}
          label={t('quantity')}
          name="quantity"
          onChange={(event) => setQuantity(event.target.value)}
          control={control}
          type="number"
          value={quantity}
          width="215px"
          error={!!errors.quantity}
        />
        <InputText
          errorMessage={errors.description?.message}
          label={t('description')}
          name="description"
          onChange={(event) => setDescription(event.target.value)}
          control={control}
          type="text"
          value={description}
          width="215px"
          error={!!errors.description}
        />
      </DivInputsArea>
      <DivButtonArea>
        <Button
          text={t('Cancel')}
          variant={'secondary'}
          size="small"
          onClick={() => {
            setShowModal(false);
          }}
        />
        <Button
          text={t('Save')}
          variant={'primary'}
          size="small"
          onClick={handleSubmit(handleSubmitQuantDescriForm)}
        />
      </DivButtonArea>
    </HolderForm>
  );
};

export default AtoQuantityDescriptionForm;
