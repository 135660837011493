import * as Types from '../base-schema';

import * as Apollo from '@apollo/client';
import { gql } from '@apollo/client';
export type DeleteActionPlanTeamMemberMutationVariables = Types.Exact<{
  actionPlanId: Types.Scalars['String']['input'];
  teamMemberId: Types.Scalars['String']['input'];
}>;

export type DeleteActionPlanTeamMemberMutation = {
  __typename?: 'Mutation';
  deleteActionPlanTeamMember: string;
};

export const DeleteActionPlanTeamMemberDocument = gql`
  mutation DeleteActionPlanTeamMember(
    $actionPlanId: String!
    $teamMemberId: String!
  ) {
    deleteActionPlanTeamMember(
      actionPlanId: $actionPlanId
      teamMemberId: $teamMemberId
    )
  }
`;

export type DeleteActionPlanTeamMemberMutationFn = Apollo.MutationFunction<
  DeleteActionPlanTeamMemberMutation,
  DeleteActionPlanTeamMemberMutationVariables
>;

export type DeleteActionPlanTeamMemberMutationResult =
  Apollo.MutationResult<DeleteActionPlanTeamMemberMutation>;

export type DeleteActionPlanTeamMemberMutationOptions =
  Apollo.BaseMutationOptions<
    DeleteActionPlanTeamMemberMutation,
    DeleteActionPlanTeamMemberMutationVariables
  >;
