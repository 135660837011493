import { useQuery } from '@apollo/client';
import {
  FindAllGistmDataDocument,
  FindAllGistmDataQuery,
  FindAllGistmDataQueryVariables
} from '../../graphql/query/generated/findAllGistmData.query';

export function useFindAllGistmData(
  structureId: string,
  onCompleted?: () => void,
  onError?: () => void
) {
  const { data, loading, error } = useQuery<
    FindAllGistmDataQuery,
    FindAllGistmDataQueryVariables
  >(FindAllGistmDataDocument, {
    variables: {
      structureId
    },
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    data,
    loading,
    error
  };
}
