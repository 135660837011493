import Skeleton from 'react-loading-skeleton';
import Text from '../../Atoms/Text/Text';
import { SeeMoreTemplateProps } from './SeeMoreTemplate.interface';
import {
  HolderContent,
  HolderHeader,
  HolderTitle,
  PageContainer
} from './SeeMoreTemplate.styles';

const SeeMoreTemplate = ({
  header,
  title,
  content,
  icon,
  loading
}: SeeMoreTemplateProps) => {
  return loading ? (
    <PageContainer>
      <HolderTitle>
        <Skeleton width="300px" height="48px" />
      </HolderTitle>
      {header && (
        <HolderHeader>
          <Skeleton width="300px" height="48px" />
        </HolderHeader>
      )}
      <HolderContent>
        <Skeleton width="90vw" height="50vh" />
      </HolderContent>
    </PageContainer>
  ) : (
    <PageContainer>
      <HolderTitle>
        {icon}
        <Text weight="bold" type="h2">
          {title}
        </Text>
      </HolderTitle>
      <HolderHeader>{header}</HolderHeader>
      <HolderContent>{content}</HolderContent>
    </PageContainer>
  );
};

export default SeeMoreTemplate;
