import styled from 'styled-components';

export const DivTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const DivImages = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 15px;
  width: 90%;
`;

export const DivContainerImages = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
`;
