import SimpleContentTemplate from '../../Templates/SimpleContentTemplate/SimpleContentTemplate';
import { useTranslation } from 'react-i18next';
import Text from '../../Atoms/Text/Text';
import PhotoChart from '../../Organisms/PhotoChart/PhotoChart';

const SectionGraph = () => {
  const { t: translate } = useTranslation();

  return (
    <SimpleContentTemplate
      title={<Text type="h3">{translate('GenerateGraph')}</Text>}
      button={<></>}
      content={<PhotoChart />}
      loading={false}
    />
  );
};

export default SectionGraph;
