import Text from '../../Atoms/Text/Text';
import InputCheckbox from '../../Molecules/InputCheckbox/InputCheckbox';
import InputTextarea from '../../Molecules/InputTextarea/InputTextarea';
import { VolumeCheckboxItem, VolumeCheckboxProps } from './VolumeCheckbox.interface';
import {
  DivContainerVolumeCheckboxList,
  DivPadding,
  DivVolumeCheckboxContainer,
  DivVolumeCheckboxList
} from './VolumeCheckbox.styles';

export function VolumeCheckbox({
  title,
  comments,
  setComments,
  data,
  isComment,
  error
}: VolumeCheckboxProps) {
  const renderNestedVolumes = (
    items: VolumeCheckboxItem[],
    parentId: string | null = null
  ) => {
    const insertId = (value: string) => {
      if (comments.find((event) => event.id === value)) {
        const newFilesIds = comments.filter((e) => e.id !== value);
        setComments(newFilesIds);
      } else {
        setComments((prev) => [...prev, { id: value, comment: '' }]);
      }
    };

    const insertIdAndComents = (
      event: React.ChangeEvent<HTMLTextAreaElement>,
      itemId: string
    ) => {
      const newComment = event.target.value;
      const newState = comments.map((item) => {
        if (item.id === itemId.toString()) {
          return { id: item.id, comment: newComment };
        } else {
          return item;
        }
      });
      setComments(newState);
    };

    return items.map((item) => (
      <div key={item.id}>
        <DivVolumeCheckboxList>
          <InputCheckbox
            label={item.name}
            name={item.id.toString()}
            onChange={() => insertId(item.id.toString())}
          />
        </DivVolumeCheckboxList>
        {item.nested && item.nested.length > 0 && (
          <DivPadding>
            {renderNestedVolumes(item.nested, item.id.toString())}
          </DivPadding>
        )}
        {isComment &&
          comments.find((id) => id.id === item.id) &&
          parentId === null && (
            <InputTextarea
              error={
                error && !comments.find((id) => id.id === item.id)?.comment
              }
              onChange={(event) => insertIdAndComents(event, item.id)}
              errorMessage="É necessario inserir um comentário"
              name="Comentário"
              label="Comentário"
              width="100%"
            />
          )}
      </div>
    ));
  };

  return (
    <DivVolumeCheckboxContainer>
      {title && <Text type={'h4'}>{title}</Text>}
      <DivContainerVolumeCheckboxList>
        {renderNestedVolumes(data)}
      </DivContainerVolumeCheckboxList>
    </DivVolumeCheckboxContainer>
  );
}
