import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GenerateInspectionPdfMutationVariables = Types.Exact<{
  inspectionId: Types.Scalars['String']['input'];
}>;


export type GenerateInspectionPdfMutation = { __typename?: 'Mutation', generateInspectionPdf: string };


export const GenerateInspectionPdfDocument = gql`
    mutation GenerateInspectionPdf($inspectionId: String!) {
  generateInspectionPdf(inspectionId: $inspectionId)
}
    `;
export type GenerateInspectionPdfMutationFn = Apollo.MutationFunction<GenerateInspectionPdfMutation, GenerateInspectionPdfMutationVariables>;
export type GenerateInspectionPdfMutationResult = Apollo.MutationResult<GenerateInspectionPdfMutation>;
export type GenerateInspectionPdfMutationOptions = Apollo.BaseMutationOptions<GenerateInspectionPdfMutation, GenerateInspectionPdfMutationVariables>;