import { useEffect } from 'react';
import { findAllGISTMData } from '../../../../data/services/GISTMService';
import Accordion from '../../Molecules/Accordion/Accordion';
import { FileType } from '../../Molecules/FileViewer/FileViewer.interfaces';
import InputFileWithList from '../InputFileWithList/InputFileWithList';
import { VolumeCheckbox } from '../VolumeCheckbox/VolumeCheckbox';
import { IGISTMListVolumes } from './GISTMListVolumes.interface';
import { DivBodyModal } from './GISTMListVolumes.style';

export function GISTMListVolumes({
  setFiles,
  setComments,
  files,
  setData,
  comments,
  error,
  data,
  structureSelectedId
}: IGISTMListVolumes) {
  useEffect(() => {
    fetchFiles();
  }, []);

  const fetchFiles = async () => {
    const res = await findAllGISTMData(structureSelectedId);
    if (!res) return;
    setData(res.findAllGistmData);
  };

  const handleFileChange = (files: File[]) => {
    const newFiles = Array.from(files).map((file) => ({
      id: '',
      name: file.name,
      url: URL.createObjectURL(file),
      file: file
    }));
    setFiles((prev) => [...prev, ...newFiles]);
  };

  const handleDeleteImage = (fileSelect: FileType) => {
    const updatedFiles = files.filter((file) => file.file !== fileSelect.file);
    setFiles(updatedFiles);
  };

  return (
    <DivBodyModal>
      <InputFileWithList
        name="GISTM"
        error={error && files.length === 0}
        accept=".pdf"
        label=""
        multiple={true}
        files={files}
        onChange={(e) => handleFileChange(e.target.files as any)}
        onDelete={(file: FileType) => handleDeleteImage(file)}
      />
      {data &&
        data.map((topic) => (
          <Accordion
            key={topic.id}
            component={
              topic.nested &&
              topic.nested.map((principle) => {
                if (principle.nested) {
                  return principle.nested.map((requirement) => {
                    if (requirement.nested && requirement.nested.length > 0) {
                      return requirement.nested.map((subRequirement) => (
                        <Accordion
                          key={subRequirement.id}
                          component={
                            <VolumeCheckbox
                              data={[subRequirement]}
                              comments={comments}
                              setComments={setComments}
                              isComment={true}
                              error={error}
                            />
                          }
                          title={subRequirement.name}
                        />
                      ));
                    } else {
                      return (
                        <Accordion
                          key={principle.id}
                          component={
                            <VolumeCheckbox
                              data={principle.nested || []}
                              comments={comments}
                              setComments={setComments}
                              isComment={true}
                              error={error}
                            />
                          }
                          title={principle.name}
                        />
                      );
                    }
                  });
                } else {
                  return (
                    <Accordion
                      key={principle.id}
                      component={
                        <VolumeCheckbox
                          data={principle.nested || []}
                          comments={comments}
                          setComments={setComments}
                          isComment={true}
                          error={error}
                        />
                      }
                      title={principle.name}
                    />
                  );
                }
              })
            }
            title={topic.name}
          />
        ))}
    </DivBodyModal>
  );
}
