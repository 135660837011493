import { useState } from 'react';
import { MenuItemType, RecursiveMenuProps } from './RecursiveMenu.interface';
import InputCheckbox from '../InputCheckbox/InputCheckbox';
import Text from '../../Atoms/Text/Text';
import Icon from '../../Atoms/Icon/Icon';
import { BiChevronDown, BiChevronRight } from 'react-icons/bi';
import { useTranslation } from 'react-i18next';
import { DrawerItem, Li, Ul } from './RecursiveMenu.styles';

const RecursiveMenu = ({
  items,
  onClick,
  selectAll,
  checkbox = false,
  selected,
  setSelected
}: RecursiveMenuProps) => {
  const { t: translate } = useTranslation();
  const [expanded, setExpanded] = useState<{ [key: string]: boolean }>({});

  const handleExpanded = (id: string) => {
    setExpanded((prev) => ({
      ...prev,
      [id]: !prev[id]
    }));
  };

  const handleSelect = (menuItem: MenuItemType) => {
    if (!menuItem?.children || menuItem?.children.length === 0) {
      const isSelected = selected?.some((item) => item?.id === menuItem?.id);
      if (isSelected) {
        setSelected?.((prev) =>
          prev.filter((item) => item?.id !== menuItem?.id)
        );
      } else {
        setSelected?.((prev) => [...prev, menuItem]);
      }

      onClick([menuItem]);
    } else {
      handleExpanded(menuItem?.id);
    }
  };

  const handleSelectAll = (menuItems: MenuItemType[]) => {
    const hasAnySelected = menuItems.some((item) =>
      selected?.some((selectedItem) => selectedItem?.id === item?.id)
    );

    if (hasAnySelected) {
      setSelected?.((prev) =>
        prev.filter(
          (item) => !menuItems.some((menuItem) => menuItem?.id === item?.id)
        )
      );
    }

    const isSelected = menuItems.every((item) =>
      selected?.some((selectedItem) => selectedItem?.id === item?.id)
    );

    if (isSelected) {
      setSelected?.((prev) =>
        prev.filter(
          (item) => !menuItems.some((menuItem) => menuItem?.id === item?.id)
        )
      );
      selectAll?.(menuItems[0]?.identifier);
      return;
    }

    setSelected?.((prev) => [...prev, ...menuItems]);
    selectAll?.(menuItems);
  };

  const handleClick = (item: MenuItemType) => {
    onClick([item]);
  };

  return (
    <Ul>
      {items[0]?.children && items[0]?.children.length === 0 && checkbox && (
        <InputCheckbox
          label={translate('selectAll')}
          name="selectall"
          onChange={() => handleSelectAll(items)}
          value={
            selected?.filter((item) => item.identifier === items[0].identifier)
              .length === items.length
          }
        />
      )}
      {items?.map((item) => (
        <Li key={item?.id}>
          {item?.children && item?.children.length > 0 && (
            <DrawerItem onClick={() => handleSelect(item)}>
              <Text
                type="label"
                weight="semiBold"
                onClick={(event) => {
                  event.stopPropagation();
                  handleSelect(item);
                }}
                style={{
                  cursor: 'pointer'
                }}
              >
                {item?.children && item?.children.length > 0 && (
                  <Icon
                    Icon={expanded[item?.id] ? BiChevronDown : BiChevronRight}
                    style={{ marginRight: 8 }}
                    size={16}
                  />
                )}
                {item?.icon && (
                  <Icon
                    Icon={item?.icon}
                    size={16}
                    style={{ marginRight: 3 }}
                  />
                )}
                {translate(item.name)}
              </Text>
            </DrawerItem>
          )}
          {item?.children &&
            item?.children.length === 0 &&
            (checkbox ? (
              <InputCheckbox
                label={translate(item.name)}
                name={item?.id}
                onChange={() => handleSelect(item)}
                value={selected?.some(
                  (selectedItem) => selectedItem?.id === item?.id
                )}
              />
            ) : (
              <DrawerItem onClick={() => handleClick(item)}>
                {item?.icon && (
                  <Icon
                    Icon={item?.icon}
                    size={16}
                    style={{ marginRight: 3 }}
                  />
                )}
                <Text
                  type="label"
                  onClick={(event) => {
                    event.stopPropagation();
                    handleClick(item);
                  }}
                  style={{
                    cursor: 'pointer'
                  }}
                >
                  {translate(item.name)}
                </Text>
              </DrawerItem>
            ))}

          {expanded[item?.id] && item?.children && (
            <RecursiveMenu
              items={item?.children}
              onClick={onClick}
              selectAll={selectAll}
              selected={selected}
              setSelected={setSelected}
              checkbox={checkbox}
            />
          )}
        </Li>
      ))}
    </Ul>
  );
};

export default RecursiveMenu;
