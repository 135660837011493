import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import * as z from 'zod';
import { AtoRecordRedAlertPeriodType } from '../../../../data/graphql/base-schema';
import {
  SaveRecordRedAlertPeriodDocument,
  SaveRecordRedAlertPeriodMutation,
  SaveRecordRedAlertPeriodMutationVariables
} from '../../../../data/graphql/generated/saveRecordRedAlertPeriod';
import {
  UpdateRecordRedAlertPeriodDocument,
  UpdateRecordRedAlertPeriodMutation,
  UpdateRecordRedAlertPeriodMutationVariables
} from '../../../../data/graphql/generated/updateRecordRedAlertPeriod';
import {
  FindDataByRecordDocument,
  FindDataByRecordQuery
} from '../../../../data/graphql/query/generated/findDataByRecord';
import ErrorsTreatments from '../../../../utils/errorTreatment';
import { toastfyError, toastfySuccess } from '../../../Toastify';
import Button from '../../Atoms/Button/Button';
import DatepickerInput from '../../Molecules/DatepickerInput/DatepickerInput';
import { AlertPeriodProps } from './AlertPeriodForm.interface';
import {
  DivButtonArea,
  DivInputsArea,
  HolderForm
} from './AlertPeriodForm.styles';

const queryRedAlertPeriods = gql`
  query FindDataByRecord($recordId: String!) {
    findDataByRecord(recordId: $recordId) {
      redAlertPeriods {
        end
        id
        start
      }
      recordDate
    }
  }
`;

const alertPeriodSchema = z.object({
  initialPeriod: z
    .date()
    .nullable()
    .refine((val) => val !== null, {
      message: 'fieldIsRequired'
    }),
  finalPeriod: z
    .date()
    .nullable()
    .refine((val) => val !== null, {
      message: 'fieldIsRequired'
    })
});

type AlertPeriodSchemaType = z.infer<typeof alertPeriodSchema>;

const AlertPeriodForm = ({ setShowModal, idAlertPeriod }: AlertPeriodProps) => {
  const { t } = useTranslation();
  const { recordId } = useParams();
  const [saveRecordRedAlertPeriod] = useMutation<
    SaveRecordRedAlertPeriodMutation,
    SaveRecordRedAlertPeriodMutationVariables
  >(SaveRecordRedAlertPeriodDocument);
  const [updateRecordRedAlertPeriod] = useMutation<
    UpdateRecordRedAlertPeriodMutation,
    UpdateRecordRedAlertPeriodMutationVariables
  >(UpdateRecordRedAlertPeriodDocument);

  const [getAlertPeriod, { data }] = useLazyQuery(queryRedAlertPeriods);

  const editAlertPeriod = data?.findDataByRecord.redAlertPeriods.find(
    (item: AtoRecordRedAlertPeriodType) => item.id === idAlertPeriod
  );
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<AlertPeriodSchemaType>({
    resolver: zodResolver(alertPeriodSchema),
    defaultValues: async () => {
      const responseAlertPeriod = await getAlertPeriod({
        variables: { recordId: recordId }
      });
      const alertPeriod =
        responseAlertPeriod.data.findDataByRecord.redAlertPeriods.find(
          (item: AtoRecordRedAlertPeriodType) => item.id === idAlertPeriod
        );
      if (alertPeriod) {
        return {
          initialPeriod: new Date(alertPeriod.start),
          finalPeriod: new Date(alertPeriod.end)
        };
      } else {
        return {
          initialPeriod: new Date(),
          finalPeriod: new Date()
        };
      }
    }
  });

  const handleSubmitAlertPeriodForm = (data: AlertPeriodSchemaType) => {
    const { finalPeriod, initialPeriod } = data;
    if (editAlertPeriod?.id) {
      updateRecordRedAlertPeriod({
        variables: {
          data: {
            end: finalPeriod,
            id: editAlertPeriod.id,
            start: initialPeriod
          }
        },
        onCompleted: () => {
          setShowModal(false);
          toastfySuccess(t('editedSuccessfully'));
        },
        onError: ({ graphQLErrors }) => {
          const errorMessage = ErrorsTreatments(graphQLErrors[0].message, t);
          toastfyError(errorMessage);
        },
        update: (cache, { data }) => {
          if (!data) return;
          const existingData = cache.readQuery({
            query: FindDataByRecordDocument,
            variables: {
              recordId: recordId
            }
          }) as FindDataByRecordQuery;

          const updatedData = {
            ...existingData,
            findDataByRecord: {
              ...existingData.findDataByRecord,
              redAlertPeriods:
                existingData.findDataByRecord.redAlertPeriods.map(
                  (item: AtoRecordRedAlertPeriodType) =>
                    item.id === editAlertPeriod?.id
                      ? {
                          ...item,
                          id: editAlertPeriod?.id,
                          end: finalPeriod,
                          start: initialPeriod,
                          __typename: 'AtoRecordRedAlertPeriodType'
                        }
                      : item
                )
            }
          };

          cache.writeQuery({
            query: FindDataByRecordDocument,
            variables: {
              recordId: recordId
            },
            data: updatedData
          });
        }
      });
    } else {
      saveRecordRedAlertPeriod({
        variables: {
          data: {
            recordId: recordId!,
            start: initialPeriod,
            end: finalPeriod
          }
        },
        onCompleted: () => {
          setShowModal(false);
          toastfySuccess(t('registeredSuccessfully'));
        },
        onError: ({ graphQLErrors }) => {
          const errorMessage = ErrorsTreatments(graphQLErrors[0].message, t);
          toastfyError(errorMessage);
        },
        update: (cache, { data }) => {
          const existingData = cache.readQuery({
            query: FindDataByRecordDocument,
            variables: {
              recordId: recordId
            }
          }) as FindDataByRecordQuery;

          const updatedData = {
            ...existingData,
            findDataByRecord: {
              ...existingData.findDataByRecord,
              redAlertPeriods: [
                ...existingData.findDataByRecord.redAlertPeriods,
                {
                  id: data?.saveRecordRedAlertPeriod,
                  start: initialPeriod,
                  end: finalPeriod,
                  __typename: 'AtoRecordRedAlertPeriodType'
                }
              ]
            }
          };

          cache.writeQuery({
            query: FindDataByRecordDocument,
            variables: {
              recordId: recordId
            },
            data: updatedData
          });
        }
      });
    }
  };

  return (
    <HolderForm>
      <DivInputsArea>
        <DatepickerInput
          placeholder={t('initialPeriod')}
          enableMinMaxDateSelection={true}
          minDate={
            editAlertPeriod?.rdoDate
              ? new Date(editAlertPeriod?.rdoDate)
              : new Date(editAlertPeriod?.start)
          }
          maxDate={
            editAlertPeriod?.rdoDate
              ? new Date(editAlertPeriod?.rdoDate)
              : new Date(editAlertPeriod?.start)
          }
          time={true}
          error={!!errors.initialPeriod}
          errorMessage={errors.initialPeriod?.message}
          control={control}
          name="initialPeriod"
          label={t('initialPeriod')}
        />
        <DatepickerInput
          placeholder={t('finalPeriod')}
          enableMinMaxDateSelection={true}
          minDate={
            editAlertPeriod?.rdoDate
              ? new Date(editAlertPeriod?.rdoDate)
              : new Date(editAlertPeriod?.end)
          }
          maxDate={
            editAlertPeriod?.rdoDate
              ? new Date(editAlertPeriod?.rdoDate)
              : new Date(editAlertPeriod?.end)
          }
          time={true}
          error={!!errors.finalPeriod}
          errorMessage={errors.finalPeriod?.message}
          control={control}
          name="finalPeriod"
          label={t('finalPeriod')}
        />
      </DivInputsArea>
      <DivButtonArea>
        <Button
          text={t('Cancel')}
          variant={'secondary'}
          size="small"
          onClick={() => {
            setShowModal(false);
          }}
        />
        <Button
          text={t('Save')}
          variant={'primary'}
          size="small"
          onClick={handleSubmit(handleSubmitAlertPeriodForm)}
        />
      </DivButtonArea>
    </HolderForm>
  );
};

export default AlertPeriodForm;
