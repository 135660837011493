import { useState } from 'react';
import InputCheckbox from '../../Molecules/InputCheckbox/InputCheckbox';
import Button from '../../Atoms/Button/Button';
import { useTranslation } from 'react-i18next';
import {
  DivBody,
  DivInputs,
  HolderCheckbox,
  HolderCheckboxLis,
  HolderExports,
  HolderFooter,
  HolderInputsForm
} from './ExportTableInstruments.styles';
import { ExportTableInstrumentsProps } from './ExportTableInstruments.interface';
import * as z from 'zod';
import { useParams, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import DatepickerInput from '../../Molecules/DatepickerInput/DatepickerInput';
import InputSelectSearch from '../../Molecules/InputSelectSearch/InputSelectSearch';
import InputText from '../../Molecules/InputText/InputText';
import Enums from '../../../../utils/enumns';
import Text from '../../Atoms/Text/Text';
import { useLazyQuery } from '@apollo/client';
import {
  ExportInstrumentsByStructureDocument,
  ExportInstrumentsByStructureQuery,
  ExportInstrumentsByStructureQueryVariables
} from '../../../../data/graphql/query/generated/exportInstrumentsByStructure.query';
import { toastfyError, toastfySuccess } from '../../../Toastify';
import { InstrumentTypeEnum } from '../../../../data/graphql/base-schema';

const FilterFormType = z.object({
  name: z.string().optional(),
  depth: z.string().optional(),
  activationDate: z.date().nullable().optional(),
  deactivationDate: z.date().nullable().optional(),
  installationDate: z.date().nullable().optional(),
  operationalStatus: z
    .object({
      value: z.string(),
      label: z.string()
    })
    .nullable()
    .optional(),
  bottomCote: z.string().optional(),
  topCote: z.string().optional(),
  coordinateE: z.string().optional(),
  coordinateN: z.string().optional(),
  installLocation: z
    .object({
      value: z.string(),
      label: z.string()
    })
    .nullable()
    .optional(),
  readingType: z
    .object({
      value: z.string(),
      label: z.string()
    })
    .nullable()
    .optional()
});

type FilterFormValues = z.infer<typeof FilterFormType>;

const ExportTableInstruments = ({ columns }: ExportTableInstrumentsProps) => {
  const { t: translate } = useTranslation();
  const { instrumentType, structureId } = useParams();
  const [searchFilterParams, setSearchFilterParams] = useSearchParams();
  const { TypeOfInstallation, TypeOfReading, instrumentsEnum } = Enums();
  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    columns.map((col) => col.key)
  );

  const operationalStatusOptions = [
    { value: 'Active', label: translate('Active') },
    { value: 'Inactive', label: translate('Inactive') }
  ];

  const [exportTable] = useLazyQuery<
    ExportInstrumentsByStructureQuery,
    ExportInstrumentsByStructureQueryVariables
  >(ExportInstrumentsByStructureDocument);

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<FilterFormValues>({
    resolver: zodResolver(FilterFormType)
  });

  const handleCheckboxChange = (checked: boolean, key: string) => {
    setSelectedColumns((prevSelected) =>
      checked
        ? [...prevSelected, key]
        : prevSelected.filter((colKey) => colKey !== key)
    );
  };

  const handleExport = (data: FilterFormValues) => {
    exportTable({
      variables: {
        instrumentType: instrumentsEnum[
          instrumentType as keyof typeof instrumentsEnum
        ] as unknown as InstrumentTypeEnum,
        structureId: structureId!,
        selectedFields: selectedColumns,
        filters: {
          name: data.name,
          depth: data.depth ? parseFloat(data.depth) : null,
          activationDate: data.activationDate,
          deactivationDate: data.deactivationDate,
          installationDate: data.installationDate,
          operationalStatus: data.operationalStatus?.value,
          bottomCote: data.bottomCote ? parseFloat(data.bottomCote) : null,
          topCote: data.topCote ? parseFloat(data.topCote) : null,
          coordinateE: data.coordinateE ? parseFloat(data.coordinateE) : null,
          coordinateN: data.coordinateN ? parseFloat(data.coordinateN) : null,
          installLocation: data.installLocation?.value,
          readingType: data.readingType?.value
        }
      },
      onCompleted: () => {
        toastfySuccess(translate('ExportTableEmail'));
        const params = new URLSearchParams(searchFilterParams);
        params.delete('exporttable');
        setSearchFilterParams(params);
      },
      onError: () => {
        toastfyError(translate('error'));
      }
    });
  };

  return (
    <HolderExports>
      <DivBody>
        <Text type="label" color="black">
          {translate('Filters')}
        </Text>
        <HolderInputsForm>
          <DivInputs>
            <InputText
              width="300px"
              label={translate('name')}
              type="text"
              control={control}
              name="name"
              error={!!errors.name}
              errorMessage={errors?.name?.message}
            />
          </DivInputs>
          {instrumentType === 'piezometer' && (
            <>
              <DivInputs>
                <InputText
                  width="300px"
                  label={translate('depth')}
                  type="text"
                  control={control}
                  name="depth"
                  error={!!errors.depth}
                  errorMessage={errors?.depth?.message}
                />
              </DivInputs>
              <DivInputs>
                <InputText
                  width="300px"
                  label={translate('bottomCote')}
                  type="text"
                  control={control}
                  name="bottomCote"
                  error={!!errors.bottomCote}
                  errorMessage={errors?.bottomCote?.message}
                />
              </DivInputs>
              <DivInputs>
                <InputText
                  width="300px"
                  label={translate('topCote')}
                  type="text"
                  control={control}
                  name="topCote"
                  error={!!errors.topCote}
                  errorMessage={errors?.topCote?.message}
                />
              </DivInputs>
            </>
          )}
          <DivInputs>
            <InputText
              width="300px"
              label={translate('coordinateE')}
              type="text"
              control={control}
              name="coordinateE"
              error={!!errors.coordinateE}
              errorMessage={errors?.coordinateE?.message}
            />
          </DivInputs>
          <DivInputs>
            <InputText
              width="300px"
              label={translate('coordinateN')}
              type="text"
              control={control}
              name="coordinateN"
              error={!!errors.coordinateN}
              errorMessage={errors?.coordinateN?.message}
            />
          </DivInputs>
          <DivInputs>
            <InputSelectSearch
              control={control}
              placeholder={translate('operationalStatus')}
              width="300px"
              label={translate('operationalStatus')}
              options={operationalStatusOptions}
              name="operationalStatus"
              error={!!errors.operationalStatus}
              errorMessage={errors?.operationalStatus?.message}
            />
          </DivInputs>
          <DivInputs>
            <InputSelectSearch
              control={control}
              placeholder={translate('installationSite')}
              width="300px"
              label={translate('installationSite')}
              options={TypeOfInstallation}
              name="installLocation"
              error={!!errors.installLocation}
              errorMessage={errors?.installLocation?.message}
            />
          </DivInputs>
          <DivInputs>
            <InputSelectSearch
              control={control}
              placeholder={translate('readingType')}
              width="300px"
              label={translate('readingType')}
              options={TypeOfReading}
              name="readingType"
              error={!!errors.readingType}
              errorMessage={errors?.readingType?.message}
            />
          </DivInputs>
          <DivInputs>
            <DatepickerInput
              label={translate('installationDate')}
              control={control}
              name="installationDate"
              placeholder={translate('installationDate')}
              time={false}
              error={!!errors.installationDate}
              errorMessage={errors?.installationDate?.message}
            />
          </DivInputs>
          <DivInputs>
            <DatepickerInput
              label={translate('activationDate')}
              control={control}
              name="activationDate"
              placeholder={translate('activationDate')}
              time={false}
              error={!!errors.activationDate}
              errorMessage={errors?.activationDate?.message}
            />
          </DivInputs>

          <DivInputs>
            <DatepickerInput
              label={translate('deactivationDate')}
              control={control}
              name="deactivationDate"
              placeholder={translate('deactivationDate')}
              time={false}
              error={!!errors.deactivationDate}
              errorMessage={errors?.deactivationDate?.message}
            />
          </DivInputs>
        </HolderInputsForm>
        <Text type="label" color="black">
          {translate('Fields')}
        </Text>
        <HolderCheckboxLis>
          {columns.map(({ key, label }) => (
            <HolderCheckbox key={key}>
              <InputCheckbox
                label={label}
                name={key}
                value={selectedColumns.includes(key)}
                onChange={(newValue) => handleCheckboxChange(newValue, key)}
              />
            </HolderCheckbox>
          ))}
        </HolderCheckboxLis>
      </DivBody>
      <HolderFooter>
        <Button
          text={translate('ExportTable')}
          variant="primary"
          onClick={handleSubmit(handleExport)}
        />
      </HolderFooter>
    </HolderExports>
  );
};

export default ExportTableInstruments;
