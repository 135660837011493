import { useMutation } from '@apollo/client';
import {
  GenerateInspectionPdfDocument,
  GenerateInspectionPdfMutation,
  GenerateInspectionPdfMutationVariables
} from '../graphql/generated/generateInspectionPdf.mutation';
import { FindAllInspectionsByStructureDocument } from '../graphql/query/generated/findAllInspectionsByStructure.query';

export function useGenerateInspectionPdf(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [generateInspectionPdf, { data, error, loading }] = useMutation<
    GenerateInspectionPdfMutation,
    GenerateInspectionPdfMutationVariables
  >(GenerateInspectionPdfDocument, {
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError,
    refetchQueries: [FindAllInspectionsByStructureDocument]
  });

  return {
    generateInspectionPdf,
    data,
    error,
    loading
  };
}
