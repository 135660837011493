import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type CreateUserInstrumentNotificationMutationVariables = Types.Exact<{
  data: Types.CreateUserNotificationInput;
}>;


export type CreateUserInstrumentNotificationMutation = { __typename?: 'Mutation', createUserInstrumentNotification: string };


export const CreateUserInstrumentNotificationDocument = gql`
    mutation CreateUserInstrumentNotification($data: CreateUserNotificationInput!) {
  createUserInstrumentNotification(data: $data)
}
    `;
export type CreateUserInstrumentNotificationMutationFn = Apollo.MutationFunction<CreateUserInstrumentNotificationMutation, CreateUserInstrumentNotificationMutationVariables>;
export type CreateUserInstrumentNotificationMutationResult = Apollo.MutationResult<CreateUserInstrumentNotificationMutation>;
export type CreateUserInstrumentNotificationMutationOptions = Apollo.BaseMutationOptions<CreateUserInstrumentNotificationMutation, CreateUserInstrumentNotificationMutationVariables>;