import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type FindGeneralInfoQueryVariables = Types.Exact<{
  atoId: Types.Scalars['String']['input'];
}>;


export type FindGeneralInfoQuery = { __typename?: 'Query', findGeneralInfo: { __typename?: 'FindGeneralInfoType', activitiesCount: number, contract: string, contractualPeriod: string, finalDate: Date, imagesCount: number, initialDate: Date, observationsCount: number, occurrencesCount: number, recentPhotos: Array<string>, recentVideos: Array<string>, recordsCount: number, remainingTerm: string, situation: Types.AtoSituationEnum, title: string, videosCount: number, recentRecords: Array<{ __typename?: 'RecentRecordsType', date: Date, imagesCount: number, videosCount: number, id: string, recordNumber: string, situation: Types.AtoSituationEnum }> } };


export const FindGeneralInfoDocument = gql`
    query FindGeneralInfo($atoId: String!) {
  findGeneralInfo(atoId: $atoId) {
    activitiesCount
    contract
    contractualPeriod
    finalDate
    imagesCount
    initialDate
    observationsCount
    occurrencesCount
    recentPhotos
    recentRecords {
      date
      imagesCount
      videosCount
      id
      recordNumber
      situation
    }
    recentVideos
    recordsCount
    remainingTerm
    situation
    title
    videosCount
  }
}
    `;
export type FindGeneralInfoQueryResult = Apollo.QueryResult<FindGeneralInfoQuery, FindGeneralInfoQueryVariables>;