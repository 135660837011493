const ToastifyModel = () => {
  const toastifyMessage = {
    error: 'error',
    loading: 'loading',
    updating: 'updating',
    registerInstrument: 'registerInstrument',
    updatingInstrument: 'updatingInstrument',
    successEditInstrument: 'successEditInstrument',
    successCreateControlLevel: 'successCreateControlLevel',
    successRegisterInstrument: 'successRegisterInstrument',
    registerReading: 'registerReading',
    successEditReading: 'successEditReading',
    successRegisterReading: 'successRegisterReading',
    updatingCote: 'updatingCote',
    fillRequiredFields: 'fillRequiredFields',
    successUpdateCote: 'successUpdateCote',
    invalidCoordinateE: 'invalidCoordinateE',
    invalidCoordinateN: 'invalidCoordinateN'
  };

  const alerts = {
    selectAStructure: 'SelectAStructure',
    selectInstrument: 'SelectInstrument'
  };

  return { toastifyMessage, alerts };
};

export default ToastifyModel;
