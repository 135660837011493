import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BiSolidDownArrow,
  BiSolidPencil,
  BiSolidUpArrow
} from 'react-icons/bi';
import { MdDelete } from 'react-icons/md';
import {
  ActionsRow,
  ArrowIconStyle,
  TableAdmin,
  TableBody,
  TableCell,
  TableCollumName,
  TableHeader,
  TableHeaderCell,
  TableRow
} from '../../Screens/AdminScreen/AdminScreenStyle';

type AdminTableProps = {
  tableColumns: string[];
  tableValues: { [key: string]: string }[];
  subLine?: boolean;
  edit: { status: boolean; data: any };
  setEdit: Dispatch<SetStateAction<{ status: boolean; data: any }>>;
  deleting: { status: boolean; data: any };
  setDeleting: Dispatch<SetStateAction<{ status: boolean; data: any }>>;
};

function AdminTable(props: AdminTableProps) {
  const { t } = useTranslation();
  const [filteredValues] = useState<string[]>([]);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');

  const [users, setUsers] = useState<{ [key: string]: string }[]>([]);
  const [expandedRows, setExpandedRows] = useState<number[]>([]);

  useEffect(() => {
    setUsers(props.tableValues);
  }, [props]);

  const toggleSortOrder = () => {
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const sortUsers = (column: string) => {
    toggleSortOrder();

    const sortedUsers = [...users].sort(
      (a: { [key: string]: string }, b: { [key: string]: string }) => {
        const valueA = a[column];
        const valueB = b[column];

        if (valueA > valueB) {
          return sortOrder === 'asc' ? -1 : 1;
        } else if (valueA < valueB) {
          return sortOrder === 'asc' ? 1 : -1;
        }

        return 0;
      }
    );
    setUsers(sortedUsers);
  };

  const toggleRowExpansion = (rowIndex: number) => {
    setExpandedRows([...expandedRows, rowIndex]);
  };

  return (
    <>
      <TableAdmin>
        <TableHeader>
          <TableRow>
            {props.tableColumns.map((th: string, index: number) => {
              return (
                <TableHeaderCell key={index}>
                  <TableCollumName>{t(th)}</TableCollumName>
                  {sortOrder === 'asc' ? (
                    <BiSolidDownArrow
                      style={ArrowIconStyle}
                      onClick={() => {
                        sortUsers(th);
                      }}
                    />
                  ) : (
                    <BiSolidUpArrow
                      style={ArrowIconStyle}
                      onClick={() => {
                        sortUsers(th);
                      }}
                    />
                  )}
                </TableHeaderCell>
              );
            })}
            <TableHeaderCell>{t('actions')}</TableHeaderCell>
          </TableRow>
        </TableHeader>
        <TableBody>
          {users
            .filter((user) =>
              Object.values(user).every((value) => {
                if (typeof value === 'string') {
                  return !filteredValues.includes(value);
                }
                return true;
              })
            )
            .map((row: any, rowIndex) => (
              <React.Fragment key={rowIndex}>
                <TableRow>
                  {props.tableColumns.map((column, columnIndex) => {
                    return (
                      <TableCell key={columnIndex}>
                        {row[column] ? t(row[column]) : '-'}
                        {props.subLine &&
                        column === 'name' &&
                        row.associatedStructures &&
                        row.associatedStructures.length > 0 ? (
                          <BiSolidDownArrow
                            style={{
                              position: 'relative',
                              left: 10,
                              fontSize: '8px',
                              cursor: 'pointer'
                            }}
                            onClick={() => toggleRowExpansion(rowIndex)}
                          />
                        ) : (
                          ''
                        )}
                      </TableCell>
                    );
                  })}
                  <TableCell>
                    {row.status === t('inactive') ? (
                      '-'
                    ) : row.status === t('active') ||
                      row.Permission ||
                      row.module ||
                      row.Section ? (
                      <ActionsRow>
                        {!row.AlertLevel ? (
                          <BiSolidPencil
                            onClick={() => {
                              props.setEdit({ status: true, data: row });
                            }}
                          />
                        ) : (
                          ''
                        )}
                        <MdDelete
                          onClick={() => {
                            props.setDeleting({ status: true, data: row });
                          }}
                        />
                      </ActionsRow>
                    ) : (
                      '-'
                    )}
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
        </TableBody>
      </TableAdmin>
    </>
  );
}

export default AdminTable;
