import { useMutation } from '@apollo/client';
import {
  UpdateDateDocument,
  UpdateDateMutation,
  UpdateDateMutationVariables
} from '../graphql/generated/updateDate';

export function useUpdateDate(onCompleted?: () => void, onError?: () => void) {
  const [updateDate, { data, error, loading }] = useMutation<
    UpdateDateMutation,
    UpdateDateMutationVariables
  >(UpdateDateDocument, {
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    updateDate,
    data,
    error,
    loading
  };
}
