import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ListReadingByPluviometerPagQueryVariables = Types.Exact<{
  structureInfo: Types.StructureInfoInput;
  pageInfo?: Types.InputMaybe<Types.PageInput>;
  sortInfo?: Types.InputMaybe<Array<Types.SortInput> | Types.SortInput>;
  filters?: Types.InputMaybe<Types.FilterInput>;
}>;


export type ListReadingByPluviometerPagQuery = { __typename?: 'Query', listReadingByPluviometerPag: { __typename?: 'PaginatedReadingType', count: number, nodes: Array<{ __typename?: 'PaginatedPluviometerReadingType', observation?: string | null, date: Date, rainfall: number, name: string, structureType?: string | null, coordinateE: number, coordinateN: number, installLocation?: string | null, readingType: Types.InstrumentReadingTypeEnum, operationalStatus: Types.InstrumentStatusEnum, instrumentId: string, readingId: string }>, pageInfo: { __typename?: 'PageInfo', page: number, offset: number, limit: number, totalPages: number, hasNextPage: boolean, hasPreviousPage: boolean } } };


export const ListReadingByPluviometerPagDocument = gql`
    query ListReadingByPluviometerPag($structureInfo: StructureInfoInput!, $pageInfo: PageInput, $sortInfo: [SortInput!], $filters: FilterInput) {
  listReadingByPluviometerPag(
    structureInfo: $structureInfo
    pageInfo: $pageInfo
    sortInfo: $sortInfo
    filters: $filters
  ) {
    nodes {
      observation
      date
      rainfall
      name
      structureType
      coordinateE
      coordinateN
      installLocation
      readingType
      operationalStatus
      instrumentId
      readingId
    }
    count
    pageInfo {
      page
      offset
      limit
      totalPages
      hasNextPage
      hasPreviousPage
    }
  }
}
    `;
export type ListReadingByPluviometerPagQueryResult = Apollo.QueryResult<ListReadingByPluviometerPagQuery, ListReadingByPluviometerPagQueryVariables>;