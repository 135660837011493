import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type SaveRecordRedAlertPeriodMutationVariables = Types.Exact<{
  data: Types.SaveAtoRecordRedAlertPeriodInput;
}>;


export type SaveRecordRedAlertPeriodMutation = { __typename?: 'Mutation', saveRecordRedAlertPeriod: string };


export const SaveRecordRedAlertPeriodDocument = gql`
    mutation SaveRecordRedAlertPeriod($data: SaveAtoRecordRedAlertPeriodInput!) {
  saveRecordRedAlertPeriod(data: $data)
}
    `;
export type SaveRecordRedAlertPeriodMutationFn = Apollo.MutationFunction<SaveRecordRedAlertPeriodMutation, SaveRecordRedAlertPeriodMutationVariables>;
export type SaveRecordRedAlertPeriodMutationResult = Apollo.MutationResult<SaveRecordRedAlertPeriodMutation>;
export type SaveRecordRedAlertPeriodMutationOptions = Apollo.BaseMutationOptions<SaveRecordRedAlertPeriodMutation, SaveRecordRedAlertPeriodMutationVariables>;