import { FaRegFolderOpen } from 'react-icons/fa6';
import Icon from '../../Atoms/Icon/Icon';
import Text from '../../Atoms/Text/Text';
import { VolumeProps } from './Volume.interface';
import {
  DivContainerVolumeList,
  DivPadding,
  DivVolumeContainer,
  DivVolumeList
} from './Volume.styles';
import { useNavigate } from 'react-router-dom';

const Volume = ({ data, title, url }: VolumeProps) => {
  const navigate = useNavigate();
  const renderNestedVolumes = (data: any, url: string) => {
    return data.map(
      (item: { id: string; name: string; nested: VolumeProps[] }) => (
        <DivPadding key={item.id}>
          <DivVolumeList>
            <Text type={'span'}>{item.name}</Text>
            {(!item.nested || item.nested.length === 0) && (
              <Icon
                Icon={FaRegFolderOpen}
                variant="small"
                onClick={() => {
                  navigate(`${url}/${item.id}`);
                }}
              />
            )}
          </DivVolumeList>
          {item.nested &&
            item.nested.length > 0 &&
            renderNestedVolumes(item.nested, url)}
        </DivPadding>
      )
    );
  };

  return (
    <DivVolumeContainer>
      {title && <Text type={'h4'}>{title}</Text>}
      <DivContainerVolumeList>
        {renderNestedVolumes(data, url)}
      </DivContainerVolumeList>
    </DivVolumeContainer>
  );
};

export default Volume;
