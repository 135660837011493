import Skeleton from 'react-loading-skeleton';
import Text from '../../Atoms/Text/Text';
import { SquareCardProps } from './SquareCard.interfaces';
import {
  ContainerSkeleton,
  DivContainerCard,
  DivContainerLabelCard,
  DivContainerSpanCard
} from './SquareCard.styles';

const SquareCard = ({ label, value }: SquareCardProps) => {
  return (
    <DivContainerCard>
      <DivContainerLabelCard>
        <Text type="label" size="small" color="black">
          {label}
        </Text>
      </DivContainerLabelCard>
      <DivContainerSpanCard>
        <Text type="span" size="large">
          {value}
        </Text>
      </DivContainerSpanCard>
    </DivContainerCard>
  );
};

export const SquareCardSkeleton = () => (
  <ContainerSkeleton>
    <Skeleton
      wrapper={DivContainerCard}
      style={{ position: 'absolute', height: '120px', width: '120px' }}
    />
  </ContainerSkeleton>
);

export default SquareCard;
