import styled from 'styled-components';
interface TableRowContentProps {
  onClick?: () => void;
}

export const TableRowContent = styled.tr<TableRowContentProps>`
  &:hover {
    background-color: #f1f1f1;
  }

  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
`;
