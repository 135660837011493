import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type DeleteRecordWorkShiftMutationVariables = Types.Exact<{
  deleteRecordWorkShiftId: Types.Scalars['String']['input'];
}>;


export type DeleteRecordWorkShiftMutation = { __typename?: 'Mutation', deleteRecordWorkShift: string };


export const DeleteRecordWorkShiftDocument = gql`
    mutation DeleteRecordWorkShift($deleteRecordWorkShiftId: String!) {
  deleteRecordWorkShift(id: $deleteRecordWorkShiftId)
}
    `;
export type DeleteRecordWorkShiftMutationFn = Apollo.MutationFunction<DeleteRecordWorkShiftMutation, DeleteRecordWorkShiftMutationVariables>;
export type DeleteRecordWorkShiftMutationResult = Apollo.MutationResult<DeleteRecordWorkShiftMutation>;
export type DeleteRecordWorkShiftMutationOptions = Apollo.BaseMutationOptions<DeleteRecordWorkShiftMutation, DeleteRecordWorkShiftMutationVariables>;