import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type ListNotificationsByModulePagQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.NotificationFilterInput>;
  moduleId: Types.Scalars['String']['input'];
  pageInfo: Types.PageInput;
}>;

export type ListNotificationsByModulePagQuery = {
  __typename?: 'Query';
  listNotificationsByModulePag: {
    __typename?: 'PaginatedListNotificationsByModuleType';
    count: number;
    nodes: Array<{
      __typename?: 'NotificationsByModuleType';
      contentId: string;
      unread: boolean;
      createdAt: Date;
      body?: string | null;
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      page: number;
      offset: number;
      limit: number;
      totalPages: number;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  };
};

export const ListNotificationsByModulePagDocument = gql`
  query ListNotificationsByModulePag(
    $filters: NotificationFilterInput
    $moduleId: String!
    $pageInfo: PageInput!
  ) {
    listNotificationsByModulePag(
      filters: $filters
      moduleId: $moduleId
      pageInfo: $pageInfo
    ) {
      nodes {
        contentId
        unread
        createdAt
        body
      }
      count
      pageInfo {
        page
        offset
        limit
        totalPages
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

export type ListNotificationsByModulePagQueryResult = Apollo.QueryResult<
  ListNotificationsByModulePagQuery,
  ListNotificationsByModulePagQueryVariables
>;
