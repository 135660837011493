import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetStructureSummaryQueryVariables = Types.Exact<{
  structureInfo: Types.StructureInfoInput;
}>;

export type GetStructureSummaryQuery = {
  __typename?: 'Query';
  getStructureSummary: {
    __typename?: 'StructureType';
    id: string;
    name: string;
    status: boolean;
    type: string;
    associatedStructures?: Array<{
      __typename?: 'AssociatedType';
      name: string;
      type?: string | null;
      active: boolean;
      isAssociated: boolean;
      id: string;
      summary: {
        __typename?: 'StructureSummaryType';
        id: string;
        ANMClassification: Types.AnmClassificationEnum;
        coordinateE: number;
        coordinateN: number;
        datumName: Types.DatumEnum;
        maxHeight?: number | null;
        operationEnd?: Date | null;
        operationStart?: Date | null;
        operationalStatus?: string | null;
        decharacterizationDate?: Date | null;
        potentialDamage?: Types.ClassificationEnum | null;
        riskClassification: Types.ClassificationEnum;
        utmZone?: string | null;
        isUTM: boolean;
      };
    }> | null;
    damRaisingHistory: Array<{
      __typename?: 'DamRaisingType';
      id: string;
      stage: string;
      elevation: number;
      date?: Date | null;
      method: string;
    }>;
    hydrologyInfos?: {
      __typename?: 'SummaryHydrologyType';
      id: string;
      volumeTotalElevationCrest?: number | null;
      spillwaySill?: number | null;
      NAMaximoMaximorum?: number | null;
      projectRainfall?: number | null;
      returnPeriod?: string | null;
      criticalDuration?: number | null;
      inflowRate?: number | null;
      outflowRate?: number | null;
      spillwayType?: string | null;
      reservoirContributionArea?: number | null;
    } | null;
    summary?: {
      __typename?: 'ListStructureSummary';
      id: string;
      ANMClassification: Types.AnmClassificationEnum;
      coordinateE: number;
      coordinateN: number;
      datumName: Types.DatumEnum;
      maxHeight?: number | null;
      operationEnd?: Date | null;
      operationStart?: Date | null;
      operationalStatus?: string | null;
      decharacterizationDate?: Date | null;
      potentialDamage?: Types.ClassificationEnum | null;
      riskClassification: Types.ClassificationEnum;
      utmZone?: string | null;
      isUTM: boolean;
      latitude?: number | null;
      longitude?: number | null;
    } | null;
    reservoirHandling?: {
      __typename?: 'StructureReservoirHandlingType';
      id: string;
      reservoirOccupiedArea?: number | null;
      reservoirOccupiedVolume?: number | null;
      reservoirTotalVolume?: number | null;
      waterVolume?: number | null;
      tailingVolumeHistory: Array<{
        __typename?: 'StructureDamTailingVolumeType';
        date: Date;
        id: string;
        volume: number;
      }>;
    } | null;
    riskAssessment?: {
      __typename?: 'RiskAssessmentType';
      id: string;
      downstreamEnvironmentalInterest?: string | null;
      downstreamHumanOccupation?: string | null;
      downstreamInstallations?: string | null;
      wasteType?: string | null;
    } | null;
    geometry?: {
      __typename?: 'StructureGeometryType';
      id: string;
      crestElevation?: number | null;
      crestLength?: number | null;
      crestWidth?: number | null;
      globalSlopeDownstream?: string | null;
      slopeBetweenBerms?: string | null;
      upstreamSlopeInclination?: string | null;
      bermLength?: number | null;
    } | null;
    sections?: Array<{
      __typename?: 'SectionType';
      id: string;
      name: string;
      type?: string | null;
      isUTM: boolean;
      status: boolean;
      initialCoordinateE: number;
      initialCoordinateN: number;
      finalCoordinateE: number;
      finalCoordinateN: number;
    }> | null;
    responsible: {
      __typename?: 'StructureResponsibleType';
      id: string;
      name: string;
    };
    complex?: {
      __typename?: 'StructureComplexType';
      id: string;
      name: string;
      address: string;
      neighborhood: string;
      city: string;
      state: string;
      country: string;
      zipCode: string;
      phone?: string | null;
    } | null;
    company: {
      __typename?: 'CompanyType';
      id: string;
      status: boolean;
      name: string;
      logo?: string | null;
      cnpj: string;
      address: {
        __typename?: 'CompanyAddressType';
        country: string;
        state: string;
        city: string;
        zipCode?: string | null;
        addressLine?: string | null;
        number?: number | null;
      };
    };
  };
};

export const GetStructureSummaryDocument = gql`
  query GetStructureSummary($structureInfo: StructureInfoInput!) {
    getStructureSummary(structureInfo: $structureInfo) {
      id
      associatedStructures {
        name
        type
        active
        isAssociated
        id
        summary {
          id
          ANMClassification
          coordinateE
          coordinateN
          datumName
          maxHeight
          operationEnd
          operationStart
          operationalStatus
          decharacterizationDate
          potentialDamage
          riskClassification
          utmZone
          isUTM
        }
      }
      damRaisingHistory {
        id
        stage
        elevation
        date
        method
      }
      hydrologyInfos {
        id
        volumeTotalElevationCrest
        spillwaySill
        NAMaximoMaximorum
        projectRainfall
        returnPeriod
        criticalDuration
        inflowRate
        outflowRate
        spillwayType
        reservoirContributionArea
      }
      name
      status
      summary {
        id
        ANMClassification
        coordinateE
        coordinateN
        datumName
        maxHeight
        operationEnd
        operationStart
        operationalStatus
        decharacterizationDate
        potentialDamage
        riskClassification
        utmZone
        isUTM
        latitude
        longitude
      }
      reservoirHandling {
        id
        reservoirOccupiedArea
        reservoirOccupiedVolume
        reservoirTotalVolume
        waterVolume
        tailingVolumeHistory {
          date
          id
          volume
        }
      }
      riskAssessment {
        id
        downstreamEnvironmentalInterest
        downstreamHumanOccupation
        downstreamInstallations
        wasteType
      }
      geometry {
        id
        crestElevation
        crestLength
        crestWidth
        globalSlopeDownstream
        slopeBetweenBerms
        upstreamSlopeInclination
        bermLength
      }
      type
      sections {
        id
        name
        type
        isUTM
        status
        initialCoordinateE
        initialCoordinateN
        finalCoordinateE
        finalCoordinateN
      }
      responsible {
        id
        name
      }
      complex {
        id
        name
        address
        neighborhood
        city
        state
        country
        zipCode
        phone
      }
      company {
        id
        status
        name
        logo
        cnpj
        address {
          country
          state
          city
          zipCode
          addressLine
          number
        }
      }
    }
  }
`;
export type GetStructureSummaryQueryResult = Apollo.QueryResult<
  GetStructureSummaryQuery,
  GetStructureSummaryQueryVariables
>;
