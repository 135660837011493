import { useNavigate, useParams } from 'react-router-dom';
import ReadingPagePzINA from '../ReadingPagePzINA/ReadingPagePzINA';
import ReadingsPagePluviometer from '../ReadingsPagePluviometer/ReadingsPagePluviometer';
import ReadingsPageSurfaceMarker from '../ReadingsPageSurfaceMarker/ReadingsPageSurfaceMarker';
import ReadingsPageWaterLevel from '../ReadingsPageWaterLevel/ReadingsPageWaterLevel';
import DrawerMenuTemplate from '../../Templates/DrawerMenuTemplate/DrawerMenuTemplate';
import RecursiveMenu from '../../Molecules/RecursiveMenu/RecursiveMenu';
import PiezomererIcon from '../../icons/piezometerIcon';
import InaIcon from '../../icons/inaIcon';
import { MdOutlineWaterDrop } from 'react-icons/md';
import { FaWater } from 'react-icons/fa6';
import SurfaceMarkerIcon from '../../icons/surfaceMarkerIcon';
import { IconType } from 'react-icons';
import { MenuItemType } from '../../Molecules/RecursiveMenu/RecursiveMenu.interface';
import { InstrumentType } from '../InstrumentDataListPage/InstrumentDataListPage.interface';

const ReadingsPage = () => {
  const { structureId, instrumentType } = useParams();
  const navigate = useNavigate();

  const instrumentListPageMap = {
    piezometer: ReadingPagePzINA,
    ina: ReadingPagePzINA,
    pluviometer: ReadingsPagePluviometer,
    waterlevel: ReadingsPageWaterLevel,
    surfacemarker: ReadingsPageSurfaceMarker
  };

  const InstrumentInfoWindow =
    instrumentListPageMap[
      instrumentType!.toLocaleLowerCase() as InstrumentType
    ];

  const instrumentTypes = [
    { type: 'piezometer', icon: PiezomererIcon as unknown as IconType },
    { type: 'ina', icon: InaIcon as unknown as IconType },
    { type: 'pluviometer', icon: MdOutlineWaterDrop },
    { type: 'waterlevel', icon: FaWater },
    { type: 'surfacemarker', icon: SurfaceMarkerIcon as unknown as IconType }
  ];

  const menuItems: MenuItemType[] = instrumentTypes.map(
    (instrument, index) => ({
      id: index.toString(),
      icon: instrument.icon,
      name: instrument.type,
      identifier: instrument.type,
      children: []
    })
  );

  const handleItemClick = (item: MenuItemType[]) => {
    const selectedInstrument = item[0].name;
    navigate(`/${structureId}/monitoring/readings/${selectedInstrument}`);
  };

  return (
    <DrawerMenuTemplate
      title=""
      titleMenu="readings"
      width={250}
      menu={
        <RecursiveMenu
          items={menuItems}
          onClick={(item) => handleItemClick(item)}
          checkbox={false}
        />
      }
      content={InstrumentInfoWindow && <InstrumentInfoWindow />}
    />
  );
};

export default ReadingsPage;
