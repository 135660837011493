import { useMutation } from '@apollo/client';
import {
  EditSectionDocument,
  EditSectionMutation,
  EditSectionMutationVariables
} from '../../graphql/generated/editSection.mutation';
import { ListCompaniesDocument } from '../../graphql/query/generated/listCompanies.query';
import { ListCompanyRelatedUsersAndStructuresDocument } from '../../graphql/query/generated/listCompanyRelatedUsersAndStructures.query';
import { ListModulesRolesDocument } from '../../graphql/query/generated/listModulesRole.query';
import { ListSectionDocument } from '../../graphql/query/generated/listSection.query';
import { ListStructureAndModulesDocument } from '../../graphql/query/generated/listStructureAndModules.query';
import { ListStructuresDocument } from '../../graphql/query/generated/listStructures.query';
import { ListUsersDocument } from '../../graphql/query/generated/listUsers.query';
import { GetUserStructuresListDocument } from '../../graphql/query/generated/structureList.query';
import { GetStructureSummaryDocument } from '../../graphql/query/generated/structureSummary.query';

export function useEditSection(onCompleted?: () => void, onError?: () => void) {
  const [editEditSection, { data, error, loading }] = useMutation<
    EditSectionMutation,
    EditSectionMutationVariables
  >(EditSectionDocument, {
    refetchQueries: [
      ListStructureAndModulesDocument,
      ListSectionDocument,
      ListStructuresDocument,
      ListCompaniesDocument,
      ListUsersDocument,
      ListModulesRolesDocument,
      GetUserStructuresListDocument,
      ListCompanyRelatedUsersAndStructuresDocument,
      GetStructureSummaryDocument
    ],
    awaitRefetchQueries: true,
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    editEditSection,
    data,
    error,
    loading
  };
}
