import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type EditPluviometerAndWaterLevelAlertMutationVariables = Types.Exact<{
  structureId: Types.Scalars['String']['input'];
  data: Types.EditInstrumentAlertInput;
}>;


export type EditPluviometerAndWaterLevelAlertMutation = { __typename?: 'Mutation', editPluviometerAndWaterLevelAlert: string };


export const EditPluviometerAndWaterLevelAlertDocument = gql`
    mutation EditPluviometerAndWaterLevelAlert($structureId: String!, $data: EditInstrumentAlertInput!) {
  editPluviometerAndWaterLevelAlert(structureId: $structureId, data: $data)
}
    `;
export type EditPluviometerAndWaterLevelAlertMutationFn = Apollo.MutationFunction<EditPluviometerAndWaterLevelAlertMutation, EditPluviometerAndWaterLevelAlertMutationVariables>;
export type EditPluviometerAndWaterLevelAlertMutationResult = Apollo.MutationResult<EditPluviometerAndWaterLevelAlertMutation>;
export type EditPluviometerAndWaterLevelAlertMutationOptions = Apollo.BaseMutationOptions<EditPluviometerAndWaterLevelAlertMutation, EditPluviometerAndWaterLevelAlertMutationVariables>;