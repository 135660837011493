import { useMutation, useQuery } from '@apollo/client';
import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TiDelete } from 'react-icons/ti';
import { useParams } from 'react-router-dom';
import { z } from 'zod';
import {
  UpdateAtoDocument,
  UpdateAtoMutation,
  UpdateAtoMutationVariables
} from '../../../../data/graphql/generated/addEditAto.mutation';
import {
  CreateAtoDocument,
  CreateAtoMutation,
  CreateAtoMutationVariables
} from '../../../../data/graphql/generated/createAto.mutation';
import {
  DeleteAtoDocsDocument,
  DeleteAtoDocsMutation,
  DeleteAtoDocsMutationVariables
} from '../../../../data/graphql/generated/deleteAtoDocs';
import {
  SaveAtoDocsDocument,
  SaveAtoDocsMutation,
  SaveAtoDocsMutationVariables
} from '../../../../data/graphql/generated/saveAtoDocs';
import {
  FindAllAtoDocument,
  FindAllAtoQuery
} from '../../../../data/graphql/query/generated/findAllAto.query';
import {
  FindAtoVerifiersDocument,
  FindAtoVerifiersQuery,
  FindAtoVerifiersQueryVariables
} from '../../../../data/graphql/query/generated/findAtoVerifiers';
import {
  ListCompaniesDocument,
  ListCompaniesQuery,
  ListCompaniesQueryVariables
} from '../../../../data/graphql/query/generated/listCompanies.query';
import { toastfyError, toastfySuccess } from '../../../Toastify';
import Button from '../../Atoms/Button/Button';
import DatepickerInput from '../../Molecules/DatepickerInput/DatepickerInput';
import InputSelectSearch from '../../Molecules/InputSelectSearch/InputSelectSearch';
import InputText from '../../Molecules/InputText/InputText';
import TableComponent from '../../Molecules/TableComponent/TableComponent';
import { AtoFormProps, Docs } from './AtoForm.interfaces';
import {
  DivDoc,
  DivInput,
  HolderFooter,
  HolderFormAto,
  HolderTable
} from './AtoForm.styles';
import {
  FindAtoApproversDocument,
  FindAtoApproversQuery,
  FindAtoApproversQueryVariables
} from '../../../../data/graphql/query/generated/findAtoApprovers';
import ErrorsTreatments from '../../../../utils/errorTreatment';

const formAtoSchema = z.object({
  title: z.string().min(1, 'O campo deve ser preenchido'),
  contract: z.string().min(1, 'O campo deve ser preenchido'),
  startDate: z
    .date()
    .nullable()
    .refine((val) => val !== null, {
      message: 'O campo deve ser preenchido'
    }),
  endDate: z
    .date()
    .nullable()
    .refine((val) => val !== null, {
      message: 'O campo deve ser preenchido'
    }),
  responsibleCompany: z
    .object({
      value: z.string(),
      label: z.string()
    })
    .nullable()
    .refine((val) => val !== null, {
      message: 'O campo deve ser preenchido'
    }),
  companyClient: z
    .object({
      value: z.string(),
      label: z.string()
    })
    .nullable()
    .refine((val) => val !== null, {
      message: 'O campo deve ser preenchido'
    }),
  approvedClient: z
    .object({
      value: z.string(),
      label: z.string()
    })
    .nullable(),
  verifier: z
    .object({
      value: z.string(),
      label: z.string()
    })
    .nullable()
    .refine((val) => val !== null, {
      message: 'O campo deve ser preenchido'
    }),
  docs: z.array(z.object({ name: z.string(), id: z.string() })).optional()
});

type formAtoSchemaType = z.infer<typeof formAtoSchema>;

const docSchema = z.object({
  docs: z.string().min(1, { message: 'O campo deve ser preenchido' })
});

type docFormSchemaType = z.infer<typeof docSchema>;

const AtoForm = ({ ato, setShowModal }: AtoFormProps) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue
  } = useForm<formAtoSchemaType>({
    resolver: zodResolver(formAtoSchema),
    defaultValues: {
      title: ato?.title || '',
      contract: ato?.contract || '',
      startDate: ato?.startDate ? new Date(ato?.startDate) : new Date(),
      endDate: ato?.finalDate ? new Date(ato?.finalDate) : new Date(),

      responsibleCompany: ato?.responsibleCompany
        ? {
            value: ato?.responsibleCompany.id,
            label: ato?.responsibleCompany.name
          }
        : {
            value: '',
            label: ''
          },
      companyClient: ato?.client
        ? {
            value: ato?.client.id,
            label: ato?.client.name
          }
        : {
            value: '',
            label: ''
          },
      approvedClient: ato?.clientApprover
        ? {
            value: ato?.clientApprover.id,
            label: ato?.clientApprover.name
          }
        : {
            value: '',
            label: ''
          },
      verifier: ato?.verifier
        ? {
            value: ato?.verifier.id,
            label: ato?.verifier.name
          }
        : {
            value: '',
            label: ''
          },
      docs: ato?.docs.map((doc) => ({ name: doc.name, id: doc.id })) || []
    }
  });
  const {
    control: docControl,
    handleSubmit: docHandleSubmit,
    reset: docsReset,
    formState: { errors: docErrors }
  } = useForm<docFormSchemaType>({
    resolver: zodResolver(docSchema)
  });

  const [docInput, setDocInput] = useState<Docs[]>(ato?.docs || []);
  const { t: translate } = useTranslation();
  const [createAto] = useMutation<
    CreateAtoMutation,
    CreateAtoMutationVariables
  >(CreateAtoDocument);
  const [editAto] = useMutation<UpdateAtoMutation, UpdateAtoMutationVariables>(
    UpdateAtoDocument
  );
  const { data: listCompanies } = useQuery<
    ListCompaniesQuery,
    ListCompaniesQueryVariables
  >(ListCompaniesDocument, {
    variables: {
      data: {
        listAllCompanies: true
      }
    }
  });
  const { structureId } = useParams();
  const [deleteDoc] = useMutation<
    DeleteAtoDocsMutation,
    DeleteAtoDocsMutationVariables
  >(DeleteAtoDocsDocument);
  const [saveDoc] = useMutation<
    SaveAtoDocsMutation,
    SaveAtoDocsMutationVariables
  >(SaveAtoDocsDocument);
  const responsibleCompanyData = useWatch({
    control,
    name: 'responsibleCompany'
  });

  const clientCompanyData = useWatch({
    control,
    name: 'companyClient'
  });
  const { data: listAllVerifiers } = useQuery<
    FindAtoVerifiersQuery,
    FindAtoVerifiersQueryVariables
  >(FindAtoVerifiersDocument, {
    variables: {
      structureId: structureId!,
      responsibleCompanyId: responsibleCompanyData?.value || ''
    }
  });

  const { data: listAllApprovers } = useQuery<
    FindAtoApproversQuery,
    FindAtoApproversQueryVariables
  >(FindAtoApproversDocument, {
    variables: {
      structureId: structureId!,
      clientId: clientCompanyData?.value || ''
    }
  });

  const listCompaniesOptions = listCompanies?.listCompanies.map((client) => ({
    value: client.id,
    label: client.name
  }));
  const listApproversOptions = listAllApprovers?.findAtoApprovers.map(
    (client) => ({
      value: client.id,
      label: client.name
    })
  );

  const listVerifiersOptions = listAllVerifiers?.findAtoVerifiers.map(
    (verifier) => ({
      value: verifier.id,
      label: verifier.name
    })
  );

  const handleAddDoc = (data: docFormSchemaType) => {
    const newDoc = { name: data.docs, id: '' };
    const newDocs = [...docInput, newDoc];

    if (!ato?.atoId) {
      setDocInput(newDocs);
      setValue('docs', newDocs);
      docsReset({ docs: '' });
    } else {
      const docsToSave = {
        name: newDoc.name
      };

      saveDoc({
        variables: {
          docs: docsToSave,
          atoId: ato?.atoId || ''
        },
        onCompleted: () => {
          setDocInput(newDocs);
          setValue('docs', newDocs);
          toastfySuccess(translate('registeredSuccessfully'));
        },
        onError: ({ graphQLErrors }) => {
          const errorMessage = ErrorsTreatments(
            graphQLErrors[0].message,
            translate
          );
          toastfyError(errorMessage);
        },
        update: (cache, { data }) => {
          if (!data) return;

          const existingData = cache.readQuery<FindAllAtoQuery>({
            query: FindAllAtoDocument,
            variables: { structureId: structureId ? structureId : '' }
          });

          if (existingData?.findAllAto) {
            const updatedAtos = existingData.findAllAto.map((atoItem) => {
              if (atoItem.id === ato!.atoId) {
                return {
                  ...atoItem,
                  docs: [
                    ...atoItem.docs,
                    { name: newDoc.name, id: newDoc.id || '' }
                  ]
                };
              }
              return atoItem;
            });

            cache.writeQuery({
              query: FindAllAtoDocument,
              data: { findAllAto: updatedAtos },
              variables: { structureId: structureId ? structureId : '' }
            });
          }
        }
      });
    }
  };

  const handleRemoveDoc = (doc: Docs) => {
    const newDocs = docInput.filter((item) => item.id !== doc.id);
    if (!doc.id) {
      setDocInput(newDocs);
      setValue('docs', newDocs);
    } else {
      deleteDoc({
        variables: {
          deleteAtoDocsId: doc.id
        },
        onCompleted: () => {
          setDocInput(newDocs);
          setValue('docs', newDocs);
          toastfySuccess(translate('deletedSuccessfully'));
        },
        onError: ({ graphQLErrors }) => {
          const errorMessage = ErrorsTreatments(
            graphQLErrors[0].message,
            translate
          );
          toastfyError(errorMessage);
        },
        update: (cache, { data }) => {
          if (!data) return;
          const existingData = cache.readQuery<FindAllAtoQuery>({
            query: FindAllAtoDocument,
            variables: { structureId: structureId ? structureId : '' }
          });
          if (existingData?.findAllAto) {
            const updatedAtos = existingData.findAllAto.map((ato) =>
              ato.id === ato!.id
                ? {
                    ...ato,
                    docs: ato.docs.filter(
                      (existingDoc) => existingDoc.id !== doc.id
                    )
                  }
                : ato
            );
            cache.writeQuery({
              query: FindAllAtoDocument,
              data: { findAllAto: updatedAtos },
              variables: { structureId: structureId ? structureId : '' }
            });
          }
        }
      });
    }
  };

  const handleUpdateAto = (data: formAtoSchemaType) => {
    const atoData = {
      id: ato!.atoId,
      title: data.title,
      contract: data.contract,
      finalDate: data.endDate,
      responsibleCompanyId: data.responsibleCompany.value,
      verifierId: data.verifier.value,
      clientApproverId: data.approvedClient?.value || null
    };

    editAto({
      variables: {
        data: atoData
      },
      onCompleted: () => {
        setShowModal(false);
        toastfySuccess(translate('updatedSuccessfully'));
      },
      onError: ({ graphQLErrors }) => {
        const errorMessage = ErrorsTreatments(
          graphQLErrors[0].message,
          translate
        );
        toastfyError(errorMessage);
      },
      update: (cache, { data }) => {
        if (!data) return;
        const existingData = cache.readQuery<FindAllAtoQuery>({
          query: FindAllAtoDocument,
          variables: { structureId: structureId ? structureId : '' }
        });

        if (existingData && existingData.findAllAto) {
          const updatedAtos = existingData.findAllAto.map((ato) => {
            if (ato.id === atoData.id) {
              return {
                ...ato,
                title: atoData.title,
                contract: atoData.contract,
                finalDate: atoData.finalDate,
                responsibleCompany: {
                  ...ato.responsibleCompany,
                  id: atoData.responsibleCompanyId
                },
                verifier: {
                  ...ato.verifier,
                  id: atoData.verifierId
                },
                clientApprover: atoData.clientApproverId
                  ? { ...ato.clientApprover, id: atoData.clientApproverId }
                  : ato.clientApprover
              };
            }
            return ato;
          });

          cache.writeQuery({
            query: FindAllAtoDocument,
            data: { findAllAto: updatedAtos },
            variables: { structureId: structureId ? structureId : '' }
          });
        }
      }
    });
  };

  const handleSaveAto = (data: formAtoSchemaType) => {
    const atoData = {
      title: data.title,
      contract: data.contract,
      initialDate: new Date(data.startDate),
      finalDate: new Date(data.endDate),
      responsibleCompanyId: data.responsibleCompany.value,
      verifierId: data.verifier.value,
      clientId: data.companyClient.value,
      clientApproverId: data.approvedClient?.value || null,
      docs: data.docs?.map((doc) => doc.name) || []
    };

    createAto({
      variables: {
        data: atoData,
        structureId: structureId!
      },
      onCompleted: () => {
        setShowModal(false);
        toastfySuccess(translate('registeredSuccessfully'));
      },
      onError: ({ graphQLErrors }) => {
        const errorMessage = ErrorsTreatments(
          graphQLErrors[0].message,
          translate
        );
        toastfyError(errorMessage);
      }
    });
  };

  return (
    <HolderFormAto>
      <DivInput>
        <InputText
          width="215px"
          control={control}
          label={translate('title')}
          name={'title'}
          type={'text'}
          error={!!errors.title}
          errorMessage={errors?.title?.message}
        />
      </DivInput>
      <DivInput>
        <InputText
          width="215px"
          control={control}
          label={translate('contractCode')}
          name={'contract'}
          type={'text'}
          error={!!errors.contract}
          errorMessage={errors?.contract?.message}
        />
      </DivInput>
      <DivInput>
        <InputSelectSearch
          placeholder={translate('selectResponsibleCompany')}
          width="230px"
          control={control}
          label={translate('responsibleCompany')}
          options={listCompaniesOptions || []}
          name={'responsibleCompany'}
          error={!!errors.responsibleCompany}
          errorMessage={errors?.responsibleCompany?.message}
        />
      </DivInput>
      <DivInput>
        <InputSelectSearch
          placeholder={translate('selectVerifier')}
          width="230px"
          control={control}
          label={translate('verifier')}
          options={listVerifiersOptions || []}
          name="verifier"
          error={!!errors.verifier}
          errorMessage={errors?.verifier?.message}
        />
      </DivInput>
      <DivInput>
        <InputSelectSearch
          placeholder={translate('selectCompanyClient')}
          width="230px"
          control={control}
          label={translate('clientCompany')}
          options={listCompaniesOptions || []}
          name="companyClient"
          error={!!errors.companyClient}
          errorMessage={errors?.companyClient?.message}
          disabled={!!ato}
        />
      </DivInput>
      <DivInput>
        <InputSelectSearch
          placeholder={translate('selectApprovingClient')}
          width="230px"
          control={control}
          label={translate('approvingClient')}
          options={listApproversOptions || []}
          name="approvedClient"
          error={!!errors.approvedClient}
          errorMessage={errors?.approvedClient?.message}
        />
      </DivInput>
      <DivInput>
        <DatepickerInput
          label={translate('startDate')}
          control={control}
          name="startDate"
          placeholder={translate('startDate')}
          time={false}
          error={!!errors.startDate}
          errorMessage={errors?.startDate?.message}
          disabled={!!ato}
        />
      </DivInput>
      <DivInput>
        <DatepickerInput
          label={translate('finalDate')}
          control={control}
          name="endDate"
          placeholder={translate('finalDate')}
          time={false}
          error={!!errors.endDate}
          errorMessage={errors?.endDate?.message}
        />
      </DivInput>
      <DivDoc>
        <DivInput>
          <InputText
            control={docControl}
            name="docs"
            width="230px"
            label={translate('archiveName')}
            type={'text'}
            error={!!docErrors.docs}
            errorMessage={docErrors?.docs?.message}
          />
        </DivInput>
        <Button
          variant={'secondary'}
          size="medium"
          text={translate('adcDocument')}
          onClick={docHandleSubmit(handleAddDoc)}
        />
        {docInput.length > 0 && (
          <HolderTable>
            <TableComponent
              tableData={docInput || []}
              columns={[
                {
                  key: 'name',
                  label: `${translate('archiveName')}`
                }
              ]}
              actions={[
                {
                  name: `${translate('Delete')}`,
                  icon: <TiDelete />,
                  onClick: (doc) => handleRemoveDoc(doc as any),
                  canShow: 'canDelete'
                }
              ]}
            />
          </HolderTable>
        )}
      </DivDoc>
      <HolderFooter>
        <Button
          size="large"
          text={ato ? `${translate('editAto')}` : `${translate('createAto')}`}
          onClick={
            ato ? handleSubmit(handleUpdateAto) : handleSubmit(handleSaveAto)
          }
          variant={'primary'}
        />
      </HolderFooter>
    </HolderFormAto>
  );
};

export default AtoForm;
