import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type EditSurfaceMarkerReferenceMutationVariables = Types.Exact<{
  structureInfo: Types.StructureInfoInput;
  data: Types.EditSurfaceMarkerReferenceInput;
}>;


export type EditSurfaceMarkerReferenceMutation = { __typename?: 'Mutation', editSurfaceMarkerReference: string };


export const EditSurfaceMarkerReferenceDocument = gql`
    mutation EditSurfaceMarkerReference($structureInfo: StructureInfoInput!, $data: EditSurfaceMarkerReferenceInput!) {
  editSurfaceMarkerReference(structureInfo: $structureInfo, data: $data)
}
    `;
export type EditSurfaceMarkerReferenceMutationFn = Apollo.MutationFunction<EditSurfaceMarkerReferenceMutation, EditSurfaceMarkerReferenceMutationVariables>;
export type EditSurfaceMarkerReferenceMutationResult = Apollo.MutationResult<EditSurfaceMarkerReferenceMutation>;
export type EditSurfaceMarkerReferenceMutationOptions = Apollo.BaseMutationOptions<EditSurfaceMarkerReferenceMutation, EditSurfaceMarkerReferenceMutationVariables>;