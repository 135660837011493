import styled from 'styled-components';

interface CreateActionPlanProps {
  admin: boolean;
}

export const DivContainerCreateActionPlan = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-items: end;
`;

export const DivContainerCreateButton = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  justify-content: flex-end;
  padding-top: 30px;
  gap: 15px;
`;

export const DivSelects = styled.div<CreateActionPlanProps>`
  display: ${({ admin }) => (admin ? 'block' : 'none')};
`;
