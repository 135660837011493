import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type UpdateActionPlanMutationVariables = Types.Exact<{
  data: Types.UpdateActionPlanInput;
}>;


export type UpdateActionPlanMutation = { __typename?: 'Mutation', updateActionPlan: string };


export const UpdateActionPlanDocument = gql`
    mutation UpdateActionPlan($data: UpdateActionPlanInput!) {
  updateActionPlan(data: $data)
}
    `;
export type UpdateActionPlanMutationFn = Apollo.MutationFunction<UpdateActionPlanMutation, UpdateActionPlanMutationVariables>;
export type UpdateActionPlanMutationResult = Apollo.MutationResult<UpdateActionPlanMutation>;
export type UpdateActionPlanMutationOptions = Apollo.BaseMutationOptions<UpdateActionPlanMutation, UpdateActionPlanMutationVariables>;