import styled, { css } from 'styled-components';
import { Color, FontSize } from '../../../../Styles/Styles';

interface ButtonProps {
  width?: string;
  size?: 'small' | 'medium' | 'large';
  error?: boolean;
}

const sizeStyles = {
  small: css`
    padding: 8px 16px;
    width: 100px;
    font-size: ${FontSize.Small};
  `,
  medium: css`
    padding: 8px 24px;
    width: 200px;
    font-size: ${FontSize.Small};
  `,
  large: css`
    padding: 8px 32px;
    width: 300px;
    font-size: ${FontSize.Small};
  `
};

export const ButtonPrimaryStyled = styled.button<ButtonProps>`
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.width || '150px'};
  height: auto;
  box-sizing: border-box;
  outline: none;
  transition: background-color 0.3s ease;
  background-color: ${Color.Brown1};
  color: ${Color.White};
  border-radius: 5px;
  gap: 5px;

  ${(props) =>
    props.error &&
    css`
      border: 1px solid ${Color.Red1};
    `}
  ${(props) => props.size && sizeStyles[props.size]}
    &:hover {
    background-color: ${Color.Brown2};
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${Color.Gray1};
    color: ${Color.Disabled};
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ButtonSecondaryStyled = styled.button<ButtonProps>`
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.width || '150px'};
  height: auto;
  box-sizing: border-box;
  outline: none;
  transition: background-color 0.3s ease;
  background-color: ${Color.White};
  color: ${Color.Brown1};
  gap: 5px;

  ${(props) =>
    props.error &&
    css`
      border: 1px solid ${Color.Red1};
    `}

  ${(props) => props.size && sizeStyles[props.size]}

  &:hover {
    color: ${Color.Brown2};
  }

  &:disabled {
    cursor: not-allowed;
    color: ${Color.Gray1};
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ButtonDangerStyled = styled.button<ButtonProps>`
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.width || '150px'};
  height: auto;
  box-sizing: border-box;
  outline: none;
  transition: background-color 0.3s ease;
  background-color: ${Color.White};
  color: ${Color.Red2};
  gap: 5px;

  ${(props) =>
    props.error &&
    css`
      border: 1px solid ${Color.Red1};
    `}

  ${(props) => props.size && sizeStyles[props.size]}

  &:hover {
    color: ${Color.Red1};
  }

  &:disabled {
    cursor: not-allowed;
    color: ${Color.Gray1};
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ContainerSkeleton = styled.div`
  position: relative;
`;
