import { useMutation } from '@apollo/client';
import {
  DeleteInstrumentMutation,
  DeleteInstrumentMutationVariables,
  DeleteInstrumentDocument
} from '../graphql/generated/deleteInstrument.mutation';
import { ListPiezometersByStructureDocument } from '../graphql/query/generated/listPiezometersByStructure.query';
import { ListPluviometersByStructureDocument } from '../graphql/query/generated/listPluviometersByStructure.query';
import { ListSurfaceMarkersByStructureDocument } from '../graphql/query/generated/listSurfaceMarkersByStructure.query';
import { ListWaterLevelByStructureDocument } from '../graphql/query/generated/listWaterLevelByStructure.query';

export function useDeleteInstrumen(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [deleteInstruments, { data, error, loading }] = useMutation<
    DeleteInstrumentMutation,
    DeleteInstrumentMutationVariables
  >(DeleteInstrumentDocument, {
    awaitRefetchQueries: true,
    refetchQueries: [
      ListPiezometersByStructureDocument,
      ListPluviometersByStructureDocument,
      ListSurfaceMarkersByStructureDocument,
      ListWaterLevelByStructureDocument
    ],
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    deleteInstruments,
    data,
    error,
    loading
  };
}
