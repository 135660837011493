import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type AddSurfaceMarkerAlertLevelsMutationVariables = Types.Exact<{
  structureId: Types.Scalars['String']['input'];
  data: Array<Types.AddSurfaceMarkerAlertLevels> | Types.AddSurfaceMarkerAlertLevels;
}>;


export type AddSurfaceMarkerAlertLevelsMutation = { __typename?: 'Mutation', addSurfaceMarkerAlertLevels: string };


export const AddSurfaceMarkerAlertLevelsDocument = gql`
    mutation AddSurfaceMarkerAlertLevels($structureId: String!, $data: [AddSurfaceMarkerAlertLevels!]!) {
  addSurfaceMarkerAlertLevels(structureId: $structureId, data: $data)
}
    `;
export type AddSurfaceMarkerAlertLevelsMutationFn = Apollo.MutationFunction<AddSurfaceMarkerAlertLevelsMutation, AddSurfaceMarkerAlertLevelsMutationVariables>;
export type AddSurfaceMarkerAlertLevelsMutationResult = Apollo.MutationResult<AddSurfaceMarkerAlertLevelsMutation>;
export type AddSurfaceMarkerAlertLevelsMutationOptions = Apollo.BaseMutationOptions<AddSurfaceMarkerAlertLevelsMutation, AddSurfaceMarkerAlertLevelsMutationVariables>;