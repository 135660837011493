import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type FindAllAtoVideosQueryVariables = Types.Exact<{
  atoId: Types.Scalars['String']['input'];
}>;


export type FindAllAtoVideosQuery = { __typename?: 'Query', findAllAtoVideos: Array<{ __typename?: 'AtoVideosType', recordNumber: string, videos: Array<{ __typename?: 'AtoVideoType', video: string }> }> };


export const FindAllAtoVideosDocument = gql`
    query FindAllAtoVideos($atoId: String!) {
  findAllAtoVideos(atoId: $atoId) {
    recordNumber
    videos {
      video
    }
  }
}
    `;
export type FindAllAtoVideosQueryResult = Apollo.QueryResult<FindAllAtoVideosQuery, FindAllAtoVideosQueryVariables>;