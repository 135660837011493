import * as Types from '../../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type FindAllInspectionsByStructureQueryVariables = Types.Exact<{
  structureId: Types.Scalars['String']['input'];
}>;

export type FindAllInspectionsByStructureQuery = {
  __typename?: 'Query';
  findAllInspectionsByStructure: Array<{
    __typename?: 'InspectionsType';
    conservationStateCalc?: number | null;
    date: Date;
    generalComments?: string | null;
    id: string;
    responsible: string;
    structureId: string;
    systemId: number;
    systemPdf?: string | null;
    userPdf?: string | null;
    participants: Array<{
      __typename?: 'InspectionParticipantsType';
      company?: string | null;
      id: string;
      name: string;
      position?: string | null;
      signature?: string | null;
      status: boolean;
    }>;
  }>;
};

export const FindAllInspectionsByStructureDocument = gql`
  query FindAllInspectionsByStructure($structureId: String!) {
    findAllInspectionsByStructure(structureId: $structureId) {
      conservationStateCalc
      date
      generalComments
      id
      participants {
        company
        id
        name
        position
        signature
        status
      }
      responsible
      structureId
      systemId
      systemPdf
      userPdf
    }
  }
`;
export type FindAllInspectionsByStructureQueryResult = Apollo.QueryResult<
  FindAllInspectionsByStructureQuery,
  FindAllInspectionsByStructureQueryVariables
>;
