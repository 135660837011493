import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type AddPluviometerAlertLevelsMutationVariables = Types.Exact<{
  structureId: Types.Scalars['String']['input'];
  data: Array<Types.CreatePluviometerAndWaterLevelAlertLevelInput> | Types.CreatePluviometerAndWaterLevelAlertLevelInput;
}>;


export type AddPluviometerAlertLevelsMutation = { __typename?: 'Mutation', addPluviometerAlertLevels: string };


export const AddPluviometerAlertLevelsDocument = gql`
    mutation AddPluviometerAlertLevels($structureId: String!, $data: [CreatePluviometerAndWaterLevelAlertLevelInput!]!) {
  addPluviometerAlertLevels(structureId: $structureId, data: $data)
}
    `;
export type AddPluviometerAlertLevelsMutationFn = Apollo.MutationFunction<AddPluviometerAlertLevelsMutation, AddPluviometerAlertLevelsMutationVariables>;
export type AddPluviometerAlertLevelsMutationResult = Apollo.MutationResult<AddPluviometerAlertLevelsMutation>;
export type AddPluviometerAlertLevelsMutationOptions = Apollo.BaseMutationOptions<AddPluviometerAlertLevelsMutation, AddPluviometerAlertLevelsMutationVariables>;