import styled from 'styled-components';

export const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  background-color: white;
  padding: 5px 30px 40px 30px;
  box-sizing: border-box;
  overflow: auto;
`;

export const HolderTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 5px;
`;

export const HolderHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const HolderContent = styled.div`
  width: 100%;
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  row-gap: 30px;
`;
