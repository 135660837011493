import axios from 'axios';

interface Address {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  gia: string;
  ddd: string;
  siafi: string;
}

export const getAddressForCep = async (cep: string) => {
  try {
    const apiCep = cep.replace(/\D/g, '');

    if (apiCep.length !== 8) {
      return null;
    }

    const response = await axios.get<Address>(
      `https://viacep.com.br/ws/${apiCep}/json/`
    );

    return response.data;
  } catch (error) {
    console.error('Erro ao buscar endereço:', error);
    return null;
  }
};
