import { useMutation } from '@apollo/client';
import { SaveConservationStateInput } from '../graphql/base-schema';
import {
  SaveConservationStateDocument,
  SaveConservationStateMutation,
  SaveConservationStateMutationVariables
} from '../graphql/generated/saveConservationState.mutation';
import { FindAllInspectionsByStructureDocument } from '../graphql/query/generated/findAllInspectionsByStructure.query';

export function useSaveConservationState(
  onCompleted?: () => void,
  onError?: () => void
) {
  const [saveConservationState, { data, error, loading }] = useMutation<
    SaveConservationStateMutation,
    SaveConservationStateMutationVariables
  >(SaveConservationStateDocument, {
    refetchQueries: [FindAllInspectionsByStructureDocument],
    awaitRefetchQueries: true,
    errorPolicy: 'all',
    onCompleted: onCompleted,
    onError: onError
  });

  return {
    saveConservationState,
    data,
    error,
    loading
  };
}
