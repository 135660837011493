const ReadingsTableConst = (translate: any) => {

  const columnsPluviometer = [
    {
      label: translate('name'),
      key: 'name'
    },
    {
      label: translate('date'),
      key: 'date'
    },
    {
      label: translate('Observation'),
      key: 'observation'
    },
    {
      label: translate('Pluviometry'),
      key: 'pluviometry'
    },
    {
      label: translate('structureType'),
      key: 'structureType'
    },
    {
      label: translate('installLocation'),
      key: 'installLocation'
    },
    {
      label: translate('typeOfReading'),
      key: 'readingType'
    },
    {
      label: translate('operationalStatus'),
      key: 'operationalStatus'
    }
  ];

  const columnsWaterLevel = [
    {
      label: translate('name'),
      key: 'name'
    },
    {
      label: translate('date'),
      key: 'date'
    },
    {
      label: translate('structureType'),
      key: 'structureType'
    },
    {
      label: translate('typeOfReading'),
      key: 'readingType'
    },
    {
      label: translate('Observation'),
      key: 'observation'
    },
    {
      label: translate('value'),
      key: 'valor'
    },
    {
      label: translate('operationalStatus'),
      key: 'operationalStatus'
    }
  ];
  const columnsSurfaceMarker = [
    {
      label: translate('name'),
      key: 'name'
    },
    {
      label: translate('date'),
      key: 'date'
    },
    {
      label: translate('coordinateE'),
      key: 'coordinateE'
    },
    {
      label: translate('coordinateN'),
      key: 'coordinateN'
    },
    {
      label: translate('elevation'),
      key: 'elevation'
    },

    {
      label: translate('operationalStatus'),
      key: 'operationalStatus'
    },
    {
      label: translate('structureType'),
      key: 'structureType'
    },
    {
      label: translate('typeOfReading'),
      key: 'readingType'
    }
  ];

  return {
    pluviometer: columnsPluviometer,
    waterLevel: columnsWaterLevel,
    surfaceMarker: columnsSurfaceMarker
  };
};

export default ReadingsTableConst;
