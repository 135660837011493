import React from 'react';
import Accordion from '../../Molecules/Accordion/Accordion';
import { FileType } from '../../Molecules/FileViewer/FileViewer.interfaces';
import InputFileWithList from '../InputFileWithList/InputFileWithList';
import { VolumeCheckbox } from '../VolumeCheckbox/VolumeCheckbox';
import { PSBListVolumesProps } from './PSBListVolumes.interface';
import { DivBodyModal } from './PSBListVolumes.styles';
import { useFindAllPsbData } from '../../../../data/services/PSBService';
import { useParams } from 'react-router-dom';

export function PSBListVolumes({
  setFiles,
  setComments,
  files,
  comments,
  error
}: PSBListVolumesProps) {
  const { structureId } = useParams();
  const { data: psbData } = useFindAllPsbData(structureId || '');

  const handleFileChange = (files: File[]) => {
    const newFiles = Array.from(files).map((file) => ({
      id: '',
      name: file.name,
      url: URL.createObjectURL(file),
      file: file
    }));
    setFiles((prev) => [...prev, ...newFiles]);
  };

  const handleDeleteImage = (fileSelect: FileType) => {
    const updatedFiles = files.filter((file) => file.file !== fileSelect.file);
    setFiles(updatedFiles);
  };

  return (
    <DivBodyModal>
      <InputFileWithList
        name="PSB"
        error={error && files.length === 0}
        accept=".pdf"
        label=""
        multiple={true}
        files={files}
        onChange={(e) => handleFileChange(e.target.files as any)} // TODO: Ajustar o tipo de e.target.files
        onDelete={(file: FileType) => handleDeleteImage(file)}
      />
      {psbData?.findAllPsbData?.map((volumeItem) => (
        <Accordion
          key={volumeItem.id}
          component={
            volumeItem.nested &&
            volumeItem.nested.map((contentItem: any) => {
              if (contentItem.nested) {
                for (const i of contentItem.nested) {
                  if (i.nested && i.nested.length > 0) {
                    i.nested.map((aux: any) => {
                      return (
                        <VolumeCheckbox
                          error={error}
                          key={aux.id}
                          data={[aux]}
                          title={''}
                          setComments={setComments}
                          comments={comments}
                          isComment={false}
                        />
                      );
                    });
                  } else {
                    return (
                      <VolumeCheckbox
                        error={error}
                        key={contentItem.id}
                        data={contentItem.nested}
                        title={contentItem.name}
                        setComments={setComments}
                        comments={comments}
                        isComment={false}
                      />
                    );
                  }
                }
              }
            })
          }
          title={volumeItem.name}
        />
      ))}
    </DivBodyModal>
  );
}
