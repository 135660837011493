import * as Types from '../base-schema';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type RecordReprovalFlowMutationVariables = Types.Exact<{
  commentary: Types.Scalars['String']['input'];
  recordId: Types.Scalars['String']['input'];
}>;


export type RecordReprovalFlowMutation = { __typename?: 'Mutation', recordReprovalFlow: string };


export const RecordReprovalFlowDocument = gql`
    mutation RecordReprovalFlow($commentary: String!, $recordId: String!) {
  recordReprovalFlow(commentary: $commentary, recordId: $recordId)
}
    `;
export type RecordReprovalFlowMutationFn = Apollo.MutationFunction<RecordReprovalFlowMutation, RecordReprovalFlowMutationVariables>;
export type RecordReprovalFlowMutationResult = Apollo.MutationResult<RecordReprovalFlowMutation>;
export type RecordReprovalFlowMutationOptions = Apollo.BaseMutationOptions<RecordReprovalFlowMutation, RecordReprovalFlowMutationVariables>;